import { I4BCellType, I4BDiagnosticVariableColumnConfiguration } from '../configurations';
import { DataLibrary, I4bCellConcept } from '../enums';
import { I4BBasicColumnHeader } from '../headers';
import { HeaderType, I4BColumnHeader } from '../models';
import { BasicColumnOptions, I4BColumnOptions } from '../options';
import { I4BColumn } from './i4b-column';

export class DiagnosticVariableColumn implements I4BColumn<I4BBasicColumnHeader, I4BDiagnosticVariableColumnConfiguration, I4BColumnOptions> {
  columnId = 'c653e68d-f410-4952-814b-d6c69c6dacce-diagnostic-variable';
  className: DiagnosticVariableColumn;

  dataLibs: DataLibrary[] = [DataLibrary.DEVICES];

  header: I4BBasicColumnHeader = new I4BBasicColumnHeader({ type: HeaderType.BASIC });
  configuration: I4BDiagnosticVariableColumnConfiguration = {
    concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
    id: null,
    isConfigurable: false,
    isDefault: true,
    isLink: true,
    position: null,
    sortProperty: null,
    width: 160,
    cell: {
      type: I4BCellType.DIAGNOSTIC_VARIABLE,
      options: {
        variableCategory: '',
        displayMode: 'value'
      },
      event: { type: 'openChart', options: null }
    }
  };
  options: I4BColumnOptions = new BasicColumnOptions({ sortable: false });

  constructor(
    customHeader: Partial<I4BColumnHeader>,
    customConfig: Partial<I4BDiagnosticVariableColumnConfiguration>,
    customOptions: Partial<I4BColumnOptions>
  ) {
    if (customHeader) {
      this.header = new I4BBasicColumnHeader({
        ...this.header,
        ...customHeader
      });
    }

    if (customConfig) {
      this.configuration = { ...this.configuration, ...customConfig, cell: { ...this.configuration.cell, ...customConfig.cell } };
    }

    if (customOptions) {
      this.options = { ...this.options, ...customOptions, sortable: false };
    }
  }
}
