<div class="site-card">
  <div class="site-card-row" fxLayout="row" fxLayout.lt-md="column">
    <div class="site-card-col site-card__image" fxLayout="column wrap" fxLayoutAlign="center center" fxFlex="360px" fxFlex.lt-md="100%"><img [src]="imgPath" alt="" /></div>
    <div class="site-card-col site-card__details full-width p-20" fxLayout="column" fxFlex="100%" *ngIf="!loading">
      <div class="site-card-header" fxLayout="row" fxLayoutAlign="start stretch">
        <span class="site-card__name">{{ site?.displayName | infoDisplay }}</span>
        <div class="site-card-buttons app-round-button" *ngIf="canDelete || canUpdate">
          <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="canUpdate" (click)="update?.emit()">{{ 'SITES.CARD.EDIT' | translate  | uppercase }}</button>
            <button mat-menu-item *ngIf="canDelete" (click)="delete?.emit()" [disabled]="site?.totalAssets !== 0 || site?.totalDevices !== 0">{{ 'SITES.CARD.DELETE' | translate | uppercase }}</button>
          </mat-menu>
        </div>
      </div>
      <div class="site-card-content" fxLayout="row wrap">
        <ul class="site-card-list" fxFlex="50%" fxFlex.lt-md="100%">
          <li class="site-card__item">{{ 'SITES.CARD.SHIP_TO_ID' | translate }}<span class="site-card__value">{{ site?.name | infoDisplay }}</span></li>
          <li class="site-card__item">{{ 'SITES.CARD.TYPE' | translate }}<span class="site-card__value">{{ ('SITES.CARD.TYPES.' + site?.type) | infoDisplay | translate }}</span></li>
          <li class="site-card__item">{{ 'SITES.CARD.ENTITY' | translate }}<span class="site-card__value" *ngIf="site?.entity">{{ site?.entity.name | infoDisplay }}</span></li>
        </ul>
        <ul class="site-card-list" fxFlex="50%" fxFlex.lt-md="100%">
          <li class="site-card__item"><span class="site-card__value" *ngIf="site?.address">{{ site?.address.address1 | infoDisplay }}</span></li>
          <li class="site-card__item"><span class="site-card__value" *ngIf="site?.address">{{ site?.address.address2 | infoDisplay }}</span></li>
          <li class="site-card__item"><span class="site-card__value" *ngIf="site?.address">{{ site?.address | infoDisplay: 'brackets':'city':'zipCode' }}</span></li>
          <li class="site-card__item">
            <span class="site-card__value" *ngIf="!site?.address || !site?.address.country">-</span>
            <span class="site-card__value" *ngIf="site?.address && site?.address.country">{{ 'IOT_DICTIONARY.COUNTRIES.' + site?.address.country | translate }}</span>
          </li>
        </ul>
      </div>

      <p class="site-card__description">{{ site?.description | infoDisplay }}</p>
    </div>
    <div class="site-card-col" fxFlex="100%" fxLayoutAlign="center center" *ngIf="loading">
      <mat-progress-spinner fxLayout="row" color='accent' mode='indeterminate' [diameter]='50'></mat-progress-spinner>
    </div>
  </div>
</div>
