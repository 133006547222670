import { MatTooltipModule } from '@angular/material/tooltip';
import { ListDisplayComponent } from './list-display.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ListDisplayComponent],
  imports: [CommonModule, MatTooltipModule],
  exports: [ListDisplayComponent]
})
export class ListDisplayModule {}
