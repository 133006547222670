import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { FavoriteView } from '@iot-platform/models/common';

import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import {
  selectGridAndLoadData
} from '../../../../../../../grid-engine/src/lib/components/state/actions/grids-db.actions';

import { FavoriteViewsActions } from '../../../../../../../shared/src/lib/favorite-views/+state/actions';
import * as fromFavoriteViews from '../../../../../../../shared/src/lib/favorite-views/+state/reducers';

@Component({
  selector: 'iot4bos-ui-home-shell',
  templateUrl: './home-shell.component.html',
  styleUrls: ['./home-shell.component.scss']
})
export class HomeShellComponent implements OnInit {
  sharedFavoriteViews$: Observable<FavoriteView[]>;
  notSharedFavoriteViews$: Observable<FavoriteView[]>;
  fvLoading: false;

  constructor(private router: Router, private dialog: MatDialog, private store: Store) {}

  ngOnInit() {
    this.store.dispatch(FavoriteViewsActions.loadFavoriteViews());
    this.sharedFavoriteViews$ = this.store.pipe(select(fromFavoriteViews.getSharedFavoriteViews));
    this.notSharedFavoriteViews$ = this.store.pipe(select(fromFavoriteViews.getNotSharedFavoriteViews));
  }

  navigateTo(favoriteView: FavoriteView): void {
    this.store.dispatch(FavoriteViewsActions.setCurrentFavoriteView({ masterView: favoriteView.masterView, favoriteView: favoriteView }));
    this.store.dispatch(
      selectGridAndLoadData({ gridId: favoriteView.gridId as string, masterview: favoriteView.masterView as string, filters: favoriteView.filters })
    );
  }
}
