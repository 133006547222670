import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Asset } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-detail-assets-card',
  templateUrl: './detail-assets-card.component.html',
  styleUrls: ['./detail-assets-card.component.scss']
})
export class DetailAssetsCardComponent implements OnInit {
  @Input() total: number = 0;
  @Input() assets: Asset[] = [];

  @Output() selectItem: EventEmitter<Asset> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClick(asset: Asset) {
    this.selectItem.emit(asset);
  }
}
