import { GetUtils } from '@iot-platform/iot-platform-utils';
import { BaseState, CommonIndexedPagination, Filter, INITIAL_BASE_STATE } from '@iot-platform/models/common';
import { Product, Site } from '@iot-platform/models/oyan';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SiteActions, SiteProductsActions } from '../actions';

export const featureKey = 'sitesProductsApiFeatureKey';

export type State = BaseState<Product, CommonIndexedPagination, Filter>;

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>({
  selectId: (entity: Product) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, hasMore: false, limit: 10, maxPage: 0, total: 0 }
});

const setAll = (state: State, site: Site): State => {
  return {
    ...adapter.setAll(GetUtils.get(site, 'products', []), { ...state })
  };
};

export const reducer = createReducer(
  initialState,
  on(SiteActions.loadSiteByIdSuccess, (state: State, { response }): State => setAll(state, response)),
  on(SiteActions.setActiveSite, (state: State, { site }): State => setAll(state, site)),

  on(SiteProductsActions.addProductToSiteSuccess, (state: State, { product }): State => {
    return adapter.addOne(product, { ...state, entity: product });
  }),

  on(SiteProductsActions.removeProductFromSiteSuccess, (state: State, { product }): State => {
    return adapter.removeOne(product.id, state);
  }),

  on(
    SiteProductsActions.addProductToSite,
    SiteProductsActions.removeProductFromSite,
    (state: State): State => ({
      ...state,
      loading: true
    })
  ),
  on(
    SiteProductsActions.addProductToSiteSuccess,
    SiteProductsActions.removeProductFromSiteSuccess,
    (state: State): State => ({
      ...state,
      loading: false,
      loaded: true
    })
  ),
  on(
    SiteProductsActions.addProductToSiteFailure,
    SiteProductsActions.removeProductFromSiteFailure,
    (state: State, { error }): State => ({
      ...state,
      loading: false,
      loaded: false,
      error
    })
  )
);
