import { NgModule } from '@angular/core';

import { SharedModule } from '@iot-platform/shared';
import { PanelComponent } from './panel.component';

@NgModule({
  imports: [SharedModule],
  declarations: [PanelComponent],
  exports: [PanelComponent]
})
export class PanelModule {}
