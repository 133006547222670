import { Action, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../../auth/src/lib/state/actions';
import { AssetEventsByTopicLogsDbActions, AssetEventsByTopicLogsUiActions } from '../../actions';
//
export const assetEventsByTopicLogsUiFeatureKey = 'assetEventsByTopicLogsUi';

export interface State {
  error?: any;
  loaded?: boolean;
}

export const initialState: State = {
  error: null,
  loaded: false
};

const assetEventsByTopicLogsUiReducer = createReducer(
  initialState,
  on(AssetEventsByTopicLogsUiActions.loadLogsByAssetEventId, (state: State) => ({ ...state, error: null, loaded: false })),
  on(AssetEventsByTopicLogsDbActions.loadLogsByAssetEventIdSuccess, (state: State) => ({ ...state, loaded: true })),
  on(AssetEventsByTopicLogsDbActions.loadLogsByAssetEventIdFailure, (state: State, { error }) => ({ ...state, loaded: false, error })),
  // ****
  on(AssetEventsByTopicLogsUiActions.createLogByAssetEventId, (state: State) => ({ ...state, error: null })),
  on(AssetEventsByTopicLogsDbActions.createLogByAssetEventIdSuccess, (state: State) => ({ ...state, loaded: true })),
  on(AssetEventsByTopicLogsDbActions.createLogByAssetEventIdFailure, (state: State, { error }) => ({ ...state, loaded: false, error })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsByTopicLogsUiReducer(state, action);
}

export const getError = (state: State) => state.error;
export const getLogsLoaded = (state: State) => state.loaded;
