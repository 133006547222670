import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TabNavBarComponent } from './tab-nav-bar.component';

@NgModule({
  imports: [CommonModule, MatTabsModule, RouterModule, TranslateModule],
  declarations: [TabNavBarComponent],
  exports: [TabNavBarComponent],
})
export class TabNavBarModule { }
