import { PlatformResponse, UserPreferences } from '@iot-platform/models/common';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import { fromUserPreferences } from '../../../../../../../users/src/lib/features/preferences';
import * as fromContactsDb from './contacts-db.reducer';
import * as fromContactsUi from './contacts-ui.reducer';
import * as fromGraphsDb from './graphs-db.reducer';
import * as fromGraphsUi from './graphs-ui.reducer';
import * as fromSitesDb from './sites-db.reducer';
import * as fromSitesUi from './sites-ui.reducer';
import * as fromSpreadsheetExportsDb from './spreadsheet-export-db.reducer';
import * as fromSpreadsheetExportsUi from './spreadsheet-export-ui.reducer';

export const sitesFeatureKey = 'sites';

export interface SitesState {
  [fromSitesDb.sitesDbFeatureKey]: fromSitesDb.State;
  [fromSitesUi.sitesUiFeatureKey]: fromSitesUi.State;
  [fromGraphsDb.graphsDbFeatureKey]: fromGraphsDb.State;
  [fromGraphsUi.graphsUiFeatureKey]: fromGraphsUi.State;
  [fromSpreadsheetExportsDb.spreadsheetExportsDbFeatureKey]: fromSpreadsheetExportsDb.State;
  [fromSpreadsheetExportsUi.spreadsheetExportsUiFeatureKey]: fromSpreadsheetExportsUi.State;
  [fromContactsDb.contactsDbFeatureKey]: fromContactsDb.State;
  [fromContactsUi.contactsUiFeatureKey]: fromContactsUi.State;
}

export interface State {
  [sitesFeatureKey]: SitesState;
}

export function reducers(state: SitesState | undefined, action: Action) {
  return combineReducers({
    [fromSitesDb.sitesDbFeatureKey]: fromSitesDb.reducer,
    [fromSitesUi.sitesUiFeatureKey]: fromSitesUi.reducer,
    [fromGraphsDb.graphsDbFeatureKey]: fromGraphsDb.reducer,
    [fromGraphsUi.graphsUiFeatureKey]: fromGraphsUi.reducer,
    [fromSpreadsheetExportsDb.spreadsheetExportsDbFeatureKey]: fromSpreadsheetExportsDb.reducer,
    [fromSpreadsheetExportsUi.spreadsheetExportsUiFeatureKey]: fromSpreadsheetExportsUi.reducer,
    [fromContactsDb.contactsDbFeatureKey]: fromContactsDb.reducer,
    [fromContactsUi.contactsUiFeatureKey]: fromContactsUi.reducer
  })(state, action);
}

export const selectSitesState = createFeatureSelector<SitesState>(sitesFeatureKey);
const getUserPreferencesState = createFeatureSelector<fromUserPreferences.UserPreferencesState>(fromUserPreferences.userPreferencesFeatureKey);
export const selectUserPreferencesState = createFeatureSelector<fromUserPreferences.UserPreferencesState>(fromUserPreferences.userPreferencesFeatureKey);

export const selectSitesDbState = createSelector(selectSitesState, (state: SitesState) => state[fromSitesDb.sitesDbFeatureKey]);
export const selectSitesUIState = createSelector(selectSitesState, (state: SitesState) => state[fromSitesUi.sitesUiFeatureKey]);

export const {
  selectIds: getSitesIds,
  selectEntities: getSitesEntities,
  selectAll: getAllSites,
  selectTotal: getTotalSites
} = fromSitesDb.adapter.getSelectors(selectSitesDbState);

export const getSelectedSiteId = createSelector(selectSitesDbState, fromSitesDb.getSelectedSiteId);
export const getSelectedSite = createSelector(getSitesEntities, getSelectedSiteId, (entities, selectedId) => selectedId && entities[selectedId]);

export const getCurrentSite = createSelector(getSitesEntities, getSelectedSiteId, (entities, selectedId) => selectedId && entities[selectedId]);

export const getTagsBySite = createSelector(selectSitesDbState, fromSitesDb.getTagsBySite);
export const getAssetsBySite = createSelector(selectSitesDbState, fromSitesDb.getAssetsBySite);
export const getDevicesBySite = createSelector(selectSitesDbState, fromSitesDb.getDevicesBySite);
export const getCurrentFavoriteView = createSelector(selectSitesDbState, fromSitesDb.getCurrentFavoriteView);
export const getCurrentFilters = createSelector(selectSitesDbState, fromSitesDb.getCurrentFilters);
export const getSitesPagination = createSelector(selectSitesDbState, fromSitesDb.getPagination);

export const getUserPreferences = createSelector(fromUserPreferences.selectUserPreferencesDbState, (state) => state.currentUser.preferences);
export const getUserPreferencesMVSitesPagination = createSelector(getUserPreferences, (preferences: UserPreferences) =>
  preferences ? preferences.mvSites : null
);
export const getMVSettings = createSelector(selectSitesDbState, (state: fromSitesDb.State) => state.settings);
export const getInitialSort = createSelector(selectSitesDbState, (state: fromSitesDb.State) => state.initialSort);
export const getPagination = createSelector(selectSitesDbState, getMVSettings, (state, settings) => {
  return settings && settings['masterViewTable']['bluePrint'].pageSize
    ? { ...state.pagination, limit: settings['masterViewTable']['bluePrint'].pageSize.toString() }
    : state.pagination;
});

export const getFormattedData = createSelector(getAllSites, getPagination, getInitialSort, (data, pagination, initialSort) => {
  const response: PlatformResponse = {
    data: data,
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total,
    initialSort
  };
  return response;
});

export const getSiteLoaded = createSelector(selectSitesUIState, fromSitesUi.getSiteLoaded);
export const getSiteLoading = createSelector(selectSitesUIState, fromSitesUi.getSiteLoading);
export const getAssetLoaded = createSelector(selectSitesUIState, fromSitesUi.getAssetsLoaded);
export const getAssetLoading = createSelector(selectSitesUIState, fromSitesUi.getAssetsLoading);
export const getDeviceLoaded = createSelector(selectSitesUIState, fromSitesUi.getDevicesLoaded);
export const getDeviceLoading = createSelector(selectSitesUIState, fromSitesUi.getDevicesLoading);
export const getTagsLoaded = createSelector(selectSitesUIState, fromSitesUi.getTagsLoaded);
export const getTagsLoading = createSelector(selectSitesUIState, fromSitesUi.getTagsLoading);
export const getError = createSelector(selectSitesUIState, fromSitesUi.getError);

/********************************/
/************ Graphs ************/
/********************************/
export const selectGraphsDbState = createSelector(selectSitesState, (state: SitesState) => {
  return state ? state[fromGraphsDb.graphsDbFeatureKey] : null;
});
export const selectGraphsUiState = createSelector(selectSitesState, (state: SitesState) => {
  return state ? state[fromGraphsUi.graphsUiFeatureKey] : null;
});

export const {
  selectIds: getGraphsIds,
  selectEntities: getGraphsEntities,
  selectAll: getAllGraphs,
  selectTotal: getTotalGraphs
} = fromGraphsDb.adapter.getSelectors(selectGraphsDbState);

// ***** Graph DB
export const getSelectedGraphId = createSelector(selectGraphsDbState, fromGraphsDb.getSelectedGraphId);
export const getSelectedGraph = createSelector(getGraphsEntities, getSelectedGraphId, (entities, selectedId) => selectedId && entities[selectedId]);
// ***** Graph UI
export const getGraphsLoaded = createSelector(selectGraphsUiState, fromGraphsUi.getGraphLoaded);
export const getGraphsLoading = createSelector(selectGraphsUiState, fromGraphsUi.getGraphLoading);

/*********************************/
/****** Spreadsheet Exports ******/
/*********************************/
export const selectSpreadsheetExportsDbState = createSelector(selectSitesState, (state: SitesState) => {
  return state[fromSpreadsheetExportsDb.spreadsheetExportsDbFeatureKey];
});
export const selectSpreadsheetExportsUiState = createSelector(selectSitesState, (state: SitesState) => {
  return state[fromSpreadsheetExportsUi.spreadsheetExportsUiFeatureKey];
});

export const { selectEntities: getAllSpreadsheetExports } = fromSpreadsheetExportsDb.adapter.getSelectors(selectSpreadsheetExportsDbState);

export const { selectEntities: getAllSpreadsheetExportUiIndicators } = fromSpreadsheetExportsUi.adapter.getSelectors(selectSpreadsheetExportsUiState);

export const selectSpreadsheetExportsByContactId = (contactId: string) =>
  createSelector(getAllSpreadsheetExports, (spreadsheetExports) => {
    return spreadsheetExports[contactId];
  });
export const selectSpreadsheetExportLoading = (contactId: string) =>
  createSelector(getAllSpreadsheetExportUiIndicators, (uiIndicators) => {
    return !!uiIndicators[contactId]?.loading;
  });
export const selectSpreadsheetExportLoaded = (contactId: string) =>
  createSelector(getAllSpreadsheetExportUiIndicators, (uiIndicators) => {
    return !!uiIndicators[contactId]?.loaded;
  });
export const selectSpreadsheetExportError = (contactId: string) =>
  createSelector(getAllSpreadsheetExportUiIndicators, (uiIndicators) => {
    return uiIndicators[contactId]?.error;
  });

/********************************/
/************ Contacts ************/
/********************************/
export const selectContactsDbState = createSelector(selectSitesState, (state: SitesState) => {
  return state ? state[fromContactsDb.contactsDbFeatureKey] : null;
});
export const selectContactsUiState = createSelector(selectSitesState, (state: SitesState) => {
  return state ? state[fromContactsUi.contactsUiFeatureKey] : null;
});

export const {
  selectIds: selectContactsIds,
  selectEntities: selectContactsEntities,
  selectAll: selectAllContacts,
  selectTotal: selectTotalContacts
} = fromContactsDb.adapter.getSelectors(selectContactsDbState);

// ***** Contacts DB
export const selectSelectedContactId = createSelector(selectContactsDbState, fromContactsDb.getSelectedContactId);
export const selectSelectedContact = createSelector(
  selectContactsEntities,
  selectSelectedContactId,
  (entities, selectedId) => selectedId && entities[selectedId]
);
// ***** Contacts UI
export const selectContactsLoaded = createSelector(selectContactsUiState, fromContactsUi.getContactsLoaded);
export const selectContactsLoading = createSelector(selectContactsUiState, fromContactsUi.getContactsLoading);
