import { MASTER_VIEW_KEY } from '@iot-platform/models/i4b';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import { SortUtil } from '../../../utils/sort.util';
import * as fromFavoriteViewsApi from './favorite-views-api.reducer';
import * as fromFavoriteViewsUi from './favorite-views-ui.reducer';

export const favoriteViewsFeatureKey = 'favoriteViews';

export interface FavoriteViewsState {
  [fromFavoriteViewsApi.favoriteViewsApiFeatureKey]: fromFavoriteViewsApi.State;
  [fromFavoriteViewsUi.favoriteViewsUiFeatureKey]: fromFavoriteViewsUi.State;
}

export interface State {
  [favoriteViewsFeatureKey]: FavoriteViewsState;
}

export function reducers(state: FavoriteViewsState | undefined, action: Action) {
  return combineReducers({
    [fromFavoriteViewsApi.favoriteViewsApiFeatureKey]: fromFavoriteViewsApi.reducer,
    [fromFavoriteViewsUi.favoriteViewsUiFeatureKey]: fromFavoriteViewsUi.reducer
  })(state, action);
}

export const selectFavoriteViewsState = createFeatureSelector<FavoriteViewsState>(favoriteViewsFeatureKey);

export const selectFavoriteViewsApiState = createSelector(
  selectFavoriteViewsState,
  (state: FavoriteViewsState) => state[fromFavoriteViewsApi.favoriteViewsApiFeatureKey]
);

export const selectFavoriteViewsUiState = createSelector(
  selectFavoriteViewsState,
  (state: FavoriteViewsState) => state[fromFavoriteViewsUi.favoriteViewsUiFeatureKey]
);

export const {
  selectIds: getFavoriteViewsIds,
  selectEntities: getFavoriteViewsEntities,
  selectAll: getAllFavoriteViews,
  selectTotal: getTotalFavoritesViews
} = fromFavoriteViewsApi.adapter.getSelectors(selectFavoriteViewsApiState);

export const getSharedFavoriteViews = createSelector(getAllFavoriteViews, (favoriteViews) =>
  favoriteViews.filter((view) => view.pinned === true && view.shared === true).sort(SortUtil.sortByName)
);

export const getNotSharedFavoriteViews = createSelector(getAllFavoriteViews, (favoriteViews) =>
  favoriteViews.filter((view) => view.pinned === true && view.shared === false).sort(SortUtil.sortByName)
);

export const getFavoriteViewsForMasterViewAssets = createSelector(getAllFavoriteViews, (favoriteViews) =>
  favoriteViews.filter((view) => view.masterView === MASTER_VIEW_KEY.ASSETS)
);

export const getFavoriteViewsForMasterViewSites = createSelector(getAllFavoriteViews, (favoriteViews) =>
  favoriteViews.filter((view) => view.masterView === MASTER_VIEW_KEY.SITES)
);

export const getFavoriteViewsForMasterViewDevices = createSelector(getAllFavoriteViews, (favoriteViews) =>
  favoriteViews.filter((view) => view.masterView === MASTER_VIEW_KEY.DEVICES)
);

export const getFavoriteViewsForMasterViewAssetEvents = createSelector(getAllFavoriteViews, (favoriteViews) =>
  favoriteViews.filter((view) => view.masterView === MASTER_VIEW_KEY.ASSET_EVENTS)
);

export const getFavoriteViewsForMasterViewDeviceEvents = createSelector(getAllFavoriteViews, (favoriteViews) =>
  favoriteViews.filter((view) => view.masterView === MASTER_VIEW_KEY.DEVICE_EVENTS)
);

export const getFavoriteViewsForMasterViewByKey = (key: MASTER_VIEW_KEY) =>
  createSelector(getAllFavoriteViews, (favoriteViews) => favoriteViews.filter((view) => view.masterView === key));

export const getSelectedFavoriteViewIdForMasterViewAssets = createSelector(selectFavoriteViewsUiState, fromFavoriteViewsUi.getCurrentFavoriteViewIdForAssets);

export const getSelectedFavoriteViewForMasterViewAssets = createSelector(
  getFavoriteViewsEntities,
  getSelectedFavoriteViewIdForMasterViewAssets,
  (entities, selectedId) => {
    return selectedId && entities[selectedId];
  }
);

export const getSelectedFavoriteViewIdForMasterViewSites = createSelector(selectFavoriteViewsUiState, fromFavoriteViewsUi.getCurrentFavoriteViewIdForSites);

export const getSelectedFavoriteViewForMasterViewSites = createSelector(
  getFavoriteViewsEntities,
  getSelectedFavoriteViewIdForMasterViewSites,
  (entities, selectedId) => {
    return selectedId && entities[selectedId];
  }
);

export const getSelectedFavoriteViewIdForMasterViewDevices = createSelector(selectFavoriteViewsUiState, fromFavoriteViewsUi.getCurrentFavoriteViewIdForDevices);

export const getSelectedFavoriteViewForMasterViewDevices = createSelector(
  getFavoriteViewsEntities,
  getSelectedFavoriteViewIdForMasterViewDevices,
  (entities, selectedId) => {
    return selectedId && entities[selectedId];
  }
);

export const getSelectedFavoriteViewIdForMasterViewAssetEvents = createSelector(
  selectFavoriteViewsUiState,
  fromFavoriteViewsUi.getCurrentFavoriteViewIdForAssetEvents
);

export const getSelectedFavoriteViewForMasterViewAssetEvents = createSelector(
  getFavoriteViewsEntities,
  getSelectedFavoriteViewIdForMasterViewAssetEvents,
  (entities, selectedId) => {
    return selectedId && entities[selectedId];
  }
);

export const getSelectedFavoriteViewIdForMasterViewDeviceEvents = createSelector(
  selectFavoriteViewsUiState,
  fromFavoriteViewsUi.getCurrentFavoriteViewIdForDeviceEvents
);

export const getSelectedFavoriteViewForMasterViewDeviceEvents = createSelector(
  getFavoriteViewsEntities,
  getSelectedFavoriteViewIdForMasterViewDeviceEvents,
  (entities, selectedId) => {
    return selectedId && entities[selectedId];
  }
);

export const getFiltersForMasterViewAssets = createSelector(selectFavoriteViewsUiState, fromFavoriteViewsUi.getCurrentFiltersForAssets);

export const getFiltersForMasterViewSites = createSelector(selectFavoriteViewsUiState, fromFavoriteViewsUi.getCurrentFiltersForSites);

export const getFiltersForMasterViewDevices = createSelector(selectFavoriteViewsUiState, fromFavoriteViewsUi.getCurrentFiltersForDevices);

export const getFiltersForMasterViewAssetEvents = createSelector(selectFavoriteViewsUiState, fromFavoriteViewsUi.getCurrentFiltersForAssetEvents);

export const getFiltersForMasterViewDeviceEvents = createSelector(selectFavoriteViewsUiState, fromFavoriteViewsUi.getCurrentFiltersForDeviceEvents);

export const getLoading = createSelector(selectFavoriteViewsUiState, fromFavoriteViewsUi.getLoading);

export const getLoaded = createSelector(selectFavoriteViewsUiState, fromFavoriteViewsUi.getLoaded);

export const getError = createSelector(selectFavoriteViewsUiState, fromFavoriteViewsUi.getError);

///////////////////////////////////////////////////
// Favorite views configuration for master views //
///////////////////////////////////////////////////

export const selectSiteFavoriteViewsConfiguration = createSelector(
  getFavoriteViewsForMasterViewSites,
  getSelectedFavoriteViewForMasterViewSites,
  getLoading,
  (siteFVs, currentFV, fVLoading) => {
    return { sortedFavoriteViews: siteFVs.sort(SortUtil.sortByName), currentFavoriteView: currentFV, isFavoriteViewsLoading: fVLoading };
  }
);

export const selectAssetFavoriteViewsConfiguration = createSelector(
  getFavoriteViewsForMasterViewAssets,
  getSelectedFavoriteViewForMasterViewAssets,
  getLoading,
  (siteFVs, currentFV, fVLoading) => {
    return { sortedFavoriteViews: siteFVs.sort(SortUtil.sortByName), currentFavoriteView: currentFV, isFavoriteViewsLoading: fVLoading };
  }
);

export const selectDeviceFavoriteViewsConfiguration = createSelector(
  getFavoriteViewsForMasterViewDevices,
  getSelectedFavoriteViewForMasterViewDevices,
  getLoading,
  (siteFVs, currentFV, fVLoading) => {
    return { sortedFavoriteViews: siteFVs.sort(SortUtil.sortByName), currentFavoriteView: currentFV, isFavoriteViewsLoading: fVLoading };
  }
);

export const selectAssetEventFavoriteViewsConfiguration = createSelector(
  getFavoriteViewsForMasterViewAssetEvents,
  getSelectedFavoriteViewForMasterViewAssetEvents,
  getLoading,
  (siteFVs, currentFV, fVLoading) => {
    return { sortedFavoriteViews: siteFVs.sort(SortUtil.sortByName), currentFavoriteView: currentFV, isFavoriteViewsLoading: fVLoading };
  }
);

export const selectDeviceEventFavoriteViewsConfiguration = createSelector(
  getFavoriteViewsForMasterViewDeviceEvents,
  getSelectedFavoriteViewForMasterViewDeviceEvents,
  getLoading,
  (siteFVs, currentFV, fVLoading) => {
    return { sortedFavoriteViews: siteFVs.sort(SortUtil.sortByName), currentFavoriteView: currentFV, isFavoriteViewsLoading: fVLoading };
  }
);
