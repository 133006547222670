<div [ngClass]="color" class="popup">
  <div class="popup-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="popup-title">{{ popup?.title }}</h1>
    <button (click)="close(false)" *ngIf="data.type === 'delete' || 'reset' || 'subscribe' || 'unsubscribe'" mat-icon-button><mat-icon>close</mat-icon></button>
  </div>


  <div *ngIf="data.type !== 'subscribe' && data.type !== 'unsubscribe'" class="popup-content" fxLayout="column" fxLayoutAlign="start ">
    <span class="popup-value">{{ message| translate }}</span>
    <span class="popup-message">{{ popup?.message }}</span>
  </div>

  <div *ngIf="data.type === 'subscribe' || data.type === 'unsubscribe'" class="popup-content" fxLayout="column" fxLayoutAlign="center center">
    <span class="popup-message">{{ popup?.message }}</span>
    <div class="topic-name" fxLayout="row">
      <mat-icon [svgIcon]="'subscribe'" class="icon"></mat-icon>
      <span class="popup-value">{{ data.value | translate }}</span>
    </div>
  </div>

  <div class="popup-footer" fxLayout="row" fxLayoutAlign="end center">
    <ng-container *ngIf="data.type === 'delete'">
      <button (click)="close(false)" class="popup-cancel" mat-button>{{ popup?.cancel }}</button>
      <button (click)="close(true)" class="popup-delete" mat-button>{{ popup?.delete }}</button>
    </ng-container>
    <ng-container *ngIf="data.type === 'subscribe'">
      <button (click)="close(false)" class="popup-cancel" mat-button>{{ popup?.cancel }}</button>
      <button (click)="close(true)" class="popup-subscribe" mat-button>{{ popup?.subscribe }}</button>
    </ng-container>
    <ng-container *ngIf="data.type === 'unsubscribe'">
      <button (click)="close(false)" class="popup-cancel" mat-button>{{ popup?.cancel }}</button>
      <button (click)="close(true)" class="popup-unsubscribe" mat-button>{{ popup?.unsubscribe }}</button>
    </ng-container>
    <ng-container *ngIf="data.type === 'reset'">
      <button (click)="close(false)" class="popup-cancel" mat-button>{{ popup?.cancel }}</button>
      <button (click)="close(true)" class="popup-reset" mat-button>{{ popup?.reset }}</button>
    </ng-container>
    <ng-container *ngIf="data.type === 'info'">
      <button (click)="close(false)" class="popup-cancel" mat-button>{{ popup?.cancel }}</button>
      <button (click)="close(true)" class="popup-info" mat-button>{{ popup?.confirm }}</button>
    </ng-container>
    <ng-container *ngIf="data.type === 'confirm'">
      <button (click)="close(false)" class="popup-cancel" mat-button>{{ popup?.cancel }}</button>
      <button (click)="close(true)" class="popup-confirm" mat-button>{{ popup?.confirm }}</button>
    </ng-container>
    <ng-container *ngIf="data.type === 'error'">
      <button (click)="close(false)" class="popup-close" mat-button>{{ popup?.close }}</button>
    </ng-container>
  </div>
</div>
