import { CommonApiRequest, CommonApiResponse, CommonIndexedPagination, Filter } from '@iot-platform/models/common';
import { Receiver } from '@iot-platform/models/oyan';
import { createAction, props } from '@ngrx/store';

export const loadReceivers = createAction('[OYAN RECEIVERS/UI] Load Receivers', props<{ request: CommonApiRequest }>());
export const loadReceiversSuccess = createAction(
  '[OYAN RECEIVERS/API] Load Receivers Success',
  props<{ response: CommonApiResponse<Receiver, CommonIndexedPagination> }>()
);
export const loadReceiversFailure = createAction('[OYAN RECEIVERS/API] Load Receivers Failure', props<{ error: unknown }>());

export const loadReceiverById = createAction('[OYAN RECEIVERS/UI] Load Receiver By Id', props<{ id: string }>());
export const loadReceiverByIdSuccess = createAction('[OYAN RECEIVERS/API] Load Receiver By Id Success', props<{ response: Receiver }>());
export const loadReceiverByIdFailure = createAction('[OYAN RECEIVERS/API] Load Receiver By Id Failure', props<{ error: unknown }>());

export const setFilters = createAction('[OYAN RECEIVERS/UI] Set filters', props<{ filters: Filter[] }>());
