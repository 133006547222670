import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  DeviceCommandsStatuses,
  DeviceCommandStatus,
  DeviceLastCommandErrorStatus,
  DeviceLastCommandStepStatus,
  LastCommandStatusStates
} from '@iot-platform/models/common';
import { Device } from '@iot-platform/models/i4b';
import { DateFormatModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { of, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DevicesService } from '../../../../../../../shared/src/lib/services/devices.service';

interface CustomCommandStatus {
  deviceId: string;
  commandName: string;
  commandStatus: DeviceCommandStatus;
  iconToDisplay?: { name: string; className: string };
  state?: string;
}
@Component({
  selector: 'iot4bos-ui-device-latest-command-status',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, MatProgressSpinnerModule, MatIconModule, DateFormatModule, TranslateModule, FlexLayoutModule],
  templateUrl: './device-latest-command-status.component.html',
  styleUrls: ['./device-latest-command-status.component.scss']
})
export class DeviceLatestCommandStatusComponent implements OnInit, OnChanges, OnDestroy {
  @Input() device?: Device;

  mostRecentCommand: CustomCommandStatus | null = null;

  stepList: string[] = Object.values(DeviceLastCommandStepStatus);
  errorList: string[] = Object.values(DeviceLastCommandErrorStatus);

  tooltipMessage!: string;
  commandRefreshTimer = 5000;
  commandsStatusTrigger$: Subject<string> = new Subject();

  subs: Subscription[] = [];

  constructor(private readonly devicesService: DevicesService, private readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.subs.push(
      this.commandsStatusTrigger$
        .pipe(
          switchMap((deviceId) => {
            if (deviceId === this.mostRecentCommand?.deviceId) {
              return this.devicesService.getById(deviceId);
            } else {
              return of(null);
            }
          })
        )
        .subscribe((device) => this.onCommandStatusChange(device))
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.device?.currentValue) {
      if (changes.device.currentValue.commandsStatuses) {
        this.onCommandStatusChange(changes.device.currentValue);
      }
      this.tooltipMessage = this.getTooltipMessage();
    } else {
      this.mostRecentCommand = null;
    }
  }

  setMostRecentCommand(commands: DeviceCommandsStatuses): void {
    if (commands) {
      this.mostRecentCommand = null;
      const commandsEntries: Array<[string, DeviceCommandStatus]> = Object.entries(commands);

      this.mostRecentCommand = commandsEntries.reduce((currentMostRecentCommand: CustomCommandStatus | null, value: [string, DeviceCommandStatus]) => {
        if (
          (!currentMostRecentCommand && value[1]?.status && value[1]?.timestamp) ||
          value[1]?.timestamp > currentMostRecentCommand?.commandStatus?.timestamp
        ) {
          currentMostRecentCommand = { deviceId: this.device?.id as string, commandName: value[0], commandStatus: { ...value[1] } };

          if (this.errorList.includes(currentMostRecentCommand.commandStatus?.status)) {
            currentMostRecentCommand.state = LastCommandStatusStates.ERROR;
          } else if (currentMostRecentCommand.commandStatus?.status === DeviceLastCommandStepStatus.COMPLETED_COMMAND_PROCESSING) {
            currentMostRecentCommand.state = LastCommandStatusStates.COMPLETED;
          } else {
            currentMostRecentCommand.state = LastCommandStatusStates.ONGOING;
          }

          currentMostRecentCommand.iconToDisplay = this.getIconToDisplayByState(currentMostRecentCommand.state);
        }
        return currentMostRecentCommand;
      }, this.mostRecentCommand);
      this.cdr.detectChanges();
    }
  }

  onCommandStatusChange(device: Device): void {
    this.setMostRecentCommand(device?.commandsStatuses ?? {});
    if (
      this.mostRecentCommand &&
      !this.errorList.includes(this.mostRecentCommand.commandStatus.status) &&
      this.mostRecentCommand.commandStatus.status !== DeviceLastCommandStepStatus.COMPLETED_COMMAND_PROCESSING
    ) {
      setTimeout(() => this.commandsStatusTrigger$.next(device.id), this.commandRefreshTimer);
    }
  }

  getIconToDisplayByState(state: string): { name: string; className: string } {
    let icon: { name: string; className: string } = { name: '', className: '' };
    switch (state) {
      case LastCommandStatusStates.ONGOING:
        icon = null;
        break;
      case LastCommandStatusStates.ERROR:
        icon = { name: 'warning', className: 'error' };
        break;
      case LastCommandStatusStates.COMPLETED:
        icon = { name: 'done', className: 'completed' };
        break;
      default:
        break;
    }
    return icon;
  }

  getTooltipMessage(): string {
    if (this.device?.lastCommandStatus?.name === 'failure') {
      return this.device.lastCommandStatus.commandErrorReason ?? this.device.lastCommandStatus.status;
    }
    return '';
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
