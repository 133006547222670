<div fxLayout="row" fxLayoutAlign="start center">
  <div fxLayout="row" fxLayoutAlign="start center" class='customHeaderLabel'>
    <ng-content></ng-content>
  </div>
  <div *ngIf="params?.enableSorting" fxLayout="row">
    <div (click)="onSortRequested('asc', $event)" [ngClass]="ascSort" class="customSortDownLabel">
      <mat-icon class="icon">arrow_upward</mat-icon>
    </div>
    <div (click)="onSortRequested('desc', $event)" [ngClass]="descSort" class="customSortUpLabel">
      <mat-icon class="icon">arrow_downward</mat-icon>
    </div>
    <div *ngIf="sorted !== ''" (click)="onSortRequested('', $event)" [ngClass]="noSort" class="customSortRemoveLabel">
      <mat-icon class="icon">clear</mat-icon>
    </div>
  </div>
</div>
