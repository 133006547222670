<oyan-ui-simple-card class='card' [element]='element' [withMenu]='withMenu' [icon]='icon' [cssClasses]='cssClasses'>
  <div cardContent fxLayout='column'>

    <h3 class='card__title'> {{ element?.label | translate | infoDisplay }} </h3>

    <div class='threshold-card__form' fxLayout='row' fxLayoutAlign='start center'>

      <mat-form-field fxFlex color='accent'>

        <mat-select [formControl]='control'
                    [placeholder]='element?.placeholder | translate'
                    [required]='element?.required'
                    (selectionChange)='onSelectionChange($event?.value)'>
          <mat-option [value]='threshold' *ngFor='let threshold of thresholdDefinitions[element?.type]'>{{ threshold.displayValue }}</mat-option>
        </mat-select>

        <mat-error
          *ngIf='control?.errors?.required'>{{ 'SITES.CONFIGURATIONS.CARD.REQUIRED_ERROR_MESSAGE' | translate }}</mat-error>

      </mat-form-field>

    </div>
  </div>
</oyan-ui-simple-card>
