import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MasterViewEngineEvent } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'i4b-table-engine-button-cell',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatTooltipModule, TranslateModule, NgIf, FlexLayoutModule],
  template: `
    <button
      class="button-cell"
      *ngIf="cellOptions?.svgIcon"
      mat-icon-button
      fxLayout="row"
      fxLayoutAlign="center center"
      [matTooltipDisabled]="cellOptions?.tooltipDisabled"
      [matTooltip]="cellOptions?.tooltip || '' | translate"
      matTooltipPosition="above"
      matTooltipClass="regular-tooltip"
      (click)="$event.preventDefault(); $event.stopPropagation(); onClick()"
    >
      <mat-icon *ngIf="cellOptions?.svgIcon" [svgIcon]="cellOptions?.svgIcon"></mat-icon>
    </button>
    <button
      class="button-cell"
      *ngIf="!cellOptions?.svgIcon && (cellOptions?.label || data)"
      mat-stroked-button
      fxLayout="row"
      fxLayoutAlign="center center"
      [matTooltipDisabled]="cellOptions?.tooltipDisabled"
      [matTooltip]="cellOptions?.tooltip || data || '' | translate"
      matTooltipPosition="above"
      matTooltipClass="regular-tooltip"
      (click)="$event.preventDefault(); $event.stopPropagation(); onClick()"
    >
      <span class="label" *ngIf="cellOptions?.label || data" [style.width]="(cellOptions?.labelWidth || 5) + 'rem'">{{
        cellOptions?.label || data | translate
      }}</span>
    </button>
    <span *ngIf="!cellOptions?.svgIcon && !cellOptions?.label && !data">-</span>
  `,
  styles: [
    `
      .button-cell {
        height: 30px;
        padding: 0 0.5rem;
      }

      .label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        font-size: 0.8rem;
      }
    `
  ]
})
export class ButtonCellComponent {
  @Input() data: any;
  @Input() rawData: any;
  @Input() clickEvent!: MasterViewEngineEvent;
  @Input() cellOptions!: { tooltip?: string; svgIcon?: string; label?: string; labelWidth?: number };

  @Output() dispatchEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();

  onClick(): void {
    this.dispatchEvent.emit({
      type: this.clickEvent.type,
      options: this.cellOptions,
      rawData: this.rawData
    });
  }
}
