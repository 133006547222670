import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';
import { PreferencesActions } from '../../../../../../../users/src/lib/features/preferences/state/actions';
import {
  UserProfileShellComponent as I4bUserProfileShellComponent
} from '../../../../../../../users/src/lib/features/profile/containers/user-profile-shell/user-profile-shell.component';
import {
  UserPreferencesFormDialogComponent
} from '../../components/user-preferences-form-dialog/user-preferences-form-dialog.component';

@Component({
  selector: 'oyan-ui-user-profile-shell',
  templateUrl: './user-profile-shell.component.html',
  styleUrls: [
    '../../../../../../../users/src/lib/features/profile/containers/user-profile-shell/user-profile-shell.component.scss',
    './user-profile-shell.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileShellComponent extends I4bUserProfileShellComponent {
  withNotifications = false;

  constructor(protected store: Store, protected dialog: MatDialog) {
    super(store, dialog);
  }

  changeUserPreferences(): void {
    this.currentUser$
      .pipe(
        take(1),
        switchMap((user: User) => {
          return this.dialog
            .open(UserPreferencesFormDialogComponent, {
              width: '700px',
              disableClose: true,
              data: {
                user: user
              }
            })
            .afterClosed();
        })
      )
      .subscribe((updatedUser: User) => {
        if (updatedUser) {
          this.store.dispatch(PreferencesActions.saveUserPreferences({ user: updatedUser, preferences: updatedUser.preferences }));
        }
      });
  }
}
