import { createReducer, on } from '@ngrx/store';
import { PreferencesActions } from '../actions';

export const userPreferencesUiFeatureKey = 'userPreferencesUi';

export interface State {
  loading: boolean;
  error: any;
}

export const initialState: State = {
  loading: null,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(PreferencesActions.loadUserPreferences, (state: State) => ({ ...state, loading: true })),
  on(PreferencesActions.loadUserPreferencesSuccess, (state: State, preferences) => ({ ...state, loading: false })),
  on(PreferencesActions.loadUserPreferencesFailure, (state: State, error) => ({ ...state, loading: false, error: error }))
);

export const getError = (state: State) => state.error;
export const getLoading = (state: State) => state.loading;
