<mat-card-title>
  <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center">
    <h2 class="panel-title" fxFlex fxLayout="row" fxLayoutGap="10px">
      <span> {{ 'ADMIN.BUSINESS_PROFILES.TABS.GRID_MANAGER' | translate | uppercase }} </span>
    </h2>
    <button
      *ngIf="canUpdateBusinessProfile"
      [matTooltip]="'ADMIN.BUSINESS_PROFILES.GRID_MANAGER.ADD_GRID' | translate"
      matTooltipClass="regular-tooltip"
      matTooltipPosition="below"
      mat-icon-button
      class="regular-round-button"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon>add</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        *ngFor="let masterView of masterViewList"
        (click)="addGrid.emit(masterView)"
        class=""
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="6px"
      >
        <mat-icon [svgIcon]="masterView" [color]="'#394C5A'"></mat-icon>
        <span>{{ 'IOT_DICTIONARY.' + masterView | translate }}</span>
      </button>
    </mat-menu>
  </mat-toolbar>
</mat-card-title>

<div>
  <i4b-table-engine-master-view-engine
    [masterViewType]="'grid-manager'"
    [useExternalData]="true"
    [useFullyLoadedDataset]="true"
    [data]="grids"
    [userPermissions]="userPermissions"
    (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
    (pageChange)="changeGridPage.emit($event)"
  >
  </i4b-table-engine-master-view-engine>
</div>
