import { Component, Input, OnInit } from '@angular/core';
import { Filter } from '@iot-platform/models/common';
import { Observable } from 'rxjs';
import { TimezoneAutocompleteComponent } from '../timezone-autocomplete/timezone-autocomplete.component';

type Timezone = { key: string; value: string };

@Component({
  selector: 'iot-platform-ui-timezone-autocomplete-multiple-selects',
  templateUrl: './timezone-autocomplete-multiple-selects.component.html',
  styleUrls: ['./timezone-autocomplete-multiple-selects.component.scss']
})
export class TimezoneAutocompleteMultipleSelectsComponent extends TimezoneAutocompleteComponent implements OnInit {
  @Input() data: any = [];
  @Input() currentFilters$!: Observable<Filter[]>;
  @Input() currentFiltersSize: number = 0;
  @Input() maxFilters: number = 20;

  ngOnInit(): void {
    super.ngOnInit();
  }
}
