import { AfterContentInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Entity, ProductCatalog } from '@iot-platform/models/common';

import { select, Store } from '@ngrx/store';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { EntitiesService } from '../../../../../../../shared/src/lib/entities.service';
import { ProductCatalogsUiActions } from '../../state/actions';
import * as fromProducts from '../../state/reducers';

@Component({
  selector: 'iot4bos-backoffice-ui-admin-catalogs-edition-popup',
  templateUrl: './admin-catalogs-edition-popup.component.html',
  styleUrls: ['./admin-catalogs-edition-popup.component.scss']
})
export class AdminCatalogsEditionPopupComponent implements OnInit, OnDestroy, AfterContentInit {
  catalogs$: Observable<ProductCatalog[]>;
  canClose = false;

  areCatalogsSaved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  newCatalog: ProductCatalog;

  entities$: Observable<Entity[]>;
  entities: Entity[];

  subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<AdminCatalogsEditionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { canUpdate: boolean; canDelete: boolean },
    private store: Store<fromProducts.State>,
    private entitiesService: EntitiesService
  ) {}

  ngOnInit(): void {
    this.catalogs$ = this.store.pipe(select(fromProducts.getAllCatalogs));
    this.subscriptions.push(this.catalogs$.subscribe((catalogs) => this.areCatalogsSaved$.next(true)));

    this.newCatalog = { name: '', entities: [] };

    this.areCatalogsSaved$.subscribe((value) => (this.canClose = value));
  }

  ngAfterContentInit() {
    this.entitiesService.getHierarchicallySortedEntities().subscribe((entities) => (this.entities = entities));
  }

  onChangeValue(value: boolean): void {
    this.areCatalogsSaved$.next(value);
  }

  onAddCatalog(productCatalogToAdd: ProductCatalog) {
    this.store.dispatch(ProductCatalogsUiActions.addProductCatalog({ productCatalogToAdd }));
  }

  onUpdateCatalog(productCatalogToUpdate: ProductCatalog) {
    this.store.dispatch(ProductCatalogsUiActions.updateProductCatalog({ productCatalogToUpdate }));
  }

  onDeleteCatalog(productCatalogToDelete: ProductCatalog) {
    this.store.dispatch(ProductCatalogsUiActions.deleteProductCatalog({ productCatalogToDelete }));
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
