<section class='site-gateways'>
  <iot4bos-ui-panel
    [title]="'SITES.GATEWAYS.PANEL_TITLE' | translate"
    [hasButton]='false'
    [withCustomAction]='true'
    [buttonTooltip]="'SITES.GATEWAYS.ADD' | translate"
  >
    <div panelCustomAction fxFlexLayout='row' fxLayoutAlign='center center'>
      <div *ngIf="gatewaysBySite?.length > 2 || (gatewaysBySite?.length > 1 && hasGateways && canAddGateway)" class="panel-button app-round-button" [matTooltip]="(horizontalLayout ? 'PANELS.TOOLTIPS.HORIZONTAL_LAYOUT': 'PANELS.TOOLTIPS.VERTICAL_LAYOUT') | translate" matTooltipClass="regular-tooltip">
        <button mat-icon-button fxHide.lt-md (click)="horizontalLayout = !horizontalLayout">
          <mat-icon>{{ horizontalLayout ? 'apps' : 'swap_horiz' }}</mat-icon>
        </button>
      </div>
      <div *ngIf=" !loading && canUpdateSite && canAddGateway && hasGateways" class="panel-button app-round-button" [matTooltip]="'SITES.GATEWAYS.ADD' | translate" matTooltipClass="regular-tooltip">
        <ng-container *ngTemplateOutlet="gatewaysContextMenu"></ng-container>
      </div>
      <div class="panel-button app-round-button site-gateways__btn-sync" *ngIf='canUpdateSite' [ngClass]="{ 'app-round-button--rotate': loading }" [matTooltip]="'SITES.GATEWAYS.SYNCHRONIZE' | translate" matTooltipClass="regular-tooltip">
        <button mat-icon-button (click)='onSyncGateways()'>
          <mat-icon>sync</mat-icon>
        </button>
      </div>
    </div>

    <ng-container panelGrid *ngIf="!loading && !hasAssignedGateways && !hasGateways; else gatewaysTmpl">
      <ng-container *ngTemplateOutlet="emptyGatewaysTmp"></ng-container>
    </ng-container>

  </iot4bos-ui-panel>
</section>

<ng-template #gatewaysTmpl>
  <div panelGrid *ngIf="loading" fxFlexLayout='row' fxLayoutAlign='center center' style='width: 100%'>
    <mat-progress-spinner color='accent' mode='indeterminate' [diameter]='50'></mat-progress-spinner>
  </div>

  <oyan-ui-cards-container panelGrid [horizontalLayout]="horizontalLayout" *ngIf="!loading">
    <oyan-ui-gateway-card [withMenu]='canUpdateSite && canDeleteGateway' class='card-md' fxFlex.lt-md="100%"
                          icon='gateway' cssClasses='bg-primary'
                          *ngFor='let gateway of gatewaysBySite' [element]='gateway'
                          (dispatchEvent)="onDispatchEvent($event)"
    ></oyan-ui-gateway-card>

    <iot4bos-ui-default-card *ngIf="canUpdateSite && canAddGateway && hasGateways" class='card-md' fxFlex.lt-md="100%"
                             [withCustomButton]="true"
                             [backgroundClass]="'h-100'"
                             [title]="'SITES.GATEWAYS.ADD' | translate"
    >
      <ng-container cardCustomButton *ngTemplateOutlet="gatewaysContextMenu"></ng-container>

    </iot4bos-ui-default-card>
  </oyan-ui-cards-container>
</ng-template>

<ng-template #gatewaysContextMenu>
  <oyan-ui-mat-menu-list *ngIf='hasGateways'
    [data]='filteredGateways$ | async'
    displayKey='name'
    placeholder="SITES.GATEWAYS.FILTER_GATEWAYS"
    noDataMessage="SITES.GATEWAYS.NO_GATEWAYS"
    (search)="onApplyFilter($event)"
    (clear)="onClearFilter()"
    (selectItem)='onAddGateway($event)'
  ></oyan-ui-mat-menu-list>
</ng-template>

<ng-template #emptyGatewaysTmp>
  <div class="no-data-message" fxFlexLayout='row' fxFlexAlign='center center'>
    <p>{{ 'SITES.GATEWAYS.NO_GATEWAYS' | translate }}</p>
  </div>
</ng-template>
