import { NgModule } from '@angular/core';
import { CardLoaderModule, ChipModule, DetailPopupModule, EventSeverityDisplayModule, IconModule } from '@iot-platform/iot-platform-ui';
import { SharedModule } from '@iot-platform/shared';
import { DetailPopupCardsModule } from '../../../../../iot-platform-ui/src/lib/ui/components/detail-popup-cards/detail-popup-cards.module';
import { EventCommentsModule } from '../event-comments/event-comments.module';
import { EventDetailPopupComponent } from './event-detail-popup.component';
import { EventTimelineLogComponent } from './event-timeline-log/event-timeline-log.component';
import { EventTimelineComponent } from './event-timeline/event-timeline.component';
import { EventTopicListComponent } from './event-topics/event-topic-list.component';
import { IotPlatformPipesModule } from '@iot-platform/pipes';

@NgModule({
  imports: [
    SharedModule,
    DetailPopupModule,
    ChipModule,
    CardLoaderModule,
    IconModule,
    IotPlatformPipesModule,
    DetailPopupCardsModule,
    EventCommentsModule,
    EventSeverityDisplayModule
  ],
  declarations: [EventDetailPopupComponent, EventTimelineComponent, EventTimelineLogComponent, EventTopicListComponent]
})
export class EventDetailPopupModule {}
