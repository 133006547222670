import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'infoDisplay' })
export class InfoDisplayPipe implements PipeTransform {
  transform(value: any, symbol?: string, ...keys: string[]): string | number {
    if (!value && value !== 0) {
      return '-';
    } else if ((value && keys.length === 0) || value === 0) {
      return value;
    } else if (value && keys.length === 2) {
      if (value[keys[0]] && value[keys[1]]) {
        return this.getValueWithSymbol(symbol, value[keys[0]], value[keys[1]]);
      } else if (value[keys[0]] || value[keys[1]]) {
        return value[keys[0]] ? value[keys[0]] : value[keys[1]];
      } else {
        return '-';
      }
    } else if (value && keys.length > 2) {
      const returned = keys.reduce((acc, key) => `${acc} ${value[key] ? value[key] : ''} ${value[key] ? InfoDisplayPipe.getSymbol(symbol) : ''}`, '');
      return returned.substring(0, returned.lastIndexOf('-'));
    }
    return '';
  }

  private static getSymbol(symbol: string | undefined) {
    switch (symbol) {
      case 'pipe':
        return '|';
      case 'hyphen':
        return '-';
      default:
        return symbol;
    }
  }
  private getValueWithSymbol(symbol?: string, ...values: Array<string | number>): string {
    switch (symbol) {
      case 'pipe':
        return `${values[0]} | ${values[1]}`;
      case 'hyphen':
        return `${values[0]} - ${values[1]}`;
      case 'brackets':
        return `${values[0]} (${values[1]})`;
      default:
        return `${values[0]} ${values[1]}`;
    }
  }
}
