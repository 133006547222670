import { Injectable } from '@angular/core';
import { ApiSelector, Filter, Pagination } from '@iot-platform/models/common';
import { Message } from '@iot-platform/models/oyan';
import * as fromMain from '../reducers';
import * as fromMessages from '../reducers/messages.reducer';

@Injectable({
  providedIn: 'root'
})
export class MessagesSelectors extends ApiSelector<fromMain.State, Message, Pagination, Filter> {
  constructor() {
    super(fromMain.featureKey, fromMessages.featureKey, fromMessages.adapter);
  }
}
