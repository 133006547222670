<mat-grid-list cols="{{ colors.length }}" gutterSize="5px" class="color-picker">
  <mat-grid-tile
    colspan="1"
    rowspan="1"
    *ngFor="let color of colors"
    [style.background]="color"
    (click)="onSelect(color)"
    [class.color-selected]="color === selectedColor"
  >
  </mat-grid-tile>
</mat-grid-list>
