<mat-nav-list class="two-line-list" [disableRipple]="true">
  <mat-list-item
    class="hoverable"
    *ngFor="let profile of profiles"
    [ngClass]="{ selected: selectedBusinessProfile && profile ? selectedBusinessProfile.id === profile.id : false }"
    (click)="onSelectBusinessProfileButtonClick(profile)"
  >
    <!--<iot-platform-ui-icon matListIcon style="font-size: 24px" [name]="'business_profile'" [color]="'#394c5a'"></iot-platform-ui-icon>-->
    <mat-icon matListIcon style="font-size: 24px" [svgIcon]="'business_profile'"></mat-icon>
    <h3 matLine>{{ profile.name }}</h3>
    <button mat-icon-button [matMenuTriggerFor]="actionMenu" *ngIf="canDeleteBusinessProfile || canUpdateBusinessProfile" class="bp-call-to-action" (click)="$event.stopPropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>
    <p matLine class="complementary-line">
      <span>{{ profile.entityName }}</span>
    </p>

    <mat-menu #actionMenu="matMenu">
      <button mat-menu-item (click)="selectBusinessProfile.emit(profile)" *ngIf="canUpdateBusinessProfile">
        <span> {{ 'IOT_DICTIONARY.EDIT' | translate | uppercase }}</span>
      </button>
      <div *ngIf="canDeleteBusinessProfile" [matTooltip]="getTooltip(profile)" matTooltipClass="regular-tooltip" [matTooltipDisabled]="isDeletePossible(profile)">
        <button mat-menu-item (click)="onDeleteBusinessProfileButtonClick(profile)" [disabled]="!isDeletePossible(profile)">
          <span> {{ 'IOT_DICTIONARY.DELETE' | translate | uppercase }}</span>
        </button>
      </div>
    </mat-menu>
  </mat-list-item>
</mat-nav-list>
