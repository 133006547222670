import { Action, createReducer, on } from '@ngrx/store';

import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';
import { DeviceEventsDbActions, DeviceEventsUiActions } from '../../../device-events/+state/actions';

import { AssetEventsDbActions, AssetEventsLogsDbActions, AssetEventsLogsUiActions, AssetEventsUiActions } from '../actions';

export const assetEventsUiFeatureKey = 'assetEventsUi';

export interface State {
  error?: any;
  assetEventsLoaded: boolean;
  assetEventsLoading: boolean;
  siteLoading?: boolean;
  siteLoaded?: boolean;
  assetLoaded?: boolean;
  assetLoading?: boolean;
  assetVarLoaded?: boolean;
  assetVarLoading?: boolean;
  deviceLoaded?: boolean;
  deviceLoading?: boolean;
  deviceVarLoaded?: boolean;
  deviceVarLoading?: boolean;
  tagsLoaded?: boolean;
}

export const initialState: State = {
  error: null,
  assetEventsLoaded: false,
  assetEventsLoading: false,
  siteLoading: false,
  siteLoaded: false,
  assetLoaded: false,
  assetLoading: false,
  assetVarLoaded: false,
  assetVarLoading: false,
  deviceLoaded: false,
  deviceLoading: false,
  deviceVarLoaded: false,
  deviceVarLoading: false,
  tagsLoaded: false
};

const assetEventsUiReducer = createReducer(
  initialState,
  on(AssetEventsUiActions.loadAssetEvents, (state: State) => ({ ...state, assetEventsLoading: true, assetEventsLoaded: false })),
  on(AssetEventsDbActions.loadAssetEventsSuccess, (state: State) => ({ ...state, assetEventsLoading: false, assetEventsLoaded: true })),
  on(AssetEventsDbActions.loadAssetEventsFailure, (state: State, { error }) => ({ ...state, assetEventsLoading: false, assetEventsLoaded: true, error })),
  // ****
  on(AssetEventsUiActions.loadMVSettings, (state: State) => ({ ...state, assetEventsLoading: true, assetEventsLoaded: false })),
  on(AssetEventsDbActions.loadMVSettingsSuccess, (state: State) => ({ ...state, assetEventsLoading: false, assetEventsLoaded: true })),
  on(AssetEventsDbActions.loadMVSettingsFailure, (state: State, { error }) => ({ ...state, assetEventsLoading: false, assetEventsLoaded: true, error })),
  // ****
  on(AssetEventsUiActions.saveMVSettings, (state: State) => ({ ...state, assetEventsLoading: true, assetEventsLoaded: false })),
  on(AssetEventsDbActions.saveMVSettingsSuccess, (state: State) => ({ ...state, assetEventsLoading: false, assetEventsLoaded: true })),
  on(AssetEventsDbActions.saveMVSettingsFailure, (state: State, { error }) => ({ ...state, assetEventsLoading: false, assetEventsLoaded: true, error })),
  // ****
  on(AssetEventsUiActions.loadSiteById, (state: State) => ({ ...state, siteLoading: true, siteLoaded: false })),
  on(AssetEventsDbActions.loadSiteByIdSuccess, (state: State) => ({ ...state, siteLoading: false, siteLoaded: true })),
  on(AssetEventsDbActions.loadSiteByIdFailure, (state: State, { error }) => ({ ...state, siteLoading: false, siteLoaded: true, error })),
  // ****
  on(AssetEventsUiActions.loadAssetById, (state: State) => ({ ...state, assetLoading: true, assetLoaded: false })),
  on(AssetEventsDbActions.loadAssetByIdSuccess, (state: State) => ({ ...state, assetLoading: false, assetLoaded: true })),
  on(AssetEventsDbActions.loadAssetByIdFailure, (state: State, { error }) => ({ ...state, assetLoading: false, assetLoaded: true, error })),
  // ****
  on(AssetEventsUiActions.loadDeviceById, (state: State) => ({ ...state, deviceLoading: true, deviceLoaded: false })),
  on(AssetEventsDbActions.loadDeviceByIdSuccess, (state: State) => ({ ...state, deviceLoading: false, deviceLoaded: true })),
  on(AssetEventsDbActions.loadDeviceByIdFailure, (state: State, { error }) => ({ ...state, deviceLoading: false, deviceLoaded: true, error })),
  // ****
  on(AssetEventsUiActions.loadDeviceVariableById, (state: State) => ({ ...state, deviceVarLoading: true, deviceVarLoaded: false })),
  on(AssetEventsDbActions.loadDeviceVariableByIdSuccess, (state: State) => ({ ...state, deviceVarLoading: false, deviceVarLoaded: true })),
  on(AssetEventsDbActions.loadDeviceVariableByIdFailure, (state: State, { error }) => ({ ...state, deviceVarLoading: false, deviceVarLoaded: true, error })),
  // ****
  on(AssetEventsUiActions.loadAssetVariableById, (state: State) => ({ ...state, assetVarLoading: true, assetVarLoaded: false })),
  on(AssetEventsDbActions.loadAssetVariableByIdSuccess, (state: State) => ({ ...state, assetVarLoading: false, assetVarLoaded: true })),
  on(AssetEventsDbActions.loadAssetVariableByIdFailure, (state: State, { error }) => ({ ...state, assetVarLoading: false, assetVarLoaded: true, error })),
  // ****
  on(AssetEventsLogsDbActions.loadLogsByAssetEventIdSuccess, (state: State) => ({ ...state, assetEventsLoaded: true })),
  on(AssetEventsLogsDbActions.loadLogsByAssetEventIdFailure, (state: State, { error }) => ({ ...state, assetEventsLoaded: true, error })),
  // ****
  on(AssetEventsUiActions.loadTagsByAssetEventId, (state: State) => ({ ...state })),
  on(AssetEventsDbActions.loadTagsByAssetEventIdSuccess, (state: State) => ({ ...state, tagsLoaded: true })),
  on(AssetEventsDbActions.loadTagsByAssetEventIdFailure, (state: State, { error }) => ({ ...state, tagsLoaded: true, error })),
  // ****
  on(AssetEventsLogsUiActions.createLogByAssetEventId, (state: State) => ({ ...state })),
  on(AssetEventsLogsDbActions.createLogByAssetEventIdSuccess, (state: State) => ({ ...state, assetEventsLoaded: true })),
  on(AssetEventsLogsDbActions.createLogByAssetEventIdFailure, (state: State, { error }) => ({ ...state, assetEventsLoaded: true, error })),
  // ****
  on(AssetEventsUiActions.bulkUpdateStatusByAssetEventId, (state: State) => ({ ...state, assetEventsLoaded: false })),
  on(AssetEventsDbActions.updateStatusByAssetEventIdSuccess, (state: State) => ({ ...state, assetEventsLoaded: true })),
  on(AssetEventsDbActions.updateStatusByAssetEventIdFailure, (state: State, { error }) => ({ ...state, assetEventsLoaded: true, error })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsUiReducer(state, action);
}

export const getAssetEventsLoaded = (state: State) => state.assetEventsLoaded;
export const getAssetEventsLoading = (state: State) => state.assetEventsLoading;
export const getSiteLoaded = (state: State) => state.siteLoaded;
export const getSiteLoading = (state: State) => state.siteLoading;
export const getAssetLoaded = (state: State) => state.assetLoaded;
export const getAssetLoading = (state: State) => state.assetLoading;
export const getAssetVariableLoaded = (state: State) => state.assetVarLoaded;
export const getAssetVariableLoading = (state: State) => state.assetVarLoading;
export const getDeviceLoaded = (state: State) => state.deviceLoaded;
export const getDeviceLoading = (state: State) => state.deviceLoading;
export const getDeviceVariableLoaded = (state: State) => state.deviceVarLoaded;
export const getDeviceVariableLoading = (state: State) => state.deviceVarLoading;
export const getTagsLoaded = (state: State) => state.tagsLoaded;
