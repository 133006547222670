import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailPopupModule } from '@iot-platform/iot-platform-ui';

import { SharedModule } from '@iot-platform/shared';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NumberFormatPipeModule } from '@iot-platform/pipes';

import { UserProfileFormComponent } from './components/user-profile-form/user-profile-form.component';
import { UserProfileInfoComponent } from './components/user-profile-info/user-profile-info.component';
import { UserProfilePreferencesFormComponent } from './components/user-profile-preferences-form/user-profile-preferences-form.component';
import { UserProfilePreferencesComponent } from './components/user-profile-preferences/user-profile-preferences.component';
import { UserRelatedBusinessProfilesModule } from './components/user-related-business-profiles/user-related-business-profiles.module';
import { UserProfileShellComponent } from './containers/user-profile-shell/user-profile-shell.component';
import { ProfileEffects } from './state/effects/profile.effects';
import * as fromUserProfile from './state/reducers';
import { InfoDisplayModule } from '../../../../../iot-platform-pipes/src/lib/info-display/info-display.module';
import { DateFormatModule } from '../../../../../iot-platform-pipes/src/lib/date-format/date-format.module';

@NgModule({
  declarations: [
    UserProfileShellComponent,
    UserProfileFormComponent,
    UserProfileInfoComponent,
    UserProfilePreferencesComponent,
    UserProfilePreferencesFormComponent
  ],
  exports: [UserProfileInfoComponent, UserProfilePreferencesComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    StoreModule.forFeature(fromUserProfile.userProfileFeatureKey, fromUserProfile.reducers),
    EffectsModule.forFeature([ProfileEffects]),
    InfoDisplayModule,
    DateFormatModule,
    NumberFormatPipeModule,
    UserRelatedBusinessProfilesModule,
    DetailPopupModule
  ],
  providers: [ProfileEffects]
})
export class ProfileModule {}
