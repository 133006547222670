<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'ADMIN.DIALOG.ADD_USER.TITLE' | translate }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content fxLayout="column" class="dialog-card-content p-20">
    <form [formGroup]="usersForm" fxLayout="column" fxFlex fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
        <mat-label id="is-shared-radio-group-label" class="capitalize-first-letter">{{ 'ADMIN.USERS.TABLE.ACCOUNT_TYPE' | translate }}</mat-label>
        <mat-radio-group
          formControlName="isShared"
          aria-labelledby="is-shared-radio-group-label"
          color="accent"
          fxLayout="row"
          fxLayoutGap="30px"
          [value]="isShared"
        >
          <mat-radio-button [value]="false">{{ 'ADMIN.USERS.TABLE.TYPE.USER_ACCOUNT' | translate }}</mat-radio-button>
          <mat-radio-button [value]="true">{{ 'ADMIN.USERS.TABLE.TYPE.SHARED_ACCOUNT' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-hint class="account-type-disclaimer">{{
        (data.externalEntityManager && !isShared.value
          ? 'ADMIN.DIALOG.ADD_USER.CARM_DISCLAIMER'
          : isShared.value
          ? 'ADMIN.DIALOG.ADD_USER.SHARED_ACCOUNT_DISCLAIMER'
          : 'ADMIN.DIALOG.ADD_USER.USER_ACCOUNT_DISCLAIMER'
        ) | translate
      }}</mat-hint>
      <a *ngIf="data.externalEntityManager && !isShared.value && !!carmURL.length" [href]="carmURL" target="_blank">{{ carmURL }}</a>
      <div *ngIf="!data.externalEntityManager  || isShared.value">
        <div fxLayout="row" fxFlex fxLayoutAlign="space-between center" fxLayoutGap="10px">
          <mat-form-field *ngIf="!isShared.value" fxFlex color="accent">
            <input matInput formControlName="firstname" required maxlength="50" [placeholder]="'ADMIN.DIALOG.ADD_USER.FIRST_NAME_PLACEHOLDER' | translate" />
          </mat-form-field>

          <mat-form-field fxFlex color="accent">
            <input
              matInput
              formControlName="lastname"
              required
              maxlength="50"
              [placeholder]="
                (isShared.value ? 'ADMIN.DIALOG.ADD_USER.SHARED_ACCOUNT_NAME_PLACEHOLDER' : 'ADMIN.DIALOG.ADD_USER.LAST_NAME_PLACEHOLDER') | translate
              "
            />
          </mat-form-field>
          <mat-form-field fxFlex color="accent">
            <input
              matInput
              formControlName="email"
              (input)="emailControl.setValue($event.target.value.toLowerCase())"
              required
              maxlength="50"
              [placeholder]="'ADMIN.DIALOG.ADD_USER.EMAIL_PLACEHOLDER' | translate"
            />
            <mat-error *ngIf="emailControl.invalid && emailControl.errors.used">{{
              'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_EMAIL' | translate
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <span>{{ 'IOT_DICTIONARY.MANDATORY_FIELDS' | translate }}</span>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="close()">{{ 'ADMIN.DIALOG.ADD_USER.CANCEL' | translate | uppercase }}</button>
    <button mat-raised-button color="accent" class="button-regular" [disabled]="isUserFormDisabled()" (click)="save()">
      {{ 'ADMIN.DIALOG.ADD_USER.SAVE' | translate | uppercase }}
    </button>
  </mat-card-actions>
</mat-card>
