import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { TranslateModule } from '@ngx-translate/core';

import { CardLoaderComponent } from './card-loader.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatProgressBarModule, TranslateModule],
  exports: [CardLoaderComponent],
  declarations: [CardLoaderComponent]
})
export class CardLoaderModule {}
