import { Injectable } from '@angular/core';

import { Connector, DeviceCommandsStatuses } from '@iot-platform/models/common';
import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import * as moment from 'moment';

import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { GridsDbActions } from '../../../../../../../grid-engine/src/lib/components/state/actions';
import * as fromGrids from '../../../../../../../grid-engine/src/lib/components/state/reducers';

import { DeviceConnectorsService } from '../../../../../../../shared/src/lib/services/device-connectors.service';
import { DevicesService } from '../../../../../../../shared/src/lib/services/devices.service';
import { DeviceConnectorsDbActions, DeviceConnectorsUiActions, DevicesDbActions } from '../actions';

@Injectable()
export class DeviceConnectorsEffects {
  addConnector$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceConnectorsUiActions.addConnector),
      switchMap((action) =>
        this.deviceConnectorService.saveConnector(action.connectorToAdd).pipe(
          map((addedConnector: Connector) => DeviceConnectorsDbActions.addConnectorSuccess({ addedConnector })),
          catchError((error) => of(DeviceConnectorsDbActions.addConnectorFailure({ error: error })))
        )
      )
    )
  );

  loadConnectors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceConnectorsUiActions.loadConnectors),
      switchMap((action) =>
        this.deviceConnectorService.getAll(action.request).pipe(
          map((response) => DeviceConnectorsDbActions.loadConnectorsSuccess({ response })),
          catchError((error) => of(DeviceConnectorsDbActions.loadConnectorsFailure({ error: error })))
        )
      )
    )
  );

  deleteConnector$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceConnectorsUiActions.deleteConnector),
      switchMap((action) =>
        this.deviceConnectorService.deleteConnector(action.connectorToDelete).pipe(
          map((deletedConnector: Connector) => DeviceConnectorsDbActions.deleteConnectorSuccess({ deletedConnector })),
          catchError((error) => of(DeviceConnectorsDbActions.deleteConnectorFailure({ error: error })))
        )
      )
    )
  );
  updateConnector$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceConnectorsUiActions.updateConnector),
      switchMap((action) =>
        this.deviceConnectorService.updateConnector(action.connectorToUpdate).pipe(
          withLatestFrom(this.store.select(fromGrids.getDefaultConnectorsGrid)),
          switchMap(([updatedConnector, grid]) =>
            grid?.id
              ? [
                  DeviceConnectorsDbActions.updateConnectorSuccess({ updatedConnector }),
                  GridsDbActions.updateItemInGridData({ gridId: grid.id, item: updatedConnector })
                ]
              : [DeviceConnectorsDbActions.updateConnectorSuccess({ updatedConnector })]
          ),
          catchError((error) => of(DeviceConnectorsDbActions.updateConnectorFailure({ error: error })))
        )
      )
    )
  );

  saveTableState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceConnectorsUiActions.selectConnector),
      switchMap((action) =>
        this.deviceConnectorService.saveTableState(action.selectedConnector).pipe(
          map((selectedConnector: Connector) => DeviceConnectorsDbActions.selectConnectorSuccess({ selectedConnector })),
          catchError((error) => of(DeviceConnectorsDbActions.selectConnectorFailure({ error: error })))
        )
      )
    )
  );

  sendConnectorCommand = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceConnectorsUiActions.sendConnectorCommand),
      switchMap((action) =>
        this.deviceConnectorService.sendCommand(action.device.id, action.command).pipe(
          concatMap((result) => {
            const command: DeviceCommandsStatuses = {
              refresh: null,
              selfconf: null
            };
            command[action.command.command] = {
              status: 'CommandSent',
              timestamp: moment.now()
            };
            return [
              DeviceConnectorsDbActions.sendConnectorCommandSuccess(),
              DevicesDbActions.loadDeviceByIdSuccess({
                loadedDevice: { ...action.device, commandsStatuses: command }
              })
            ];
          }),
          catchError((error) => of(DeviceConnectorsDbActions.sendConnectorCommandFailure({ error })))
        )
      )
    )
  );

  bulkSendConnectorCommand = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceConnectorsUiActions.bulkSendConnectorCommand),
      switchMap((action) =>
        this.deviceConnectorService.bulkSendCommand(
          action.devices.map((device) => device.id),
          action.command
        )
      ),
      mergeMap((result) =>
        result.pipe(
          map(() => DeviceConnectorsDbActions.sendConnectorCommandSuccess()),
          catchError((error) => of(DeviceConnectorsDbActions.sendConnectorCommandFailure({ error })))
        )
      )
    )
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceConnectorsDbActions.addConnectorSuccess,
          DeviceConnectorsDbActions.updateConnectorSuccess,
          DeviceConnectorsDbActions.deleteConnectorSuccess,
          DeviceConnectorsDbActions.sendConnectorCommandSuccess
        ),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceConnectorsDbActions.addConnectorFailure,
          DeviceConnectorsDbActions.updateConnectorFailure,
          DeviceConnectorsDbActions.deleteConnectorFailure,
          DeviceConnectorsDbActions.loadConnectorsFailure,
          DeviceConnectorsDbActions.sendConnectorCommandFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    {
      dispatch: false
    }
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceConnectorsUiActions.loadConnectors,
          DeviceConnectorsUiActions.addConnector,
          DeviceConnectorsUiActions.updateConnector,
          DeviceConnectorsUiActions.deleteConnector,
          DeviceConnectorsUiActions.sendConnectorCommand
        ),
        tap((response) => {
          this.notificationService.showLoader();
        })
      ),
    {
      dispatch: false
    }
  );

  hideLoaderAfterResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceConnectorsDbActions.loadConnectorsSuccess,
          DeviceConnectorsDbActions.loadConnectorsFailure,
          DeviceConnectorsDbActions.addConnectorFailure,
          DeviceConnectorsDbActions.addConnectorSuccess,
          DeviceConnectorsDbActions.updateConnectorFailure,
          DeviceConnectorsDbActions.updateConnectorSuccess,
          DeviceConnectorsDbActions.deleteConnectorFailure,
          DeviceConnectorsDbActions.deleteConnectorSuccess,
          DeviceConnectorsDbActions.sendConnectorCommandSuccess,
          DeviceConnectorsDbActions.sendConnectorCommandFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    {
      dispatch: false
    }
  );

  constructor(
    private actions$: Actions,
    private deviceConnectorService: DeviceConnectorsService,
    private devicesService: DevicesService,
    private notificationService: NotificationService,
    private store: Store
  ) {}
}
