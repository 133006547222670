import { NgModule } from '@angular/core';

import { SharedModule } from '@iot-platform/shared';
import { DefaultCardComponent } from './default-card.component';

@NgModule({
  imports: [SharedModule],
  declarations: [DefaultCardComponent],
  exports: [DefaultCardComponent]
})
export class DefaultCardModule {}
