import { BusinessProfile, Pagination, User } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { AdminUsersApiActions, AdminUsersPageActions } from '../actions';

export const adminUsersApiFeatureKey = 'adminUsersApi';

export interface State extends EntityState<User> {
  selectedUserId: string | null;
  loadedUser: User | null;
  businessProfiles: BusinessProfile[] | null;
  pagination: Pagination;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: (user: User) => user.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedUserId: null,
  businessProfiles: null,
  loadedUser: null,
  pagination: { total: 0, hasMore: false, maxPage: 0, currentPage: 0, limit: 100 }
});

export const reducer = createReducer(
  initialState,
  on(AdminUsersPageActions.listUsers, (state: State, { request }) => ({
    ...state,
    pagination: { ...state.pagination, currentPage: request.page }
  })),
  on(AdminUsersApiActions.listUsersSuccess, (state: State, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: { total: response.total, hasMore: response.hasMore, maxPage: response.maxPage, currentPage: response.currentPage, limit: response.limit }
    })
  ),
  on(AdminUsersApiActions.listUsersFailure, (state: State, { error }) => ({ ...state, error })),
  on(AdminUsersPageActions.selectUser, (state: State, { selectedUserId }) => {
    return { ...state, selectedUserId };
  }),
  on(AdminUsersApiActions.selectUserSuccess, (state: State, { selectedUser }) => adapter.updateOne({ changes: selectedUser, id: selectedUser.id }, state)),
  on(AdminUsersApiActions.addUserSuccess, (state: State, { addedUser }) => adapter.addOne(addedUser, state)),
  on(AdminUsersApiActions.updateUserSuccess, (state: State, { updatedUser }) => adapter.updateOne(updatedUser, state)),
  on(AdminUsersApiActions.activateUserSuccess, (state: State, { updatedUser }) => adapter.updateOne(updatedUser, state)),
  on(AdminUsersApiActions.disableUserSuccess, (state: State, { updatedUser }) => adapter.updateOne(updatedUser, state)),
  on(AdminUsersApiActions.resetUserSuccess, (state: State, { updatedUser }) => adapter.updateOne(updatedUser, state)),
  on(AdminUsersApiActions.deleteUserSuccess, (state: State, { deletedUserId }) => adapter.removeOne(deletedUserId, state)),

  on(AdminUsersApiActions.loadUserByIdSuccess, (state: State, { user }) => ({ ...state, loadedUser: user })),
  on(AdminUsersApiActions.loadUserByIdFailure, (state: State, { error }) => ({ ...state, loadedUser: null })),
  on(AdminUsersPageActions.clearloadedUser, (state: State) => ({ ...state, loadedUser: null })),

  on(AdminUsersPageActions.filterUsersByStatus, (state: State, { status }) => adapter.removeAll(state)),
  on(AdminUsersApiActions.filterUsersByStatusSuccess, (state: State, { response }) => adapter.setAll(response.data, { ...state, response })),

  on(AdminUsersPageActions.getBusinessProfilesByUserId, (state: State) => ({ ...state, error: null, pending: true, businessProfiles: [] })),
  on(AdminUsersApiActions.getBusinessProfilesByUserIdSuccess, (state: State, { businessProfiles }) => ({ ...state, businessProfiles: [...businessProfiles] }))
);

export const getSelectedId = (state: State) => state.selectedUserId;
export const getLoadedUser = (state: State) => state.loadedUser;
export const getBusinessProfiles = (state: State) => state.businessProfiles;
export const getPagination = (state: State) => state.pagination;
