import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageSelectorService {
  constructor(private http: HttpClient) {}

  getGallery(galleryPath: string): Observable<string[]> {
    return this.http.get<string[]>(`assets/data/` + galleryPath);
  }
}
