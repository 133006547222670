import { NgModule } from '@angular/core';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../../../../../iot4bos-ui/src/lib/directives/directives.module';
import { MasterViewEngineModule } from '../../../../../table-engine/src/lib/components/master-view-engine/master-view-engine.module';
import { AssetsRoutingModule } from './assets-routing.module';
import { AssetsShellComponent } from './containers/assets-shell/assets-shell.component';
import { AssetsEffects } from './state/effects/assets.effects';
import * as fromAssets from './state/reducers';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule,
    IotPlatformPipesModule,
    IotPlatformUiModule,
    DirectivesModule,
    MasterViewEngineModule,
    AssetsRoutingModule,
    StoreModule.forFeature(fromAssets.featureKey, fromAssets.reducers),
    EffectsModule.forFeature([AssetsEffects])
  ],
  declarations: [AssetsShellComponent]
})
export class AssetsModule {
}
