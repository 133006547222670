import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReleaseNotesService } from './services/release-notes.service';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

@Component({
  selector: 'iot-platform-ui-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReleaseNotesComponent {
  releaseNote$: Observable<SafeHtml> = this.releaseNotesService.getReleaseNotes().pipe(map((unsafeHtml) => this.sanitizer.bypassSecurityTrustHtml(unsafeHtml)));
  currentVersion$: Observable<string> = this.releaseNotesService.getCurrentVersion();

  constructor(
    private releaseNotesService: ReleaseNotesService,
    private dialogRef: MatDialogRef<ReleaseNotesComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: { appName: string }
  ) {}

  close() {
    this.dialogRef.close();
  }
}
