import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { Device, DeviceVariable, Event } from '@iot-platform/models/i4b';
import {
  ProcessMultipleStateVariablesUtil
} from '../../../../../../../shared/src/lib/utils/process-multiple-state-variables.util';

@Component({
  selector: 'iot-platform-ui-detail-device-variable-card',
  templateUrl: './detail-device-variable-card.component.html',
  styleUrls: ['./detail-device-variable-card.component.scss']
})
export class DetailDeviceVariableCardComponent implements OnInit, OnChanges {
  @Input() device: Device;
  @Input() deviceVariable: DeviceVariable;
  @Input() event: Event;

  deviceVariableProcessedUnit: string;
  eventVariableProcessedUnit: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('deviceVariable') && changes.deviceVariable.currentValue) {
      if (changes.deviceVariable.currentValue.unit && changes.deviceVariable.currentValue.unit[0] === '/') {
        this.deviceVariableProcessedUnit = ProcessMultipleStateVariablesUtil.getProcessedUnit(changes.deviceVariable.currentValue);
      }
    }

    if (changes.hasOwnProperty('event') && changes.event.currentValue) {
      if (changes.event.currentValue.context?.deviceVariable?.unit && changes.event.currentValue.context?.deviceVariable?.unit[0] === '/') {
        const lastRecord =
          changes.event.currentValue.context.deviceVariable.value !== null
            ? [{ value: changes.event.currentValue.context.deviceVariable.value, datetime: '' }]
            : [];

        this.eventVariableProcessedUnit = ProcessMultipleStateVariablesUtil.getProcessedUnit({
          ...changes.event.currentValue.context.deviceVariable,
          lastRecords: lastRecord
        });
      }
    }
  }
}
