import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, first, map, retry, switchMap, tap } from 'rxjs/operators';

import { AdminOrganizationsPageActions } from '../../../admin-organizations/state/actions';
import { BusinessProfilesService } from '../../services/business-profiles.service';
import {
  BusinessProfilesApiActions,
  BusinessProfilesFavoriteViewsApiActions,
  BusinessProfilesGridsApiActions,
  BusinessProfilesMembersApiActions,
  BusinessProfilesPageActions,
  BusinessProfilesRolesApiActions,
  BusinessProfilesTagsApiActions
} from '../actions';
import * as fromAuth from './../../../../../../../auth/src/lib/state/reducers';

@Injectable()
export class AdminBusinessProfilesEffects {
  loadBusinessProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesPageActions.listBusinessProfiles),
      switchMap(() =>
        this.store.select(fromAuth.selectLoggedIn).pipe(
          filter((loggedIn) => loggedIn === true),
          first(),
          switchMap(() =>
            this.bpService.getBusinessProfiles().pipe(
              retry(3),
              map((businessProfiles) => BusinessProfilesApiActions.listBusinessProfilesSuccess({ businessProfiles })),
              catchError((error) => of(BusinessProfilesApiActions.listBusinessProfilesFailure({ error })))
            )
          )
        )
      )
    )
  );

  saveBusinessProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesPageActions.createBusinessProfile),
      switchMap((action) =>
        this.bpService.saveBusinessProfile(action.businessProfile).pipe(
          map((businessProfile) => BusinessProfilesApiActions.addBusinessProfileSuccess({ businessProfile })),
          catchError((error) => of(BusinessProfilesApiActions.addBusinessProfileFailure({ error })))
        )
      )
    )
  );

  updateBusinessProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesPageActions.updateBusinessProfile),
      switchMap((action) =>
        this.bpService.updateBusinessProfile(action.businessProfile).pipe(
          tap((businessProfile) => {
            if (JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)).id === businessProfile.id) {
              const upd = { ...businessProfile, timezone: businessProfile.timezoneDetails?.offset };
              this.storage.set(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY, JSON.stringify(upd));
            }
          }),
          map((businessProfile) =>
            BusinessProfilesApiActions.updateBusinessProfileSuccess({ businessProfile: { id: businessProfile.id, changes: businessProfile } })
          ),
          catchError((error) => of(BusinessProfilesApiActions.updateBusinessProfileFailure({ error })))
        )
      )
    )
  );

  deleteBusinessProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesPageActions.deleteBusinessProfile),
      switchMap((action) =>
        this.bpService.deleteBusinessProfile(action.businessProfile).pipe(
          map((deletedBusinessProfile) => BusinessProfilesApiActions.deleteBusinessProfileSuccess({ deletedBusinessProfile })),
          catchError((error) => of(BusinessProfilesApiActions.deleteBusinessProfileFailure({ error })))
        )
      )
    )
  );

  listRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesPageActions.listRolesByBusinessProfile),
      switchMap((action) =>
        this.bpService.loadRolesByBusinessProfileId(action.businessProfileId).pipe(
          map((roles) => BusinessProfilesRolesApiActions.listRolesByBusinessProfileSuccess({ roles: roles })),
          catchError((error) => of(BusinessProfilesRolesApiActions.listRolesByBusinessProfileFailure({ error })))
        )
      )
    )
  );

  listMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesPageActions.listMembersByBusinessProfile),
      switchMap((action) =>
        this.bpService.loadMembersByBusinessProfileId(action.businessProfileId).pipe(
          map((members) => BusinessProfilesMembersApiActions.listMembersByBusinessProfileSuccess({ members: members })),
          catchError((error) => of(BusinessProfilesMembersApiActions.listMembersByBusinessProfileFailure({ error })))
        )
      )
    )
  );

  listTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesPageActions.listTagsByBusinessProfile),
      switchMap((action) =>
        this.bpService.loadTagsByBusinessProfileId(action.businessProfileId).pipe(
          map((tags) => BusinessProfilesTagsApiActions.listTagsByBusinessProfileSuccess({ tags })),
          catchError((error) => of(BusinessProfilesTagsApiActions.listTagsByBusinessProfileFailure({ error })))
        )
      )
    )
  );

  listFavoriteViews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesPageActions.listFavoriteViewsByBusinessProfile),
      switchMap((action) =>
        this.bpService.loadFavoriteViewsByBusinessProfileId(action.businessProfileId).pipe(
          map((response) => BusinessProfilesFavoriteViewsApiActions.listFavoriteViewsByBusinessProfileSuccess({ response })),
          catchError((error) => of(BusinessProfilesFavoriteViewsApiActions.listFavoriteViewsByBusinessProfileFailure({ error })))
        )
      )
    )
  );

  listGrids$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesPageActions.listGridsByBusinessProfile),
      switchMap((action) =>
        this.bpService.loadGridsByBusinessProfileId(action.businessProfileId).pipe(
          map((response) => BusinessProfilesGridsApiActions.listGridsByBusinessProfileSuccess({ response })),
          catchError((error) => of(BusinessProfilesGridsApiActions.listGridsByBusinessProfileFailure({ error })))
        )
      )
    )
  );

  selectBusinessProfileThenListMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesApiActions.selectBusinessProfile),
      map((action) => BusinessProfilesPageActions.listMembersByBusinessProfile({ businessProfileId: action.selectedBusinessProfileId }))
    )
  );

  selectBusinessProfileThenListRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesApiActions.selectBusinessProfile),
      map((action) => BusinessProfilesPageActions.listRolesByBusinessProfile({ businessProfileId: action.selectedBusinessProfileId }))
    )
  );

  selectBusinessProfileThenListTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesApiActions.selectBusinessProfile),
      map((action) => BusinessProfilesPageActions.listTagsByBusinessProfile({ businessProfileId: action.selectedBusinessProfileId }))
    )
  );

  selectBusinessProfileThenListFavoriteViews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesApiActions.selectBusinessProfile),
      map((action) => BusinessProfilesPageActions.listFavoriteViewsByBusinessProfile({ businessProfileId: action.selectedBusinessProfileId }))
    )
  );

  selectBusinessProfileThenListGrids$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesApiActions.selectBusinessProfile),
      map((action) => BusinessProfilesPageActions.listGridsByBusinessProfile({ businessProfileId: action.selectedBusinessProfileId }))
    )
  );

  navigateToSelectedOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesPageActions.navigateToSelectedOrganization),
      map((action) => AdminOrganizationsPageActions.selectOrganization({ selectedOrganizationId: action.organizationId })),
      tap(() => this.router.navigate(['admin', 'organizations']))
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BusinessProfilesTagsApiActions.updateTagsForBusinessProfileSuccess, BusinessProfilesApiActions.updateBusinessProfileSuccess),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BusinessProfilesTagsApiActions.updateTagsForBusinessProfileFailure,
          BusinessProfilesApiActions.listBusinessProfilesFailure,
          BusinessProfilesApiActions.updateBusinessProfileFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BusinessProfilesPageActions.listBusinessProfiles),
        tap((action) => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BusinessProfilesApiActions.listBusinessProfilesSuccess),
        tap((action) => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly bpService: BusinessProfilesService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly storage: LocalStorageService
  ) {}
}
