import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { TagLabel } from '@iot-platform/models/common';

import { CustomCellParams } from '../custom-cell.params';

@Component({
  selector: 'grid-engine-color-cell',
  templateUrl: './tag-cell.component.html',
  styleUrls: ['./tag-cell.component.css']
})
export class TagCellComponent implements ICellRendererAngularComp {
  params: CustomCellParams;
  tagLabel: TagLabel;

  agInit(params: CustomCellParams): void {
    this.params = params;
    this.tagLabel = params.value;
  }

  refresh(): boolean {
    return false;
  }
}
