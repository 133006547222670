import { Sort } from '@angular/material/sort';
import {
  FavoriteView,
  Filter,
  gridSortModel,
  Pagination,
  PlatformResponse,
  TagCategory
} from '@iot-platform/models/common';
import { Site } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';
import { SitesDbActions } from '../actions';

export const sitesDbFeatureKey = 'sitesDb';

export interface State extends EntityState<Site> {
  site?: Site;
  selectedSiteId?: string;
  filter?: string;
  filteredSites?: Site[];
  tags?: TagCategory[];
  assetsBySite?: PlatformResponse;
  devicesBySite?: PlatformResponse;
  pagination: Pagination;
  settings: any;
  currentFilters: Filter[];
  initialSort: gridSortModel[] | Sort;
  currentFavoriteView: FavoriteView;
}

export const adapter: EntityAdapter<Site> = createEntityAdapter<Site>({
  selectId: (site: Site) => site.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedSiteId: null,
  site: null,
  filter: null,
  filteredSites: [],
  tags: [],
  assetsBySite: { data: [], currentPage: 0, hasMore: false, limit: 0, maxPage: 0, total: 0 },
  devicesBySite: { data: [], currentPage: 0, hasMore: false, limit: 0, maxPage: 0, total: 0 },
  pagination: { currentPage: 0, hasMore: false, limit: 10, maxPage: 0, total: 0 },
  settings: null,
  currentFilters: null,
  initialSort: [],
  currentFavoriteView: null
});

export const reducer = createReducer(
  initialState,
  on(SitesDbActions.addSiteSuccess, (state: State, { siteAdded }) => adapter.addOne(siteAdded, state)),
  on(SitesDbActions.updateSiteSuccess, (state: State, { updatedSite }) => {
    return adapter.updateOne({ changes: updatedSite, id: updatedSite.id }, state);
  }),
  on(SitesDbActions.deleteSiteSuccess, (state: State, { deletedSite }) => adapter.removeOne(deletedSite.id, state)),
  //
  on(SitesDbActions.loadSites, (state: State, { request }) => ({ ...state, site: null, pagination: { ...state.pagination, currentPage: request.page } })),
  on(SitesDbActions.loadSitesSuccess, (state: State, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: { limit: response.limit, hasMore: response.hasMore, maxPage: response.maxPage, total: response.total, currentPage: response.currentPage }
    })
  ),
  on(SitesDbActions.loadSiteById, (state: State) => ({ ...state, site: null })),
  on(SitesDbActions.loadSiteByIdSuccess, (state: State, { site }) => ({ ...state, site, selectedSiteId: site.id })),
  on(SitesDbActions.loadAssetsBySiteId, (state: State) => ({
    ...state,
    assetsBySite: { data: [], currentPage: 0, hasMore: false, limit: 0, maxPage: 0, total: 0 }
  })),
  on(SitesDbActions.loadAssetsBySiteIdSuccess, (state: State, { response }) => ({ ...state, assetsBySite: response })),
  on(SitesDbActions.loadDevicesBySiteId, (state: State) => ({
    ...state,
    devicesBySite: { data: [], currentPage: 0, hasMore: false, limit: 0, maxPage: 0, total: 0 }
  })),
  on(SitesDbActions.loadDevicesBySiteIdSuccess, (state: State, { response }) => ({ ...state, devicesBySite: response })),
  on(SitesDbActions.loadTagsBySiteId, (state: State) => ({ ...state, tags: [] })),
  on(SitesDbActions.loadTagsBySiteIdSuccess, (state: State, { tags }) => ({ ...state, tags })),
  //

  on(SitesDbActions.filterSites, (state: State, { filter }) => ({ ...state, filter: filter })), // filteredSites
  on(SitesDbActions.selectSiteSuccess, (state: State, { selectedSite }) => ({ ...state, selectedSiteId: selectedSite.id })),
  on(SitesDbActions.saveCurrentFavoriteView, (state: State, { currentFavoriteView }) => ({
    ...state,
    currentFavoriteView,
    currentFilters: currentFavoriteView ? currentFavoriteView.filters : []
  })),
  on(SitesDbActions.saveCurrentFilters, (state: State, { currentFilters }) => ({ ...state, currentFilters })),
  on(SitesDbActions.updateTagsBySiteIdSuccess, (state: State, { tags }) => ({ ...state, tags })),
  on(SitesDbActions.openSiteDetail, (state: State, { siteId }) => ({ ...state, selectedSiteId: siteId })),
  //
  on(SitesDbActions.loadMVSettingsSuccess, (state: State, { settings }) => ({ ...state, settings })),
  on(SitesDbActions.saveMVSettingsSuccess, (state: State, { settings }) => ({ ...state, settings })),
  on(SitesDbActions.saveMVSettings, (state: State, { name, type, values }) => adapter.removeAll({ ...state })),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState),
  //
  on(SitesDbActions.saveInitialSort, (state: State, { initialSort }) => ({ ...state, initialSort }))
);

export const getSelectedSiteId = (state: State) => state.selectedSiteId;
export const getTagsBySite = (state: State) => state.tags;
export const getAssetsBySite = (state: State) => state.assetsBySite;
export const getDevicesBySite = (state: State) => state.devicesBySite;
export const getCurrentFavoriteView = (state: State) => state.currentFavoriteView;
export const getCurrentFilters = (state: State) => state.currentFilters;
export const getPagination = (state: State) => state.pagination;
