<header fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="20px">
  <button
    (click)="onToggleMenuClick()"
    [matTooltipClass]="'regular-tooltip'"
    [matTooltip]="'HEADER.TOGGLE_NAVIGATION_TOOLTIP' | translate"
    class="regular-round-button"
    mat-icon-button
  >
    <mat-icon>menu</mat-icon>
  </button>

  <img (click)="onLogoClick()" [src]="logo" alt="" />

  <span class="border-left">IoT-4BOS</span>

  <span class="border-left">{{ selectedBusinessProfile?.entityName }}</span>

  <span fxFlex></span>

  <span class="border-right">{{ timezone$ | async }}</span>

  <mat-select
    (click)="onBusinessProfileListClicked($event)"
    (selectionChange)="switchBusinessProfile($event)"
    [disabled]="currentUserLoading"
    [value]="selectedProfile"
    class="navbar-business-profile-select border-right"
  >
    <mat-select-trigger>{{ selectedProfile?.name }}</mat-select-trigger>
    <mat-option *ngFor="let profile of businessProfiles" [disabled]="profile.notCoveredByUserEntityAt" [value]="profile">
      <span class="bp-list--bp-name">{{ profile.name }}</span>
      <mat-icon
        *ngIf="profile.notCoveredByUserEntityAt"
        [matTooltip]="'HEADER.BUSINESS_PROFILE_OUT_OF_SCOPE' | translate"
        class="bp-list--info-icon"
        matTooltipClass="regular-tooltip"
        >info_outlined</mat-icon
      >
    </mat-option>
  </mat-select>

  <button [matMenuTriggerFor]="menu" class="user-button" color="accent" mat-mini-fab><p data-hj-suppress>{{ initials }}</p></button>
  <mat-menu #menu="matMenu" fxLayout="row">
    <button (click)="onMyProfileClick()" mat-menu-item>{{ 'HEADER.MY_PROFILE' | translate }}</button>
    <mat-divider></mat-divider>
    <button (click)="onLogout()" mat-menu-item>{{ 'HEADER.LOGOUT' | translate }}</button>
  </mat-menu>
</header>
