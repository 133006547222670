import { Connector, DeviceCommandsStatuses, PlatformResponse } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const addConnectorSuccess = createAction('[Connectors] Add Connector Success', props<{ addedConnector: Connector }>());
export const addConnectorFailure = createAction('[Connectors] Add Connector Failure', props<{ error: any }>());

export const updateConnectorSuccess = createAction('[Connectors] Update Connector Success', props<{ updatedConnector: Connector }>());
export const updateConnectorFailure = createAction('[Connectors] Update Connector Failure', props<{ error: any }>());

export const deleteConnectorSuccess = createAction('[Connectors] Delete Connector Success', props<{ deletedConnector: Connector }>());
export const deleteConnectorFailure = createAction('[Connectors] Delete Connector Failure', props<{ error: any }>());

export const selectConnectorSuccess = createAction('[Connectors] Select Connector Success', props<{ selectedConnector: Connector }>());
export const selectConnectorFailure = createAction('[Connectors] Select Connector Failure', props<{ error: any }>());

export const loadConnectorsSuccess = createAction('[Connectors] Load Connectors Success', props<{ response: PlatformResponse }>());
export const loadConnectorsFailure = createAction('[Connectors] Load Connectors Failure', props<{ error: any }>());

export const sendConnectorCommandSuccess = createAction('[Connectors] Send Connector Command Success');
export const sendConnectorCommandFailure = createAction('[Connectors] Send Connector Command Failure', props<{ error: any }>());

export const bulkSendConnectorCommandSuccess = createAction('[Connectors] Bulk Send Connector Command Success');
export const bulkSendConnectorCommandFailure = createAction('[Connectors] Bulk Send Connector Command Failure', props<{ error: any }>());

export const refreshCommandStatusSuccess = createAction('[Connectors] Refresh Command Status Success', props<{ commandStatuses: DeviceCommandsStatuses }>());
export const refreshCommandStatusFailure = createAction('[Connectors] Refresh Command Status Failure', props<{ error: any }>());
