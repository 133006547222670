import { Log } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { DeviceEventsLogsDbActions, DeviceEventsLogsUiActions } from '../actions';

export const deviceEventsLogsDbFeatureKey = 'deviceEventsLogsDb';

export interface State extends EntityState<Log> {
  selectedLogId: string | null;
  error?: any;
  log?: Log;
}

export const adapter: EntityAdapter<Log> = createEntityAdapter<Log>({
  selectId: (log: Log) => log.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedLogId: null,
  error: null,
  log: null
});

const deviceEventsLogsDbReducer = createReducer(
  initialState,
  on(DeviceEventsLogsUiActions.loadLogsByDeviceEventId, (state: State) => ({ ...state })),
  on(DeviceEventsLogsDbActions.loadLogsByDeviceEventIdSuccess, (state: State, { logs }) => adapter.setAll(logs, state)),
  on(DeviceEventsLogsDbActions.loadLogsByDeviceEventIdFailure, (state: State, { error }) => ({ ...state, error })),
  // ****
  on(DeviceEventsLogsDbActions.createLogByDeviceEventIdSuccess, (state: State, { log }) => adapter.addOne(log, { ...state, log })),
  on(DeviceEventsLogsDbActions.createLogByDeviceEventIdFailure, (state: State, { error }) => ({ ...state, error }))
  // ****
);

export function reducer(state: State | undefined, action: Action) {
  return deviceEventsLogsDbReducer(state, action);
}

export const getLog = (state: State) => state.log;
