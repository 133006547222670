import { Injectable } from '@angular/core';
import { BaseFacade, CommonApiRequest, Filter, Pagination } from '@iot-platform/models/common';
import { Message } from '@iot-platform/models/oyan';
import { Store } from '@ngrx/store';
import { MessagesActions } from '../actions';
import { MessagesSelectors } from '../selectors/messages.selectors';

@Injectable({
  providedIn: 'root'
})
export class MessagesFacade extends BaseFacade<Message, Pagination, Filter> {
  constructor(protected store: Store, protected selector: MessagesSelectors) {
    super(store, selector);
  }

  getAll(request?: CommonApiRequest) {
    this.store.dispatch(MessagesActions.loadMessages({ request }));
  }

  setFilters(filters: Filter[]) {
    this.store.dispatch(MessagesActions.setFilters({ filters }));
  }
}
