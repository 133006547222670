import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { User } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'iot-platform-users-user-profile-info',
  templateUrl: './user-profile-info.component.html',
  styleUrls: ['./user-profile-info.component.scss']
})
export class UserProfileInfoComponent implements OnInit, OnChanges {
  @Input() currentUser: User;

  @Output() editUserProfile: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translateService: TranslateService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {}

  get relatedBusinessProfilesTitle$(): Observable<string> {
    if (this.currentUser) {
      return this.translateService.get('USERS.RELATED_BUSINESS_PROFILES.TITLE', { totalBP: this.currentUser.totalBusinessProfile });
    } else {
      return of('');
    }
  }
}
