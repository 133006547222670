import { Component, EventEmitter, Input, Output } from '@angular/core';

import { User } from '@iot-platform/models/common';

@Component({
  selector: 'oyan-ui-users-user-profile-info',
  templateUrl: './user-profile-info.component.html',
  styleUrls: ['./user-profile-info.component.scss']
})
export class UserProfileInfoComponent {
  @Input() currentUser: User;
  @Output() editUserProfile: EventEmitter<void> = new EventEmitter<void>();
}
