import { GetUtils, TemplateDefUtils } from '@iot-platform/iot-platform-utils';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export enum TemplateDefType {
  DEFAULT = 'DEFAULT',
  LIST = 'LIST',
  LINK = 'LINK',
  BUTTON = 'BUTTON'
}

export enum TemplateDefDispatchEventType {
  OPEN_LINK = 'OPEN_LINK',
  BUTTON_CLICK = 'BUTTON_CLICK'
}

export interface TemplateDefDispatchEvent {
  def: TemplateDef;
  data: any;
  type: TemplateDefDispatchEventType;
}

export interface TemplateDefParams {
  attr: string;
  paramKey?: string;
}

export interface TemplateDefOptions {
  element: any;
  def: TemplateDef;
  value: any;
}

export interface TemplateDefAPI {
  endpoint: string;
  pathParams?: string[];
  useCache?: boolean;
  cacheFieldId?: string;
  extendedEndpoint?: boolean;
  params?: string[];
  compareField?: string;
}

export class TemplateDef {
  id!: string;
  type: TemplateDefType;
  attrKey: string | undefined;
  attrKeys: string[];
  separator: string | undefined;
  label: string | undefined;
  tooltip: string | undefined;
  fxFlex: number | undefined;
  predicates: Array<(options: TemplateDefOptions) => any>;
  normalizeRawData?: (options: Partial<TemplateDefOptions>) => any;
  api: Partial<TemplateDefAPI> | undefined;
  value$!: Observable<unknown> | Array<Observable<unknown>>;

  constructor(options?: Partial<TemplateDef>) {
    this.id = options?.id ?? uuidv4();
    this.type = options?.type ?? TemplateDefType.DEFAULT;
    this.attrKey = options?.attrKey;
    this.attrKeys = options?.attrKeys ?? [];
    this.separator = options?.separator;
    this.label = options?.label;
    this.tooltip = options?.tooltip;
    this.fxFlex = options?.fxFlex ?? (this.type === TemplateDefType.LIST ? 100 : 33);
    this.predicates = options?.predicates ?? [];
    this.normalizeRawData = options?.normalizeRawData;
    this.api = options?.api;
  }

  get isLink(): boolean {
    return this.type === TemplateDefType.LINK;
  }

  get isList(): boolean {
    return this.type === TemplateDefType.LIST;
  }
}

export class TemplateDefLink extends TemplateDef {
  path: string;
  queryParams: TemplateDefParams[];
  pathParams: TemplateDefParams[];

  constructor(options: Partial<TemplateDefLink>) {
    super(options);
    this.type = TemplateDefType.LINK;
    this.path = options.path ?? '';
    this.queryParams = options?.queryParams ?? [];
    this.pathParams = options?.pathParams ?? [];
  }

  getLink = (data: unknown): string => {
    let link = '';
    if (GetUtils.get(this, 'queryParams', []).length > 0) {
      link = TemplateDefUtils.getQueryParams({ data, def: this });
    } else {
      link = TemplateDefUtils.getPathParams({ data, def: this });
    }
    return `${this.path}${link}`;
  };
}

export class TemplateDefButton extends TemplateDef {
  tooltip: string;
  constructor(options: Partial<TemplateDefButton>) {
    super(options);
    this.type = TemplateDefType.BUTTON;
    this.tooltip = options?.tooltip ?? '';
  }
}
