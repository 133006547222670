import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Role } from '@iot-platform/models/common';

@Component({
  selector: 'iot4bos-backoffice-ui-dialog-role-edit-form',
  templateUrl: './dialog-role-edit-form.component.html',
  styleUrls: ['./dialog-role-edit-form.component.scss']
})
export class DialogRoleEditFormComponent implements OnInit {
  roleForm: UntypedFormGroup;
  params = { roleName: this.data.role?.name };

  constructor(
    public dialogRef: MatDialogRef<DialogRoleEditFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { role: Role; isAdmin: boolean; adminConceptsFromEntitySession: [string] }
  ) {}

  ngOnInit() {
    this.roleForm = new UntypedFormGroup({ roleToUpdate: new UntypedFormControl(this.data.role ? this.data.role : null) });
  }

  get roleToUpdate(): AbstractControl {
    return this.roleForm.get('roleToUpdate');
  }

  onAuthorizationsChanged(authorizationsToUpdate: any[]) {
    this.roleToUpdate.setValue(Object.assign({}, this.roleToUpdate.value, { rights: { ...authorizationsToUpdate } }));
    this.roleForm.markAsDirty();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.roleToUpdate.value);
  }
}
