<div fxLayout="column" fxLayoutAlign="start stretch" class="full-width container">
  <!--  <mat-form-field class="display-mode-select" color="accent" [ngClass]="{ 'display-mode-select-navigation': navigationMode }">-->
  <!--    <mat-select [value]="displayBy" [placeholder]="'SITES.ASSOCIATIONS.DISPLAY_BY' | translate" (selectionChange)="onUpdateUserPreferences($event)">-->
  <!--      <mat-option value="assets">{{ 'SITES.ASSOCIATIONS.ASSETS' | translate }}</mat-option>-->
  <!--      <mat-option value="devices">{{ 'SITES.ASSOCIATIONS.DEVICES' | translate }}</mat-option>-->
  <!--    </mat-select>-->
  <!--  </mat-form-field>-->

  <div [fxLayout]="displayBy === 'assets' ? 'row' : 'row-reverse'" fxLayoutAlign="space-between center" fxLayoutGap="30px" class="association-toolbars">
    <div fxFlex="50%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <span
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        [ngClass]="{ 'display-by-toggle': displayBy !== 'assets', 'display-by-active': displayBy === 'assets' }"
        [matTooltip]="(displayBy === 'assets' ? 'SITES.ASSOCIATIONS.DISPLAYED_BY_ASSETS' : 'SITES.ASSOCIATIONS.DISPLAY_BY_ASSETS') | translate"
        matTooltipPosition="below"
        matTooltipClass="regular-tooltip"
        (click)="onUpdateUserPreferences('assets')"
      >
        <!--<iot-platform-ui-icon name="asset" [color]="displayBy === 'assets' ? '#4F79B7' : '#b0b0b0'" class="toolbar-asset-icon"></iot-platform-ui-icon>-->
        <mat-icon svgIcon="asset" [color]="displayBy === 'assets' ? 'accent' : '#b0b0b0'"></mat-icon>
        <span class="association-toolbars-title">{{ 'SITES.ASSOCIATIONS.ASSETS' | translate }}</span>
      </span>
      <hr fxFlex class="regular-1px-line" />
      <button
        mat-mini-fab
        class="light-button"
        *ngIf="canCreateAsset && !navigationMode"
        [matTooltip]="'SITES.ASSOCIATIONS.ADD_ASSET' | translate"
        matTooltipPosition="below"
        matTooltipClass="regular-tooltip"
        (click)="dispatchAction.emit({ action: 'add-asset' })"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <div fxFlex="50%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <span
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        class="display-by-toggle"
        [ngClass]="{ 'display-by-toggle': displayBy !== 'devices', 'display-by-active': displayBy === 'devices' }"
        [matTooltip]="(displayBy === 'devices' ? 'SITES.ASSOCIATIONS.DISPLAYED_BY_DEVICES' : 'SITES.ASSOCIATIONS.DISPLAY_BY_DEVICES') | translate"
        matTooltipPosition="below"
        matTooltipClass="regular-tooltip"
        (click)="onUpdateUserPreferences('devices')"
      >
        <!--        <iot-platform-ui-icon name="device" [color]="displayBy === 'devices' ? '#4F79B7' : '#b0b0b0'" class="toolbar-device-icon"></iot-platform-ui-icon>-->
        <mat-icon svgIcon="device" [color]="displayBy === 'devices' ? 'accent' : '#b0b0b0'"></mat-icon>
        <span class="association-toolbars-title">{{ 'SITES.ASSOCIATIONS.DEVICES' | translate }}</span>
      </span>
      <hr fxFlex class="regular-1px-line" />
      <button
        mat-mini-fab
        class="light-button"
        *ngIf="canCreateDevice && !navigationMode"
        [matTooltip]="'SITES.ASSOCIATIONS.ADD_DEVICE' | translate"
        matTooltipPosition="below"
        matTooltipClass="regular-tooltip"
        (click)="dispatchAction.emit({ action: 'add-device' })"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <section *ngIf="!associationsLoaded">
    <div [fxLayout]="displayBy === 'assets' ? 'row' : 'row-reverse'" fxLayoutAlign="space-between center" fxLayoutGap="30px">
      <iot-platform-ui-card-loader fxFlex [backgroundClass]="'default-card h-82'"></iot-platform-ui-card-loader>
      <iot-platform-ui-card-loader fxFlex [backgroundClass]="'default-card h-82'"></iot-platform-ui-card-loader>
    </div>
  </section>
  <section [ngClass]="{ 'association-section': navigationMode && associationsLoaded, invisible: !associationsLoaded, visible: associationsLoaded }">
    <div
      *ngIf="!navigationMode && ((!hasAssets && canCreateAsset) || (!hasDevices && canCreateDevice))"
      [fxLayout]="displayBy === 'assets' ? 'row' : 'row-reverse'"
      fxLayoutAlign="space-between center"
      fxLayoutGap="30px"
      class="association-section"
    >
      <div fxFlex>
        <iot4bos-ui-default-card
          *ngIf="!hasAssets && canCreateAsset"
          [backgroundClass]="'h-82'"
          [title]="'SITES.ASSOCIATIONS.ADD_ASSET' | translate"
          (click)="dispatchAction.emit({ action: 'add-asset' })"
        ></iot4bos-ui-default-card>
      </div>

      <div fxFlex>
        <iot4bos-ui-default-card
          *ngIf="!hasDevices && canCreateDevice"
          [backgroundClass]="'h-82'"
          [title]="'SITES.ASSOCIATIONS.ADD_DEVICE' | translate"
          (click)="dispatchAction.emit({ action: 'add-device' })"
        ></iot4bos-ui-default-card>
      </div>
    </div>

    <div *ngIf="associationsLoaded && associations[displayBy].length">
      <div *ngFor="let association of associations[displayBy]" class="association-section">
        <iot4bos-ui-asset-device-association-item
          [association]="association"
          [navigationMode]="navigationMode"
          [selectedItemId]="selectedItem?.id"
          [canCreateAsset]="canCreateAsset"
          [canCreateDevice]="canCreateDevice"
          [displayBy]="displayBy"
          (dispatchAction)="dispatchAction.emit($event)"
        ></iot4bos-ui-asset-device-association-item>
      </div>
    </div>
  </section>
</div>
