import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import { User } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';
import { DialogUsersAddFormComponent } from 'libs/iot4bos-backoffice-ui/src/lib/components/dialogs/dialog-users-add-form/dialog-users-add-form.component';
import { UsersService } from 'libs/iot4bos-backoffice-ui/src/lib/features/admin-users/services/users.service';
import { SortUtil } from 'libs/shared/src/lib/utils/sort.util';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'oyan-ui-user-form-dialog',
  templateUrl: './user-form-dialog.component.html',
  styleUrls: ['./user-form-dialog.component.scss']
})
export class UserFormDialogComponent extends DialogUsersAddFormComponent implements OnInit {
  availableLanguages = this.translateService.langs;
  countries$: Observable<Array<{ key: string; value: string }>>;

  constructor(
    protected dialogRef: MatDialogRef<UserFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User; externalEntityManager?: boolean },
    protected usersService: UsersService,
    private readonly translateService: TranslateService
  ) {
    super(dialogRef, data, usersService);
    this.countries$ = this.translateService.get('IOT_DICTIONARY.COUNTRIES').pipe(
      map((countries: { [key: string]: string }) => {
        return Object.keys(countries)
          .map((key: string) => ({ key, value: countries[key] }))
          .sort(SortUtil.sortByProperty('value'));
      })
    );
  }

  get country(): AbstractControl {
    return this.usersForm.get('country');
  }

  get language(): AbstractControl {
    return this.usersForm.get('language');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initForm();
    this.initUserCountry();
  }

  checkEmail(email: UntypedFormControl): Observable<{ used: boolean } | null | undefined> {
    if (this.data?.user && GetUtils.get(email, 'value', '').toLowerCase() === GetUtils.get(this.data, 'user.email', '').toLowerCase()) {
      return of(null);
    }
    return super.checkEmail(email);
  }

  save(): void {
    const user: User = {
      firstname: this.isShared.value ? '' : this.firstnameControl.value,
      lastname: this.lastnameControl.value,
      email: this.emailControl.value,
      isShared: this.isShared.value,
      preferences: {
        ...this.data?.user?.preferences,
        appLanguage: this.language.value
      },
      addressCountry: this.country.value
    };
    this.dialogRef.close(user);
  }

  /**
   TODO temporary workaround to init user country input.
   Should be removed once the users GET endpoint will be migrated to v2
   */
  private initUserCountry(): void {
    if (this.data?.user) {
      (async () => {
        const user: User = await this.usersService.getById(this.data.user.id).toPromise();
        this.country.setValue(GetUtils.get(user, 'addressCountry', ''));
      })();
    }
  }

  private initForm(): void {
    this.usersForm.addControl('language', new UntypedFormControl(GetUtils.get(this.data, 'user.preferences.appLanguage', 'en'), []));
    this.usersForm.addControl('country', new UntypedFormControl('', [Validators.required]));

    if (GetUtils.get(this.data, 'user', null)) {
      this.usersForm.patchValue({
        firstname: GetUtils.get(this.data, 'user.firstname', ''),
        lastname: GetUtils.get(this.data, 'user.lastname', ''),
        email: GetUtils.get(this.data, 'user.email', ''),
        isShared: false,
        country: GetUtils.get(this.data, 'user.addressCountry', '')
      });
    }
  }
}
