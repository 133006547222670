export enum DeviceLastCommandStepStatus {
  START_PROCESSING_COMMAND = 'StartProcessingCommand',
  CONNECTED = 'Connected',
  RETRY_IN_PROGRESS = 'RetryInProgress',
  COMMAND_ERROR = 'CommandError',
  DATA_RETRIEVED = 'DataRetrieved',
  COMPLETED_COMMAND_PROCESSING = 'CompletedCommandProcessing'
}

export enum DeviceLastCommandErrorStatus {
  CONNECTION_ERROR = 'ConnectionError',
  NO_PORT_AVAILABLE = 'NoPortAvailable',
  ABORTED_COMMAND_PROCESSING = 'AbortedCommandProcessing',
  COMMAND_ERROR = 'CommandError',
  COMMAND_TIMED_OUT = 'command_timed_out'
}

export enum LastCommandStatusStates {
  ONGOING = 'ongoing',
  ERROR = 'error',
  COMPLETED = 'completed'
}
