import { Log } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { AssetEventsLogsDbActions, AssetEventsLogsUiActions } from '../actions';

export const assetEventsLogsDbFeatureKey = 'assetEventsLogsDb';

export interface State extends EntityState<Log> {
  selectedLogId: string | null;
  error?: any;
  log?: Log;
}

export const adapter: EntityAdapter<Log> = createEntityAdapter<Log>({
  selectId: (log: Log) => log.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedLogId: null,
  error: null,
  log: null
});

const assetEventsLogsDbReducer = createReducer(
  initialState,
  on(AssetEventsLogsUiActions.loadLogsByAssetEventId, (state: State) => ({ ...state })),
  on(AssetEventsLogsDbActions.loadLogsByAssetEventIdSuccess, (state: State, { logs }) => adapter.setAll(logs, state)),
  on(AssetEventsLogsDbActions.loadLogsByAssetEventIdFailure, (state: State, { error }) => ({ ...state, error })),
  // ****
  on(AssetEventsLogsDbActions.createLogByAssetEventIdSuccess, (state: State, { log }) => adapter.addOne(log, { ...state, log })),
  on(AssetEventsLogsDbActions.createLogByAssetEventIdFailure, (state: State, { error }) => ({ ...state, error }))
  // ****
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsLogsDbReducer(state, action);
}

export const getLog = (state: State) => state.log;
