import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { Environment, User } from '@iot-platform/models/common';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import * as moment from 'moment-timezone';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, debounceTime, delay, filter, map, retry, switchMap, tap } from 'rxjs/operators';
import { SsoCodeToTokensRawResponse, SsoCodeToTokensResponse } from '../models/sso-code-to-tokens-response';

// https://github.com/BrianKopp/ngrx-cognito
// https://www.integralist.co.uk/posts/cognito/
// https://aws-amplify.github.io/docs/js/angular#subscribe-to-authentication-state-changes
// https://blog.angularindepth.com/top-10-ways-to-use-interceptors-in-angular-db450f8a62d6

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  cognitoUserSession$: BehaviorSubject<CognitoUserSession> = new BehaviorSubject<CognitoUserSession>(null);
  cognitoUser$: BehaviorSubject<CognitoUser> = new BehaviorSubject<CognitoUser>(null);
  authError$: BehaviorSubject<Error> = new BehaviorSubject<Error>(null);

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject('environment') private readonly environment: Environment,
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    private readonly storage: LocalStorageService
  ) {
    this.cognitoUserSession$
      .pipe(
        filter((value) => value !== null),
        debounceTime(500)
      )
      .subscribe((cognitoUserSession: CognitoUserSession) => {
        if (cognitoUserSession) {
          this.storage.set(LocalStorageKeys.STORAGE_ID_TOKEN_KEY, cognitoUserSession.getIdToken().getJwtToken());
        }
      });
  }

  getSsoTokensFromCode(code: string): Observable<SsoCodeToTokensResponse> {
    const body = new URLSearchParams();
    body.set('client_id', this.environment.sso.clientId);
    body.set('grant_type', 'authorization_code');
    body.set('code', code);
    body.set('redirect_uri', `${window.location.origin}/login/callback`);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.httpClient
      .post<SsoCodeToTokensRawResponse>(`${this.environment.sso.domain}/oauth2/token`, body.toString(), {
        headers
      })
      .pipe(
        map((r) => ({
          idToken: r.id_token,
          accessToken: r.access_token,
          tokenType: r.token_type,
          expiresIn: r.expires_in,
          refreshToken: r.refresh_token
        }))
      );
  }

  public refreshSsoTokens(refreshToken: string): Observable<{ idToken: string; accessToken: string; tokenType: string; expiresIn: number }> {
    const body = new URLSearchParams();
    body.set('client_id', this.environment.sso.clientId);
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', refreshToken);
    body.set('redirect_uri', `${window.location.origin}/login/callback`);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.httpClient
      .post<any>(`${this.environment.sso.domain}/oauth2/token`, body.toString(), {
        headers
      })
      .pipe(
        map((r) => ({
          idToken: r.id_token,
          accessToken: r.access_token,
          tokenType: r.token_type,
          expiresIn: r.expires_in
        }))
      );
  }

  storeSsoTokens({ idToken, accessToken, tokenType, refreshToken, expiresIn }: Record<string, string>) {
    this.storage.set(LocalStorageKeys.STORAGE_SSO_TOKEN_ID, idToken);
    this.storage.set(LocalStorageKeys.STORAGE_SSO_ACCESS_TOKEN, accessToken);
    this.storage.set(LocalStorageKeys.STORAGE_SSO_TOKEN_TYPE, tokenType);
    this.storage.set(LocalStorageKeys.STORAGE_SSO_EXPIRES_IN, expiresIn);
    this.storage.set(LocalStorageKeys.STORAGE_SSO_EXPIRES_AT, new Date(Date.now() + parseInt(expiresIn) * 1000).toISOString());
    this.storage.set(LocalStorageKeys.STORAGE_SSO_LOGGED_IN_AT, String(Date.now()));
    this.storage.set(LocalStorageKeys.STORAGE_SSO_LOGGED_IN_WITH_SSO, 'true');
    if (refreshToken) {
      this.storage.set(LocalStorageKeys.STORAGE_SSO_REFRESH_TOKEN, refreshToken);
    }
  }

  signWithRefreshToken(token: string, userId: string): Observable<boolean> {
    return this.httpClient
      .post<{ AuthenticationResult: { AccessToken: string; IdToken: string } }>(
        `https://cognito-idp.${this.environment.cognito.region}.amazonaws.com/`,
        {
          ClientId: this.environment.cognito.clientId,
          AuthFlow: 'REFRESH_TOKEN_AUTH',
          AuthParameters: {
            REFRESH_TOKEN: token
          }
        },
        {
          headers: new HttpHeaders({
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
            'Content-Type': 'application/x-amz-json-1.1'
          })
        }
      )
      .pipe(
        switchMap(({ AuthenticationResult: { AccessToken, IdToken } }) => {
          this.setSharedCredentials(userId, AccessToken, token, IdToken);
          return this.isValidSession();
        }),
        catchError(() => {
          return of(false);
        })
      );
  }

  isValidSession(): Observable<boolean> {
    return from(
      Auth.currentAuthenticatedUser()
        .then((cognitoUser: CognitoUser) => {
          this.cognitoUser$.next(cognitoUser);
          return Auth.currentSession();
        })
        .then((session: CognitoUserSession) => {
          this.cognitoUserSession$.next(session);
          return session.isValid();
        })
        .catch(() => {
          return false;
        })
    );
  }

  getAccount(headers: HttpHeaders): Observable<User> {
    return this.httpClient.get<User>(`${this.environment.api.url}/account`, { headers }).pipe(retry(1));
  }

  public signIn(username: string, password: string): Observable<CognitoUser> {
    return from(Auth.signIn(username, password)).pipe(
      tap((cognitoUser: CognitoUser) => {
        this.cognitoUserSession$.next(cognitoUser.getSignInUserSession());
        this.cognitoUser$.next(cognitoUser);
      })
    );
  }

  public signInWithSso() {
    const url = new URL(`${this.environment.sso.domain}/authorize`);
    url.searchParams.set('client_id', this.environment.sso.clientId);
    url.searchParams.set('idp_identifier', this.environment.sso.idpIdentifier);
    url.searchParams.set('response_type', this.environment.sso.responseType);
    url.searchParams.set('redirect_uri', `${window.location.origin}/${this.environment.sso.redirectUri}`);
    this.document.location.href = url.toString();
  }

  public validateSsoTokens(idToken: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
    return this.getAccount(headers);
  }

  public retrieveSsoTokens() {
    return {
      idToken: this.storage.get(LocalStorageKeys.STORAGE_SSO_TOKEN_ID),
      accessToken: this.storage.get(LocalStorageKeys.STORAGE_SSO_ACCESS_TOKEN),
      refreshToken: this.storage.get(LocalStorageKeys.STORAGE_SSO_REFRESH_TOKEN)
    };
  }

  public isLoggedInWithSSO() {
    return this.storage.get(LocalStorageKeys.STORAGE_SSO_LOGGED_IN_WITH_SSO) === 'true';
  }

  public storeSession(data: any) {
    this.storage.set(LocalStorageKeys.STORAGE_SESSION_KEY, data);
  }

  public retrieveSession() {
    this.storage.get(LocalStorageKeys.STORAGE_SESSION_KEY);
  }

  public forgotPassword(username: string): Observable<any> {
    return from(Auth.forgotPassword(username));
  }

  public forgotPasswordSubmit(credentials: { username: string; code: string; password: string }): Observable<any> {
    return from(Auth.forgotPasswordSubmit(credentials.username, credentials.code, credentials.password));
  }

  public changePassword(user, newPwd: string): Observable<unknown> {
    return from(
      Auth.completeNewPassword(this.cognitoUser$.getValue(), newPwd, {})
        .then((cognito) => {
          this.cognitoUser$.next(cognito);
          this.cognitoUserSession$.next(cognito.getSignInUserSession());
        })
        .catch((error) => this.authError$.next(error))
    );
  }

  public logout(): Observable<any> {
    return from(
      Auth.signOut()
        .then(() => this.cognitoUserSession$.next(null))
        .catch((error) => {
          this.cognitoUserSession$.next(null);
          this.authError$.next(error);
        })
    );
  }

  public loadAccount(): Observable<any> {
    // In account we have : User infos, preferences, business profiles, privileges
    return this.httpClient.get(`${this.environment.api.url}/account`).pipe(
      map((response) => {
        const work = { ...response };
        const bps = work['businessProfiles'].map((profile) => {
          const tzDetails: { name: string; offset: string } = {
            name: profile.timezoneDetails ? profile.timezoneDetails.name : null,
            offset: profile.timezoneDetails && profile.timezoneDetails.name ? moment().tz(profile.timezoneDetails.name).format('Z') : null
          };

          return { ...profile, timezoneDetails: tzDetails };
        });
        const transformed = { ...work, businessProfiles: bps };
        return transformed;
      })
    );
  }

  public retrieveCognitoUser(): Observable<CognitoUser> {
    Auth.currentAuthenticatedUser()
      .then((authenticated: CognitoUser) => {
        if (authenticated) {
          this.refreshSession(
            authenticated,
            (session) => {
              if (session) {
                this.cognitoUserSession$.next(session);
              }
            },
            (error) => {
              this.authError$.next(error);
            }
          );
        } else {
          console.log('AuthService::Authentication is not possible;');
        }
      })
      .catch((error) => {
        this.clearStorageAndGoToLoginPage();
        console.log('AuthService::isSessionValid():: An error occured', error);
      });
    return from(Auth.currentAuthenticatedUser()).pipe(delay(400));
  }

  public refreshToken(): Observable<CognitoUser> {
    const promise: Promise<CognitoUser> = new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then((authenticated) => {
          if (authenticated) {
            this.refreshSession(
              authenticated,
              (session) => {
                if (session) {
                  authenticated.setSignInUserSession(session);
                  this.cognitoUserSession$.next(session);
                }
                resolve(authenticated);
              },
              (error) => {
                this.authError$.next(error);
                reject(error);
              }
            );
          } else {
            console.log('AuthService::Authentication is not possible;');
            reject();
          }
        })
        .catch((error) => {
          console.log('AuthService::isSessionValid():: An error occured', error);
          reject(error);
        });
    });
    return from(promise) as Observable<CognitoUser>;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded: JwtPayload = jwt_decode(token);

    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  clearStorageAndGoToLoginPage() {
    this.storage.clear();
    this.router.navigate(['/login']);
  }

  public tokenExpired(token: string): boolean {
    const expiration: Date = this.getTokenExpirationDate(token);
    const now = new Date().getTime().valueOf();
    return !(expiration.valueOf() > now);
  }

  public ssoTokenExpired(ssoTokenExpiresAt: string): boolean {
    if (!ssoTokenExpiresAt) {
      return true;
    }
    return new Date(ssoTokenExpiresAt).getTime() - Date.now() <= 0;
  }

  isUserAdmin(sessionEntityId: string, currentUserId: string) {
    return this.httpClient
      .get(`${this.environment.api.url}/entities/${sessionEntityId}/admin-profiles`)
      .pipe(map((response: { content: User[] }) => response.content.filter((user) => user.id === currentUserId).length > 0));
  }

  private setSharedCredentials(userId: string, accessToken: string, token: string, idToken: string): void {
    const keyPrefix = `CognitoIdentityServiceProvider.${this.environment.cognito.clientId}`;
    this.storage.set(`${keyPrefix}.${userId}.accessToken`, accessToken, true);
    this.storage.set(`${keyPrefix}.${userId}.refreshToken`, token, true);
    this.storage.set(`${keyPrefix}.${userId}.idToken`, idToken, true);
    this.storage.set(`${keyPrefix}.LastAuthUser`, userId, true);
  }

  private refreshSession(authenticated: CognitoUser, cbSuccess, cbFailure) {
    const expiration: Date = this.getTokenExpirationDate(authenticated.getSignInUserSession().getIdToken().getJwtToken());
    const now = (new Date().getTime() + 1800000).valueOf();
    const tokenExpired = !(expiration.valueOf() > now);
    if (tokenExpired) {
      authenticated.refreshSession(authenticated.getSignInUserSession().getRefreshToken(), (error, session) => {
        if (error) {
          cbFailure(error);
        } else {
          cbSuccess(session);
        }
      });
    } else {
      cbSuccess();
    }
  }
}
