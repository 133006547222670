import { CommonApiRequest, CommonApiResponse, CommonIndexedPagination, Filter } from '@iot-platform/models/common';
import { Product } from '@iot-platform/models/oyan';
import { createAction, props } from '@ngrx/store';

export const loadProducts = createAction('[OYAN PRODUCTS/UI] Load Products', props<{ request: CommonApiRequest }>());
export const loadProductsSuccess = createAction(
  '[OYAN PRODUCTS/API] Load Products Success',
  props<{ response: CommonApiResponse<Product, CommonIndexedPagination> }>()
);
export const loadProductsFailure = createAction('[OYAN PRODUCTS/API] Load Products Failure', props<{ error: unknown }>());

export const loadProductById = createAction('[OYAN PRODUCTS/UI] Load Product By Id', props<{ id: string }>());
export const loadProductByIdSuccess = createAction('[OYAN PRODUCTS/API] Load Product By Id Success', props<{ response: Product }>());
export const loadProductByIdFailure = createAction('[OYAN PRODUCTS/API] Load Product By Id Failure', props<{ error: unknown }>());

export const addProduct = createAction('[OYAN PRODUCTS/UI] Add Product', props<{ product: Product }>());
export const addProductSuccess = createAction('[OYAN PRODUCTS/API] Add Product Success', props<{ response: Product }>());
export const addProductFailure = createAction('[OYAN PRODUCTS/API] Add Product Failure', props<{ error: unknown }>());

export const updateProduct = createAction('[OYAN PRODUCTS/UI] Update Product', props<{ product: Product }>());
export const updateProductSuccess = createAction('[OYAN PRODUCTS/API] Update Product Success', props<{ response: Product }>());
export const updateProductFailure = createAction('[OYAN PRODUCTS/API] Update Product Failure', props<{ error: unknown }>());

export const deleteProduct = createAction('[OYAN PRODUCTS/UI] Delete Product', props<{ product: Product }>());
export const deleteProductSuccess = createAction('[OYAN PRODUCTS/API] Delete Product Success', props<{ response: Product }>());
export const deleteProductFailure = createAction('[OYAN PRODUCTS/API] Delete Product Failure', props<{ error: unknown }>());

export const setFilters = createAction('[OYAN PRODUCTS/UI] Set filters', props<{ filters: Filter[] }>());
