import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceInfoToolbarComponent } from './device-info-toolbar.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [DeviceInfoToolbarComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    TranslateModule,
    MatButtonModule,
    FlexModule,
    MatInputModule
  ],
  exports: [DeviceInfoToolbarComponent]
})
export class DeviceInfoToolbarModule {}
