<div fxFlex fxLayout="column">
  <div class="sticky">
    <iot-platform-ui-toolbar-v2
      [total]="totalItems$ | async"
      [name]="'GATEWAYS.NAME_TOOLBAR' | translate"
      [isDataLoaded]="loaded$ | async"
      [buttonList]="toolbarButtonList"
      (dispatchToolbarEvent)="onToolbarEvent($event)"
    >
      <section class="iot-platform-toolbar-section iot-platform-toolbar-button-section" fxLayout="row" fxLayoutAlign="end center" toolbarCustomSection>
        <button
          mat-mini-fab
          class="iot-platform-toolbar__kerlink-button"
          [matTooltip]="'MV_TOOLBAR.TOOLTIP.KERLINK_ACCESS' | translate"
          matTooltipClass="regular-tooltip"
          color="accent"
          (click)="onKerlinkClick()"
        >
          <span>{{ 'MV_TOOLBAR.TOOLTIP.KERLINK_ACCESS' | translate }}</span>
        </button>
      </section>
    </iot-platform-ui-toolbar-v2>

    <iot-platform-ui-filter-engine-container
      [masterView]="'gateways'"
      [expanded]="filterEngineOpened$ | async"
      [clearAppliedFilters$]="clearAppliedFilters$"
      [currentFilters$]="filtersToDisplay$"
      [currentFavoriteView$]="currentFavoriteView$"
      (applyFilters)="onApplyFilters($event)"
    >
    </iot-platform-ui-filter-engine-container>
  </div>
  <div fxLayout="row" fxFlex>
    <i4b-table-engine-master-view-engine
      fxFlex
      [sticky]="true"
      [masterViewType]="{ name: 'gateways', isPath: false }"
      [useExternalData]="true"
      [data]="platformResponse$ | async"
      [pageSizeOptions]="pageSizeOptions"
      (pageChange)="onPageChange($event)"
      (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
    >
    </i4b-table-engine-master-view-engine>
  </div>
</div>
