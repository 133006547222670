<iot-platform-ui-filter-engine
  *ngIf="!withFavoriteFilters"
  data-cy="iot-platform-ui-filter-engine-container"
  [masterView]="masterView"
  [expanded]="expanded"
  [displayActionButtons]="displayActionButtons"
  [clearAppliedFilters$]="clearAppliedFilters$"
  [currentFavoriteView$]="currentFavoriteView$"
  [currentFilters$]="currentFilters$"
  [readonly]="readonly"
  (applyFilters)="onApplyFilters($event)"
>
</iot-platform-ui-filter-engine>

<iot-platform-ui-favorite-filter-engine
  *ngIf="withFavoriteFilters"
  data-cy="iot-platform-ui-favorite-filter-engine-container"
  [masterView]="masterView"
  [expanded]="expanded"
  [displayManageFavoriteFilters]="displayManageFavoriteFilters"
  [displayActionButtons]="displayActionButtons"
  [currentFavoriteView$]="currentFavoriteView$"
  [currentFilters$]="currentFilters$"
  [readonly]="readonly"
  (applyFilters)="onApplyFilters($event)"
>
</iot-platform-ui-favorite-filter-engine>
