import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthInterceptor, AuthService } from '@iot-platform/auth';
import { AsyncAutocompleteModule, TimezoneAutocompleteModule } from '@iot-platform/iot-platform-ui';

import { DateFormatModule } from '@iot-platform/pipes';

import { SharedModule } from '@iot-platform/shared';
import { UserNamePipe } from '../../../iot-platform-pipes/src/lib/user-name/user-name.pipe';

import { DialogAdministratorAddFormComponent } from './components/dialogs/dialog-administrator-add-form/dialog-administrator-add-form.component';
import { DialogBusinessProfileAddFormComponent } from './components/dialogs/dialog-business-profile-add-form/dialog-business-profile-add-form.component';
// tslint:disable-next-line:max-line-length
import { DialogLinkMembersToBusinessProfileComponent } from './components/dialogs/dialog-link-members-to-business-profile/dialog-link-members-to-business-profile.component';
// tslint:disable-next-line:max-line-length
import { DialogLinkRolesToBusinessProfileComponent } from './components/dialogs/dialog-link-roles-to-business-profile/dialog-link-roles-to-business-profile.component';
import { DialogManageConceptsFormComponent } from './components/dialogs/dialog-manage-concepts-form/dialog-manage-concepts-form.component';
import { DialogOrganizationAddFormComponent } from './components/dialogs/dialog-organization-add-form/dialog-organization-add-form.component';
import { DialogOrganizationEditFormComponent } from './components/dialogs/dialog-organization-edit-form/dialog-organization-edit-form.component';
import { DialogRoleAddFormComponent } from './components/dialogs/dialog-role-add-form/dialog-role-add-form.component';
import { DialogRoleEditFormComponent } from './components/dialogs/dialog-role-edit-form/dialog-role-edit-form.component';
import { DialogUsersAddFormComponent } from './components/dialogs/dialog-users-add-form/dialog-users-add-form.component';
import { OrganizationConceptsSelectionModule } from './components/organization-concepts-selection/organization-concepts-selection.module';
import { RolesCrudModule } from './components/roles-crud/roles-crud.module';
import { AdminShellComponent } from './containers/admin-shell/admin-shell.component';
import { AccountValidationComponent } from './features/account-validation/account-validation.component';
import { AdminBusinessProfilesModule } from './features/admin-business-profiles/admin-business-profiles.module';
import { AdminBusinessProfilesComponent } from './features/admin-business-profiles/containers/admin-business-profiles.component';
import { AdminOrganizationsModule } from './features/admin-organizations/admin-organizations.module';
import { AdminOrganizationsComponent } from './features/admin-organizations/containers/admin-organizations.component';
import { AdminProductCatalogsModule } from './features/admin-product-catalogs/admin-product-catalogs.module';
import { AdminProductCatalogsShellComponent } from './features/admin-product-catalogs/containers/admin-product-catalogs-shell/admin-product-catalogs-shell.component';
import { AdminUsersModule } from './features/admin-users/admin-users.module';
import { AdminUsersComponent } from './features/admin-users/containers/admin-users.component';
import { EmailTemplatesShellComponent } from './features/email-templates/containers/email-templates-shell/email-templates-shell.component';
import { EmailTemplatesModule } from './features/email-templates/email-templates.module';
import { NavigationGuard } from './services/navigation.guard';
import { ErrorInterceptor } from '../../../auth/src/lib/error.interceptor';
import { UserDetailsShellComponent } from './features/admin-users/containers/user-details-shell/user-details-shell.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      { path: 'users', component: AdminUsersComponent },
      { path: 'users/:user_id', component: UserDetailsShellComponent },
      {
        path: 'business-profiles',
        component: AdminBusinessProfilesComponent
      },
      {
        path: 'organizations',
        component: AdminOrganizationsComponent
      },
      {
        path: 'product-catalogs',
        component: AdminProductCatalogsShellComponent
      },
      {
        path: 'i18n',
        loadChildren: () => import('@iot-platform/i18n').then((m) => m.TranslationManagementModule)
      },
      {
        path: 'email-templates',
        component: EmailTemplatesShellComponent
      }
    ]),
    AdminUsersModule,
    AdminBusinessProfilesModule,
    AdminOrganizationsModule,
    RolesCrudModule,
    DateFormatModule,
    OrganizationConceptsSelectionModule,
    AdminProductCatalogsModule,
    AsyncAutocompleteModule,
    EmailTemplatesModule,
    TimezoneAutocompleteModule
  ],
  declarations: [
    AdminShellComponent,
    DialogBusinessProfileAddFormComponent,
    DialogRoleAddFormComponent,
    DialogAdministratorAddFormComponent,
    DialogLinkMembersToBusinessProfileComponent,
    DialogLinkRolesToBusinessProfileComponent,
    DialogUsersAddFormComponent,
    DialogOrganizationAddFormComponent,
    DialogRoleEditFormComponent,
    DialogOrganizationEditFormComponent,
    DialogManageConceptsFormComponent,
    AccountValidationComponent
  ],
  exports: [AdminShellComponent, RouterModule, AdminBusinessProfilesModule],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    NavigationGuard,
    UserNamePipe
  ]
})
export class Iot4bosBackofficeUiModule {}
