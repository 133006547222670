import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'iot4bos-backoffice-ui-admin-shell',
  templateUrl: './admin-shell.component.html',
  styleUrls: ['./admin-shell.component.scss']
})
export class AdminShellComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
