import { ProcessCellForExportParams } from '@ag-grid-community/core';
import { TranslateService } from '@ngx-translate/core';

export class EventDurationCellHelpers {
  static processValue(translateService: TranslateService, params: ProcessCellForExportParams): string {
    const {
      value: { hours, minutes, seconds }
    } = params;
    if (seconds < 0) {
      return 'N/A';
    }
    const _hours = hours > 0 ? `${hours}${translateService.instant('EVENTS.TIMELINE.TIME.HOUR')} ` : '';
    const _minutes = minutes > 0 ? `${minutes}${translateService.instant('EVENTS.TIMELINE.TIME.MINUTE')} ` : '';
    const _seconds = seconds > 0 ? `${seconds}${translateService.instant('EVENTS.TIMELINE.TIME.SECOND')}` : '';
    return `${_hours}${_minutes}${_seconds}`;
  }
}
