<div
  *ngIf="data"
  class="cell"
  [matTooltip]="data[cellOptions.tooltip] | dateFormat"
  matTooltipClass="regular-tooltip"
  matTooltipPosition="above"
  [matTooltipDisabled]="!cellOptions.tooltip"
>
  <span *ngIf="!processedUnit && data[cellOptions.value] !== (undefined || null)">
    {{ data[cellOptions.value] | numberFormat | infoDisplay }} {{ data[cellOptions.unit] }}
  </span>

  <span *ngIf="processedUnit">{{ processedUnit }}</span>

  <span *ngIf="data[cellOptions.value] === (undefined || null)">-</span>
</div>
<div *ngIf="!data" class="cell no-value">-</div>

