import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { CustomEncoder, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { PlatformRequest, PlatformResponse, TagCategory } from '@iot-platform/models/common';

import { Asset, AssetEvent, AssetVariable, Device, DeviceVariable, Log, Site } from '@iot-platform/models/i4b';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class AssetEventsService extends EventsService {
  constructor(@Inject('environment') protected environment, protected httpClient: HttpClient, private storage: LocalStorageService) {
    super(environment, httpClient);
  }

  getAssetEvents(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });

    params = params.set('limit', request.limit?.toString(10) ?? '100');
    params = params.set('page', request.page?.toString(10) ?? '0');

    if (request.filters) {
      request.filters.forEach((filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient.get<AssetEvent[]>(`${this.environment.api.url + this.environment.api.endpoints.assetEvents}`, { params }).pipe(
      map((data: any) => {
        return {
          data: data.content,
          currentPage: data.page.curPage,
          hasMore: data.page.hasMore,
          limit: data.page.limit,
          maxPage: data.page.maxPage,
          total: data.page.total
        };
      })
    );
  }

  getSiteById(siteId: string): Observable<Site> {
    return this.httpClient.get<Site>(this.environment.api.url + this.environment.api.endpoints.sites + '/' + siteId);
  }

  getDeviceById(deviceId: string): Observable<Device | undefined> {
    return !!deviceId ? this.httpClient.get<Device>(this.environment.api.url + this.environment.api.endpoints.devices + '/' + deviceId) : of(undefined);
  }

  getDeviceVariableById(deviceVariableId: string): Observable<DeviceVariable | undefined> {
    return !!deviceVariableId
      ? this.httpClient.get<DeviceVariable>(this.environment.api.url + this.environment.api.endpoints.deviceVariables + '/' + deviceVariableId)
      : of(undefined);
  }

  getAssetById(assetId: string): Observable<Asset> {
    return this.httpClient.get<Asset>(this.environment.api.url + this.environment.api.endpoints.assets + '/' + assetId);
  }

  getAssetVariableById(assetVariableId: string): Observable<AssetVariable> {
    return this.httpClient.get<AssetVariable>(this.environment.api.url + this.environment.api.endpoints.assetVariables + '/' + assetVariableId);
  }

  getLogsById(assetEventId: string): Observable<Log[]> {
    return this.httpClient
      .get<Log[]>(this.environment.api.url + this.environment.api.endpoints.assetEvents + '/' + assetEventId + '/logs')
      .pipe(map((data: any) => data.content));
  }

  putLogById(comment: { assetEvent: AssetEvent; value: string }): Observable<Log> {
    return this.httpClient.put<Log>(`${this.environment.api.url}${this.environment.api.endpoints.assetEvents}/${comment.assetEvent.id}/comments`, {
      comment: comment.value
    });
  }

  getTagsByAssetEventId(assetEventId: string): Observable<TagCategory[]> {
    return this.httpClient
      .get<TagCategory[]>(this.environment.api.url + this.environment.api.endpoints.assetEvents + `/${assetEventId}/tags`)
      .pipe(map((data: any) => data.content));
  }

  putStatus(status: { assetEventId: string; value: string }): Observable<AssetEvent> {
    return this.httpClient.put<AssetEvent>(`${this.environment.api.url}${this.environment.api.endpoints.assetEvents}/${status.assetEventId}/${status.value}`, {
      comment: null
    });
  }

  bulkUpdateStatus(assetEventIds: string[], status: string): Observable<AssetEvent>[] {
    return assetEventIds.map((id) => this.putStatus({ assetEventId: id, value: status }));
  }

  saveTableState(tableState: { selected: AssetEvent; checked: AssetEvent[] }): Observable<{ selected: AssetEvent; checked: AssetEvent[] }> {
    this.storage.set(LocalStorageKeys.STORAGE_MV_ASSET_EVENTS_TABLE_STATE_KEY, JSON.stringify(tableState));
    return of(tableState);
  }
}
