import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PlatformRequest, PlatformResponse, ProductCatalog } from '@iot-platform/models/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminProductCatalogsService {
  constructor(@Inject('environment') private environment, private httpClient: HttpClient) {}

  getAllProductCatalogs(request: PlatformRequest): Observable<PlatformResponse> {
    return this.httpClient.get(`${this.environment.api.url}${this.environment.api.endpoints.productCatalogs}`).pipe(
      map((response: { page: any; content: ProductCatalog[] }) => {
        return {
          data: response.content,
          currentPage: response.page.curPage,
          hasMore: response.page.hasMore,
          limit: response.page.limit,
          maxPage: response.page.maxPage,
          total: response.page.total
        };
      })
    );
  }

  addProductCatalog(productCatalogToAdd: ProductCatalog): Observable<ProductCatalog> {
    return this.httpClient.post<ProductCatalog>(`${this.environment.api.url}${this.environment.api.endpoints.productCatalogs}`, productCatalogToAdd);
  }

  updateProductCatalog(productCatalogToUpdate: ProductCatalog): Observable<ProductCatalog> {
    return this.httpClient.patch<ProductCatalog>(`${this.environment.api.url}${this.environment.api.endpoints.productCatalogs}/${productCatalogToUpdate.id}`, {
      name: productCatalogToUpdate.name,
      entities: productCatalogToUpdate.entities
    });
  }

  deleteProductCatalog(productCatalogToDelete: ProductCatalog): Observable<ProductCatalog> {
    return this.httpClient
      .delete(`${this.environment.api.url}${this.environment.api.endpoints.productCatalogs}/${productCatalogToDelete.id}`)
      .pipe(map((_) => productCatalogToDelete));
  }
}
