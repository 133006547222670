<iot-platform-ui-async-autocomplete
  fxFlex
  displayKey="name.common"
  [placeholder]="placeholder | translate"
  [displayWrapper]='displayWrapper.bind(this)'
  [autocomplete]="true"
  [clearOnSelect]="clearOnSelect"
  [required]="required"
  [errorMessage]="errorMessage"
  [displaySearchIcon]="false"
  [debounceTime]="300"
  [initialItem]="selectedCountry$ | async"
  [minLength]="0"
  [data]="filteredItems$ | async"
  (search)="onSearch($event)"
  (reset)="onReset()"
  (selectionChanged)='onSelect($event)'
></iot-platform-ui-async-autocomplete>
