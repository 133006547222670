import {
  AssetEventsGridData,
  AssetsGridData,
  DeviceEventsGridData,
  DevicesGridData,
  I4BGrid,
  I4BGridData,
  I4BGridOptions,
  SitesGridData
} from '@iot-platform/models/grid-engine';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { SortUtil } from 'libs/shared/src/lib/utils/sort.util';

import * as fromGridsDb from './grids-db.reducer';

export const gridsFeatureKey = 'grids';

export interface GridsState {
  [fromGridsDb.gridsDbFeatureKey]: fromGridsDb.State;
}

export interface State {
  [gridsFeatureKey]: GridsState;
}

export function reducers(state: GridsState | undefined, action: Action) {
  return combineReducers({
    [fromGridsDb.gridsDbFeatureKey]: fromGridsDb.reducer
  })(state, action);
}

export const selectGridsState = createFeatureSelector<GridsState>(gridsFeatureKey);
export const selectGridsDbState = createSelector(selectGridsState, (state: GridsState) => state[fromGridsDb.gridsDbFeatureKey]);

export const {
  selectIds: getGridsIds,
  selectEntities: getGridsEntities,
  selectAll: getAllGrids,
  selectTotal: getTotalGrids
} = fromGridsDb.adapter.getSelectors(selectGridsDbState);

export const getSitesGrids = createSelector(getAllGrids, (grids) => grids.filter((grid) => grid.masterview.toLowerCase() === 'SITES'.toLowerCase()));
export const getAssetsGrids = createSelector(getAllGrids, (grids) => grids.filter((grid) => grid.masterview.toLowerCase() === 'ASSETS'.toLowerCase()));
export const getDevicesGrids = createSelector(getAllGrids, (grids) => grids.filter((grid) => grid.masterview.toLowerCase() === 'DEVICES'.toLowerCase()));
export const getDeviceEventsGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'DEVICE-EVENTS'.toLowerCase())
);
export const getAssetEventsGrids = createSelector(getAllGrids, (grids) =>
  grids.filter((grid) => grid.masterview.toLowerCase() === 'ASSET-EVENTS'.toLowerCase())
);

export const getSelectedGridId = createSelector(selectGridsDbState, fromGridsDb.getSelectedGridId);
export const getSelectedGrid = createSelector(getGridsEntities, getSelectedGridId, (entities, selectedId) => selectedId && entities[selectedId]);
export const getSelectedGridByConcept = createSelector(getGridsEntities, getSelectedGridId, (entities, selectedId) => selectedId && entities[selectedId]);
export const getSelectedGridData = createSelector(getGridsEntities, getSelectedGridId, (entities, selectedId) => selectedId && entities[selectedId].data);
export const getSelectedItemIdInSelectedGrid = createSelector(selectGridsDbState, fromGridsDb.getSelectedGridId);
export const getSelectedIds = createSelector(selectGridsDbState, fromGridsDb.getSelectedIds);
export const getDefaultGrids = createSelector(selectGridsDbState, fromGridsDb.getDefaultGrids);
export const selectRefreshActivated = createSelector(selectGridsDbState, fromGridsDb.selectRefreshActivated);

//
export const getDefaultSitesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['sites']) {
    const defaultGrid = defaultGrids['sites'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, SitesGridData> | undefined;
  }
});

export const getDefaultAssetsGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['assets']) {
    const defaultGrid = defaultGrids['assets'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, AssetsGridData> | undefined;
  }
});

export const getDefaultDevicesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['devices']) {
    const defaultGrid = defaultGrids['devices'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, DevicesGridData> | undefined;
  }
});

export const getDefaultDeviceEventsGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['device-events']) {
    const defaultGrid = defaultGrids['device-events'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined;
  }
});

export const getDefaultAssetEventsGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['asset-events']) {
    const defaultGrid = defaultGrids['asset-events'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, AssetEventsGridData> | undefined;
  }
});

export const getDefaultEmailTemplatesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['email-templates']) {
    const defaultGrid = defaultGrids['email-templates'];
    const grid = grids[defaultGrid.gridId];
    return grid;
  }
});

export const getDefaultConnectorsGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['connectors']) {
    const defaultGrid = defaultGrids['connectors'];
    const grid = grids[defaultGrid.gridId];
    return grid;
  }
});

export const selectDefaultStockSiteDevicesGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['stock-site-devices']) {
    const defaultGrid = defaultGrids['stock-site-devices'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, DevicesGridData> | undefined;
  }
});

export const selectDefaultActiveAssetEventsPopupGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['active-asset-events-popup']) {
    const defaultGrid = defaultGrids['active-asset-events-popup'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, AssetEventsGridData> | undefined;
  }
});

export const getDefaultPoEventGeneratedAssetEventsGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['po-event-generated-asset-events']) {
    const defaultGrid = defaultGrids['po-event-generated-asset-events'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, AssetEventsGridData> | undefined;
  }
});

export const getDefaultPoEventGeneratedDeviceEventsGrid = createSelector(getDefaultGrids, getGridsEntities, (defaultGrids, grids) => {
  if (grids && defaultGrids && defaultGrids['po-event-generated-device-events']) {
    const defaultGrid = defaultGrids['po-event-generated-device-events'];
    const grid = grids[defaultGrid.gridId];
    return grid as I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined;
  }
});

export const getDataLoadingByGrid = (gridId: string) =>
  createSelector(getSelectedIds, (ids) => {
    return ids[gridId].dataLoading;
  });

export const getSortByGrid = (gridId: string) =>
  createSelector(getGridsEntities, (ids) => {
    return ids[gridId] ? ids[gridId].gridOptions.gridSort : [];
  });

export const getDataLoadedByGrid = (gridId: string) =>
  createSelector(getSelectedIds, (ids) => {
    if (gridId && ids[gridId]) {
      return ids[gridId].dataLoaded;
    } else {
      return true;
    }
  });

export const getSelectedItemIdInGrid = (gridId: string) =>
  createSelector(getSelectedIds, (ids) => {
    return ids[gridId].selectedItemId;
  });

export const getSelectedItemInSelectedGrid = createSelector(getGridsEntities, getSelectedGridId, getSelectedIds, (entities, selectedGridId, selectedIds) => {
  if (selectedGridId && selectedIds && entities) {
    const selectedItemId = selectedIds[selectedGridId].selectedItemId;
    return (entities[selectedGridId].data as I4BGridData)?.response?.data?.find((data) => data.id === selectedItemId);
  }
});

export const selectItemInGrid = (gridId: string, itemId: string) =>
  createSelector(getGridsEntities, (entities) => {
    if (entities) {
      return (entities[gridId].data as I4BGridData).response.data.filter((data) => data.id === itemId)[0];
    }
  });

export const selectSiteGridsConfiguration = createSelector(getDefaultSitesGrid, getSitesGrids, (defaultGrid, grids) => {
  return {
    sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
    currentGrid: defaultGrid,
    isGridsLoading: false
  };
});

export const selectAssetGridsConfiguration = createSelector(getDefaultAssetsGrid, getAssetsGrids, (defaultGrid, grids) => {
  return {
    sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
    currentGrid: defaultGrid,
    isGridsLoading: false
  };
});

export const selectDeviceGridsConfiguration = createSelector(getDefaultDevicesGrid, getDevicesGrids, (defaultGrid, grids) => {
  return {
    sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
    currentGrid: defaultGrid,
    isGridsLoading: false
  };
});

export const selectAssetEventGridsConfiguration = createSelector(getDefaultAssetEventsGrid, getAssetEventsGrids, (defaultGrid, grids) => {
  return {
    sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
    currentGrid: defaultGrid,
    isGridsLoading: false
  };
});

export const selectDeviceEventGridsConfiguration = createSelector(getDefaultDeviceEventsGrid, getDeviceEventsGrids, (defaultGrid, grids) => {
  return {
    sortedGridsWithoutAppDefault: grids.filter((g: I4BGrid<I4BGridOptions, I4BGridData>) => !g.isAppDefault).sort(SortUtil.sortByName),
    currentGrid: defaultGrid,
    isGridsLoading: false
  };
});
