<form [formGroup]="inputsForm" fxLayout='row wrap' fxLayoutGap="30px" fxLayoutAlign="start center" class="multiple-inputs-container">
  <div *ngFor="let input of inputs" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-form-field class="input-field" color="accent">
      <input
        [formControlName]="input.criteriaKey"
        (keyup.enter)="onInputValidation(input.criteriaKey)"
        matInput
        [placeholder]="input.placeholder | translate"
      />
    </mat-form-field>

    <button
      mat-mini-fab
      class="add-button-off"
      color="accent"
      type="button"
      [disabled]="!inputsForm.get(input.criteriaKey).value"
      [ngClass]="{ 'add-button-on': inputsForm.get(input.criteriaKey).value }"
      (click)="onInputValidation(input.criteriaKey)"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
</form>
