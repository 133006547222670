import { GridEngineModule } from '@iot-platform/grid-engine';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@iot-platform/auth';
import { TableEngineModule } from '@iot-platform/table-engine';
import { SharedModule } from '@iot-platform/shared';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { AdminProductCatalogsShellComponent } from './containers/admin-product-catalogs-shell/admin-product-catalogs-shell.component';
import { AdminProductCatalogsService } from './services/admin-product-catalogs.service';
import { AdminProductsService } from './services/admin-products.service';
import { StoreModule } from '@ngrx/store';
import * as fromProducts from './state/reducers';
import { EffectsModule } from '@ngrx/effects';
import { ProductCatalogsEffects } from './state/effects/product-catalogs.effects';
import { ProductsEffects } from './state/effects/products.effects';
import { AdminProductCreationFormComponent } from './components/admin-product-creation-form/admin-product-creation-form.component';
import { AdminProductCatalogPanelComponent } from './components/admin-product-catalog-panel/admin-product-catalog-panel.component';
import { AdminCatalogsEditionPopupComponent } from './components/admin-catalogs-edition-popup/admin-catalogs-edition-popup.component';
import { CatalogEditorFormComponent } from './components/admin-catalogs-edition-popup/catalog-editor-form/catalog-editor-form.component';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { ErrorInterceptor } from '../../../../../auth/src/lib/error.interceptor';

@NgModule({
  imports: [
    CommonModule,
    TableEngineModule,
    SharedModule,
    IotPlatformUiModule,
    StoreModule.forFeature(fromProducts.productsFeatureKey, fromProducts.reducers),
    EffectsModule.forFeature([ProductCatalogsEffects, ProductsEffects]),
    IotPlatformPipesModule,
    GridEngineModule
  ],
  declarations: [
    AdminProductCatalogsShellComponent,
    AdminProductCreationFormComponent,
    AdminProductCatalogPanelComponent,
    AdminCatalogsEditionPopupComponent,
    CatalogEditorFormComponent
  ],
  providers: [
    AdminProductCatalogsService,
    AdminProductsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  exports: []
})
export class AdminProductCatalogsModule {}
