export class HashUtils {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  static simpleHashFromObj = (obj: Record<string, any>) => {
    const str = JSON.stringify(obj);
    let hash = 0,
      i = 0;
    const len = str.length;
    while (i < len) {
      hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0;
    }
    return `${hash}`;
  };
}
