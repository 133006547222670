<iot4bos-ui-device-info-toolbar (applyFilters)="applyFilters($event)"
  (clearFilters)="clearFilters($event)"
  *ngIf="device$ | async"
  [deviceVariableFiles]="variableFiles"
  [deviceVariableGroups]="variableGroups"
  [device]="device$ | async"
  [displayLoader]="!(variablesLoaded$ | async)"
  [filters]="filters$ | async"
  [totalFiltered]="totalFiltered"
  [total]="totalVariables"
  [withDescriptionFilter]="true"
  [withFileFilter]="true"
  [withGroupFilter]="true"
  [withLinkedFilter]="true"
  [withNameFilter]="true"
>
</iot4bos-ui-device-info-toolbar>

<div style="flex: 1;">
  <grid-engine-grid-engine-component
    (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
    [displayPagination]="false"
    [gridData]="variables$ | async"
    [gridMeta]="mvSettings$ | async"
    [userPermissions]="userPermissions$ | async"
    class="layout-ag-grid-shell"
    fxFlex
  >
  </grid-engine-grid-engine-component>
</div>
