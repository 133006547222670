import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { CustomCellParams } from '../custom-cell.params';

import { TopicSelfSubscriptionCellService } from './topic-self-subscription-cell.service';

//
@Component({
  selector: 'grid-engine-topic-self-subscription-cell',
  templateUrl: './topic-self-subscription-cell.component.html',
  styleUrls: ['./topic-self-subscription-cell.component.scss']
})
export class TopicSelfSubscriptionCellComponent implements OnDestroy, ICellRendererAngularComp {
  params: CustomCellParams;

  subscribersLoaded = false;
  subscribed: boolean;

  destroyed$ = new Subject();

  constructor(private readonly store: Store, private readonly topicSelfSubscriptionService: TopicSelfSubscriptionCellService) {}

  agInit(params: CustomCellParams): void {
    this.params = params;

    this.subscribed = !!this.params.data?.subscribedAt;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  onDispatchEvent(event: MouseEvent) {
    if (event) {
      event.stopPropagation();
      this.params.dispatchEvent({
        type: this.params.eventConfiguration.type,
        options: { ...this.params.eventConfiguration.options, subscribed: this.subscribed },
        rawData: this.params.data
      });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
  }
}
