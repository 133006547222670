<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'ADMIN.DIALOG.LINK_MEMBERS_TO_BP.TITLE' | translate: param }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="exit()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content fxLayout="row" fxLayoutGap="20px" class="dialog-card-content p-20">
    <section fxLayout="column" fxFlex class="">
      <mat-toolbar fxLayout="row" color="primary" fxLayoutGap="10px" fxLayoutAlign="start center">
        <p>{{ unselectedUsers.length }} {{ 'ADMIN.DIALOG.LINK_MEMBERS_TO_BP.AVAILABLE_USERS' | translate | uppercase }}</p>
      </mat-toolbar>

      <iot-platform-ui-async-autocomplete
        fxFlex class="filter-input"
        displayKey="email"
        placeholder="ADMIN.DIALOG.ADD_ADMIN.FILTER_USERS"
        [displayWrapper]="displayUserWrapper"
        [autocomplete]="false"
        [debounceTime]="300"
        [minLength]="3"
        [showSpinner]="loadingUsers$ | async"
        [data]="filteredUnselectedUsers"
        (search)="onSearchUsers($event)"
      ></iot-platform-ui-async-autocomplete>

      <mat-list fxFlex class="height-300 two-line-list">
        <mat-list-item *ngFor="let user of filteredUnselectedUsers" class="hoverable">
          <h3 matLine>{{ user.firstname }} {{ user.lastname }}</h3>
          <button mat-icon-button class="regular-round-button" (click)="addMemberToProfile(user)">
            <mat-icon>person_add</mat-icon>
          </button>
          <p matLine class="complementary-line">
            <span> {{ 'ADMIN.DIALOG.LINK_MEMBERS_TO_BP.LAST_ACTIVITY' | translate }}</span>
            <span>{{ user.lastActivity | dateFormat }}</span>
          </p>
        </mat-list-item>
      </mat-list>
    </section>

    <section fxLayout="column" fxFlex class="">
      <mat-toolbar fxLayout="row" color="primary" fxLayoutAlign="start center">
        <p>{{ currentMembers.length }}{{ 'ADMIN.DIALOG.LINK_MEMBERS_TO_BP.SELECTED_MEMBERS' | translate | uppercase }}</p>
      </mat-toolbar>

      <mat-form-field class="filter-input" color="accent">
        <mat-label>{{ 'ADMIN.DIALOG.ADD_ADMIN.FILTER_USERS' | translate }}</mat-label>
        <mat-icon matPrefix class="search-icon">search</mat-icon>
        <input matInput type="text" (keyup)="filterUsers($event, currentMembers, true)" />
      </mat-form-field>

      <mat-list fxFlex class="height-300 two-line-list">
        <mat-list-item *ngFor="let user of filteredCurrentUsers" class="hoverable">
          <h3 matLine>{{ user.firstname }} {{ user.lastname }}</h3>
          <button mat-icon-button class="regular-round-button" (click)="removeMemberFromProfile(user)">
            <mat-icon>delete</mat-icon>
          </button>
          <p matLine class="complementary-line">
            <span>{{ 'ADMIN.DIALOG.LINK_MEMBERS_TO_BP.LAST_ACTIVITY' | translate }}</span>
            <span>{{ user.lastActivity | dateFormat }}</span>
          </p>
        </mat-list-item>
      </mat-list>
    </section>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button color="accent" class="button-regular" (click)="exit()">{{ 'ADMIN.DIALOG.LINK_MEMBERS_TO_BP.DONE' | translate }}</button>
  </mat-card-actions>
</mat-card>
