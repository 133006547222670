<div class="basic-cell" fxLayout="row" fxLayoutAlign="start center">
  <span [ngClass]="{ capitalize: cellOptions?.capitalize }" class="basic-cell">
    <span>{{ params?.value }}</span>
  </span>
  <mat-icon
    *ngIf="displayInfoIcon$ | async"
    [matTooltipClass]="'regular-tooltip'"
    [matTooltip]="'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OUTGOING_CONNECTOR_MISSING_CREDENTIALS' | translate"
    class="icon-info"
    matTooltipPosition="above"
  >info_outline</mat-icon>
</div>
