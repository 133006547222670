import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'iot4bos-ui-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnChanges {
  @Input() data!: { placeholder: string; value: string };

  inputForm: UntypedFormGroup = new UntypedFormGroup({ value: new UntypedFormControl(null) });
  placeholder$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  @Output() valueChange: EventEmitter<{ name: string | null; value: string; valid: boolean; touched: boolean }> = new EventEmitter();

  get control(): AbstractControl {
    return this.inputForm.get('value') as UntypedFormControl;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].firstChange) {
      this.placeholder$.next(changes['data'].currentValue.placeholder);
      this.control.setValue(changes['data'].currentValue.value);
      this.control.setValidators([Validators.required]);
      this.control.updateValueAndValidity();
      this.valueChange.emit({ name: changes['data'].currentValue.placeholder, value: this.control.value, valid: this.control.valid, touched: false });
    }
  }

  onValueChange(): void {
    this.valueChange.emit({
      name: this.placeholder$.getValue(),
      value: this.control.value,
      valid: this.control.valid,
      touched: true
    });
  }
}
