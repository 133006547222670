import { Injectable } from '@angular/core';

import { Log } from '@iot-platform/models/i4b';

import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { DeviceEventsService } from '../../../../../../shared/src/lib/services/device-events.service';
import { DeviceEventsBySiteLogsDbActions, DeviceEventsBySiteLogsUiActions } from '../actions';

@Injectable()
export class DeviceEventsBySiteLogsEffects {
  loadLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsBySiteLogsUiActions.loadLogsByDeviceEventId),
      switchMap((action) =>
        this.deviceEventsService.getLogsById(action.deviceEventId).pipe(
          map((logs: Log[]) => DeviceEventsBySiteLogsDbActions.loadLogsByDeviceEventIdSuccess({ logs })),
          catchError((error) => of(DeviceEventsBySiteLogsDbActions.loadLogsByDeviceEventIdFailure({ error })))
        )
      )
    )
  );

  createLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsBySiteLogsUiActions.createLogByDeviceEventId),
      switchMap((action) =>
        this.deviceEventsService.putLogById(action.comment).pipe(
          map((log: Log) => DeviceEventsBySiteLogsDbActions.createLogByDeviceEventIdSuccess({ log, deviceEvent: action.comment.deviceEvent })),
          catchError((error) => of(DeviceEventsBySiteLogsDbActions.createLogByDeviceEventIdFailure({ error })))
        )
      )
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceEventsBySiteLogsDbActions.createLogByDeviceEventIdSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceEventsBySiteLogsDbActions.loadLogsByDeviceEventIdFailure, DeviceEventsBySiteLogsDbActions.createLogByDeviceEventIdFailure),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private deviceEventsService: DeviceEventsService, private notificationService: NotificationService) {}
}
