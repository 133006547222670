<mat-sidenav-container style="height: 100%" (backdropClick)="onCloseComments()" hasBackdrop="true">
  <mat-sidenav-content>
    <mat-tab-group mat-align-tabs="center" color="accent">
      <mat-tab label="{{ 'DEVICES.TABS.INFO' | translate | uppercase }}">
        <iot4bos-ui-device-info
          [canDelete]="canDelete"
          [canCreate]="canCreate"
          [canUpdate]="canUpdate"
          [canRead]="canRead"
          [canReadAuditTrail]="canReadAuditTrail"
          [site]="site$ | async"
          (configureDeviceConnector)="onConfigureDeviceConnector($event)"
        ></iot4bos-ui-device-info>
      </mat-tab>
      <mat-tab class="mat-tab">
        <ng-template mat-tab-label>
          {{ 'DEVICES.TABS.DEVICE_VARIABLES' | translate | uppercase }}
        </ng-template>
        <ng-template matTabContent>
          <iot4bos-ui-device-variables-table fxFlex fxLayout="column" style="padding: 10px 20px"></iot4bos-ui-device-variables-table>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="canReadEvent">
        <ng-template matTabLabel>
          <div fxLayout="row" fxLayoutAlign="start center">
            <span>{{ 'IOT_DICTIONARY.device-events' | translate | uppercase }}</span
            >&nbsp;
            <mat-progress-spinner *ngIf="!(deviceEventsByDeviceLoaded$ | async)" mode="indeterminate" color="accent" [diameter]="14"></mat-progress-spinner>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <div class="layout-master-view-engine-container" fxLayoutGap="10px" fxLayout="column">
            <iot-platform-ui-filter-engine-container
              [masterView]="'device-events-by-device'"
              [expanded]="filterEngineOpened"
              [displayActionButtons]="true"
              [withFavoriteFilters]="true"
              [clearAppliedFilters$]="clearAppliedFilters$"
              [currentFavoriteView$]="currentFavoriteView$"
              [currentFilters$]="deviceEventsByDeviceCurrentFilters$"
              (applyFilters)="onApplyFilters($event)"
            >
            </iot-platform-ui-filter-engine-container>

            <iot-platform-ui-detail-popup-section-header
              [headerTitle]="'DEVICES.EVENT_TABS.FILTERED_DEVICE_EVENTS' | translate: { total: (externalDeviceEventsByDevice$ | async)?.total | numberFormat }"
              [loading]="!(deviceEventsByDeviceLoaded$ | async)"
            ></iot-platform-ui-detail-popup-section-header>

            <grid-engine-grid-engine-component
              fxFlex
              class="layout-ag-grid-shell"
              [gridData]="externalDeviceEventsByDevice$ | async"
              [gridMeta]="mvDeviceEventsByDeviceSettings$ | async"
              [userPermissions]="userPermissions"
              (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
              (pageChange)="onEventsPageChange($event)"
            >
            </grid-engine-grid-engine-component>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab class="mat-tab">
        <ng-template mat-tab-label>
          {{ 'DEVICES.TABS.CALL_LOG' | translate | uppercase }}
        </ng-template>
        <ng-template matTabContent>
          <iot4bos-ui-device-call-log [deviceName]="(device$ | async).name" fxFlex fxLayout="column" style="padding: 10px 20px"></iot4bos-ui-device-call-log>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-sidenav-content>

  <mat-sidenav class="sidenav" #sidenav position="end" disableClose>
    <iot4bos-ui-event-comments
      [eventType]="eventType"
      [resetLogs$]="resetLogs$"
      [logsByEvent]="logsByDeviceEvent$ | async"
      [canUpdateEvent]="canUpdateEvent"
      (addComment)="onAddComment($event)"
      (closeComments)="onCloseComments()"
    >
    </iot4bos-ui-event-comments>
  </mat-sidenav>
</mat-sidenav-container>
