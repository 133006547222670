import { CommonApiRequest, CommonApiResponse, CommonIndexedPagination, Filter } from '@iot-platform/models/common';
import { Message } from '@iot-platform/models/oyan';
import { createAction, props } from '@ngrx/store';

export const loadMessages = createAction('[OYAN COMMUNICATIONS/UI] Load Messages', props<{ request: CommonApiRequest }>());
export const loadMessagesSuccess = createAction(
  '[OYAN COMMUNICATIONS/API] Load Messages Success',
  props<{ response: CommonApiResponse<Message, CommonIndexedPagination> }>()
);
export const loadMessagesFailure = createAction('[OYAN COMMUNICATIONS/API] Load Messages Failure', props<{ error: unknown }>());

export const setFilters = createAction('[OYAN COMMUNICATIONS/UI] Set filters', props<{ filters: Filter[] }>());
