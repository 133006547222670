<ng-container *ngIf="!fabIconButton">
  <button data-cy='iot-platform-ui-progress-bar-button' mat-raised-button class="progress-bar-button"
          [matTooltip]="tooltip | translate"
          [ngClass]="cssClasses"
          [color]="buttonColor" [disabled]="disabled" (click)="onClick($event)">
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    <mat-progress-bar
      class="progress-bar-button-gauge"
      [color]="gaugeColor"
      [mode]="mode"
      [value]="value"
      [bufferValue]="bufferValue"
      *ngIf="enableGauge"
    ></mat-progress-bar>
    <span class="progress-bar-button-title">{{ title }}</span>
  </button>
</ng-container>

<ng-container *ngIf="fabIconButton">
  <button data-cy='iot-platform-ui-progress-bar-button' mat-icon-button mat-mini-fab class="progress-spinner-button"
          [matTooltip]="tooltip | translate"
          [color]="buttonColor" [ngClass]="cssClasses"
          [disabled]="disabled" (click)="onClick($event)">
    <mat-icon>{{ icon }}</mat-icon>
    <mat-progress-spinner
      class="progress-spinner-button__gauge"
      *ngIf="enableGauge"
      [color]="spinnerColor"
      [mode]="mode"
      [diameter]="value"></mat-progress-spinner>
  </button>
</ng-container>
