import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { EventSeverityDisplayComponent } from './event-severity-display.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [EventSeverityDisplayComponent],
  exports: [EventSeverityDisplayComponent]
})
export class EventSeverityDisplayModule {}
