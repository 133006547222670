import { NgModule } from '@angular/core';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { Iot4bosBackofficeUiModule } from '@iot-platform/iot4bos-backoffice-ui';
import { SharedModule } from '@iot-platform/shared';
import { ProfileModule, UserRelatedBusinessProfilesModule } from '@iot-platform/users';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MasterViewEngineModule } from '../../../../../table-engine/src/lib/components/master-view-engine/master-view-engine.module';
import { AdminRouterModule } from './admin.router.module';
import { UserFormDialogComponent } from './components/user-form-dialog/user-form-dialog.component';
import { UserLinkBusinessProfileDialogComponent } from './components/user-link-business-profile-dialog/user-link-business-profile-dialog.component';
import { UserPreferencesFormDialogComponent } from './components/user-preferences-form-dialog/user-preferences-form-dialog.component';
import { UserProfileInfoComponent } from './components/user-profile-info/user-profile-info.component';
import { UserProfilePreferencesComponent } from './components/user-profile-preferences/user-profile-preferences.component';
import { AdminUsersComponent } from './containers/admin-users/admin-users.component';
import { UserProfileShellComponent } from './containers/user-profile-shell/user-profile-shell.component';
import { AdminBusinessProfilesEffects } from './state/effects/admin-business-profiles.effects';
import * as fromAdmin from './state/reducers';
import { IotPlatformPipesModule } from '@iot-platform/pipes';

@NgModule({
  imports: [
    SharedModule,
    AdminRouterModule,
    IotPlatformUiModule,
    MasterViewEngineModule,
    Iot4bosBackofficeUiModule,
    ProfileModule,
    IotPlatformPipesModule,
    StoreModule.forFeature(fromAdmin.featureKey, fromAdmin.reducers),
    EffectsModule.forFeature([AdminBusinessProfilesEffects]),
    UserRelatedBusinessProfilesModule
  ],
  declarations: [
    AdminUsersComponent,
    UserLinkBusinessProfileDialogComponent,
    UserFormDialogComponent,
    UserProfileShellComponent,
    UserPreferencesFormDialogComponent,
    UserProfileInfoComponent,
    UserProfilePreferencesComponent
  ]
})
export class OyanUiAdminModule {}
