import { Injectable } from '@angular/core';
import { fromAdminBusinessProfiles } from '@iot-platform/iot4bos-backoffice-ui';
import { BaseFacade, BusinessProfile, Filter, Pagination, User } from '@iot-platform/models/common';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  BusinessProfilesMembersDialogActions,
  BusinessProfilesPageActions
} from '../../../../../../../iot4bos-backoffice-ui/src/lib/features/admin-business-profiles/state/actions';
import { AdminBusinessProfilesSelectors } from '../selectors/admin-business-profiles.selectors';

@Injectable({
  providedIn: 'root'
})
export class AdminBusinessProfilesFacade extends BaseFacade<BusinessProfile, Pagination, Filter> {
  allBusinessProfilesEntities$: Observable<Dictionary<BusinessProfile>> = this.store.select(fromAdminBusinessProfiles.getBusinessProfilesEntities);
  allBusinessProfiles$: Observable<BusinessProfile[]> = this.store.select(fromAdminBusinessProfiles.getAllBusinessProfiles);

  userBusinessProfiles$: Observable<BusinessProfile[]> = this.store.select(this.selector.selectAll);
  selectedBusinessProfile$: Observable<BusinessProfile> = this.store.select(this.selector.selectSelectedBusinessProfile);

  constructor(protected store: Store, protected selector: AdminBusinessProfilesSelectors) {
    super(store, selector);
  }

  getAll(): void {
    this.store.dispatch(BusinessProfilesPageActions.listBusinessProfiles());
  }

  setFilters(filters: Filter[]): void {
    this.filters$ = of(filters);
  }

  unlinkBusinessProfileFromMember(businessProfileId: string, user: User) {
    this.store.dispatch(
      BusinessProfilesMembersDialogActions.removeMemberFromBusinessProfile({
        businessProfileId,
        memberToRemove: user
      })
    );
  }

  linkBusinessProfileToMember(businessProfileId: string, user: User) {
    this.store.dispatch(
      BusinessProfilesMembersDialogActions.addMemberToBusinessProfile({
        businessProfileId,
        memberToAdd: user
      })
    );
  }
}
