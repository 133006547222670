import { GetUtils } from '@iot-platform/iot-platform-utils';
import { BaseState, CommonIndexedPagination, Filter, INITIAL_BASE_STATE } from '@iot-platform/models/common';
import { Gateway } from '@iot-platform/models/oyan';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SiteGatewaysActions } from '../actions';

export const featureKey = 'sitesGatewaysApiFeatureKey';

export type State = BaseState<Gateway, CommonIndexedPagination, Filter>;

export const adapter: EntityAdapter<Gateway> = createEntityAdapter<Gateway>({
  selectId: (entity: Gateway) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, hasMore: false, limit: 10, maxPage: 0, total: 0 }
});

export const reducer = createReducer(
  initialState,

  on(
    SiteGatewaysActions.loadGatewaysBySiteSuccess,
    (state: State, { response }): State => ({
      ...adapter.setAll(GetUtils.get(response, 'data', []), { ...state, pagination: response.pagination, selectedId: null, entity: null })
    })
  ),

  on(
    SiteGatewaysActions.addGatewayToSiteSuccess,
    (state: State, { gateway }): State => ({
      ...adapter.addOne(gateway, { ...state, entity: gateway })
    })
  ),

  on(
    SiteGatewaysActions.removeGatewayFromSiteSuccess,
    (state: State, { gateway }): State => ({
      ...adapter.removeOne(gateway.id, state)
    })
  ),

  on(
    SiteGatewaysActions.loadGatewaysBySite,
    SiteGatewaysActions.addGatewayToSite,
    SiteGatewaysActions.removeGatewayFromSite,
    SiteGatewaysActions.synchronizeGatewaysBySite,
    (state: State): State => ({
      ...state,
      loading: true
    })
  ),
  on(
    SiteGatewaysActions.loadGatewaysBySiteSuccess,
    SiteGatewaysActions.addGatewayToSiteSuccess,
    SiteGatewaysActions.removeGatewayFromSiteSuccess,
    SiteGatewaysActions.synchronizeGatewaysBySiteSuccess,
    (state: State): State => ({
      ...state,
      loading: false,
      loaded: true
    })
  ),
  on(
    SiteGatewaysActions.loadGatewaysBySiteFailure,
    SiteGatewaysActions.addGatewayToSiteFailure,
    SiteGatewaysActions.removeGatewayFromSiteFailure,
    SiteGatewaysActions.synchronizeGatewaysBySiteFailure,
    (state: State, { error }): State => ({
      ...state,
      loading: false,
      loaded: false,
      error
    })
  )
);
