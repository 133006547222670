import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'grid-engine-count-cell',
  templateUrl: './count-cell.component.html',
  styleUrls: ['./count-cell.component.scss']
})
export class CountCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
