import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridExportService } from '@iot-platform/grid-engine';
import {
  ADD_BUTTON_CONFIG,
  CLEAR_FILTERS_BUTTON_CONFIG,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  REFRESH_BUTTON_CONFIG,
  TOGGLE_FILTER_ENGINE_BUTTON_CONFIG
} from '@iot-platform/iot-platform-ui';
import { IotToolbarEvent, MasterViewEngineEvent, User } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  AuthorizationService as I4bAuthorizationService
} from '../../../../../../../auth/src/lib/services/authorization.service';
import {
  AdminUsersComponent as I4bAdminUsersComponent
} from '../../../../../../../iot4bos-backoffice-ui/src/lib/features/admin-users/containers/admin-users.component';
import {
  UserPreferencesService
} from '../../../../../../../users/src/lib/features/preferences/services/user-preferences.service';
import { AuthorizationService } from '../../../auth/services/authorization.service';
import { OyanAuthorizationConcept, OyanAuthorizationType } from '../../../auth/types/authorization.types';
import { UserFormDialogComponent } from '../../components/user-form-dialog/user-form-dialog.component';
import {
  UserLinkBusinessProfileDialogComponent
} from '../../components/user-link-business-profile-dialog/user-link-business-profile-dialog.component';
import { AdminBusinessProfilesActions } from '../../state/actions';
import { AdminBusinessProfilesFacade } from '../../state/facades/admin-business-profiles.facade';
import { AdminUsersFacade } from '../../state/facades/admin-users.facade';

@Component({
  selector: 'oyan-ui-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminUsersComponent extends I4bAdminUsersComponent {
  canDeleteBusinessProfile: boolean;
  canCreateBusinessProfile: boolean;
  userPermissions$: BehaviorSubject<Array<{ key: string; value: boolean }>> = new BehaviorSubject([]);
  clearAppliedFilters$: Subject<boolean> = new Subject<boolean>();
  canUpdateUser: boolean;

  constructor(
    private readonly ref: ChangeDetectorRef,
    protected userPrefService: UserPreferencesService,
    protected translateService: TranslateService,
    protected store: Store,
    protected dialog: MatDialog,
    protected i4bAuthorizationService: I4bAuthorizationService,
    protected authorizationService: AuthorizationService,
    private readonly adminBusinessProfilesFacade: AdminBusinessProfilesFacade,
    private readonly adminUsersFacade: AdminUsersFacade,
    protected readonly gridExportService: GridExportService,
    @Inject('environment') protected readonly environment
  ) {
    super(translateService, userPrefService, store, dialog, i4bAuthorizationService, gridExportService, environment);
    this.initUserPermissions();
  }

  initToolbarButtonList(): void {
    this.adminUsersButtonList = [
      new IotToolbarDefaultButton({ ...ADD_BUTTON_CONFIG, displayButton: this.canCreateUser }, 0),
      new IotToolbarDefaultButton({ ...CLEAR_FILTERS_BUTTON_CONFIG, tooltip: 'MV_TOOLBAR.TOOLTIP.CLEAR_APPLIED_FILTERS' }, 1),
      new IotToolbarDefaultButton({ ...TOGGLE_FILTER_ENGINE_BUTTON_CONFIG, tooltip: 'MV_TOOLBAR.TOOLTIP.SHOW_FILTER_ENGINE' }, 2),
      new IotToolbarDefaultButton({ ...REFRESH_BUTTON_CONFIG, tooltip: 'MV_TOOLBAR.TOOLTIP.REFRESH_MV' }, 3)
    ];
  }

  initUserPermissions(): void {
    this.canUpdateUserStatus = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.USER_STATUS, OyanAuthorizationType.UPDATE);
    this.canUpdateUser = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.USER, OyanAuthorizationType.UPDATE);
    this.canCreateUser = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.USER, OyanAuthorizationType.CREATE);
    this.canDeleteUser = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.USER, OyanAuthorizationType.DELETE);
    this.canCreateBusinessProfile = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.BUSINESS_PROFILE, OyanAuthorizationType.CREATE);
    this.canDeleteBusinessProfile = this.authorizationService.applyAuthorization(OyanAuthorizationConcept.BUSINESS_PROFILE, OyanAuthorizationType.DELETE);
    this.userPermissions$.next([
      { key: 'canUpdateUserStatus', value: this.canUpdateUserStatus },
      { key: 'canCreateUser', value: this.canCreateUser },
      { key: 'canDeleteUser', value: this.canDeleteUser },
      { key: 'canUpdateUser', value: this.canUpdateUser },
      { key: 'canCreateBusinessProfile', value: this.canCreateBusinessProfile },
      { key: 'canDeleteBusinessProfile', value: this.canDeleteBusinessProfile }
    ]);
  }

  onDispatchMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    super.onDispatchMasterViewEngineEvent(event);
    switch (event.type) {
      case 'linkBusinessProfile':
        this.adminBusinessProfilesFacade.getAll();
        this.adminUsersFacade.getBusinessProfilesByUserId(event.options.element.id);
        this.linkBusinessProfiles(event.options.element);
        break;
      case 'updateUser':
        this.editUser(event.options.element);
        break;
      default:
        break;
    }
  }

  onToolbarEvent(event: IotToolbarEvent) {
    switch (event.type) {
      case IotToolbarDispatchActionType.ADD_ELEMENT:
        this.addUser();
        break;
      case IotToolbarDispatchActionType.CLEAR_FILTERS_AND_RELOAD_DATA:
        this.onClearAppliedFiltersClicked();
        break;
      case IotToolbarDispatchActionType.REFRESH_PAGE:
        this.onRefreshClicked();
        break;
      case IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE:
        this.onShowFilter();
        break;
      default:
        break;
    }
  }

  onClearAppliedFiltersClicked(): void {
    this.clearAppliedFilters$.next(true);
    this.currentFilters = [];
    this.reLoadMasterView();
  }

  linkBusinessProfiles(user: User): void {
    this.store.dispatch(AdminBusinessProfilesActions.setUserBusinessProfiles({ businessProfiles: [] }));
    this.dialog.open(UserLinkBusinessProfileDialogComponent, {
      width: '1000px',
      disableClose: true,
      data: {
        user,
        canCreateBusinessProfile: this.canCreateBusinessProfile,
        canDeleteBusinessProfile: this.canDeleteBusinessProfile
      }
    });
  }

  addUser(): void {
    this.subscriptions.push(
      this.dialog
        .open(UserFormDialogComponent, {
          width: '800px',
          disableClose: true
        })
        .afterClosed()
        .subscribe((newUser) => {
          if (newUser) {
            this.adminUsersFacade.addUser(newUser);
          }
        })
    );
  }

  editUser(user: User): void {
    this.subscriptions.push(
      this.dialog
        .open(UserFormDialogComponent, {
          width: '800px',
          disableClose: true,
          data: {
            user
          }
        })
        .afterClosed()
        .subscribe((userToUpdate: User) => {
          if (userToUpdate) {
            this.adminUsersFacade.updateUser({
              ...user,
              ...userToUpdate
            });
          }
        })
    );
  }

  onShowFilter(): void {
    this.filterEngineOpened = !this.filterEngineOpened;
  }
}
