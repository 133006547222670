import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';

import { DateFormatModule } from '../../../../../../iot-platform-pipes/src/lib/date-format/date-format.module';

import { IconModule } from '../icon/icon.module';
import { ChipComponent } from './chip.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [CommonModule, MatIconModule, MatChipsModule, IconModule, TranslateModule, DateFormatModule, FlexLayoutModule],
  declarations: [ChipComponent],
  exports: [ChipComponent]
})
export class ChipModule {}
