<section fxFlex fxLayout="column" fxLayoutGap="10px">
  <iot-platform-ui-detail-popup-section-header
    headerTitle=" {{ (totalDevices > 1 ? 'IOT_DICTIONARY.devices' : 'IOT_DICTIONARY.device') | translate }} ({{ totalDevices }})"
  ></iot-platform-ui-detail-popup-section-header>

  <iot-platform-ui-card-loader *ngIf="!isDeviceLoaded"
                               [backgroundClass]="'mv-detail-card h-60'"></iot-platform-ui-card-loader>
  <iot-platform-ui-detail-devices-card
    (selectItem)="selectDevice.emit($event)"
    *ngIf="isDeviceLoaded"
    [devices]="devices"
    [ngClass]="{ invisible: !isDeviceLoaded, visible: isDeviceLoaded}"
    [total]="totalDevices"
  ></iot-platform-ui-detail-devices-card>
</section>
