import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { AsyncAutocompleteMultipleSelectsModule } from '../async-autocomplete-multiple-selects/async-autocomplete-multiple-selects.module';
import { AsyncAutocompleteModule } from '../async-autocomplete/async-autocomplete.module';

import { TimezoneAutocompleteMultipleSelectsComponent } from './timezone-autocomplete-multiple-selects.component';

@NgModule({
  imports: [CommonModule, TranslateModule, AsyncAutocompleteModule, AsyncAutocompleteMultipleSelectsModule],
  declarations: [TimezoneAutocompleteMultipleSelectsComponent],
  exports: [TimezoneAutocompleteMultipleSelectsComponent]
})
export class TimezoneAutocompleteMultipleSelectsModule {}
