import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, EventEmitter, Output } from '@angular/core';
import { MasterViewEngineEvent } from '@iot-platform/models/common';
import { SortUtil } from '../../../../../../../shared/src/lib/utils/sort.util';

import { CallToActionCellParams } from '../call-to-action-cell.params';
import { CustomCellParams } from '../custom-cell.params';

@Component({
  selector: 'grid-engine-call-to-action-cell',
  templateUrl: './call-to-action-cell.component.html',
  styleUrls: ['./call-to-action-cell.component.scss']
})
export class CallToActionCellComponent implements ICellRendererAngularComp {
  params: CallToActionCellParams;

  @Output() dispatchEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();

  isVisible: boolean;
  isEnabled: boolean;
  isDisabled: boolean;

  element;
  actions = [];
  userPermissions = []; // : Array<{ key: string; value: boolean }>;
  actionVisibilityList = [];
  isCallToActionVisible = true;

  agInit(params: CallToActionCellParams): void {
    this.params = params;
    this.element = params.element;
    this.actions = params.actions;
    this.userPermissions = params.userPermissions;
    this.actions.sort(SortUtil.sortByOrder);
  }

  refresh(params: CustomCellParams): boolean {
    return false;
  }

  onActionClicked(event: MasterViewEngineEvent) {
    this.params.dispatchEvent(event);
  }
}
