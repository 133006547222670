<div class="detail-card" fxLayout="column" fxLayoutGap="4px">
  <ul class="detail-card-list">
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_ASSET_VARIABLE_CARD_COMPONENT.NAME' | translate }}">{{ asset?.name | infoDisplay }}</li>
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_ASSET_VARIABLE_CARD_COMPONENT.SHIP_TO' | translate }}">
      {{ asset?.erpReference.shipTo | infoDisplay }}
    </li>
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_ASSET_VARIABLE_CARD_COMPONENT.VARIABLE' | translate }}">
      {{ assetVariable?.name | infoDisplay }}
    </li>
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_ASSET_VARIABLE_CARD_COMPONENT.EVENT_VALUE' | translate }}">
      <span *ngIf="!eventVariableProcessedUnit">
        {{ event?.context?.assetVariable?.value | numberFormat | infoDisplay }} {{ event?.context?.assetVariable?.unit }}
      </span>
      <span *ngIf="eventVariableProcessedUnit"> {{ eventVariableProcessedUnit }} </span>
      <span *ngIf="event?.context?.assetVariable?.value">({{ event?.occurrenceTime | dateFormat }})</span>
    </li>
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_ASSET_VARIABLE_CARD_COMPONENT.COMMENT' | translate }}">
      {{ assetVariable?.comment | infoDisplay }}
    </li>
  </ul>
  <ul class="detail-card-list">
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_ASSET_VARIABLE_CARD_COMPONENT.CURRENT_VALUE' | translate }}">
      <span class="detail-asset-variable-card-value" *ngIf="assetVariable?.lastValue && !assetVariableProcessedUnit">
        {{ assetVariable.lastValue.value | numberFormat | infoDisplay }} {{ assetVariable.unit }}
      </span>
      <span *ngIf="assetVariableProcessedUnit">
        {{ assetVariableProcessedUnit }}
      </span>
      <span *ngIf="assetVariable?.lastValue">({{ assetVariable.lastValue.datetime | dateFormat }})</span>
      <span *ngIf="!(assetVariable && assetVariable?.lastValue)">-</span>
    </li>
  </ul>
</div>
