import { NgModule } from '@angular/core';
import { AuthModule } from '@iot-platform/auth';
import { SharedModule } from '@iot-platform/shared';
import { PreferencesModule } from '@iot-platform/users';
import { OyanUiAuthRouterModule } from './auth.router.module';
import { LoginComponent } from './containers/login/login.component';

@NgModule({
  declarations: [LoginComponent],
  imports: [SharedModule, OyanUiAuthRouterModule, AuthModule.forRoot(), PreferencesModule]
})
export class OyanUiAuthModule {}
