<div fxLayout="column">
  <mat-card-title>
    <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center">
      <h2 class="panel-title" fxFlex fxLayout="row" fxLayoutGap="10px">
        <span> {{ 'ADMIN.BUSINESS_PROFILES.DETAIL.TAGS' | translate | uppercase }} ({{ getTagsTotal(tags) }}) </span>
        <mat-progress-spinner *ngIf="tagsPendingStatus" [diameter]="14" mode="indeterminate" color="accent"></mat-progress-spinner>
      </h2>
      <button
        *ngIf="canUpdateBusinessProfile"
        [matTooltip]="'ADMIN.BUSINESS_PROFILES.DETAIL.MANAGE_TAGS' | translate"
        matTooltipClass="regular-tooltip"
        matTooltipPosition="below"
        mat-icon-button
        class="regular-round-button"
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <mat-menu #menu="matMenu" backdropClass="tags-menu">
        <button
          mat-menu-item
          *ngFor="let menuItem of menuItems"
          (click)="onAddTagsToProfile(menuItem.concept)"
          class="tags-button"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="6px"
        >
          <!--<iot-platform-ui-icon [name]="menuItem.concept" [color]="'#394C5A'"></iot-platform-ui-icon>-->
          <mat-icon [svgIcon]="menuItem.concept" [color]="'#394C5A'"></mat-icon>
          <span>{{ menuItem.translationKey | translate }}</span>
        </button>
      </mat-menu>
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content class="business-profile-detail-tag-list">
    <div *ngFor="let concept of conceptList" class="business-profile-detail-tag-list-by-concept">
      <iot-platform-ui-tag-category-list-by-concept
        [concept]="concept"
        [tagCategoriesByConcept]="getTagCategoriesByConcept(concept)"
        [canCreate]="false"
        [canManageTags]="canUpdateBusinessProfile"
        [labelOnly]="false"
        (manageTags)="onAddTagsToProfile($event)"
      ></iot-platform-ui-tag-category-list-by-concept></div
  ></mat-card-content>
</div>
