import { Injectable } from '@angular/core';
import { AssetVariable, DeviceVariable, FollowedVariable } from '@iot-platform/models/i4b';
import { get } from 'lodash';

@Injectable()
export class ProcessMultipleStateVariablesUtil {
  static getProcessedUnit(variable: DeviceVariable | AssetVariable | FollowedVariable): string | null {
    const parsedUnits = variable.unit.split('/');
    parsedUnits.shift();

    const unitsAsValueAndLabel: Array<{ valueToMatch: number | null; unitLabel: string }> = parsedUnits.reduce((acc, unit) => {
      acc.push(this.getUnitAsValueAndLabel(unit, acc.length, acc.length + 1 === parsedUnits.length));
      return acc;
    }, []);

    let unitToDisplay: { valueToMatch: number | null; unitLabel: string } | undefined | null = null;

    if (variable.lastValue) {
      unitToDisplay =
        unitsAsValueAndLabel.find((unit) => {
          return get(variable, 'lastValue.value', null) === unit.valueToMatch;
        }) ?? unitsAsValueAndLabel[unitsAsValueAndLabel.length - 1];
    }

    return unitToDisplay?.unitLabel ?? null;
  }

  private static getUnitAsValueAndLabel(unit: string, currentIndex: number, isDefaultValue: boolean): { valueToMatch: number | null; unitLabel: string } {
    const valueFirstIndex = unit.indexOf('[');
    const valueLastIndex = unit.lastIndexOf(']');

    let unitAsValueAndLabel: { valueToMatch: number | null; unitLabel: string } = { valueToMatch: null, unitLabel: unit.slice(valueLastIndex + 1) };

    if (isDefaultValue) {
      unitAsValueAndLabel.valueToMatch = null;
    } else if (valueFirstIndex !== -1) {
      unitAsValueAndLabel.valueToMatch = parseInt(unit.slice(valueFirstIndex + 1, valueLastIndex), 10);
    } else {
      unitAsValueAndLabel.valueToMatch = currentIndex;
    }

    return unitAsValueAndLabel;
  }

  static getProcessedUnitForFollowedVariable(variable: FollowedVariable): string | null {
    const parsedUnits = variable.unit.split('/');
    parsedUnits.shift();

    const unitsAsValueAndLabel: Array<{ valueToMatch: number | null; unitLabel: string }> = parsedUnits.reduce((acc, unit) => {
      acc.push(this.getUnitAsValueAndLabel(unit, acc.length, acc.length + 1 === parsedUnits.length));
      return acc;
    }, []);

    let unitToDisplay: { valueToMatch: number | null; unitLabel: string } | undefined | null = null;

    if (variable.value !== null && variable.value !== undefined) {
      unitToDisplay = unitsAsValueAndLabel.find((unit) => unit.valueToMatch === variable.value) ?? unitsAsValueAndLabel[unitsAsValueAndLabel.length - 1];
    }

    return unitToDisplay?.unitLabel ?? null;
  }

  static isUnitMultipleState(unit?: string): boolean {
    return !!(unit && unit[0] === '/');
  }
}
