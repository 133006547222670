<mat-card class='dialog-card edit-product'>
  <mat-card-title class='dialog-card-header'>
    <mat-toolbar fxLayout='row' fxLayoutAlign='space-between center' class='dialog-toolbar' color='accent'>
      <h1 class='dialog-card-title'>{{ 'PRODUCTS.EDIT_FORM.TITLE' | translate: { productName: product?.identifier } }}</h1>
      <button mat-icon-button class='regular-round-button' (click)='onClose()'>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class='dialog-card-content'>
    <form [formGroup]="form" fxLayout="column" fxFlex fxLayoutGap="10px">
      <div fxLayout="row" fxFlex fxLayoutAlign="space-between center" fxLayoutGap="10px" style='padding: 2rem;'>
        <mat-form-field fxFlex color="accent">
          <input matInput formControlName="productName" [placeholder]="'PRODUCTS.TABLE_CONTENT.PRODUCT_NAME' | translate" [disabled]='true' />
        </mat-form-field>
        <mat-form-field fxFlex color="accent">
          <input matInput formControlName="displayName" [placeholder]="'PRODUCTS.TABLE_CONTENT.CUSTOMER_PRODUCT_NAME' | translate" />
        </mat-form-field>
      </div>

    </form>
  </mat-card-content>

  <mat-card-actions class='dialog-card-actions' fxLayout='row' fxLayoutAlign='end'>
    <button ngStyle.lt-md='margin-bottom: 10px; max-width: 13rem' mat-button (click)='onClose()'
            class='button-regular cancel-btn'>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button mat-raised-button class='button-regular' color='accent' [disabled]='!formStateChanged || form?.invalid' (click)='onSave()'>
      {{ 'IOT_DICTIONARY.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
