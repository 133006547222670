import { AgGridModule } from '@ag-grid-community/angular';
import { NgModule } from '@angular/core';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationCreateFormComponent } from './components/translation-create-form/translation-create-form.component';
import { TranslationDetailsComponent } from './components/translation-details/translation-details.component';
import { TranslationEditFormComponent } from './components/translation-edit-form/translation-edit-form.component';
import { TranslationPreviewChangesComponent } from './components/translation-preview-changes/translation-preview-changes.component';
import { TranslationTableViewComponent } from './components/translation-table-view/translation-table-view.component';
import { TranslationToolbarComponent } from './components/translation-toolbar/translation-toolbar.component';
import { TranslationOverviewComponent } from './containers/translation-overview/translation-overview.component';
import { TranslationManagementRouterModule } from './translation-management.router.module';

@NgModule({
  imports: [TranslationManagementRouterModule, SharedModule, AgGridModule, TranslateModule, IotPlatformPipesModule, IotPlatformUiModule],
  declarations: [
    TranslationOverviewComponent,
    TranslationToolbarComponent,
    TranslationTableViewComponent,
    TranslationEditFormComponent,
    TranslationDetailsComponent,
    TranslationPreviewChangesComponent,
    TranslationCreateFormComponent
  ]
})
export class TranslationManagementModule {
}
