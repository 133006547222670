import { DeviceEvent, Log } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadLogsByDeviceEventIdSuccess = createAction('[DeviceEvents] Load Logs By DeviceEvent Id Success', props<{ logs: Log[] }>());
export const loadLogsByDeviceEventIdFailure = createAction('[DeviceEvents] Load Logs By DeviceEvent Id Failure', props<{ error: any }>());
//
export const createLogByDeviceEventIdSuccess = createAction(
  '[DeviceEvents] Create Log By DeviceEvent Id Success',
  props<{ log: Log; deviceEvent: DeviceEvent }>()
);
export const createLogByDeviceEventIdFailure = createAction('[DeviceEvents] Create Log By DeviceEvent Id Failure', props<{ error: any }>());
