<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.TITLE_USER_POPUP' | translate }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <form [formGroup]="columnsForm" fxFlex fxLayout="row" fxLayoutGap="10px" class="p-20">
      <div fxFlex="20" fxLayout="column" fxLayoutAlign="space-between ">
        <section fxLayoutGap="10px">
          <mat-radio-group *ngIf="!data.isAdminMode" [value]="isNewGrid" color="accent" fxLayout="column" fxLayoutGap="10px" (change)="onNewGridChange($event)">
            <mat-radio-button *ngIf="allGrids.length > 0" [value]="false">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.UPDATE_MY_GRIDS' | translate }}</mat-radio-button>
            <mat-radio-button [value]="true">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.ADD_NEW_GRID' | translate }}</mat-radio-button>
          </mat-radio-group>

          <div fxLayout="column" fxLayoutGap="10px">
            <div *ngIf="!isNewGrid && !data.isAdminMode">
              <mat-form-field color="accent" fxFlex>
                <mat-select
                  formControlName="gridToUpdate"
                  [placeholder]="'GRID_ENGINE.GRID_MANAGER_POPUP.SELECT_A_GRID' | translate"
                  (selectionChange)="onSelectGridToUpdate($event)"
                >
                  <mat-select-trigger>
                    <span>{{ gridToUpdate.value?.name }}</span>
                  </mat-select-trigger>
                  <mat-option *ngFor="let grid of allGrids" [value]="grid">
                    <span title="{{ grid.name }}" class="grid-select-item" fxFlex>
                      <span>{{ grid.name }}</span>
                      <span class="select-grid-is-default">{{ grid.isDefault ? ('GRID_ENGINE.GRID_MANAGER_POPUP.DEFAULT_GRID' | translate) : '' }}</span>
                    </span>
                    <mat-icon class="icon-centered" *ngIf="!grid.businessProfileId">person</mat-icon>
                    <mat-icon class="icon-centered" *ngIf="grid.businessProfileId">groups</mat-icon>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-form-field color="accent" fxFlex>
              <mat-label>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.NAME' | translate }}</mat-label>
              <input matInput #nameInput placeholder="{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.NAME' | translate }}" formControlName="name" required maxlength="50" />
              <mat-hint align="end">{{ nameInput.value?.length || 0 }}/50</mat-hint>
            </mat-form-field>

            <mat-form-field color="accent">
              <mat-select
                formControlName="selectedLibrary"
                [placeholder]="'GRID_ENGINE.DATA_LIBRARIES' | translate"
                (selectionChange)="onLibraryChange($event)"
              >
                <mat-option *ngFor="let datalib of (selectedViewDef$ | async)?.dataLibraries" [value]="datalib">
                  {{ 'GRID_ENGINE.CONCEPT_LIST.' + datalib.libraryName | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </section>

        <div fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
            <mat-form-field color="accent" fxFlex="48%">
              <mat-select formControlName="pageSize" [placeholder]="'ADMIN.COLUMN_CATALOG.MASTER_VIEW_SETTINGS.PAGINATION' | translate">
                <mat-option *ngFor="let pageSize of gridPageSizeList" [value]="pageSize">{{ pageSize }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field color="accent" fxFlex="48%">
              <mat-select formControlName="autoRefreshDelay" [placeholder]="'ADMIN.COLUMN_CATALOG.MASTER_VIEW_SETTINGS.AUTO_REFRESH' | translate">
                <mat-option [value]="0">{{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_SETTINGS.NO_REFRESH' | translate }}</mat-option>
                <mat-option *ngFor="let delay of delayList" [value]="delay">{{ delay }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <mat-slide-toggle
            *ngIf="!data.isAdminMode"
            formControlName="isDefault"
            color="accent"
            fxLayout="row wrap"
            [disabled]="!!gridToUpdate.value?.businessProfileId || isShared.value"
            ><div class="slide-toggle-label">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SET_AS_DEFAULT_GRID' | translate }}</div></mat-slide-toggle
          >

          <div class="share-grid" *ngIf="canUpdateBp">
            <mat-slide-toggle
              formControlName="isShared"
              color="accent"
              [disabled]="!!gridToUpdate.value?.businessProfileId || gridToUpdate.value?.isDefault || isDefault.value"
            >
              <div class="slide-toggle-label">
                {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SHARE_WITHIN_BP' | translate: { bpName: selectedBusinessProfile?.name } }}
              </div>
            </mat-slide-toggle>
            <mat-error class="share-warning-message">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SHARE_WARNING' | translate }}</mat-error>
          </div>
        </div>
      </div>

      <div fxFlex="80" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="38" fxLayout="column">
          <p>
            {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.AVAILABLE_COLUMNS' | translate: { totalColumns: availableColumns?.length ?? '' } }}
          </p>
          <div
            cdkDropList
            #availableColumnsList="cdkDropList"
            [cdkDropListData]="availableColumns"
            [cdkDropListConnectedTo]="[selectedColumnsList]"
            class="dd-list"
            (cdkDropListDropped)="drop($event)"
          >
            <div
              class="accordeon-item dd-item"
              fxLayoutAlign="start center"
              *ngFor="let column of availableColumns"
              cdkDrag
              cdkDragHandle
              [cdkDragData]="column"
            >
              <p fxFlex="35" class="concept">{{ 'GRID_ENGINE.CONCEPT_LIST.' + column.configuration?.concept | translate | uppercase }}</p>
              <p fxFlex="65">{{ column.header?.displayName }}</p>
            </div>
          </div>
        </div>

        <div fxFlex="58" fxLayout="column">
          <div fxLayoutAlign="space-between center">
            <p>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SELECTED_COLUMNS' | translate: { totalColumns: selectedColumns.length ?? '' } }}</p>
            <p class="link" (click)="setDefaultView()">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.DEFAULT_COLUMNS' | translate }}</p>
          </div>
          <div
            cdkDropList
            class="dd-list"
            #selectedColumnsList="cdkDropList"
            [cdkDropListData]="selectedColumns"
            [cdkDropListConnectedTo]="[availableColumnsList]"
            (cdkDropListDropped)="drop($event)"
          >
            <mat-expansion-panel *ngFor="let column of selectedColumns; let index = index" cdkDrag cdkDragLockAxis="y" [cdkDragData]="column" fxLayout="column">
              <mat-expansion-panel-header fxLayoutGap="10px" class="accordeon-item">
                <div fxFlex fxLayoutAlign="start center">
                  <mat-panel-title fxFlex="30" fxLayoutGap="10px" fxLayoutAlign="start center">
                    <p>{{ index + 1 }}</p>
                    <p class="concept">{{ 'GRID_ENGINE.CONCEPT_LIST.' + column.configuration?.concept | translate | uppercase }}</p>
                  </mat-panel-title>
                  <mat-panel-description fxFlex="grow" fxLayoutAlign="start center">
                    {{ getHeaderValue(column) }}
                  </mat-panel-description>
                  <mat-panel-description class="sort-summary" fxLayoutAlign="start center" *ngIf="!!column.options.sort">
                    {{ getSortValue(column.options.sort) + 1 }}
                    <mat-icon>{{ column.options.sort.sort === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
                  </mat-panel-description>
                  <button mat-icon-button color="warn" (click)="onRemoveSelectedColumn($event, column)">
                    <mat-icon>delete</mat-icon>
                  </button>
                  <mat-icon cdkDragHandle color="accent" class="dd-handle-icon">drag_indicator</mat-icon>
                </div>
              </mat-expansion-panel-header>

              <div fxLayout="column">
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="10px"
                  class="dd-item--expanded-area"
                  *ngIf="column?.configuration?.isConfigurable"
                >
                  <mat-form-field color="accent" fxFlex>
                    <mat-label>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.CUSTOM_HEADER' | translate }}</mat-label>
                    <input matInput [value]="column.options.overrideHeaderName ?? null" #customHeader />
                  </mat-form-field>
                  <button
                    mat-button
                    color="accent"
                    type="button"
                    [disabled]="!customHeader.value.trim() || customHeader.value === column.options.overrideHeaderName"
                    (click)="applyNewHeader(column, customHeader.value.trim())"
                  >
                    {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.APPLY' | translate }}
                  </button>
                  <button mat-icon-button color="warn" (click)="resetHeaderName(column)"><mat-icon>clear</mat-icon></button>
                </div>

                <div
                  *ngIf="!column?.configuration?.children"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxLayoutGap="10px"
                  class="dd-item--expanded-area"
                >
                  <mat-form-field color="accent" fxFlex="35">
                    <mat-select
                      [placeholder]="'GRID_ENGINE.GRID_MANAGER_POPUP.SORT_PRIORITY' | translate"
                      #sortOrder
                      [value]="getSortValue(column.options.sort)"
                    >
                      <mat-option></mat-option>
                      <mat-option *ngFor="let sortOrder of [0, 1, 2]" [value]="sortOrder" [disabled]="disableSelectedColumnsForSort(sortOrder)">
                        {{ sortOrder + 1 }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field color="accent" fxFlex="35">
                    <mat-select
                      #sortType
                      [placeholder]="'GRID_ENGINE.GRID_MANAGER_POPUP.SORT_DIRECTION' | translate"
                      [value]="column.options.sort?.sort ?? 'asc'"
                    >
                      <mat-option *ngFor="let sortType of ['asc', 'desc']" [value]="sortType"
                        >{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SORT_' + sortType.toUpperCase() | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button
                    mat-button
                    color="accent"
                    type="button"
                    [disabled]="sortOrder.value === null"
                    (click)="applySortPreferences(column, sortOrder.value, sortType.value)"
                  >
                    {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.APPLY' | translate }}
                  </button>
                  <button mat-icon-button color="warn" (click)="resetGridSort(column)">
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>

                <div
                  *ngIf="
                    column?.configuration?.concept === I4bCellConcept.GROUPED_VARIABLES || column?.configuration?.concept === I4bCellConcept.FOLLOWED_VARIABLES || column?.configuration?.concept === I4bCellConcept.DIAGNOSTIC_VARIABLES
                  "
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="20px"
                  class="dd-item--expanded-area dd-item--variable-group"
                >
                  <mat-slide-toggle
                    [checked]="column?.options?.displayDateTime"
                    (change)="column.options.displayDateTime = $event.checked"
                    color="accent"
                    fxLayout="row wrap"
                  >
                    <div class="slide-toggle-label">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.DISPLAY_DATE_TIME' | translate }}</div>
                  </mat-slide-toggle>
                  <mat-form-field color="accent" fxFlex="30%" *ngIf="!!column.options.displayThresholds">
                    <mat-label>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.DISPLAY_THRESHOLDS' | translate }}</mat-label>
                    <mat-select multiple [formControlName]="column.columnId + column.header.displayName + '-displayThresholds'">
                      <mat-option
                        *ngFor="let thresholdPosition of thresholdList"
                        [value]="thresholdPosition"
                        [id]="thresholdPosition.toString(10)"
                        (onSelectionChange)="column.options.displayThresholds[thresholdPosition] = $event.source.selected"
                        >{{ thresholdPosition }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <p fxFlex *ngIf="notEnoughRights()" class="submission-disabled-message">
      {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.NOT_ENOUGH_RIGHTS_MESSAGE' | translate }}
    </p>
    <button mat-button class="button-regular" (click)="close()">{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button mat-button class="button-regular delete-btn" color="warn" *ngIf="!data.isAdminMode" [disabled]="isDeleteDisabled()" (click)="openConfirmPopup()">
      {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.DELETE_GRID' | translate }}
    </button>
    <button mat-raised-button class="button-regular save-btn" color="accent" (click)="save()" [disabled]="isSubmissionDisabled()">
      {{ actionButtonLabel$ | async | translate }}
    </button>
  </mat-card-actions>
</mat-card>
