import { Injectable } from '@angular/core';
import { ApiSelector, Filter, Pagination } from '@iot-platform/models/common';
import { Site } from '@iot-platform/models/oyan';
import * as fromMain from '../reducers';
import * as fromSite from '../reducers/site.reducer';

@Injectable({
  providedIn: 'root'
})
export class SiteSelectors extends ApiSelector<fromMain.State, Site, Pagination, Filter> {
  constructor() {
    super(fromMain.featureKey, fromSite.featureKey, fromSite.adapter);
  }
}
