import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';

import { TranslateModule } from '@ngx-translate/core';
import { CardLoaderModule } from '../../../../../../../iot-platform-ui/src/lib/ui/components/card-loader/card-loader.module';
import { ChipModule } from '../../../../../../../iot-platform-ui/src/lib/ui/components/chip/chip.module';

import { MasterViewEngineDirectiveModule } from '../master-view-engine-directive.module';
import { MasterViewExpandContainerComponent } from './master-view-expand-container.component';
import { TagExpandComponent } from './tag-expand/tag-expand.component';

@NgModule({
    imports: [CommonModule, TranslateModule, MasterViewEngineDirectiveModule, CardLoaderModule, MatChipsModule, ChipModule],
    declarations: [MasterViewExpandContainerComponent, TagExpandComponent],
    exports: [MasterViewExpandContainerComponent]
})
export class MasterViewExpandModule {}
