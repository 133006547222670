<form [formGroup]="inputForm" fxFlex>
  <mat-form-field fxFlex color="accent">
    <mat-label>{{ ('PO_EVENTS.CREATION_CONFIGURATION_POPUP.INPUT_LIST.' + (placeholder$ | async)) | translate }}</mat-label>
    <input matInput type="number" formControlName="value" (change)="onValueChange($event)" (keyup)="onValueChange($event)" required/>
    <mat-hint align="start" *ngIf="minValue">{{'PO_EVENTS.CREATION_CONFIGURATION_POPUP.MIN' | translate}}&nbsp;{{ minValue }}</mat-hint>
    <mat-hint align="end" *ngIf="maxValue">{{'PO_EVENTS.CREATION_CONFIGURATION_POPUP.MAX' | translate}}&nbsp;{{ maxValue }}</mat-hint>
    <mat-error align="start" *ngIf="value.hasError('min')">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.ERROR_MIN' | translate }}</mat-error>
    <mat-error align="start" *ngIf="value.hasError('max')">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.ERROR_MAX' | translate }}</mat-error>
    <mat-error align="start" *ngIf="value.hasError('required')">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.REQUIRED' | translate }}</mat-error>
  </mat-form-field>
</form>
