<form [formGroup]="selectForm">
  <mat-form-field class="select-field" color="accent">
    <mat-select
      data-cy="iot-platform-ui-select-field"
      formControlName="select"
      [placeholder]="data.criteriaLabel | translate"
      (selectionChange)="onSelectionChange()"
      [compareWith]="compareFn"
      [multiple]="data.multiSelect"
    >
      <mat-option
        data-cy="iot-platform-ui-select-field-option"
        [value]="option"
        *ngFor="let option of data.list"
        [disabled]="data.multiSelect && currentFiltersSize >= maxFilters"
        >{{ option.value | translate }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</form>
