import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TagCategory, TagLabel } from '@iot-platform/models/common';

import { TranslateService } from '@ngx-translate/core';

import { Observable, Subscription } from 'rxjs';

import { ManageTagsFormService } from './manage-tags-form.service';

export interface TagsByEntity {
  entityName: string;
  entityId: string;
  index: number;
  tagCategories: TagCategory[];
}

interface SelectedTag {
  id: string;
  name: string;
  categoryId: string;
  categoryName: string;
  concept: string;
  color: string;
  selected: boolean;
  entityId: string;
  label: TagLabel;
  category: TagCategory;
}

@Component({
  selector: 'iot-platform-ui-manage-tags-form',
  templateUrl: './manage-tags-form.component.html',
  styleUrls: ['./manage-tags-form.component.scss']
})
export class ManageTagsFormComponent implements OnInit, OnDestroy {
  manageTagForm: UntypedFormGroup;
  selectedTags: SelectedTag[] = [];
  allTags: { [concept: string]: TagsByEntity[] } = {};

  subs: Subscription[] = [];

  params: { value: any };
  visible = true;

  canClose = false;

  areTagsLoaded = false;
  minimumSelectionCount!: number;

  get canRemove(): boolean {
    return this.selectedTags.length > (this.data?.minimumSelectionCount ?? 0);
  }

  constructor(
    private dialogRef: MatDialogRef<ManageTagsFormComponent>,
    private translateService: TranslateService,
    private tagsService: ManageTagsFormService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      concepts: string[];
      openOnConcept?: string;
      selectedTags: TagCategory[];
      objectName: string;
      currentEntityId: string;
      multiSelection: boolean;
      editable: boolean;
      withChildren: boolean;
      withParents: boolean;
      joinable: boolean;
      minimumSelectionCount?: number;
    }
  ) {}

  ngOnInit() {
    this.manageTagForm = new UntypedFormGroup({ concept: new UntypedFormControl('', [Validators.required]) });
    this.setConceptAtInit();
    this.loadAllTags();
  }

  loadAllTags() {
    this.tagsService
      .getTagCategoriesByConceptSortedByEntity(this.data.concepts, this.data.currentEntityId, this.data.withChildren, this.data.withParents, this.data.joinable)
      .subscribe((tagsByConcept: { [concept: string]: TagsByEntity[] }) => {
        this.allTags = tagsByConcept;
        this.areTagsLoaded = true;
        this.initPreviousSelectedTagsByConcept();
      });
  }

  initPreviousSelectedTagsByConcept(): void {
    if (this.data.selectedTags) {
      this.selectedTags = this.getSelectedTags([...this.data.selectedTags]);
      this.selectedTags.forEach((selected) => {
        this.setSelectedLabelStatus(selected.concept, selected.entityId, selected.categoryId, selected.id, true);
      });
    }
  }

  getSelectedTags(workingArr): SelectedTag[] {
    const working = [...workingArr];

    return working.reduce((acc, value) => {
      const newLabs = value.labels.map((label) => {
        label = { ...label, selected: true };
        const transformed: SelectedTag = {
          id: label.id,
          name: label.name,
          categoryId: value.id,
          categoryName: value.name,
          color: value.color,
          concept: value.concept,
          entityId: value.entityId,
          selected: true,
          category: value,
          label
        };
        return transformed;
      });
      acc.push(...newLabs);
      return acc;
    }, []);
  }

  setSelectedLabelStatus(concept: string, entityId: string, categoryId: string, labelId: string, selected: boolean): void {
    const level1 = this.allTags[concept].find((e) => e.entityId === entityId);
    const cat = level1.tagCategories.find((c) => c.id === categoryId);
    const lab = cat.labels.find((l) => l.id === labelId);
    lab.selected = selected;
  }

  setConceptAtInit(): void {
    if (this.data.concepts.length === 1) {
      this.manageTagForm.controls['concept'].setValue(this.data.concepts[0]);
    }
    if (this.data.concepts.find((concept) => concept === this.data.openOnConcept)) {
      this.manageTagForm.controls['concept'].setValue(this.data.openOnConcept);
    }
  }

  get title$(): Observable<string> {
    return this.translateService.get('MANAGE_TAGS_FORM.TITLE', { value: this.data.objectName });
  }

  get concept(): AbstractControl {
    return this.manageTagForm.get('concept');
  }

  getSelectedTagsByEntityTotal(tagCategories: TagCategory[]): number {
    let total = 0;
    tagCategories.forEach((category) => {
      total += category.labels.filter((label) => label.selected === true).length;
    });

    return total;
  }

  addSelectedTag(tagCategory: TagCategory, tag: TagLabel) {
    if (!tag.selected) {
      this.setSelectedLabelStatus(tagCategory.concept, tagCategory.entityId, tagCategory.id, tag.id, true);
      const selectedTag: SelectedTag = {
        id: tag.id,
        name: tag.name,
        concept: tagCategory.concept,
        entityId: tagCategory.entityId,
        color: tagCategory.color,
        categoryId: tagCategory.id,
        categoryName: tagCategory.name,
        selected: false, // set to false for effect purpose only - need to be set to true
        label: tag,
        category: tagCategory
      };

      if (this.data.multiSelection) {
        this.selectedTags.push(selectedTag);
        setTimeout(() => (selectedTag.selected = true), 10);
      } else {
        const indexOfTagToReplace = this.selectedTags.findIndex((l) => l.categoryId === selectedTag.categoryId);
        const tagToReplace = this.selectedTags.find((l) => l.categoryId === selectedTag.categoryId);

        if (!tagToReplace) {
          this.selectedTags.push(selectedTag);
        } else {
          tagToReplace.selected = false;
          this.setSelectedLabelStatus(tagToReplace.concept, tagToReplace.entityId, tagToReplace.categoryId, tagToReplace.id, false);
          setTimeout(() => this.selectedTags.splice(indexOfTagToReplace, 1, selectedTag), 200);
        }
        setTimeout(() => (selectedTag.selected = true), 10);
      }
    }
  }

  removeSelectedTag(selectedTag: SelectedTag) {
    selectedTag.selected = false;
    this.setSelectedLabelStatus(selectedTag.concept, selectedTag.entityId, selectedTag.categoryId, selectedTag.id, false);
    setTimeout(
      () =>
        this.selectedTags.splice(
          this.selectedTags.findIndex((label) => label.id === selectedTag.id),
          1
        ),
      400
    );
  }

  disableSaveButton(): boolean {
    let result = 0;
    if (this.data.selectedTags.length !== this.selectedTags.length) {
      return false;
    }

    /*if (this.data.selectedTags && this.selectedTags) {*/
    this.data.selectedTags.forEach((tag) => {
      if (this.selectedTags.filter((selectedTag) => selectedTag.id === tag.labels[0].id).length) {
        result += 1;
      }
    });
    return result === this.data.selectedTags.length;
    /*}*/
  }

  save() {
    const working = [...this.selectedTags];
    const toSave: TagCategory[] = working.reduce((acc, value) => {
      let found = acc.find((v) => v.entityId === value.entityId);
      if (!found) {
        const newCat: TagCategory = {
          name: value.categoryName,
          id: value.categoryId,
          concept: value.concept,
          color: value.color,
          labels: [{ id: value.id, name: value.name }]
        };
        acc.push(newCat);
      } else {
        found = { ...found, labels: [...found.labels, { id: value.id, name: value.name }] };
        acc[acc.findIndex((x) => x.entityId === found.entityId)] = found;
      }
      return acc;
    }, []);
    this.dialogRef.close(toSave);
  }

  onEditorChangeValue(value: boolean) {
    this.canClose = value;
  }

  closeOnCancel() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }
}
