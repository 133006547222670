import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { flatten } from 'flat';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationDictionary } from '../models/translation-dictionary.model';

@Injectable({
  providedIn: 'root'
})
export class TranslationManagementService {
  private baseLangKey = 'en';

  constructor(private readonly httpClient: HttpClient, private readonly translateService: TranslateService) {}

  public getLanguages(): string[] {
    return this.translateService.langs.filter((v) => v !== 'undefined');
  }

  public getDictionaries(): Observable<TranslationDictionary[]> {
    const languages: string[] = this.getLanguages();
    return forkJoin(
      languages.reduce((acc: Array<Observable<{ langKey: string; data: Array<{ key: string; value: string }> }>>, lang: string) => {
        acc.push(
          this.httpClient.get(`assets/i18n/${lang}.json`).pipe(
            map((data) => {
              const flattenedData = flatten(data, { safe: true });
              const dataArray = [];
              Object.entries(flattenedData).forEach(([key, value]) => {
                dataArray.push({ key, value });
              });
              return { langKey: lang, data: dataArray };
            })
          )
        );
        return acc;
      }, [])
    ).pipe(map((data) => this.normalizeDictionariesContent(data)));
  }

  public normalizeDictionariesContent(dictionaries: Array<{ langKey: string; data: Array<{ key: string; value: string }> }>): TranslationDictionary[] {
    const baseDictionary = dictionaries.find((i) => i.langKey === this.baseLangKey);
    const otherDictionaries = dictionaries.filter((i) => i.langKey !== this.baseLangKey);
    return baseDictionary.data.reduce((acc, item) => {
      const obj: TranslationDictionary = {
        key: item.key,
        en: {
          value: item.value,
          missing: false
        }
      };
      otherDictionaries.forEach((dic) => {
        if (!obj[dic.langKey]) {
          obj[dic.langKey] = {};
        }
        const elem = dic.data.find((e) => e.key === item.key);
        obj[dic.langKey]['value'] = elem ? elem.value : '';
        obj[dic.langKey]['missing'] = !elem;
      });
      return [...acc, obj];
    }, []);
  }
}
