<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'ADMIN.TRANSLATION_MANAGEMENT.CREATE_FORM.TITLE' | translate }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <form fxLayout="column" class="full-width p-20" [formGroup]="createForm">

      <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
        <mat-form-field fxFlex color="accent">
          <input matInput formControlName="key" placeholder="{{ 'ADMIN.TRANSLATION_MANAGEMENT.CREATE_FORM.LANGUAGE_KEY' | translate | uppercase }}" [required]="true"/>
          <ng-container *ngIf="languageKey?.invalid && languageKey?.dirty">
            <mat-error *ngIf="languageKey?.errors?.required">{{ 'ADMIN.TRANSLATION_MANAGEMENT.ERROR_MESSAGE.REQUIRED_KEY' | translate }}</mat-error>
            <mat-error *ngIf="languageKey?.errors?.exist">{{ 'ADMIN.TRANSLATION_MANAGEMENT.ERROR_MESSAGE.LANGUAGE_EXIST' | translate }}</mat-error>
          </ng-container>
        </mat-form-field>
      </div>

    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="close()">{{ 'IOT_DICTIONARY.CANCEL' | translate | uppercase }}</button>
    <button mat-raised-button class="button-regular" color="accent" [disabled]="!createForm.valid || !createForm.touched" (click)="save()">
      {{ 'IOT_DICTIONARY.CREATE' | translate | uppercase }}
    </button>
  </mat-card-actions>
</mat-card>
