import { Component } from '@angular/core';
import { IHeaderGroupAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'grid-engine-group-header-cell',
  templateUrl: './group-header-cell.component.html',
  styleUrls: ['./group-header-cell.component.scss']
})
export class GroupHeaderCellComponent implements IHeaderGroupAngularComp {
  public params: any;
  public expandState: string;

  agInit(params): void {
    this.params = params;

    this.params.columnGroup.getProvidedColumnGroup().addEventListener('expandedChanged', this.syncExpandButtons.bind(this));

    this.syncExpandButtons();
  }

  expandOrCollapse() {
    let currentState = this.params.columnGroup.getProvidedColumnGroup().isExpanded();
    this.params.setExpanded(!currentState);
  }

  syncExpandButtons() {
    if (this.params.columnGroup.getProvidedColumnGroup().isExpanded()) {
      this.expandState = 'expanded';
    } else {
      this.expandState = 'collapsed';
    }
  }
}
