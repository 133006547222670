import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FilterEngineContainerModule } from '../../../../../iot-platform-ui/src/lib/ui/components/filter-engine-container/filter-engine-container.module';
import { MasterViewCommentsComponent } from './master-view-comments/master-view-comments.component';
import { MasterViewEngineComponent } from './master-view-engine.component';
import { MasterViewEngineService } from './master-view-engine.service';
import { MasterViewTableModule } from './master-view-table/master-view-table.module';

@NgModule({
  declarations: [MasterViewEngineComponent, MasterViewCommentsComponent],
  imports: [
    CommonModule,
    FilterEngineContainerModule,
    MatSelectModule,
    FlexModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MasterViewTableModule,
    MatDialogModule
  ],
  exports: [MasterViewEngineComponent, MasterViewTableModule, MasterViewCommentsComponent],
  providers: [MasterViewEngineService]
})
export class MasterViewEngineModule {}
