<section fxFlex fxLayout="column" fxLayoutGap="10px">
  <iot-platform-ui-detail-popup-section-header
    headerTitle="{{ 'IOT_DICTIONARY.site' | translate }}"
  ></iot-platform-ui-detail-popup-section-header>
  <iot-platform-ui-card-loader *ngIf="!isSiteLoaded"
                               [backgroundClass]="'mv-detail-card h-140'"></iot-platform-ui-card-loader>
  <iot4bos-ui-detail-site-card
    (selectSite)="selectSite.emit(site)"
    *ngIf="isSiteLoaded"
    [site]="site"
  ></iot4bos-ui-detail-site-card>
</section>
