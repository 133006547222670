<div clas='organization-detail'>
  <span *ngIf="!organization" fxFlexFill class="regular-background no-profile" fxLayoutAlign="center center">{{
    'ADMIN.ORGANIZATIONS.DETAIL.SELECT_AN_ORGANIZATION' | translate | uppercase
    }}</span>

  <div *ngIf="organization" fxFlexFill class="regular-background full-height p-20" fxLayout="column">
    <mat-toolbar color="accent" fxLayoutAlign="space-between center" style="flex-shrink: 0;">
      <h2 fxFlex fxLayout="row" fxLayoutAlign="start center">
        {{ organization?.name }}
      </h2>
      <button
        *ngIf="canUpdateOrganization && (organization?.isLocked || isTopLevelAdmin)"
        [matTooltip]="(organization?.isLocked ? 'ADMIN.ORGANIZATIONS.DETAIL.UNLOCK_ORGANIZATION' : 'ADMIN.ORGANIZATIONS.DETAIL.LOCK_ORGANIZATION') | translate"
        matTooltipClass="regular-tooltip"
        matTooltipPosition="below"
        mat-icon-button
        class="regular-round-button organization-detail__lock-btn"
        [disabled]="!isTopLevelAdmin"
        (click)="onLockUnlockOrganization(!organization?.isLocked)"
      >
        <mat-icon>{{ organization?.isLocked ? 'lock_open_outline' : 'lock_outline'}}</mat-icon>
      </button>
      <ng-container *ngIf='!organization?.isLocked || isTopLevelAdmin'>
        <button
          *ngIf="canUpdateOrganization"
          [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.EDIT_ORGANIZATION_NAME' | translate"
          matTooltipClass="regular-tooltip"
          matTooltipPosition="below"
          mat-icon-button
          class="regular-round-button organization-detail__edit-btn"
          [disabled]="organization?.isLocked"
          (click)="editOrganizationName(organization)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          *ngIf="canDeleteOrganization"
          [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.DELETE_ORGANIZATION' | translate"
          matTooltipClass="regular-tooltip"
          matTooltipPosition="below"
          mat-icon-button
          class="regular-round-button organization-detail__delete-btn"
          (click)="onDeleteOrganizationButtonClick(organization)"
          [disabled]="organization?.isLocked"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </ng-container>
    </mat-toolbar>

    <mat-tab-group fxFlex mat-align-tabs="left" mat-stretch-tabs color="accent">
      <mat-tab *ngIf="canReadBusinessProfile">
        <ng-template mat-tab-label>
          {{ 'ADMIN.ORGANIZATIONS.DETAIL.BUSINESS_PROFILES' | translate | uppercase }} ({{ businessProfiles?.length || 0 }})
          <mat-progress-spinner
            *ngIf="businessProfilesPendingStatus"
            [diameter]="14"
            mode="indeterminate"
            color="accent"
            style="margin-left: 8px;"
          ></mat-progress-spinner>
          <span style="width: 20px;" *ngIf="!businessProfilesPendingStatus"></span>
        </ng-template>
        <mat-toolbar color="primary" fxLayoutAlign="space-between center">
          <h2>{{ businessProfiles?.length }} {{ 'ADMIN.ORGANIZATIONS.DETAIL.BUSINESS_PROFILES' | translate | uppercase }}</h2>
          <button
            *ngIf="canCreateBusinessProfile"
            [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.ADD_BUSINESS_PROFILES' | translate"
            [matTooltipClass]="'regular-tooltip'"
            matTooltipPosition="below"
            mat-icon-button
            class="regular-round-button"
            (click)="addBusinessProfile.emit(organization)"
          >
            <mat-icon>add</mat-icon>
          </button>
        </mat-toolbar>

        <iot4bos-backoffice-ui-business-profiles-list
          [profiles]="businessProfiles"
          [canUpdateBusinessProfile]="canUpdateBusinessProfile"
          [canDeleteBusinessProfile]="canDeleteBusinessProfile"
          (deleteBusinessProfile)="onDeleteBusinessProfile($event)"
          (selectBusinessProfile)="onSelectBusinessProfile($event)"
        >
        </iot4bos-backoffice-ui-business-profiles-list>
      </mat-tab>
      <mat-tab *ngIf="canReadBusinessProfile">
        <ng-template mat-tab-label>
          {{ 'ADMIN.ORGANIZATIONS.DETAIL.ROLES' | translate | uppercase }} ({{ roles?.length || 0 }})
          <mat-progress-spinner *ngIf="rolesPendingStatus" [diameter]="14" mode="indeterminate" style="margin-left: 8px;" color="accent"></mat-progress-spinner>
          <span style="width: 20px;" *ngIf="!rolesPendingStatus"></span>
        </ng-template>

        <div fxFlex fxLayout="row">
          <div fxFlex fxLayout="column">
            <mat-toolbar color="primary" fxLayoutAlign="space-between center">
              <h2>{{ roles?.length }} {{ 'ADMIN.ORGANIZATIONS.DETAIL.ROLES' | translate | uppercase }}</h2>
              <button
                [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.ADD_ROLES' | translate"
                matTooltipClass="regular-tooltip"
                matTooltipPosition="below"
                mat-icon-button
                class="regular-round-button"
                (click)="addRole.emit(organization)"
                *ngIf="isCurrentUserAdmin"
              >
                <!--<iot-platform-ui-icon name="add_role" color="#394C5A"></iot-platform-ui-icon>-->
                <mat-icon svgIcon="add_role" color="#394C5A"></mat-icon>
              </button>
            </mat-toolbar>

            <div fxLayout="row" fxLayoutAlign="start start" class="organization-detail-role-list">
              <iot4bos-backoffice-ui-roles-list
                fxFlex="40"
                [roles]="roles"
                [selectedRole]="selectedRole"
                [showActionMenu]="isCurrentUserAdmin"
                (selectRole)="onSelectRole($event)"
                (editRole)="onAuthorizationsChanged($event)"
                (deleteRole)="onDeleteRole($event)"
                (renameRole)="onRenameRole($event)"
              >
              </iot4bos-backoffice-ui-roles-list>
              <span fxFlex="60" *ngIf="!selectedRole" class="background no-profile">{{
                'ADMIN.ORGANIZATIONS.DETAIL.SELECT_A_ROLE' | translate | uppercase
                }}</span>
              <iot4bos-backoffice-ui-roles-crud
                fxFlex="60"
                *ngIf="selectedRole"
                [adminConceptsFromEntitySession]="adminConceptsFromEntitySession"
                [role]="selectedRole"
                [isAdmin]="isCurrentUserAdmin"
                [readonly]="true"
              >
              </iot4bos-backoffice-ui-roles-crud>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="canReadTags">
        <ng-template mat-tab-label>
          {{ 'ADMIN.ORGANIZATIONS.DETAIL.TAGS' | translate | uppercase }} ({{ tags?.length }})
          <mat-progress-spinner *ngIf="tagsPendingStatus" [diameter]="14" mode="indeterminate" style="margin-left: 8px;" color="accent"></mat-progress-spinner>
          <span style="width: 20px;" *ngIf="!tagsPendingStatus"></span>
        </ng-template>
        <div fxFlex style="width: 100%; height: 100%;" class="organization-detail-tags-zone">
          <div fxFlex fxLayout="column">
            <mat-toolbar color="primary" fxLayoutAlign="space-between center">
              <h2>
                {{ tags?.length }} {{ 'ADMIN.ORGANIZATIONS.DETAIL.TAG_CATEGORIES' | translate | uppercase }} / {{ getTagsTotal(tags) }}
                {{ 'ADMIN.ORGANIZATIONS.DETAIL.TAG_LABELS' | translate | uppercase }}
              </h2>
            </mat-toolbar>

            <div *ngFor="let concept of conceptList$ | async" class="organization-detail-tags-list">
              <iot-platform-ui-tag-category-list-by-concept
                [concept]="concept"
                [tagCategoriesByConcept]="getTagCategoriesByConcept(concept)"
                [canCreate]="false"
                [canManageTags]="canCreateTags && canUpdateTags && canDeleteTags"
                [labelOnly]="true"
                (manageTags)="onManageTags($event)"
              ></iot-platform-ui-tag-category-list-by-concept>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="isCurrentUserAdmin">
        <ng-template mat-tab-label>
        <span fxLayoutAlign="start center"
        >{{ 'ADMIN.ORGANIZATIONS.DETAIL.ADMINISTRATORS_AND_CONCEPTS' | translate | uppercase }}&nbsp;
          <mat-icon
            [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.ADMINISTRATORS_AND_CONCEPTS_TOOLTIP' | translate"
            matTooltipClass="regular-tooltip"
            [matTooltipPosition]="'below'"
          >warning</mat-icon
          >
        </span>
          <mat-progress-spinner
            *ngIf="administratorsPendingStatus"
            [diameter]="14"
            mode="indeterminate"
            style="margin-left: 8px;"
            color="accent"
          ></mat-progress-spinner>
          <span style="width: 20px;" *ngIf="!administratorsPendingStatus"></span>
        </ng-template>

        <div fxLayout="row" fxLayoutGap="10px">
          <section fxFlex="50%" fxLayout="column">
            <mat-toolbar color="primary" fxLayoutAlign="space-between center">
              <h2>{{ administrators?.length }} {{ 'ADMIN.ORGANIZATIONS.DETAIL.ADMINISTRATORS' | translate | uppercase }}</h2>
              <button
                [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.MANAGE_ADMINISTRATORS' | translate"
                matTooltipClass="regular-tooltip"
                matTooltipPosition="below"
                mat-icon-button
                class="regular-round-button"
                [disabled]="organization.isLocked"
                (click)="addAdministratorToOrganization.emit(true)"
              >
                <!--<iot-platform-ui-icon name="edit_admin" color="#394C5A"></iot-platform-ui-icon>-->
                <mat-icon>person_add_outlined</mat-icon>
              </button>
            </mat-toolbar>
            <iot4bos-backoffice-ui-users-list [users]="administrators"></iot4bos-backoffice-ui-users-list>
          </section>

          <section fxFlex="50%" fxLayout="column">
            <mat-toolbar color="primary" fxLayoutAlign="space-between center">
              <h2>{{ organization?.adminConcepts?.length || 0 }} {{ 'ADMIN.ORGANIZATIONS.DETAIL.CONCEPTS' | translate | uppercase }}</h2>
              <button
                [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.MANAGE_CONCEPTS' | translate"
                matTooltipClass="regular-tooltip"
                matTooltipPosition="below"
                mat-icon-button
                class="regular-round-button"
                [disabled]="organization.isLocked"
                (click)="manageConcepts.emit({ organization: organization, adminConceptsFromEntitySession: adminConceptsFromEntitySession })"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </mat-toolbar>
            <iot4bos-backoffice-ui-organization-concepts-selection
              [readonly]="true"
              [organization]="organization"
              [adminConceptsFromEntitySession]="adminConceptsFromEntitySession"
            ></iot4bos-backoffice-ui-organization-concepts-selection>
          </section>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
