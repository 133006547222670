import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@iot-platform/iot-platform-ui';

import { BusinessProfile, PlatformRequest, User } from '@iot-platform/models/common';

import { select, Store } from '@ngrx/store';
import { SortUtil } from 'libs/shared/src/lib/utils/sort.util';
import { uniqBy } from 'lodash';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { BusinessProfilesMembersDialogActions } from '../../../features/admin-business-profiles/state/actions';
import * as fromProfiles from '../../../features/admin-business-profiles/state/reducers';
import { UsersService } from '../../../features/admin-users/services/users.service';

@Component({
  selector: 'iot4bos-backoffice-ui-dialog-link-members-to-business-profile',
  templateUrl: './dialog-link-members-to-business-profile.component.html',
  styleUrls: ['./dialog-link-members-to-business-profile.component.scss', '../../../style/admin.style.scss']
})
export class DialogLinkMembersToBusinessProfileComponent extends BaseComponent implements OnInit {
  allUsers: User[] = [];

  unselectedUsers: User[] = [];
  filteredUnselectedUsers: User[] = [];

  currentMembers: User[] = [];
  filteredCurrentUsers: User[] = [];

  param = { bPName: '' };
  loadingUsers$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private filterUnselected;
  private filterSelected;

  constructor(
    private dialogRef: MatDialogRef<DialogLinkMembersToBusinessProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { businessProfile: BusinessProfile },
    private store: Store<fromProfiles.State>,
    private usersService: UsersService
  ) {
    super();
    this.subscriptions.push(
      this.store.pipe(select(fromProfiles.getAllMembersByBusinessProfile)).subscribe((members: User[]) => {
        this.currentMembers = [...members];
        this.filteredUnselectedUsers = this.unselectedUsers = this.checkAvailableUsers(this.allUsers).sort(SortUtil.sortByProperty('lastname'));

        if (this.filterSelected) {
          this.filterUsers(this.filterSelected, this.currentMembers, true);
        } else {
          this.filteredCurrentUsers = [...this.currentMembers];
        }

        if (this.filterUnselected) {
          this.filterUsers(this.filterUnselected, this.unselectedUsers, false);
        }
      })
    );
  }

  ngOnInit() {
    if (this.data && this.data.businessProfile) {
      this.param.bPName = this.data.businessProfile.name;
    }
  }

  onSearchUsers(term: string): void {
    const request1: PlatformRequest = {
      limit: 1000,
      filters: [
        { criteriaKey: 'userEmail', value: term },
        { criteriaKey: 'targetBusinessProfileId', value: this.data.businessProfile.id }
      ]
    };
    const request2: PlatformRequest = {
      limit: 1000,
      filters: [
        { criteriaKey: 'userFirstName', value: term },
        { criteriaKey: 'targetBusinessProfileId', value: this.data.businessProfile.id }
      ]
    };
    const request3: PlatformRequest = {
      limit: 1000,
      filters: [
        { criteriaKey: 'userLastName', value: term },
        { criteriaKey: 'targetBusinessProfileId', value: this.data.businessProfile.id }
      ]
    };
    this.getUsers(request1, request2, request3);
  }

  displayUserWrapper(user: User): string {
    return user ? `${user.firstname} ${user.lastname}` : '';
  }

  removeMemberFromProfile(memberToRemove: User) {
    this.store.dispatch(
      BusinessProfilesMembersDialogActions.removeMemberFromBusinessProfile({
        businessProfileId: this.data.businessProfile.id,
        memberToRemove: memberToRemove
      })
    );
  }

  addMemberToProfile(memberToAdd: User) {
    this.store.dispatch(
      BusinessProfilesMembersDialogActions.addMemberToBusinessProfile({
        businessProfileId: this.data.businessProfile.id,
        memberToAdd: memberToAdd
      })
    );
  }

  filterUsers(event, listToFilter: User[], areUsersSelected: boolean): void {
    if (areUsersSelected) {
      this.filterSelected = event;
      this.filteredCurrentUsers = this.getFilteredUsers(listToFilter, this.filterSelected);
    } else {
      this.filterUnselected = event;
      this.filteredUnselectedUsers = this.getFilteredUsers(listToFilter, this.filterUnselected);
    }
  }

  getFilteredUsers(listToFilter: User[], filterEvent): User[] {
    return listToFilter.filter(
      (user) =>
        user.lastname.toLowerCase().includes(filterEvent.target.value.toLowerCase()) ||
        user.firstname.toLowerCase().includes(filterEvent.target.value.toLowerCase()) ||
        user.email.toLowerCase().includes(filterEvent.target.value.toLowerCase())
    );
  }

  exit(): void {
    this.dialogRef.close(true);
  }

  private getUsers(requestEmail?: PlatformRequest, requestFirtName?: PlatformRequest, requestLastName?: PlatformRequest): void {
    this.loadingUsers$.next(true);
    this.subscriptions.push(
      forkJoin([
        this.usersService.getUsers(requestEmail).pipe(map((response) => response.data)),
        this.usersService.getUsers(requestFirtName).pipe(map((response) => response.data)),
        this.usersService.getUsers(requestLastName).pipe(map((response) => response.data))
      ])
        .pipe(finalize(() => this.loadingUsers$.next(false)))
        .subscribe((responses: User[][]) => {
          this.allUsers = uniqBy(
            responses.reduce((acc, arr) => acc.concat(arr), []),
            'id'
          );
          this.filteredUnselectedUsers = this.unselectedUsers = [...this.checkAvailableUsers(this.allUsers).sort(SortUtil.sortByProperty('lastname'))];
        })
    );
  }

  private checkAvailableUsers(usersByOrganization: User[]): User[] {
    const available: User[] = [];
    usersByOrganization.forEach((user) => {
      if (this.currentMembers.indexOf(this.currentMembers.find((u) => user.id === u.id)) === -1) {
        available.push(user);
      }
    });
    return available;
  }
}
