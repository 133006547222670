import { Component, Input, OnInit } from '@angular/core';
import { LastValue } from '@iot-platform/models/common';

import { FollowedVariable } from '@iot-platform/models/i4b';
import { ProcessMultipleStateVariablesUtil } from '../../../../../../../shared/src/lib/utils/process-multiple-state-variables.util';

@Component({
  selector: 'iot-platform-ui-detail-followed-variable',
  templateUrl: './detail-followed-variable.component.html',
  styleUrls: ['./detail-followed-variable.component.scss']
})
export class DetailFollowedVariableComponent implements OnInit {
  @Input() followedVariable?: FollowedVariable;
  @Input() followedNumber?: number;

  processedValue!: string | null;
  isMultipleStateVariable = false;

  constructor() {}

  ngOnInit() {
    this.isMultipleStateVariable = ProcessMultipleStateVariablesUtil.isUnitMultipleState(this.followedVariable?.unit);

    if (this.isMultipleStateVariable && !!this.followedVariable) {
      const lastValue: LastValue | null =
        this.followedVariable.value !== null ? { value: this.followedVariable.value, datetime: this.followedVariable.datetime } : null;

      this.processedValue = ProcessMultipleStateVariablesUtil.getProcessedUnit({ ...this.followedVariable, lastValue });
    }
  }
}
