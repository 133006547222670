import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { CustomNumberCellParams } from '../custom-number-cell.params';

@Component({
  selector: 'grid-engine-address-accuracy-cell',
  templateUrl: './address-accuracy-cell.component.html',
  styleUrls: ['./address-accuracy-cell.component.scss']
})
export class AddressAccuracyCellComponent implements ICellRendererAngularComp {
  params: CustomNumberCellParams;

  constructor() {}

  agInit(params: CustomNumberCellParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  getApplicableScore(matchScore: number): { min: number; max: number; color: string } {
    const mScoreRed: { min: number; max: number; color: string } = { min: 0, max: 0.4, color: '#E75D70' };
    const mScoreOrange: { min: number; max: number; color: string } = { min: 0.4, max: 0.7, color: '#F58C2D' };
    const mScoreYellow: { min: number; max: number; color: string } = { min: 0.7, max: 0.9, color: '#7CCA6F' };
    const mScoreGreen: { min: number; max: number; color: string } = { min: 0.9, max: 1.01, color: '#375F9B' };
    const mScoreBlue: { min: number; max: number; color: string } = { min: 0, max: 0, color: '#96A5AF' };

    const scores: { min: number; max: number; color: string }[] = [mScoreRed, mScoreOrange, mScoreYellow, mScoreGreen];

    let applicableScore: { min: number; max: number; color: string } | undefined = mScoreBlue;

    if (matchScore) {
      applicableScore = scores.find((score: { min: number; max: number; color: string }) => {
        return matchScore < score.max && matchScore >= score.min;
      });
    }

    return applicableScore ?? mScoreBlue;
  }
}
