import { Injectable } from '@angular/core';
import { BaseFacade, CommonApiRequest, Filter, Pagination } from '@iot-platform/models/common';
import { Gateway, Product, Receiver, Site, Ward } from '@iot-platform/models/oyan';
import { Store } from '@ngrx/store';
import { SiteActions, SiteGatewaysActions, SiteProductsActions, SiteWardsActions } from '../actions';
import { SiteContactsSelectors } from '../selectors/site-contacts.selectors';
import { SiteGatewaysSelectors } from '../selectors/site-gateways.selectors';
import { SiteProductsSelectors } from '../selectors/site-products.selectors';
import { SiteWardsSelectors } from '../selectors/site-wards.selectors';
import { SiteSelectors } from '../selectors/site.selectors';

@Injectable({
  providedIn: 'root'
})
export class SitesFacade extends BaseFacade<Site, Pagination, Filter> {
  productsBySite$ = this.store.select(this.productSelector.selectAll);
  productsLoading$ = this.store.select(this.productSelector.selectLoading);

  gatewaysBySite$ = this.store.select(this.gatewaySelector.selectAll);
  gatewaysLoading$ = this.store.select(this.gatewaySelector.selectLoading);

  currentWard$ = this.store.select(this.wardSelector.selectCurrentEntity);
  wardsLoading$ = this.store.select(this.wardSelector.selectLoading);
  wardsLoaded$ = this.store.select(this.wardSelector.selectLoaded);
  wardsPagination$ = this.store.select(this.wardSelector.selectPagination);
  wardsBySiteAsPlatformResponse$ = this.store.select(this.wardSelector.selectPlatformResponse);

  contactsBySite$ = this.store.select(this.contactSelector.selectAll);
  contactsLoading$ = this.store.select(this.contactSelector.selectLoading);

  constructor(
    protected store: Store,
    protected selector: SiteSelectors,
    private readonly contactSelector: SiteContactsSelectors,
    private readonly gatewaySelector: SiteGatewaysSelectors,
    private readonly productSelector: SiteProductsSelectors,
    private readonly wardSelector: SiteWardsSelectors
  ) {
    super(store, selector);
  }

  getAll(request?: CommonApiRequest): void {
    this.store.dispatch(SiteActions.loadSites({ request }));
  }

  loadSiteById(id: string): void {
    this.store.dispatch(SiteActions.loadSiteById({ id }));
  }

  addSite(site: Site): void {
    this.store.dispatch(SiteActions.addSite({ site }));
  }

  updateSite(site: Site): void {
    this.store.dispatch(SiteActions.updateSite({ site }));
  }

  deleteSite(site: Site): void {
    this.store.dispatch(SiteActions.deleteSite({ site }));
  }

  loadGatewaysBySite(site: Site): void {
    let request: CommonApiRequest = null;
    if (site) {
      request = {
        filters: [
          { criteriaKey: 'siteId', value: site.id },
          { criteriaKey: 'entityId', value: site.entity.id }
        ]
      };
    }
    this.store.dispatch(SiteGatewaysActions.loadGatewaysBySite({ request }));
  }

  loadWardsBySite(site: Site, pagination: Pagination): void {
    const request: CommonApiRequest = {
      page: pagination.currentPage,
      limit: pagination.limit
    };
    if (site) {
      request.filters = [{ criteriaKey: 'siteId', value: site.id }];
    }
    this.store.dispatch(SiteWardsActions.loadWardsBySite({ request }));
  }

  addProductToSite(product: Product, site: Site): void {
    this.store.dispatch(SiteProductsActions.addProductToSite({ product, site }));
  }

  removeProductFromSite(product: Product, site: Site): void {
    this.store.dispatch(SiteProductsActions.removeProductFromSite({ product, site }));
  }

  addReceiverToWard(receiver: Receiver, ward: Ward, site: Site): void {
    this.store.dispatch(SiteWardsActions.addReceiverToWard({ receiver, ward, site }));
  }

  removeReceiverFromWard(receiver: Receiver, ward: Ward, site: Site): void {
    this.store.dispatch(SiteWardsActions.removeReceiverFromWard({ receiver, ward, site }));
  }

  setActiveSite(site: Site): void {
    this.store.dispatch(SiteActions.setActiveSite({ site }));
  }

  setFilters(filters: Filter[]) {
    this.store.dispatch(SiteActions.setFilters({ filters }));
  }

  addGatewayToSite(gateway: Gateway, site: Site): void {
    this.store.dispatch(SiteGatewaysActions.addGatewayToSite({ gateway, site }));
  }

  removeGatewayFromSite(gateway: Gateway, site: Site): void {
    this.store.dispatch(SiteGatewaysActions.removeGatewayFromSite({ gateway, site }));
  }

  synchronizeGateways(site: Site): void {
    this.store.dispatch(SiteGatewaysActions.synchronizeGatewaysBySite({ site }));
  }
}
