<section [fxLayout]="displayMode === 'default' ?  'row' : 'column'"  fxLayoutGap="20px">
  <div fxFlex="50%" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="6px">
    <span (click)="selectDevice.emit(device)"
          class="mve-detail-popup-title fw-600 mve-detail-popup-title-clickable">{{ device?.name }}</span>
    <iot-platform-ui-device-info-status [device]="device"></iot-platform-ui-device-info-status>
  </div>
  <div class="" fxFlex="50%" fxLayout="column">
    <iot-platform-ui-detail-popup-display-property
      property="{{ 'DEVICES.DETAIL_DEVICE_CARD.STATUS' | translate }}"
      value="{{ 'IOT_DICTIONARY.' + device?.status?.name | infoDisplay | translate }}"
    ></iot-platform-ui-detail-popup-display-property>
    <iot-platform-ui-detail-popup-display-property
      property="{{ 'DEVICES.DETAIL_DEVICE_CARD.TYPE' | translate }}"
      value="{{ device?.type | infoDisplay: '-':'family':'model':'firmware' }}"
    ></iot-platform-ui-detail-popup-display-property>
    <iot-platform-ui-detail-popup-display-property
      property="{{ 'DEVICES.DETAIL_DEVICE_CARD.IDENTIFIER' | translate }}"
      value="{{ device?.identifier | infoDisplay }}"
    ></iot-platform-ui-detail-popup-display-property>
    <iot-platform-ui-detail-popup-display-property
      property="{{ 'DEVICES.DETAIL_DEVICE_CARD.SUPPORT' | translate }}"
      value="{{ device?.communication.support | infoDisplay }}"
    ></iot-platform-ui-detail-popup-display-property>
    <iot-platform-ui-detail-popup-display-property
      property="{{ 'DEVICES.DETAIL_DEVICE_CARD.ENDPOINT' | translate }}"
      value="{{ device?.communication.endpoint | infoDisplay }}"
    ></iot-platform-ui-detail-popup-display-property>
  </div>
</section>
