import { I4BCellType, I4BColumnConfiguration } from '../configurations';
import { I4bCellConcept } from '../enums';
import { HeaderType, I4BColumnHeader } from '../models';
import { I4BVariableColumnOptions } from '../options';
import { I4BBasicColumn } from './i4b-basic.column';
import { RichVariableColumn } from './i4b-rich-variable.column';
import { I4BThresholdColumn } from './i4b-threshold.column';

const defaultDisplayThresholds = { 1: false, 2: false, 3: false, 4: false, 5: false };

export class AssetVariableGroupColumn extends I4BBasicColumn {
  columnId = '19bcb62c-724b-11ec-a844-acde48001122-asset-variable-group';
  className = AssetVariableGroupColumn;

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BVariableColumnOptions>) {
    super(
      {
        displayName: customOptions?.overrideHeaderName ? customOptions?.overrideHeaderName : customHeader?.displayName ? customHeader?.displayName : 'VARIABLE',
        type: HeaderType.BASIC
      },
      {
        cell: { type: I4BCellType.BASIC },
        id: customConfig.id,
        concept: I4bCellConcept.GROUPED_VARIABLES,
        isDefault: false,
        marryChildren: true,
        children: [
          new RichVariableColumn(
            { displayName: 'VALUE' },
            {
              id: 'expandedVariables.' + customConfig.id,
              isDefault: true,
              sortProperty: 'expandedVariables.' + customConfig.id + 'lastValue.value',
              cell: {
                type: I4BCellType.RICH_VARIABLE,
                options: { dataType: 'expandedVariable', unit: 'unit', value: 'value', tooltip: 'datetime' },
                event: { type: 'openChartByVariable', options: { variableName: customConfig.id } },
                valueGetter: 'expandedVariables.' + customConfig.id + '.lastValue.value'
              }
            },
            { order: 0, customId: 'expandedVariables.' + customConfig.id, sortable: true, isDisplayed: true }
          ),
          new I4BBasicColumn(
            { displayName: 'DATETIME' },
            { id: 'expandedVariables.' + customConfig.id + '.lastValue.datetime', cell: { type: I4BCellType.DATE }, width: 130 },
            { order: 1, customId: 'expandedVariables.' + customConfig.id, isDisplayed: customOptions.displayDateTime }
          ),
          new I4BThresholdColumn(
            {},
            {
              id: customConfig.id,
              cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL, options: { position: 1 } }
            },
            {
              order: 3,
              displayThresholds: customOptions.displayThresholds
            }
          ),
          new I4BThresholdColumn(
            {},
            {
              id: customConfig.id,
              cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL, options: { position: 2 } }
            },
            {
              order: 4,
              displayThresholds: customOptions.displayThresholds
            }
          ),
          new I4BThresholdColumn(
            {},
            {
              id: customConfig.id,
              cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL, options: { position: 3 } }
            },
            {
              order: 5,
              displayThresholds: customOptions.displayThresholds
            }
          ),
          new I4BThresholdColumn(
            {},
            {
              id: customConfig.id,
              cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL, options: { position: 4 } }
            },
            {
              order: 6,
              displayThresholds: customOptions.displayThresholds
            }
          ),
          new I4BThresholdColumn(
            {},
            {
              id: customConfig.id,
              cell: { type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL, options: { position: 5 } }
            },
            {
              order: 7,
              displayThresholds: customOptions.displayThresholds
            }
          )
        ]
      },
      { displayDateTime: true, displayThresholds: defaultDisplayThresholds, ...customOptions, customId: customConfig.id }
    );
  }
}
