import { Injectable } from '@angular/core';
import { PlatformResponse, TagCategory } from '@iot-platform/models/common';

import {
  Asset,
  AssetVariable,
  Device,
  DeviceEvent,
  DeviceVariable,
  I4BBulkOperationApiResponse,
  I4BBulkOperationApiResponseStatuses,
  Site
} from '@iot-platform/models/i4b';

import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { GridsDbActions } from '../../../../../../../grid-engine/src/lib/components/state/actions';
import * as fromGrids from '../../../../../../../grid-engine/src/lib/components/state/reducers';
import * as fromFavoriteViews from '../../../../../../../shared/src/lib/favorite-views/+state/reducers';

import { DeviceEventsService } from '../../../../../../../shared/src/lib/services/device-events.service';
import {
  UserPreferencesService
} from '../../../../../../../users/src/lib/features/preferences/services/user-preferences.service';
import { DeviceEventsDbActions, DeviceEventsLogsUiActions, DeviceEventsUiActions } from '../actions';

@Injectable()
export class DeviceEventsEffects {
  loadDeviceEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadDeviceEvents),
      switchMap((action) =>
        this.deviceEventsService.getDeviceEvents(action.request).pipe(
          map((response: PlatformResponse) => DeviceEventsDbActions.loadDeviceEventsSuccess({ response })),
          catchError((error) => of(DeviceEventsDbActions.loadDeviceEventsFailure({ error })))
        )
      )
    )
  );

  loadSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadSiteById),
      switchMap((action) =>
        this.deviceEventsService.getSiteById(action.siteId).pipe(
          map((site: Site) => DeviceEventsDbActions.loadSiteByIdSuccess({ site })),
          catchError((error) => of(DeviceEventsDbActions.loadSiteByIdFailure({ error })))
        )
      )
    )
  );

  loadAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadAssetById),
      switchMap((action) =>
        this.deviceEventsService.getAssetById(action.assetId).pipe(
          map((asset: Asset) => DeviceEventsDbActions.loadAssetByIdSuccess({ asset })),
          catchError((error) => of(DeviceEventsDbActions.loadAssetByIdFailure({ error })))
        )
      )
    )
  );

  loadAssetVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadAssetVariableById),
      switchMap((action) =>
        this.deviceEventsService.getAssetVariableById(action.assetVariableId).pipe(
          map((assetVariable: AssetVariable) => DeviceEventsDbActions.loadAssetVariableByIdSuccess({ assetVariable })),
          catchError((error) => of(DeviceEventsDbActions.loadAssetVariableByIdFailure({ error })))
        )
      )
    )
  );

  loadDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadDeviceById),
      switchMap((action) =>
        this.deviceEventsService.getDeviceById(action.deviceId).pipe(
          map((device: Device) => DeviceEventsDbActions.loadDeviceByIdSuccess({ device })),
          catchError((error) => of(DeviceEventsDbActions.loadDeviceByIdFailure({ error })))
        )
      )
    )
  );

  loadDeviceVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadDeviceVariableById),
      switchMap((action) =>
        this.deviceEventsService.getDeviceVariableById(action.deviceVariableId).pipe(
          map((deviceVariable: DeviceVariable) => DeviceEventsDbActions.loadDeviceVariableByIdSuccess({ deviceVariable })),
          catchError((error) => of(DeviceEventsDbActions.loadDeviceVariableByIdFailure({ error })))
        )
      )
    )
  );

  loadTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadTagsByDeviceEventId),
      switchMap((action) => {
        return this.deviceEventsService.getTagsByDeviceEventId(action.deviceEventId).pipe(
          map((tags: TagCategory[]) => DeviceEventsDbActions.loadTagsByDeviceEventIdSuccess({ tags })),
          catchError((error) => of(DeviceEventsDbActions.loadTagsByDeviceEventIdFailure({ error })))
        );
      })
    )
  );

  updateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.updateStatusByDeviceEventId),
      withLatestFrom(this.store.select(fromGrids.getDefaultDeviceEventsGrid)),
      switchMap(([action, grid]) =>
        this.deviceEventsService.putStatus(action.status).pipe(
          switchMap((deviceEvent: DeviceEvent) =>
            grid?.id
              ? [
                  DeviceEventsDbActions.updateStatusByDeviceEventIdSuccess({ deviceEvent }),
                  GridsDbActions.updateItemInGridData({ gridId: grid.id, item: deviceEvent })
                ]
              : [DeviceEventsDbActions.updateStatusByDeviceEventIdSuccess({ deviceEvent })]
          ),
          catchError((error) => of(DeviceEventsDbActions.updateStatusByDeviceEventIdFailure({ error })))
        )
      )
    )
  );

  bulkUpdateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.bulkUpdateStatusByDeviceEventId),
      switchMap((action) => this.deviceEventsService.bulkUpdateStatus(action.deviceEventIds, action.status)),
      mergeMap((results) =>
        results.pipe(
          switchMap((deviceEvent) => [
            GridsDbActions.updateItemInAllGridsData({ updatedItem: deviceEvent }),
            DeviceEventsDbActions.updateStatusByDeviceEventIdSuccess({ deviceEvent })
          ]),
          catchError((error) => of(DeviceEventsDbActions.updateStatusByDeviceEventIdFailure({ error })))
        )
      )
    )
  );

  newBulkUpdateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.newBulkUpdateStatusByDeviceEventId),
      withLatestFrom(
        this.store.pipe(select(fromGrids.getDefaultDeviceEventsGrid)),
        this.store.pipe(select(fromFavoriteViews.getFiltersForMasterViewDeviceEvents))
      ),
      switchMap(([action, grid, filters]) =>
        this.deviceEventsService.newBulkUpdateStatusByEventType('device-events', action.deviceEventIds, action.status).pipe(
          concatMap((response: I4BBulkOperationApiResponse) => {
            return [
              DeviceEventsDbActions.newBulkUpdateStatusByDeviceEventIdSuccess({ response }),
              GridsDbActions.loadGridData({
                request: {
                  filters,
                  limit: grid?.data.response.pagination.limit,
                  concept: grid?.masterview.toLowerCase(),
                  page: grid?.data.response.pagination.currentPage,
                  variables: grid?.gridOptions.variableNames,
                  tags: grid?.gridOptions.tagIds,
                  endPoint: grid?.gridOptions.endPoint
                }
              })
            ];
          }),
          catchError((error) => of(DeviceEventsDbActions.newBulkUpdateStatusByAssetEventIdFailure({ error })))
        )
      )
    )
  );

  displaySuccessAfterBulkUpdateStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceEventsDbActions.newBulkUpdateStatusByDeviceEventIdSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type + I4BBulkOperationApiResponseStatuses[action.response.status]);
        })
      ),
    { dispatch: false }
  );

  loadLogsAfterUpdateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsDbActions.updateStatusByDeviceEventIdSuccess),
      map((action) => DeviceEventsLogsUiActions.loadLogsByDeviceEventId({ deviceEventId: action.deviceEvent.id }))
    )
  );

  loadMVDeviceEventsSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.loadMVSettings),
      switchMap((action) =>
        this.userPrefService.loadActiveSettings(action.settingName).pipe(
          map((settings) => {
            return DeviceEventsDbActions.loadMVSettingsSuccess({ settings });
          }),
          catchError((error) => of(DeviceEventsDbActions.loadMVSettingsFailure({ error: error })))
        )
      )
    )
  );

  saveTableState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsUiActions.saveTableState),
      switchMap((action) =>
        this.deviceEventsService.saveTableState(action.tableState).pipe(
          map((tableState: { selected: DeviceEvent; checked: DeviceEvent[] }) =>
            DeviceEventsDbActions.saveTableStateSuccess({
              selectedId: tableState.selected ? tableState.selected.id : null,
              checkedIds: tableState.checked ? tableState.checked.map((c) => c.id) : []
            })
          ),
          catchError((error) => of(DeviceEventsDbActions.saveTableStateFailure({ error })))
        )
      )
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceEventsDbActions.updateStatusByDeviceEventIdSuccess, DeviceEventsDbActions.saveMVSettingsSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceEventsDbActions.saveTableStateFailure,
          DeviceEventsDbActions.loadDeviceEventsFailure,
          DeviceEventsDbActions.updateStatusByDeviceEventIdFailure,
          DeviceEventsDbActions.loadMVSettingsFailure,
          DeviceEventsDbActions.saveMVSettingsFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceEventsUiActions.loadDeviceEvents,
          DeviceEventsUiActions.updateStatusByDeviceEventId,
          DeviceEventsUiActions.saveMVSettings,
          DeviceEventsUiActions.loadMVSettings,
          DeviceEventsUiActions.newBulkUpdateStatusByDeviceEventId
        ),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeviceEventsDbActions.loadDeviceEventsSuccess,
          DeviceEventsDbActions.updateStatusByDeviceEventIdSuccess,
          DeviceEventsDbActions.loadDeviceEventsFailure,
          DeviceEventsDbActions.updateStatusByDeviceEventIdFailure,
          DeviceEventsDbActions.saveMVSettingsSuccess,
          DeviceEventsDbActions.saveMVSettingsFailure,
          DeviceEventsDbActions.loadMVSettingsSuccess,
          DeviceEventsDbActions.loadMVSettingsFailure,
          DeviceEventsDbActions.newBulkUpdateStatusByDeviceEventIdSuccess,
          DeviceEventsDbActions.newBulkUpdateStatusByAssetEventIdFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly deviceEventsService: DeviceEventsService,
    private readonly notificationService: NotificationService,
    private readonly userPrefService: UserPreferencesService,
    private readonly store: Store
  ) {}
}
