<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 [innerHtml]="title$ | async" class="dialog-card-title"></h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <form [formGroup]="favoriteViewForm" class="full-height" fxLayout="column">
      <mat-tab-group color="accent" mat-align-tabs="center">
        <mat-tab label="{{ 'FAVORITE_VIEW.FORM.TABS_LABEL.INFORMATION' | translate | uppercase }}">
          <div class="full-height-width p-20" fxLayout="column" fxLayoutAlign="start " fxLayoutGap="20px">
            <iot-platform-ui-detail-popup-display-property
              property="{{ 'FAVORITE_VIEW.FORM.MASTER_VIEW_LABEL' | translate }}"
              value="{{ 'FAVORITE_VIEW.FORM.MASTER_VIEW.' + data.favoriteView.masterView | translate }}"
            ></iot-platform-ui-detail-popup-display-property>
            <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
              <div fxFlex fxLayout="column" fxLayoutAlign="start ">
                <mat-form-field color="accent">
                  <div fxLayout="row">
                    <input
                      #nameInput
                      [placeholder]="'FAVORITE_VIEW.FORM.PLACEHOLDER_NAME' | translate"
                      formControlName="name"
                      matInput
                      maxlength="50"
                      pattern="\S.*"
                      required
                    />
                    <mat-progress-spinner *ngIf="checkingName$ | async" [diameter]="14" color="accent" mode="indeterminate"></mat-progress-spinner>
                  </div>
                  <mat-error *ngIf="name.invalid && (name.errors.maxlength || name.errors.pattern)">{{ errorMessage$ | async }}</mat-error>
                  <mat-hint *ngIf="displayDuplicateMessage$ | async"
                    ><span class="duplicate-error">{{ 'FAVORITE_VIEW.FORM.ERROR_MESSAGE.DUPLICATE' | translate }}</span></mat-hint
                  >
                  <mat-hint align="end">{{ nameInput.value?.length || 0 }}/50</mat-hint>
                </mat-form-field>

                <mat-checkbox formControlName="pinned" fxFlex="40">{{ 'FAVORITE_VIEW.FORM.ADD_TO_HOME_PAGE' | translate }}</mat-checkbox>
              </div>
              <div *ngIf="data.canUpdateBusinessProfile" fxFlex fxLayout="column" fxLayoutAlign="start ">
                <mat-form-field color="accent">
                  <mat-select
                    (selectionChange)="onBusinessProfileChange()"
                    [placeholder]="'FAVORITE_VIEW.FORM.BUSINESS_PROFILE' | translate"
                    formControlName="businessProfileId"
                    required
                  >
                    <mat-option *ngFor="let bp of businessProfileList" [value]="bp.id">{{ bp.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-checkbox (change)="sharedBoxChanged$.next($event.checked)" *ngIf="data.canUpdateBusinessProfile" formControlName="shared" fxFlex="60">{{
                  'FAVORITE_VIEW.FORM.SHARED_WITH_BP' | translate
                }}</mat-checkbox>
                <mat-error class="share-warning-message">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SHARE_WARNING' | translate }}</mat-error>
                <mat-error *ngIf="selectedGridType.value === GridTypes.USER_GRID" class="share-warning-message">{{
                  'FAVORITE_VIEW.FORM.SHARING_FV_GRID_WARNING' | translate
                }}</mat-error>
              </div>
            </section>
            <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
              <mat-form-field class="favorite-view-color" color="accent" fxFlex="50">
                <iot-platform-ui-color-picker
                  (selectColor)="onSelectColor($event)"
                  [preSelectedColor]="data.favoriteView.id ? data.favoriteView.color : ''"
                  fxFlex="80"
                ></iot-platform-ui-color-picker>
                <input formControlName="color" matInput style="display: none" />
              </mat-form-field>
              <mat-form-field color="accent" fxFlex="50">
                <textarea
                  #descriptionInput
                  formControlName="description"
                  matInput
                  maxlength="200"
                  placeholder="{{ 'FAVORITE_VIEW.FORM.DESCRIPTION' | translate }}"
                  rows="3"
                ></textarea>
                <mat-hint align="end">{{ descriptionInput.value?.length || 0 }}/200</mat-hint>
              </mat-form-field>
            </section>

            <div fxFlex></div>

            <section *ngIf="mode?.edition" class="favorite-view-info" fxLayout="row">
              <iot-platform-ui-detail-popup-metadata-footer [data]="data.favoriteView"></iot-platform-ui-detail-popup-metadata-footer>
            </section>
          </div>
        </mat-tab>
        <mat-tab [disabled]="checkingName$ | async" label="{{ 'FAVORITE_VIEW.FORM.TABS_LABEL.FILTERS' | translate | uppercase }} ({{ filtersNumber() }})">
          <div class="full-width p-20 filter-engine-container" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="20px">
            <iot-platform-ui-filter-engine
              (applyFilters)="onApplyFilters($event)"
              [clearAppliedFilters$]="clearAppliedFilters$"
              [currentFavoriteView$]="currentFavoriteView$"
              [currentFilters$]="currentFilters$"
              [expanded]="true"
              [masterView]="data.favoriteView.masterView.toLowerCase()"
              [readonly]="data.duplicateMode"
              fxFlex
            >
            </iot-platform-ui-filter-engine>
          </div>
        </mat-tab>
        <mat-tab *ngIf="displayGridTab()" [disabled]="checkingName$ | async" label="{{ 'FAVORITE_VIEW.FORM.TABS_LABEL.GRID' | translate | uppercase }}">
          <div class="full-width p-20" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="20px">
            <label>{{ 'FAVORITE_VIEW.FORM.GRID_SELECTION_LABEL' | translate }}</label>
            <mat-radio-group (change)="onGridTypeChange()" color="accent" formControlName="selectedGridType" fxLayout="row" fxLayoutAlign="space-between start">
              <div fxFlex="20" fxLayout="column" fxLayoutGap="10px">
                <mat-radio-button [value]="GridTypes.DEFAULT_GRID">
                  <label>{{ 'FAVORITE_VIEW.FORM.NO_GRID' | translate }}</label>
                </mat-radio-button>
                <mat-hint style="font-size: 10px">{{ 'FAVORITE_VIEW.FORM.DEFAULT_GRID_HINT' | translate }}</mat-hint>
              </div>
              <div fxFlex="30" fxLayout="column">
                <mat-radio-button [value]="GridTypes.USER_GRID" class="example-radio-button">
                  <mat-label>{{ 'FAVORITE_VIEW.FORM.USER_GRIDS' | translate }}</mat-label>
                </mat-radio-button>
                <mat-form-field color="accent" fxLayoutGap="30px">
                  <mat-label>{{ 'FAVORITE_VIEW.FORM.SELECT_A_GRID' | translate }}</mat-label>
                  <mat-select
                    (selectionChange)="onSelectedGridChange($event)"
                    [(value)]="selectedGrid"
                    [disabled]="selectedGridType.value !== GridTypes.USER_GRID || data.duplicateMode"
                  >
                    <mat-option *ngFor="let grid of userGrids" [value]="grid">
                      {{ grid.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-hint style="font-size: 10px">{{ 'FAVORITE_VIEW.FORM.USER_GRID_HINT' | translate }}</mat-hint>
                <mat-error *ngIf="!!shared.value && selectedGridType.value === GridTypes.USER_GRID" class="share-warning-message">{{
                  'FAVORITE_VIEW.FORM.SHARING_FV_GRID_WARNING' | translate
                }}</mat-error>
              </div>

              <div fxFlex="40" fxLayout="column">
                <mat-radio-button [value]="GridTypes.SHARED_GRIDS" class="example-radio-button">
                  <mat-label>{{ 'FAVORITE_VIEW.FORM.SHARED_GRIDS' | translate }}</mat-label>
                </mat-radio-button>
                <mat-form-field color="accent" fxLayoutGap="30px">
                  <mat-label>{{ 'FAVORITE_VIEW.FORM.SELECT_A_GRID' | translate }}</mat-label>
                  <mat-select
                    (selectionChange)="onSelectedGridChange($event)"
                    [(value)]="selectedGrid"
                    [disabled]="selectedGridType.value !== GridTypes.SHARED_GRIDS || data.duplicateMode"
                  >
                    <mat-option *ngFor="let grid of sharedGrids" [value]="grid">
                      {{ grid.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-radio-group>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'FAVORITE_VIEW.FORM.CANCEL' | translate }}</button>
    <button
      (click)="save()"
      [disabled]="!favoriteViewForm.valid || !isSelectedGridIdValid || (isDisabled$ | async)"
      class="button-regular"
      color="accent"
      mat-raised-button
    >
      {{ 'FAVORITE_VIEW.FORM.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
