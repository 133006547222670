import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { DateIntervalUtils, GetUtils } from '@iot-platform/iot-platform-utils';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { BaseComponent } from '../base-component/base-component';
import { DateRange, DateRangeElement } from './date-range.model';

@Component({
  selector: 'iot-platform-ui-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() placeholderFrom = 'DATE_RANGE.PLACEHOLDER_FROM';
  @Input() placeholderTo = 'DATE_RANGE.PLACEHOLDER_TO';
  @Input() placeholderHours = 'DATE_RANGE.PLACEHOLDER_HOURS';
  @Input() placeholderMinutes = 'DATE_RANGE.PLACEHOLDER_MINUTES';
  @Input() placeholderClear = 'DATE_RANGE.CLEAR';
  @Input() required = false;
  @Input() dateFormatterPattern!: string;
  @Input() diffInMonths = 0;

  @Input() clearDateRangeFilters$: Subject<any> = new Subject<any>();
  @Input() validateForm$: Subject<any> = new Subject<any>();

  @Output() dateRangeChange: EventEmitter<DateRange> = new EventEmitter();
  @Output() dateRangeReady: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  @Output() dateRangeCleared: EventEmitter<DateRange> = new EventEmitter();

  dateForm!: UntypedFormGroup;
  hours: Array<number> = [];
  minutes: Array<number> = [];
  defaultEndHours = 23;
  defaultEndMinutes = 59;
  defaultEndSeconds = 0;

  constructor(private storage: LocalStorageService) {
    super();
  }

  get startDate(): AbstractControl | null {
    return this.dateForm.get('startDate');
  }

  get startHours(): AbstractControl | null {
    return this.dateForm.get('startHours');
  }

  get startMinutes(): AbstractControl | null {
    return this.dateForm.get('startMinutes');
  }

  get startSeconds(): AbstractControl | null {
    return this.dateForm.get('startSeconds');
  }

  get endDate(): AbstractControl | null {
    return this.dateForm.get('endDate');
  }

  get endHours(): AbstractControl | null {
    return this.dateForm.get('endHours');
  }

  get endMinutes(): AbstractControl | null {
    return this.dateForm.get('endMinutes');
  }

  get endSeconds(): AbstractControl | null {
    return this.dateForm.get('endSeconds');
  }

  get timeZone(): string {
    if (JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY))?.timezoneDetails?.offset) {
      return GetUtils.get(JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)), 'timezoneDetails.offset', '');
    } else {
      return GetUtils.get(JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)), 'timezone', '');
    }
  }

  ngOnInit(): void {
    this.hours = DateIntervalUtils.getArrayOfConsecutiveNumbers(0, this.defaultEndHours);
    this.minutes = DateIntervalUtils.getArrayOfConsecutiveNumbers(0, this.defaultEndMinutes);
    this.initForm();
    this.subscriptions.push(
      this.dateForm.valueChanges.pipe(distinctUntilChanged()).subscribe(() => this.onValueChanges()),
      this.clearDateRangeFilters$.subscribe(() => {
        this.resetForm();
      }),
      this.validateForm$.subscribe(() => {
        this.validateAllFields();
      })
    );
    this.dateRangeReady.emit(this.dateForm);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dateForm && Object.prototype.hasOwnProperty.call(changes, 'diffInMonths') && changes.diffInMonths.currentValue !== null) {
      this.dateForm.setValidators(changes.diffInMonths.currentValue > 0 ? [this.dateRangeDiffInMonthsValidator] : []);
      this.dateForm.updateValueAndValidity({ emitEvent: false });
    }
  }

  validateAllFields(): void {
    Object.keys(this.dateForm.controls).forEach((field: any) => {
      const c = this.dateForm.get(field);
      c.markAsTouched({ onlySelf: true });
      c.updateValueAndValidity();
    });
  }

  resetForm(): void {
    this.dateForm.reset(
      {
        startDate: null,
        startHours: 0,
        startMinutes: 0,
        startSeconds: 0,
        endDate: null,
        endHours: this.defaultEndHours,
        endMinutes: this.defaultEndMinutes,
        endSeconds: this.defaultEndSeconds
      },
      { emitEvent: false }
    );
  }

  resetStartDate(): void {
    this.dateForm.reset({
      ...this.dateForm.value,
      startDate: null,
      startHours: 0,
      startMinutes: 0,
      startSeconds: 0
    });

    this.dateRangeCleared.emit({ startDate: this.getStartDate(), endDate: this.getEndDate() });
  }

  resetEndDate(): void {
    this.dateForm.reset({
      ...this.dateForm.value,
      endDate: null,
      endHours: this.defaultEndHours,
      endMinutes: this.defaultEndMinutes,
      endSeconds: this.defaultEndSeconds
    });
    this.dateRangeCleared.emit({ startDate: this.getStartDate(), endDate: this.getEndDate() });
  }

  getStartDate(): DateRangeElement | null {
    return !!this.startDate.value ? this.getDate(this.startDate.value, this.startHours.value, this.startMinutes.value, this.startSeconds.value) : null;
  }

  getEndDate(): DateRangeElement | null {
    return !!this.endDate.value ? this.getDate(this.endDate.value, this.endHours.value, this.endMinutes.value, this.endSeconds.value) : null;
  }

  getDate(date: Date, hours: number, minutes: number, seconds: number): DateRangeElement {
    const _date = new Date(date);
    _date.setHours(hours);
    _date.setMinutes(minutes);
    _date.setSeconds(seconds);
    const { value, dateLabel: label } = DateIntervalUtils.getDateFilter(_date, hours, minutes, seconds, this.timeZone)
    return {
      date: _date,
      hours,
      minutes,
      seconds,
      label,
      value: this.dateFormatterPattern ? moment(value).format(this.dateFormatterPattern) : value,
      timestamp: _date.getTime()
    };
  }

  onValueChanges(): void {
    this.dateRangeChange.emit({
      startDate: this.getStartDate(),
      endDate: this.getEndDate()
    });
  }

  private initForm(): void {
    this.dateForm = new UntypedFormGroup(
      {
        startDate: new UntypedFormControl(null, this.required ? [Validators.required] : []),
        startHours: new UntypedFormControl(0, []),
        startMinutes: new UntypedFormControl(0, []),
        startSeconds: new UntypedFormControl(0, []),
        endDate: new UntypedFormControl(null, this.required ? [Validators.required] : []),
        endHours: new UntypedFormControl(this.defaultEndHours, []),
        endMinutes: new UntypedFormControl(this.defaultEndMinutes, []),
        endSeconds: new UntypedFormControl(this.defaultEndSeconds, [])
      },
      this.diffInMonths > 0 ? [this.dateRangeDiffInMonthsValidator] : []
    );
  }

  private readonly dateRangeDiffInMonthsValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from: DateRangeElement | null = this.dateForm ? this.getStartDate() : null;
    const to: DateRangeElement | null = this.dateForm ? this.getEndDate() : null;
    if (from && to) {
      const diffInMonths = moment.duration(moment(to.date).diff(moment(from.date))).asMonths();
      invalid = diffInMonths > this.diffInMonths;
    }
    return invalid ? { invalidRange: { from: from?.date, to: to?.date } } : null;
  };
}
