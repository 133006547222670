<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="2px" color="primary">
  <div class="regular-toolbar-section" fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <span class="regular-toolbar-label">{{ 'USERS.PROFILE_INFO.SHELL_TITLE' | translate | uppercase }}</span>
    <mat-progress-spinner *ngIf="loading$ | async" mode="indeterminate" [diameter]="20" class="regular-loader" color="accent"></mat-progress-spinner>
  </div>
</mat-toolbar>

<div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="50px" class="user-profile-info-container">
  <iot-platform-users-user-profile-info
    fxFlex="50%"
    [currentUser]="currentUser$ | async"
    (editUserProfile)="editUserProfile()"
  ></iot-platform-users-user-profile-info>

  <iot-platform-users-user-profile-preferences
    fxFlex="50%"
    [withNotifications]="withNotifications"
    [currentUser]="currentUser$ | async"
    [preferences]="preferences$ | async"
    (changeUserPreferences)="changeUserPreferences()"
  >
  </iot-platform-users-user-profile-preferences>
</div>
