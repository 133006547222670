import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomEncoder } from '@iot-platform/core';

import { BusinessProfile, FavoriteView, PlatformRequest, PlatformResponse } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AssetEventsService } from '../../services/asset-events.service';
import { AssetsService } from '../../services/assets.service';
import { DeviceEventsService } from '../../services/device-events.service';
import { DevicesService } from '../../services/devices.service';
import { SitesService } from '../../services/sites.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteViewsService {
  constructor(
    @Inject('environment') private environment,
    private httpClient: HttpClient,
    private sitesService: SitesService,
    private assetsService: AssetsService,
    private devicesService: DevicesService,
    private assetEventsService: AssetEventsService,
    private deviceEventsService: DeviceEventsService
  ) {}

  getFavoriteViews(): Observable<FavoriteView[]> {
    return this.httpClient
      .get(this.environment.api.url_v2 + this.environment.api.endpoints.favoriteViews)
      .pipe(map((data: { page: any; content: FavoriteView[] }) => data.content));
  }

  getFavoriteViewsByBusinessProfile(businessProfileId: string, masterview: string, shared: boolean = true): Observable<FavoriteView[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('businessProfileId', businessProfileId);
    params = params.set('shared', shared);
    params = params.set('masterview', masterview);
    return this.httpClient.get<{ page: any; content: any }>(`${this.environment.api.url_v2}${this.environment.api.endpoints.favoriteViews}`, { params }).pipe(
      map((data) => {
        return data.content;
      })
    );
  }

  saveFavoriteView(favoriteView: FavoriteView): Observable<FavoriteView> {
    return this.httpClient.post<FavoriteView>(this.environment.api.url_v2 + this.environment.api.endpoints.favoriteViews, favoriteView);
  }

  updateFavoriteView(favoriteView: FavoriteView): Observable<FavoriteView> {
    return this.httpClient.patch<FavoriteView>(this.environment.api.url_v2 + this.environment.api.endpoints.favoriteViews + '/' + favoriteView.id, {
      name: favoriteView.name,
      pinned: favoriteView.pinned,
      shared: favoriteView.shared,
      color: favoriteView.color,
      filters: favoriteView.filters,
      description: favoriteView.description,
      businessProfileId: favoriteView.businessProfileId,
      gridId: favoriteView.gridId
    });
  }

  deleteFavoriteView(favoriteView: FavoriteView): Observable<any> {
    return this.httpClient
      .delete<any>(this.environment.api.url_v2 + this.environment.api.endpoints.favoriteViews + '/' + favoriteView.id)
      .pipe(map((_) => favoriteView));
  }

  getFavoriteViewCount(favoriteView: FavoriteView): Observable<FavoriteView> {
    const request: PlatformRequest = {
      page: 0,
      limit: 0,
      filters: favoriteView.filters
    };

    switch (favoriteView.masterView) {
      case 'sites':
        return this.sitesService.getAllSites(request).pipe(
          map((response: PlatformResponse) => {
            favoriteView.count = response.total;
            return favoriteView;
          })
        );
      case 'assets':
        return this.assetsService.getAll(request).pipe(
          map((response: PlatformResponse) => {
            favoriteView.count = response.total;
            return favoriteView;
          })
        );
      case 'devices':
        return this.devicesService.getAll(request).pipe(
          map((response: PlatformResponse) => {
            favoriteView.count = response.total;
            return favoriteView;
          })
        );
      case 'asset-events':
        return this.assetEventsService.getAssetEvents(request).pipe(
          map((response: PlatformResponse) => {
            favoriteView.count = response.total;
            return favoriteView;
          })
        );
      case 'device-events':
        return this.deviceEventsService.getDeviceEvents(request).pipe(
          map((response: PlatformResponse) => {
            favoriteView.count = response.total;
            return favoriteView;
          })
        );
      default:
        return of(favoriteView);
    }
  }

  getBusinessProfiles(): Observable<BusinessProfile[]> {
    return this.httpClient
      .get<{ page: any; content: BusinessProfile[] }>(`${this.environment.api.url}${this.environment.api.endpoints.businessProfiles}`)
      .pipe(map((response) => response.content));
  }

  getGridsByMasterViewAndBusinessProfileId(masterView: string, businessProfileId: string): Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('businessProfileId', businessProfileId);

    return this.httpClient
      .get<{ page: any; content: I4BGrid<I4BGridOptions, I4BGridData>[] }>(`${this.environment.api.url}${this.environment.api.endpoints.grids}/${masterView}`, {
        params
      })
      .pipe(map((response) => response.content));
  }

  getPersonalGridByUserId(masterView: string, userId: string): Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('userId', userId);

    return this.httpClient
      .get<{ page: any; content: I4BGrid<I4BGridOptions, I4BGridData>[] }>(`${this.environment.api.url}${this.environment.api.endpoints.grids}/${masterView}`, {
        params
      })
      .pipe(map((response) => response.content));
  }

  duplicateAndShareGrid(
    gridToDuplicate: I4BGrid<I4BGridOptions, I4BGridData>,
    favoriteView: FavoriteView
  ): Observable<{ grid: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }> {
    gridToDuplicate = { ...gridToDuplicate, businessProfileId: favoriteView.businessProfileId, id: undefined };
    return this.httpClient
      .post<I4BGrid<I4BGridOptions, I4BGridData>>(`${this.environment.api.url}${this.environment.api.endpoints.grids}/${favoriteView.masterView}`, {
        ...gridToDuplicate,
        data: null
      })
      .pipe(
        map((grid) => {
          return { grid, favoriteView };
        })
      );
  }

  shareGrid(
    grid: I4BGrid<I4BGridOptions, I4BGridData>,
    favoriteView: FavoriteView
  ): Observable<{ grid: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }> {
    grid = { ...grid, businessProfileId: favoriteView.businessProfileId };
    return this.httpClient
      .put<I4BGrid<I4BGridOptions, I4BGridData>>(`${this.environment.api.url}/grids/${favoriteView.masterView}/${grid.id}`, { ...grid, data: null })
      .pipe(
        map((grid) => {
          return { grid, favoriteView };
        })
      );
  }
}
