import { Ward } from '@iot-platform/models/oyan';
import { ReceiverBuilder } from './receiver.builder';

export class WardBuilder {
  public static DEFAULT_WARD: Ward = {
    id: 'c657bfd1-8914-4c38-891c-254652567f81',
    siteId: 'e03b49bc-d878-47b7-86d7-7157e68872aa',
    name: 'Trauma',
    comment: 'Localit\u00e0 Sirri 9013 CARBONIA',
    building: 'B',
    floor: '1',
    UF: null,
    code: null,
    sorting: null,
    primaryStock: false,
    receivers: [
      ReceiverBuilder.build('614b3854-2fd3-446b-9a6b-9b69d970c089', '"3299ff6d-3320-415e-9b97-a7efe81e67f5_0334edd2-9b2f-4069-be39-bb56ee74b40d_0010747171"')
    ],
    createdAt: '2021-05-25T10:03:20.599645+00:00',
    createdBy: null,
    updatedAt: '2021-06-10T15:38:36.551101+00:00',
    updatedBy: null,
    shipToId: '',
    totalReceivers: 2,
    address: null
  };
  static build = (id: string, name = '', params: Partial<Ward> = WardBuilder.DEFAULT_WARD): Ward => ({
    ...WardBuilder.DEFAULT_WARD,
    ...params,
    id,
    name
  });
}
