<oyan-ui-simple-card class="card" [element]="element" [withMenu]="false" [icon]="icon" [cssClasses]="cssClasses" [actions]="actions">
  <iot4bos-ui-site-contact
    cardContent
    fxLayout="column"
    class="card__contact"
    [minimalDisplay]="true"
    [contact]="element"
    [canUpdate]="canUpdateSite && canUpdate"
    (toEdit)="onEdit()"
    (delete)="onDelete()"
  >
  </iot4bos-ui-site-contact>
</oyan-ui-simple-card>
