import { NgModule } from '@angular/core';

import { SharedModule } from '@iot-platform/shared';

import { IconModule } from '../../../../iot-platform-ui/src/lib/ui/components/icon/icon.module';
import { MvToolbarComponent } from './mv-toolbar.component';

@NgModule({
  imports: [SharedModule, IconModule],
  declarations: [MvToolbarComponent],
  exports: [MvToolbarComponent]
})
export class MvToolbarModule {}
