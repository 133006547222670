import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageOptions {
  public prefix = '';
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private options: LocalStorageOptions;

  constructor(options: LocalStorageOptions) {
    this.options = options;
  }

  get(key: string, skipPrefix = false): string {
    return localStorage.getItem(skipPrefix ? key : `${this.options.prefix}.${key}`) as string;
  }

  set(key: string, data: string, skipPrefix = false): void {
    localStorage.setItem(skipPrefix ? key : `${this.options.prefix}.${key}`, data);
  }

  remove(key: string, skipPrefix = false): void {
    localStorage.removeItem(skipPrefix ? key : `${this.options.prefix}.${key}`);
  }

  clear(): void {
    localStorage.clear();
  }
}
