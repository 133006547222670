import { Component, Input, OnInit } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Subject } from 'rxjs';

import { AuthValidators } from '../../validators/auth.validator';

@Component({
  selector: 'iot-platform-login-password-rules',
  templateUrl: './login-password-rules.component.html',
  styleUrls: ['./login-password-rules.component.scss']
})
export class LoginPasswordRulesComponent implements OnInit {
  @Input() password$: Subject<string>;

  errors: ValidationErrors | null = {
    format: {
      size: true,
      lowercase: true,
      uppercase: true,
      number: true,
      special: true
    }
  };

  ngOnInit() {
    this.password$.subscribe((password) => (this.errors = AuthValidators.checkPassword(password)));
  }
}
