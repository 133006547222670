import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { IotToolbarEvent } from '@iot-platform/models/common';
import { IotToolbarDispatchActionType } from './configuration-files/button-dispatch-action-type.enum';
import { IotToolbarButtonType } from './configuration-files/button-type.enum';
import { IotToolbarDefaultButton } from './configuration-files/default-button';
import { IotToolbarMenuButton } from './configuration-files/menu-button';

@Component({
  selector: 'iot-platform-ui-toolbar-button',
  templateUrl: './toolbar-button.component.html',
  styleUrls: ['./toolbar-button.component.scss']
})
export class ToolbarButtonComponent implements OnInit {
  @Input() button!: IotToolbarDefaultButton | IotToolbarMenuButton;

  @Output() buttonClick: EventEmitter<IotToolbarEvent> = new EventEmitter();

  computedClasses: { [key: string]: boolean } = {};
  filterEngineOpenByDefault: boolean = JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.filterEngineOpenByDefault ?? false;
  displayMenuButton = false;
  menuButton?: IotToolbarMenuButton;

  constructor(private readonly storage: LocalStorageService) {}

  ngOnInit() {
    this.initFilterEngineButtonStatus();

    if (this.button instanceof IotToolbarDefaultButton && this.button.type === IotToolbarButtonType.TOGGLE_BUTTON) {
      this.setComputedClassesForToggleButtons(this.button);
    }

    if (this.button instanceof IotToolbarMenuButton) {
      this.menuButton = this.button;
    }
  }

  initFilterEngineButtonStatus(): void {
    if (this.button instanceof IotToolbarDefaultButton && this.button.dispatchAction.type === IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE) {
      if (this.filterEngineOpenByDefault) {
        this.button.isToggleOn = true;
      }
    }
  }

  onButtonClick(event: IotToolbarEvent): void {
    if (this.button instanceof IotToolbarDefaultButton && this.button.type === IotToolbarButtonType.TOGGLE_BUTTON) {
      this.button.isToggleOn = !this.button.isToggleOn;
      this.setComputedClassesForToggleButtons(this.button);
    }
    this.buttonClick.emit(event);
  }

  onMenuOptionClick(event: IotToolbarEvent): void {
    this.buttonClick.emit(event);
  }

  setComputedClassesForToggleButtons(button: IotToolbarDefaultButton): void {
    this.computedClasses[button.toggleOnClass] = button.isToggleOn;
    this.computedClasses[button.toggleOffClass] = !button.isToggleOn;
  }
}
