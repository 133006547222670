<div class="site-contact" [class.minimalDisplay]="minimalDisplay">
  <div class="site-contact-row" fxLayout="row">
    <div class="site-contact-col" fxFlex="40px">
      <button mat-mini-fab class="user-initials" color="accent">{{ initials }}</button>
    </div>
    <div class="site-contact-col" fxLayout="column" fxLayoutAlign="space-between start">
      <div class="contact-info">
        <div class="contact-info__name">
          <h3
            [matTooltip]="contact.firstname + ' ' + contact.lastname | infoDisplay"
            matTooltipClass="regular-tooltip"
            matTooltipPosition="above"
            class="site-contact-title fw-600"
          >
            {{ contact.firstname + ' ' + contact.lastname | infoDisplay }}
          </h3>
          <div class="site-contact-job fw-600 capitalize-first-letter">{{ contact.function | infoDisplay }}</div>
        </div>
        <div class="contact-info__phone-email">
          <div class="phone">
            <mat-icon>phone</mat-icon>
            <div class="site-contact-item">{{ contact | infoDisplay: 'pipe':'phone':'mobile' }}</div>
          </div>
          <div class="email">
            <mat-icon>mail_outlined</mat-icon
            ><a
              class="site-contact-item"
              [matTooltip]="contact.email"
              matTooltipClass="regular-tooltip"
              matTooltipPosition="above"
              href="mailto:{{ contact.email }}"
              >{{ contact.email | infoDisplay }}</a
            >
          </div>
        </div>
      </div>
      <div class="flex-wrapper" *ngIf="!minimalDisplay">
        <div class="box notification-wrapper">
          <ng-container *ngIf="contact.notificationFilter?.length">
            <div class="notification-info">
              <div class="subtitle notif-active">
                <mat-icon svgIcon="send_email"></mat-icon> &nbsp;
                {{ 'SITES.CONTACT_FORM.NOTIFICATION_ACTIVE' | translate }}
              </div>
              <div>
                {{ 'IOT_DICTIONARY.SUPPORT' | translate }}:
                <span *ngIf="!contact.notificationSupport?.sms"> {{ 'SITES.CONTACT_FORM.EMAIL' | translate }}</span>
                <span *ngIf="contact.notificationSupport?.sms"> {{ 'SITES.CONTACT_FORM.EMAIL+SMS' | translate }}</span>
              </div>
              <div>
                <span>{{ 'IOT_DICTIONARY.assets' | translate }}: </span>
                <iot4bos-ui-list-display [list]="getFilterByKey('assetName')" [maxToShow]="4"></iot4bos-ui-list-display>
              </div>
              <div class="event-class">
                <span> {{ 'SITES.CONTACT_FORM.NOTIFICATION_CLASS' | translate }}: </span>
                <iot4bos-ui-list-display [list]="getFilterByKey('eventClass')"></iot4bos-ui-list-display>
              </div>
              <div class="event-severity">
                {{ 'SITES.CONTACT_FORM.NOTIFICATION_SEVERITY' | translate }}:
                <iot4bos-ui-list-display [list]="getFilterByKey('eventSeverity')" [maxToShow]="4"></iot4bos-ui-list-display>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!contact.notificationFilter?.length">
            <div class="notification-info">
              <div class="subtitle notif-deactivate">
                <mat-icon svgIcon="no_email"></mat-icon>
                {{ 'SITES.CONTACT_FORM.NOTIFICATION_NOT_ACTIVE' | translate }}
              </div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="spreadsheetExport$ | async as spreadsheetExport">
          <div class="box exports-wrapper">
            <ng-container *ngIf="loaded$ | async">
              <div *ngIf="spreadsheetExport.status !== 'ACTIVE'" class="subtitle no-export">
                <mat-icon svgIcon="excel_file"></mat-icon>
                {{ 'SITES.CONTACT_FORM.EXPORT_NOT_ACTIVE' | translate }}
              </div>
              <div *ngIf="spreadsheetExport.status === 'ACTIVE'" class="export-active">
                <div class="subtitle capitalize-first-letter">
                  <mat-icon svgIcon="excel_file"></mat-icon>
                  {{ 'SITES.CONTACT_FORM.EXPORT_SPREADSHEET_ACTIVE' | translate }}
                </div>
                <br />
                <div>
                  {{ 'IOT_DICTIONARY.LAST_RUN' | translate }}:
                  <a
                    *ngIf="spreadsheetExport.jobs?.[0]?.presignedUrl"
                    href="{{ spreadsheetExport.jobs[0].presignedUrl }}"
                    download="Spreadsheet Export ({{ spreadsheetExport?.lastRun | dateFormat: 'yyyy-MM-DD HH:mm' }})"
                    target="_blank"
                    rel="noopener"
                    >{{ spreadsheetExport?.lastRun | dateFormat: 'yyyy-MM-DD HH:mm' | infoDisplay }}</a
                  >
                  <span *ngIf="!spreadsheetExport.jobs?.[0]?.presignedUrl">{{
                    spreadsheetExport?.lastRun | dateFormat: 'yyyy-MM-DD HH:mm' | infoDisplay
                  }}</span>
                </div>
                <div>
                  {{ 'IOT_DICTIONARY.NEXT_RUN' | translate }}: <span>{{ spreadsheetExport?.nextRun | dateFormat: 'yyyy-MM-DD HH:mm' }}</span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="loading$ | async">
              <div fxLayout="column" style="height: 100%; width: 100%" fxLayoutAlign="center center">
                <mat-progress-spinner mode="indeterminate" [diameter]="30" color="accent"></mat-progress-spinner>
              </div>
            </ng-container>
            <ng-container *ngIf="fetchingError$ | async">
              <div fxLayout="column" style="height: 100%; width: 100%" fxLayoutAlign="center center">
                {{ 'SITES.SPREADSHEET_EXPORT_FORM.LOADING_ERROR' | translate }}
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <div class="site-card-buttons app-round-button" *ngIf="canUpdate || canDelete">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngIf="canUpdate" (click)="edit()">{{ 'SITES.CONTACT_FORM.EDIT' | translate }}</button>
          <ng-container *ngIf="!minimalDisplay">
            <button mat-menu-item *ngIf="canUpdate" (click)="configureNotif()">{{ 'SITES.CONTACT_FORM.CONFIGURE_NOTIF' | translate }}</button>
            <button mat-menu-item *ngIf="canUpdate" (click)="configureExport()">{{ 'SITES.CONTACT_FORM.CONFIGURE_EXPORT' | translate }}</button>
          </ng-container>
          <button mat-menu-item *ngIf="canDelete" (click)="onDelete()">{{ 'SITES.CONTACT_FORM.DELETE' | translate }}</button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
