<h2 [ngClass]="{'login-regular-title': !isI4BLogin, 'layout-login-regular-title': isI4BLogin}">{{ 'LOGIN.TITLE.GET_CODE' | translate }}</h2>

<p *ngIf="!isI4BLogin" class="login-get-code-message">{{ 'LOGIN.HELP_MESSAGE.GET_CODE' | translate }}</p>

<form [formGroup]="form" fxLayout="column" [ngClass]="{ 'login-form-i4b': isI4BLogin }">
  <mat-form-field fxFlex="100%" color="accent">
    <mat-icon matPrefix>email</mat-icon>
    <input matInput formControlName="email" type="email" [placeholder]="'LOGIN.EMAIL' | translate" (input)="onChangeEmail($event.target.value)" />
    <mat-error *ngIf="email.invalid">{{ 'LOGIN.ERROR_MESSAGE.EMAIL' | translate }}</mat-error>
  </mat-form-field>

  <div class="layout-login-error" [class.error]="hasError">
    <em>{{ 'LOGIN.ERROR_MESSAGE.INCORRECT_EMAIL' | translate }}</em>
    <span>{{ hasError?.message }}</span>
  </div>

  <p *ngIf="isI4BLogin" class="login-get-code-message-i4b">{{ 'LOGIN.HELP_MESSAGE.GET_CODE' | translate }}</p>

  <div [ngClass]="{'layout-form-actions': isI4BLogin, 'layout-login-form-actions': !isI4BLogin}">
    <button class="layout-login-button" type="button" mat-button (click)="onCancel()">{{ 'LOGIN.BUTTON.CANCEL' | translate }}</button>
    <button class="layout-login-button layout-login-button--submit" type="submit" mat-raised-button [disabled]="!form.valid" (click)="onSendCode()">
      {{ 'LOGIN.BUTTON.SEND_CODE' | translate }}
    </button>
  </div>
</form>
