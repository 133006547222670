import { CommonApiRequest, CommonApiResponse, CommonIndexedPagination, Filter } from '@iot-platform/models/common';
import { Gateway } from '@iot-platform/models/oyan';
import { createAction, props } from '@ngrx/store';

export const loadGateways = createAction('[OYAN GATEWAYS/UI] Load Gateways', props<{ request: CommonApiRequest }>());
export const loadGatewaysSuccess = createAction(
  '[OYAN GATEWAYS/API] Load Gateways Success',
  props<{ response: CommonApiResponse<Gateway, CommonIndexedPagination> }>()
);
export const loadGatewaysFailure = createAction('[OYAN GATEWAYS/API] Load Gateways Failure', props<{ error: unknown }>());

export const loadGatewayById = createAction('[OYAN GATEWAYS/UI] Load Gateway By Id', props<{ id: string }>());
export const loadGatewayByIdSuccess = createAction('[OYAN GATEWAYS/API] Load Gateway By Id Success', props<{ response: Gateway }>());
export const loadGatewayByIdFailure = createAction('[OYAN GATEWAYS/API] Load Gateway By Id Failure', props<{ error: unknown }>());

export const setFilters = createAction('[OYAN GATEWAYS/UI] Set filters', props<{ filters: Filter[] }>());
