import { Action, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';
import { TopicSubscribedUsersDbActions, TopicSubscribedUsersUiActions } from '../actions';
//
export const topicsSubscribedUsersUiFeatureKey = 'topicsSubscribedUsersUi';

export interface State {
  error?: any;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false
};

const topicsSubscribedUsersUiReducer = createReducer(
  initialState,
  on(TopicSubscribedUsersUiActions.listUsersSubscribedToTopicByTopicId, (state: State) => ({ ...state, pending: true })),
  on(TopicSubscribedUsersDbActions.listUserslSubscribedToTopicByTopicIdSuccess, (state: State) => ({ ...state, pending: false })),
  on(TopicSubscribedUsersDbActions.listUsersubscribedToTopicByTopicIdFailure, (state: State, { error }) => ({ ...state, error, pending: false })),
  //
  on(TopicSubscribedUsersUiActions.subscribeUserToTopic, (state: State) => ({ ...state, pending: true })),
  on(TopicSubscribedUsersDbActions.subscribeToTopicByUserIdSuccess, (state: State) => ({ ...state, pending: false })),
  on(TopicSubscribedUsersDbActions.subscribeToTopicByUserIdFailure, (state: State, { error }) => ({ ...state, error, pending: false })),
  //
  on(TopicSubscribedUsersUiActions.unsubscribeUserToTopic, (state: State) => ({ ...state, pending: true })),
  on(TopicSubscribedUsersDbActions.unsubscribeToTopicByUserIdSuccess, (state: State) => ({ ...state, pending: false })),
  on(TopicSubscribedUsersDbActions.unsubscribeToTopicByUserIdFailure, (state: State, { error }) => ({ ...state, error, pending: false })),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return topicsSubscribedUsersUiReducer(state, action);
}

export const getPending = (state: State) => state.pending;
export const getTopicSubscribedUsersError = (state: State) => state.error;
