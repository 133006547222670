<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ title$ | async }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content p-20">
    <form [formGroup]="businessProfileFrom" fxLayout="column">
      <iot-platform-ui-async-autocomplete
        [data]="sortedEntities"
        [standaloneMode]="true"
        [disabled]="!!data?.organization || !!data?.businessProfile"
        [initialItem]="initialEntity"
        [showSpinner]="entitiesLoading"
        [displaySearchIcon]="false"
        [minLength]="1"
        [displayKey]="'label'"
        [placeholder]="'IOT_DICTIONARY.ENTITY'"
        [filterKey]="'label'"
        [required]="true"
        (selectionChanged)="onEntitySelection($event)"
        (search)="resetEntity()"
        (reset)="resetEntity()"
      >
      </iot-platform-ui-async-autocomplete>
      <mat-form-field class="" color="accent">
        <input matInput formControlName="nameControl" required maxlength="50" [placeholder]="'ADMIN.DIALOG.ADD_BP.PLACEHOLDER_NAME' | translate" />
        <mat-hint align="end" *ngIf="displayDuplicateMessage$ | async">
          <span class="dialog-card-input-error">{{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_NAME' | translate }}</span>
        </mat-hint>
      </mat-form-field>

      <iot-platform-ui-timezone-autocomplete
        fxFlex="25%"
        [initialItem]="initialTZ"
        [required]="true"
        [clearOnSelect]="false"
        [displayOffset]="true"
        (selectionChange)="onTimezoneSelection($event)"
        (reset)="resetTimezone()"
      >
      </iot-platform-ui-timezone-autocomplete>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-label id="chart-period-radio-group-label">{{ 'ADMIN.BUSINESS_PROFILES.DETAIL.CHART_DEFAULT_PERIOD' | translate }}</mat-label>
        <mat-radio-group
          formControlName="chartPeriodControl"
          aria-labelledby="chart-period-radio-group-label"
          class="example-radio-group"
          color="accent"
          fxLayout="row"
          fxLayoutGap="10px"
          [value]="chartDefaultPeriod"
        >
          <mat-radio-button [value]="1">{{ 'ADMIN.BUSINESS_PROFILES.DETAIL.CHART_1_DAY' | translate }}</mat-radio-button>
          <mat-radio-button [value]="7">{{ 'ADMIN.BUSINESS_PROFILES.DETAIL.CHART_7_DAY' | translate }}</mat-radio-button>
          <mat-radio-button [value]="30">{{ 'ADMIN.BUSINESS_PROFILES.DETAIL.CHART_30_DAY' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="slider-container">
        <mat-label id="stock-visibility-radio-group-label">{{ 'ADMIN.BUSINESS_PROFILES.DETAIL.STOCK_SITES' | translate }}</mat-label>
        <mat-slide-toggle color="accent" formControlName="stockVisibilityControl">
          {{ 'ADMIN.BUSINESS_PROFILES.DETAIL.VISIBLE' | translate }}
        </mat-slide-toggle>
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="close()">{{ 'ADMIN.DIALOG.ADD_BP.CANCEL' | translate }}</button>
    <button
      mat-raised-button
      color="accent"
      class="button-regular"
      [disabled]="!businessProfileFrom.valid || (nameControl.touched && isDisabled$ | async)"
      (click)="save()"
    >
      {{ 'ADMIN.DIALOG.ADD_BP.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
