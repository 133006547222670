import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-detail-popup-display-property',
  templateUrl: './detail-popup-display-property.component.html',
  styleUrls: ['./detail-popup-display-property.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailPopupDisplayPropertyComponent {
  @Input() property?: string;
  @Input() value?: string | number;
  @Input() margin?: string;
  @Input() loading: boolean = false;
}
