import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SharedModule } from '@iot-platform/shared';
import { BreadcrumbItemComponent } from './breadcrumb-item.component';

@NgModule({
  imports: 
  [
    CommonModule, 
    MatIconModule, 
    SharedModule
  ],
  declarations: [BreadcrumbItemComponent],
  exports:  [BreadcrumbItemComponent],
  providers: []
})
export class BreadcrumbItemModule {}
