import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@iot-platform/shared';

import { FavoriteViewFormModule } from '../favorite-view-form/favorite-view-form.module';
import { FilterEngineModule } from '../filter-engine/filter-engine.module';
import { FilterEngineContainerComponent } from './filter-engine-container.component';
import { ManageFavoriteFiltersPopupComponent } from './manage-favorite-filters-popup/manage-favorite-filters-popup.component';

@NgModule({
  imports: [FilterEngineModule, FavoriteViewFormModule, SharedModule, CommonModule, DragDropModule],
  declarations: [FilterEngineContainerComponent, ManageFavoriteFiltersPopupComponent],
  exports: [FilterEngineContainerComponent]
})
export class FilterEngineContainerModule {}
