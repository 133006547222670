<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ title$ | async }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <form [formGroup]="productForm" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="" class="full-width p-20">
      <mat-form-field color="accent">
        <mat-select
          formControlName="catalog"
          required
          [placeholder]="'ADMIN.PRODUCT_CATALOGS.FORM.CATALOG' | translate"
          (selectionChange)="initCatalogValidation()"
        >
          <!--(selectionChange)="checkIdUnicity()"-->
          <mat-option *ngFor="let catalog of data.catalogs" [value]="catalog">{{ catalog.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="catalog.invalid">{{ errorMessage$ | async }}</mat-error>
      </mat-form-field>

      <mat-form-field color="accent">
        <input
          matInput
          #productIdInput
          formControlName="productId"
          required
          [maxlength]="ID_MAX_LENGTH"
          [placeholder]="'ADMIN.PRODUCT_CATALOGS.PRODUCT_ID' | translate"
          (change)="initCatalogValidation()"
          oninput="this.value = this.value.toUpperCase()"
        />
        <mat-error *ngIf="productId.invalid && !productId.value">{{ errorMessage$ | async }}</mat-error>
        <mat-error *ngIf="productId.invalid && productId.errors.pattern">{{ 'ADMIN.PRODUCT_CATALOGS.FORM.PATTERN_ERROR' | translate }}</mat-error>
        <mat-error *ngIf="productId.invalid && productId.errors.productExist">{{ 'ADMIN.PRODUCT_CATALOGS.FORM.DUPLICATE_ID' | translate }}</mat-error>
        <mat-hint align="end">{{ productIdInput.value?.length || 0 }}/{{ ID_MAX_LENGTH }}</mat-hint>
      </mat-form-field>

      <mat-form-field color="accent">
        <input
          matInput
          #nameInput
          formControlName="name"
          required
          [maxlength]="NAME_MAX_LENGTH"
          [placeholder]="'ADMIN.PRODUCT_CATALOGS.FORM.PRODUCT_NAME' | translate"
        />
        <mat-error *ngIf="name.invalid">{{ errorMessage$ | async }}</mat-error>
        <mat-hint align="end">{{ nameInput.value?.length || 0 }}/{{ NAME_MAX_LENGTH }}</mat-hint>
      </mat-form-field>
      <p>{{ 'IOT_DICTIONARY.MANDATORY_FIELDS' | translate }}</p>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="close()">{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button mat-raised-button class="button-regular" color="accent" (click)="save()" [disabled]="!productForm.valid && productForm.dirty">
      {{ actionButtonLabel$ | async | translate }}
    </button>
  </mat-card-actions>
</mat-card>
