import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthInterceptor } from '@iot-platform/auth';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { EventSeverityDisplayModule, IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  FilterEngineModule
} from '../../../../../iot-platform-ui/src/lib/ui/components/filter-engine/filter-engine.module';
import { MvToolbarModule } from '../../../../../shared/src/lib/mv-toolbar/mv-toolbar.module';
import {
  MasterViewEngineModule
} from '../../../../../table-engine/src/lib/components/master-view-engine/master-view-engine.module';
import { EventCommentsModule } from '../../components/event-comments/event-comments.module';
import { PanelModule } from '../../components/panel/panel.module';
import { PoEventsEffects } from './+state/effects/po-events.effects';
import * as fromPoEventRules from './+state/reducers';
import {
  NumberInputComponent
} from './components/po-event-configure-form/controls/number-input/number-input.component';
import {
  StringInputComponent
} from './components/po-event-configure-form/controls/string-input/string-input.component';
import { TextInputComponent } from './components/po-event-configure-form/controls/text-input/text-input.component';
import { PoEventConfigureFormComponent } from './components/po-event-configure-form/po-event-configure-form.component';
import { PoEventCreateFormComponent } from './components/po-event-create-form/po-event-create-form.component';
import { PoEventDetailPopupComponent } from './components/po-event-detail-popup/po-event-detail-popup.component';
import {
  PoEventGeneratedEventsTabComponent
} from './components/po-event-generated-events-tab/po-event-generated-events-tab.component';
import { PoEventInfoComponent } from './components/po-event-info/po-event-info.component';
import { PoEventDetailsShellComponent } from './containers/po-event-details-shell/po-event-details-shell.component';
import { PoEventsShellComponent } from './containers/po-events-shell/po-events-shell.component';

const poEventRulesRoutes: Routes = [
  { path: '', component: PoEventsShellComponent },
  { path: ':poEventId', component: PoEventDetailsShellComponent }
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(fromPoEventRules.poEventRulesFeatureKey, fromPoEventRules.reducers),
    EffectsModule.forFeature([PoEventsEffects]),
    RouterModule.forChild(poEventRulesRoutes),
    MasterViewEngineModule,
    PanelModule,
    MvToolbarModule,
    IotPlatformUiModule,
    FilterEngineModule,
    IotPlatformPipesModule,
    EventSeverityDisplayModule,
    GridEngineModule,
    EventCommentsModule
  ],
  declarations: [
    PoEventsShellComponent,
    PoEventDetailPopupComponent,
    PoEventCreateFormComponent,
    PoEventConfigureFormComponent,
    NumberInputComponent,
    StringInputComponent,
    TextInputComponent,
    PoEventDetailsShellComponent,
    PoEventInfoComponent,
    PoEventGeneratedEventsTabComponent
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class PoEventsModule {}
