import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';

import { TranslateModule } from '@ngx-translate/core';

import { TagEditorModule } from '../../../../../../iot4bos-backoffice-ui/src/lib/components/tag-editor/tag-editor.module';
import { ChipModule } from '../chip/chip.module';
import { ColorPickerModule } from '../color-picker/color-picker.module';
import { IconModule } from '../icon/icon.module';
import { ManageTagsFormComponent } from './manage-tags-form.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ChipModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatChipsModule,
    MatButtonModule,
    IconModule,
    FlexLayoutModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatExpansionModule,
    TagEditorModule,
    ColorPickerModule,
    MatProgressSpinnerModule
  ],
  declarations: [ManageTagsFormComponent],
  exports: [ManageTagsFormComponent]
})
export class ManageTagsFormModule {}
