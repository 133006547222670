import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { DateFormatModule } from '../../../../../../iot-platform-pipes/src/lib/date-format/date-format.module';
import { SortEntitiesUtil } from '../../../../../../shared/src/lib/utils/sort-entities.util';
import { AsyncAutocompleteMultipleSelectsModule } from '../async-autocomplete-multiple-selects/async-autocomplete-multiple-selects.module';
import { AsyncAutocompleteModule } from '../async-autocomplete/async-autocomplete.module';
import { ChipModule } from '../chip/chip.module';
import { CountryAutocompleteModule } from '../country-autocomplete/country-autocomplete.module';
import { DateRangeModule } from '../date-range/date-range.module';
import { IconModule } from '../icon/icon.module';
import { TimezoneAutocompleteMultipleSelectsModule } from '../timezone-autocomplete-multiple-selects/timezone-autocomplete-multiple-selects.module';
import { TimezoneAutocompleteModule } from '../timezone-autocomplete/timezone-autocomplete.module';
import { CountryAutocompleteFieldComponent } from './country-autocomplete-field/country-autocomplete-field.component';
import { DateIntervalFieldComponent } from './date-field/date-interval-field.component';
import { DynamicListFieldService } from './dynamic-list-field/dynamic-list-filed.service';
import { DynamicListFieldMultipleSelectsComponent } from './dynamic-list-field/multiple-selects/dynamic-list-field-multiple-selects.component';
import { DynamicListFieldSingleSelectComponent } from './dynamic-list-field/single-select/dynamic-list-field-single-select.component';
import { FavoriteFilterEngineDirective } from './favorite-filter-engine.directive';
import { FavoriteFilterEngineComponent } from './favorite-filter-engine/favorite-filter-engine.component';
import { FilterComponentFactory } from './filter-component-factory';
import { FilterEngineComponent } from './filter-engine.component';
import { FilterEngineDirective } from './filter-engine.directive';
import { FilterEngineService } from './filter-engine.service';
import { InputFieldComponent } from './input-field/input-field.component';
import { MultipleInputsFieldComponent } from './input-field/multiple-inputs-field/multiple-inputs-field.component';
import { SelectFieldComponent } from './select-field/select-field.component';
import { TimezoneAutocompleteFieldComponent } from './timezone-autocomplete-field/timezone-autocomplete-field.component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDatepickerModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatChipsModule,
    ChipModule,
    IotPlatformPipesModule,
    TranslateModule,
    IconModule,
    DateFormatModule,
    MatProgressSpinnerModule,
    DateRangeModule,
    TimezoneAutocompleteModule,
    TimezoneAutocompleteMultipleSelectsModule,
    AsyncAutocompleteModule,
    AsyncAutocompleteMultipleSelectsModule,
    CountryAutocompleteModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatTooltipModule
  ],
  exports: [FilterEngineComponent, FavoriteFilterEngineComponent],
  declarations: [
    FilterEngineComponent,
    FavoriteFilterEngineComponent,
    FilterEngineDirective,
    FavoriteFilterEngineDirective,
    InputFieldComponent,
    MultipleInputsFieldComponent,
    SelectFieldComponent,
    DateIntervalFieldComponent,
    DynamicListFieldSingleSelectComponent,
    DynamicListFieldMultipleSelectsComponent,
    TimezoneAutocompleteFieldComponent,
    CountryAutocompleteFieldComponent
  ],
  providers: [FilterEngineService, FilterComponentFactory, DynamicListFieldService, SortEntitiesUtil]
})
export class FilterEngineModule {}
