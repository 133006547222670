<div fxLayout="column" fxFlex fxLayoutAlign="space-between stretch">
  <h2 class="layout-login-regular-title" [ngClass]="{'login-select-authentication--title': isI4BLogin}">{{ 'LOGIN.TITLE.LOGIN' | translate }}</h2>

  <form [formGroup]="form" fxLayout="column" [ngClass]="{'login-form-i4b': isI4BLogin}">
    <mat-form-field fxFlex="100%" color="accent">
      <mat-icon matPrefix>email</mat-icon>
      <input matInput formControlName="email" type="email" [placeholder]="'LOGIN.EMAIL' | translate" (input)="onChangeEmail($event.target.value)" />
      <mat-error *ngIf="email.invalid">{{ 'LOGIN.ERROR_MESSAGE.EMAIL' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="100%" color="accent">
      <mat-icon matPrefix>lock</mat-icon>
      <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" [placeholder]="'LOGIN.PASSWORD' | translate" />
      <mat-icon class="layout-pointer-cursor" matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      <mat-error *ngIf="password.invalid">{{ 'LOGIN.ERROR_MESSAGE.PASSWORD' | translate }}</mat-error>
    </mat-form-field>

    <div *ngIf="!isI4BLogin" fxLayout="row" fxLayoutAlign="end start" class="login-reset-pwd-button" >
      <button mat-button type="button" (click)="onResetPassword()">{{ 'LOGIN.RESET_PASSWORD' | translate }}</button>
    </div>

    <div class="layout-login-error" fxLayout="column" [class.error]="hasError">
      <em>{{ 'LOGIN.ERROR_MESSAGE.LOGIN_FAILED' | translate }}</em>
      <span>{{ 'LOGIN.ERROR_MESSAGE.FIX_EMAIL_PASSWORD' | translate }}</span>
    </div>

    <div class="layout-form-actions" [ngClass]="{'layout-form-actions-i4b': isI4BLogin}" fxLayoutGap="20px">
      <button class="layout-login-button" type="button" mat-button *ngIf="isI4BLogin" (click)="cancel.emit()">{{ 'LOGIN.BUTTON.CANCEL' | translate }}</button>

      <iot-platform-ui-progress-bar-button
        type="submit"
        [disabled]="form.invalid"
        [title]="'LOGIN.BUTTON.SIGN_IN' | translate"
        [enableGauge]="enableGauge && !hasError"
        [gaugeColor]="'accent'"
        (clickEvent)="onSignIn()"
      ></iot-platform-ui-progress-bar-button>
    </div>
  </form>

    <span *ngIf="isI4BLogin" class="login-reset-pwd-button-i4b" (click)="onResetPassword()">{{ 'LOGIN.RESET_PASSWORD' | translate }}</span>
</div>
