<mat-card class="dialog-card translation-preview">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'ADMIN.TRANSLATION_MANAGEMENT.PREVIEW.TITLE' | translate }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <div class="full-width p-20" fxLayout="column" fxLayoutGap="10px">
      <ng-container *ngFor="let item of translations">
        <iot-platform-ui-detail-popup-section-header
          headerTitle="{{ item?.key | uppercase }}"
        ></iot-platform-ui-detail-popup-section-header>

        <div fxLayout="row" fxLayoutAlign="start center"
             *ngFor="let lang of item?.languages">
          <iot-platform-ui-detail-popup-display-property
            property="{{ lang?.key | uppercase }}"
            value="{{ lang?.value | infoDisplay }}"
          ></iot-platform-ui-detail-popup-display-property>
        </div>
      </ng-container>

    </div>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="close()">{{ 'IOT_DICTIONARY.CANCEL' | translate | uppercase }}</button>
    <button mat-raised-button class="button-regular" color="accent" (click)="close(true)">
      {{ 'ADMIN.TRANSLATION_MANAGEMENT.PREVIEW.DOWNLOAD' | translate | uppercase }}
    </button>
  </mat-card-actions>
</mat-card>
