<mat-card class="dialog-card timeseries-widget">
  <mat-card-title class="dialog-card-header" *ngIf="options?.header?.enabled">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title timeseries-widget_title">{{ options?.header?.title }}</h1>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content" fxLayout="row">
    <iot4bos-ui-variable-chart
      class="timeseries-widget_chart timeseries-widget_chart--{{ options?.size?.toLowerCase() }}"
      variableType="assetVariable"
      [data]="options?.variables"
      [options]="options"
    ></iot4bos-ui-variable-chart>
  </mat-card-content>

  <mat-card-actions *ngIf="options?.footer?.enabled" class="dialog-card-actions" fxLayout="row" fxLayoutAlign="center center">
    <div class="timeseries-widget_legend">{{ options?.footer?.legend }}</div>
  </mat-card-actions>
</mat-card>
