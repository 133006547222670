import { Component, ComponentRef, Input, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { fromAuth } from '@iot-platform/auth';
import { FavoriteFiltersByConcept, Filter, FilterType, User, UserPreferences } from '@iot-platform/models/common';
import { fromUserPreferences } from '@iot-platform/users';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortUtil } from '../../../../../../../shared/src/lib/utils/sort.util';
import { PreferencesActions } from '../../../../../../../users/src/lib/features/preferences/state/actions';
import {
  ManageFavoriteFiltersPopupComponent
} from '../../filter-engine-container/manage-favorite-filters-popup/manage-favorite-filters-popup.component';
import { FavoriteFilterEngineDirective } from '../favorite-filter-engine.directive';

import { FilterComponentFactory } from '../filter-component-factory';
import { FilterEngineComponent } from '../filter-engine.component';
import { FilterEngineService } from '../filter-engine.service';
import { FilterComponent } from '../filter.component';

interface FilterCriteriaConfiguration {
  key: string;
  label: string;
  order: number;
  options: FilterConfiguration[];
}

interface FilterConfiguration {
  key: string;
  defaultFavorite?: boolean;
}

@Component({
  selector: 'iot-platform-ui-favorite-filter-engine',
  templateUrl: './favorite-filter-engine.component.html',
  styleUrls: ['./favorite-filter-engine.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FavoriteFilterEngineComponent extends FilterEngineComponent {
  FilterType = FilterType;
  currentFilterType = FilterType.FAVORITE;

  filterCriteria: FilterCriteriaConfiguration[] = [];
  resetFavoriteFilters$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userPreferences!: UserPreferences;
  user!: User;

  @Input() displayManageFavoriteFilters = true;

  @ViewChild(FavoriteFilterEngineDirective, { static: true }) iotPlatformUiFavoriteFilterEngine!: FavoriteFilterEngineDirective;

  constructor(
    private readonly store: Store,
    private readonly dialog: MatDialog,
    protected readonly filterEngineService: FilterEngineService,
    protected readonly filterComponentFactory: FilterComponentFactory
  ) {
    super(filterEngineService, filterComponentFactory);
  }

  ngOnInit() {
    super.ngOnInit();
    this.resetFilterCriteriaButtonTitle();
    this.subs.push(
      combineLatest([
        this.filterEngineService.getFilterCriteriaByConcept(this.masterView),
        this.store.pipe(select(fromUserPreferences.getPreferences)),
        this.resetFavoriteFilters$
      ]).subscribe(([categories, preferences, resetFavoriteFilters]) => {
        this.filterCriteria = this.filterEngineService.sortFilterCriteria(categories);
        let favoriteFilters: FilterConfiguration[] = [];

        if (!!preferences.favoriteFilters && !!preferences.favoriteFilters[this.masterView] && this.displayManageFavoriteFilters) {
          favoriteFilters = FavoriteFilterEngineComponent.getFilterByUserPreferences(this.filterCriteria, preferences.favoriteFilters[this.masterView]);
        } else {
          categories.some((category: FilterCriteriaConfiguration) =>
            category.options.some((option: FilterConfiguration) => {
              if (!!option.defaultFavorite) {
                favoriteFilters.push(option);
              }
            })
          );
          favoriteFilters.sort(SortUtil.sortByKey);
        }
        if (favoriteFilters.length) {
          this.clearViewContainerRef(this.iotPlatformUiFavoriteFilterEngine?.viewContainerRef);
          favoriteFilters.forEach((criteria) => this.addField(criteria, FilterType.FAVORITE));
        }
      }),
      this.store.pipe(select(fromUserPreferences.getPreferences)).subscribe((preferences) => (this.userPreferences = preferences)),

      this.store.pipe(select(fromAuth.selectAccount)).subscribe((user) => {
        if (user) {
          this.user = user;
        }
      })
    );
  }

  initViewContainerRef(filterType = FilterType.EXPANDED): ViewContainerRef {
    if (filterType === FilterType.EXPANDED) {
      const viewContainerRef: ViewContainerRef = this.iotPlatformUiFilterEngine.viewContainerRef;
      viewContainerRef.clear();
      return viewContainerRef;
    } else {
      return this.iotPlatformUiFavoriteFilterEngine.viewContainerRef;
    }
  }

  static getFilterByUserPreferences(filterCriteria: FilterCriteriaConfiguration[], favoriteFilters: FavoriteFiltersByConcept): FilterConfiguration[] {
    const flatFilters = Object.values(favoriteFilters).flat();
    flatFilters.sort(SortUtil.sortByOrder);
    const flatFilterCriteria: FilterConfiguration[] = filterCriteria.map((fc) => fc.options).flat();

    return flatFilters.reduce((acc: FilterConfiguration[], f: { name: string; order: number }) => {
      const matchingFilterCriteria = flatFilterCriteria.find((fc) => fc.key === f.name);
      if (matchingFilterCriteria) {
        acc.push(matchingFilterCriteria);
      }
      return acc;
    }, []);
  }

  onClearAllFiltersForFavorite() {
    this.onClearAllFilters();
    this.resetFavoriteFilters$.next(true);
  }

  clearViewContainerRef(viewContainerRef: ViewContainerRef): void {
    viewContainerRef.clear();
  }

  addField(criteria: any, filterType = FilterType.EXPANDED) {
    if (filterType == FilterType.EXPANDED) {
      this.filterCriteriaButtonTitle = criteria.fullLabel;
    }

    const componentRef: ComponentRef<FilterComponent> | null = this.filterComponentFactory.createComponent(
      criteria.element,
      this.initViewContainerRef(filterType)
    );

    if (componentRef) {
      componentRef.instance.data = criteria.options;
      componentRef.instance.currentFiltersSize = this.currentFiltersNotHidden.length;
      componentRef.instance.maxFilters = this.maxFilters;

      if (criteria.options.multiSelect) {
        if (criteria.key === 'eventType' && criteria.options.criteriaKey === 'algo') {
          componentRef.instance.currentFilters$ = this.currentFilters$.pipe(map((filters) => filters.filter((filter) => filter.criteriaKey === 'algo')));
        } else if (criteria.key === 'eventStatus' && criteria.options.criteriaKey === 'isActive') {
          componentRef.instance.currentFilters$ = this.currentFilters$.pipe(map((filters) => filters.filter((filter) => filter.criteriaKey === 'isActive')));
        } else if (criteria.key === 'timezone' && criteria.options.criteriaKey === 'teamPlanningTimezoneDetailsName') {
          componentRef.instance.currentFilters$ = this.currentFilters$.pipe(
            map((filters) => filters.filter((filter) => filter.criteriaKey === 'teamPlanningTimezoneDetailsName'))
          );
        } else {
          componentRef.instance.currentFilters$ = this.currentFilters$.pipe(
            map((filters) => filters.filter((filter) => filter.criteriaKey === criteria.options.criteriaKey))
          );
        }
      }

      componentRef.instance.dispatchFilterValue.subscribe((componentOutput: Filter | Filter[]) => {
        if (componentOutput) {
          if (componentOutput instanceof Array) {
            componentOutput.forEach((filter: Filter) => {
              this.checkFilterDuplicate(filter, criteria.options.multiSelect ?? false);
            });
          } else {
            this.checkFilterDuplicate(componentOutput, criteria.options.multiSelect ?? false);
          }
          this.onApplyFilters();
        }
      });
    }
  }

  onToggleFilterType(event: MatButtonToggleChange): void {
    this.currentFilterType = event.value;
  }

  openManageFavoriteFilters(): void {
    this.dialog
      .open(ManageFavoriteFiltersPopupComponent, {
        data: { masterView: this.masterView, filterCriteria: this.filterCriteria, userPreferences: this.userPreferences },
        disableClose: true,
        maxWidth: '1100px',
        minWidth: '600px'
      })
      .afterClosed()
      .subscribe((updatedFavoriteFilters) => {
        if (updatedFavoriteFilters) {
          const preferencesToUpdate: UserPreferences = {
            ...this.userPreferences,
            favoriteFilters: { ...this.userPreferences.favoriteFilters, [this.masterView]: { ...updatedFavoriteFilters } }
          };
          const userToUpdate: User = { ...cloneDeep(this.user), preferences: preferencesToUpdate };
          this.store.dispatch(PreferencesActions.saveUserPreferences({ user: userToUpdate, preferences: preferencesToUpdate }));
        }
      });
  }

  onClearAppliedFilters(): void {
    this.clearViewContainerRef(this.iotPlatformUiFilterEngine.viewContainerRef);
    this.resetFilterCriteriaButtonTitle();
  }
}
