import { Injectable } from '@angular/core';
import { CommonApiRequest, FavoriteView, Filter, Product, TagCategory } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Asset, AssetVariable, CommandType } from '@iot-platform/models/i4b';
import { select, Store } from '@ngrx/store';
import { GridsDbActions } from '../../../../../../../grid-engine/src/lib/components/state/actions';
import * as fromGrids from '../../../../../../../grid-engine/src/lib/components/state/reducers';

import { FavoriteViewsActions } from '../../../../../../../shared/src/lib/favorite-views/+state/actions';
import * as fromFavoriteViews from '../../../../../../../shared/src/lib/favorite-views/+state/reducers';
import { AssetsUiActions } from '../actions';
import * as fromAssets from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class AssetsFacade {
  grid$ = this.store.pipe(select(fromGrids.getDefaultAssetsGrid));
  grids$ = this.store.pipe(select(fromGrids.getAssetsGrids));

  variable$ = this.store.pipe(select(fromAssets.getVariable));
  site$ = this.store.pipe(select(fromAssets.getSite));
  siteLoaded$ = this.store.pipe(select(fromAssets.getSiteLoaded));
  tags$ = this.store.pipe(select(fromAssets.getTags));
  tagsLoaded$ = this.store.pipe(select(fromAssets.getTagsLoaded));

  favoriteViews$ = this.store.pipe(select(fromFavoriteViews.getFavoriteViewsForMasterViewAssets));
  currentFavoriteView$ = this.store.pipe(select(fromFavoriteViews.getSelectedFavoriteViewForMasterViewAssets));
  currentFilters$ = this.store.pipe(select(fromFavoriteViews.getFiltersForMasterViewAssets));
  loadingFavoriteViews$ = this.store.pipe(select(fromFavoriteViews.getLoading));
  //
  assetFavoriteViewsConfiguration$ = this.store.pipe(select(fromFavoriteViews.selectAssetFavoriteViewsConfiguration));
  //

  constructor(private readonly store: Store) {}

  loadMetadata() {
    this.store.dispatch(GridsDbActions.getDefaultGridByConcept({ concept: 'assets' }));
  }

  addAsset(asset: Asset) {
    this.store.dispatch(AssetsUiActions.addAsset({ assetToAdd: asset }));
  }

  addAssetByTemplateId(assetTemplateId: string, siteId: string) {
    this.store.dispatch(AssetsUiActions.addAssetByTemplateId({ assetTemplateId, siteId }));
  }

  addAssetVariable(assetVariable: AssetVariable) {
    this.store.dispatch(AssetsUiActions.addAssetVariable({ assetVariableToAdd: assetVariable }));
  }

  loadAssetById(assetId: string) {
    this.store.dispatch(AssetsUiActions.loadAssetById({ assetId: assetId }));
  }

  loadAssets(request: CommonApiRequest) {
    this.store.dispatch(
      GridsDbActions.loadGridData({
        request: { concept: 'assets', ...request }
      })
    );
  }

  loadSiteById(siteId: string) {
    this.store.dispatch(AssetsUiActions.loadSiteById({ siteId: siteId }));
  }

  loadTagsByAssetId(assetId: string) {
    this.store.dispatch(AssetsUiActions.loadTagsByAssetId({ assetId: assetId }));
  }

  updateTagsByAssetId(assetId: string, tags: TagCategory[]) {
    this.store.dispatch(AssetsUiActions.updateTagsByAssetId({ assetId, tags }));
  }

  loadVariablesByAssetId(assetId: string) {
    this.store.dispatch(AssetsUiActions.loadVariablesByAssetId({ assetId: assetId }));
  }

  updateAsset(asset: Asset) {
    this.store.dispatch(AssetsUiActions.updateAsset({ assetToUpdate: asset }));
  }

  deleteAsset(asset: Asset) {
    this.store.dispatch(AssetsUiActions.deleteAsset({ assetToDelete: asset }));
  }

  updateAssetVariable(assetVariable: AssetVariable) {
    this.store.dispatch(AssetsUiActions.updateAssetVariable({ assetVariableToUpdate: assetVariable }));
  }

  updateFollowedAssetVariable(assetVariable: AssetVariable) {
    this.store.dispatch(AssetsUiActions.updateFollowedAssetVariable({ assetVariableToUpdate: assetVariable }));
  }

  deleteAssetVariables(assetVariables: AssetVariable[]) {
    this.store.dispatch(AssetsUiActions.deleteAssetVariables({ assetVariablesToDelete: assetVariables }));
  }

  assignProductToAsset(product: Product, asset: Asset) {
    this.store.dispatch(AssetsUiActions.assignProductToAsset({ product, asset }));
  }

  removeProductFromAsset(product: Product, asset: Asset) {
    this.store.dispatch(AssetsUiActions.removeProductFromAsset({ product, asset }));
  }

  selectAsset(selectedAsset: Asset) {
    this.store.dispatch(AssetsUiActions.selectAsset({ selectedAsset }));
  }

  saveMVSettings(name: string, values: any) {
    this.store.dispatch(AssetsUiActions.saveMVSettings({ name, values }));
  }

  saveFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenAddFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.addFavoriteView({ favoriteView: data.favoriteView }));
    }
  }

  updateFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenUpdateFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.updateFavoriteView({ favoriteView: data.favoriteView }));
    }
  }

  deleteFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.deleteFavoriteView({ favoriteView }));
  }

  setCurrentFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFavoriteView({ masterView: 'assets', favoriteView: favoriteView }));
  }

  setCurrentFilters(filters: Filter[]) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'assets', filters: filters }));
  }

  sendCommand(asset: Asset, command: { command: CommandType }) {
    this.store.dispatch(AssetsUiActions.sendCommand({ asset, command }));
  }

  resetAssetVariablesLastValues(assetVariables: AssetVariable[]) {
    this.store.dispatch(AssetsUiActions.resetAssetVariablesLastValues({ assetVariables }));
  }

  bulkSendCommand(assetsIds: string[], command: { command: CommandType }): void {
    this.store.dispatch(AssetsUiActions.bulkSendCommand({ assetsIds, command }));
  }
}
