import { Injectable } from '@angular/core';
import { CommonApiRequest, FavoriteView, Filter } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { DeviceEvent } from '@iot-platform/models/i4b';
import { select, Store } from '@ngrx/store';
import { GridsDbActions } from '../../../../../../grid-engine/src/lib/components/state/actions';
import {
  getDefaultDeviceEventsGrid,
  getDeviceEventsGrids,
  selectItemInGrid
} from '../../../../../../grid-engine/src/lib/components/state/reducers';

import { FavoriteViewsActions } from '../../../../../../shared/src/lib/favorite-views/+state/actions';
import * as fromFavoriteViews from '../../../../../../shared/src/lib/favorite-views/+state/reducers';
import { DeviceEventsLogsUiActions, DeviceEventsUiActions } from './actions';
import * as fromDeviceEvents from './reducers';

@Injectable()
export class DeviceEventsApi {
  // formattedDeviceEvents$ = this.store.pipe(select(fromDeviceEvents.getFormattedDeviceEvents));

  // selectedDeviceEvent$ = this.store.pipe(select(fromDeviceEvents.getSelectedDeviceEvent));

  // deviceEvents$ = this.store.pipe(select(fromDeviceEvents.getAllDeviceEvents));

  grid$ = this.store.select(getDefaultDeviceEventsGrid);
  grids$ = this.store.select(getDeviceEventsGrids);
  selectedDeviceEvent$ = (gridId: string, itemId: string) => this.store.select(selectItemInGrid(gridId, itemId));

  // this.store.select(getSelectedItemIdInSelectedGrid);

  favoriteViews$ = this.store.pipe(select(fromFavoriteViews.getFavoriteViewsForMasterViewDeviceEvents));
  currentFavoriteView$ = this.store.pipe(select(fromFavoriteViews.getSelectedFavoriteViewForMasterViewDeviceEvents));
  currentFilters$ = this.store.pipe(select(fromFavoriteViews.getFiltersForMasterViewDeviceEvents));
  loadingFavoriteViews$ = this.store.pipe(select(fromFavoriteViews.getLoading));

  deviceEventsLoaded$ = this.store.pipe(select(fromDeviceEvents.getDeviceEventsLoaded));

  site$ = this.store.pipe(select(fromDeviceEvents.getSite));
  siteLoaded$ = this.store.pipe(select(fromDeviceEvents.getSiteLoaded));

  asset$ = this.store.pipe(select(fromDeviceEvents.getAsset));
  assetLoaded$ = this.store.pipe(select(fromDeviceEvents.getAssetLoaded));

  assetVariable$ = this.store.pipe(select(fromDeviceEvents.getAssetVariable));
  assetVariableLoaded$ = this.store.pipe(select(fromDeviceEvents.getAssetVariableLoaded));

  device$ = this.store.pipe(select(fromDeviceEvents.getDevice));
  deviceLoaded$ = this.store.pipe(select(fromDeviceEvents.getDeviceLoaded));

  deviceVariable$ = this.store.pipe(select(fromDeviceEvents.getDeviceVariable));
  deviceVariableLoaded$ = this.store.pipe(select(fromDeviceEvents.getDeviceVariableLoaded));

  logs$ = this.store.pipe(select(fromDeviceEvents.getAllLogs));
  logsLoaded$ = this.store.pipe(select(fromDeviceEvents.getLogsLoaded));
  log$ = this.store.pipe(select(fromDeviceEvents.getLog));

  tags$ = this.store.pipe(select(fromDeviceEvents.getTags));
  tagsLoaded$ = this.store.pipe(select(fromDeviceEvents.getTagsLoaded));

  status$ = this.store.pipe(select(fromDeviceEvents.getStatus));
  //
  deviceEventFavoriteViewsConfiguration$ = this.store.pipe(select(fromFavoriteViews.selectDeviceEventFavoriteViewsConfiguration));
  //

  constructor(private readonly store: Store) {}

  loadMetadata() {
    this.store.dispatch(GridsDbActions.getDefaultGridByConcept({ concept: 'device-events' }));
  }

  loadDeviceEvents(request: CommonApiRequest) {
    this.store.dispatch(
      GridsDbActions.loadGridData({
        request: { concept: 'device-events', ...request }
      })
    );
  }

  loadSiteById(siteId: string) {
    this.store.dispatch(DeviceEventsUiActions.loadSiteById({ siteId }));
  }

  loadDeviceById(deviceId: string) {
    this.store.dispatch(DeviceEventsUiActions.loadDeviceById({ deviceId }));
  }

  loadDeviceVariableById(deviceVariableId: string) {
    this.store.dispatch(DeviceEventsUiActions.loadDeviceVariableById({ deviceVariableId }));
  }

  loadAssetById(assetId: string) {
    this.store.dispatch(DeviceEventsUiActions.loadAssetById({ assetId }));
  }

  loadAssetVariableById(assetVariableId: string) {
    this.store.dispatch(DeviceEventsUiActions.loadAssetVariableById({ assetVariableId }));
  }

  loadLogsByDeviceEventId(deviceEventId: string) {
    this.store.dispatch(DeviceEventsLogsUiActions.loadLogsByDeviceEventId({ deviceEventId }));
  }

  loadTagsByDeviceEventId(deviceEventId: string) {
    this.store.dispatch(DeviceEventsUiActions.loadTagsByDeviceEventId({ deviceEventId }));
  }

  loadEventDetailPopupDataByDeviceEvent(deviceEvent: DeviceEvent) {
    this.loadSiteById(deviceEvent.context.site.id);
    this.loadAssetById(deviceEvent.context.asset.id);
    this.loadAssetVariableById(deviceEvent.context.assetVariable.id);
    this.loadDeviceById(deviceEvent.context.device.id);
    this.loadDeviceVariableById(deviceEvent.context.deviceVariable.id);
  }

  createLogByDeviceEventId(comment: { deviceEvent: DeviceEvent; value: string }) {
    this.store.dispatch(DeviceEventsLogsUiActions.createLogByDeviceEventId({ comment }));
  }

  bulkUpdateStatusByGridAndDeviceEvent(grid, deviceEvents: DeviceEvent[], status: string) {
    deviceEvents.forEach((dEvent) => {
      const updatedEvent = {
        ...dEvent,
        status: status
      };
      this.store.dispatch(GridsDbActions.updateItemInGridData({ gridId: grid.id, item: updatedEvent }));
    });
  }

  updateStatusByDeviceEventId(deviceEventIds: string[], status: string) {
    this.store.dispatch(DeviceEventsUiActions.bulkUpdateStatusByDeviceEventId({ deviceEventIds, status }));
  }

  bulkUpdateStatusByDeviceEventId(deviceEventIds: string[], status: string) {
    this.store.dispatch(DeviceEventsUiActions.newBulkUpdateStatusByDeviceEventId({ deviceEventIds, status }));
  }

  saveTableState(tableState: { selected: DeviceEvent; checked: DeviceEvent[] }) {
    this.store.dispatch(DeviceEventsUiActions.saveTableState({ tableState }));
  }

  saveFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenAddFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.addFavoriteView({ favoriteView: data.favoriteView }));
    }
  }

  updateFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenUpdateFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.updateFavoriteView({ favoriteView: data.favoriteView }));
    }
  }

  deleteFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.deleteFavoriteView({ favoriteView }));
  }

  setCurrentFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFavoriteView({ masterView: 'device-events', favoriteView }));
  }

  setCurrentFilters(filters: Filter[]) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'device-events', filters }));
  }
}
