import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  CommonApiRequest,
  CommonApiResponse,
  CommonGenericModel,
  CommonIndexedPagination,
  CommonPagination
} from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';

import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { Observable, of, throwError, timer } from 'rxjs';
import {
  catchError,
  concatMap,
  finalize,
  map,
  mergeMap,
  retryWhen,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { FavoriteViewsActions } from '../../../../../../shared/src/lib/favorite-views/+state/actions';
import { GridsService } from '../../grid/grids.service';

import { GridsDbActions } from '../actions';
import * as fromGrids from '../reducers';

export const genericRetryStrategy =
  ({
    maxRetryAttempts = 3,
    scalingDuration = 1000,
    excludedStatusCodes = [401]
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
  } = {}) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find((e) => e === error.status)) {
          return throwError(error);
        }
        console.warn(`[${error['name']}][${error['message']}] - Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`);
        return timer(retryAttempt * scalingDuration);
      }),
      finalize(() => console.log('Max attempts reached or no error thrown'))
    );
  };

@Injectable()
export class GridsEffects {
  loadGrids$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.loadGrids),
      switchMap((action) =>
        this.gridsService.loadAllGrids().pipe(
          map((response: CommonApiResponse<I4BGrid<I4BGridOptions, I4BGridData>, CommonPagination>) => {
            return GridsDbActions.loadGridsSuccess({ response });
          }),
          catchError((error) => of(GridsDbActions.loadGridsFailure({ error: error })))
        )
      )
    )
  );

  loadGridDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.loadGridDetails),
      switchMap((action) => {
        return this.gridsService.loadGridDetails(action.concept, action.gridId).pipe(
          map((loadedGrid: I4BGrid<I4BGridOptions, I4BGridData>) => {
            return GridsDbActions.loadGridDetailsSuccess({ grid: loadedGrid });
          }),
          catchError((error) => of(GridsDbActions.loadGridDetailsFailure({ error: error })))
        );
      })
    )
  );

  selectGridAndLoadData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GridsDbActions.selectGridAndLoadData),
      withLatestFrom(this.store.select(fromGrids.getAllGrids)),
      switchMap(([action, grids]) => {
        // TODO : On doit pouvoir faire mieux
        const founds = grids.filter((g) => g.id === action.gridId);
        if (action.gridId && founds.length > 0) {
          const pagination: CommonIndexedPagination = founds[0].data?.response.pagination as CommonIndexedPagination;
          const request: CommonApiRequest = {
            limit: pagination ? pagination.limit : founds[0].gridOptions.pageSize,
            page: 0,
            filters: action.filters ? action.filters : founds[0]?.gridOptions?.filters,
            concept: founds[0].masterview.toLowerCase(),
            variables: founds[0].gridOptions.variableNames,
            tags: founds[0].gridOptions.tagIds
          };
          return [
            GridsDbActions.selectGrid({
              gridId: grids.filter((g) => g.id === action.gridId)[0].id,
              masterview: grids.filter((g) => g.id === action.gridId)[0].masterview
            }),
            GridsDbActions.loadGridData({ request })
          ];
        } else {
          const userDefaultGrid = grids.filter((g) => g.masterview === action.masterview && g.isUserDefault)[0];
          if (!!userDefaultGrid) {
            const pagination: CommonIndexedPagination = userDefaultGrid.data?.response.pagination as CommonIndexedPagination;
            const request: CommonApiRequest = {
              limit: pagination ? pagination.limit : userDefaultGrid.gridOptions.pageSize,
              page: 0,
              filters: action.filters,
              concept: userDefaultGrid.masterview.toLowerCase(),
              variables: userDefaultGrid.gridOptions.variableNames,
              tags: userDefaultGrid.gridOptions.tagIds
            };
            return [
              GridsDbActions.selectGrid({ gridId: userDefaultGrid.id, masterview: userDefaultGrid.masterview }),
              GridsDbActions.loadGridData({ request })
            ];
          } else {
            const appDefaultGrid = grids.filter((g) => g.masterview === action.masterview && g.isAppDefault)[0];
            if (appDefaultGrid) {
              const pagination: CommonIndexedPagination = appDefaultGrid.data?.response.pagination as CommonIndexedPagination;
              const request: CommonApiRequest = {
                limit: pagination ? pagination.limit : appDefaultGrid.gridOptions.pageSize,
                page: 0,
                filters: action.filters,
                concept: appDefaultGrid.masterview.toLowerCase(),
                variables: appDefaultGrid.gridOptions.variableNames,
                tags: appDefaultGrid.gridOptions.tagIds
              };
              request.endPoint = action.endPoint ?? appDefaultGrid.gridOptions.endPoint ?? undefined;
              return [
                GridsDbActions.selectGrid({ gridId: appDefaultGrid.id, masterview: appDefaultGrid.masterview }),
                GridsDbActions.loadGridData({ request })
              ];
            }
            return [];
          }
        }
      })
    );
  });

  reactOnFavoriteViewCrud$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.addFavoriteViewSuccess, FavoriteViewsActions.updateFavoriteViewSuccess),
      switchMap((action) => of(GridsDbActions.selectGrid({ gridId: action.favoriteView.gridId, masterview: action.favoriteView.masterView })))
    )
  );

  loadGridData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.loadGridData),
      switchMap((action) => {
        return this.gridsService.loadGridData(action.request).pipe(
          map((response: CommonApiResponse<CommonGenericModel, CommonPagination>) => {
            return GridsDbActions.loadGridDataSuccess({ gridData: { response }, masterView: action.request.concept });
          }),
          retryWhen(
            genericRetryStrategy({
              maxRetryAttempts: 3,
              scalingDuration: 1500,
              excludedStatusCodes: [401]
            })
          ),
          catchError((error) => {
            return of(GridsDbActions.loadGridDataFailure({ error: error }));
          })
        );
      })
    )
  );

  changeGridPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.changeGridPage),
      switchMap((action) => {
        return this.gridsService.loadGridData(action.request).pipe(
          map((response: CommonApiResponse<CommonGenericModel, CommonPagination>) => {
            return GridsDbActions.loadGridDataSuccess({ gridData: { response }, masterView: action.request.concept });
          }),
          retryWhen(
            genericRetryStrategy({
              maxRetryAttempts: 3,
              scalingDuration: 1500,
              excludedStatusCodes: [401]
            })
          ),
          catchError((error) => {
            return of(GridsDbActions.loadGridDataFailure({ error: error }));
          })
        );
      })
    )
  );

  updateGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.updateGrid),
      switchMap((action) =>
        this.gridsService.updateGrid(action.toUpdate as I4BGrid<I4BGridOptions, I4BGridData>).pipe(
          switchMap((grid: I4BGrid<I4BGridOptions, I4BGridData>) => {
            return [
              GridsDbActions.updateGridSuccess({ grid: grid }),
              GridsDbActions.loadGridData({
                request: {
                  limit: grid.gridOptions.pageSize,
                  page: 0,
                  tags: grid.gridOptions.tagIds,
                  variables: grid.gridOptions.variableNames,
                  concept: grid.masterview,
                  filters: grid.gridOptions.filters
                }
              })
            ];
          }),
          catchError((error) => of(GridsDbActions.updateGridFailure({ error: error })))
        )
      )
    )
  );

  updateSilentGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.updateSilentGrid),
      switchMap((action) =>
        this.gridsService.updateSilentGrid(action.toUpdate as I4BGrid<I4BGridOptions, I4BGridData>).pipe(
          map((response) => {
            return GridsDbActions.updateSilentGridSuccess({ grid: response });
          }),
          catchError((error) => of(GridsDbActions.updateSilentGridFailure({ error: error })))
        )
      )
    )
  );

  /*getDefaultGridByConcept$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.getDefaultGridByConcept),
      switchMap((action) =>
        this.gridsService.getDefaultGridByConcept(action.concept).pipe(
          map((response) => {
            return GridsDbActions.getDefaultGridByConceptSuccess({ defaultGrid: response });
          }),
          catchError((error) => of(GridsDbActions.getDefaultGridByConceptFailure({ error: error })))
        )
      )
    )
  );*/

  addGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.addGrid),
      switchMap((action) =>
        this.gridsService.saveGrid(action.toAdd as I4BGrid<I4BGridOptions, I4BGridData>).pipe(
          map((response) => {
            return GridsDbActions.addGridSuccess({ grid: response });
          }),
          catchError((error) => of(GridsDbActions.addGridFailure({ error: error })))
        )
      )
    )
  );

  addGridSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GridsDbActions.addGridSuccess, GridsDbActions.updateGridSuccess),
      concatMap(({ grid }) => {
        return [
          GridsDbActions.loadGrids({}),
          GridsDbActions.selectGridAndLoadData({ gridId: grid.id as string, masterview: (grid as I4BGrid<I4BGridOptions, I4BGridData>).masterview })
        ];
      })
    );
  });

  removeGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GridsDbActions.removeGrid),
      switchMap((action) =>
        this.gridsService.deleteGrid(action.toRemove as I4BGrid<I4BGridOptions, I4BGridData>).pipe(
          map((response) => {
            return GridsDbActions.removeGridSuccess({ removed: action.toRemove });
          }),
          catchError((error) => of(GridsDbActions.removeGridFailure({ error: error })))
        )
      )
    )
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GridsDbActions.addGridSuccess, GridsDbActions.updateGridSuccess, GridsDbActions.updateGridDataSuccess, GridsDbActions.removeGridSuccess),
        tap((action) => {
          return this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GridsDbActions.getDefaultGridByConceptFailure,
          GridsDbActions.removeGridFailure,
          GridsDbActions.updateGridFailure,
          GridsDbActions.updateGridDataFailure,
          GridsDbActions.loadGridDataFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GridsDbActions.loadGrids,
          GridsDbActions.loadGridData,
          GridsDbActions.loadGridDetails,
          GridsDbActions.getDefaultGridByConcept,
          GridsDbActions.removeGrid,
          GridsDbActions.updateGrid,
          GridsDbActions.addGrid,
          GridsDbActions.updateGridData,
          GridsDbActions.changeGridPage
        ),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoaderAfterResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          GridsDbActions.loadGridsSuccess,
          GridsDbActions.loadGridDetailsSuccess,
          GridsDbActions.loadGridDataSuccess,
          GridsDbActions.getDefaultGridByConceptSuccess,
          GridsDbActions.updateGridDataSuccess,
          GridsDbActions.updateGridSuccess,
          GridsDbActions.addGridSuccess,
          GridsDbActions.removeGridSuccess,
          GridsDbActions.loadGridsFailure,
          GridsDbActions.loadGridDetailsFailure,
          GridsDbActions.loadGridDataFailure,
          GridsDbActions.getDefaultGridByConceptFailure,
          GridsDbActions.updateGridDataFailure,
          GridsDbActions.addGridFailure,
          GridsDbActions.updateGridFailure,
          GridsDbActions.removeGridFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly gridsService: GridsService,
    private readonly router: Router,
    private readonly store: Store
  ) {}
}
