import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, tap } from 'rxjs/operators';
import { BaseComponent } from '../base-component/base-component';

@Component({
  selector: 'iot-platform-ui-async-autocomplete',
  templateUrl: './async-autocomplete.component.html',
  styleUrls: ['./async-autocomplete.component.scss']
})
export class AsyncAutocompleteComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() showSpinner = false;
  @Input() displaySearchIcon = true;
  @Input() data: any[] = [];
  @Input() debounceTime = 300;
  @Input() minLength = 3;
  @Input() displayWrapper!: (item: any) => string;
  @Input() displayKey = 'id';
  @Input() placeholder = '';
  @Input() autocomplete = true;
  @Input() clearOnSelect = false;
  @Input() initialItem!: any;
  @Input() standaloneMode = false;
  @Input() filterKey = '';
  @Input() hintMessage = '';
  @Input() disabled = false;
  @Input() required = false;
  @Input() errorMessage!: string;

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() reset: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  searchForm!: UntypedFormGroup;
  filteredData$: Observable<any[]> = of([]);

  get control(): AbstractControl {
    return this.searchForm?.get('searchKey') as UntypedFormControl;
  }

  @ViewChild('inputAutoComplete') inputAutoComplete: any;

  ngOnInit(): void {
    if (!this.displayWrapper) {
      this.displayWrapper = this.defaultDisplayWrapper.bind(this);
    }
    this.searchForm = new UntypedFormGroup({
      searchKey: new UntypedFormControl({ value: this.initialItem, disabled: this.disabled }, this.required ? [Validators.required] : [])
    });

    if (this.standaloneMode) {
      this.initFilteredData();

      this.subscriptions.push(
        this.control.valueChanges.subscribe((term: string) => {
          this.search.emit(term);
        })
      );
    } else {
      this.subscriptions.push(
        this.control.valueChanges
          .pipe(
            debounceTime(this.debounceTime),
            filter((term) => typeof term === 'string'),
            tap((term: string) => {
              if (this.control.touched && (term === null || term === undefined || !term.length || term.length < this.minLength)) {
                this.reset.emit();
              }
            }),
            filter((term: string) => !!term && term.length >= this.minLength),
            distinctUntilChanged()
          )
          .subscribe((term: string) => {
            this.search.emit(term);
          })
      );
    }
  }

  initFilteredData(): void {
    const initialSearchString = !!this.initialItem ? (this.initialItem[this.filterKey] as string) : '';
    this.filteredData$ = this.control.valueChanges.pipe(
      startWith(initialSearchString),
      map((value) => {
        return this.filterValue(value);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('initialItem')) {
      this.control?.setValue(changes.initialItem?.currentValue);
      this.selectionChanged.emit(changes.initialItem?.currentValue ?? undefined);
    }

    if (changes?.hasOwnProperty('disabled')) {
      changes.disabled.currentValue ? this.control?.disable() : this.control?.enable();
    }
  }

  filterValue(value: any): any[] {
    let filterValue = '';
    if (!!value) {
      if (!!this.filterKey) {
        filterValue = typeof value === 'string' ? value?.toLowerCase() : value[this.filterKey].toLowerCase();
      } else {
        filterValue = typeof value === 'string' ? value?.toLowerCase() : value.toLowerCase();
      }
    }
    return this.data?.filter((option) => {
      if (!!this.filterKey) {
        return option[this.filterKey]?.toLowerCase().includes(filterValue);
      } else {
        return option?.toLowerCase().includes(filterValue);
      }
    });
  }

  defaultDisplayWrapper(item: any): string {
    return item && this.displayKey ? item[this.displayKey] : item;
  }

  onOptionSelected(event: any): void {
    const {
      option: { value }
    } = event;

    this.selectionChanged.emit(value);

    if (this.clearOnSelect) {
      this.control.reset();
    }
  }

  resetControl(event: any): void {
    event.stopPropagation();
    this.showSpinner = false;
    this.control.setValue('');
    this.reset.emit();
    this.inputAutoComplete?.nativeElement.focus();
  }
}
