<ng-container *ngIf="displaySearchBar">
  <mat-sidenav-container (backdropClick)="closeNav()">
    <mat-sidenav-content fxLayout="column" style="overflow-x: hidden" class="variable-chart-side-nav-container">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="variable-chart-export-bar">
        <div>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="variable-chart-days-filter" fxFlex>
            <button class="variable-chart-selector-button" mat-raised-button [class.selected]="chartPeriod === 1" (click)="onSelectPeriod(1)">
              1 {{ 'VARIABLE_CHART.DAY' | translate }}
            </button>
            <button class="variable-chart-selector-button" mat-raised-button [class.selected]="chartPeriod === 7" (click)="onSelectPeriod(7)">
              7 {{ 'VARIABLE_CHART.DAY' | translate }}s
            </button>
            <button class="variable-chart-selector-button" mat-raised-button [class.selected]="chartPeriod === 30" (click)="onSelectPeriod(30)">
              30 {{ 'VARIABLE_CHART.DAY' | translate }}s
            </button>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="variable-chart-dates-filter" fxFlex>
            <div
              fxLayout="row"
              class="variable-chart-datepicker"
              style="width: 140px; background-color: #ececec; margin-left: 8px; margin-right: 8px; padding-left: 8px"
            >
              <input matInput #startDate [matDatepicker]="startPicker" placeholder="{{ 'VARIABLE_CHART.FROM' | translate }}" />
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </div>

            <div fxLayout="row" class="variable-chart-datepicker" style="width: 140px; background-color: #ececec; padding-left: 8px">
              <input matInput #endDate [matDatepicker]="endPicker" placeholder="{{ 'VARIABLE_CHART.TO' | translate }}" />
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
            </div>
          </div>

          <button
            fxFlex
            class="variable-chart-selector-button"
            mat-raised-button
            [class.selected]="chartPeriod === 0"
            [disabled]="!startDate.value"
            (click)="onSelectDates(startDate.value, endDate.value)"
          >
            {{ 'VARIABLE_CHART.APPLY' | translate | uppercase }}
          </button>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-slide-toggle [checked]="(scale$ | async) === null" (change)="onAutoScaleChange($event)">{{ 'VARIABLE_CHART.AUTO_SCALE' | translate }}</mat-slide-toggle>
          <button mat-icon-button class="variable-chart-add-variables-button" [matTooltip]="'VARIABLE_CHART.ADD_VARIABLES' | translate" matTooltipClass="regular-tooltip" (click)="openVariables()">
            <mat-icon>addchart</mat-icon>
          </button>
        </div>
      </div>

      <mat-progress-bar class="variable-chart-loader" mode="indeterminate" color="accent" *ngIf="displayLoader"></mat-progress-bar>

      <div class="variables-closed" [class.fullscreen]="fullscreenOn">
        <highcharts-chart
          fxLayout="column"
          #highChart
          fxFlex
          [constructorType]="chartConstructorType"
          [Highcharts]="Highcharts"
          [options]="mainChartOptions$ | async"
          [oneToOne]="true"
          style="width: 100%; height: 100%; display: block"
        ></highcharts-chart>
      </div>
    </mat-sidenav-content>

    <mat-sidenav #sidenavVariables [opened]="variablesOpened" mode="over" position="end" (keydown.escape)="closeNav()">
      <div *ngIf="displayAllVariablesLoader" style="width: 450px; height: 100%" fxLayoutAlign="center center">
        <mat-spinner [diameter]="30" color="accent"></mat-spinner>
      </div>

      <p fxLayout="column" *ngIf="allVariables && !displayAllVariablesLoader" style="width: 450px">
        <mat-form-field class="filter-input" color="accent">
          <mat-icon matPrefix class="search-icon">search</mat-icon>
          <mat-label>{{ 'VARIABLE_CHART.FILTER_VARIABLES' | translate }}</mat-label>
          <input matInput type="text" (keyup)="filterVariables($event, allVariables)" />
        </mat-form-field>
        <mat-list>
          <mat-list-item *ngFor="let variable of filteredVariables">
            <iot4bos-ui-variable-item
              fxFlex
              [variable]="variable"
              [selected]="getSelectedVariables(variable)"
              (toggle)="toggleVariable(variable, $event)"
              [locked]="getLockedVariables(variable)"
            >
            </iot4bos-ui-variable-item>
          </mat-list-item>
        </mat-list>
      </p>
    </mat-sidenav>
  </mat-sidenav-container>
</ng-container>

<ng-container *ngIf="!displaySearchBar">
  <highcharts-chart
    #highChart
    fxFlex
    [constructorType]="chartConstructorType"
    [Highcharts]="Highcharts"
    [options]="previewChartOptions$ | async"
    [oneToOne]="true"
    style="width: 100%; height: 100%; display: block"
  ></highcharts-chart>
</ng-container>
