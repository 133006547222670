import { Injectable } from '@angular/core';
import { Contact } from '@iot-platform/models/common';
import { SpreadsheetExport } from '@iot-platform/models/i4b';
import { select, Store } from '@ngrx/store';

import { ContactsUiActions, SpreadsheetExportsUiActions } from '../actions';
import * as fromContacts from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class ContactsFacade {
  constructor(private readonly store: Store) {}

  public contactsLoading$ = this.store.pipe(select(fromContacts.selectContactsLoading));
  public contacts$ = this.store.pipe(select(fromContacts.selectAllContacts));
  public totalContacts$ = this.store.pipe(select(fromContacts.selectTotalContacts));

  public loadContacts(siteId: string): void {
    this.store.dispatch(ContactsUiActions.loadContacts({ siteId }));
  }

  public addContact(contact: Contact): void {
    this.store.dispatch(ContactsUiActions.addContact({ contact }));
  }

  public updateContact(contact: Contact): void {
    this.store.dispatch(ContactsUiActions.updateContact({ contact }));
  }

  public deleteContact(contact: Contact): void {
    this.store.dispatch(ContactsUiActions.deleteContact({ contact }));
  }

  public configureSpreadsheetExport(spreadsheetExport: SpreadsheetExport): void {
    this.store.dispatch(SpreadsheetExportsUiActions.configureSpreadsheetExport({ spreadsheetExport }));
  }
}
