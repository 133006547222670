import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthorizationConcept, AuthorizationType } from '../../../../auth/src/lib/authorization.types';

import { AuthorizationService } from '../../../../auth/src/lib/services/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationGuard implements CanActivate {
  constructor(private authorizationService: AuthorizationService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    switch (state.url) {
      case '/admin/business-profiles':
        if (
          this.authorizationService.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.CREATE) ||
          this.authorizationService.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.UPDATE)
        ) {
          return true;
        }
        break;

      case '/admin/users':
        if (
          this.authorizationService.applyAuthorization(AuthorizationConcept.USER, AuthorizationType.CREATE) ||
          this.authorizationService.applyAuthorization(AuthorizationConcept.USER, AuthorizationType.UPDATE)
        ) {
          return true;
        }
        break;

      case '/admin/organizations':
        if (
          this.authorizationService.applyAuthorization(AuthorizationConcept.ORGANIZATION_TREE, AuthorizationType.CREATE) ||
          this.authorizationService.applyAuthorization(AuthorizationConcept.ORGANIZATION_TREE, AuthorizationType.UPDATE) ||
          this.authorizationService.applyAuthorization(AuthorizationConcept.TAG, AuthorizationType.CREATE) ||
          this.authorizationService.applyAuthorization(AuthorizationConcept.TAG, AuthorizationType.UPDATE)
        ) {
          return true;
        }
        break;

      case '/admin/product-catalogs':
        if (
          this.authorizationService.applyAuthorization(AuthorizationConcept.PRODUCT_CATALOG, AuthorizationType.READ) ||
          this.authorizationService.applyAuthorization(AuthorizationConcept.PRODUCT_CATALOG, AuthorizationType.UPDATE)
        ) {
          return true;
        }
        break;

      default:
        break;
    }

    this.router.navigate(['/home']);
    return false;
  }
}
