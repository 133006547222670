<section class='site-configurations'>
  <iot4bos-ui-panel
    [title]="'SITES.CONFIGURATIONS.PANEL_TITLE' | translate"
    [hasButton]='false'
    [withCustomAction]='true'
    [buttonTooltip]="'SITES.CONFIGURATIONS.ADD' | translate"
  >
    <div *ngIf="thresholdCards && thresholdCards.length > 4" panelCustomAction class="panel-button app-round-button" [matTooltip]="(horizontalLayout ? 'PANELS.TOOLTIPS.HORIZONTAL_LAYOUT': 'PANELS.TOOLTIPS.VERTICAL_LAYOUT') | translate" matTooltipClass="regular-tooltip">
      <button panelCustomAction mat-icon-button fxHide.lt-md  (click)="horizontalLayout = !horizontalLayout">
        <mat-icon>{{ horizontalLayout ? 'apps' : 'swap_horiz' }}</mat-icon>
      </button>
    </div>

    <oyan-ui-cards-container panelGrid [horizontalLayout]="horizontalLayout">
      <oyan-ui-threshold-card class='card-xs' fxFlex.lt-md="100%"
          [withMenu]='false'
          [canUpdate]='canUpdate'
          *ngFor='let card of thresholdCards' [element]='card'
          (dispatchEvent)="onDispatchEvent($event)"
      ></oyan-ui-threshold-card>
    </oyan-ui-cards-container>

  </iot4bos-ui-panel>
</section>
