import { Injectable } from '@angular/core';
import { BaseSelector } from '@iot-platform/models/common';
import { createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { ActiveElement } from '../../models/active-element.model';
import * as fromMain from '../reducers';
import * as fromNavigations from '../reducers/navigations.reducer';

@Injectable({
  providedIn: 'root'
})
export class NavigationsSelectors extends BaseSelector<fromMain.State, fromNavigations.State> {
  selectActiveElement: MemoizedSelector<fromNavigations.State, ActiveElement> = createSelector(
    this.selectState,
    (state: fromNavigations.State): ActiveElement => state.activeElement
  );

  constructor() {
    super(fromMain.featureKey, fromNavigations.featureKey);
  }
}
