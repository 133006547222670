import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CommonIndexedPagination, Contact, EmailTemplate } from '@iot-platform/models/common';
import { SpreadsheetExport } from '@iot-platform/models/i4b';
import {
  DynamicListFieldService
} from 'libs/iot-platform-ui/src/lib/ui/components/filter-engine/dynamic-list-field/dynamic-list-filed.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { PoEventsService } from './po-events.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  constructor(
    @Inject('environment') private readonly env,
    private readonly http: HttpClient,
    private readonly dynamicListFieldService: DynamicListFieldService,
    private readonly poEventsService: PoEventsService
  ) {}

  public loadContacts(siteId: string): Observable<Contact[]> {
    return this.http
      .get<{ content: Contact[]; page: CommonIndexedPagination }>(
        `${this.env.api.url}${this.env.api.endpoints.sites}/${siteId}${this.env.api.endpoints.contacts}`
      )
      .pipe(map((response) => response.content));
  }

  public addContact(contact: Contact): Observable<Contact> {
    return this.http.post<Contact>(`${this.env.api.url}${this.env.api.endpoints.contacts}`, contact);
  }

  public updateContact(contact: Contact): Observable<Contact> {
    return this.http.put<Contact>(`${this.env.api.url}${this.env.api.endpoints.contacts}/${contact.id}`, contact);
  }

  public deleteContact(contact: Contact): Observable<Contact> {
    return this.http.delete<Contact>(`${this.env.api.url}${this.env.api.endpoints.contacts}/${contact.id}`).pipe(map(() => contact));
  }

  public getClassList() {
    return this.dynamicListFieldService
      .getDynamicList('/api/v1/tag-categories?concept=EVENT&joinable=true', {
        property: 'name',
        type: 'alphabetically'
      })
      .pipe(map((arr) => arr[0].labels.map((item) => item.name)));
  }

  public getSeverities() {
    return this.poEventsService.getSeverities();
  }

  public getSpreadsheetExport(contactId: string): Observable<SpreadsheetExport> {
    return this.http
      .get<{ content: SpreadsheetExport }>(`${this.env.api.url}${this.env.api.endpoints.contacts}/${contactId}/spreadsheet-export`)
      .pipe(map((response) => response.content));
  }

  public configureSpreadsheetExport(spreadsheetExport: SpreadsheetExport): Observable<SpreadsheetExport> {
    if (spreadsheetExport.id) {
      return this.updateSpreadsheetExport(spreadsheetExport);
    }
    return this.createSpreadsheetExport(spreadsheetExport);
  }

  public createSpreadsheetExport(spreadsheetExport: SpreadsheetExport): Observable<SpreadsheetExport> {
    return this.http.post<SpreadsheetExport>(`${this.env.api.url}${this.env.api.endpoints.contacts}/spreadsheet-export`, spreadsheetExport);
  }

  public updateSpreadsheetExport(spreadsheetExport: SpreadsheetExport): Observable<SpreadsheetExport> {
    return this.http.put<SpreadsheetExport>(
      `${this.env.api.url}${this.env.api.endpoints.contacts}/${spreadsheetExport.contactId}/spreadsheet-export`,
      spreadsheetExport
    );
  }

  public getSpreadsheetExportPreview(spreadsheetExport: SpreadsheetExport) {
    return this.http.post<{ presignedUrl: string }>(`${this.env.api.url}${this.env.api.endpoints.contacts}/spreadsheet-export/preview`, spreadsheetExport);
  }

  public getEmailTemplatesByEntityId(entityId: string): Observable<EmailTemplate[]> {
    let queryParams = new HttpParams().set('type', 'email').set('workflow', 'event_notification').set('includeParentEntities', true).set('entityId', entityId);
    return this.http
      .get<EmailTemplate[]>(`${this.env.api.url}${this.env.api.endpoints.notificationTemplates}`, { params: queryParams })
      .pipe(map((data: any) => data.content));
  }

  public getEmailTemplatesById(emailTemplateId: string): Observable<EmailTemplate | null> {
    return emailTemplateId
      ? this.http.get<EmailTemplate>(`${this.env.api.url}${this.env.api.endpoints.notificationTemplates}/${emailTemplateId}`, {})
      : of(null);
  }
}
