import { GetUtils } from '@iot-platform/iot-platform-utils';
import { BaseState, CommonIndexedPagination, Filter, INITIAL_BASE_STATE } from '@iot-platform/models/common';
import { Site } from '@iot-platform/models/oyan';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ContactsDbActions, SiteActions, SiteGatewaysActions, SiteProductsActions } from '../actions';

export const featureKey = 'sitesApiFeatureKey';

export type State = BaseState<Site, CommonIndexedPagination, Filter>;

export const adapter: EntityAdapter<Site> = createEntityAdapter<Site>({
  selectId: (entity: Site) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, hasMore: false, limit: 10, maxPage: 0, total: 0 }
});

export const reducer = createReducer(
  initialState,

  on(
    SiteActions.setFilters,
    (state: State, { filters }): State => ({
      ...state,
      filters: [...filters]
    })
  ),

  on(
    SiteActions.loadSites,
    (state: State, { request }): State => ({
      ...state,
      entity: null,
      filters: GetUtils.get(request, 'filters', [])
    })
  ),

  on(
    SiteActions.loadSitesSuccess,
    (state: State, { response }): State => ({
      ...adapter.setAll(GetUtils.get(response, 'data', []), { ...state, pagination: response.pagination })
    })
  ),

  on(
    SiteActions.loadSiteById,
    (state: State, { id }): State => ({
      ...state,
      selectedId: id,
      entity: null
    })
  ),
  on(
    SiteActions.loadSiteByIdSuccess,
    (state: State, { response }): State => ({
      ...state,
      selectedId: response.id,
      entity: response
    })
  ),
  on(
    SiteActions.loadSiteByIdFailure,
    (state: State): State => ({
      ...state,
      selectedId: null,
      entity: null
    })
  ),

  on(
    SiteActions.setActiveSite,
    (state: State, { site }): State => ({
      ...state,
      selectedId: site.id,
      entity: {
        ...site,
        products: GetUtils.get(site, 'products', []),
        contacts: GetUtils.get(site, 'contacts', [])
      }
    })
  ),

  on(
    SiteActions.addSiteSuccess,
    (state: State, { response }): State => ({
      ...adapter.addOne(response, { ...state, entity: response })
    })
  ),

  on(
    SiteActions.updateSiteSuccess,
    (state: State, { response }): State => ({
      ...adapter.updateOne({ id: response.id, changes: response }, { ...state, entity: response })
    })
  ),

  on(
    SiteActions.deleteSiteSuccess,
    (state: State, { response }): State => ({
      ...state,
      ...adapter.removeOne(response.id, state)
    })
  ),

  on(SiteProductsActions.addProductToSiteSuccess, (state: State, { product, site }): State => {
    const siteToUpdate: Site = {
      ...site,
      products: [...site.products, product]
    };
    return adapter.updateOne({ id: siteToUpdate.id, changes: siteToUpdate }, { ...state, entity: siteToUpdate });
  }),

  on(SiteProductsActions.removeProductFromSiteSuccess, (state: State, { product, site }): State => {
    const siteToUpdate: Site = {
      ...site,
      products: site.products.filter((p) => p.id !== product.id)
    };
    return adapter.updateOne({ id: siteToUpdate.id, changes: siteToUpdate }, { ...state, entity: siteToUpdate });
  }),

  on(SiteGatewaysActions.addGatewayToSiteSuccess, (state: State, { gateway, site }): State => {
    const siteToUpdate: Site = {
      ...site,
      gateways: [...site.gateways, gateway.id]
    };
    return adapter.updateOne({ id: siteToUpdate.id, changes: siteToUpdate }, { ...state, entity: siteToUpdate });
  }),

  on(SiteGatewaysActions.removeGatewayFromSiteSuccess, (state: State, { gateway, site }): State => {
    const siteToUpdate: Site = {
      ...site,
      gateways: site.gateways.filter((gid) => gid !== gateway.id)
    };
    return adapter.updateOne({ id: siteToUpdate.id, changes: siteToUpdate }, { ...state, entity: siteToUpdate });
  }),

  on(ContactsDbActions.addContactSuccess, (state: State, { addedContact: contact }): State => {
    const { entity } = state;
    const siteToUpdate: Site = {
      ...entity,
      contacts: [...entity.contacts, contact]
    };
    return adapter.updateOne({ id: siteToUpdate.id, changes: siteToUpdate }, { ...state, entity: siteToUpdate });
  }),

  on(ContactsDbActions.updateContactSuccess, (state: State, { updatedContact }): State => {
    const { entity } = state;
    const siteToUpdate: Site = {
      ...entity,
      contacts: [...entity.contacts.filter((c) => c.id !== updatedContact.id), updatedContact]
    };
    return adapter.updateOne({ id: siteToUpdate.id, changes: siteToUpdate }, { ...state, entity: siteToUpdate });
  }),

  on(ContactsDbActions.deleteContactSuccess, (state: State, { deletedContact }): State => {
    const { entity } = state;
    const siteToUpdate: Site = {
      ...entity,
      contacts: entity.contacts.filter((c) => c.id !== deletedContact.id)
    };
    return adapter.updateOne({ id: siteToUpdate.id, changes: siteToUpdate }, { ...state, entity: siteToUpdate });
  }),

  on(
    SiteActions.loadSites,
    SiteActions.loadSiteById,
    SiteActions.addSite,
    SiteActions.updateSite,
    SiteActions.deleteSite,
    (state: State): State => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),
  on(
    SiteActions.loadSitesSuccess,
    SiteActions.loadSiteByIdSuccess,
    SiteActions.addSiteSuccess,
    SiteActions.updateSiteSuccess,
    SiteActions.deleteSiteSuccess,
    (state: State): State => ({
      ...state,
      loading: false,
      loaded: true
    })
  ),
  on(
    SiteActions.loadSitesFailure,
    SiteActions.loadSiteByIdFailure,
    SiteActions.addSiteFailure,
    SiteActions.updateSiteFailure,
    SiteActions.deleteSiteFailure,
    (state: State, { error }): State => ({
      ...state,
      loading: false,
      loaded: false,
      error
    })
  )
);
