import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FavoriteView } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BusinessProfilesService } from '../../services/business-profiles.service';

@Component({
  selector: 'iot4bos-backoffice-ui-favorite-views-detail',
  templateUrl: './favorite-views-detail.component.html',
  styleUrls: ['./favorite-views-detail.component.scss']
})
export class FavoriteViewsDetailComponent implements OnInit, OnDestroy {
  gridName = '';
  destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { favoriteView: FavoriteView },
    private businessProfilesService: BusinessProfilesService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    if (!!this.data.favoriteView.masterView && !!this.data.favoriteView.gridId) {
      this.businessProfilesService
        .getGridNameById(this.data.favoriteView.masterView, this.data.favoriteView.gridId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((gridName) => (this.gridName = gridName));
    } else {
      this.gridName = this.translateService.instant('FAVORITE_VIEW.DETAIL.DEFAULT_GRID');
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
