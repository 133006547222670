import { Component, Input } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-breadcrumb-item',
  templateUrl: './breadcrumb-item.component.html',
  styleUrls: ['./breadcrumb-item.component.scss']
})
export class BreadcrumbItemComponent {
  @Input() entityName?: string;
  @Input() name?: string;
  @Input() icon?: string;
}
