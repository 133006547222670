import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, retry, switchMap, tap } from 'rxjs/operators';
import { BusinessProfilesApiActions } from '../../../admin-business-profiles/state/actions';
import { OrganizationsService } from '../../services/organizations.service';
import {
  AdminOrganizationsAdministratorsApiActions,
  AdminOrganizationsApiActions,
  AdminOrganizationsBusinessProfilesApiActions,
  AdminOrganizationsBusinessProfilesPageActions,
  AdminOrganizationsPageActions,
  AdminOrganizationsRolesApiActions,
  AdminOrganizationsTagsApiActions
} from '../actions';
import * as fromAdminOrganizationsApi from '../reducers/admin-organizations-api.reducer';

@Injectable()
export class AdminOrganizationsEffects {
  listOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsPageActions.listOrganizations),
      switchMap(() =>
        this.organizationsService.getOrganizations().pipe(
          retry(3),
          map((organizations) => AdminOrganizationsApiActions.listOrganizationsSuccess({ organizations: organizations })),
          catchError((error) => of(AdminOrganizationsApiActions.listOrganizationsFailure({ error })))
        )
      )
    )
  );

  selectOrganizationThenListRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsApiActions.selectOrganizationSuccess),
      switchMap((action) =>
        this.organizationsService.getRolesByOrganization(action.selectedOrganization.id).pipe(
          map((roles) => AdminOrganizationsRolesApiActions.listRolesByOrganizationSuccess({ roles: roles })),
          catchError((error) => of(AdminOrganizationsRolesApiActions.listRolesByOrganizationFailure({ error })))
        )
      )
    )
  );

  selectOrganizationThenListBusinessProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsApiActions.selectOrganizationSuccess),
      mergeMap((action) =>
        this.organizationsService.getBusinessProfilesByOrganization(action.selectedOrganization.id).pipe(
          map((businessProfiles) => AdminOrganizationsBusinessProfilesApiActions.listBusinessProfilesByOrganizationSuccess({ businessProfiles })),
          catchError((error) => of(AdminOrganizationsBusinessProfilesApiActions.listBusinessProfilesByOrganizationFailure({ error })))
        )
      )
    )
  );

  selectOrganizationThenListTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsApiActions.selectOrganizationSuccess),
      switchMap((action) =>
        this.organizationsService.getTagsByOrganization(action.selectedOrganization.id, false).pipe(
          map((tags) => AdminOrganizationsTagsApiActions.listTagsByOrganizationSuccess({ tags })),
          catchError((error) => of(AdminOrganizationsTagsApiActions.listTagsByOrganizationFailure({ error })))
        )
      )
    )
  );

  selectOrganizationThenListAdministrators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsApiActions.selectOrganizationSuccess),
      switchMap((action) =>
        this.organizationsService.getAdministratorsByOrganization(action.selectedOrganization.id).pipe(
          map((administrators) => AdminOrganizationsAdministratorsApiActions.listAdministratorsByOrganizationSuccess({ administrators })),
          catchError((error) => of(AdminOrganizationsAdministratorsApiActions.listAdministratorsByOrganizationFailure({ error })))
        )
      )
    )
  );

  selectOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsPageActions.selectOrganization),
      switchMap((action) =>
        this.organizationsService.getOrganizationById(action.selectedOrganizationId).pipe(
          map((selectedOrganization) => AdminOrganizationsApiActions.selectOrganizationSuccess({ selectedOrganization })),
          catchError((error) => of(AdminOrganizationsApiActions.selectOrganizationFailure({ error })))
        )
      )
    )
  );

  addBusinessProfileToOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsBusinessProfilesPageActions.addBusinessProfileToOrganization),
      switchMap((action) =>
        this.organizationsService.saveBusinessProfile(action.businessProfileToAdd).pipe(
          map((businessProfileAdded) => BusinessProfilesApiActions.addBusinessProfileSuccess({ businessProfile: businessProfileAdded })),
          catchError((error) => of(BusinessProfilesApiActions.addBusinessProfileFailure({ error })))
        )
      )
    )
  );

  removeBusinessProfileFromOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsBusinessProfilesPageActions.removeBusinessProfileFromOrganization),
      switchMap((action) =>
        this.organizationsService.deleteBusinessProfile(action.businessProfileToRemove).pipe(
          map((removedBusinessProfile) => BusinessProfilesApiActions.deleteBusinessProfileSuccess({ deletedBusinessProfile: removedBusinessProfile })),
          catchError((error) => of(BusinessProfilesApiActions.deleteBusinessProfileFailure({ error })))
        )
      )
    )
  );

  updateOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsPageActions.updateOrganization),
      switchMap((action) =>
        this.organizationsService.updateOrganization(action.organizationToUpdate).pipe(
          map((updatedOrganization) => AdminOrganizationsApiActions.updateOrganizationSuccess({ updatedOrganization: updatedOrganization })),
          catchError((error) => of(AdminOrganizationsApiActions.updateOrganizationFailure({ error })))
        )
      )
    )
  );

  addOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsPageActions.addOrganization),
      switchMap((action) =>
        this.organizationsService.addOrganization(action.newOrganization).pipe(
          map((addedOrganization) => AdminOrganizationsApiActions.addOrganizationSuccess({ organization: addedOrganization })),
          catchError((error) => of(AdminOrganizationsApiActions.addOrganizationFailure({ error })))
        )
      )
    )
  );

  deleteOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsPageActions.deleteOrganization),
      switchMap((action) =>
        this.organizationsService.deleteOrganization(action.organizationToDelete).pipe(
          map((removedOrganization) => AdminOrganizationsApiActions.deleteOrganizationSuccess({ removedOrganization })),
          catchError((error) => of(AdminOrganizationsApiActions.deleteOrganizationFailure({ error })))
        )
      )
    )
  );

  navigateToSelectedBusinessProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsBusinessProfilesPageActions.navigateToSelectedBusinessProfile),
      map((action) => BusinessProfilesApiActions.selectBusinessProfile({ selectedBusinessProfileId: action.businessProfile.id })),
      tap(() => this.router.navigate(['admin', 'business-profiles']))
    )
  );

  successedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BusinessProfilesApiActions.addBusinessProfileSuccess,
          BusinessProfilesApiActions.deleteBusinessProfileSuccess,
          AdminOrganizationsApiActions.addOrganizationSuccess,
          AdminOrganizationsApiActions.updateOrganizationSuccess,
          AdminOrganizationsApiActions.deleteOrganizationSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BusinessProfilesApiActions.addBusinessProfileFailure,
          BusinessProfilesApiActions.deleteBusinessProfileFailure,
          AdminOrganizationsApiActions.addOrganizationFailure,
          AdminOrganizationsApiActions.updateOrganizationFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  deleteEntityFailureActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminOrganizationsApiActions.deleteOrganizationFailure),
        tap((action) => {
          this.notificationService.displayError(action);
        })
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminOrganizationsPageActions.listOrganizations,
          AdminOrganizationsPageActions.selectOrganization,
          AdminOrganizationsPageActions.updateOrganization,
          AdminOrganizationsPageActions.addOrganization,
          AdminOrganizationsPageActions.deleteOrganization
        ),
        tap((action) => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AdminOrganizationsApiActions.listOrganizationsSuccess,
          AdminOrganizationsApiActions.listOrganizationsFailure,
          AdminOrganizationsApiActions.selectOrganizationSuccess,
          AdminOrganizationsApiActions.selectOrganizationFailure,
          AdminOrganizationsApiActions.updateOrganizationSuccess,
          AdminOrganizationsApiActions.updateOrganizationFailure,
          AdminOrganizationsApiActions.addOrganizationSuccess,
          AdminOrganizationsApiActions.addOrganizationFailure,
          AdminOrganizationsApiActions.deleteOrganizationSuccess,
          AdminOrganizationsApiActions.deleteOrganizationFailure
        ),
        tap((action) => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  /*saveBusinessProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesPageActions.createBusinessProfile),
      mergeMap(action =>
        this.bpService
          .saveBusinessProfile(action.businessProfile)
          .pipe(
            map(
              businessProfile => BusinessProfilesApiActions.saveBusinessProfileSuccess({ businessProfile }),
              catchError(error => of(BusinessProfilesApiActions.saveBusinessProfileFailure({ error })))
            )
          )
      )
    )
  );

  updateBusinessProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesPageActions.updateBusinessProfile),
      mergeMap(action =>
        this.bpService
          .updateBusinessProfile(action.businessProfile)
          .pipe(
            map(
              businessProfile =>
                BusinessProfilesApiActions.updateBusinessProfileSuccess({ businessProfile: { id: businessProfile.id, changes: businessProfile } }),
              catchError(error => of(BusinessProfilesApiActions.updateBusinessProfileFailure({ error })))
            )
          )
      )
    )
  );

  listRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesPageActions.listRolesByBusinessProfile),
      mergeMap(action =>
        this.bpService
          .loadRolesByBusinessProfileId(action.businessProfileId)
          .pipe(
            map(
              roles => BusinessProfilesApiActions.listRolesByBusinessProfileSuccess({ roles: roles }),
              catchError(error => of(BusinessProfilesApiActions.listRolesByBusinessProfileFailure({ error })))
            )
          )
      )
    )
  );

  listMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesPageActions.listMembersByBusinessProfile),
      mergeMap(action =>
        this.bpService
          .loadMembersByBusinessProfileId(action.businessProfileId)
          .pipe(
            map(
              members => BusinessProfilesApiActions.listMembersByBusinessProfileSuccess({ members: members }),
              catchError(error => of(BusinessProfilesApiActions.listMembersByBusinessProfileFailure({ error })))
            )
          )
      )
    )
  );*/

  constructor(
    private actions$: Actions,
    private organizationsService: OrganizationsService,
    private notificationService: NotificationService,
    private adminOrganizationStore: Store<fromAdminOrganizationsApi.State>,
    private router: Router
  ) {}
}
