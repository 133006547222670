import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Entity, ProductCatalog } from '@iot-platform/models/common';

@Component({
  selector: 'iot4bos-backoffice-ui-admin-product-catalog-panel',
  templateUrl: './admin-product-catalog-panel.component.html',
  styleUrls: ['./admin-product-catalog-panel.component.scss']
})
export class AdminProductCatalogPanelComponent {
  @Input() catalogs: ProductCatalog[];
  @Input() canUpdate: boolean;
  @Input() canDelete: boolean;

  @Output() editCatalogs: EventEmitter<any> = new EventEmitter<any>();

  displayEntities(entities: Entity[]): string {
    return entities.map((e) => e.name).join(', ');
  }
}
