import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Device } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-detail-devices-card',
  templateUrl: './detail-devices-card.component.html',
  styleUrls: ['./detail-devices-card.component.scss']
})
export class DetailDevicesCardComponent implements OnInit {
  @Input() total: number = 0;
  @Input() devices: Device[] = [];

  @Output() selectItem: EventEmitter<Device> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClick(device: Device) {
    this.selectItem.emit(device);
  }
}
