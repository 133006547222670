import { I4BCellType, I4BColumnConfiguration } from './configurations';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import {
  DynamicIconColumn,
  EventVariableGroupColumn,
  I4BAssetEventsButtonColumn,
  I4BBasicColumn,
  I4BColumn,
  I4BSelectionColumn,
  NameColumn,
  TotalColumn
} from './definitions';
import { I4bCellConcept } from './enums';
import { AssetEventsGridData, HeaderType, I4BColumnHeader, I4BGrid, I4BGridOptions } from './models';
import { I4BColumnOptions } from './options';

export const DEFAULT_ASSETS_COLUMNS_FOR_PO_EVENT_GENERATED_ASSET_EVENTS_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new NameColumn(
    { displayName: 'ASSET NAME', type: HeaderType.ICON_AND_TEXT, icon: 'asset' },
    {
      id: 'context.asset.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ASSET_NAME',
      concept: I4bCellConcept.ASSETS,
      isDefault: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToAsset' } }
    },
    { order: 6 }
  ),
  new EventVariableGroupColumn(
    {
      displayName: 'ASSET VARIABLE'
    },
    { id: 'assetVariable', isDefault: true, concept: I4bCellConcept.ASSETS },
    { order: 7 }
  )
];

export const DEFAULT_EVENTS_COLUMNS_FOR_PO_EVENT_GENERATED_ASSET_EVENTS_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new DynamicIconColumn(
    { displayName: 'DELAY', type: HeaderType.ICON, icon: 'delay' },
    {},
    {
      order: 0
    }
  ),
  new I4BBasicColumn(
    { displayName: 'OCCURRENCE TIME', type: HeaderType.BASIC },
    { id: 'occurrenceTime', isDefault: true, sortProperty: 'occurrenceTime', cell: { type: I4BCellType.DATE }, concept: I4bCellConcept.EVENTS },
    { order: 2 }
  ),
  new I4BBasicColumn(
    { displayName: 'RECEPTION TIME', type: HeaderType.BASIC },
    { id: 'receptionTime', isDefault: true, sortProperty: 'receptionTime', cell: { type: I4BCellType.DATE }, concept: I4bCellConcept.EVENTS },
    { order: 3 }
  ),
  new I4BBasicColumn(
    { displayName: 'SEVERITY', type: HeaderType.BASIC },
    {
      id: 'severity',
      isDefault: true,
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SEVERITY',
      sortProperty: 'severity',
      cell: { type: I4BCellType.SEVERITY_CELL },
      concept: I4bCellConcept.EVENTS
    },
    { order: 9 }
  ),
  new TotalColumn(
    {
      displayName: 'COMMENTS',
      type: HeaderType.EMPTY
    },
    {
      id: 'totalComments',
      isDefault: true,
      isConfigurable: false,
      cell: { type: I4BCellType.COMMENT },
      concept: I4bCellConcept.EVENTS
    },
    {
      order: 11
    }
  ),
  new I4BBasicColumn(
    { displayName: 'EVENT STATUS', type: HeaderType.ICON, icon: 'event_alarm' },
    {
      id: 'status',
      isDefault: true,
      isConfigurable: false,
      sortProperty: 'status',
      concept: I4bCellConcept.EVENTS,
      cell: { type: I4BCellType.ICON, options: { tooltip: 'EVENTS.STATUS' } }
    },
    { order: 1, width: 60 }
  )
];

export class DefaultPoEventGeneratedAssetEventsGrid implements I4BGrid<I4BGridOptions, AssetEventsGridData> {
  masterview: string = 'po-event-generated-asset-events';
  isDefault: boolean = false;
  isUserDefault: boolean = false;
  isAppDefault: boolean = false;
  name!: string;
  userId!: string;
  data!: AssetEventsGridData;
  gridOptions: I4BGridOptions = {
    endPoint: '/asset-events',
    pageSize: 100,
    multipleSelection: true,
    autoRefresh: { enabled: false, delay: 120 },
    gridSort: [],
    buttonColumn: { enabled: true, className: new I4BAssetEventsButtonColumn() },
    selectionColumn: {
      enabled: true,
      className: new I4BSelectionColumn()
    }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  export(): void {}
}
