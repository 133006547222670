import { Injectable } from '@angular/core';

import { Log } from '@iot-platform/models/i4b';

import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AssetEventsService } from '../../../../../../shared/src/lib/services/asset-events.service';
import { AssetEventsBySiteLogsDbActions, AssetEventsBySiteLogsUiActions } from '../actions';

@Injectable()
export class AssetEventsBySiteLogsEffects {
  loadLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsBySiteLogsUiActions.loadLogsByAssetEventId),
      switchMap((action) =>
        this.assetEventsService.getLogsById(action.assetEventId).pipe(
          map((logs: Log[]) => AssetEventsBySiteLogsDbActions.loadLogsByAssetEventIdSuccess({ logs })),
          catchError((error) => of(AssetEventsBySiteLogsDbActions.loadLogsByAssetEventIdFailure({ error })))
        )
      )
    )
  );

  createLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsBySiteLogsUiActions.createLogByAssetEventId),
      switchMap((action) =>
        this.assetEventsService.putLogById(action.comment).pipe(
          map((log: Log) => AssetEventsBySiteLogsDbActions.createLogByAssetEventIdSuccess({ log, assetEvent: action.comment.assetEvent })),
          catchError((error) => of(AssetEventsBySiteLogsDbActions.createLogByAssetEventIdFailure({ error })))
        )
      )
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetEventsBySiteLogsDbActions.createLogByAssetEventIdSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetEventsBySiteLogsDbActions.loadLogsByAssetEventIdFailure, AssetEventsBySiteLogsDbActions.createLogByAssetEventIdFailure),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private assetEventsService: AssetEventsService, private notificationService: NotificationService) {}
}
