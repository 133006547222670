<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">
        {{
          'MANAGE_TAGS_FORM.ADD_OR_REMOVE_DIALOG.' + (data.isAddition ? 'TITLE_ADD' : 'TITLE_REMOVE')
            | translate: { total: data.selectionTotal, concept: data.concept }
        }}
      </h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content" fxLayout="row">
    <form class="full-width p-20 site-info-form" [formGroup]="manageTagForm" fxLayout="column">
      <iot-platform-ui-detail-popup-display-property
        class="add-or-remove-tags-dialog--disclaimer"
        [property]="'MANAGE_TAGS_FORM.ADD_OR_REMOVE_DIALOG.NUMBER_OF_ITEMS' | translate"
        [value]="data.selectionTotal | numberFormat"
      ></iot-platform-ui-detail-popup-display-property>
      <p class="add-or-remove-tags-dialog--disclaimer">{{ 'MANAGE_TAGS_FORM.ADD_OR_REMOVE_DIALOG.BP_MESSAGE' | translate }}</p>
      <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div fxLayout="row" fxFlex="50" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-form-field color="accent" fxFlex>
            <mat-select [placeholder]="'IOT_DICTIONARY.CATEGORY' | translate" formControlName="category" required (selectionChange)="onCategoryChange($event)">
              <mat-option *ngFor="let category of categories" [value]="category">{{ category.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-progress-spinner *ngIf="categoriesLoading$ | async" mode="indeterminate" color="accent" [diameter]="14"></mat-progress-spinner>
        </div>

        <mat-form-field color="accent" fxFlex="50">
          <mat-select [placeholder]="'IOT_DICTIONARY.LABEL' | translate" formControlName="label" required>
            <mat-option *ngFor="let label of labels" [value]="label">{{ label.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </section>
      <p class="dialog-form-tips">{{ 'IOT_DICTIONARY.MANDATORY_FIELDS' | translate }}</p>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="close()">{{ 'SITES.INFO_FORM.CANCEL' | translate }}</button>
    <button mat-raised-button class="button-regular" color="accent" [disabled]="!manageTagForm.valid" (click)="save()">
      {{ 'IOT_DICTIONARY.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
