<form [formGroup]="catalogForm" fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="tag-editor-form-container">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <button
      *ngIf="mode === 'UPDATE'"
      mat-icon-button
      color="warn"
      class="regular-round-button"
      [matTooltip]="'TAG_EDITOR.REMOVE' | translate"
      matTooltipClass="warning-tooltip"
      matTooltipPosition="below"
      (click)="onRemove(catalog)"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <mat-form-field fxFlex="200px" color="accent">
    <input
      #nameInput
      matInput
      required
      maxlength="20"
      [placeholder]="'TAG_EDITOR.CATEGORY_NAME' | translate"
      [value]="catalog.name"
      formControlName="name"
    />
    <mat-error *ngIf="name.invalid && name.errors.duplicate">
      {{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_CATEGORY' | translate }}
    </mat-error>
    <mat-hint align="end"
    ><span>{{ nameInput.value.length || 0 }}/{{ nameMaximumLength }}</span></mat-hint
    >
  </mat-form-field>

  <mat-form-field fxFlex color="accent">
    <mat-select formControlName="entities" placeholder="{{ 'ADMIN.PRODUCT_CATALOGS.FORM.ATTACHED_ENTITIES' | translate }}" multiple required>
      <mat-select-trigger *ngIf="entities.value">{{ getEntitiesFormatted() }}</mat-select-trigger>
      <mat-option *ngFor="let entity of attachedEntitiesNotVisible?.concat(entityList)" [disabled]="disableEntity(entity)" [value]="entity">{{ entity?.label }}</mat-option>
    </mat-select>
    <mat-error *ngIf="entities.value.length > maximumEntitiesPerCatalog">{{ 'ADMIN.PRODUCT_CATALOGS.FORM.TOO_MANY_ENTITIES' | translate }}</mat-error>
    <mat-hint align="end" fxLayoutGap="10px">
      <span>{{ entities.value?.length || 0 }}/{{ maximumEntitiesPerCatalog }}</span>
    </mat-hint>
  </mat-form-field>
  <button
    *ngIf="mode === 'UPDATE'"
    mat-raised-button
    color="accent"
    class="button-regular"
    [disabled]="!catalogForm.valid || (catalogForm.valid && !catalogForm.dirty)"
    (click)="onSave(catalog)"
  >
    {{ 'TAG_EDITOR.UPDATE' | translate | uppercase }}
  </button>
  <button *ngIf="mode === 'ADD'" mat-raised-button color="accent" class="button-regular" [disabled]="!catalogForm.valid" (click)="onSave(catalog)">
    {{ 'TAG_EDITOR.ADD' | translate | uppercase }}
  </button>
</form>
