import { I4BCellType, I4BSelectionColumnConfiguration } from '../configurations';
import { DataLibrary, I4bCellConcept } from '../enums';
import { I4BSelectionColumnHeader } from '../headers';
import { HeaderType } from '../models';
import { I4BColumnOptions } from '../options';
import { I4BColumn } from './i4b-column';

export class I4BSelectionColumn implements I4BColumn<I4BSelectionColumnHeader, I4BSelectionColumnConfiguration, I4BColumnOptions> {
  className = I4BSelectionColumn;
  columnId: string = '06cf77aa-af7e-11ec-bb66-acde48001122-selection';
  dataLibs: DataLibrary[] = [DataLibrary.DEVICE_EVENTS, DataLibrary.ASSET_EVENTS, DataLibrary.STOCK_SITE_DEVICES];
  header: I4BSelectionColumnHeader = { type: HeaderType.EMPTY, displayName: '', icon: '' };
  configuration: I4BSelectionColumnConfiguration = {
    concept: I4bCellConcept.EVENTS,
    isConfigurable: false,
    isDefault: true,
    isLink: false,
    position: '',
    sortProperty: '',
    width: 10,
    id: 'selection',
    cell: {
      id: '',
      type: I4BCellType.BASIC,
      name: ''
    }
  };
  options: I4BColumnOptions;

  constructor(customHeader?: I4BSelectionColumnHeader, customConfig?: Partial<I4BSelectionColumnConfiguration>, customOptions?: I4BColumnOptions) {
    if (customHeader) {
      this.header = { ...this.header, ...customHeader };
    }
    if (customConfig) {
      this.configuration = { ...this.configuration, ...customConfig };
    }
    if (customOptions) {
      this.options = { ...this.options, ...customOptions };
    }
  }
}
