import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-event-severity-display',
  templateUrl: './event-severity-display.component.html',
  styleUrls: ['./event-severity-display.component.scss']
})
export class EventSeverityDisplayComponent implements OnInit {
  @Input() severity: string;

  constructor() {}

  ngOnInit(): void {}
}
