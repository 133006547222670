import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { Log } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AssetEventsByTopicLogsDbActions, AssetEventsByTopicLogsUiActions } from '../../actions';
//
export const assetEventsByTopicLogsDbFeatureKey = 'assetEventsByTopicLogsDb';

export interface State extends EntityState<Log> {
  selectedLogId: string | null;
  error?: any;
  log?: Log;
}

export const adapter: EntityAdapter<Log> = createEntityAdapter<Log>({
  selectId: (log: Log) => log.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedLogId: null,
  error: null,
  log: null
});

const assetEventsByTopicLogsDbReducer = createReducer(
  initialState,

  on(AssetEventsByTopicLogsUiActions.loadLogsByAssetEventId, (state: State) => ({ ...state })),
  on(AssetEventsByTopicLogsDbActions.loadLogsByAssetEventIdSuccess, (state: State, { logs }) => adapter.setAll(logs, state)),
  on(AssetEventsByTopicLogsDbActions.loadLogsByAssetEventIdFailure, (state: State, { error }) => ({ ...state, error })),
  // ****
  on(AssetEventsByTopicLogsDbActions.createLogByAssetEventIdSuccess, (state: State, { log }) => adapter.addOne(log, { ...state, log })),
  on(AssetEventsByTopicLogsDbActions.createLogByAssetEventIdFailure, (state: State, { error }) => ({ ...state, error })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsByTopicLogsDbReducer(state, action);
}

export const getLog = (state: State) => state.log;
