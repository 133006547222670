<mat-card-content class="p-20 mve-detail-popup-container" fxLayout="column" fxLayoutGap="20px">

  <section fxLayout="row" fxLayoutGap="20px">
    <div fxLayout="column" fxFlex fxLayoutGap="6px" fxLayoutAlign="start start">
      <span class="mve-detail-popup-title fw-600">{{ data?.rowData?.key | uppercase }}</span>
    </div>
  </section>

  <section fxLayout="column" fxLayoutGap="10px" *ngFor="let item of translations">
    <iot-platform-ui-detail-popup-section-header
      headerTitle="{{ item?.key | uppercase }}"
    ></iot-platform-ui-detail-popup-section-header>

    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <iot-platform-ui-detail-popup-display-property
        value="{{ item?.value | infoDisplay }}"
      ></iot-platform-ui-detail-popup-display-property>
    </div>
  </section>

</mat-card-content>
