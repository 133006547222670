import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@iot-platform/shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserPreferencesComponent } from './components/user-preferences.component';
import { UserPreferencesService } from './services/user-preferences.service';
import { PreferencesEffects } from './state/effects/preferences.effects';
import * as fromPreferences from './state/reducers';

@NgModule({
    declarations: [UserPreferencesComponent],
    imports: [
        CommonModule,
        SharedModule,
        StoreModule.forFeature(fromPreferences.userPreferencesFeatureKey, fromPreferences.reducers),
        EffectsModule.forFeature([PreferencesEffects])
    ],
    exports: [UserPreferencesComponent],
    providers: [PreferencesEffects, UserPreferencesService]
})
export class PreferencesModule {}
