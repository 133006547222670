import { Injectable } from '@angular/core';
import { CommonApiRequest, FavoriteView, Filter, PlatformRequest, TagCategory } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Site } from '@iot-platform/models/i4b';
import { select, Store } from '@ngrx/store';
import { GridsDbActions } from '../../../../../../../grid-engine/src/lib/components/state/actions';
import * as fromGrids from '../../../../../../../grid-engine/src/lib/components/state/reducers';

import { FavoriteViewsActions } from '../../../../../../../shared/src/lib/favorite-views/+state/actions';
import * as fromFavoriteViews from '../../../../../../../shared/src/lib/favorite-views/+state/reducers';
import { SitesDbActions } from '../actions';
import * as fromSites from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class SitesFacade {
  // allSites$ = this.sitesStore.pipe(select(fromSites.getAllSites));
  grid$ = this.store.pipe(select(fromGrids.getDefaultSitesGrid));
  grids$ = this.store.pipe(select(fromGrids.getSitesGrids));
  siteGridsCompilation$ = this.store.pipe(select(fromGrids.selectSiteGridsConfiguration));
  assetsBySite$ = this.store.pipe(select(fromSites.getAssetsBySite));
  devicesBySite$ = this.store.pipe(select(fromSites.getDevicesBySite));
  currentSite$ = this.store.pipe(select(fromSites.getCurrentSite));
  siteLoaded$ = (gridId: string) => this.store.select(fromGrids.getDataLoadedByGrid(gridId));
  assetLoaded$ = this.store.pipe(select(fromSites.getAssetLoaded));
  deviceLoaded$ = this.store.pipe(select(fromSites.getDeviceLoaded));
  tags$ = this.store.pipe(select(fromSites.getTagsBySite));
  tagsLoaded$ = this.store.pipe(select(fromSites.getTagsLoaded));

  // pagination$ = this.sitesStore.pipe(select(fromSites.getPagination));
  // mvSettings$ = this.sitesStore.pipe(select(fromSites.getMVSettings));
  selectedSite$ = this.store.pipe(select(fromSites.getSelectedSite));

  favoriteViews$ = this.store.pipe(select(fromFavoriteViews.getFavoriteViewsForMasterViewSites));
  currentFavoriteView$ = this.store.pipe(select(fromFavoriteViews.getSelectedFavoriteViewForMasterViewSites));
  currentFilters$ = this.store.pipe(select(fromFavoriteViews.getFiltersForMasterViewSites));
  loadingFavoriteViews$ = this.store.pipe(select(fromFavoriteViews.getLoading));

  siteFavoriteViewsConfiguration$ = this.store.pipe(select(fromFavoriteViews.selectSiteFavoriteViewsConfiguration));
  // formattedData$ = this.sitesStore.pipe(select(fromSites.getFormattedData));

  constructor(private store: Store) {}

  addSite(site: Site) {
    this.store.dispatch(SitesDbActions.addSite({ siteToAdd: site }));
  }

  loadMetadata() {
    // this.sitesStore.dispatch(SitesDbActions.loadMVSettings({ settingName: 'sites' }));
    this.store.dispatch(GridsDbActions.getDefaultGridByConcept({ concept: 'sites' }));
  }

  loadSites(request: CommonApiRequest) {
    // this.sitesStore.dispatch(SitesDbActions.loadSites({ request: params }));
    this.store.dispatch(GridsDbActions.loadGridData({ request: { concept: 'sites', ...request } }));
  }

  loadGrids() {
    this.store.dispatch(GridsDbActions.loadGrids({ concept: 'sites' }));
  }

  saveMVSettings(name: string, values: any) {
    this.store.dispatch(SitesDbActions.saveMVSettings({ name, values }));
  }
  loadAssetsBySiteId(request: PlatformRequest) {
    this.store.dispatch(SitesDbActions.loadAssetsBySiteId({ request: request }));
  }

  loadDevicesBySiteId(request: PlatformRequest) {
    this.store.dispatch(SitesDbActions.loadDevicesBySiteId({ request: request }));
  }

  loadAssociationsBySiteId(siteId: string) {
    this.store.dispatch(SitesDbActions.loadAssociationsBySiteId({ siteId }));
  }

  loadSiteById(siteId: string) {
    this.store.dispatch(SitesDbActions.loadSiteById({ siteId: siteId }));
  }

  loadTagsBySiteId(siteId: string) {
    this.store.dispatch(SitesDbActions.loadTagsBySiteId({ siteId: siteId }));
  }

  selectSite(selectedSite: Site) {
    this.store.dispatch(SitesDbActions.selectSite({ selectedSite }));
  }

  updateSite(site: Site) {
    this.store.dispatch(SitesDbActions.updateSite({ siteToUpdate: site }));
  }

  updateTagsBySiteId(siteId: string, tags: TagCategory[]) {
    this.store.dispatch(SitesDbActions.updateTagsBySiteId({ siteId, tags }));
  }

  deleteSite(site: Site) {
    this.store.dispatch(SitesDbActions.deleteSite({ siteToDelete: site }));
  }

  saveFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenAddFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.addFavoriteView({ favoriteView: data.favoriteView }));
    }
  }

  updateFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenUpdateFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.updateFavoriteView({ favoriteView: data.favoriteView }));
    }
  }

  deleteFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.deleteFavoriteView({ favoriteView }));
  }

  setCurrentFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFavoriteView({ masterView: 'sites', favoriteView: favoriteView }));
  }

  setCurrentFilters(filters: Filter[]) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'sites', filters: filters }));
  }
}
