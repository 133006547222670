import { NgModule } from '@angular/core';
import { SharedModule } from '@iot-platform/shared';
import { DefaultCardModule } from 'libs/iot4bos-ui/src/lib/components/default-card/default-card.module';
import { SiteContactModule } from 'libs/iot4bos-ui/src/lib/features/sites/components/site-contact/site-contact.module';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { GatewayCardComponent } from './components/gateway-card/gateway-card.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { SimpleCardComponent } from './components/simple-card/simple-card.component';
import { ThresholdCardComponent } from './components/threshold-card/threshold-card.component';
import { CardsContainerComponent } from './containers/cards-container/cards-container.component';
import { IotPlatformPipesModule } from '@iot-platform/pipes';

const COMPONENTS = [SimpleCardComponent, ProductCardComponent, CardsContainerComponent, ThresholdCardComponent, GatewayCardComponent, ContactCardComponent];

@NgModule({
  imports: [SharedModule, IotPlatformPipesModule, DefaultCardModule, SiteContactModule],
  declarations: COMPONENTS,
  exports: [DefaultCardModule, ...COMPONENTS]
})
export class CardsModule {}
