<section class="device-info-toolbar" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center">
  <div *ngIf="displayLoader" style="width: 100px;" fxLayoutAlign="start center">
    <mat-spinner [diameter]="20" color="accent"></mat-spinner>
  </div>
  <p *ngIf="!displayLoader" style="font-size: 14px; min-width: 100px;">{{ totalFiltered }} / {{ total }}</p>

  <div fxFlex fxLayoutAlign="space-around center">
    <form [formGroup]="toolbarForm" (ngSubmit)="apply()" fxFlex fxLayoutAlign="space-around center">
      <div fxLayoutAlign="space-around center" style="padding: 4px;">
        <mat-form-field class="search" color="accent" style="width: 120px; margin-left: 8px; margin-right: 8px;" *ngIf="withNameFilter">
          <mat-icon matPrefix class="search-icon">search</mat-icon>
          <mat-label>{{ 'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_NAME' | translate }}</mat-label>
          <input matInput formControlName="name" type="text" (keyup.enter)="onFilterNameValueChange($event)" (change)="onFilterNameValueChange($event)" />
        </mat-form-field>

        <mat-form-field class="search" color="accent" style="width: 120px; margin-left: 8px; margin-right: 8px;" *ngIf="withDescriptionFilter">
          <mat-icon matPrefix class="search-icon">search</mat-icon>
          <mat-label>{{ 'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_DESCRIPTION' | translate }}</mat-label>
          <input matInput formControlName="description" type="text" (change)="onFilterDescriptionValueChange($event)" />
        </mat-form-field>

        <mat-form-field color="accent" style="width: 120px; margin-left: 8px; margin-right: 8px;" *ngIf="withGroupFilter">
          <mat-select
            formControlName="group"
            [placeholder]="'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_GROUP' | translate"
            (selectionChange)="onGroupSelectionChange($event)"
          >
            <mat-option *ngFor="let group of deviceVariableGroups" [value]="group.key">{{ group.key | translate }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field color="accent" style="width: 120px; margin-left: 8px; margin-right: 8px;" *ngIf="withUnitFilter">
          <mat-select
            formControlName="unit"
            [placeholder]="'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_UNIT' | translate"
            (selectionChange)="onUnitSelectionChange($event)"
          >
            <mat-option *ngFor="let unit of variableUnits" [value]="unit">{{ unit }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field color="accent" style="width: 120px; margin-left: 8px; margin-right: 8px;" *ngIf="withFileFilter">
          <mat-select
            formControlName="file"
            [placeholder]="'MASTER_VIEW_ENGINE.TABLE.FILTERS.FILTER_BY_FILE' | translate"
            (selectionChange)="onFileSelectionChange($event)"
          >
            <mat-option *ngFor="let file of deviceVariableFiles" [value]="file.key">{{ file.key | translate }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field color="accent" style="width: 120px; margin-left: 8px; margin-right: 8px;" *ngIf="withLinkedFilter">
          <mat-select formControlName="linked" [placeholder]="'linked'" (selectionChange)="onLinkedSelectionChange($event)">
            <mat-option *ngFor="let link of ['all', false, true]" [value]="link">{{ link }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <button mat-button (click)="clear()" color="accent">{{ 'DEVICES.CALL_LOG.RESET_FILTERS' | translate }}</button>
  </div>

  <!--<button mat-button (click)="onManageColumns()">C</button>-->
</section>
