import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { Device } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-device-info-status',
  templateUrl: './device-info-status.component.html',
  styleUrls: ['./device-info-status.component.scss']
})
export class DeviceInfoStatusComponent implements OnInit, OnChanges {
  @Input() device: Device | null = null;

  deviceStatus: string;
  iconToDisplay: { name: string; className: string };

  constructor() {}

  ngOnInit() {
    this.getDeviceStatus(this.device);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('device') && changes.device.currentValue) {
      this.getDeviceStatus(changes.device.currentValue);
    }
  }

  getDeviceStatus(device: Device): void {
    if (device && device.cyclicalCallFault && device.cyclicalCallFault.status) {
      this.deviceStatus = 'cCF';
      this.iconToDisplay = { name: 'cyclicalCallFault', className: 'ccf' };
      return;
    } else if (
      device &&
      device.cyclicalCallFault &&
      !device.cyclicalCallFault.status &&
      device.lastCallStatus &&
      this.isLastCall(device.lastCallStatus.datetime)
    ) {
      this.deviceStatus = 'warning';
      this.iconToDisplay = { name: 'clock', className: 'warning' };
      return;
    } else if (device && device.lastCallStatus && device.lastCallStatus.name === 'success' && !this.isLastCall(device.lastCallStatus.datetime)) {
      this.deviceStatus = 'success';
      this.iconToDisplay = { name: 'done', className: 'success' };
      return;
    } else {
      this.deviceStatus = 'undefined';
      this.iconToDisplay = { name: null, className: null };
    }
  }

  isLastCall(dateTime: string): boolean {
    const timestamp: number = Date.now();
    const lastCallDate: number = Date.parse(dateTime);
    const days = (timestamp - lastCallDate) / 1000 / 60 / 60 / 24;
    return days > 1;
  }
}
