import { Injectable } from '@angular/core';
import { AuthorizationService } from '@iot-platform/auth';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { Contact, PlatformRequest, Product, TagCategory } from '@iot-platform/models/common';
import {
  Asset,
  AssetEvent,
  AssetVariable,
  CommandType,
  Device,
  DeviceEvent,
  DeviceVariable,
  Site,
  VariablesTableFilters
} from '@iot-platform/models/i4b';
import { select, Store } from '@ngrx/store';

import * as fromNavigation from '../+state/reducers';
import { AuthorizationConcept, AuthorizationType } from '../../../../../auth/src/lib/authorization.types';
import { AssetEventsApi } from '../../features/asset-events/+state/asset-events.api';
import { AssetsFacade } from '../../features/assets/+state/facades/assets.facade';
import { DeviceEventsApi } from '../../features/device-events/+state/device-events.api';
import { DevicesFacade } from '../../features/devices/+state/facades/devices.facade';
import { ContactsFacade } from '../../features/sites/+state/facades/contacts.facade';
import { GraphsFacade } from '../../features/sites/+state/facades/graphs.facade';
import { SitesFacade } from '../../features/sites/+state/facades/sites.facade';
import {
  AssetEventsByAssetLogsUiActions,
  AssetEventsByAssetUiActions,
  AssetEventsBySiteLogsUiActions,
  AssetEventsBySiteUiActions,
  DeviceEventsByDeviceLogsUiActions,
  DeviceEventsByDeviceUiActions,
  DeviceEventsBySiteLogsUiActions,
  DeviceEventsBySiteUiActions,
  NavigationActions
} from './actions';

@Injectable()
export class NavigationApi {
  // SITE
  site$ = this.store.pipe(select(fromNavigation.getSite));
  assets$ = this.store.pipe(select(fromNavigation.getAssets));
  devices$ = this.store.pipe(select(fromNavigation.getDevices));
  devicesByStock$ = this.store.pipe(select(fromNavigation.getDevicesByStock));
  siteTags$ = this.store.pipe(select(fromNavigation.getSiteTags));
  siteLoaded = this.store.pipe(select(fromNavigation.getSiteLoaded));
  siteTagsLoaded = this.store.pipe(select(fromNavigation.getSiteTagsLoaded));
  siteAssociations$ = this.store.pipe(select(fromNavigation.getSiteAssociations));
  siteFullAssociations$ = this.store.pipe(select(fromNavigation.getFullAssociations));
  siteFullAssociationsLoaded$ = this.store.pipe(select(fromNavigation.getFullAssociationsLoaded));
  isSiteReadyFromDeletion$ = this.store.pipe(select(fromNavigation.selectIsSiteReadyFromDeletion));

  // STOCK DEVICES
  deviceCountByFamily$ = this.store.pipe(select(fromNavigation.selectDeviceCountByFamily));
  deviceCountByFamilyLoading$ = this.store.pipe(select(fromNavigation.selectDeviceCountByFamilyLoading));

  // SELECTED ASSET
  selectedAsset$ = this.store.pipe(select(fromNavigation.getSelectedAsset));
  assetLoaded = this.store.pipe(select(fromNavigation.getAssetLoaded));
  assetTags$ = this.store.pipe(select(fromNavigation.getAssetTags));
  assetTagsLoaded$ = this.store.pipe(select(fromNavigation.getAssetTagsLoaded));
  assetVariables$ = this.store.pipe(select(fromNavigation.getAssetVariables));
  assetVariablesLoaded$ = this.store.pipe(select(fromNavigation.getAssetVariablesLoaded));

  // SELECTED DEVICE
  selectedDevice$ = this.store.pipe(select(fromNavigation.getSelectedDevice));
  deviceLoaded = this.store.pipe(select(fromNavigation.getDeviceLoaded));
  deviceTags$ = this.store.pipe(select(fromNavigation.getDeviceTags));
  deviceTagsLoaded$ = this.store.pipe(select(fromNavigation.getDeviceTagsLoaded));
  deviceVariables$ = this.store.pipe(select(fromNavigation.getDeviceVariables));
  getDeviceVariablesTableFilters$ = this.store.pipe(select(fromNavigation.getDeviceVariablesTableFilters));
  deviceVariablesLoaded$ = this.store.pipe(select(fromNavigation.getDeviceVariablesLoaded));
  //

  siteId$ = this.store.pipe(select(fromNavigation.getSiteId));
  activeId$ = this.store.pipe(select(fromNavigation.getActiveId));
  origin$ = this.store.pipe(select(fromNavigation.getOrigin));
  pageType$ = this.store.pipe(select(fromNavigation.getPageType));
  data$ = this.store.pipe(select(fromNavigation.getData));

  // MASTER VIEWS ASSET EVENT BY ASSET
  formattedAssetEventsByAsset$ = this.store.pipe(select(fromNavigation.getFormattedAssetEventsByAsset));
  formattedAssetEventsByAssetLoaded$ = this.store.pipe(select(fromNavigation.getAssetEventsByAssetLoaded));
  totalActiveAssetEventsByAsset$ = this.store.pipe(select(fromNavigation.getTotalActiveAssetEventsByAsset));
  totalActiveAssetEventsByAssetLoaded$ = this.store.pipe(select(fromNavigation.getTotalActiveAssetEventsByAssetLoaded));
  selectedAssetEventByAsset$ = this.store.pipe(select(fromNavigation.getSelectedAssetEventByAsset));
  assetEventsByAssetTableState$ = this.store.pipe(select(fromNavigation.getAssetEventsByAssetTableState));
  assetEventsByAssetAutoRefresh$ = this.store.pipe(select(fromNavigation.getAssetEventsByAssetAutoRefresh));
  assetEventsByAssetRefreshDelay$ = this.store.pipe(select(fromNavigation.getAssetEventsByAssetRefreshDelay));
  mvAssetEventsByAssetSettings$ = this.store.pipe(select(fromNavigation.getMvAssetEventsByAssetSettings));
  assetEventsByAssetPagination$ = this.store.pipe(select(fromNavigation.getAssetEventsByAssetPagination));
  assetEventsByAssetLogsByAssetEventId$ = this.store.pipe(select(fromNavigation.getAllAssetEventsByAssetLogs));
  assetEventsByAssetLogsByAssetEventIdLoaded$ = this.store.pipe(select(fromNavigation.getAssetEventsByAssetLogsLoaded));
  assetEventByAssetStatus$ = this.store.pipe(select(fromNavigation.getAssetEventByAssetStatus));

  // MASTER VIEWS ASSET EVENT BY SITE
  formattedAssetEventsBySite$ = this.store.pipe(select(fromNavigation.getFormattedAssetEventsBySite));
  formattedAssetEventsBySiteLoaded$ = this.store.pipe(select(fromNavigation.getAssetEventsBySiteLoaded));
  totalActiveAssetEventsBySite$ = this.store.pipe(select(fromNavigation.getTotalActiveAssetEventsBySite));
  totalActiveAssetEventsBySiteLoaded$ = this.store.pipe(select(fromNavigation.getTotalActiveAssetEventsBySiteLoaded));
  selectedAssetEventBySite$ = this.store.pipe(select(fromNavigation.getSelectedAssetEventBySite));
  assetEventsBySiteTableState$ = this.store.pipe(select(fromNavigation.getAssetEventsBySiteTableState));
  assetEventsBySiteAutoRefresh$ = this.store.pipe(select(fromNavigation.getAssetEventsBySiteAutoRefresh));
  assetEventsBySiteRefreshDelay$ = this.store.pipe(select(fromNavigation.getAssetEventsBySiteRefreshDelay));
  mvAssetEventsBySiteSettings$ = this.store.pipe(select(fromNavigation.getMvAssetEventsBySiteSettings));
  assetEventsBySitePagination$ = this.store.pipe(select(fromNavigation.getAssetEventsBySitePagination));
  assetEventsBySiteLogsByAssetEventId$ = this.store.pipe(select(fromNavigation.getAllAssetEventsBySiteLogs));
  assetEventsBySiteLogsByAssetEventIdLoaded$ = this.store.pipe(select(fromNavigation.getAssetEventsBySiteLogsLoaded));
  assetEventBySiteStatus$ = this.store.pipe(select(fromNavigation.getAssetEventBySiteStatus));

  // MASTER VIEWS DEVICE EVENT BY DEVICE
  formattedDeviceEventsByDevice$ = this.store.pipe(select(fromNavigation.getFormattedDeviceEventsByDevice));
  formattedDeviceEventsByDeviceLoaded$ = this.store.pipe(select(fromNavigation.getDeviceEventsByDeviceLoaded));
  totalActiveDeviceEventsByDevice$ = this.store.pipe(select(fromNavigation.getTotalActiveDeviceEventsByDevice));
  totalActiveDeviceEventsByDeviceLoaded$ = this.store.pipe(select(fromNavigation.getTotalActiveDeviceEventsByDeviceLoaded));
  selectedDeviceEventByDevice$ = this.store.pipe(select(fromNavigation.getSelectedDeviceEventByDevice));
  deviceEventsByDeviceTableState$ = this.store.pipe(select(fromNavigation.getDeviceEventsByDeviceTableState));
  deviceEventsByDevicePagination$ = this.store.pipe(select(fromNavigation.getDeviceEventsByDevicePagination));
  deviceEventsByDeviceAutoRefresh$ = this.store.pipe(select(fromNavigation.getDeviceEventsByDeviceAutoRefresh));
  deviceEventsByDeviceRefreshDelay$ = this.store.pipe(select(fromNavigation.getDeviceEventsByDeviceRefreshDelay));
  mvDeviceEventsByDeviceSettings$ = this.store.pipe(select(fromNavigation.getMvDeviceEventsByDeviceSettings));
  deviceEventsByDeviceLogsByDeviceEventId$ = this.store.pipe(select(fromNavigation.getAllDeviceEventsByDeviceLogs));
  deviceEventsByDeviceLogsByDeviceEventIdLoaded$ = this.store.pipe(select(fromNavigation.getDeviceEventsByDeviceLogsLoaded));
  deviceEventByDeviceStatus$ = this.store.pipe(select(fromNavigation.getDeviceEventByDeviceStatus));

  // MASTER VIEWS DEVICE EVENT BY SITE
  formattedDeviceEventsBySite$ = this.store.pipe(select(fromNavigation.getFormattedDeviceEventsBySite));
  formattedDeviceEventsBySiteLoaded$ = this.store.pipe(select(fromNavigation.getDeviceEventsBySiteLoaded));
  totalActiveDeviceEventsBySite$ = this.store.pipe(select(fromNavigation.getTotalActiveDeviceEventsBySite));
  totalActiveDeviceEventsBySiteLoaded$ = this.store.pipe(select(fromNavigation.getTotalActiveDeviceEventsBySiteLoaded));
  selectedDeviceEventBySite$ = this.store.pipe(select(fromNavigation.getSelectedDeviceEventBySite));
  deviceEventsBySiteTableState$ = this.store.pipe(select(fromNavigation.getDeviceEventsBySiteTableState));
  deviceEventsBySiteAutoRefresh$ = this.store.pipe(select(fromNavigation.getDeviceEventsBySiteAutoRefresh));
  deviceEventsBySiteRefreshDelay$ = this.store.pipe(select(fromNavigation.getDeviceEventsBySiteRefreshDelay));
  mvDeviceEventsBySiteSettings$ = this.store.pipe(select(fromNavigation.getMvDeviceEventsBySiteSettings));
  deviceEventsBySitePagination$ = this.store.pipe(select(fromNavigation.getDeviceEventsBySitePagination));
  deviceEventsBySiteLogsByDeviceEventId$ = this.store.pipe(select(fromNavigation.getAllDeviceEventsBySiteLogs));
  deviceEventsBySiteLogsByDeviceEventIdLoaded$ = this.store.pipe(select(fromNavigation.getDeviceEventsBySiteLogsLoaded));
  deviceEventBySiteStatus$ = this.store.pipe(select(fromNavigation.getDeviceEventBySiteStatus));

  constructor(
    private readonly store: Store,
    private readonly authorizationService: AuthorizationService,
    private readonly assetEventsApi: AssetEventsApi,
    private readonly deviceEventsApi: DeviceEventsApi,
    private readonly sitesFacade: SitesFacade,
    private readonly assetsFacade: AssetsFacade,
    private readonly devicesFacade: DevicesFacade,
    private readonly graphsFacade: GraphsFacade,
    private readonly contactsFacade: ContactsFacade,
    private readonly storage: LocalStorageService
  ) {}

  /*
  public loadData(type: string, id: string, siteId: string, pageType: 'info' | 'stock' = 'info') {
    this.store.dispatch(NavigationActions.loadData({ origin: type, activeId: id, siteId: siteId, pageType }));
  }
  */

  public selectAsset(siteId: string, assetId: string) {
    this.store.dispatch(NavigationActions.selectAsset({ siteId, assetId }));
  }

  public selectDevice(siteId: string, deviceId: string) {
    this.store.dispatch(NavigationActions.selectDevice({ siteId, deviceId }));
  }

  public loadSiteById(siteId: string) {
    this.sitesFacade.loadSiteById(siteId);
  }

  public loadAssociationsBySiteId(siteId: string) {
    this.sitesFacade.loadAssociationsBySiteId(siteId);
  }

  public loadTagsBySiteId(siteId: string) {
    this.sitesFacade.loadTagsBySiteId(siteId);
  }

  public loadAssetsBySiteId(siteId: string) {
    this.sitesFacade.loadAssetsBySiteId({ limit: 50, page: 0, filters: [{ criteriaKey: 'siteId', value: siteId }] });
  }

  public loadDevicesBySiteId(siteId: string) {
    this.sitesFacade.loadDevicesBySiteId({ limit: 50, page: 0, filters: [{ criteriaKey: 'siteId', value: siteId }] });
  }

  public loadGraphsBySiteId(siteId: string) {
    if (this.authorizationService.applyAuthorization(AuthorizationConcept.GRAPH, AuthorizationType.READ)) {
      this.graphsFacade.loadGraphsBySiteId(siteId);
    }
  }

  public changeStockPage(request: PlatformRequest) {
    this.store.dispatch(NavigationActions.changeStockPage({ request }));
    // this.sitesFacade.loadDevicesBySiteId({ limit: request.limit, page: request.page, filters: request.filters });
  }

  public deleteSite(siteToDelete: Site) {
    this.sitesFacade.deleteSite(siteToDelete);
  }

  public updateSite(siteToUpdate: Site) {
    this.sitesFacade.updateSite(siteToUpdate);
  }

  public addAsset(assetToAdd: Asset) {
    this.assetsFacade.addAsset(assetToAdd);
  }

  public loadContactBySiteId(siteId: string) {
    if (this.authorizationService.applyAuthorization(AuthorizationConcept.CONTACT, AuthorizationType.READ)) {
      this.contactsFacade.loadContacts(siteId);
    }
  }

  public addContact(contact: Contact) {
    this.contactsFacade.addContact(contact);
  }

  public updateContact(contactToUpdate: Contact) {
    this.contactsFacade.updateContact(contactToUpdate);
  }

  public deleteContact(contactToDelete: Contact) {
    this.contactsFacade.deleteContact(contactToDelete);
  }

  public addDevice(deviceToAdd: Device) {
    this.devicesFacade.addDevice(deviceToAdd);
  }

  public updateTagsBySiteId(siteId: string, tagsToUpdate: TagCategory[]) {
    this.sitesFacade.updateTagsBySiteId(siteId, tagsToUpdate);
  }

  public clearSelectedItem(site: Site) {
    this.store.dispatch(NavigationActions.clearSelectedItem({ site }));
  }

  public loadTagsByAssetId(assetId: string) {
    this.assetsFacade.loadTagsByAssetId(assetId);
  }

  loadVariablesByAssetId(assetId: string) {
    this.assetsFacade.loadVariablesByAssetId(assetId);
  }

  loadTagsByDeviceId(deviceId: string) {
    this.devicesFacade.loadTagsByDeviceId(deviceId);
  }

  loadVariablesByDeviceId(deviceId: string) {
    this.devicesFacade.loadVariablesByDeviceId(deviceId);
  }

  loadDeviceCallLogById(deviceId: string) {
    this.devicesFacade.loadDeviceCallLogById(deviceId);
  }

  loadAssetById(assetId: string) {
    this.assetsFacade.loadAssetById(assetId);
  }

  addAssetByTemplateId(assetTemplateId: string, siteId: string) {
    this.assetsFacade.addAssetByTemplateId(assetTemplateId, siteId);
  }

  selectAssetAvecLeSite(selectedAsset: Asset) {
    this.store.dispatch(NavigationActions.selectAssetAvecLeSite({ selectedAsset }));
  }

  selectDeviceAvecLeSite(selectedDevice: Device) {
    this.store.dispatch(NavigationActions.selectDeviceAvecLeSite({ selectedDevice }));
  }

  selectLeSite(selectedSite: Site) {
    this.store.dispatch(NavigationActions.selectLeSite({ selectedSite }));
  }

  selectLeStock(stock: Site, request: PlatformRequest) {
    this.store.dispatch(NavigationActions.selectLeStock({ stock, request }));
  }

  openSiteDetail(site: Site, origin = 'site') {
    if (site.type === 'stock') {
      this.selectLeStock(site, { page: 0, limit: 25, filters: [{ criteriaKey: 'siteId', value: site.id }] });
    } else {
      this.selectLeSite(site);
    }
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, origin);
    this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, site.id ?? '');
  }

  openSiteDetailFromDevice(device: Device, origin = 'device') {
    if (device?.site) {
      if (device.site?.type === 'stock') {
        this.selectLeStock(device.site, { page: 0, limit: 25, filters: [{ criteriaKey: 'siteId', value: device.site.id }] });
      } else {
        this.selectLeSite(device.site);
      }
      this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, origin);
      this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, device.id ?? '');
    }
  }

  openAssetDetail(asset: Asset, origin = 'asset') {
    this.selectAssetAvecLeSite(asset);
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, origin);
    this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, asset?.id ?? '');
  }

  openDeviceDetail(device: Device, origin = 'device') {
    this.selectDeviceAvecLeSite(device);
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, origin);
    this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, device?.id ?? '');
  }

  loadDeviceById(id: string) {
    this.devicesFacade.loadDeviceById(id);
  }

  sendConnectorCommand(device: Device, command: any) {
    this.devicesFacade.sendConnectorCommand(device, command);
  }

  bulkSendConnectorCommand(devices: Device[], command: any) {
    this.devicesFacade.bulkSendConnectorCommand(devices, command);
  }

  updateDevice(deviceToMove: Device) {
    // this.devicesFacade.updateDevice(updated);
    this.store.dispatch(NavigationActions.moveDevice({ deviceToMove }));
  }

  bulkUpdateDevices(devicesToMove: Device[]) {
    this.store.dispatch(NavigationActions.moveDevices({ devicesToMove }));
  }

  moveDevices(devicesToMove: Device[], originSite: Site): void {
    this.devicesFacade.moveDevices(devicesToMove, originSite);
  }

  deleteAsset(asset: Asset) {
    this.assetsFacade.deleteAsset(asset);
  }

  deleteAssetVariables(assetVariables: AssetVariable[]) {
    this.assetsFacade.deleteAssetVariables(assetVariables);
  }

  deleteDeviceVariables(deviceVariables: DeviceVariable[]) {
    this.devicesFacade.deleteVariable(deviceVariables);
  }

  updateAsset(asset: Asset) {
    this.assetsFacade.updateAsset(asset);
  }

  addAssetVariable(assetVariable: AssetVariable) {
    this.assetsFacade.addAssetVariable(assetVariable);
  }

  updateAssetVariable(assetVariable: AssetVariable) {
    this.assetsFacade.updateAssetVariable(assetVariable);
  }

  updateFollowedAssetVariable(assetVariable: AssetVariable) {
    this.assetsFacade.updateFollowedAssetVariable(assetVariable);
  }

  updateTagsByAssetId(id: string, tags: TagCategory[]) {
    this.assetsFacade.updateTagsByAssetId(id, tags);
  }

  assignProductToAsset(product: Product, asset: Asset) {
    this.assetsFacade.assignProductToAsset(product, asset);
  }

  removeProductFromAsset(product: Product, asset: Asset) {
    this.assetsFacade.removeProductFromAsset(product, asset);
  }

  sendAssetCommand(asset: Asset, command: { command: CommandType }) {
    this.assetsFacade.sendCommand(asset, command);
  }

  applyDeviceVariablesFilters(filters: Partial<VariablesTableFilters>) {
    this.store.dispatch(NavigationActions.applyDeviceVariablesTableFilters({ filters }));
  }

  // **** Master View Events By Site **** //

  loadEvents(params: PlatformRequest, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'site') {
      this.store.dispatch(AssetEventsBySiteUiActions.loadAssetEventsBySite({ request: params }));
    }
    if (view === 'asset-events' && filteredBy === 'asset') {
      this.store.dispatch(AssetEventsByAssetUiActions.loadAssetEventsByAsset({ request: params }));
    }
    if (view === 'device-events' && filteredBy === 'site') {
      this.store.dispatch(DeviceEventsBySiteUiActions.loadDeviceEventsBySite({ request: params }));
    }
    if (view === 'device-events' && filteredBy === 'device') {
      this.store.dispatch(DeviceEventsByDeviceUiActions.loadDeviceEventsByDevice({ request: params }));
    }
  }

  loadMetadata(view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'site') {
      this.store.dispatch(AssetEventsBySiteUiActions.loadMvAssetEventsBySiteSettings({ settingName: 'assetEventsBySite' }));
    }
    if (view === 'asset-events' && filteredBy === 'asset') {
      this.store.dispatch(AssetEventsByAssetUiActions.loadMvAssetEventsByAssetSettings({ settingName: 'assetEventsByAsset' }));
    }
    if (view === 'device-events' && filteredBy === 'site') {
      this.store.dispatch(DeviceEventsBySiteUiActions.loadMvDeviceEventsBySiteSettings({ settingName: 'deviceEventsBySite' }));
    }
    if (view === 'device-events' && filteredBy === 'device') {
      this.store.dispatch(DeviceEventsByDeviceUiActions.loadMvDeviceEventsByDeviceSettings({ settingName: 'deviceEventsByDevice' }));
    }
  }

  loadEventDetailPopupDataByEvent(event: AssetEvent | DeviceEvent, view: string, filteredBy: string) {
    if (view === 'asset-events') {
      this.assetEventsApi.loadEventDetailPopupDataByAssetEvent(event);
    }
    if (view === 'device-events') {
      this.deviceEventsApi.loadEventDetailPopupDataByDeviceEvent(event);
    }
    this.loadLogsByEventId(event.id, view, filteredBy);
  }

  saveEventsTableState(tableState: { checked: AssetEvent[]; selected: AssetEvent }, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'site') {
      this.store.dispatch(AssetEventsBySiteUiActions.saveTableStateBySite({ tableState }));
    }
    if (view === 'asset-events' && filteredBy === 'asset') {
      this.store.dispatch(AssetEventsByAssetUiActions.saveTableStateByAsset({ tableState }));
    }
    if (view === 'device-events' && filteredBy === 'site') {
      this.store.dispatch(DeviceEventsBySiteUiActions.saveTableStateBySite({ tableState }));
    }
    if (view === 'device-events' && filteredBy === 'device') {
      this.store.dispatch(DeviceEventsByDeviceUiActions.saveTableStateByDevice({ tableState }));
    }
  }

  updateEventsStatus(eventIds: string[], status: string, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'site') {
      this.store.dispatch(AssetEventsBySiteUiActions.bulkUpdateStatusByAssetEventIdBySite({ assetEventIds: eventIds, status }));
    }
    if (view === 'asset-events' && filteredBy === 'asset') {
      this.store.dispatch(AssetEventsByAssetUiActions.bulkUpdateStatusByAssetEventIdByAsset({ assetEventIds: eventIds, status }));
    }
    if (view === 'device-events' && filteredBy === 'site') {
      this.store.dispatch(DeviceEventsBySiteUiActions.bulkUpdateStatusByDeviceEventIdBySite({ deviceEventIds: eventIds, status }));
    }
    if (view === 'device-events' && filteredBy === 'device') {
      this.store.dispatch(DeviceEventsByDeviceUiActions.bulkUpdateStatusByDeviceEventIdByDevice({ deviceEventIds: eventIds, status }));
    }
  }

  loadLogsByEventId(eventId: string, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'site') {
      this.store.dispatch(AssetEventsBySiteLogsUiActions.loadLogsByAssetEventId({ assetEventId: eventId }));
    }
    if (view === 'asset-events' && filteredBy === 'asset') {
      this.store.dispatch(AssetEventsByAssetLogsUiActions.loadLogsByAssetEventId({ assetEventId: eventId }));
    }
    if (view === 'device-events' && filteredBy === 'site') {
      this.store.dispatch(DeviceEventsBySiteLogsUiActions.loadLogsByDeviceEventId({ deviceEventId: eventId }));
    }
    if (view === 'device-events' && filteredBy === 'device') {
      this.store.dispatch(DeviceEventsByDeviceLogsUiActions.loadLogsByDeviceEventId({ deviceEventId: eventId }));
    }
  }

  createLogByEventId(event: AssetEvent | DeviceEvent, value: string, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'site') {
      this.store.dispatch(AssetEventsBySiteLogsUiActions.createLogByAssetEventId({ comment: { assetEvent: event, value: value } }));
    }
    if (view === 'asset-events' && filteredBy === 'asset') {
      this.store.dispatch(AssetEventsByAssetLogsUiActions.createLogByAssetEventId({ comment: { assetEvent: event, value: value } }));
    }
    if (view === 'device-events' && filteredBy === 'site') {
      this.store.dispatch(DeviceEventsBySiteLogsUiActions.createLogByDeviceEventId({ comment: { deviceEvent: event, value: value } }));
    }
    if (view === 'device-events' && filteredBy === 'device') {
      this.store.dispatch(DeviceEventsByDeviceLogsUiActions.createLogByDeviceEventId({ comment: { deviceEvent: event, value: value } }));
    }
  }

  /* Master View Events By Site END */

  clearData() {
    this.store.dispatch(NavigationActions.clearData());
  }
}
