<section class='site-products'>
  <iot4bos-ui-panel
    [title]="'SITES.PRODUCTS.PANEL_TITLE' | translate"
    [hasButton]='false'
    [withCustomAction]='true'
    [buttonTooltip]="'SITES.PRODUCTS.ADD' | translate"
  >
    <div panelCustomAction fxFlexLayout='row' fxLayoutAlign='center center'>
      <div *ngIf="productsBySite?.length > 4 || (productsBySite?.length > 3 && hasProducts && canAddProduct)" class="panel-button app-round-button" [matTooltip]="(horizontalLayout ? 'PANELS.TOOLTIPS.HORIZONTAL_LAYOUT': 'PANELS.TOOLTIPS.VERTICAL_LAYOUT') | translate" matTooltipClass="regular-tooltip">
        <button mat-icon-button fxHide.lt-md (click)="horizontalLayout = !horizontalLayout">
          <mat-icon>{{ horizontalLayout ? 'apps' : 'swap_horiz' }}</mat-icon>
        </button>
      </div>
      <div *ngIf="!loading && canUpdateSite && canAddProduct && hasProducts" class="panel-button app-round-button" [matTooltip]="'SITES.PRODUCTS.ADD' | translate" matTooltipClass="regular-tooltip">
        <ng-container *ngTemplateOutlet="productsContextMenu"></ng-container>
      </div>
    </div>

    <ng-container panelGrid *ngIf="!loading && !hasAssignedProducts && !hasProducts; else productsTmpl">
      <ng-container *ngTemplateOutlet="emptyProductsTmp"></ng-container>
    </ng-container>

  </iot4bos-ui-panel>
</section>

<ng-template #productsTmpl>
  <div panelGrid *ngIf="loading" fxFlexLayout='row' fxLayoutAlign='center center' style='width: 100%'>
    <mat-progress-spinner color='accent' mode='indeterminate' [diameter]='50'></mat-progress-spinner>
  </div>

  <oyan-ui-cards-container panelGrid [horizontalLayout]="horizontalLayout" *ngIf="!loading">
    <oyan-ui-product-card [withMenu]='canUpdateSite && canDeleteProduct' class='card-xs' fxFlex.lt-md="100%"
                          *ngFor='let product of productsBySite' [element]='product'
                          (dispatchEvent)="onDispatchEvent($event)"
    ></oyan-ui-product-card>

    <iot4bos-ui-default-card *ngIf="canUpdateSite && canAddProduct && hasProducts" class='card-xs' fxFlex.lt-md="100%"
                             [withCustomButton]="true"
                             [backgroundClass]="'h-100'"
                             [title]="'SITES.PRODUCTS.ADD' | translate"
    >
      <ng-container cardCustomButton *ngTemplateOutlet="productsContextMenu"></ng-container>

    </iot4bos-ui-default-card>
  </oyan-ui-cards-container>
</ng-template>

<ng-template #productsContextMenu>
  <oyan-ui-mat-menu-list
    *ngIf='hasProducts'
    [data]='filteredProducts$ | async'
    displayKey='name'
    placeholder="SITES.PRODUCTS.FILTER_PRODUCTS"
    noDataMessage="SITES.PRODUCTS.NO_PRODUCTS"
    (search)="onApplyFilter($event)"
    (clear)="onClearFilter()"
    (selectItem)='onAddProduct($event)'
  ></oyan-ui-mat-menu-list>
</ng-template>

<ng-template #emptyProductsTmp>
  <div class="no-data-message" fxFlexLayout='row' fxFlexAlign='center center'>
    <p>{{ 'SITES.PRODUCTS.NO_PRODUCTS' | translate }}</p>
  </div>
</ng-template>
