import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { ColorPickerService } from './color-picker.service';

@Component({
  selector: 'iot-platform-ui-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit, OnDestroy {
  @Input() preSelectedColor: string;

  @Output() selectColor: EventEmitter<string> = new EventEmitter<string>();

  colors: string[] = [];
  selectedColor: string = null;
  private subs: Subscription[] = [];

  constructor(private colorPickerService: ColorPickerService) {}

  ngOnInit() {
    this.initComponent();
  }

  initComponent(): void {
    this.subs.push(
      this.colorPickerService.getColorList().subscribe((list: string[]) => {
        this.colors = list;

        if (this.preSelectedColor && this.colors.includes(this.preSelectedColor)) {
          this.onSelect(this.preSelectedColor);
          this.selectedColor = this.preSelectedColor;
        }
      }),
      this.colorPickerService.getDefaultColor().subscribe((color: string) => {
        if (!(this.preSelectedColor && this.colors.includes(this.preSelectedColor))) {
          this.onSelect(color);
        }
      })
    );
  }

  onSelect(color: string) {
    this.selectedColor = color;
    this.selectColor.emit(this.selectedColor);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
