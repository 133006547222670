import { PlatformResponse } from '@iot-platform/models/common';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromDeviceEventsDb from './device-events-db.reducer';
import * as fromDeviceEventsLogsDb from './device-events-logs-db.reducer';
import * as fromDeviceEventsLogsUi from './device-events-logs-ui.reducer';
import * as fromDeviceEventsUi from './device-events-ui.reducer';

export const deviceEventsFeatureKey = 'deviceEvents';

export interface DeviceEventsState {
  [fromDeviceEventsDb.deviceEventsDbFeatureKey]: fromDeviceEventsDb.State;
  [fromDeviceEventsUi.deviceEventsUiFeatureKey]: fromDeviceEventsUi.State;
  [fromDeviceEventsLogsDb.deviceEventsLogsDbFeatureKey]: fromDeviceEventsLogsDb.State;
  [fromDeviceEventsLogsUi.deviceEventsLogsUiFeatureKey]: fromDeviceEventsLogsUi.State;
}

export interface State {
  [deviceEventsFeatureKey]: DeviceEventsState;
}

export function reducers(state: DeviceEventsState | undefined, action: Action) {
  return combineReducers({
    [fromDeviceEventsDb.deviceEventsDbFeatureKey]: fromDeviceEventsDb.reducer,
    [fromDeviceEventsUi.deviceEventsUiFeatureKey]: fromDeviceEventsUi.reducer,
    [fromDeviceEventsLogsDb.deviceEventsLogsDbFeatureKey]: fromDeviceEventsLogsDb.reducer,
    [fromDeviceEventsLogsUi.deviceEventsLogsUiFeatureKey]: fromDeviceEventsLogsUi.reducer
  })(state, action);
}

export const selectDeviceEventsState = createFeatureSelector<DeviceEventsState>(deviceEventsFeatureKey);

export const selectDeviceEventsDbState = createSelector(
  selectDeviceEventsState,
  (state: DeviceEventsState) => state[fromDeviceEventsDb.deviceEventsDbFeatureKey]
);
export const selectDeviceEventsUiState = createSelector(
  selectDeviceEventsState,
  (state: DeviceEventsState) => state[fromDeviceEventsUi.deviceEventsUiFeatureKey]
);
export const selectDeviceEventsLogsDbState = createSelector(
  selectDeviceEventsState,
  (state: DeviceEventsState) => state[fromDeviceEventsLogsDb.deviceEventsLogsDbFeatureKey]
);
export const selectDeviceEventsLogsUiState = createSelector(
  selectDeviceEventsState,
  (state: DeviceEventsState) => state[fromDeviceEventsLogsUi.deviceEventsLogsUiFeatureKey]
);

export const {
  selectIds: getDeviceEventsIds,
  selectEntities: getDeviceEventsEntities,
  selectAll: getAllDeviceEvents,
  selectTotal: getTotalDeviceEvents
} = fromDeviceEventsDb.adapter.getSelectors(selectDeviceEventsDbState);

export const {
  selectIds: getLogsIds,
  selectEntities: getLogsEntities,
  selectAll: getAllLogs,
  selectTotal: getTotalLogs
} = fromDeviceEventsLogsDb.adapter.getSelectors(selectDeviceEventsLogsDbState);

export const getSelectedDeviceEventId = createSelector(selectDeviceEventsDbState, fromDeviceEventsDb.getSelectedId);

export const getSelectedDeviceEvent = createSelector(
  getDeviceEventsEntities,
  getSelectedDeviceEventId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

// ***** DATA
export const getSite = createSelector(selectDeviceEventsDbState, fromDeviceEventsDb.getSite);
export const getAsset = createSelector(selectDeviceEventsDbState, fromDeviceEventsDb.getAsset);
export const getAssetVariable = createSelector(selectDeviceEventsDbState, fromDeviceEventsDb.getAssetVariable);
export const getDevice = createSelector(selectDeviceEventsDbState, fromDeviceEventsDb.getDevice);
export const getDeviceVariable = createSelector(selectDeviceEventsDbState, fromDeviceEventsDb.getDeviceVariable);

export const getTags = createSelector(selectDeviceEventsDbState, fromDeviceEventsDb.getTags);

export const getCheckedIds = createSelector(selectDeviceEventsDbState, fromDeviceEventsDb.getCheckedIds);
export const getTableState = createSelector(
  selectDeviceEventsDbState,
  getAllDeviceEvents,
  getSelectedDeviceEvent,
  getCheckedIds,
  (state, allEvents, selected, checkedIds) => {
    return { selected, checked: allEvents.filter((event) => checkedIds.find((c) => c === event.id)) };
  }
);

export const getLog = createSelector(selectDeviceEventsLogsDbState, fromDeviceEventsLogsDb.getLog);

export const getMVSettings = createSelector(selectDeviceEventsDbState, (state: fromDeviceEventsDb.State) => state.settings);
export const getStatus = createSelector(selectDeviceEventsDbState, fromDeviceEventsDb.getStatus);
export const getPagination = createSelector(selectDeviceEventsDbState, getMVSettings, (state, settings) => {
  return settings && settings['masterViewTable']['bluePrint'].pageSize
    ? { ...state.pagination, limit: settings['masterViewTable']['bluePrint'].pageSize.toString() }
    : state.pagination;
});
export const getAutoRefresh = createSelector(getMVSettings, (settings) => {
  return settings ? settings['masterViewTable']['bluePrint'].autoRefresh : false;
});

export const getRefreshDelay = createSelector(getMVSettings, (settings) => {
  return settings ? settings['masterViewTable']['bluePrint'].refreshDelay : 120;
});
export const getInitialSort = createSelector(selectDeviceEventsDbState, (state: fromDeviceEventsDb.State) => state.initialSort);

export const getFormattedDeviceEvents = createSelector(getAllDeviceEvents, getPagination, getInitialSort, (data, pagination, initialSort) => {
  const response: PlatformResponse = {
    data: data,
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total,
    initialSort
  };
  return response;
});

// ***** UI
export const getError = createSelector(selectDeviceEventsUiState, fromDeviceEventsUi.getSiteLoaded);

export const getDeviceEventsLoaded = createSelector(selectDeviceEventsUiState, fromDeviceEventsUi.getDeviceEventsLoaded);
export const getDeviceEventsLoading = createSelector(selectDeviceEventsUiState, fromDeviceEventsUi.getDeviceEventsLoading);
export const getSiteLoaded = createSelector(selectDeviceEventsUiState, fromDeviceEventsUi.getSiteLoaded);
export const getSiteLoading = createSelector(selectDeviceEventsUiState, fromDeviceEventsUi.getSiteLoading);
export const getAssetLoaded = createSelector(selectDeviceEventsUiState, fromDeviceEventsUi.getAssetLoaded);
export const getAssetLoading = createSelector(selectDeviceEventsUiState, fromDeviceEventsUi.getAssetLoading);
export const getAssetVariableLoaded = createSelector(selectDeviceEventsUiState, fromDeviceEventsUi.getAssetVariableLoaded);
export const getAssetVariableLoading = createSelector(selectDeviceEventsUiState, fromDeviceEventsUi.getAssetVariableLoading);
export const getDeviceLoaded = createSelector(selectDeviceEventsUiState, fromDeviceEventsUi.getDeviceLoaded);
export const getDeviceLoading = createSelector(selectDeviceEventsUiState, fromDeviceEventsUi.getDeviceLoading);
export const getDeviceVariableLoaded = createSelector(selectDeviceEventsUiState, fromDeviceEventsUi.getDeviceVariableLoaded);
export const getDeviceVariableLoading = createSelector(selectDeviceEventsUiState, fromDeviceEventsUi.getDeviceVariableLoading);
export const getTagsLoaded = createSelector(selectDeviceEventsUiState, fromDeviceEventsUi.getTagsLoaded);
export const getLogsLoaded = createSelector(selectDeviceEventsLogsUiState, fromDeviceEventsLogsUi.getLogsLoaded);
