<section class="device-info-toolbar" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center">
  <div class="buttons-row" fxLayout="row">
    <div class="buttons-col" fxLayout="column">
      <div
        class="device-info-toolbar-status-card"
        fxLayout="row"
        fxLayoutAlign="start center"
        [ngClass]="{ failure: deviceStatus === 'cCF', notDefined: deviceStatus === 'undefined', warning: deviceStatus === 'warning' }"
      >
        <!-- <iot-platform-ui-icon
          *ngIf="deviceStatus && deviceStatus !== 'undefined'"
          [name]="iconToDisplay.name"
          [color]="iconToDisplay.color"
        ></iot-platform-ui-icon>-->
        <mat-icon *ngIf="deviceStatus && deviceStatus !== 'undefined'" [svgIcon]="iconToDisplay.name" class="iconToDisplay.color"></mat-icon>

        <div [ngSwitch]="deviceStatus" fxLayout="column" fxLayoutAlign="start center">
          <span class="device-info-toolbar-status-undefined" *ngSwitchCase="'undefined'">{{ 'DEVICES.INFO_TOOLBAR.HAS_NOT_CALLED_YET' | translate }}</span>
          <span class="device-info-toolbar-status-datetime" *ngSwitchCase="'success'">
            {{ 'DEVICES.INFO_TOOLBAR.LAST_INCOMING_CALL' | translate }}{{ device.lastCallStatus?.datetime | dateFormat }}
          </span>
          <span class="device-info-toolbar-status-datetime" *ngSwitchCase="'cCF'">
            {{ 'DEVICES.INFO_TOOLBAR.LAST_INCOMING_CALL' | translate }}{{ device.lastCallStatus?.datetime | dateFormat }}
          </span>
          <span class="device-info-toolbar-status-warning" *ngSwitchCase="'warning'">
            {{ 'DEVICES.INFO_TOOLBAR.LAST_INCOMING_CALL' | translate }}{{ device.lastCallStatus?.datetime | dateFormat }}
          </span>
        </div>
      </div>
    </div>
  </div>
</section>
