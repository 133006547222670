import { Injectable } from '@angular/core';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { User } from '@iot-platform/models/common';

import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { TranslateService } from '@ngx-translate/core';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { UserPreferencesService } from '../../services/user-preferences.service';
import { PreferencesActions } from '../actions';

@Injectable()
export class PreferencesEffects {
  loadUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferencesActions.loadUserPreferences),
      switchMap((action) =>
        this.userPrefService.loadPreferences().pipe(
          map((user) => PreferencesActions.loadUserPreferencesSuccess({ user: user, preferences: user.preferences })),
          catchError((error) => of(PreferencesActions.loadUserPreferencesFailure({ error })))
        )
      )
    )
  );

  saveUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreferencesActions.saveUserPreferences),
      switchMap((action) =>
        this.userPrefService.saveUserPreferences(action.user).pipe(
          map((user: User) => {
            this.storage.set(LocalStorageKeys.STORAGE_USER_PREFERENCES, JSON.stringify(user.preferences));
            return PreferencesActions.saveUserPreferencesSuccess({ user: user, preferences: user.preferences });
          }),
          catchError((error) => of(PreferencesActions.saveUserPreferencesFailure({ error })))
        )
      )
    )
  );

  changeLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PreferencesActions.changeLanguage),
        tap(({ lang }) => {
          if (lang && this.translateService.langs.includes(lang)) {
            this.translateService.currentLang = '';
            this.translateService.use(lang);
          }
        })
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PreferencesActions.saveUserPreferencesSuccess),
        // map(({ preferences }) => PreferencesActions.changeLanguage({ lang: preferences.appLanguage })),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PreferencesActions.saveUserPreferencesFailure),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PreferencesActions.saveUserPreferences),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PreferencesActions.saveUserPreferencesSuccess, PreferencesActions.saveUserPreferencesFailure),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private userPrefService: UserPreferencesService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private readonly storage: LocalStorageService
  ) {}
}
