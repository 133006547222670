<button
  (click)="click.emit()"
  [ngClass]="{ subscribed: isCurrentUserSubscribed, unsubscribed: !isCurrentUserSubscribed }"
  [matTooltip]="isCurrentUserSubscribed ? ('TOPICS.SHELL.UNSUBSCRIBE' | translate) : ('TOPICS.SHELL.SUBSCRIBE' | translate)"
  matTooltipClass="regular-tooltip"
  matTooltipPosition="above"
  class="regular-round-button"
  mat-icon-button
>
  <mat-icon [svgIcon]="'subscribe'"></mat-icon>
</button>
