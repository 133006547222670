<div fxLayoutAlign="start center">
  <span class="number-cell-value" *ngIf="!params?.value">-</span>
  <span
    class="number-cell-value"
    [matTooltip]="params?.value | numberFormat"
    matTooltipClass="regular-tooltip"
    matTooltipPosition="above"
    *ngIf="params?.value && !params?.isLink && !params?.unit"
    >{{ params?.value | numberFormat }}</span
  >
</div>
