  <ul *ngIf="total > 0" style="overflow: auto; max-height: 224px; ">
    <li
      (click)="onClick(device)"
      *ngFor="let device of devices"
      class="detail-devices-card-item"
      fxLayout="row"
      fxLayoutAlign="start center"
      matRipple
      matRippleColor="rgba(241,249,249,0.4)"
    >
     <mat-icon class="detail-devices-card-icon" color="#394c5a" svgIcon="device"></mat-icon>
      <div class="detail-devices-card-container">
        <span class="detail-devices-card-name">{{ device?.name }}</span>
        <span class="detail-devices-card-type">{{ device?.type?.family }} - {{ device?.type?.model }}</span>
      </div>
    </li>
  </ul>

<div *ngIf="total === 0" class="detail-devices-card">
  <div class="detail-devices-card-empty" fxLayout="row" fxLayoutAlign="center center">{{ 'DETAIL_DEVICES_CARD_COMPONENT.NO_DATA' | translate }} {{ 'IOT_DICTIONARY.device' | translate }}</div>
</div>
