<div data-cy='iot-platform-ui-date-interval-field' fxFlex fxLayout="row wrap" fxLayoutAlign="space-between center">
  <iot-platform-ui-date-range
    data-cy='iot-platform-ui-date-interval-field-date-range'
    fxFlex="90%"
    fxLayoutAlign="space-around center"
    placeholderFrom='FILTER_ENGINE.DATE_PLACEHOLDER_FROM'
    placeholderTo='FILTER_ENGINE.DATE_PLACEHOLDER_TO'
    placeholderHours='FILTER_ENGINE.DATE_PLACEHOLDER_HOURS'
    placeholderMinutes='FILTER_ENGINE.DATE_PLACEHOLDER_MINUTES'
    placeholderClear='FILTER_ENGINE.CLEAR'
    [dateFormatterPattern]='data?.dateFormatterPattern'
    [clearDateRangeFilters$]='clearDateRangeFilters$'
    (dateRangeChange)='onDateRangeChange($event)'>
  </iot-platform-ui-date-range>
  <button
    data-cy='iot-platform-ui-date-interval-field-add-btn'
    mat-icon-button
    color="accent"
    [disabled]="!startDate && !endDate"
    (click)="onValidation()"
  >
    <mat-icon>add_circle</mat-icon>
  </button>
</div>
