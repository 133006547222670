<div class="popup-content" fxLayout="column" style="width: 800px; padding: 16px 16px 0 16px">
  <div fxFlex fxLayout="row" fxLayoutAlign="center center">
    <iot-platform-ui-card-loader *ngIf="isLoading" [backgroundClass]="'h-140'" fxFlex></iot-platform-ui-card-loader>
  </div>

  <div *ngIf="!isLoading">
    <p class="basic-info" fxLayout="row" fxLayoutAlign="space-between center">
      <span>
        {{ data?.assetVariable?.name }}
      </span>
      <span *ngIf="isMultiStateVariable" class="value">
        {{ processedValue }}
      </span>
      <span *ngIf="!isMultiStateVariable" class="value"> {{ data?.assetVariable?.lastRecords[0]?.value | numberFormat }} {{ data?.assetVariable?.unit }} </span>
    </p>
    <p fxLayout="row" fxLayoutAlign="space-between center">
      <span>
        {{ data?.assetVariable?.comment }}
      </span>
      <span>
        {{ data?.assetVariable?.lastRecords[0]?.datetime | dateFormat }}
      </span>
    </p>
    <div fxLayout="column">
      <iot-platform-ui-detail-popup-section-header
        headerTitle="Formula"
        style="padding-bottom: 8px; padding-top: 8px"
      ></iot-platform-ui-detail-popup-section-header>

      <div class="main-block" fxLayout="column">
        <div fxLayout="column">
          <div class="inner-block" fxLayout="column">
            <p>
              <span class="label" fxFlex="30">Model:</span>
              <span class="value"> {{ assetVariable.formula?.model | infoDisplay }}</span>
            </p>
          </div>
          <div class="inner-block" fxLayout="column">
            <p *ngFor="let parameter of parameters" fxFlex fxLayout="row">
              <span class="label" fxFlex="30">{{ parameter.key }}</span>
              <span class="value">{{ parameter.value | numberFormat | infoDisplay }}</span>
            </p>
          </div>
        </div>
      </div>

      <div *ngIf="displaySourceDeviceVariable || displaySourceAssetVariables" fxLayout="column">
        <iot-platform-ui-detail-popup-section-header
          headerTitle="Selected variable(s)"
          style="padding-bottom: 10px; padding-top: 10px"
        ></iot-platform-ui-detail-popup-section-header>

        <div class="main-block" fxLayout="column">
          <div class="inner-block" fxLayout="column">
            <p>
              <span class="label" fxFlex="30">{{ displaySourceAssetVariables ? 'Asset name: ' : 'Device name: ' }}</span>
              <span class="value">{{ displaySourceAssetVariables ? variablesToDisplay[0]?.asset.name : variablesToDisplay[0]?.device.name }}</span>
            </p>
          </div>
          <iot-platform-ui-detail-popup-section-header
            headerTitle=""
            style="padding-bottom: 10px; padding-top: 10px"
          ></iot-platform-ui-detail-popup-section-header>

          <div fxLayout="row">
            <p fxFlex="30" fxLayout="column">
              <span class="label">Name</span>
            </p>

            <p fxFlex="20" fxLayout="column">
              <span class="label">Value</span>
            </p>
            <p fxFlex="20" fxLayout="column">
              <span class="label">Time Stamp</span>
            </p>
            <p fxFlex="30" fxLayout="column">
              <span class="label">Description</span>
            </p>
          </div>
          <div *ngFor="let formulaVariable of variablesToDisplay">
            <div class="inner-block" fxLayout="column">
              <div fxLayout="row">
                <p fxFlex="30" fxLayout="column">
                  <span class="value">{{ formulaVariable.name | infoDisplay }}</span>
                </p>

                <p fxFlex="20" fxLayout="column">
                  <span *ngIf="isMultiStateVariable" class="value">
                    {{ processedValue }}
                  </span>
                  <span *ngIf="!isMultiStateVariable" class="value">
                    {{ formulaVariable.lastValue.value | numberFormat | infoDisplay }} {{ formulaVariable.unit | infoDisplay }}
                  </span>
                </p>
                <p fxFlex="20" fxLayout="column">
                  <span class="label">{{ formulaVariable.lastValue.datetime | dateFormat | infoDisplay }}</span>
                </p>
                <p fxFlex="30" fxLayout="column">
                  <span class="label">{{ formulaVariable.comment | truncate: [20, '...'] | infoDisplay }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column">
          <iot-platform-ui-detail-popup-section-header
            headerTitle="Threshold(s)"
            style="padding-bottom: 10px; padding-top: 10px"
          ></iot-platform-ui-detail-popup-section-header>
          <div *ngIf="assetVariable.thresholds?.values.length === 0" class="main-block" fxLayout="column" style="margin-bottom: 16px">
            <p fxFlex fxLayout="column" fxLayoutAlign="start center">
              <span class="value" fxFlex>No thresholds</span>
            </p>
          </div>

          <div *ngIf="assetVariable.thresholds?.values.length > 0" class="main-block" fxLayout="column" style="margin-bottom: 16px">
            <div fxLayout="column">
              <p class="inner-block" fxFlex fxLayout="row">
                <span class="label" fxFlex="30">Operator</span>
                <span class="value">{{ getTextualOperator(assetVariable.thresholds?.operator) }}</span>
              </p>
              <iot-platform-ui-detail-popup-section-header
                headerTitle=""
                style="padding-bottom: 10px; padding-top: 10px"
              ></iot-platform-ui-detail-popup-section-header>

              <div class="inner-block" fxFlex fxLayout="column">
                <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                  <p fxFlex="30" fxLayout="column" fxLayoutAlign="center start">
                    <span class="label" fxFlex>Name</span>
                  </p>
                  <p fxFlex="20" fxLayout="column" fxLayoutAlign="center center">
                    <span class="label" fxFlex>Value</span>
                  </p>
                  <p fxFlex="15" fxLayout="column" fxLayoutAlign="center center">
                    <span class="label" fxFlex>Position</span>
                  </p>
                  <p fxFlex="10" fxLayout="column" fxLayoutAlign="center center">
                    <span class="label" fxFlex>Color</span>
                  </p>
                  <p fxFlex="25" fxLayout="column" fxLayoutAlign="center center">
                    <span class="label" fxFlex>Dynamic</span>
                  </p>
                </div>
                <div *ngFor="let threshold of assetVariable.thresholds?.values" fxFlex fxLayout="row" fxLayoutAlign="start center">
                  <p fxFlex="30" fxLayout="column" fxLayoutAlign="center start">
                    <span class="value">{{ threshold.name | infoDisplay }}</span>
                  </p>
                  <p fxFlex="20" fxLayout="column" fxLayoutAlign="center center">
                    <span class="value" fxFlex>{{ threshold.value | numberFormat | infoDisplay }}</span>
                  </p>
                  <p fxFlex="15" fxLayout="column" fxLayoutAlign="center center">
                    <span class="value" fxFlex>{{ threshold.position | infoDisplay }}</span>
                  </p>
                  <p fxFlex="10" fxLayout="column" fxLayoutAlign="center center">
                    <span [style.background-color]="threshold.lineColor" class="color"> </span>
                  </p>
                  <p fxFlex="25" fxLayout="column" fxLayoutAlign="center center">
                    <span class="value" fxFlex>{{ threshold.calculate ? threshold.calculate.formulaName : ('-' | infoDisplay) }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <iot-platform-ui-detail-popup-metadata-footer [data]="metadata$ | async" style="padding-bottom: 10px"></iot-platform-ui-detail-popup-metadata-footer>
    </div>
  </div>
</div>
