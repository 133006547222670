import { Action, combineReducers } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import * as fromSiteContacts from './site-contacts.reducer';
import * as fromSiteGateways from './site-gateways.reducer';
import * as fromSiteProducts from './site-products.reducer';
import * as fromSiteWards from './site-wards.reducer';

import * as fromSite from './site.reducer';

export const featureKey = 'oyanSites';

export interface SitesState {
  [fromSite.featureKey]: fromSite.State;
  [fromSiteContacts.featureKey]: fromSiteContacts.State;
  [fromSiteGateways.featureKey]: fromSiteGateways.State;
  [fromSiteProducts.featureKey]: fromSiteProducts.State;
  [fromSiteWards.featureKey]: fromSiteWards.State;
}

export interface State {
  [featureKey]: SitesState;
}

export function reducers(state: SitesState, action: Action): ActionReducer<SitesState> | unknown {
  return combineReducers({
    [fromSite.featureKey]: fromSite.reducer,
    [fromSiteContacts.featureKey]: fromSiteContacts.reducer,
    [fromSiteGateways.featureKey]: fromSiteGateways.reducer,
    [fromSiteProducts.featureKey]: fromSiteProducts.reducer,
    [fromSiteWards.featureKey]: fromSiteWards.reducer
  })(state, action);
}
