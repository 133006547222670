import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { UserPreferencesService } from '../../../../../../../users/src/lib/features/preferences/services/user-preferences.service';
import { SchedulersService } from '../../services/schedulers.service';

import { SchedulersDbActions, SchedulersUiActions } from '../actions';

@Injectable()
export class SchedulersEffects {
  listAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulersUiActions.listSchedulers),
      switchMap((action) =>
        this.schedulersService.getSchedulers(action.entityId, action.request).pipe(
          map((response) => SchedulersDbActions.listSchedulersSuccess({ response })),
          catchError((error) => of(SchedulersDbActions.listSchedulersFailure({ error })))
        )
      )
    )
  );

  getScheduler$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulersUiActions.getScheduler),
      switchMap((action) =>
        this.schedulersService.getOne(action.entityId, action.schedulerId).pipe(
          map((selectedScheduler) => SchedulersDbActions.getSchedulerSuccess({ selectedScheduler })),
          catchError((error) => of(SchedulersDbActions.getSchedulerFailure({ error })))
        )
      )
    )
  );

  launch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulersUiActions.launchNow),
      switchMap((action) =>
        this.schedulersService.launchNow(action.toLaunch).pipe(
          map((launched) => SchedulersDbActions.launchNowSuccess({ launched })),
          catchError((error) => of(SchedulersDbActions.launchNowFailure({ error })))
        )
      )
    )
  );

  addScheduler$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulersUiActions.addScheduler),
      switchMap((action) =>
        this.schedulersService.addOne(action.schedulerToAdd).pipe(
          map((addedScheduler) => SchedulersDbActions.addSchedulerSuccess({ addedScheduler })),
          catchError((error) => of(SchedulersDbActions.addSchedulerFailure({ error })))
        )
      )
    )
  );

  updateScheduler$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulersUiActions.updateScheduler),
      switchMap((action) =>
        this.schedulersService.updateOne(action.schedulerToUpdate).pipe(
          map((updatedScheduler) => SchedulersDbActions.updateSchedulerSuccess({ updatedScheduler })),
          catchError((error) => of(SchedulersDbActions.updateSchedulerFailure({ error })))
        )
      )
    )
  );

  manageDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulersUiActions.manageDevices),
      switchMap((action) =>
        this.schedulersService.manageDevices(action.schedulerToUpdate, action.idsToAdd, action.idsToRemove).pipe(
          map((updatedScheduler) => SchedulersDbActions.updateSchedulerSuccess({ updatedScheduler })),
          catchError((error) => of(SchedulersDbActions.updateSchedulerFailure({ error })))
        )
      )
    )
  );

  deleteScheduler$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulersUiActions.deleteScheduler),
      switchMap((action) =>
        this.schedulersService.deleteOne(action.schedulerToDelete).pipe(
          map((deletedScheduler) => SchedulersDbActions.deleteSchedulerSuccess({ deletedScheduler })),
          catchError((error) => of(SchedulersDbActions.deleteSchedulerFailure({ error })))
        )
      )
    )
  );

  deleteSchedulerSuccessThenNavigateToMV$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SchedulersDbActions.deleteSchedulerSuccess),
        tap((action) => this.router.navigate(['schedulers']))
      ),
    { dispatch: false }
  );

  loadAvailableDevicesForScheduler$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulersUiActions.loadAvailableDevicesForScheduler),
      switchMap((action) =>
        this.schedulersService.loadAvailableDevicesForScheduler(action.scheduler, action.filters, action.page).pipe(
          map((response) => SchedulersDbActions.loadAvailableDevicesForSchedulerSuccess({ devices: response })),
          catchError((error) => of(SchedulersDbActions.loadAvailableDevicesForSchedulerFailure({ error })))
        )
      )
    )
  );

  loadSelectedDevicesForScheduler$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulersUiActions.loadSelectedDevicesForScheduler),
      switchMap((action) =>
        this.schedulersService.loadSelectedDevicesForScheduler(action.scheduler, action.filters, action.page).pipe(
          map((response) => SchedulersDbActions.loadSelectedDevicesForSchedulerSuccess({ devices: response })),
          catchError((error) => of(SchedulersDbActions.loadSelectedDevicesForSchedulerFailure({ error })))
        )
      )
    )
  );

  loadTotalAvailableDevicesForScheduler$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulersUiActions.loadTotalAvailableDevicesForScheduler),
      switchMap((action) =>
        this.schedulersService.loadTotalAvailableDevicesForScheduler(action.scheduler).pipe(
          map((totalDevices) => SchedulersDbActions.loadTotalAvailableDevicesForSchedulerSuccess({ totalDevices })),
          catchError((error) => of(SchedulersDbActions.loadTotalAvailableDevicesForSchedulerFailure({ error })))
        )
      )
    )
  );

  loadTotalSelectedDevicesForScheduler$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulersUiActions.loadTotalSelectedDevicesForScheduler),
      switchMap((action) =>
        this.schedulersService.loadTotalSelectedDevicesForScheduler(action.scheduler).pipe(
          map((totalDevices) => SchedulersDbActions.loadTotalSelectedDevicesForSchedulerSuccess({ totalDevices })),
          catchError((error) => of(SchedulersDbActions.loadTotalSelectedDevicesForSchedulerFailure({ error })))
        )
      )
    )
  );

  loadMVSchedulersSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulersUiActions.loadMVSettings),
      switchMap((action) =>
        this.userPrefService.loadActiveSettings(action.settingName).pipe(
          map((settings) => {
            return SchedulersDbActions.loadMVSettingsSuccess({ settings });
          }),
          catchError((error) => of(SchedulersDbActions.loadMVSettingsFailure({ error: error })))
        )
      )
    )
  );

  loadSchedulerSelectedDevicesMVSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulersUiActions.loadSchedulerSelectedDevicesMVSettings),
      switchMap((action) =>
        this.userPrefService.loadActiveSettings(action.settingName).pipe(
          map((settings) => {
            return SchedulersDbActions.loadSchedulerSelectedDevicesMVSettingsSuccess({ settings });
          }),
          catchError((error) => of(SchedulersDbActions.loadSchedulerSelectedDevicesMVSettingsFailure({ error: error })))
        )
      )
    )
  );

  loadSchedulerAvailableDevicesMVSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulersUiActions.loadSchedulerAvailableDevicesMVSettings),
      switchMap((action) =>
        this.userPrefService.loadActiveSettings(action.settingName).pipe(
          map((settings) => {
            return SchedulersDbActions.loadSchedulerAvailableDevicesMVSettingsSuccess({ settings });
          }),
          catchError((error) => of(SchedulersDbActions.loadSchedulerAvailableDevicesMVSettingsFailure({ error: error })))
        )
      )
    )
  );

  navigateToSchedulerDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulersUiActions.navigateToSchedulerDetails),
      tap((action) => this.router.navigate(['schedulers', action.selectedScheduler.id, action.selectedScheduler.entity.id])),
      map((action) => SchedulersUiActions.navigateToSchedulerDetailsSuccess({ tabIndex: action.tabIndex }))
    )
  );

  navigateToNewlyAddedScheduler$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SchedulersDbActions.addSchedulerSuccess),
        tap((action) => {
          this.router.navigate(['schedulers', action.addedScheduler.id, action.addedScheduler.entity.id]);
        })
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SchedulersDbActions.addSchedulerSuccess,
          SchedulersDbActions.updateSchedulerSuccess,
          SchedulersDbActions.deleteSchedulerSuccess,
          SchedulersDbActions.launchNowSuccess
        ),
        tap((action) => {
          return this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SchedulersDbActions.addSchedulerFailure,
          SchedulersDbActions.updateSchedulerFailure,
          SchedulersDbActions.listSchedulersFailure,
          SchedulersDbActions.deleteSchedulerFailure,
          SchedulersDbActions.getSchedulerFailure,
          SchedulersDbActions.launchNowFailure
        ),
        tap((action) => this.notificationService.displayError(action.type + ' ' + action.error.error.error))
      ),
    { dispatch: false }
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SchedulersUiActions.listSchedulers,
          SchedulersUiActions.addScheduler,
          SchedulersUiActions.updateScheduler,
          SchedulersUiActions.deleteScheduler,
          SchedulersUiActions.getScheduler,
          SchedulersUiActions.launchNow,
          SchedulersUiActions.manageDevices
        ),
        tap(() => {
          this.notificationService.showLoader();
        })
      ),
    { dispatch: false }
  );

  hideLoaderAfterResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SchedulersDbActions.listSchedulersSuccess,
          SchedulersDbActions.listSchedulersFailure,
          SchedulersDbActions.getSchedulerSuccess,
          SchedulersDbActions.getSchedulerFailure,
          SchedulersDbActions.addSchedulerSuccess,
          SchedulersDbActions.addSchedulerFailure,
          SchedulersDbActions.updateSchedulerSuccess,
          SchedulersDbActions.updateSchedulerFailure,
          SchedulersDbActions.deleteSchedulerSuccess,
          SchedulersDbActions.deleteSchedulerFailure,
          SchedulersDbActions.launchNowSuccess,
          SchedulersDbActions.launchNowFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly schedulersService: SchedulersService,
    private readonly notificationService: NotificationService,
    private readonly userPrefService: UserPreferencesService,
    private readonly router: Router
  ) {}
}
