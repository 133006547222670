<div class="sticky">
  <iot-platform-ui-toolbar-v2
    [total]="totalAssetEvents$ | async"
    [name]="'EVENTS.ASSET_EVENTS' | translate"
    [favoriteViewConfiguration]="assetEventFavoriteViewsConfiguration$ | async"
    [gridsConfiguration]="assetEventGridsConfiguration$ | async"
    [buttonList]="masterViewAssetEventsButtonList"
    [autoRefresh]="{
      counter: 100 - ((timerValue$ | async) / (assetEventGridsConfiguration$ | async)?.currentGrid?.gridOptions?.autoRefresh?.delay) * 100,
      timeLeft: (assetEventGridsConfiguration$ | async)?.currentGrid?.gridOptions?.autoRefresh?.delay - (timerValue$ | async),
      displaySpinner: (assetEventGridsConfiguration$ | async)?.currentGrid?.gridOptions?.autoRefresh?.enabled
    }"
    [isDataLoaded]="assetEventsLoaded$ | async"
    [size]="toolbarSize"
    (dispatchToolbarEvent)="onToolbarEvent($event)"
  >
  </iot-platform-ui-toolbar-v2>

  <iot-platform-ui-filter-engine-container
    [masterView]="'asset-events'"
    [expanded]="filterEngineOpened"
    [displayActionButtons]="true"
    [withFavoriteFilters]="true"
    [currentFavoriteView$]="currentFavoriteView$"
    [currentFilters$]="currentFilters$"
    (applyFilters)="onApplyFilters($event)"
  >
  </iot-platform-ui-filter-engine-container>
</div>

<mat-sidenav-container (backdropClick)="onCloseComments()" hasBackdrop="true">
  <mat-sidenav-content class="layout-master-view-engine-container">
    <grid-engine-grid-manager
      [grid]="grid$ | async"
      [gridSort]="gridSort$ | async"
      [userPermissions]="userPermissions"
      [currentFilters]="currentFilters$ | async"
      [visibleNodeId]="selectedRowId"
      (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
      (timerValueChanged)="_timerValue$.next($event)"
    ></grid-engine-grid-manager>
  </mat-sidenav-content>

  <mat-sidenav class="sidenav" #sidenav position="end" disableClose>
    <iot4bos-ui-event-comments
      [logsByEvent]="logsByAssetEvent$ | async"
      [resetLogs$]="resetLogs$"
      [canUpdateEvent]="canUpdateEvent"
      (addComment)="onAddComment($event)"
      (closeComments)="onCloseComments()"
    >
    </iot4bos-ui-event-comments>
  </mat-sidenav>
</mat-sidenav-container>
