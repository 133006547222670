import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { Subscriber } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { TopicsSubscribedProtocolsDb, TopicsSubscribedProtocolsUi } from '../actions';
//
export const topicsSubscribedProtocolsDbFeatureKey = 'topicsSubscribedProtocolsDb';

export interface State extends EntityState<Subscriber> {
  selectedProtocolId: string;

  error: any;
}

export const adapter: EntityAdapter<Subscriber> = createEntityAdapter<Subscriber>({
  selectId: (subscriber: Subscriber) => subscriber.escalationProtocol.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedProtocolId: null,
  error: null
});

export const topicsSubscribedProtocolsDbReducer = createReducer(
  initialState,
  on(TopicsSubscribedProtocolsUi.listProtocolSubscribedToTopicByTopicId, (state: State) => adapter.removeAll(state)),
  on(TopicsSubscribedProtocolsDb.listProtocolSubscribedToTopicByTopicIdSuccess, (state: State, { subscribers }) => adapter.setAll(subscribers, state)),
  on(TopicsSubscribedProtocolsDb.listProtocolSubscribedToTopicByTopicIdFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(TopicsSubscribedProtocolsDb.subscribeToTopicByProtocolIdSuccess, (state: State, { subscribedProtocol }) => adapter.addOne(subscribedProtocol, state)),
  //
  on(TopicsSubscribedProtocolsDb.unsubscribeToTopicByProtocolIdSuccess, (state: State, { unsubscribedProtocol }) =>
    adapter.removeOne(unsubscribedProtocol.escalationProtocol.id, state)
  ),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return topicsSubscribedProtocolsDbReducer(state, action);
}

export const selectedProtocolId = (state: State) => state.selectedProtocolId;
