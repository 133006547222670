import { Component, Input, OnInit } from '@angular/core';
import { Log } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-event-timeline-log',
  templateUrl: './event-timeline-log.component.html',
  styleUrls: ['./event-timeline-log.component.scss']
})
export class EventTimelineLogComponent implements OnInit {
  @Input() log: Log;

  constructor() {}

  ngOnInit() {}
}
