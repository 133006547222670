import { ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { CountryAutocompleteFieldComponent } from './country-autocomplete-field/country-autocomplete-field.component';

import { DateIntervalFieldComponent } from './date-field/date-interval-field.component';
import { DynamicListFieldMultipleSelectsComponent } from './dynamic-list-field/multiple-selects/dynamic-list-field-multiple-selects.component';
import { DynamicListFieldSingleSelectComponent } from './dynamic-list-field/single-select/dynamic-list-field-single-select.component';
import { FilterComponent } from './filter.component';
import { InputFieldComponent } from './input-field/input-field.component';
import { MultipleInputsFieldComponent } from './input-field/multiple-inputs-field/multiple-inputs-field.component';
import { SelectFieldComponent } from './select-field/select-field.component';
import { TimezoneAutocompleteFieldComponent } from './timezone-autocomplete-field/timezone-autocomplete-field.component';

@Injectable()
export class FilterComponentFactory {
  readonly selector!: string;
  readonly componentType!: Type<any>;
  readonly ngContentSelectors: string[] = [];
  readonly inputs!: {
    propName: string;
    templateName: string;
  }[];
  readonly outputs!: {
    propName: string;
    templateName: string;
  }[];

  createComponent(elementType: string, viewContainerRef: ViewContainerRef): ComponentRef<FilterComponent> | null {
    switch (elementType) {
      case 'input':
        return viewContainerRef.createComponent<InputFieldComponent>(InputFieldComponent);

      case 'multiple-inputs':
        return viewContainerRef.createComponent<MultipleInputsFieldComponent>(MultipleInputsFieldComponent);

      case 'select':
        return viewContainerRef.createComponent<SelectFieldComponent>(SelectFieldComponent);

      case 'date-interval':
        return viewContainerRef.createComponent<DateIntervalFieldComponent>(DateIntervalFieldComponent);

      case 'dynamic-list-single-select':
        return viewContainerRef.createComponent<DynamicListFieldSingleSelectComponent>(DynamicListFieldSingleSelectComponent);

      case 'dynamic-list-multiple-selects':
        return viewContainerRef.createComponent<DynamicListFieldMultipleSelectsComponent>(DynamicListFieldMultipleSelectsComponent);

      case 'timezone-autocomplete':
        return viewContainerRef.createComponent<TimezoneAutocompleteFieldComponent>(TimezoneAutocompleteFieldComponent);

      case 'country-autocomplete':
        return viewContainerRef.createComponent<CountryAutocompleteFieldComponent>(CountryAutocompleteFieldComponent);

      default:
        return null;
    }
  }
}
