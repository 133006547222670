import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';

export const deviceVariablesColumnSettings = [
  {
    order: 2,
    id: 'name',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VARIABLE_NAME',
    catalogName: 'VARIABLE NAME',
    default: true,
    sortable: true,
    sortProperty: 'name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'openChart',
      options: {}
    },
    width: '15%',
    type: 'start center'
  },
  {
    order: 3,
    id: 'comment',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DESCRIPTION',
    catalogName: 'DESCRIPTION',
    default: true,
    sortable: true,
    sortProperty: 'comment',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'openChart',
      options: {}
    },
    width: '30%',
    cellWidth: '300',
    type: 'start center'
  },
  {
    order: 4,
    id: 'lastRecords',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VALUE',
    catalogName: 'VALUE',
    default: true,
    sortable: true,
    sortProperty: 'lastRecords',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.RICH_VARIABLE,
    cellTypeOptions: {
      dataType: 'lastRecord',
      unit: 'unit'
    },
    isLink: true,
    clickEvent: {
      type: 'openChart',
      options: {}
    },
    width: '20%',
    cellWidth: '200',
    type: 'start center'
  },
  {
    order: 8,
    id: 'time',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.TIME',
    catalogName: 'TIME',
    default: true,
    sortable: true,
    sortProperty: 'time',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.DATE,
    isLink: false,
    width: '12%',
    cellWidth: '170',
    type: 'start center'
  },
  {
    order: 1,
    id: 'file',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VARIABLE_FILE',
    catalogName: 'FILE',
    default: true,
    sortable: true,
    sortProperty: 'file',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'openChartByFile',
      options: {}
    },
    width: '10%',
    cellWidth: '140',
    type: 'start center'
  },
  {
    order: 0,
    id: 'group',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.GROUP',
    catalogName: 'GROUP',
    default: true,
    sortable: true,
    sortProperty: 'group',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    clickEvent: {
      type: 'openChartByGroup',
      options: {}
    },
    width: '12%',
    cellWidth: '140',
    type: 'start center'
  },
  {
    order: 6,
    id: 'device.id',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE',
    catalogName: 'DEVICE',
    default: false,
    sortable: true,
    sortProperty: 'device.id',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '10%',
    cellWidth: '300',
    type: 'start center'
  },
  {
    order: 7,
    id: 'device.name',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_NAME',
    catalogName: 'DEVICE NAME',
    default: false,
    sortable: true,
    sortProperty: 'device.name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '15%',
    cellWidth: '180',
    type: 'start center'
  },
  {
    order: 8,
    id: 'unit',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.UNIT',
    catalogName: 'UNIT',
    default: false,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: 'richVariableUnitCell',
    isLink: false,
    width: '20%',
    cellWidth: '140',
    type: 'start center'
  },
  {
    order: 9,
    id: 'id',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.VARIABLE_ID',
    catalogName: 'VARIABLE TECHNICAL ID',
    default: false,
    sortable: true,
    sortProperty: 'id',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '15%',
    cellWidth: '300',
    type: 'start center'
  },
  {
    order: 10,
    id: 'linked',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LINKED',
    catalogName: 'LINKED',
    default: true,
    sortable: true,
    sortProperty: 'linked',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.LINKED_CELL,
    isLink: false,
    width: '8%',
    cellWidth: '100',
    type: 'center center'
  },
  {
    order: 6,
    id: 'threshold1',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.THRESHOLD1',
    catalogName: 'THRESHOLD1',
    default: true,
    sortable: false,
    sortProperty: 'threshold1',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.NUMBER,
    isLink: false,
    width: '25%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 7,
    id: 'threshold2',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.THRESHOLD2',
    catalogName: 'THRESHOLD2',
    default: true,
    sortable: false,
    sortProperty: 'threshold2',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.NUMBER,
    isLink: false,
    width: '25%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 40,
    id: 'threshold3',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.THRESHOLD3',
    catalogName: 'THRESHOLD3',
    default: false,
    sortable: false,
    sortProperty: 'threshold3',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.NUMBER,
    isLink: false,
    width: '25%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 50,
    id: 'threshold4',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.THRESHOLD4',
    catalogName: 'THRESHOLD4',
    default: false,
    sortable: false,
    sortProperty: 'threshold4',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.NUMBER,
    isLink: false,
    width: '25%',
    cellWidth: '100',
    type: 'start center'
  },
  {
    order: 60,
    id: 'threshold5',
    name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.THRESHOLD5',
    catalogName: 'THRESHOLD5',
    default: false,
    sortable: false,
    sortProperty: 'threshold5',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.NUMBER,
    isLink: false,
    width: '25%',
    cellWidth: '100',
    type: 'start center'
  }
];
