<form [formGroup]="filterForm">
  <div class="device-calls-log-toolbar-container" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="device-calls-log-toolbar-status">
      <mat-form-field color="accent">
        <mat-label>{{ 'DEVICES.CALL_LOG.STATUS_SELECT' | translate }}</mat-label>
        <mat-select formControlName="status" (selectionChange)="onFilterChange()">
          <mat-option *ngFor="let s of statusList" [value]="s">{{ s }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <span style="width: 120px;"></span>
    <div class="device-calls-log-toolbar-filters" fxLayout="row" fxLayoutAlign="end center">
      <div fxLayoutAlign=" center" class="date-picker">
        <mat-icon matPrefix class="calendar-icon">event</mat-icon>
        <mat-form-field color="accent">
          <input
            matInput
            formControlName="startDate"
            (focus)="startDateInput.open()"
            [min]="minDate"
            [max]="maxDate"
            [matDatepicker]="startDateInput"
            (dateChange)="onFilterChange()"
            [placeholder]="'DEVICES.CALL_LOG.FROM' | translate"
          />
          <mat-datepicker-toggle matSuffix [for]="startDateInput">
            <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #startDateInput></mat-datepicker>
        </mat-form-field>
      </div>

      <div fxLayoutAlign=" center" class="date-picker">
        <mat-icon matPrefix class="calendar-icon">event</mat-icon>
        <mat-form-field color="accent">
          <input
            matInput
            formControlName="endDate"
            (focus)="endDateInput.open()"
            [min]="minDate"
            [max]="maxDate"
            [matDatepicker]="endDateInput"
            (dateChange)="onFilterChange()"
            [placeholder]="'DEVICES.CALL_LOG.TO' | translate"
          />
          <mat-datepicker-toggle matSuffix [for]="endDateInput">
            <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #endDateInput></mat-datepicker>
        </mat-form-field>
      </div>

      <button mat-button class="device-call-log-toolbar-reset-button" [disabled]="!(status.value || startDate.value || endDate.value)" (click)="resetFilters()" color="accent">{{ 'DEVICES.CALL_LOG.RESET_FILTERS' | translate }}</button>
    </div>
  </div>
</form>
