<div class="ag-theme-material grid-wrapper">
  <ag-grid-angular
    style="flex: 2"
    rowSelection="multiple"
    [colResizeDefault]="true"
    [accentedSort]='true'
    [gridOptions]="gridOptions"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    [debug]="false"
    (gridReady)="onGridReady($event)"
    (rowClicked)="selectRow.emit($event)"
    (sortChanged)="onSortChange($event)"
    (firstDataRendered)='onFirstDataRendered($event)'
    (newColumnsLoaded)="onGridColumnsChanged($event)"
    (selectionChanged)="onSelectionChanged($event)"
    (rowSelected)="rowSelected$.next($event)"
  >
  </ag-grid-angular>
  <grid-engine-paginator *ngIf='!hidePaginator' [pagination]="grid?.data?.response.pagination" (pageChange)="onPageChange($event)"></grid-engine-paginator>
</div>

<ng-template #exportViewRef></ng-template>
