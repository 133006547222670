import { Injectable } from '@angular/core';
import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { GridsDbActions } from '../../../../../../../grid-engine/src/lib/components/state/actions';

import { BusinessProfilesService } from '../../services/business-profiles.service';
import { BusinessProfilesGridsApiActions, BusinessProfilesGridsPageActions, BusinessProfilesPageActions } from '../actions';

@Injectable()
export class AdminBusinessProfilesGridsEffects {
  addGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesGridsPageActions.addGrid),
      switchMap((action) =>
        this.businessProfilesService.addGrid(action.gridToAdd).pipe(
          map((addedGrid) => BusinessProfilesGridsApiActions.addGridSuccess({ grid: addedGrid })),
          catchError((error) => of(BusinessProfilesGridsApiActions.addGridFailure({ error })))
        )
      )
    )
  );

  updateGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesGridsPageActions.updateGrid),
      switchMap((action) =>
        this.businessProfilesService.updateGrid(action.gridToUpdate).pipe(
          map((updatedGrid) => BusinessProfilesGridsApiActions.updateGridSuccess({ grid: updatedGrid })),
          catchError((error) => of(BusinessProfilesGridsApiActions.updateGridFailure({ error })))
        )
      )
    )
  );

  deleteGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesGridsPageActions.deleteGrid),
      switchMap((action) =>
        this.businessProfilesService.deleteGrid(action.gridToDelete).pipe(
          map((deletedGrid) => BusinessProfilesGridsApiActions.deleteGridSuccess({ removed: deletedGrid })),
          catchError((error) => of(BusinessProfilesGridsApiActions.deleteGridFailure({ error })))
        )
      )
    )
  );

  addGridSuccessThenDispatchGridsBdAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesGridsApiActions.addGridSuccess),
      map((action) => GridsDbActions.loadGrids({ concept: action.grid.masterview }))
    )
  );

  updateGridSuccessThenDispatchGridsBdAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesGridsApiActions.updateGridSuccess),
      map((action) => GridsDbActions.loadGrids({ concept: action.grid.masterview }))
    )
  );

  deleteGridSuccessThenDispatchGridsBdAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesGridsApiActions.deleteGridSuccess),
      map((action) => GridsDbActions.loadGrids({ concept: action.removed.masterview }))
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BusinessProfilesGridsApiActions.addGridSuccess,
          BusinessProfilesGridsApiActions.updateGridSuccess,
          BusinessProfilesGridsApiActions.deleteGridSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BusinessProfilesGridsApiActions.addGridFailure,
          BusinessProfilesGridsApiActions.updateGridFailure,
          BusinessProfilesGridsApiActions.deleteGridFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BusinessProfilesPageActions.listGridsByBusinessProfile),
        tap((action) => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BusinessProfilesGridsApiActions.listGridsByBusinessProfileSuccess, BusinessProfilesGridsApiActions.listGridsByBusinessProfileFailure),
        tap((action) => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly businessProfilesService: BusinessProfilesService,
    private readonly store: Store
  ) {}
}
