<div fxFlexFill fxLayout="row" fxLayoutAlign="start center" (click)="onDispatchEvent($event)">
  <div class="basic-click-cell" [class.clickable]="!minimalDisplay">
    <span *ngIf="!isMultipleStateVariable && cellOptions.dataType === 'lastRecord'" class="fw-600">
      {{ data[0]?.value | numberFormat | infoDisplay }} {{ rawData['unit'] }}
    </span>
    <span *ngIf="!isMultipleStateVariable && cellOptions.dataType !== 'lastRecord'" class="fw-600">
      {{ data?.value | numberFormat | infoDisplay }} {{ data?.unit }}
    </span>
    <span *ngIf="isMultipleStateVariable" class="fw-600">{{ processedValue }}</span>
  </div>
  <div *ngIf="!minimalDisplay">
    <mat-icon class="icon-notification" *ngIf="data?.totalActiveEvents > 0" color="warn" (click)="openFollowedVariableEvents($event)"
      >notifications_active</mat-icon
    >
    <mat-icon class="icon-graphs" *ngIf="canReadGraph && !!data?.totalGraphs" (click)="openGraphsByVariable($event)">assessment</mat-icon>
  </div>
</div>
