import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AuthorizationService, fromAuth } from '@iot-platform/auth';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { fromGrids, GridManagerUserPopupComponent } from '@iot-platform/grid-engine';
import { IotMapActionType, IotMapDisplayMode, IotMapEvent, IotMapFacade } from '@iot-platform/iot-platform-maps';
import {
  CONFIGURE_GRIDS_BUTTON_CONFIG,
  EXPORT_BUTTON_CONFIG,
  FAVORITE_VIEWS_MENU_BUTTON_CONFIG,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  IotToolbarMenuButton,
  REFRESH_BUTTON_CONFIG,
  TOGGLE_FILTER_ENGINE_BUTTON_CONFIG,
  ToolbarPageType
} from '@iot-platform/iot-platform-ui';
import {
  BusinessProfile,
  CommonApiRequest,
  CommonIndexedPagination,
  FavoriteView,
  Filter,
  gridSortModel,
  IotToolbarEvent,
  PlatformResponse,
  ToolbarSize,
  User
} from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { CommandType, Device, Site } from '@iot-platform/models/i4b';
import { fromUserPreferences } from '@iot-platform/users';

import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  FavoriteViewFormComponent
} from 'libs/iot-platform-ui/src/lib/ui/components/favorite-view-form/favorite-view-form.component';
import { PopupComponent } from 'libs/iot-platform-ui/src/lib/ui/components/popup/popup.component';
import { setDisableFavoriteViewButtons } from 'libs/iot4bos-ui/src/lib/helpers/favorite-view.helpers';
import { PreferencesActions } from 'libs/users/src/lib/features/preferences/state/actions';
import { get } from 'lodash';

import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { delay, first, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { DevicesDbActions } from '../../+state/actions';
import { DevicesFacade } from '../../+state/facades/devices.facade';
import { AuthorizationConcept, AuthorizationType } from '../../../../../../../auth/src/lib/authorization.types';
import { GridsDbActions } from '../../../../../../../grid-engine/src/lib/components/state/actions';
import { MapNavigationEvent } from '../../../../../../../iot-platform-maps/src/lib/models/map-navigation-event.model';
import {
  VariableChartDialogComponent
} from '../../../../../../../shared/src/lib/variable-chart/variable-chart-dialog/variable-chart-dialog.component';
import {
  BulkManageTagDialogComponent
} from '../../../../components/bulk-manage-tag-dialog/bulk-manage-tag-dialog.component';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { DeviceDetailPopupComponent } from '../../components/device-detail-popup/device-detail-popup.component';
import { DeviceMoveToFormComponent } from '../../components/device-move-to-form/device-move-to-form.component';

@Component({
  selector: 'iot4bos-ui-devices',
  templateUrl: './devices-shell.component.html',
  styleUrls: ['./devices-shell.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicesShellComponent implements OnInit, OnDestroy {
  masterViewDevicesButtonList!: Array<IotToolbarDefaultButton | IotToolbarMenuButton>;

  deviceGridsConfiguration$: Observable<{
    sortedGridsWithoutAppDefault: Array<I4BGrid<I4BGridOptions, I4BGridData>>;
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  }> = this.store.pipe(select(fromGrids.selectDeviceGridsConfiguration));

  grid$ = this.deviceGridsConfiguration$.pipe(map((conf) => conf.currentGrid));
  grids$ = this.devicesFacade.grids$;
  grids: Array<I4BGrid<I4BGridOptions, I4BGridData>> = [];
  total$ = this.deviceGridsConfiguration$.pipe(map((conf) => (conf.currentGrid?.data?.response?.pagination as CommonIndexedPagination)?.total | 0));

  devicesLoaded$ = this.grid$.pipe(
    switchMap((grid) => {
      if (grid) {
        return this.store.select(fromGrids.getDataLoadedByGrid(grid.id as string));
      } else {
        return of(false);
      }
    })
  );
  gridSort$ = this.grid$.pipe(
    switchMap((grid) => {
      if (grid) {
        return this.store.select(fromGrids.getSortByGrid(grid.id as string));
      } else {
        return of([]);
      }
    })
  );

  hasLeft = false;

  currentUser$: Observable<User> = this.store.pipe(select(fromUserPreferences.getCurrentUser));
  user!: User;
  currentFavoriteView: FavoriteView = {};

  deviceFavoriteViewsConfiguration$: Observable<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  }> = this.devicesFacade.deviceFavoriteViewsConfiguration$;

  currentFilters: Filter[] = [];
  currentFilters$: Observable<Filter[]> = this.devicesFacade.currentFilters$;
  currentFavoriteView$: Observable<FavoriteView | undefined> = this.deviceFavoriteViewsConfiguration$.pipe(map((conf) => conf.currentFavoriteView));

  filterEngineOpened = false;

  canCreateDevice: boolean;
  canUpdateDevice: boolean;
  canReadDevice: boolean;
  canUpdateBusinessProfile: boolean;

  _timerValue$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  timerValue$: Observable<number> = this._timerValue$.asObservable().pipe(delay(0));

  externalDevices$!: Observable<PlatformResponse>;

  userPermissions: { key: string; value: any }[];
  selectedRowId?: string;

  destroyed$: Subject<boolean> = new Subject();
  incomingConnectorUrl = '';

  currentBusinessProfile!: BusinessProfile;
  pageType$: BehaviorSubject<ToolbarPageType> = new BehaviorSubject<ToolbarPageType>(
    this.storage.get('devices-pageType') ? (this.storage.get('devices-pageType') as ToolbarPageType) : ToolbarPageType.GRID
  );
  ToolbarPageType = ToolbarPageType;
  toolbarSize: string = ToolbarSize.SMALL;
  concept = 'devices';
  displayMode$: BehaviorSubject<IotMapDisplayMode> = new BehaviorSubject<IotMapDisplayMode>(
    this.storage.get('devices-display-mode') ? (this.storage.get('devices-display-mode') as IotMapDisplayMode) : 'ccf'
  );

  constructor(
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly devicesFacade: DevicesFacade,
    private readonly navigationApi: NavigationApi,
    private readonly authorizationService: AuthorizationService,
    private readonly router: Router,
    private readonly storage: LocalStorageService,
    private readonly mapFacade: IotMapFacade,
    private readonly translateService: TranslateService
  ) {
    this.canCreateDevice = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.CREATE);
    this.canUpdateDevice = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.UPDATE);
    this.canReadDevice = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.READ);
    this.canUpdateBusinessProfile = this.authorizationService.applyAuthorization(AuthorizationConcept.BUSINESS_PROFILE, AuthorizationType.UPDATE);
    this.userPermissions = [
      { key: 'canCreateDevice', value: this.canCreateDevice },
      { key: 'canUpdateDevice', value: this.canUpdateDevice },
      { key: 'canReadDevice', value: this.canReadDevice }
    ];
    this.handleScrollPosition();
    this.initToolbarButtonList();
  }

  ngOnInit() {
    this.grids$.pipe(takeUntil(this.destroyed$)).subscribe((grids: Array<I4BGrid<I4BGridOptions, I4BGridData>>) => (this.grids = grids));

    combineLatest([this.store.pipe(select(fromAuth.selectSelectedBusinessProfileForAccount)), this.grids$, this.grid$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([businessProfile, grids, grid]) => {
        this.currentBusinessProfile = businessProfile;
        if (businessProfile && grids && !grid) {
          this.selectDefaultGrid();
        }
      });

    combineLatest([this.currentUser$, this.currentFilters$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([currentUser, filters]) => {
        if (currentUser && currentUser.id) {
          this.currentFilters = filters ?? [];
          setDisableFavoriteViewButtons(
            this.masterViewDevicesButtonList,
            this.currentFilters,
            this.currentFavoriteView,
            this.canUpdateBusinessProfile,
            currentUser.id
          );
        }
      });

    combineLatest([this.currentUser$, this.currentFavoriteView$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([currentUser, favoriteView]) => {
        if (currentUser && currentUser.id) {
          this.currentFavoriteView = favoriteView ?? {};
          setDisableFavoriteViewButtons(
            this.masterViewDevicesButtonList,
            this.currentFilters,
            this.currentFavoriteView,
            this.canUpdateBusinessProfile,
            currentUser.id
          );
        }
      });

    this.devicesLoaded$.pipe(takeUntil(this.destroyed$)).subscribe((dataLoaded: boolean) => {
      this.masterViewDevicesButtonList.map((button) => button.icon !== 'filter_list' ?? (button.disabled = !dataLoaded));
    });

    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      if (user) {
        this.user = user;
        this.filterEngineOpened = get(user, 'preferences.filterEngineOpenByDefault', false);
      }
    });
  }

  loadData(): void {
    this.grid$.pipe(takeUntil(this.destroyed$), take(1)).subscribe((grid) => {
      if (grid?.data) {
        const pagination: CommonIndexedPagination = grid.data.response.pagination as CommonIndexedPagination;

        const request: CommonApiRequest = {
          limit: pagination.limit,
          page: 0,
          filters: this.currentFilters,
          concept: grid.masterview.toLowerCase(),
          variables: grid.gridOptions.variableNames,
          tags: grid.gridOptions.tagIds
        };
        this.devicesFacade.loadDevices(request);
      }
    });
  }

  initToolbarButtonList(): void {
    this.masterViewDevicesButtonList = [
      new IotToolbarDefaultButton({ ...TOGGLE_FILTER_ENGINE_BUTTON_CONFIG, tooltip: 'IOT_TOOLBAR.TOOLTIP.TOGGLE_FILTER_ENGINE.DEVICES' }, 1),
      new IotToolbarDefaultButton({ ...REFRESH_BUTTON_CONFIG, tooltip: 'IOT_TOOLBAR.TOOLTIP.REFRESH.DEVICES' }, 2),
      new IotToolbarDefaultButton({ ...EXPORT_BUTTON_CONFIG, tooltip: 'IOT_TOOLBAR.TOOLTIP.EXPORT_DATA.DEVICES' }, 3),
      new IotToolbarMenuButton({ ...FAVORITE_VIEWS_MENU_BUTTON_CONFIG, tooltip: 'IOT_TOOLBAR.TOOLTIP.FAVORITE_VIEWS_MENU.DEVICES' }, 4),
      new IotToolbarDefaultButton({ ...CONFIGURE_GRIDS_BUTTON_CONFIG, tooltip: 'IOT_TOOLBAR.TOOLTIP.CONFIGURE_GRIDS.DEVICES' }, 5)
    ];
  }

  onCreateFavoriteView() {
    const favoriteView: FavoriteView = {};
    favoriteView.masterView = 'devices';
    favoriteView.concept = 'DEVICE';
    favoriteView.filters = this.currentFilters;

    const dialogRef = this.dialog.open(FavoriteViewFormComponent, {
      width: '1100px',
      data: { favoriteView: favoriteView, canUpdateBusinessProfile: this.canUpdateBusinessProfile, grids: this.grids },
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) => {
        if (!!response) {
          this.devicesFacade.saveFavoriteView(response);
        }
      });
  }

  onEditFavoriteView() {
    const fv: FavoriteView = { ...this.currentFavoriteView };
    fv.filters = [...this.currentFilters];
    const dialogRef = this.dialog.open(FavoriteViewFormComponent, {
      width: '1100px',
      data: { favoriteView: fv, canUpdateBusinessProfile: this.canUpdateBusinessProfile, grids: this.grids },
      disableClose: true
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) => {
        if (!!response) {
          this.devicesFacade.updateFavoriteView(response);
        }
      });
  }

  onDeleteFavoriteView() {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'delete', value: this.currentFavoriteView.name }
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: boolean) => {
        this.toggleRefreshActivated(true);
        if (response) {
          this.devicesFacade.deleteFavoriteView(this.currentFavoriteView);
          this.clearAppliedFilters();
        }
      });
  }

  onApplyFavoriteView(favoriteView: FavoriteView) {
    this.devicesFacade.setCurrentFavoriteView(favoriteView);
    if (favoriteView) {
      this.selectGridAndLoadData(favoriteView.gridId as string, favoriteView.masterView as string, this.currentFilters);
    }
    this.refreshMap();
  }

  onApplyFilters(filters: Filter[]) {
    if (filters.length === 0) {
      this.onApplyFavoriteView(null);
    } else {
      this.devicesFacade.setCurrentFilters(filters);
    }
    this.loadData();
    this.refreshMap();
  }

  onToolbarEvent(event: IotToolbarEvent): void {
    {
      switch (event.type) {
        case IotToolbarDispatchActionType.REFRESH_PAGE:
          this.onRefreshClicked();
          break;
        case IotToolbarDispatchActionType.EXPORT_DATA:
          this.onExportData();
          break;
        case IotToolbarDispatchActionType.MANAGE_GRID_SETTINGS:
          this.openGridSettings();
          break;
        case IotToolbarDispatchActionType.APPLY_FAVORITE_VIEW:
          this.onApplyFavoriteView(event.options);
          break;
        case IotToolbarDispatchActionType.APPLY_GRID:
          this.onApplyGrid(event.options.grid);
          break;
        case IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE:
          this.onShowFilter();
          break;
        case IotToolbarDispatchActionType.TOGGLE_PAGE_TYPE:
          this.storage.set('devices-pageType', event.options.pageType);
          this.pageType$.next(event.options.pageType);
          break;
        case IotToolbarDispatchActionType.CREATE_FAVORITE_VIEW:
          this.onCreateFavoriteView();
          break;
        case IotToolbarDispatchActionType.EDIT_FAVORITE_VIEW:
          this.onEditFavoriteView();
          break;
        case IotToolbarDispatchActionType.DELETE_FAVORITE_VIEW:
          this.onDeleteFavoriteView();
          break;
        default:
          break;
      }
    }
  }

  onShowFilter(): void {
    this.filterEngineOpened = !this.filterEngineOpened;
    if (this.user) {
      this.user.preferences = { ...this.user.preferences, filterEngineOpenByDefault: this.filterEngineOpened };
      this.store.dispatch(PreferencesActions.saveUserPreferences({ user: this.user, preferences: this.user.preferences }));
    }
  }

  onRefreshClicked() {
    this.loadData();
    this.refreshMap();
  }

  refreshMap(): void {
    this.mapFacade.refreshMap();
  }

  clearAppliedFilters() {
    this.devicesFacade.setCurrentFavoriteView(null);
    this.selectDefaultGrid();
    this.refreshMap();
  }

  onSortChange(initialSort: gridSortModel[] | Sort) {
    this.store.dispatch(DevicesDbActions.saveInitialSort({ initialSort }));
  }

  openGridSettings() {
    this.toggleRefreshActivated(false);
    this.grid$
      .pipe(
        take(1),
        takeUntil(this.destroyed$),
        switchMap((gridToUpdate) => {
          return this.dialog
            .open(GridManagerUserPopupComponent, {
              width: '1350px',
              maxWidth: '1350px',
              disableClose: true,
              data: { grid: { ...gridToUpdate, data: null } }
            })
            .afterClosed();
        })
      )
      .subscribe((value: { action: string; grid: any }) => {
        this.toggleRefreshActivated(true);
        if (!!value) {
          if (value.action === 'DELETE') {
            this.store.dispatch(
              GridsDbActions.removeGrid({ toRemove: { ...value.grid, gridOptions: { ...value.grid.gridOptions, filters: this.currentFilters } } })
            );
          }
          if (value.action === 'UPDATE') {
            this.store.dispatch(
              GridsDbActions.updateGrid({ toUpdate: { ...value.grid, gridOptions: { ...value.grid.gridOptions, filters: this.currentFilters } } })
            );
          }
          if (value.action === 'ADD') {
            this.store.dispatch(GridsDbActions.addGrid({ toAdd: { ...value.grid, gridOptions: { ...value.grid.gridOptions, filters: this.currentFilters } } }));
          }
        }
      });
  }

  onApplyGrid(grid: I4BGrid<I4BGridOptions, I4BGridData>) {
    if (grid) {
      this.selectGridAndLoadData(grid.id as string, grid.masterview, this.currentFilters);
      this.refreshMap();
    }
  }

  onSelectDevice(device: Device) {
    this.devicesFacade.selectDevice(device);
  }

  openMoveTo(devices: Device[]) {
    const dialogRef = this.dialog.open(DeviceMoveToFormComponent, {
      width: '800px',
      disableClose: true,
      data: { devices: devices }
    });

    dialogRef.afterClosed().subscribe((updatedDevices: Device[]) => {
      if (updatedDevices) {
        this.devicesFacade.moveDevices(updatedDevices);
      }
    });
  }

  onExportData(): void {
    const openSettingsSub$: Subject<void> = new Subject<void>();
    this.grid$.pipe(first(), takeUntil(openSettingsSub$)).subscribe((grid) => {
      if (grid) {
        grid.export({ filters: this.currentFilters, totalElements: (grid.data.response.pagination as CommonIndexedPagination).total });
      }
      openSettingsSub$.next();
      openSettingsSub$.complete();
    });
  }

  onMasterViewEngineEvent(event: any) {
    this.onSelectDevice(event.rawData);

    switch (event.type) {
      case 'navigateToDevice':
        this.navigationApi.openDeviceDetail(event.rawData);
        break;
      case 'navigateToSite':
        this.navigationApi.openSiteDetailFromDevice(event.rawData);
        break;
      case 'open':
        const selectedDevice: Device = event.rawData;
        const deviceDetailDialog = this.dialog.open(DeviceDetailPopupComponent, {
          width: '900px',
          disableClose: false,
          data: { device: selectedDevice }
        });

        deviceDetailDialog.componentInstance.selectSite.pipe(takeUntil(this.destroyed$)).subscribe((site: Site) => {
          deviceDetailDialog.close();
          this.navigationApi.openSiteDetailFromDevice(selectedDevice);
        });
        deviceDetailDialog.componentInstance.selectDevice.pipe(takeUntil(this.destroyed$)).subscribe((device: Device) => {
          deviceDetailDialog.close();
          this.navigationApi.openDeviceDetail(selectedDevice);
        });
        break;
      case 'moveTo':
        this.openMoveTo([event.rawData]);
        break;
      case 'bulkMoveTo':
        this.openMoveTo(event.rawData);
        break;
      case CommandType.REFRESH:
        this.sendRefreshCommand(event.rawData);
        break;
      case CommandType.CONFIGURE:
        this.setIncomingConnectorUrl(event.rawData);
        this.onConfigureDeviceConnector(event.rawData);
        break;
      case 'openChartByVariable':
        this.openChartByVariable(event.rawData, event.options.variableName);
        break;
      case 'bulkAddTag':
      case 'bulkRemoveTag':
        this.openBulkManageTagDialog(
          event.type === 'bulkAddTag',
          event.rawData.map((device: Device) => device.id)
        );
        break;
      case 'bulkRefresh':
        this.openSendCommandConfirmationDialog(
          CommandType.REFRESH,
          event.rawData.filter((device: Device) => device.isRefreshCommandEnabled).map((device: Device) => device.id)
        );
        break;

      default:
        break;
    }
  }

  openChartByVariable(device: Device, variableName: string) {
    this.toggleRefreshActivated(false);
    if (device['expandedVariables'][variableName]) {
      this.dialog
        .open(VariableChartDialogComponent, {
          width: '990px',
          data: {
            variables: [
              {
                ...device['expandedVariables'][variableName],
                device: { id: device.id, name: device.name }
              }
            ],
            variableType: 'deviceVariable'
          }
        })
        .afterClosed()
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => this.toggleRefreshActivated(true));
    }
  }

  openBulkManageTagDialog(isAddition: boolean, devicesIds: string[]): void {
    this.dialog
      .open(BulkManageTagDialogComponent, {
        data: { isAddition, concept: 'device', entityId: this.currentBusinessProfile.entityId, selectionTotal: devicesIds.length },
        disableClose: true,
        width: '600px'
      })
      .afterClosed()
      .subscribe((tagLabelId: string) => {
        if (tagLabelId) {
          this.store.dispatch(DevicesDbActions.bulkAddOrRemoveTag({ isAddition, tagLabelId, devicesIds }));
        }
      });
  }

  openSendCommandConfirmationDialog(command: CommandType, devicesIds: string[]): void {
    this.dialog
      .open(PopupComponent, {
        data: {
          type: 'confirm',
          value: this.translateService.instant('DEVICES.SHELL.BULK_SEND_COMMAND.CONFIRMATION_MESSAGES.' + command.toUpperCase(), { total: devicesIds.length })
        },
        disableClose: true,
        width: '600px'
      })
      .afterClosed()
      .subscribe((confirmation: boolean) => {
        if (confirmation && devicesIds.length) {
          this.store.dispatch(DevicesDbActions.bulkSendCommand({ devicesIds, command: { command: CommandType.REFRESH } }));
        }
      });
  }

  onConfigureDeviceConnector(device: Device) {
    if (device.outgoingConnector?.configuration?.sharedAuth) {
      window.open(this.incomingConnectorUrl);
    } else {
      window.open(device.incomingConnector?.configuration?.url);
    }
  }

  sendRefreshCommand(device: Device): void {
    this.devicesFacade.sendConnectorCommand(device, { command: 'refresh' });
  }

  onMapNavigationEvent(event: MapNavigationEvent<Site | Device>) {
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, 'device');
    if (event.element && event.destination === 'site') {
      this.navigationApi.selectLeSite(event.element as Site);
    } else if (event.destination === 'device') {
      this.selectedRowId = (event.element as Device).id;
      this.navigationApi.openDeviceDetail(event.element as Device);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.hasLeft = true;
  }

  onMapEvent(event: IotMapEvent) {
    if (event.type === IotMapActionType.CHANGE_DISPLAY_MODE && event.displayMode !== undefined) {
      this.displayMode$.next(event.displayMode);
      this.storage.set('devices-display-mode', event.displayMode);
    }
  }

  private handleScrollPosition() {
    const keepScrollPosition = this.router.getCurrentNavigation()?.extras.state?.keepScrollPosition;
    if (keepScrollPosition) {
      this.selectedRowId = this.storage.get(LocalStorageKeys.STORAGE_SELECTED_ROW_ID);
    }
  }

  private selectGridAndLoadData(gridId: string, masterview: string, filters: Filter[]) {
    this.store.dispatch(GridsDbActions.selectGridAndLoadData({ gridId, masterview, filters }));
  }

  private selectDefaultGrid(): void {
    this.selectGridAndLoadData('default', 'devices', this.currentFilters);
  }

  private toggleRefreshActivated(refreshActivated: boolean): void {
    this.store.dispatch(GridsDbActions.toggleRefreshActivated({ refreshActivated }));
  }

  private setIncomingConnectorUrl(device: Device) {
    combineLatest([
      this.store.select(fromAuth.selectRefreshToken),
      this.store.select(fromAuth.selectSelectedBusinessProfileForAccount),
      this.store.select(fromAuth.selectUserId)
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([token, bP, userId]) => {
        if (device?.incomingConnector && token && bP && userId) {
          this.incomingConnectorUrl =
            device.incomingConnector.configuration?.url + 'device/' + userId + '/' + bP.id + '?' + 'refreshToken=' + token + '&deviceName=' + device.name;
        }
      });
  }
}
