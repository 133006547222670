import { Connector, PlatformRequest } from '@iot-platform/models/common';
import { Device } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const addConnector = createAction('[Connectors] Add Connector', props<{ connectorToAdd: Connector }>());

export const updateConnector = createAction('[Connectors] Update Connector', props<{ connectorToUpdate: Connector }>());

export const deleteConnector = createAction('[Connectors] Delete Connector', props<{ connectorToDelete: Connector }>());

export const selectConnector = createAction('[Connectors] Select Connector', props<{ selectedConnector: Connector }>());

export const loadConnectors = createAction('[Connectors] Load Connectors', props<{ request: PlatformRequest }>());

export const sendConnectorCommand = createAction('[Connectors] Send Connector Command', props<{ device: Device; command: any }>());

export const bulkSendConnectorCommand = createAction('[Connectors] Bulk Send Connector Command', props<{ devices: Device[]; command: any }>());

export const refreshCommandStatus = createAction('[Connectors] Refresh Command Status', props<{ deviceId: string }>());
