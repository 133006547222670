import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Connector } from '@iot-platform/models/common';

import { Observable, of } from 'rxjs';
import { DeviceConnectorsService } from '../../../../../../../shared/src/lib/services/device-connectors.service';

@Component({
  selector: 'iot4bos-ui-device-connector-detail-popup',
  templateUrl: './device-connector-detail-popup.component.html',
  styleUrls: ['./device-connector-detail-popup.component.scss']
})
export class DeviceConnectorDetailPopupComponent implements OnInit {
  connector?: Connector;
  connectorEntities: string[] = [];
  commandList$: Observable<string[]> = of([]);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { connector: Connector }, private readonly deviceConnectorsService: DeviceConnectorsService) {}

  ngOnInit(): void {
    this.connector = this.data.connector;
    this.connectorEntities = this.connector?.entities ? this.connector.entities.map((entity) => entity.name) : [];
    this.commandList$ = this.deviceConnectorsService.getAllCommands();
  }
}
