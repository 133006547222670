import { NgModule } from '@angular/core';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../../../../../iot4bos-ui/src/lib/directives/directives.module';
import { MasterViewEngineModule } from '../../../../../table-engine/src/lib/components/master-view-engine/master-view-engine.module';
import { GatewaysShellComponent } from './containers/gateways-shell/gateways-shell.component';
import { GatewaysRoutingModule } from './gateways-routing.module';
import { GatewaysEffects } from './state/effects/gateways.effects';
import * as fromGateways from './state/reducers';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule,
    IotPlatformPipesModule,
    IotPlatformUiModule,
    DirectivesModule,
    MasterViewEngineModule,
    GatewaysRoutingModule,
    StoreModule.forFeature(fromGateways.featureKey, fromGateways.reducers),
    EffectsModule.forFeature([GatewaysEffects])
  ],
  declarations: [GatewaysShellComponent]
})
export class GatewaysModule {
}
