import { Injectable } from '@angular/core';
import { PlatformRequest } from '@iot-platform/models/common';
import { AssetEvent, DeviceEvent } from '@iot-platform/models/i4b';
import { select, Store } from '@ngrx/store';
import { AssetEventsApi } from 'libs/iot4bos-ui/src/lib/features/asset-events/+state/asset-events.api';
import { DeviceEventsApi } from 'libs/iot4bos-ui/src/lib/features/device-events/+state/device-events.api';
import {
  AssetEventsByTopicLogsUiActions,
  AssetEventsByTopicUiActions,
  DeviceEventsByTopicLogsUiActions,
  DeviceEventsByTopicUiActions
} from './actions';
import * as fromTopics from './reducers';

//
@Injectable()
export class TopicsFacade {
  // MASTER VIEWS ASSET EVENT BY TOPIC
  formattedAssetEventsByTopic$ = this.store.pipe(select(fromTopics.getFormattedAssetEventsByTopic));
  formattedAssetEventsByTopicLoaded$ = this.store.pipe(select(fromTopics.getAssetEventsByTopicLoaded));
  totalActiveAssetEventsByTopic$ = this.store.pipe(select(fromTopics.getTotalActiveAssetEventsByTopic));
  totalActiveAssetEventsByTopicLoaded$ = this.store.pipe(select(fromTopics.getTotalActiveAssetEventsByTopicLoaded));
  selectedAssetEventByTopic$ = this.store.pipe(select(fromTopics.getSelectedAssetEventByTopic));
  assetEventsByTopicTableState$ = this.store.pipe(select(fromTopics.getAssetEventsByTopicTableState));
  assetEventsByTopicAutoRefresh$ = this.store.pipe(select(fromTopics.getAssetEventsByTopicAutoRefresh));
  assetEventsByTopicRefreshDelay$ = this.store.pipe(select(fromTopics.getAssetEventsByTopicRefreshDelay));
  mvAssetEventsByTopicSettings$ = this.store.pipe(select(fromTopics.getMvAssetEventsByTopicSettings));
  assetEventsByTopicPagination$ = this.store.pipe(select(fromTopics.getAssetEventsByTopicPagination));
  assetEventsByTopicLogsByAssetEventId$ = this.store.pipe(select(fromTopics.getAllAssetEventsByTopicLogs));
  assetEventsByTopicLogsByAssetEventIdLoaded$ = this.store.pipe(select(fromTopics.getAssetEventsByTopicLogsLoaded));
  assetEventByTopicStatus$ = this.store.pipe(select(fromTopics.getAssetEventByTopicStatus));

  // MASTER VIEWS DEVICE EVENT BY TOPIC
  formattedDeviceEventsByTopic$ = this.store.pipe(select(fromTopics.getFormattedDeviceEventsByTopic));
  formattedDeviceEventsByTopicLoaded$ = this.store.pipe(select(fromTopics.getDeviceEventsByTopicLoaded));
  totalActiveDeviceEventsByTopic$ = this.store.pipe(select(fromTopics.getTotalActiveDeviceEventsByTopic));
  totalActiveDeviceEventsByTopicLoaded$ = this.store.pipe(select(fromTopics.getTotalActiveDeviceEventsByTopicLoaded));
  selectedDeviceEventByTopic$ = this.store.pipe(select(fromTopics.getSelectedDeviceEventByTopic));
  deviceEventsByTopicTableState$ = this.store.pipe(select(fromTopics.getDeviceEventsByTopicTableState));
  deviceEventsByTopicAutoRefresh$ = this.store.pipe(select(fromTopics.getDeviceEventsByTopicAutoRefresh));
  deviceEventsByTopicRefreshDelay$ = this.store.pipe(select(fromTopics.getDeviceEventsByTopicRefreshDelay));
  mvDeviceEventsByTopicSettings$ = this.store.pipe(select(fromTopics.getMvDeviceEventsByTopicSettings));
  deviceEventsByTopicPagination$ = this.store.pipe(select(fromTopics.getDeviceEventsByTopicPagination));
  deviceEventsByTopicLogsByDeviceEventId$ = this.store.pipe(select(fromTopics.getAllDeviceEventsByTopicLogs));
  deviceEventsByTopicLogsByDeviceEventIdLoaded$ = this.store.pipe(select(fromTopics.getDeviceEventsByTopicLogsLoaded));
  deviceEventByTopicStatus$ = this.store.pipe(select(fromTopics.getDeviceEventByTopicStatus));

  constructor(private readonly store: Store, private readonly assetEventsApi: AssetEventsApi, private readonly deviceEventsApi: DeviceEventsApi) {}

  // MASTER VIEW EVENTS BY TOPIC

  loadEvents(params: PlatformRequest, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'topic') {
      this.store.dispatch(AssetEventsByTopicUiActions.loadAssetEventsByTopic({ request: params }));
    }
    if (view === 'device-events' && filteredBy === 'topic') {
      this.store.dispatch(DeviceEventsByTopicUiActions.loadDeviceEventsByTopic({ request: params }));
    }
  }

  loadMetadata(view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'topic') {
      this.store.dispatch(AssetEventsByTopicUiActions.loadMvAssetEventsByTopicSettings({ settingName: 'assetEventsByTopic' }));
    }
    if (view === 'device-events' && filteredBy === 'topic') {
      this.store.dispatch(DeviceEventsByTopicUiActions.loadMvDeviceEventsByTopicSettings({ settingName: 'deviceEventsByTopic' }));
    }
  }

  loadEventDetailPopupDataByEvent(event: AssetEvent | DeviceEvent, view: string, filteredBy: string) {
    if (view === 'asset-events') {
      this.assetEventsApi.loadEventDetailPopupDataByAssetEvent(event);
    }
    if (view === 'device-events') {
      this.deviceEventsApi.loadEventDetailPopupDataByDeviceEvent(event);
    }
    this.loadLogsByEventId(event.id, view, filteredBy);
  }

  saveEventsTableState(tableState: { checked: AssetEvent[]; selected: AssetEvent }, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'topic') {
      this.store.dispatch(AssetEventsByTopicUiActions.saveTableStateByTopic({ tableState }));
    }
    if (view === 'device-events' && filteredBy === 'topic') {
      this.store.dispatch(DeviceEventsByTopicUiActions.saveTableStateByTopic({ tableState }));
    }
  }

  updateEventsStatus(eventIds: string[], status: string, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'topic') {
      this.store.dispatch(AssetEventsByTopicUiActions.bulkUpdateStatusByAssetEventIdByTopic({ assetEventIds: eventIds, status }));
    }
    if (view === 'device-events' && filteredBy === 'topic') {
      this.store.dispatch(DeviceEventsByTopicUiActions.bulkUpdateStatusByDeviceEventIdByTopic({ deviceEventIds: eventIds, status }));
    }
  }

  loadLogsByEventId(eventId: string, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'topic') {
      this.store.dispatch(AssetEventsByTopicLogsUiActions.loadLogsByAssetEventId({ assetEventId: eventId }));
    }
    if (view === 'device-events' && filteredBy === 'topic') {
      this.store.dispatch(DeviceEventsByTopicLogsUiActions.loadLogsByDeviceEventId({ deviceEventId: eventId }));
    }
  }

  createLogByEventId(event: AssetEvent | DeviceEvent, value: string, view: string, filteredBy: string) {
    if (view === 'asset-events' && filteredBy === 'topic') {
      this.store.dispatch(AssetEventsByTopicLogsUiActions.createLogByAssetEventId({ comment: { assetEvent: event, value: value } }));
    }
    if (view === 'device-events' && filteredBy === 'topic') {
      this.store.dispatch(DeviceEventsByTopicLogsUiActions.createLogByDeviceEventId({ comment: { deviceEvent: event, value: value } }));
    }
  }
}
