import { CommonApiRequest, CommonApiResponse, CommonIndexedPagination, Filter } from '@iot-platform/models/common';
import { Asset } from '@iot-platform/models/oyan';
import { createAction, props } from '@ngrx/store';

export const loadAssets = createAction('[OYAN ASSETS/UI] Load Assets', props<{ request: CommonApiRequest }>());
export const loadAssetsSuccess = createAction(
  '[OYAN ASSETS/API] Load Assets Success',
  props<{ response: CommonApiResponse<Asset, CommonIndexedPagination> }>()
);
export const loadAssetsFailure = createAction('[OYAN ASSETS/API] Load Assets Failure', props<{ error: unknown }>());

export const loadAssetById = createAction('[OYAN ASSETS/UI] Load Asset By Id', props<{ id: string }>());
export const loadAssetByIdSuccess = createAction('[OYAN ASSETS/API] Load Asset By Id Success', props<{ response: Asset }>());
export const loadAssetByIdFailure = createAction('[OYAN ASSETS/API] Load Asset By Id Failure', props<{ error: unknown }>());

export const setFilters = createAction('[OYAN ASSETS/UI] Set filters', props<{ filters: Filter[] }>());
