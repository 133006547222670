import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Site } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-detail-site-card',
  templateUrl: './detail-site-card.component.html',
  styleUrls: ['./detail-site-card.component.scss']
})
export class DetailSiteCardComponent implements OnInit {
  @Input() site: Site;
  @Input() extraFill = false;

  @Output() selectSite: EventEmitter<Site> = new EventEmitter<Site>();

  constructor() {}

  ngOnInit() {}
}
