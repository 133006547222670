<section fxLayout="row" fxLayoutAlign="center start" class="full-width organization-concept-section">
  <div fxLayout="column" fxLayoutGap="" fxFlex="300px">
    <iot4bos-backoffice-ui-organization-concepts-selection-checkbox
      *ngFor="let concept of allConceptsAvailable"
      [concept]="concept"
      [disabled]="readonly"
      (toggleConceptSelection)="onToggleConceptSelection($event)"
    >
    </iot4bos-backoffice-ui-organization-concepts-selection-checkbox>
  </div>
</section>
