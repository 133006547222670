import { createReducer, on } from '@ngrx/store';
import { AdminOrganizationsPageActions, AdminOrganizationsTagsApiActions, AdminOrganizationsTagsPageActions } from '../actions';

export const adminOrganizationsTagsPageFeatureKey = 'adminOrganizationsTagsPage';

export interface State {
  error: any;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false
};

export const reducer = createReducer(
  initialState,
  on(AdminOrganizationsPageActions.selectOrganization, (state: State) => ({ ...state, error: null, pending: true })),
  on(AdminOrganizationsTagsPageActions.listTagsByOrganization, (state: State) => ({ ...state, error: null, pending: true })),
  on(AdminOrganizationsTagsApiActions.listTagsByOrganizationSuccess, (state: State, { tags }) => ({ ...state, error: null, pending: false })),
  on(AdminOrganizationsTagsApiActions.listTagsByOrganizationFailure, (state: State, { error }) => ({ ...state, error, pending: false }))
);

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
