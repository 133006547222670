<div class='ag-theme-material grid-wrapper'>
  <ag-grid-angular
    style='flex: 1'
    rowSelection='multiple'
    rowHeight='38'
    [colResizeDefault]='true'
    [accentedSort]='true'
    [gridOptions]='gridOptions'
    [rowData]='rowData'
    [columnDefs]='columnDefs'
    (gridReady)='onGridReady($event)'
    (sortChanged)='onSortChanged($event)'
    (firstDataRendered)='onFirstDataRendered($event)'
    (newColumnsLoaded)='onGridColumnsChanged($event)'
    (rowSelected)='rowSelected$.next($event)'
  >
  </ag-grid-angular>
  <grid-engine-paginator [pagination]='pagination$ | async' (pageChange)='onPageChange($event)'
                         *ngIf='displayPagination'></grid-engine-paginator>
</div>
<ng-template #exportViewRef></ng-template>
