import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Country, CountryFilterType, Filter, FilterCountryOptions } from '@iot-platform/models/common';
import { get } from 'lodash';

@Component({
  selector: 'iot-platform-ui-country-autocomplete-field',
  templateUrl: './country-autocomplete-field.component.html',
  styleUrls: ['./country-autocomplete-field.component.css']
})
export class CountryAutocompleteFieldComponent {
  @Input() data: FilterCountryOptions = { filterType: CountryFilterType.ALL };

  @Output() dispatchFilterValue: EventEmitter<Filter> = new EventEmitter<Filter>();

  onSelectionChange(country: Country): void {
    let key = 'name';
    if (this.data.filterType !== CountryFilterType.NAME && this.data.filterType !== CountryFilterType.ALL) {
      key = this.data.filterType;
    }
    const value: string = get(country, key);
    this.dispatchFilterValue.emit({
      value,
      label: value,
      criteriaKey: this.data?.criteriaKey,
      criteriaLabel: this.data?.criteriaLabel
    });
  }
}
