<grid-engine-header-shell [params]="params">
  <div
    style="text-align: center"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="center center"
    [matTooltip]="params?.headerTooltip | translate"
    matTooltipPosition="above"
    matTooltipClass="regular-tooltip"
  >
    <mat-icon [svgIcon]="params?.headerIcon" class="header-icon"></mat-icon>
  </div>
</grid-engine-header-shell>
