<mat-drawer-container fxFlexFill hasBackdrop="false">
  <mat-drawer-content fxFlexFill fxLayout='column' fxLayoutAlign='start center' >
    <div class='map' fxFlexFill fxLayout='column' fxLayoutAlign='start center' style>
      <iot-platform-maps-map-spinner [loading]='loading$ | async'></iot-platform-maps-map-spinner>
      <!--<iot-platform-maps-map-toolbar style="width: 100%"></iot-platform-maps-map-toolbar>-->
      <div (leafletMapReady)="onMapReady($event)"
           [leafletBaseLayers]="baseLayers"
           [leafletOptions]="options"
           class="map__container"
           fxFlex="auto"
           leaflet>
      </div>
      <!--<div (leafletMapReady)="onMapReady($event)"
           [leafletOptions]="options"
           class="map__container"
           fxFlex="auto"
           leaflet>
      </div>-->
    </div>
  </mat-drawer-content>
  <mat-drawer [opened]="(displayPanelInfo$ | async)" class="map-drawer" mode="over" position="end">
    <iot-platform-maps-map-panel-info (close)="onClosePanelInfo($event)"
                                      (displayRoute)="onDisplayRoute($event)"
                                      (selectElement)="onElementSelection($event)"
                                      [feature]="selectedFeature$ | async"
    >
    </iot-platform-maps-map-panel-info>
  </mat-drawer>
</mat-drawer-container>
