import { HttpErrorResponse } from '@angular/common/http';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { SpreadsheetExportsDbActions, SpreadsheetExportsUiActions } from '../actions';

export const spreadsheetExportsUiFeatureKey = 'spreadsheetExportsUi';

type SpreadsheetExportUI = { contactId: string; loading: boolean; loaded: boolean; error: HttpErrorResponse | null };

export type State = EntityState<SpreadsheetExportUI>;

export const adapter: EntityAdapter<SpreadsheetExportUI> = createEntityAdapter<SpreadsheetExportUI>({
  selectId: (s: SpreadsheetExportUI) => s.contactId,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(SpreadsheetExportsUiActions.getSpreadsheetExport, (state: State, { contactId }) => {
    return adapter.addOne({ contactId, loading: true, loaded: false, error: null }, state);
  }),
  on(SpreadsheetExportsDbActions.getSpreadsheetExportSuccess, (state: State, { response }) => {
    return adapter.updateOne({ id: response.contactId, changes: { loaded: true, loading: false } }, state);
  }),
  on(SpreadsheetExportsDbActions.getSpreadsheetExportFailure, (state: State, { error, contactId }) => {
    return adapter.updateOne({ id: contactId, changes: { loaded: false, loading: false, error } }, state);
  })
);
