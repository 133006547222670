import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortUtil } from '../../../../../../shared/src/lib/utils/sort.util';

@Injectable()
export class FilterEngineService {
  constructor(private httpClient: HttpClient) {}

  getFilterCriteriaByConcept(masterView: string): Observable<any> {
    return this.httpClient.get<any>(`assets/engines/filters/filter-criteria-${masterView}.json`).pipe(map((data) => this.sortFilterCriteria(data)));
  }

  sortFilterCriteria(filterCriteria: any): any {
    filterCriteria.sort(SortUtil.sortByOrder);
    filterCriteria.forEach((criteria: any) => (Array.isArray(criteria.options) ? criteria.options.sort(SortUtil.sortByOrder) : criteria));

    return filterCriteria;
  }
}
