import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { CustomEncoder } from '@iot-platform/core';

import { BusinessProfile, PlatformRequest, PlatformResponse, User } from '@iot-platform/models/common';

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(@Inject('environment') public environment, public http: HttpClient) {}

  getUsers(request?: PlatformRequest): Observable<PlatformResponse> {
    let parameters: HttpParams = new HttpParams({ encoder: new CustomEncoder() });

    if (request) {
      if (request.hasOwnProperty('limit')) {
        parameters = parameters.set('limit', request.limit + '');
      }
      if (request.hasOwnProperty('page')) {
        parameters = parameters.set('page', request.page + '');
      }
      if (request.filters) {
        request.filters.forEach((filter) => (parameters = parameters.append(filter.criteriaKey, filter.value)));
      }
    }

    return this.http.get(`${this.environment.api.url}${this.environment.api.endpoints.users}`, { params: parameters }).pipe(
      map((data: any) => {
        return {
          data: data.content,
          currentPage: data.page.curPage,
          hasMore: data.page.hasMore,
          limit: data.page.limit,
          maxPage: data.page.maxPage,
          total: data.page.total
        };
      })
    );
  }

  getTotalUsersWithParams(params: Array<{ key: string; value: string }>): Observable<any> {
    let parameters: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    parameters = parameters.set('limit', '0');
    parameters = parameters.set('page', '0');

    params.forEach((p) => (parameters = parameters.append(p.key, p.value)));

    return this.http
      .get(`${this.environment.api.url}${this.environment.api.endpoints.users}`, { params: parameters })
      .pipe(map((response: { content: any; page: any }) => response.page.total));
  }

  getUsersFilteredByStatus(status: string): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    if (status !== '') {
      params = params.set('userStatus', status);
    }

    return this.http.get(`${this.environment.api.url}${this.environment.api.endpoints.users}`, { params }).pipe(
      map((data: any) => {
        return {
          data: data.content,
          currentPage: data.page.curPage,
          hasMore: data.page.hasMore,
          limit: data.page.limit,
          maxPage: data.page.maxPage,
          total: data.page.total
        };
      })
    );
  }

  addUser(user: User): Observable<User> {
    return this.http.post<User>(`${this.environment.api.url}${this.environment.api.endpoints.users}`, user);
  }

  activateUser(user: User): Observable<User> {
    return this.http
      .put(`${this.environment.api.url}${this.environment.api.endpoints.users}/${user.id}/activate`, {})
      .pipe(switchMap(() => this.http.get<User>(`${this.environment.api.url}${this.environment.api.endpoints.users}/${user.id}`)));
  }

  disableUser(user: User): Observable<User> {
    return this.http
      .put(`${this.environment.api.url}${this.environment.api.endpoints.users}/${user.id}/disable`, {})
      .pipe(switchMap(() => this.http.get<User>(`${this.environment.api.url}${this.environment.api.endpoints.users}/${user.id}`)));
  }

  resetUser(user: User): Observable<User> {
    return this.http
      .put(`${this.environment.api.url}${this.environment.api.endpoints.users}/${user.id}/reset`, {})
      .pipe(switchMap(() => this.http.get<User>(`${this.environment.api.url}${this.environment.api.endpoints.users}/${user.id}`)));
  }

  updateUser(user: User): Observable<User> {
    return this.http.put(`${this.environment.api.url}${this.environment.api.endpoints.users}/${user.id}`, user).pipe(map(() => user));
  }

  deleteUser(user: User): Observable<any> {
    return this.http.delete(`${this.environment.api.url}${this.environment.api.endpoints.users}/${user.id}`).pipe(map(() => user));
  }

  getBusinessProfilesByUserId(userId: string): Observable<BusinessProfile[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    if (userId) {
      params = params.set('userId', userId);
    }

    return this.http
      .get(`${this.environment.api.url}${this.environment.api.endpoints.businessProfiles}`, { params })
      .pipe(map((response: { content: any; meta: any }) => response.content));
  }

  getById(userId: string): Observable<User> {
    return this.http.get<User>(`${this.environment.api.url}${this.environment.api.endpoints.users}/${userId}`);
  }

  getCarmUrl(): string {
    return this.environment.carmUrl ?? '';
  }
}
