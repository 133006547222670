import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'oyan-ui-mat-menu-list',
  templateUrl: './mat-menu-list.component.html',
  styleUrls: ['./mat-menu-list.component.scss']
})
export class MatMenuListComponent {
  @Input() data: unknown[];
  @Input() placeholder: string;
  @Input() displayKey: string;
  @Input() icon = 'add';
  @Input() noDataMessage = 'IOT_DICTIONARY.NO_DATA_FOUND';

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() clear: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectItem: EventEmitter<unknown> = new EventEmitter<unknown>();
}
