import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';

import { ImageSelectorService } from './services/image-selector.service';

@Component({
  selector: 'iot-platform-ui-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss']
})
export class ImageSelectorComponent implements OnInit, OnChanges {
  @Input() galleryPath!: string;
  @Input() lastImageSaved!: string;
  @Input() showSlider = true;

  @Output() selectImage: EventEmitter<string> = new EventEmitter<string>();
  @Output() imagesReady: EventEmitter<string[]> = new EventEmitter<string[]>();

  images: string[] = [];
  imagesDisplayed$ = new Subject<string[]>();
  imagesDisplayed: string[] = [];
  imagePreview!: string;
  index!: number;

  constructor(private imageSelectorService: ImageSelectorService) {}

  ngOnInit() {
    this.setUpGallery();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lastImageSaved?.currentValue) {
      this.imagePreview = changes.lastImageSaved?.currentValue;
    }
  }

  setUpGallery(): void {
    this.imageSelectorService.getGallery(this.galleryPath).subscribe((gallery: string[]) => {
      this.images = gallery;
      this.imagesReady.emit(gallery);
      this.imagePreview = this.lastImageSaved ?? this.images[0];
      const index = this.lastImageSaved ? this.images.indexOf(this.lastImageSaved) : 0;
      if (this.images.length > 4) {
        if (index <= this.images.length - 4) {
          this.index = index;
          this.imagesDisplayed = this.images.slice(this.index);
        } else {
          this.index = this.images.length - 4;
          this.imagesDisplayed = this.images.slice(this.images.length - 4);
        }
      } else {
        this.imagesDisplayed = this.images;
      }
      this.imagesDisplayed$.next(this.imagesDisplayed);
    });
  }

  onSelect(photo: string): void {
    this.imagePreview = photo;
    this.selectImage.emit(photo);
  }

  showNext(): void {
    if (this.index === this.images.length - 4) {
      this.imagesDisplayed = this.images.slice(this.index);
    } else {
      this.index++;
      this.imagesDisplayed = this.images.slice(this.index);
    }
    this.imagesDisplayed$.next(this.imagesDisplayed);
  }

  showPrevious(): void {
    if (this.index === 0) {
      this.imagesDisplayed = this.images.slice(this.index);
    } else {
      this.index--;
      this.imagesDisplayed = this.images.slice(this.index);
    }
    this.imagesDisplayed$.next(this.imagesDisplayed);
  }
}
