import { Product, Site } from '@iot-platform/models/oyan';
import { createAction, props } from '@ngrx/store';

export const addProductToSite = createAction('[OYAN SITE/PRODUCTS] Add product to site', props<{ product: Product; site: Site }>());
export const addProductToSiteSuccess = createAction('[OYAN SITE/PRODUCTS] Add product to site Success', props<{ product: Product; site: Site }>());
export const addProductToSiteFailure = createAction('[OYAN SITE/PRODUCTS] Add product to site Failure', props<{ error: unknown }>());

export const removeProductFromSite = createAction('[OYAN SITE/PRODUCTS] Remove product from site', props<{ product: Product; site: Site }>());
export const removeProductFromSiteSuccess = createAction('[OYAN SITE/PRODUCTS] Remove product from site Success', props<{ product: Product; site: Site }>());
export const removeProductFromSiteFailure = createAction('[OYAN SITE/PRODUCTS] Remove product from site Failure', props<{ error: unknown }>());
