<grid-engine-grid-page
  [grid]="internalGrid"
  [gridSort]="gridSort"
  [visibleNodeId]="visibleNodeId"
  [userPermissions]="userPermissions"
  (dispatchMasterViewEngineEvent)="dispatchMasterViewEngineEvent.emit($event)"
  (dispatchGridEvent)="onDispatchGridEvent($event)"
  (pageChange)="onPageChange($event)"
  (sortChange)="onSortChange($event)"
  (selectRow)="onSelectRow($event)"
></grid-engine-grid-page>
