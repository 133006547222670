<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'DEVICES.CONNECTORS.EDIT_FORM.TITLE' | translate: param }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content p-20" fxLayout="row">
    <form [formGroup]="editConnectorFrom" class="full-width" fxLayout="column" fxLayoutGap="10px">

      <mat-form-field fxFlex color="accent">
        <input matInput formControlName="name" placeholder="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.NAME' | translate }}" #nameInput [maxlength]="nameMaxLength" required/>
        <mat-error *ngIf="name.invalid">{{ 'DEVICES.CONNECTORS.EDIT_FORM.ERROR_MESSAGE.REQUIRED' | translate }}</mat-error>
        <mat-hint align="start" *ngIf="displayDuplicateMessage$ | async">
          <span class="dialog-card-input-error">{{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_NAME' | translate }}</span>
        </mat-hint>
        <mat-hint align="end">{{ nameInput.value?.length || 0 }}/{{ nameMaxLength }}</mat-hint>
      </mat-form-field>

      <mat-form-field color="accent">
        <mat-select formControlName="entities" placeholder="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.ATTACHED_ENTITIES' | translate }}" multiple required (selectionChange)="checkConnectorNameUnicity()">
          <mat-select-trigger *ngIf="entities.value">{{ getEntitiesFormatted() }}</mat-select-trigger>
          <mat-option [disabled]="disableEntity(entity)" *ngFor="let entity of attachedEntitiesNotVisible?.concat(entityList)" [value]="entity">{{ entity.label }}</mat-option>
        </mat-select>
        <mat-error *ngIf="entities.value.length > entitiesMaxLength">{{ 'DEVICES.CONNECTORS.EDIT_FORM.ERROR_MESSAGE.TOO_MANY_ENTITIES' | translate }}</mat-error>
        <mat-hint align="end">{{ entities.value?.length || 0 }}/{{ entitiesMaxLength }}</mat-hint>
      </mat-form-field>

      <mat-form-field fxFlex color="accent">
        <input
          matInput
          formControlName="description"
          placeholder="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.DESCRIPTION' | translate }}"
          #descriptionInput
          [maxlength]="descriptionMaxLength"
        />
        <mat-hint align="end">{{ descriptionInput.value?.length || 0 }}/{{ descriptionMaxLength }}</mat-hint>
      </mat-form-field>

    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="close()">{{ 'IOT_DICTIONARY.CANCEL' | translate | uppercase }}</button>
    <button mat-raised-button class="button-regular" color="accent" [disabled]="!editConnectorFrom.valid || !editConnectorFrom.touched || (isDisabled$ | async)" (click)="save()">
      {{ 'IOT_DICTIONARY.UPDATE' | translate | uppercase }}
    </button>
  </mat-card-actions>
</mat-card>
