import { Component, EventEmitter, Input, Output } from '@angular/core';

import { User, UserPreferences } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'iot-platform-users-user-profile-preferences',
  templateUrl: './user-profile-preferences.component.html',
  styleUrls: ['./user-profile-preferences.component.scss']
})
export class UserProfilePreferencesComponent {
  @Input() currentUser: User;
  @Input() preferences: UserPreferences;
  @Input() withNotifications = true;

  @Output() changeUserPreferences: EventEmitter<User> = new EventEmitter<User>();

  today = moment();
  defaultAppDateFormat = 'yyyy-MM-DD HH:mm:ss';
  defaultAppNumberFormat = 'fr';
  defaultAppNumberOfDecimal = 3;
  defaultFilterEngineState = false;

  constructor(private readonly translateService: TranslateService) {}

  getNotifications() {
    const sms = this.currentUser.notificationSMS === true ? this.translateService.instant('USERS.PROFILE_PREFERENCES.SMS') + ', ' : '';
    const primaryEmail = this.currentUser.notificationEmail === true ? this.translateService.instant('USERS.PROFILE_PREFERENCES.PRIMARY_EMAIL') + ', ' : '';
    const secondEmail =
      this.currentUser.notificationSecondaryEmail === true ? this.translateService.instant('USERS.PROFILE_PREFERENCES.SECONDARY_EMAIL') + ', ' : '';

    let response = sms + primaryEmail + secondEmail;
    response = response.substring(0, response.length - 2);
    return response;
  }
}
