import { FavoriteView, Filter } from '@iot-platform/models/common';
import { Action, createReducer, on } from '@ngrx/store';

import { AuthBusinessProfilesPageActions } from '../../../../../../auth/src/lib/state/actions';
import { FavoriteViewsActions } from '../actions';

export const favoriteViewsUiFeatureKey = 'favoriteViewsUi';

interface CurrentFavoriteViews {
  sites: string;
  assets: string;
  devices: string;
  assetEvents: string;
  deviceEvents: string;
}

interface CurrentFilters {
  sites: Filter[];
  assets: Filter[];
  devices: Filter[];
  assetEvents: Filter[];
  deviceEvents: Filter[];
}

export interface State {
  currentFavoriteViews: CurrentFavoriteViews;
  currentFilters: CurrentFilters;

  loading: boolean;
  loaded: boolean;
  error?: any;
}

export const initialState: State = {
  currentFavoriteViews: { sites: null, assets: null, devices: null, assetEvents: null, deviceEvents: null },
  currentFilters: { sites: [], assets: [], devices: [], assetEvents: [], deviceEvents: [] },
  loading: false,
  loaded: false
};

const favoriteViewsReducer = createReducer(
  initialState,
  on(FavoriteViewsActions.addFavoriteView, (state) => ({ ...state, loaded: false, loading: true })),
  on(FavoriteViewsActions.addFavoriteViewError, (state, { error }) => ({ ...state, loaded: false, loading: false, error: error })),
  on(FavoriteViewsActions.addFavoriteViewSuccess, (state, { favoriteView }) => ({
    ...state,
    loaded: true,
    loading: false,
    currentFavoriteViews: setCurrentFavoriteViews(state.currentFavoriteViews, favoriteView.masterView, favoriteView),
    currentFilters: setCurrentFilters(state.currentFilters, favoriteView.masterView, favoriteView.filters)
  })),
  on(FavoriteViewsActions.loadFavoriteViews, (state) => ({ ...state, loaded: false, loading: true })),
  on(FavoriteViewsActions.loadFavoriteViewsError, (state, { error }) => ({ ...state, loaded: false, loading: false, error: error })),
  on(FavoriteViewsActions.loadFavoriteViewsSuccess, (state, { favoriteViews }) => ({
    ...state,
    loaded: true,
    loading: false
  })),
  on(FavoriteViewsActions.getCountForFavoriteViewSuccess, (state, { favoriteView }) => ({
    ...state,
    loaded: true,
    loading: false
  })),
  on(FavoriteViewsActions.updateFavoriteView, (state) => ({ ...state, loaded: false, loading: true })),
  on(FavoriteViewsActions.updateFavoriteViewError, (state, { error }) => ({ ...state, loaded: false, loading: false, error: error })),
  on(FavoriteViewsActions.updateFavoriteViewSuccess, (state, { favoriteView }) => ({
    ...state,
    loaded: true,
    loading: false,
    currentFavoriteViews: setCurrentFavoriteViews(state.currentFavoriteViews, favoriteView.masterView, favoriteView),
    currentFilters: setCurrentFilters(state.currentFilters, favoriteView.masterView, favoriteView.filters)
  })),
  on(FavoriteViewsActions.setCurrentFavoriteView, (state, { masterView, favoriteView }) => ({
    ...state,
    currentFavoriteViews: setCurrentFavoriteViews(state.currentFavoriteViews, masterView, favoriteView),
    currentFilters: favoriteView
      ? setCurrentFilters(state.currentFilters, masterView, favoriteView.filters)
      : setCurrentFilters(state.currentFilters, masterView, [])
  })),
  on(FavoriteViewsActions.setCurrentFilters, (state, { masterView, filters }) => ({
    ...state,
    currentFilters: setCurrentFilters(state.currentFilters, masterView, filters)
  })),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return favoriteViewsReducer(state, action);
}

function setCurrentFavoriteViews(currentFavoriteViews: CurrentFavoriteViews, masterView: string, favoriteView: FavoriteView): any {
  const newCurrentFavoriteView = { ...currentFavoriteViews };
  switch (masterView) {
    case 'sites':
      newCurrentFavoriteView.sites = favoriteView ? favoriteView.id : null;
      break;
    case 'assets':
      newCurrentFavoriteView.assets = favoriteView ? favoriteView.id : null;
      break;
    case 'devices':
      newCurrentFavoriteView.devices = favoriteView ? favoriteView.id : null;
      break;
    case 'asset-events':
      newCurrentFavoriteView.assetEvents = favoriteView ? favoriteView.id : null;
      break;
    case 'device-events':
      newCurrentFavoriteView.deviceEvents = favoriteView ? favoriteView.id : null;
      break;
    default:
  }

  return newCurrentFavoriteView;
}

function setCurrentFilters(currentFilters: CurrentFilters, masterView: string, filters: Filter[]): any {
  const newCurrentFilters = { ...currentFilters };
  switch (masterView) {
    case 'sites':
      newCurrentFilters.sites = filters;
      break;
    case 'assets':
      newCurrentFilters.assets = filters;
      break;
    case 'devices':
      newCurrentFilters.devices = filters;
      break;
    case 'asset-events':
      newCurrentFilters.assetEvents = filters;
      break;
    case 'device-events':
      newCurrentFilters.deviceEvents = filters;
      break;
    default:
  }
  return newCurrentFilters;
}

export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
export const getError = (state: State) => state.error;

export const getCurrentFavoriteViews = (state: State) => state.currentFavoriteViews;
export const getCurrentFavoriteViewIdForAssets = (state: State) => state.currentFavoriteViews.assets;
export const getCurrentFavoriteViewIdForSites = (state: State) => state.currentFavoriteViews.sites;
export const getCurrentFavoriteViewIdForDevices = (state: State) => state.currentFavoriteViews.devices;
export const getCurrentFavoriteViewIdForAssetEvents = (state: State) => state.currentFavoriteViews.assetEvents;
export const getCurrentFavoriteViewIdForDeviceEvents = (state: State) => state.currentFavoriteViews.deviceEvents;

export const getCurrentFilters = (state: State) => state.currentFilters;
export const getCurrentFiltersForAssets = (state: State) => state.currentFilters.assets;
export const getCurrentFiltersForSites = (state: State) => state.currentFilters.sites;
export const getCurrentFiltersForDevices = (state: State) => state.currentFilters.devices;
export const getCurrentFiltersForAssetEvents = (state: State) => state.currentFilters.assetEvents;
export const getCurrentFiltersForDeviceEvents = (state: State) => state.currentFilters.deviceEvents;
