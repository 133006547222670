<iot-platform-ui-toolbar-v2
  [name]="'ADMIN.ORGANIZATIONS.TOOLBAR_TITLE' | translate"
  [total]="(organizations$ | async)?.length"
  [isDataLoaded]="!(organizationsPendingStatus$ | async)"
  [buttonList]="organizationsButtonList"
  [size]="toolbarSize"
  (dispatchToolbarEvent)="onToolbarEvent($event)"
></iot-platform-ui-toolbar-v2>

<div class="bp-page">
  <div fxFlex="30" class="full-height-with-overflow-y">
    <iot4bos-backoffice-ui-organizations-list
      [isTopLevelAdmin]="isTopLevelAdmin$ | async"
      [canCreateOrganization]="canCreateOrganization"
      [canUpdateOrganization]="canUpdateOrganization"
      [organizations]="organizations$ | async"
      [selectedOrganization]="selectedOrganization$ | async"
      (addOrganization)="onAddOrganization($event)"
      (selectOrganization)="onSelectOrganization($event)"
      (lockUnlockOrganization)="onLockUnlockOrganization($event)"
    ></iot4bos-backoffice-ui-organizations-list>
  </div>

  <div fxFlex="70" class="full-height-width">
    <iot4bos-backoffice-ui-organization-detail
      [organization]="selectedOrganization$ | async"
      [roles]="roles$ | async"
      [businessProfiles]="businessProfiles$ | async"
      [tags]="tags$ | async"
      [administrators]="administrators$ | async"
      [isCurrentUserAdmin]="isAdmin$ | async"
      [isTopLevelAdmin]="isTopLevelAdmin$ | async"
      [adminConceptsFromEntitySession]="adminConceptsFromEntitySession$ | async"
      [selectedRole]="selectedRole$ | async"
      [administratorsPendingStatus]="administratorsPendingStatus$ | async"
      [businessProfilesPendingStatus]="businessProfilesPendingStatus$ | async"
      [rolesPendingStatus]="rolesPendingStatus$ | async"
      [tagsPendingStatus]="tagsPendingStatus$ | async"
      (addBusinessProfile)="onAddBusinessProfile($event)"
      (addRole)="onAddRole($event)"
      (addAdministratorToOrganization)="onAddAdministratorToOrganization()"
      (authorizationsChanged)="onAuthorizationsChanged($event)"
      (selectRole)="onSelectRole($event)"
      (renameRole)="onRenameRole($event)"
      (updateOrganization)="onUpdateOrganization($event)"
      (deleteOrganization)="onDeleteOrganization($event)"
      (deleteBusinessProfile)="onDeleteBusinessProfile($event)"
      (selectBusinessProfile)="onSelectBusinessProfile($event)"
      (deleteRole)="onDeleteRole($event)"
      (addTags)="onAddTags($event)"
      (manageTags)="onManageTagsForOrganization($event)"
      (manageConcepts)="onManageConceptsForOrganization($event)"
      (lockUnlockOrganization)="onLockUnlockOrganization($event)"
    >
    </iot4bos-backoffice-ui-organization-detail>
  </div>
</div>
