import { Injectable } from '@angular/core';
import { BaseFacade, CommonApiRequest, Filter, Pagination } from '@iot-platform/models/common';
import { Receiver } from '@iot-platform/models/oyan';
import { Store } from '@ngrx/store';
import { ReceiversActions } from '../actions';
import { ReceiversSelectors } from '../selectors/receivers.selectors';

@Injectable({
  providedIn: 'root'
})
export class ReceiversFacade extends BaseFacade<Receiver, Pagination, Filter> {
  constructor(protected store: Store, protected selector: ReceiversSelectors) {
    super(store, selector);
  }

  getAll(request?: CommonApiRequest) {
    this.store.dispatch(ReceiversActions.loadReceivers({ request }));
  }

  loadReceiverById(id: string) {
    this.store.dispatch(ReceiversActions.loadReceiverById({ id }));
  }

  setFilters(filters: Filter[]) {
    this.store.dispatch(ReceiversActions.setFilters({ filters }));
  }
}
