import { I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { TotalColumn } from './i4b-total.column';

export class TotalTagsColumn extends TotalColumn {
  columnId = 'db000b66-5b50-11ec-80a5-0023a40210d0-total-tags';
  className = TotalTagsColumn;

  dataLibs: [DataLibrary.SITES, DataLibrary.ASSETS, DataLibrary.DEVICES];
  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      { displayName: 'TOTAL TAGS', icon: 'label', ...customHeader },
      { ...customConfig, id: customConfig.id ? customConfig.id : 'totalTags', width: customOptions?.width ? customOptions.width : 60 },
      { customId: customConfig.id ? customConfig.id : 'totalTags', ...customOptions }
    );
  }
}
