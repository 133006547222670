import { DeviceEvent, Log } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadLogsByDeviceEventIdSuccess = createAction('[DeviceEventsByDevice] Load Logs By Device Event Id Success', props<{ logs: Log[] }>());
export const loadLogsByDeviceEventIdFailure = createAction('[DeviceEventsByDevice] Load Logs By Device Event Id Failure', props<{ error: any }>());
//
export const createLogByDeviceEventIdSuccess = createAction(
  '[DeviceEventsByDevice] Create Log By Device Event Id Success',
  props<{ log: Log; deviceEvent: DeviceEvent }>()
);
export const createLogByDeviceEventIdFailure = createAction('[DeviceEventsByDevice] Create Log By Device Event Id Failure', props<{ error: any }>());
