import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LoginSelectProfileComponent } from './login-select-profile.component';

@NgModule({
  imports: [CommonModule, MatProgressBarModule, FlexLayoutModule, TranslateModule, MatButtonModule, MatIconModule, MatTooltipModule],
  declarations: [LoginSelectProfileComponent],
  exports: [LoginSelectProfileComponent]
})
export class LoginSelectProfileModule {}
