import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UiModule } from '@iot-platform/iot-platform-ui';
import { IotPlatformPipesModule, NumberFormatPipeModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { TranslateModule } from '@ngx-translate/core';
import { FavoriteViewsModule } from '../../../../../shared/src/lib/favorite-views/favorite-views.module';
import { HomeFavoriteViewComponent } from './components/home-favorite-view/home-favorite-view.component';
import { HomeShellComponent } from './containers/home-shell/home-shell.component';
import { HomeService } from './services/home.service';

const myPageRoutes: Routes = [{ path: '', component: HomeShellComponent }];

@NgModule({
  imports: [SharedModule, UiModule, TranslateModule, RouterModule.forChild(myPageRoutes), IotPlatformPipesModule, FavoriteViewsModule, NumberFormatPipeModule],
  declarations: [HomeFavoriteViewComponent, HomeShellComponent],
  exports: [HomeFavoriteViewComponent, HomeShellComponent],
  providers: [HomeService]
})
export class HomeModule {}
