<div *ngIf="currentUser" fxLayout="column" class="user-profile-info-container" xmlns="http://www.w3.org/1999/html">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="user-profile-info-section-title">
    <h2 class="">{{ 'USERS.PROFILE_PREFERENCES.TITLE' | translate }}</h2>
    <hr class="user-profile-preferences-line" fxFlex />
    <button
      mat-icon-button
      [matTooltip]="'USERS.PROFILE_PREFERENCES.EDIT_TOOLTIP' | translate"
      matTooltipClass="regular-tooltip"
      matTooltipPosition="above"
      class="regular-round-button"
      (click)="changeUserPreferences.emit()"
    >
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <div fxLayout="column" fxLayoutGap="6px">

    <iot-platform-ui-detail-popup-display-property
      [property]="'USERS.PROFILE_PREFERENCES.LANGUAGE' | translate"
      [value]="'USERS.PROFILE_PREFERENCES.LANGUAGES.' + currentUser?.preferences?.appLanguage | translate"
    >
    </iot-platform-ui-detail-popup-display-property>

    <iot-platform-ui-detail-popup-display-property
      [property]="'IOT_DICTIONARY.COUNTRY' | translate"
      [value]="currentUser?.addressCountry ? ('IOT_DICTIONARY.COUNTRIES.' + currentUser?.addressCountry | translate) : '-'"
    >
    </iot-platform-ui-detail-popup-display-property>
  </div>
</div>
