import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BusinessProfile } from '@iot-platform/models/common';

import { PopupComponent } from '../../../../../iot-platform-ui/src/lib/ui/components/popup/popup.component';

@Component({
  selector: 'iot4bos-backoffice-ui-business-profiles-list',
  templateUrl: './business-profiles-list.component.html',
  styleUrls: ['./business-profiles-list.component.scss', '../../style/admin.style.scss']
})
export class BusinessProfilesListComponent implements OnInit {
  @Input() profiles: BusinessProfile[] = [];
  @Input() canUpdateBusinessProfile = false;
  @Input() canDeleteBusinessProfile = false;
  @Input() selectedBusinessProfile: BusinessProfile;
  @Input() navigateOnClick = false;

  @Output() selectBusinessProfile: EventEmitter<BusinessProfile> = new EventEmitter();
  @Output() deleteBusinessProfile: EventEmitter<BusinessProfile> = new EventEmitter<BusinessProfile>();

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  onDeleteBusinessProfileButtonClick(profile: BusinessProfile): void {
    this.confirmDeletion(profile);
  }

  onSelectBusinessProfileButtonClick(businessProfile: BusinessProfile): void {
    if (this.navigateOnClick) {
      this.selectBusinessProfile.emit(businessProfile);
    }
  }

  private confirmDeletion(businessProfileToDelete: BusinessProfile) {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'delete', value: businessProfileToDelete.name }
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.deleteBusinessProfile.emit(businessProfileToDelete);
      }
    });
  }

  getTooltip(profile: BusinessProfile): string {
    return profile.userTotal !== 0 && profile.roleTotal !== 0
      ? `Business Profile is linked to ${profile.userTotal} users and ${profile.roleTotal} roles`
      : profile.roleTotal !== 0
      ? `Business Profile is linked to ${profile.roleTotal} roles`
      : profile.userTotal !== 0
      ? `Business Profile is linked to ${profile.userTotal} users`
      : '';
  }

  isDeletePossible(profile: BusinessProfile): boolean {
    return profile.roleTotal === 0 && profile.userTotal === 0;
  }
}
