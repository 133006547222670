import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { GetUtils } from '@iot-platform/iot-platform-utils';

import { CustomCellParams } from './../custom-cell.params';

@Component({
  selector: 'grid-engine-array-of-objects-cell',
  templateUrl: './array-of-objects-cell.component.html',
  styleUrls: ['./array-of-objects-cell.component.scss']
})
export class ArrayOfObjectsCellComponent implements ICellRendererAngularComp {
  dataToDisplay;
  params: CustomCellParams;

  agInit(params: CustomCellParams) {
    this.params = params;
    this.dataToDisplay = params.value
      ?.filter((data) => {
        if (this.hasExpressions()) {
          return this.handleExpressions(data);
        }
        return data;
      })
      ?.map((data) =>
        this.params.cellOptions && this.params.cellOptions.attributes ? this.params.cellOptions.attributes.map((key: string) => data[key]).join(' ') : data
      )
      .join(this.params.cellOptions?.separator ?? ', ');
  }

  refresh(params: CustomCellParams): boolean {
    return false;
  }

  private applyExpression(elem: any, expression: any): boolean {
    const { key, value } = expression;
    if (expression.hasOwnProperty('key') && expression.hasOwnProperty('value')) {
      const _value = GetUtils.get(elem, key, null);
      if (_value !== null) {
        return _value === value;
      }
      console.error('Invalid expression : ', expression);
      return true;
    }
    return false;
  }

  private hasExpressions(): boolean {
    return this.params.cellOptions && this.params.cellOptions.expressions && !!this.params.cellOptions.expressions.length;
  }

  private handleExpressions(data: any): boolean {
    return this.params.cellOptions.expressions.map((expression) => this.applyExpression(data, expression)).some((b: boolean) => !!b);
  }
}
