<div fxLayout="column" style="width: 100%">
  <mat-form-field>
    <mat-label>Days to display</mat-label>
    <select [(ngModel)]="selected" matNativeControl required>
      <option *ngFor="let hour of hours" [value]="hour.value">{{ hour.viewValue }}</option>
    </select>
  </mat-form-field>

  <button (click)="onDisplayRoute()" mat-button>
    <mat-icon>route</mat-icon>LOAD ROUTE
  </button>

</div>
