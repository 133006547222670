import { Component, Inject, ViewChild } from '@angular/core';
import { BaseComponent } from '@iot-platform/iot-platform-ui';
import { AppName, BusinessProfile } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AUTH_CONFIG, AuthConfig } from '../../auth.config';

import {
  LoginChangeTemporaryPasswordFormComponent
} from '../../components/login-change-temporary-password-form/login-change-temporary-password-form.component';
import { LoginFormComponent } from '../../components/login-form/login-form.component';
import {
  LoginResetPasswordFormComponent
} from '../../components/login-reset-password-form/login-reset-password-form.component';
import { AuthService } from '../../services/auth.service';
import { AuthBusinessProfilesPageActions, AuthPageActions } from '../../state/actions';
import * as fromAuth from '../../state/reducers';

@Component({
  selector: 'iot-platform-login-shell',
  templateUrl: './login-shell.component.html',
  styleUrls: ['./login-shell.component.scss', '../../scss/style.scss']
})
export class LoginShellComponent extends BaseComponent {
  authConfig$: BehaviorSubject<AuthConfig | null> = new BehaviorSubject<AuthConfig | null>(null);
  loginWithEmailPassword$: Observable<boolean>;
  loggedIn$: Observable<boolean>;
  pending$: Observable<boolean>;
  businessProfiles$: Observable<BusinessProfile[]>;
  username = '';
  stepEmail$: Observable<boolean>;
  stepPassword$: Observable<boolean>;
  requireNewPassword$: Observable<boolean>;
  errorSignIn$: Observable<any>;
  errorEmail$: Observable<any>;
  errorPassword$: Observable<any>;
  cognitoUser: any;
  successMessage = '';
  AppName = AppName;

  @ViewChild(LoginFormComponent) loginFormComponent!: LoginFormComponent;
  @ViewChild(LoginResetPasswordFormComponent) loginResetPasswordFormComponent!: LoginResetPasswordFormComponent;
  @ViewChild(LoginChangeTemporaryPasswordFormComponent) loginChangePasswordFormComponent!: LoginChangeTemporaryPasswordFormComponent;

  constructor(
    protected authService: AuthService,
    protected store: Store,
    protected translateService: TranslateService,
    @Inject(AUTH_CONFIG) protected authConfig: AuthConfig
  ) {
    super();
    this.authConfig$.next(this.authConfig);
    this.subscriptions.push(this.translateService.get('LOGIN.CHANGE_PASSWORD.UPDATED_PASSWORD').subscribe((value: string) => (this.successMessage = value)));

    this.loggedIn$ = this.store.select(fromAuth.selectLoggedIn);
    this.pending$ = this.store.select(fromAuth.selectAuthPagePending);
    this.businessProfiles$ = this.store.select(fromAuth.selectBusinessProfilesForAccount);
    this.stepPassword$ = this.store.select(fromAuth.selectAuthPageStepPassword);
    this.stepEmail$ = this.store.select(fromAuth.selectAuthPageStepEmail);
    this.requireNewPassword$ = this.store.select(fromAuth.selectAuthPageRequireNewPassword);
    this.errorSignIn$ = this.store.select(fromAuth.selectAuthPageErrorSignIn);
    this.errorEmail$ = this.store.select(fromAuth.selectAuthPageErrorEmail);
    this.errorPassword$ = this.store.select(fromAuth.selectAuthPageErrorPassword);
    this.loginWithEmailPassword$ = this.store.select(fromAuth.selectDisplayEmailPasswordLogin);
  }

  onResetPassword() {
    this.store.dispatch(AuthPageActions.resetPassword());
  }

  onCancel() {
    this.store.dispatch(AuthPageActions.cancel());
  }

  onSendCode(username: string) {
    this.username = username;
    this.store.dispatch(AuthPageActions.forgotPassword({ username: this.username }));
  }

  onChangeForgottenPassword(userData: { username: string; code: string; password: string }) {
    if (this.loginResetPasswordFormComponent) {
      this.loginResetPasswordFormComponent.enableGauge = true;
    }
    this.store.dispatch(AuthPageActions.updatePasswordWithCode({ username: userData.username, code: userData.code, password: userData.password }));
  }

  onSignIn(userData) {
    if (this.loginFormComponent) {
      this.loginFormComponent.enableGauge = true;
    }
    this.store.dispatch(AuthPageActions.signIn({ username: userData.username, password: userData.password }));
  }

  onSignInWithSSO(): void {
    this.authService.signInWithSso();
  }

  onSignInWithEmailPassword(): void {
    this.store.dispatch(AuthPageActions.displayEmailPasswordLogin());
  }

  onChangeTemporaryPassword(newPwd: string): void {
    this.store.dispatch(AuthPageActions.changePassword({ user: this.cognitoUser, newPassword: newPwd }));
  }

  onSelectProfile(profile) {
    this.store.dispatch(AuthBusinessProfilesPageActions.selectBusinessProfile({ selectedBusinessProfile: profile, withRedirect: true }));
  }
}
