import { Graph } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const loadGraphsBySiteId = createAction('[Graph] Load Graphs By Site Id', props<{ siteId: string }>());
export const loadGraphsBySiteIdSuccess = createAction('[Graph] Load Graphs By Site Id Success', props<{ response: Graph[] }>());
export const loadGraphsBySiteIdFailure = createAction('[Graph] Load Graphs By Site Id Failure', props<{ error: any }>());

export const loadGraphById = createAction('[Graph] Load Graph By Id', props<{ graphId: string }>());
export const loadGraphByIdSuccess = createAction('[Graph] Load Graph By Id Success', props<{ response: Graph }>());
export const loadGraphByIdFailure = createAction('[Graph] Load Graph By Id Failure', props<{ error: any }>());

export const addGraph = createAction('[Graph] Add Graph', props<{ graph: Graph }>());
export const addGraphSuccess = createAction('[Graph] Add Graph Success', props<{ response: Graph }>());
export const addGraphFailure = createAction('[Graph] Add Graph Failure', props<{ error: any }>());

export const updateGraph = createAction('[Graph] Update Graph', props<{ graph: Graph }>());
export const updateGraphSuccess = createAction('[Graph] Update Graph Success', props<{ response: Graph }>());
export const updateGraphFailure = createAction('[Graph] Update Graph Failure', props<{ error: any }>());

export const deleteGraph = createAction('[Graph] Delete Graph', props<{ graph: Graph }>());
export const deleteGraphSuccess = createAction('[Graph] Delete Graph Success', props<{ response: Graph }>());
export const deleteGraphFailure = createAction('[Graph] Delete Graph Failure', props<{ error: any }>());
