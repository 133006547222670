<mat-card-content class="p-20 mve-detail-popup-container" *ngIf="connector" fxLayout="column" fxLayoutGap="20px">
  <section fxLayout="row" fxLayoutGap="20px">
    <div fxLayout="column" fxFlex="60%" fxLayoutGap="6px" fxLayoutAlign="start start">
      <span class="mve-detail-popup-title fw-600">{{ connector.id }}</span>
      <iot-platform-ui-detail-popup-display-property
        property="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.ATTACHED_ENTITIES' | translate }}:"
        value="{{ connectorEntities | infoDisplay }}"
      ></iot-platform-ui-detail-popup-display-property>
    </div>
    <div fxLayout="column" fxLayoutGap="6px" fxFlex="40%">
      <iot-platform-ui-detail-popup-display-property
        property="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.NAME' | translate }}:"
        value="{{ connector.name }}"
      ></iot-platform-ui-detail-popup-display-property>
      <iot-platform-ui-detail-popup-display-property
        property="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.DESCRIPTION' | translate }}:"
        value="{{ connector.description | infoDisplay }}"
      ></iot-platform-ui-detail-popup-display-property>
    </div>
  </section>

  <section fxLayout="column" fxLayoutGap="10px">
    <iot-platform-ui-detail-popup-section-header
      headerTitle="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.PARAMETERS' | translate }}"
    ></iot-platform-ui-detail-popup-section-header>
    <div fxLayout="row" fxLayoutGap="">
      <iot-platform-ui-detail-popup-display-property
        fxFlex="25"
        property="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.DEVICE_FAMILIES' | translate }}:"
        value="{{ connector.families | infoDisplay }}"
      ></iot-platform-ui-detail-popup-display-property>
      <iot-platform-ui-detail-popup-display-property
        fxFlex="25"
        property="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.SUPPORT' | translate }}:"
        value="{{ connector.support | infoDisplay }}"
      ></iot-platform-ui-detail-popup-display-property>
    <iot-platform-ui-detail-popup-display-property
        fxFlex="25"
        property="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.CONTEXT_ENTITY' | translate }}:"
        value="{{ connector.contextEntity | infoDisplay }}"
      ></iot-platform-ui-detail-popup-display-property>
    <iot-platform-ui-detail-popup-display-property
        fxFlex="25"
        property="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.KEY_IDS' | translate }}:"
        value="{{ connector.externalApiKeyIds | infoDisplay }}"
      ></iot-platform-ui-detail-popup-display-property>
    </div>
  </section>

  <section fxLayout="column" fxLayoutGap="10px">
    <iot-platform-ui-detail-popup-section-header
      headerTitle="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.COMMANDS' | translate }}"
    ></iot-platform-ui-detail-popup-section-header>
    <div fxLayout="row wrap" fxLayoutGap="">
      <iot-platform-ui-detail-popup-display-property
        fxFlex="25"
        property="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.AUTHENTICATION' | translate }}:"
        value="{{ connector.requestConfiguration?.authentication | infoDisplay }}"
      ></iot-platform-ui-detail-popup-display-property>
      <iot-platform-ui-detail-popup-display-property
        *ngFor="let command of commandList$ | async"
        fxFlex="25"
        property="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.' + command.toUpperCase() | translate }}:"
        [value]="connector.requestConfiguration?.commands?.includes(command).toString()"
      ></iot-platform-ui-detail-popup-display-property>
    </div>
  </section>

  <section fxLayout="column" fxLayoutGap="10px">
    <iot-platform-ui-detail-popup-section-header
      headerTitle="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.CONFIGURATOR' | translate }}"
    ></iot-platform-ui-detail-popup-section-header>
    <div fxLayout="row" fxLayoutGap="10px">
      <iot-platform-ui-detail-popup-display-property
        fxFlex="60%"
        property="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.URL' | translate }}:"
        value="{{ connector.configuration.url | infoDisplay }}"
      ></iot-platform-ui-detail-popup-display-property>
      <iot-platform-ui-detail-popup-display-property
        fxFlex="40%"
        property="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.SHARED_AUTHENTICATION' | translate }}:"
        value="{{ connector.configuration.sharedAuth | infoDisplay }}"
      ></iot-platform-ui-detail-popup-display-property>
    </div>
  </section>

  <iot-platform-ui-detail-popup-metadata-footer [data]="connector"></iot-platform-ui-detail-popup-metadata-footer>
</mat-card-content>
