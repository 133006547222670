import { DeviceStatus, Receiver } from '@iot-platform/models/oyan';
import { SiteBuilder } from './site.builder';

export class ReceiverBuilder {
  public static DEFAULT_RECEIVER = {
    id: '614b3854-2fd3-446b-9a6b-9b69d970c089',
    entityId: '0334edd2-9b2f-4069-be39-bb56ee74b40d',
    wardId: 'c657bfd1-8914-4c38-891c-254652567f81',
    clusterId: 676,
    site: SiteBuilder.build('e03b49bc-d878-47b7-86d7-7157e68872aa', '0010747171', { displayName: 'test demo 2' }),
    devEui: '7076FF0069050033',
    connected: false,
    name: '3299ff6d-3320-415e-9b97-a7efe81e67f5_0334edd2-9b2f-4069-be39-bb56ee74b40d_0010747171',
    status: DeviceStatus.DATAUP_SUCCESS,
    createdAt: '2021-06-16T15:59:44.334037+00:00',
    createdBy: null,
    updatedAt: '2021-07-13T00:00:26.291168+00:00',
    updatedBy: null
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static build = (id: string, wardId = '', params: Partial<Receiver> = ReceiverBuilder.DEFAULT_RECEIVER): Receiver | any => ({
    ...params,
    id,
    wardId
  });
}
