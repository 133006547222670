import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TopicSelfSubscriptionCellService {
  cachedSubscribersByTopic$ = new BehaviorSubject<{ [topicId: string]: string[] }>({});

  constructor(@Inject('environment') private readonly environment, private readonly http: HttpClient) {}

  public getSubscribersByTopicId(topicId: string): Observable<string[]> {
    if (this.cachedSubscribersByTopic$.getValue()[topicId] !== null && this.cachedSubscribersByTopic$.getValue()[topicId] !== undefined) {
      return this.cachedSubscribersByTopic$.pipe(map((subscribersByTopic: { [topicId: string]: string[] }) => subscribersByTopic[topicId]));
    } else {
      const updatedCache = this.cachedSubscribersByTopic$.getValue();
      updatedCache[topicId] = [];
      this.cachedSubscribersByTopic$.next(updatedCache);
      return this.http.get<{ content: string[] }>(`${this.environment.api.url}${this.environment.api.endpoints.topics}/${topicId}/users`).pipe(
        map((response) => {
          const subscribers = response.content;
          const updatedCache = this.cachedSubscribersByTopic$.getValue();
          updatedCache[topicId] = subscribers;
          this.cachedSubscribersByTopic$.next(updatedCache);
          return subscribers;
        })
      );
    }
  }
}
