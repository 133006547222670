<h2 class="login-regular-title">{{ 'LOGIN.CHANGE_PASSWORD.TITLE' | translate }}</h2>

<form [formGroup]="form" fxLayout="column">
  <mat-form-field fxFlex="100%" color="accent">
    <mat-icon matPrefix>lock</mat-icon>
    <input
      matInput
      formControlName="newPassword"
      type="password"
      [placeholder]="'LOGIN.CHANGE_PASSWORD.NEW_PASSWORD' | translate"
      (keyup)="sendPasswordToRules(newPassword.value)"
    />
    <mat-error *ngIf="newPassword.invalid">{{ 'LOGIN.ERROR_MESSAGE.PASSWORD' | translate }}</mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="100%" color="accent">
    <mat-icon matPrefix>lock</mat-icon>
    <input matInput formControlName="confirmNewPassword" type="password" [placeholder]="'LOGIN.CHANGE_PASSWORD.CONFIRM_PASSWORD' | translate" />
    <mat-error *ngIf="confirmNewPassword.invalid">{{ 'LOGIN.ERROR_MESSAGE.CONFIRM_PASSWORD' | translate }}</mat-error>
  </mat-form-field>

  <iot-platform-login-password-rules [password$]="password$"></iot-platform-login-password-rules>

  <div class="layout-login-error" fxLayout="column" [class.error]="hasError">
    <em>Password failed</em>
    <span>{{ hasError?.message }}</span>
  </div>

  <div class="layout-login-form-actions">
    <button class="layout-login-button" type="button" mat-button (click)="onCancel()">{{ 'LOGIN.BUTTON.CANCEL' | translate }}</button>
    <iot-platform-ui-progress-bar-button
      type="submit"
      [title]="'LOGIN.BUTTON.CONFIRM' | translate"
      [disabled]="!form.valid"
      [enableGauge]="enableGauge"
      (clickEvent)="onChangeTemporaryPassword()"
    ></iot-platform-ui-progress-bar-button>
  </div>
</form>
