import { Component, OnInit } from '@angular/core';
import { Gateway } from '@iot-platform/models/oyan';
import { CardAction } from '../../models/card-action.model';
import { CardEventType } from '../../models/enums/card-event-event-type.enum';
import { BaseCardComponent } from '../base-card/base-card.component';

@Component({
  selector: 'oyan-ui-gateway-card',
  templateUrl: './gateway-card.component.html',
  styleUrls: ['./gateway-card.component.scss']
})
export class GatewayCardComponent extends BaseCardComponent<Gateway> implements OnInit {
  ngOnInit(): void {
    this.actions = [{ label: 'SITES.CARD.DELETE', enabled: true, eventType: CardEventType.REMOVE_GATEWAY }];
  }

  onDispatchEvent(action: CardAction): void {
    this.dispatchEvent.emit({
      eventType: action.eventType,
      data: this.element
    });
  }
}
