import { Component, Input, OnInit } from '@angular/core';

enum ComputedLayoutDirection {
  ABOVE = 'ABOVE',
  RIGHT = 'RIGHT',
  BENEATH = 'BENEATH',
  LEFT = 'LEFT',
  NONE = 'NONE'
}

@Component({
  selector: 'iot-platform-ui-detail-popup-section-header',
  templateUrl: './detail-popup-section-header.component.html',
  styleUrls: ['./detail-popup-section-header.component.scss']
})
export class DetailPopupSectionHeaderComponent implements OnInit {
  @Input() headerTitle = '';
  @Input() separatorLinePosition: string = 'RIGHT';
  @Input() color?: string;
  @Input() loading = false;

  computedLayoutDirection!: string;

  constructor() {}

  ngOnInit() {
    this.computedLayoutDirection = ComputedLayoutDirection[this.separatorLinePosition.toUpperCase()];
  }
}
