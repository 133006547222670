import { AuthBusinessProfilesPageActions } from '@iot-platform/auth';
import { Subscriber } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { TopicSubscribedUsersDbActions, TopicSubscribedUsersUiActions } from '../actions';
//
export const topicsSubscribedUsersDbFeatureKey = 'topicsSubscribedUsersDb';

export interface State extends EntityState<Subscriber> {
  selectedSubscriberId: string;

  error: any;
}

export const adapter: EntityAdapter<Subscriber> = createEntityAdapter<Subscriber>({
  selectId: (subscriber: Subscriber) => subscriber.user.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedSubscriberId: null,
  error: null
});

export const topicsSubscribedProtocolsDbReducer = createReducer(
  initialState,
  on(TopicSubscribedUsersUiActions.listUsersSubscribedToTopicByTopicId, (state: State) => adapter.removeAll(state)),
  on(TopicSubscribedUsersDbActions.listUserslSubscribedToTopicByTopicIdSuccess, (state: State, { subscribers }) => adapter.setAll(subscribers, state)),
  on(TopicSubscribedUsersDbActions.listUsersubscribedToTopicByTopicIdFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(TopicSubscribedUsersDbActions.subscribeToTopicByUserIdSuccess, (state: State, { subscribedUser }) => adapter.addOne(subscribedUser, state)),
  //
  on(TopicSubscribedUsersDbActions.unsubscribeToTopicByUserIdSuccess, (state: State, { unsubscribedUser }) =>
    adapter.removeOne(unsubscribedUser.user.id, state)
  ),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return topicsSubscribedProtocolsDbReducer(state, action);
}

export const selectedSubscriberId = (state: State) => state.selectedSubscriberId;
