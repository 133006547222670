import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

import { CustomCellParams } from './../custom-cell.params';

@Component({
  selector: 'grid-engine-boolean-cell',
  templateUrl: './boolean-cell.component.html',
  styleUrls: ['./boolean-cell.component.scss']
})
export class BooleanCellComponent implements ICellRendererAngularComp {
  params: CustomCellParams;
  cellOptions: any;
  translateKey: string;
  translateStyle: string;
  data: any;

  agInit(params: CustomCellParams): void {
    this.params = params;
    this.cellOptions = this.params.cellOptions;
    this.translateKey = this.cellOptions?.translateKey ? this.cellOptions.translateKey : 'IOT_DICTIONARY.';
    this.data = this.getDataToDisplay();
    this.translateStyle = this.cellOptions?.class ? this.cellOptions.class[this.data] : '';
  }

  refresh(): boolean {
    return false;
  }

  getDataToDisplay(): string {
    switch (this.cellOptions?.mode) {
      case 'booleanAsText':
        return this.params.value ? this.cellOptions.dataToDisplayIfTrue : this.cellOptions.dataToDisplayIfFalse;

      case 'dataComparison':
        return this.params.value === this.cellOptions.comparisonKey ? this.cellOptions.dataToDisplayIfTrue : this.cellOptions.dataToDisplayIfFalse;

      default:
        return null;
    }
  }
}
