<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">
        {{ 'AUDIT_TRAIL.HISTORY_LOG_POPUP.TITLE' | translate: { elementName: data.elementName } }}
      </h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content history-logs-popup--card-content" fxLayout="row">
    <div *ngIf="historyLogsLoading" class="history-logs-popup--loader-container" fxFlex>
      <iot-platform-ui-card-loader></iot-platform-ui-card-loader>
    </div>

    <div *ngIf="!historyLogsLoading && !historyLogs.length" fxFlex fxLayoutAlign="center center">
      <span>{{ 'AUDIT_TRAIL.HISTORY_LOG_POPUP.NO_LOGS_FOUND' | translate }}</span>
    </div>
    <section
      *ngIf="!historyLogsLoading && !!historyLogs.length"
      class="history-logs-popup--main-section"
      [ngClass]="{ invisible: historyLogsLoading, visible: !historyLogsLoading }"
      fxFlex="50%"
      fxLayout="column"
    >
      <div
        class="history-logs-popup--log-summary"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        *ngFor="let historyLog of historyLogs"
        [ngClass]="{ 'history-logs-popup--selected-log': historyLog.id === selectedHistoryLog?.id }"
        (click)="selectHistoryLog(historyLog)"
      >
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="75%">
          <span [ngClass]="{ 'history-logs-popup--log-summary-system-modification': !historyLog.context.userId }">{{
            !!historyLog.context.userId ? (historyLog.context.userId | userName) : ('AUDIT_TRAIL.HISTORY_LOG_POPUP.SYSTEM' | translate)
          }}</span>
          &nbsp; <span>{{ 'AUDIT_TRAIL.HISTORY_LOG_POPUP.CONCEPT_EVENTS_LIST.' + historyLog.conceptEvent.toUpperCase() | translate }}</span> &nbsp;
          <span>{{ 'AUDIT_TRAIL.HISTORY_LOG_POPUP.TITLE_CONCEPTS.' + historyLog.concept.toUpperCase() | translate }}</span> &nbsp;
          <span
            fxFlex
            *ngIf="!!historyLog.updatedAttributes.length"
            class="history-logs-popup--log-summary-attributes"
            [matTooltip]="historyLog.updatedAttributes.join(', ')"
            matTooltipClass="regular-tooltip"
            >"{{ historyLog.updatedAttributes.join('", "') }}"</span
          >
        </div>
        <div>{{ historyLog.datetime | dateFormat }}</div>
      </div>
    </section>

    <section
      *ngIf="!historyLogsLoading && !!historyLogs.length"
      class="history-logs-popup--main-section history-logs-popup--detail-section"
      [ngClass]="{ invisible: historyLogsLoading, visible: !historyLogsLoading && !!historyLogs.length }"
      fxFlex="50%"
    >
      <div *ngIf="!selectedHistoryLog && !selectedLodLoading" fxFlex fxLayoutAlign="center center">
        <span>{{ 'AUDIT_TRAIL.HISTORY_LOG_POPUP.SELECTED_LOG_PLACEHOLDER' | translate }}</span>
      </div>

      <div *ngIf="selectedLodLoading" class="history-logs-popup--loader-container" fxFlex>
        <iot-platform-ui-card-loader></iot-platform-ui-card-loader>
      </div>

      <div *ngIf="selectedHistoryLog && !selectedLodLoading" class="full-width" fxLayout="column" fxLayoutGap="10px">
        <div *ngFor="let updatedAttribute of selectedHistoryLog.updatedAttributes" fxLayout="row">
          <div fxFlex="50" class="history-logs-popup--detail-section-old-image">
            <pre>{{ updatedAttribute }} : {{ (getParsedAttribute(selectedHistoryLog.oldImage, updatedAttribute) | json) ?? 'null' }} </pre>
          </div>
          <div fxFlex="50" class="history-logs-popup--detail-section-new-image">
            <pre>{{ updatedAttribute }} : {{ (getParsedAttribute(selectedHistoryLog.newImage, updatedAttribute) | json) ?? 'null' }}</pre>
          </div>
        </div>
        <div *ngIf="!selectedHistoryLog.oldImage && selectedHistoryLog.newImage">
          <div fxFlex="50" class="history-logs-popup--detail-section-new-image">
            <pre> {{ selectedHistoryLog.newImage | json }} </pre>
          </div>
        </div>
        <div *ngIf="!selectedHistoryLog.newImage && selectedHistoryLog.oldImage">
          <div fxFlex="50" class="history-logs-popup--detail-section-old-image">
            <pre> {{ selectedHistoryLog.oldImage | json }} </pre>
          </div>
        </div>
      </div>
    </section>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button class="button-regular" color="accent" (click)="close()">{{ 'IOT_DICTIONARY.CLOSE' | translate }}</button>
  </mat-card-actions>
</mat-card>
