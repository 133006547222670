import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'iot-platform-ui-basic-header',
  templateUrl: './basic-header.component.html',
  styleUrls: ['./basic-header.component.scss'],
})
export class BasicHeaderComponent implements OnInit {
  @Input() rawData: any;
  @Input() data: any;
  @Input() columnFormat: any;

  @Output() dispatchEvent;
  constructor() {}

  ngOnInit() {}
}
