import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { BusinessProfile, User } from '@iot-platform/models/common';
import { SortUtil } from '../../../../../../../shared/src/lib/utils/sort.util';

@Component({
  selector: 'iot-platform-users-user-related-business-profiles',
  templateUrl: './user-related-business-profiles.component.html',
  styleUrls: ['./user-related-business-profiles.component.scss']
})
export class UserRelatedBusinessProfilesComponent implements OnInit, OnChanges {
  @Input() currentUser: User;

  bPSortedByEntityLevel: BusinessProfile[];
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('currentUser')) {
      this.bPSortedByEntityLevel = Object.assign([], this.currentUser?.businessProfiles);
      this.bPSortedByEntityLevel.sort(SortUtil.sortByProperty('level'));
    }
  }
}
