import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DateFormatPipe } from './date-format.pipe';

@NgModule({
  imports: [CommonModule],
  providers: [DateFormatPipe],
  declarations: [DateFormatPipe],
  exports: [DateFormatPipe]
})
export class DateFormatModule {}
