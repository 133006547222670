import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { CustomEncoder, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { Entity, Filter, PlatformRequest, PlatformResponse, TagCategory } from '@iot-platform/models/common';

import { Asset, Device, I4BBulkOperationApiResponse, Site } from '@iot-platform/models/i4b';

import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SitesService {
  constructor(@Inject('environment') private environment, public httpClient: HttpClient, private storage: LocalStorageService) {}

  getAllSites(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter: Filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient.get<Site[]>(`${this.environment.api.url}${this.environment.api.endpoints.sites}`, { params }).pipe(
      map((data: any) => {
        return {
          data: data.content,
          currentPage: data.page.curPage,
          hasMore: data.page.hasMore,
          limit: data.page.limit,
          maxPage: data.page.maxPage,
          total: data.page.total
        };
      })
    );
  }

  getSiteById(siteId: string): Observable<Site> {
    return this.httpClient.get<Site>(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}`);
  }

  getSiteByIdWithAssetsAndDevices(siteId: string, activeId: string, origin: string): Observable<{ site: Site; assets: Asset[]; devices: Device[] }> {
    this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY, siteId);
    this.storage.set(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY, activeId);
    this.storage.set(LocalStorageKeys.STORAGE_ORIGIN_KEY, origin);

    const site$ = this.getSiteById(siteId);
    const assets$ = this.getAssetsBySiteId({ limit: 25, page: 0, filters: [{ criteriaKey: 'siteId', value: siteId }] });
    const devices$ = this.getDevicesBySiteId({ limit: 25, page: 0, filters: [{ criteriaKey: 'siteId', value: siteId }] });

    const combined$ = combineLatest([site$, assets$, devices$]);

    const subscribe = combined$.pipe(
      map(([site, assets, devices]) => {
        return { site: site, assets: assets.data, devices: devices.data };
      })
    );

    return subscribe;
  }

  getAssetsBySiteId(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter: Filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient.get<PlatformResponse>(`${this.environment.api.url + this.environment.api.endpoints.assets}`, { params }).pipe(
      map((data: any) => {
        return {
          data: data.content,
          currentPage: data.page.curPage,
          hasMore: data.page.hasMore,
          limit: data.page.limit,
          maxPage: data.page.maxPage,
          total: data.page.total
        };
      })
    );
  }

  getDevicesBySiteId(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter: Filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient.get<PlatformResponse>(`${this.environment.api.url + this.environment.api.endpoints.devices}`, { params }).pipe(
      map((data: any) => {
        return {
          data: data.content,
          currentPage: data.page.curPage,
          hasMore: data.page.hasMore,
          limit: data.page.limit,
          maxPage: data.page.maxPage,
          total: data.page.total
        };
      })
    );
  }

  selectSite(selectedSite: Site): Observable<Site> {
    this.storage.set(LocalStorageKeys.STORAGE_MV_SITES_TABLE_STATE_KEY, JSON.stringify(selectedSite));
    return of(selectedSite);
  }

  getEntities(): Observable<Entity[]> {
    return this.httpClient.get<Entity[]>(this.environment.api.url + this.environment.api.endpoints.entities).pipe(map((entities: any) => entities.content));
  }

  save(site: Site): Observable<Site> {
    return this.httpClient.post<Site>(this.environment.api.url + this.environment.api.endpoints.sites, site);
  }

  update(site: Site): Observable<Site> {
    return this.httpClient.put<Site>(this.environment.api.url + this.environment.api.endpoints.sites + '/' + site.id, site);
  }

  delete(site: Site): Observable<Site> {
    return this.httpClient.delete<any>(this.environment.api.url + this.environment.api.endpoints.sites + '/' + site.id).pipe(map((_) => site));
  }

  getTagsBySiteId(siteId: string): Observable<TagCategory[]> {
    return this.httpClient
      .get(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}/tags`)
      .pipe(map((data: { page: {}; content: TagCategory[] }) => data.content));
  }

  putTagsBySiteId(siteId: string, tags: TagCategory[]): Observable<TagCategory[]> {
    return this.httpClient
      .put(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}/tags`, {
        tags: tags
      })
      .pipe(map((data: { page: {}; content: TagCategory[] }) => data.content));
  }

  getAssociationsBySiteId(siteId: any): Observable<any> {
    return this.httpClient.get(`${this.environment.api.url}${this.environment.api.endpoints.sites}/${siteId}/associations`);
  }

  getSiteFromERP(data: { source: string; shipto: string }): Observable<any> {
    return this.httpClient.get(`${this.environment.api.url}${this.environment.api.endpoints.erpProxySAP}/${data.shipto}`);
  }

  getCountries(): Observable<any[]> {
    return this.httpClient.get<any[]>(`assets/data/countries.json`);
  }

  getDeviceFamilies(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.environment.api.url}${this.environment.api.endpoints.connector}/families`);
  }

  getTotalDevicesByFamilyAndSiteId(request: PlatformRequest): Observable<number> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient
      .get<Device[]>(`${this.environment.api.url}${this.environment.api.endpoints.devices}`, { params })
      .pipe(map((data: any) => data.page.total));
  }

  bulkAddOrRemoveTag(isAddition: boolean, sitesIds: string[], tagLabelId: string): Observable<I4BBulkOperationApiResponse> {
    return this.httpClient.post<I4BBulkOperationApiResponse>(
      `${this.environment.api.url}${isAddition ? this.environment.api.endpoints.sitesBulkAddTag : this.environment.api.endpoints.sitesBulkRemoveTag}`,
      { sitesIds, tagLabelId }
    );
  }
}
