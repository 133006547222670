<div data-cy='iot-platform-ui-date-range' fxLayout='column' fxLayoutAlign='start center'>
  <form [formGroup]='dateForm'
        data-cy='iot-platform-ui-date-range-form'
        fxLayout='row' fxLayout.lt-sm='column'
        fxLayoutAlign='space-between center'
        fxLayoutAlign.lt-sm='center center'>
    <div fxLayout='row' fxLayout.lt-sm='row wrap' fxLayoutAlign='center center' fxLayoutGap='15px' fxFlex='45%' fxFlex.lt-sm='100%'
         class='from-block'>
      <mat-form-field class='datepicker' color='accent'>
        <input
          data-cy='iot-platform-ui-date-range-start-date'
          matInput
          formControlName='startDate'
          [required]='required'
          [matDatepicker]='fromPicker'
          [placeholder]='placeholderFrom | translate'
          [max]='endDate?.value'
          (focus)='fromPicker.open()'
        />
        <mat-datepicker-toggle [disableRipple]='true' matSuffix [for]='fromPicker'>
          <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>

        <mat-hint *ngIf='startDate.value' (click)='resetStartDate()'>{{ placeholderClear | translate }}</mat-hint>
        <mat-error
          *ngIf='startDate.invalid && startDate.errors.required'>{{ 'FORM.ERROR_MESSAGE.REQUIRED' | translate }}</mat-error>
        <mat-error
          *ngIf='startDate.invalid && startDate.errors.matDatepickerMax'>{{ 'FORM.ERROR_MESSAGE.INVALID_DATE_RANGE' | translate }}</mat-error>
      </mat-form-field>

      <div [ngClass]="{ 'timepicker-visible': startDate.value }" fxLayout='row' fxLayoutAlign='start center' fxLayoutAlign.lt-md='center center'
           fxLayoutGap='5px' class='timepicker-hidden'>
        <mat-form-field class='timepicker-select' color='accent'>
          <mat-select data-cy='iot-platform-ui-date-range-start-hours' formControlName='startHours' [placeholder]='placeholderHours | translate'>
            <mat-option data-cy='iot-platform-ui-date-range-start-hours-option' *ngFor='let hour of hours' [value]='hour'>{{ hour }}</mat-option>
          </mat-select>
        </mat-form-field>
        <span>:</span>
        <mat-form-field class='timepicker-select' color='accent'>
          <mat-select data-cy='iot-platform-ui-date-range-start-minutes' formControlName='startMinutes' [placeholder]='placeholderMinutes | translate'>
            <mat-option data-cy='iot-platform-ui-date-range-start-minutes-option' *ngFor='let minute of minutes' [value]='minute'>{{ minute }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout='row' fxLayout.lt-sm='row wrap' fxLayoutAlign='center center' fxLayoutGap='15px' fxFlex='45%' fxFlex.lt-sm='100%'
         class='to-block'>
      <mat-form-field class='datepicker' color='accent'>
        <input
          data-cy='iot-platform-ui-date-range-end-date'
          matInput
          formControlName='endDate'
          [required]='required'
          [matDatepicker]='toPicker'
          [placeholder]='placeholderTo | translate'
          [min]='startDate?.value'
          (focus)='toPicker.open()'
        />
        <mat-datepicker-toggle matSuffix [for]='toPicker'>
          <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #toPicker></mat-datepicker>
        <mat-hint *ngIf='endDate.value' (click)='resetEndDate()'>{{ placeholderClear | translate }}</mat-hint>
        <mat-error
          *ngIf='endDate.invalid && endDate.errors.required'>{{ 'FORM.ERROR_MESSAGE.REQUIRED' | translate }}</mat-error>
        <mat-error
          *ngIf='endDate.invalid && endDate.errors.matDatepickerMin'>{{ 'FORM.ERROR_MESSAGE.INVALID_DATE_RANGE' | translate }}</mat-error>
      </mat-form-field>

      <div [ngClass]="{ 'timepicker-visible': endDate.value }" fxLayout='row' fxLayoutAlign='start center' fxLayoutAlign.lt-md='center center'
           fxLayoutGap='5px' class='timepicker-hidden'>
        <mat-form-field class='timepicker-select' color='accent'>
          <mat-select data-cy='iot-platform-ui-date-range-end-hours' formControlName='endHours' [placeholder]='placeholderHours | translate'>
            <mat-option data-cy='iot-platform-ui-date-range-end-hours-option' *ngFor='let hour of hours' [value]='hour'>{{ hour }}</mat-option>
          </mat-select>
        </mat-form-field>
        <span>:</span>
        <mat-form-field class='timepicker-select' color='accent'>
          <mat-select data-cy='iot-platform-ui-date-range-end-minutes' formControlName='endMinutes' [placeholder]='placeholderMinutes | translate'>
            <mat-option data-cy='iot-platform-ui-date-range-end-minutes-option' *ngFor='let minute of minutes' [value]='minute'>{{ minute }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>

  <mat-error fxFlex
             *ngIf='dateForm?.errors?.invalidRange'>{{ 'FORM.ERROR_MESSAGE.INVALID_DATE_RANGE_IN_MONTHS' | translate: { value: diffInMonths } }}</mat-error>
</div>
