import { CronDisplayPipe } from './cron-display.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [CronDisplayPipe],
  imports: [CommonModule],
  exports: [CronDisplayPipe]
})
export class CronDisplayModule {}
