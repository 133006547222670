<div *ngIf="totalSelected > 0" class="app-round-button-mv action">
  <button *ngIf="isCallToActionVisible" [matMenuTriggerFor]="menu" mat-icon-button><mat-icon>more_vert</mat-icon></button>
  <mat-menu #menu="matMenu">
    <div *ngFor="let action of params?.bulkActions">
      <button (click)="onBulkActionClick(action.key)" *ngIf="bulkActionVisibility[action.key]" mat-menu-item>
        {{ action.label | translate | uppercase }}
      </button>
    </div>
  </mat-menu>
</div>
