import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Environment } from '@iot-platform/models/common';

@Component({
  selector: 'iot-platform-login-select-authentication',
  templateUrl: './login-select-authentication.component.html',
  styleUrls: ['./login-select-authentication.component.scss', '../../scss/style.scss']
})
export class LoginSelectAuthenticationComponent implements OnInit {
  @Input() ssoDisabled = false;

  @Output() signInWithSSO: EventEmitter<void> = new EventEmitter<void>();
  @Output() signInWithEmailPassword: EventEmitter<void> = new EventEmitter<void>();

  carmUrl?: string;

  constructor(@Inject('environment') private environment: Environment) {}

  ngOnInit(): void {
    this.carmUrl = this.environment.carmUrl ?? '';
  }
}
