import { User, UserPreferences } from '@iot-platform/models/common';
import { createReducer, on } from '@ngrx/store';

import { ProfileActions } from '../../../profile/state/actions';
import { PreferencesActions } from '../actions';

export const userPreferencesDbFeatureKey = 'userPreferencesDb';

export interface State {
  currentUser: User;
  preferences: UserPreferences;
}

export const initialState: State = {
  currentUser: null,
  preferences: { appTheme: 'default-theme', assetVariableListDisplay: true, deviceVariableListDisplay: true, siteAssociationDisplay: 'assets' }
};

export const reducer = createReducer(
  initialState,
  on(PreferencesActions.loadUserPreferences, () => initialState),
  on(PreferencesActions.loadUserPreferencesSuccess, (state: State, { user, preferences }) => ({
    ...state,
    currentUser: user,
    preferences: {
      mvSites: parseInt(preferences.mvSites, 10),
      mvAssets: parseInt(preferences.mvAssets, 10),
      mvAssetEvents: parseInt(preferences.mvAssetEvents, 10),
      mvDevices: parseInt(preferences.mvDevices, 10),
      mvDeviceEvents: parseInt(preferences.mvDeviceEvents, 10),
      appDateFormat: preferences.appDateFormat,
      appLanguage: preferences.appLanguage,
      occurrenceTime: preferences.occurrenceTime,
      appTheme: preferences.appTheme ? preferences.appTheme : state.preferences.appTheme,
      assetVariableListDisplay: preferences.assetVariableListDisplay ? preferences.assetVariableListDisplay : state.preferences.assetVariableListDisplay,
      deviceVariableListDisplay: preferences.deviceVariableListDisplay ? preferences.deviceVariableListDisplay : state.preferences.deviceVariableListDisplay,
      siteAssociationDisplay: preferences.siteAssociationDisplay ? preferences.siteAssociationDisplay : state.preferences.siteAssociationDisplay,
      favoriteFilters: preferences.favoriteFilters ?? state.preferences.favoriteFilters,
      filterEngineOpenByDefault: preferences.filterEngineOpenByDefault ?? state.preferences.filterEngineOpenByDefault
    }
  })),
  on(PreferencesActions.saveUserPreferencesSuccess, (state: State, { user, preferences }) => ({ ...state, currentUser: user, preferences })),

  on(ProfileActions.saveUserProfileSuccess, (state: State, { updatedUser }) => ({ ...state, currentUser: updatedUser, preferences: updatedUser.preferences }))
);

export const getCurrentUser = (state: State) => state.currentUser;
export const getPreferences = (state: State) => state.preferences;
