import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'grid-engine-event-duration-cell',
  template: `
    <div fxLayoutAlign="start center" fxLayoutGap="5px" *ngIf="params?.value?.seconds >= 0" [class]="params?.value?.color">
      <span *ngIf="params?.value?.days" >{{ params?.value?.days }}{{ 'EVENTS.TIMELINE.TIME.DAY' | translate }}</span>
      <span *ngIf="params?.value?.hours">{{ params?.value?.hours }}{{ 'EVENTS.TIMELINE.TIME.HOUR' | translate }}</span>
      <span *ngIf="params?.value?.minutes">{{ params?.value?.minutes }}{{ 'EVENTS.TIMELINE.TIME.MINUTE' | translate }}</span>
      <span *ngIf="params?.value?.seconds">{{ params?.value?.seconds }}{{ 'EVENTS.TIMELINE.TIME.SECOND' | translate }}</span>
    </div>
    <span class="event-timeline-time" *ngIf="params?.value?.seconds < 0">
       N/A
    </span>
  `,
  styleUrls: ['event-duration-cell.component.scss']
})
export class EventDurationCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
