import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssetVariable, DeviceVariable, FollowedVariable } from '@iot-platform/models/i4b';
import { get } from 'lodash';
import { AssetVariableConfigureReadPopupComponent } from '../../../../../../../iot4bos-ui/src/lib/features/assets/components/asset-variable-configure-read-popup/asset-variable-configure-read-popup.component';

import { ProcessMultipleStateVariablesUtil } from '../../../../../../../shared/src/lib/utils/process-multiple-state-variables.util';
import { RichValueCellParams } from '../rich-value-cell.params';

@Component({
  selector: 'grid-engine-rich-variable-value-cell',
  templateUrl: './rich-variable-value-cell.component.html',
  styleUrls: ['./rich-variable-value-cell.component.scss']
})
export class RichVariableValueCellComponent implements ICellRendererAngularComp {
  params: RichValueCellParams;
  processedValue: string;
  data: Partial<AssetVariable> | Partial<DeviceVariable> | Partial<FollowedVariable>;
  isMultipleStateVariable: boolean;
  displayUnit: boolean;
  canReadGraph = false;
  canReadEvent = false;
  minimalDisplay = false;
  displayInfoIcon = false;
  dialog: MatDialog = inject(MatDialog);
  eventIconProperties: { isDisplayed: boolean; tooltip: string; iconName: string } = { isDisplayed: false, iconName: '', tooltip: '' };

  agInit(params: RichValueCellParams): void {
    this.params = params;
    this.minimalDisplay = params.cellOptions.minimalDisplay;
    this.displayUnit = params.cellOptions.displayUnit ?? true;
    this.data = get(params.data, params.colDef.field);
    this.canReadGraph = this.params.userPermissions?.find(({ key }) => key === 'canReadGraph')?.value ?? false;
    this.canReadEvent = this.params.userPermissions?.find(({ key }) => key === 'canReadEvent')?.value ?? false;

    this.setIsMultipleStateVariable(params);
    if (this.isMultipleStateVariable) {
      this.setProcessedValue(params);
    }

    this.setEventIconProperties();
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  setIsMultipleStateVariable(params): void {
    switch (params.cellOptions.dataType) {
      case 'lastRecord':
        this.isMultipleStateVariable = ProcessMultipleStateVariablesUtil.isUnitMultipleState(params.data?.unit);
        break;
      default:
        this.isMultipleStateVariable = ProcessMultipleStateVariablesUtil.isUnitMultipleState(this.data?.unit);
        break;
    }
  }

  setProcessedValue(params): void {
    switch (params.cellOptions.dataType) {
      case 'lastRecord':
        this.processedValue = ProcessMultipleStateVariablesUtil.getProcessedUnit(params.data);
        break;
      default:
        const lastValue = params.value !== null ? { value: params.value, datetime: '' } : null;
        this.processedValue = ProcessMultipleStateVariablesUtil.getProcessedUnit({
          ...this.data,
          lastValue
        } as DeviceVariable);
        break;
    }
  }

  setEventIconProperties(): void {
    if (this.canReadEvent && !!(this.data as Partial<AssetVariable>)?.totalActiveEvents && !(this.data as Partial<AssetVariable>)?.totalAcknowledgedEvents) {
      this.eventIconProperties = { iconName: 'active', isDisplayed: true, tooltip: 'GRID_ENGINE.CELLS.RICH_VARIABLE_VALUE_CELL.DISPLAY_ACTIVE_EVENTS' };
    } else if (
      this.canReadEvent &&
      !(this.data as Partial<AssetVariable>)?.totalActiveEvents &&
      !!(this.data as Partial<AssetVariable>)?.totalAcknowledgedEvents
    ) {
      this.eventIconProperties = {
        iconName: 'acknowledged',
        isDisplayed: true,
        tooltip: 'GRID_ENGINE.CELLS.RICH_VARIABLE_VALUE_CELL.DISPLAY_ACKNOWLEDGED_EVENTS'
      };
    } else if (
      this.canReadEvent &&
      !!(this.data as Partial<AssetVariable>)?.totalActiveEvents &&
      !!(this.data as Partial<AssetVariable>)?.totalAcknowledgedEvents
    ) {
      this.eventIconProperties = {
        iconName: 'ongoing',
        isDisplayed: true,
        tooltip: 'GRID_ENGINE.CELLS.RICH_VARIABLE_VALUE_CELL.DISPLAY_ACTIVE_AND_ACKNOWLEDGED_EVENTS'
      };
    } else {
      this.eventIconProperties = { iconName: '', isDisplayed: false, tooltip: '' };
    }
  }

  onDispatchEvent(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.minimalDisplay) {
      this.params.dispatchEvent({ type: this.params.eventConfiguration.type, options: this.params.eventConfiguration.options, rawData: this.params.data });
    }
  }

  openFollowedVariableEvents(event: MouseEvent): void {
    event.stopPropagation();
    this.params.dispatchEvent({
      type: 'openFollowedVariableEvents',
      options: this.params.eventConfiguration.options,
      rawData: { asset: this.params.data, followedVariable: this.data }
    });
  }

  openGraphsByVariable(event: MouseEvent): void {
    event.stopPropagation();
    let siteId: string = get(this.params, 'data.site.id', null);
    if (this.params.cellOptions.dataType === 'event' || !siteId) {
      siteId = get(this.params, 'data.context.site.id');
    }
    this.params.dispatchEvent({
      type: 'openGraphsByVariable',
      options: this.params.eventConfiguration.options,
      rawData: { asset: this.params.data, followedVariable: this.data, siteId }
    });
  }

  toggleIconInfo(displayIcon: boolean, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.displayInfoIcon = displayIcon;
  }

  openVariableConfiguration(event: MouseEvent) {
    let assetVar;
    if (this.params.colDef.field === 'lastRecords' && this.params.data['lastRecords'][0].value) {
      assetVar = this.params.data;
    }
    if (
      this.params.colDef.field === 'lastRecords' &&
      this.params.data['lastRecords'].length > 0 &&
      typeof this.params.data['lastRecords'][0].value === typeof 0
    ) {
      assetVar = this.params.data;
    } else if (get(this.params.data, this.params.colDef.field)?.value !== undefined) {
      assetVar = get(this.params.data, this.params.colDef.field);
    } else if (get(this.params.data, this.params.colDef.field) && get(this.params.data, this.params.colDef.field)['linked'] === true) {
      assetVar = get(this.params.data, this.params.colDef.field);
    }

    this.dialog.open(AssetVariableConfigureReadPopupComponent, { data: { assetVariable: assetVar } });
  }

  canDisplayInfoIcon() {
    if (
      (get(this.params.data, this.params.colDef.field) && get(this.params.data, this.params.colDef.field)['linked'] === true) ||
      (this.params.data.hasOwnProperty('linked') && this.params.data['linked'] === true && !this.params.data.hasOwnProperty('device'))
    ) {
      return true;
    }
    return false;
  }
}
