import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TableRowHoverDirective } from './table-row-hover/table-row-hover.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [TableRowHoverDirective],
  exports: [TableRowHoverDirective],
  providers: []
})
export class DirectivesModule {}
