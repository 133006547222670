<mat-nav-list>
  <mat-list-item
    class="hoverable fs-12"
    [ngClass]="{ selected: selectedRole && role ? selectedRole.id === role.id : false }"
    *ngFor="let role of sortedRoles"
    (click)="selectRole.emit(role); selectedRole = role"
  >
    <h3 matLine>{{ role.name }}</h3>
    <button mat-icon-button [matMenuTriggerFor]="actionMenu" class="role-list-call-to-action" *ngIf="showActionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #actionMenu="matMenu">
      <button mat-menu-item (click)="onRenameRoleButtonClick(role)">
        <span>{{ 'ADMIN.ROLES_LIST.RENAME' | translate | uppercase }}</span>
      </button>
      <button mat-menu-item (click)="onEditRoleButtonClick(role)">
        <span>{{ 'IOT_DICTIONARY.EDIT' | translate | uppercase }}</span>
      </button>
      <div [matTooltip]="getTooltip(role)" matTooltipClass="regular-tooltip" [matTooltipDisabled]="!isDeletePossible(role)">
        <button mat-menu-item (click)="onDeleteRoleButtonClick(role)" [disabled]="isDeletePossible(role)">
          <span>{{ 'ADMIN.ROLES_LIST.DELETE' | translate | uppercase }}</span>
        </button>
      </div>
    </mat-menu>
  </mat-list-item>
</mat-nav-list>
