<section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="event-topic-list-header fw-600">
  <span fxFlex="18px"></span>
  <span fxFlex="35%" class="capitalize-first-letter">{{ 'IOT_DICTIONARY.NAME' | translate }}</span>
  <span fxFlex="35%" class="capitalize-first-letter">{{ 'IOT_DICTIONARY.ENTITY' | translate }}</span>
  <span fxFlex="25%" class="capitalize-first-letter">{{ 'IOT_DICTIONARY.VIEW' | translate }}</span>
</section>
<section *ngIf="topics" fxLayout="column">
  <div *ngFor="let topic of topics" class="event-topic-list-topic">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <span class="color" [style.background]="topic.color"></span>
      <span fxFlex="35%">{{ topic.name }}</span>
      <span fxFlex="35%">{{ topic.entity.name }}</span>
      <span fxFlex="25%" *ngIf="topic.view" class="capitalize-first-letter">{{ 'IOT_DICTIONARY.' + topic.view | translate }}</span>
    </div>
  </div>
</section>
