import { Contact } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const loadContactsSuccess = createAction('[Contacts] Load Contacts Success', props<{ contacts: Contact[] }>());
export const loadContactsFailure = createAction('[Contacts] Load Contacts Failure', props<{ error: any }>());

export const addContactSuccess = createAction('[Contacts] Add Contact Success', props<{ addedContact: Contact }>());
export const addContactFailure = createAction('[Contacts] Add Contact Failure', props<{ error: any }>());

export const updateContactSuccess = createAction('[Contacts] Update Contact Success', props<{ updatedContact: Contact }>());
export const updateContactFailure = createAction('[Contacts] Update Contact Failure', props<{ error: any }>());

export const deleteContactSuccess = createAction('[Contacts] Delete Contact Success', props<{ deletedContact: Contact }>());
export const deleteContactFailure = createAction('[Contacts] Delete Contact Failure', props<{ error: any }>());
