import { I4BCellType, I4BColumnConfiguration } from '../configurations';
import { I4bCellConcept } from '../enums';
import { HeaderType, I4BColumnHeader } from '../models';
import { I4BVariableColumnOptions } from '../options';
import { I4BBasicColumn } from './i4b-basic.column';
import { DiagnosticVariableColumn } from './i4b-diagnostic-variable.column';

export class DiagnosticVariableGroupColumn extends I4BBasicColumn {
  columnId = '9374044f-8451-444e-8a16-6044eec9c112-diagnostic-variable-group';
  className = DiagnosticVariableGroupColumn;

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BVariableColumnOptions>) {
    super(
      {
        displayName: customOptions?.overrideHeaderName ? customOptions?.overrideHeaderName : customHeader?.displayName ? customHeader?.displayName : 'VARIABLE',
        type: HeaderType.BASIC
      },
      {
        cell: { type: I4BCellType.BASIC },
        id: customConfig?.id,
        concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
        isDefault: false,
        marryChildren: true,
        children: [
          new DiagnosticVariableColumn(
            { displayName: 'VALUE' },
            {
              id: 'expandedVariables',
              isDefault: true,
              cell: {
                type: I4BCellType.DIAGNOSTIC_VARIABLE,
                options: { variableCategory: customConfig?.id ?? '', displayMode: 'value' },
                event: { type: 'openChartByVariable', options: { variableName: customConfig?.id } }
              }
            },
            { order: 0, isDisplayed: true }
          ),

          new DiagnosticVariableColumn(
            { displayName: 'DATETIME' },
            {
              id: 'expandedVariables',
              isDefault: true,
              cell: {
                type: I4BCellType.DIAGNOSTIC_VARIABLE,
                options: { variableCategory: customConfig?.id ?? '', displayMode: 'datetime' }
              }
            },
            { order: 1, isDisplayed: customOptions.displayDateTime }
          )
        ]
      },
      { displayDateTime: true, ...customOptions, customId: customConfig?.id }
    );
  }
}
