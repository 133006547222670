import { Injectable } from '@angular/core';
import { fromGrids } from '@iot-platform/grid-engine';

import { CommonApiRequest, FavoriteView, Filter, TagCategory } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Device, DeviceVariable, Site } from '@iot-platform/models/i4b';

import { select, Store } from '@ngrx/store';
import { GridsDbActions } from '../../../../../../../grid-engine/src/lib/components/state/actions';

import { FavoriteViewsActions } from '../../../../../../../shared/src/lib/favorite-views/+state/actions';
import * as fromFavoriteViews from '../../../../../../../shared/src/lib/favorite-views/+state/reducers';
import { DeviceConnectorsUiActions, DevicesDbActions } from '../actions';
import * as fromDevices from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class DevicesFacade {
  allDevices$ = this.store.pipe(select(fromDevices.getAllDevices));
  device$ = this.store.pipe(select(fromDevices.getDevice));
  grid$ = this.store.pipe(select(fromGrids.getDefaultDevicesGrid));
  grids$ = this.store.pipe(select(fromGrids.getDevicesGrids));
  devicesUpdated$ = this.store.pipe(select(fromDevices.getDevicesUpdated));
  // deviceLoaded$ = this.store.pipe(select(fromDevices.getDeviceLoaded));
  deviceLoaded$ = (gridId: string) => this.store.select(fromGrids.getDataLoadedByGrid(gridId));
  deviceVarLoaded$ = this.store.pipe(select(fromDevices.getDeviceVarLoaded));
  siteLoaded$ = this.store.pipe(select(fromDevices.getSiteLoaded));
  site$ = this.store.pipe(select(fromDevices.getSite));
  status$ = this.store.pipe(select(fromDevices.getStatusList));
  allVariables$ = this.store.pipe(select(fromDevices.getAllVariables));
  variable$ = this.store.pipe(select(fromDevices.getVariable));
  callLogs$ = this.store.pipe(select(fromDevices.getCallLogs));
  callLogLoading$ = this.store.pipe(select(fromDevices.getCallLogsLoading));
  callLogLoaded$ = this.store.pipe(select(fromDevices.getCallLogsLoaded));
  selectedDevice$ = this.store.pipe(select(fromDevices.getSelectedDevice));
  // mvSettings$ = this.store.pipe(select(fromDevices.getMVSettings));
  getCallLogsMVSettings$ = this.store.pipe(select(fromDevices.getCallLogsMVSettings));
  tags$ = this.store.pipe(select(fromDevices.getTags));
  tagsLoaded$ = this.store.pipe(select(fromDevices.getTagsLoaded));

  // pagination$ = this.store.pipe(select(fromDevices.getPagination));

  favoriteViews$ = this.store.pipe(select(fromFavoriteViews.getFavoriteViewsForMasterViewDevices));
  currentFavoriteView$ = this.store.pipe(select(fromFavoriteViews.getSelectedFavoriteViewForMasterViewDevices));
  currentFilters$ = this.store.pipe(select(fromFavoriteViews.getFiltersForMasterViewDevices));
  loadingFavoriteViews$ = this.store.pipe(select(fromFavoriteViews.getLoading));
  //
  deviceFavoriteViewsConfiguration$ = this.store.pipe(select(fromFavoriteViews.selectDeviceFavoriteViewsConfiguration));
  //

  timeseries$ = this.store.pipe(select(fromDevices.getTimeseries));

  // formattedData$ = this.store.pipe(select(fromDevices.getDevicesAsPlatformResponse));

  constructor(private readonly store: Store) {}

  addDevice(device: Device) {
    this.store.dispatch(DevicesDbActions.addDevice({ deviceToAdd: device }));
  }

  loadMetadata() {
    this.store.dispatch(GridsDbActions.getDefaultGridByConcept({ concept: 'devices' }));
  }

  saveMVSettings(name: string, values: any) {
    this.store.dispatch(DevicesDbActions.saveMVSettings({ name, values }));
  }

  loadCallLogsMetadata() {
    this.store.dispatch(DevicesDbActions.loadCallLogsMVSettings({ settingName: 'device-call-logs' }));
  }

  saveCallLogsMVSettings(name: string, values: any) {
    this.store.dispatch(DevicesDbActions.saveCallLogsMVSettings({ name, values }));
  }

  loadDevices(request: CommonApiRequest) {
    this.store.dispatch(GridsDbActions.loadGridData({ request: { concept: 'devices', ...request } }));
  }

  loadGrids() {
    this.store.dispatch(GridsDbActions.loadGrids({ concept: 'devices' }));
  }

  loadDeviceById(deviceId: string) {
    this.store.dispatch(DevicesDbActions.loadDeviceById({ deviceId }));
  }

  loadDeviceCallLogById(deviceId: string) {
    this.store.dispatch(DevicesDbActions.loadCallLogsByDeviceId({ deviceId }));
  }

  loadDeviceStatusList() {
    this.store.dispatch(DevicesDbActions.loadDeviceStatusList());
  }

  loadSiteById(siteId: string) {
    this.store.dispatch(DevicesDbActions.loadSiteById({ siteId: siteId }));
  }

  loadTagsByDeviceId(deviceId: string) {
    this.store.dispatch(DevicesDbActions.loadTagsByDeviceId({ deviceId: deviceId }));
  }

  selectDevice(selectedDevice: Device) {
    this.store.dispatch(DevicesDbActions.selectDevice({ selectedDevice }));
  }

  loadVariablesByDeviceId(deviceId: string) {
    this.store.dispatch(DevicesDbActions.loadVariablesByDeviceId({ deviceId }));
  }

  updateDevice(device: Device) {
    this.store.dispatch(DevicesDbActions.updateDevice({ deviceToUpdate: device }));
  }

  resetDevice(deviceToReset: Device) {
    this.store.dispatch(DevicesDbActions.resetDevice({ deviceToReset }));
  }

  deleteDevice(device: Device) {
    this.store.dispatch(DevicesDbActions.deleteDevice({ deviceToDelete: device }));
  }

  bulkUpdateDevices(devices: Device[]) {
    this.store.dispatch(DevicesDbActions.bulkUpdateDevices({ devicesToUpdate: devices }));
  }

  updateVariable(variable: DeviceVariable) {
    this.store.dispatch(DevicesDbActions.updateDeviceVariable({ deviceVariableToUpdate: variable }));
  }

  deleteVariable(variables: DeviceVariable[]) {
    this.store.dispatch(DevicesDbActions.deleteDeviceVariables({ deviceVariablesToDelete: variables }));
  }

  updateTagsByDeviceId(deviceId: string, tags: TagCategory[]) {
    this.store.dispatch(DevicesDbActions.updateTagsByDeviceId({ deviceId, tags }));
  }

  sendConnectorCommand(device: Device, command: any) {
    this.store.dispatch(DeviceConnectorsUiActions.sendConnectorCommand({ device, command }));
  }

  bulkSendConnectorCommand(devices: Device[], command: any) {
    this.store.dispatch(DeviceConnectorsUiActions.bulkSendConnectorCommand({ devices, command }));
  }

  saveFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenAddFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.addFavoriteView({ favoriteView: data.favoriteView }));
    }
  }

  updateFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenUpdateFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.updateFavoriteView({ favoriteView: data.favoriteView }));
    }
  }

  deleteFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.deleteFavoriteView({ favoriteView }));
  }

  setCurrentFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFavoriteView({ masterView: 'devices', favoriteView: favoriteView }));
  }

  setCurrentFilters(filters: Filter[]) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'devices', filters: filters }));
  }

  loadTimeseriesByVariableId(deviceId: string, variableId: string, start: string, end: string, limit: number) {
    this.store.dispatch(DevicesDbActions.loadTimeseriesByDeviceVariableId({ deviceId, variableId, start, end, limit }));
  }

  resetDeviceVariablesLastValues(deviceVariables: DeviceVariable[]) {
    this.store.dispatch(DevicesDbActions.resetDeviceVariablesLastValues({ deviceVariables }));
  }

  moveDevices(devicesToMove: Device[], originSite?: Site): void {
    this.store.dispatch(DevicesDbActions.moveDevices({ devicesToMove, originSite }));
  }
}
