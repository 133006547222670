import { I4BCellType, I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BBasicColumn } from './i4b-basic.column';

export class NameColumn extends I4BBasicColumn {
  columnId = '5d94b65e-5b50-11ec-80a5-0023a40210d0-name';
  dataLibs = [DataLibrary.SITES];
  className = NameColumn;

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      {
        displayName: customOptions?.overrideHeaderName
          ? customOptions?.overrideHeaderName
          : customHeader?.displayName
          ? customHeader?.displayName
          : 'NAME COLUMN DEFAULT',
        type: HeaderType.ICON_AND_TEXT,
        ...customHeader
      },
      {
        cell: { type: I4BCellType.BASIC_LINK },
        id: 'name',
        translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE_NAME',
        isDefault: true,
        isConfigurable: true,
        isLink: true,
        width: customOptions?.width ? customOptions.width : 150,
        ...customConfig
      },
      { ...customOptions, customId: customConfig.id }
    );
  }
}
