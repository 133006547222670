import { Injectable } from '@angular/core';
import { ApiSelector, BusinessProfile, Filter, Pagination } from '@iot-platform/models/common';
import { createSelector } from '@ngrx/store';
import * as fromMain from '../reducers';
import * as fromAdminBusinessProfiles from '../reducers/admin-business-profiles.reducer';

@Injectable({
  providedIn: 'root'
})
export class AdminBusinessProfilesSelectors extends ApiSelector<fromMain.State, BusinessProfile, Pagination, Filter> {
  selectSelectedBusinessProfile;

  constructor() {
    super(fromMain.featureKey, fromAdminBusinessProfiles.featureKey, fromAdminBusinessProfiles.adapter);
    this.selectSelectedBusinessProfile = createSelector(this.selectState, (state: fromAdminBusinessProfiles.State) => state.selectedBusinessProfile);
  }
}
