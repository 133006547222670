import { I4BButtonColumnConfiguration } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { I4BButtonColumnHeader } from '../headers/i4b-button-column.header';
import { HeaderType } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BColumn } from './i4b-column';

export class I4BButtonColumn implements I4BColumn<I4BButtonColumnHeader, I4BButtonColumnConfiguration, I4BColumnOptions> {
  className = I4BButtonColumn;
  columnId: string = 'f00d6fc0-7aaa-11ec-a90f-acde48001122-button';
  dataLibs: DataLibrary[] = [DataLibrary.ASSETS];
  header: I4BButtonColumnHeader = { type: HeaderType.CALL_TO_ACTION, displayName: '', icon: '' };
  configuration: I4BButtonColumnConfiguration;
  options: I4BColumnOptions;

  constructor(customHeader: I4BButtonColumnHeader, customConfig: I4BButtonColumnConfiguration, customOptions: I4BColumnOptions) {
    if (customHeader) {
      this.header = { ...this.header, ...customHeader };
    }
    if (customConfig) {
      this.configuration = { ...this.configuration, ...customConfig };
    }
    if (customOptions) {
      this.options = { ...this.options, ...customOptions };
    }
  }
}
