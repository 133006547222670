import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Organization, Role } from '@iot-platform/models/common';

@Component({
  selector: 'iot4bos-backoffice-ui-dialog-role-add-form',
  templateUrl: './dialog-role-add-form.component.html',
  styleUrls: ['./dialog-role-add-form.component.scss']
})
export class DialogRoleAddFormComponent implements OnInit {
  bpForm: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<DialogRoleAddFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { organization: Organization; roleToUpdate: Role }
  ) {}

  ngOnInit() {
    this.bpForm = new UntypedFormGroup({
      nameControl: new UntypedFormControl(this.data.roleToUpdate ? this.data.roleToUpdate.name : '', [Validators.required, Validators.maxLength(50)])
    });
  }

  get nameControl(): AbstractControl {
    return this.bpForm.get('nameControl');
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.data.roleToUpdate) {
      const toUpdate: Role = { ...this.data.roleToUpdate, name: this.nameControl.value.trim() };
      this.dialogRef.close(toUpdate);
    } else {
      const newRole: Role = {
        name: this.nameControl.value.trim(),
        entityName: this.data.organization.name,
        entityId: this.data.organization.id
      };
      this.dialogRef.close(newRole);
    }
  }
}
