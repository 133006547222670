import { BusinessProfile } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  AuthApiActions,
  AuthBusinessProfilesApiActions,
  AuthBusinessProfilesPageActions,
  AuthPageActions
} from '../actions';

export const authBusinessProfilesApiFeatureKey = 'authBusinessProfilesApi';

export interface State extends EntityState<BusinessProfile> {
  selectedBusinessProfileId?: string | null;
}

export const adapter: EntityAdapter<BusinessProfile> = createEntityAdapter<BusinessProfile>({
  selectId: (businessProfile: BusinessProfile) => businessProfile.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedBusinessProfileId: null
});

export const reducer = createReducer(
  initialState,
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State, { selectedBusinessProfile }) => ({
    ...state,
    selectedBusinessProfileId: selectedBusinessProfile?.id
  })),
  on(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess, (state: State, { selectedBusinessProfile }) => ({ ...state, selectedBusinessProfile })),
  on(AuthBusinessProfilesPageActions.loadBusinessProfiles, (state: State) => adapter.removeAll(state)),
  on(AuthBusinessProfilesApiActions.loadBusinessProfilesSuccess, (state: State, { businessProfiles }) => adapter.setAll(businessProfiles, state)),
  on(AuthPageActions.signOut, () => initialState),
  on(AuthApiActions.retrieveSessionFailure, () => initialState)
);

export const getSelectedId = (state: State) => state.selectedBusinessProfileId;
