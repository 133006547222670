import { Component, Input } from '@angular/core';
import { Contact } from '@iot-platform/models/common';
import { CardEventType } from '../../models/enums/card-event-event-type.enum';
import { BaseCardComponent } from '../base-card/base-card.component';

@Component({
  selector: 'oyan-ui-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent extends BaseCardComponent<Contact> {
  @Input() canUpdate: boolean;
  @Input() canDelete: boolean;
  @Input() canUpdateSite: boolean;

  onEdit(): void {
    this.dispatchEvent.emit({
      eventType: CardEventType.UPDATE_CONTACT,
      data: this.element
    });
  }

  onDelete(): void {
    this.dispatchEvent.emit({
      eventType: CardEventType.REMOVE_CONTACT,
      data: this.element
    });
  }
}
