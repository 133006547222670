import { NgModule } from '@angular/core';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ExportsModule } from '../../../../../iot-platform-ui/src/lib/ui/components/exports/exports.module';
import { DetailPopupCardsModule } from '../../../../../iot-platform-ui/src/lib/ui/components/detail-popup-cards/detail-popup-cards.module';
import { PanelModule } from '../../../../../iot4bos-ui/src/lib/components/panel/panel.module';
import { DirectivesModule } from '../../../../../iot4bos-ui/src/lib/directives/directives.module';
import { ContactsEffects } from '../../../../../iot4bos-ui/src/lib/features/sites/+state/effects/contacts.effects';
import { SiteContactFormModule } from '../../../../../iot4bos-ui/src/lib/features/sites/components/site-contact-form/site-contact-form.module';
import { SiteContactModule } from '../../../../../iot4bos-ui/src/lib/features/sites/components/site-contact/site-contact.module';
import { FavoriteViewsModule } from '../../../../../shared/src/lib/favorite-views/favorite-views.module';
import { MasterViewEngineModule } from '../../../../../table-engine/src/lib/components/master-view-engine/master-view-engine.module';
import { CardsModule } from '../../components';
import { MatMenuListModule } from '../../components/mat-menu-list/mat-menu-list.module';
import { OyanInfoDisplayModule } from '../../containers';
import { LinkReceiversFormDialogComponent } from './components/link-receivers-form-dialog/link-receivers-form-dialog.component';
import { SiteCardComponent } from './components/site-card/site-card.component';
import { SiteConfigurationsComponent } from './components/site-configurations/site-configurations.component';
import { SiteContactsComponent } from './components/site-contacts/site-contacts.component';
import { SiteGatewaysComponent } from './components/site-gateways/site-gateways.component';
import { SiteInfoFormComponent } from './components/site-info-form/site-info-form.component';
import { SiteProductsComponent } from './components/site-products/site-products.component';
import { SiteStocksComponent } from './components/site-stocks/site-stocks.component';
import { SiteOverviewComponent } from './containers/site-overview/site-overview.component';
import { SitesShellComponent } from './containers/sites-shell/sites-shell.component';
import { SitesRoutingModule } from './sites-routing.module';
import { SitesEffects } from './state/effects/sites.effects';
import * as fromSites from './state/reducers';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule,
    IotPlatformPipesModule,
    IotPlatformUiModule,
    DirectivesModule,
    DetailPopupCardsModule,
    FavoriteViewsModule,
    ExportsModule,
    MasterViewEngineModule,
    OyanInfoDisplayModule,
    CardsModule,
    PanelModule,
    MatMenuListModule,
    SiteContactModule,
    SiteContactFormModule,
    StoreModule.forFeature(fromSites.featureKey, fromSites.reducers),
    EffectsModule.forFeature([SitesEffects, ContactsEffects]),
    SitesRoutingModule
  ],
  declarations: [
    SitesShellComponent,
    SiteOverviewComponent,
    SiteCardComponent,
    SiteInfoFormComponent,
    SiteProductsComponent,
    SiteConfigurationsComponent,
    SiteGatewaysComponent,
    SiteStocksComponent,
    LinkReceiversFormDialogComponent,
    SiteContactsComponent
  ]
})
export class OyanUiSitesModule {}
