<div
  [ngClass]="[size]"
  class="iot-platform-toolbar"
  data-cy="iot-platform-ui-toolbar-v2"
  fxLayout="row warp"
  fxLayoutAlign="end stretch"
  fxLayoutAlign.lt-sm="center center"
  fxLayoutGap="2px"
>
  <section
    *ngIf="pageTypeOptions.visible"
    [ngStyle.lt-sm]="{ border: 'none' }"
    class="iot-platform-toolbar-button-section iot-platform-toolbar-button-section--left"
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    <mat-button-toggle-group (change)="onTogglePageType($event)" [(ngModel)]="pageTypeOptions.pageType" style="margin-left: 10px;">
      <mat-button-toggle
        [matTooltip]="'IOT_TOOLBAR.TOOLTIP.DISPLAY_GRID' | translate"
        [value]="ToolbarPageType.GRID"
        class="reverse-accent-button"
        matTooltipClass="regular-tooltip"
      >
        <mat-icon>table_view</mat-icon></mat-button-toggle
      >
      <mat-button-toggle
        [matTooltip]="'IOT_TOOLBAR.TOOLTIP.DISPLAY_MAP' | translate"
        [value]="ToolbarPageType.MAP"
        class="reverse-accent-button"
        matTooltipClass="regular-tooltip"
      >
        <mat-icon>pin_drop</mat-icon></mat-button-toggle
      >
    </mat-button-toggle-group>

    <span class="iot-platform-toolbar-button-section__right-separator"></span>
  </section>

  <section
    *ngIf="breadCrumbConfiguration"
    [ngStyle.lt-sm]="{ border: 'none' }"
    class="iot-platform-toolbar-section"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
  >
    <iot-platform-ui-breadcrumb-item
      [entityName]="breadCrumbConfiguration.entityName"
      [icon]="breadCrumbConfiguration.icon"
      [name]="name"
    ></iot-platform-ui-breadcrumb-item>
  </section>

  <section
    *ngIf="!breadCrumbConfiguration && name"
    [fxFlex.lt-sm]="pageTypeOptions.visible ? '40%' : ''"
    [ngClass]="[size]"
    [ngStyle.lt-sm]="{ border: 'none' }"
    class="iot-platform-toolbar-section iot-platform-toolbar-title-section"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-sm="center center"
    fxLayoutGap="10px"
  >
    <span *ngIf="!favoriteViewConfiguration?.currentFavoriteView" [ngStyle.lt-sm]="{ textAlign: 'center' }" class="iot-platform-toolbar-title fw-600">{{ total | numberFormat }} {{ name }}</span>
    <div *ngIf="favoriteViewConfiguration?.currentFavoriteView" fxLayout="column">
      <span [ngStyle.lt-sm]="{ textAlign: 'center' }" class="iot-platform-toolbar-first-line-title">{{ total | numberFormat }} {{ name }}</span>
      <span [ngStyle.lt-sm]="{ textAlign: 'center' }" class="iot-platform-toolbar-second-line-title">{{ favoriteViewConfiguration?.currentFavoriteView?.name}}</span>
    </div>
    <mat-progress-spinner *ngIf="!isDataLoaded" [diameter]="20" class="default-loader" color="accent" mode="indeterminate"></mat-progress-spinner>
    <mat-progress-spinner
      *ngIf="isDataLoaded && autoRefresh?.displaySpinner"
      [diameter]="20"
      [matTooltip]="(autoRefresh?.timeLeft | number: '1.0-0') + ' s'"
      [value]="autoRefresh?.counter"
      class="auto-refresh-spinner"
      color="accent"
      matTooltipClass="regular-tooltip"
      mode="determinate"

    ></mat-progress-spinner>
  </section>

  <section *ngIf="!!favoriteViewConfiguration"
    [ngStyle.lt-sm]="{ border: 'none' }"
   [style.background-color]="favoriteViewConfiguration.currentFavoriteView ? favoriteViewConfiguration.currentFavoriteView?.color+'50' : 'transparent'"
    class="iot-platform-toolbar-section"
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="end center"
    fxLayoutGap="10px"
  >
    <div  class="iot-platform-toolbar-favorite-view-section" fxLayout="row" fxLayoutAlign="end center">
      <mat-select
        (selectionChange)="onApplyFavoriteView($event)"
        [disabled]="groupedFavoriteViews.length === 0"
        [placeholder]="'MV_TOOLBAR.FAVORITE_VIEW' | translate"
        [value]="favoriteViewConfiguration.currentFavoriteView"
        class="iot-platform-toolbar-favorite-view-select"
        fxFlex.lt-sm="100%"
      >
        <mat-select-trigger fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" >
          <span [style.background-color]="favoriteViewConfiguration.currentFavoriteView?.color" class="iot-platform-toolbar-favorite-view-color"></span>
          <span class="iot-platform-toolbar-favorite-view-select-text">
            {{ favoriteViewConfiguration?.currentFavoriteView?.name | truncate : ['18', '...'] }}

          </span>
        </mat-select-trigger>
        <mat-option [value]="null"
                    class="iot-platform-toolbar-favorite-view-option">
            <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
              <span class="iot-platform-toolbar-favorite-view-select-text" fxFlex>Default View</span>
            </span>
        </mat-option>
        <mat-optgroup *ngFor="let group of groupedFavoriteViews">
          <span class="iot-platform-toolbar-favorite-view-group-name">
            {{ group.name | translate }}
          </span>
          <mat-option *ngFor="let favoriteView of group.favoriteViews"
            [value]="favoriteView"
            class="iot-platform-toolbar-favorite-view-option"
            style="height: 32px;"
          >
            <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
              <span [style.background-color]="favoriteView.color" class="iot-platform-toolbar-favorite-view-color"></span>
              <span [innerHTML]="favoriteView.name" class="iot-platform-toolbar-favorite-view-select-text" fxFlex></span>
              <mat-icon *ngIf="favoriteView.gridId" class="favorite-view-link-icon" color="accent" fxFlex="20" fxLayoutAlign="end center">link</mat-icon>
            </span>
          </mat-option>
        </mat-optgroup>
      </mat-select>

      <div fxLayout="row" fxLayoutAlign="start center">
        <iot-platform-ui-toolbar-button (buttonClick)="dispatchToolbarEvent.emit($event)" *ngFor="let fvButton of fvButtonList" [button]="fvButton" class="inlined-button"></iot-platform-ui-toolbar-button>
      </div>
    </div>

    <div class="iot-platform-toolbar-grid-favorite-view-loader-area">
      <mat-icon
        *ngIf="
          !!gridsConfiguration &&
          gridsConfiguration.currentGrid?.id &&
          favoriteViewConfiguration.currentFavoriteView?.gridId === gridsConfiguration.currentGrid?.id &&
          !favoriteViewConfiguration.isFavoriteViewsLoading &&
          !gridsConfiguration.isGridsLoading
        "
        color="accent"
        >link</mat-icon
      >
      <mat-progress-spinner
        *ngIf="favoriteViewConfiguration?.isFavoriteViewsLoading || gridsConfiguration?.isGridsLoading"
        [diameter]="20"
        color="accent"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>
    <div class="iot-platform-toolbar-favorite-view-section" fxLayout="row" fxLayoutAlign="start center">
      <mat-select
        (selectionChange)="onApplyGrid($event)"
        *ngIf="!!gridsConfiguration"
        [disabled]="gridsConfiguration.sortedGridsWithoutAppDefault.length === 0"
        [placeholder]="'MV_TOOLBAR.GRID' | translate"
        [value]="gridsConfiguration.currentGrid"
        class="iot-platform-toolbar-grid-select"
        fxFlex.lt-sm="100%"
      >
        <mat-select-trigger fxLayout="row" fxLayoutAlign="start center">
          <span [innerHTML]="gridsConfiguration.currentGrid?.name" class="iot-platform-toolbar-favorite-view-select-text"></span>
          <span
            [innerHTML]="gridsConfiguration.currentGrid?.isDefault ? ('GRID_ENGINE.GRID_MANAGER_POPUP.DEFAULT_GRID' | translate) : ''"
            class="iot-platform-toolbar-favorite-view-select-text"
          ></span>
        </mat-select-trigger>

        <mat-optgroup *ngFor="let group of groupedGrids ">
          <span class="iot-platform-toolbar-favorite-view-group-name">
              <!--<mat-icon *ngIf="!group.shared" class="option-icon">person</mat-icon>
              <mat-icon *ngIf="group.shared" class="option-icon">groups</mat-icon>-->
            {{ group.name | translate }}
          </span>
          <mat-option *ngFor="let grid of group.grids"
                      [value]="grid"
                      class="iot-platform-toolbar-favorite-view-option"
                      style="height: 32px;" >
          <span fxFlex fxLayout="row" fxLayoutAlign="start center">
            <span [innerHTML]="grid.name" class="iot-platform-toolbar-favorite-view-select-text"></span>
            <span
              [innerHTML]="grid?.isDefault ? ('GRID_ENGINE.GRID_MANAGER_POPUP.DEFAULT_GRID' | translate) : ''"
              class="iot-platform-toolbar-favorite-view-select-text"
              style="flex: 1"
            ></span>
          </span>
          </mat-option>
        </mat-optgroup>

      </mat-select>
      <div fxLayout="row" fxLayoutAlign="start center">
        <iot-platform-ui-toolbar-button (buttonClick)="dispatchToolbarEvent.emit($event)" *ngFor="let gridButton of gridButtons" [button]="gridButton" class="inlined-button">
        </iot-platform-ui-toolbar-button>
      </div>
    </div>
    <!--    <mat-progress-spinner *ngIf="gridsCompilation.isGridsLoading" [diameter]="20" color="accent" mode="indeterminate"></mat-progress-spinner>-->
  </section>

  <section
    *ngIf="filterText?.visible"
    [ngStyle.lt-sm]="{ border: 'none' }"
    class="iot-platform-toolbar-section iot-platform-toolbar-button-section"
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    <div class="iot-platform-toolbar-filter-input" fxLayout="row" fxLayoutAlign="end center">
      <mat-icon>search</mat-icon>
      <input
        #filterQuery
        (keyup.enter)="filterTextControl?.patchValue(filterQuery?.value)"
        [focusInitial]="filterText?.autoFocus"
        [formControl]="filterTextControl"
        [matTooltip]="filterText?.tooltip || '' | translate"
        [placeholder]="filterText?.placeholder || '' | translate"
        data-cy="iot-platform-ui-toolbar-v2-search-input"
        focusInitial
        matInput
        matTooltipClass="regular-tooltip"
      />
      <button (click)="filterTextControl?.patchValue(null); filterQuery.value = ''; filterQuery?.focus()" *ngIf="filterQuery?.value" mat-icon-button>
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </section>

  <section
    *ngIf="displayButtonSection"
    [ngClass]="{ 'iot-platform-toolbar-button-section-fixed-25': !!favoriteViewConfiguration }"
    class="iot-platform-toolbar-section iot-platform-toolbar-button-section"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutAlign.lt-md="center center"
    fxLayoutGap="8px"
  >
    <iot-platform-ui-toolbar-button (buttonClick)="dispatchToolbarEvent.emit($event)" *ngFor="let button of buttonList" [button]="button">
    </iot-platform-ui-toolbar-button>
  </section>


  <ng-content select="[toolbarCustomSection]"></ng-content>
</div>
