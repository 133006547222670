import { Injectable } from '@angular/core';
import { BaseFacade, CommonApiRequest, Filter, Pagination } from '@iot-platform/models/common';
import { Gateway } from '@iot-platform/models/oyan';
import { Store } from '@ngrx/store';
import { GatewaysActions } from '../actions';
import { GatewaysSelectors } from '../selectors/gateways.selectors';

@Injectable({
  providedIn: 'root'
})
export class GatewaysFacade extends BaseFacade<Gateway, Pagination, Filter> {
  constructor(protected store: Store, protected selector: GatewaysSelectors) {
    super(store, selector);
  }

  getAll(request: CommonApiRequest) {
    this.store.dispatch(GatewaysActions.loadGateways({ request }));
  }

  loadGatewayById(id: string) {
    this.store.dispatch(GatewaysActions.loadGatewayById({ id }));
  }

  setFilters(filters: Filter[]) {
    this.store.dispatch(GatewaysActions.setFilters({ filters }));
  }
}
