import { ChangeDetectionStrategy, Component, Inject, InjectionToken, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { selectLoggedOut } from 'libs/auth/src/lib/state/reducers';
import { PopupComponent } from 'libs/iot-platform-ui/src/lib/ui/components/popup/popup.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { NotificationService } from './notification.service';
import { AnalyticsService } from '@iot-platform/core';

export const DISABLE_ERROR_MESSAGES_AFTER_LOGOUT = new InjectionToken<boolean>('DISABLE_ERROR_MESSAGES_AFTER_LOGOUT');

@Component({
  selector: 'iot-platform-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit, OnDestroy {
  analytic: AnalyticsService = new AnalyticsService('display_error_popup');
  loader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoggedOut!: boolean;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly notificationService: NotificationService,
    private readonly dialog: MatDialog,
    private readonly store: Store,
    @Optional() @Inject(DISABLE_ERROR_MESSAGES_AFTER_LOGOUT) private disableErrorMessagesAfterLogout: boolean
  ) {}

  ngOnInit(): void {
    if (this.disableErrorMessagesAfterLogout === true) {
      this.store
        .select(selectLoggedOut)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((isLoggedOut) => {
          this.isLoggedOut = isLoggedOut;
        });
    }
    this.notificationService.loader$.pipe(delay(0), takeUntil(this.unsubscribe$)).subscribe((loader: boolean) => this.loader$.next(loader));
    this.notificationService.error$
      .pipe(delay(0), takeUntil(this.unsubscribe$))
      .subscribe((value: string | { code: string; error: string }) => this.displayError(value));
    this.notificationService.success$.pipe(delay(0), takeUntil(this.unsubscribe$)).subscribe((value: string) => this.displaySuccess(value));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public openErrorPopup(value: string | { code: string; error: string } | any) {
    if (value.hasOwnProperty('type')) {
      this.analytic.logError(
        value['type'],
        `${value['error'].error.code} - ${value['error'].error.message}`,
        `${value['type']} : ${value['error'].error.code} - ${value['error'].error.message}`
      );
    }
    this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'error', value }
    });
  }

  private displaySuccess(message: string, action = '', duration = 4000) {
    this.snackBar.open(message, action, { duration });
  }

  private displayError(error: string | { code: string; error: string } | any) {
    if (this.disableErrorMessagesAfterLogout === true) {
      if (this.isLoggedOut === false) {
        this.openErrorPopup(error);
      }
    } else {
      this.openErrorPopup(error);
    }
  }
}
