<div class="wrapper">
  <iot-platform-ui-toolbar-v2
    [name]="'ADMIN.USERS.TOOLBAR_TITLE' | translate"
    [total]="totalUsers$ | async"
    [isDataLoaded]="!(usersPending$ | async)"
    [buttonList]="adminUsersButtonList"
    [size]="toolbarSize"
    (dispatchToolbarEvent)="onToolbarEvent($event)"
  >
  </iot-platform-ui-toolbar-v2>

  <iot-platform-ui-filter-engine-container
    [masterView]="'admin-users'"
    [expanded]="filterEngineOpened"
    [displayActionButtons]="true"
    [withFavoriteFilters]="true"
    [currentFilters$]="currentFilters$"
    [currentFavoriteView$]="currentFavoriteView$"
    (applyFilters)="onApplyFilters($event)"
  >
  </iot-platform-ui-filter-engine-container>

  <mat-sidenav-container class="layout-master-view-engine-container">
    <mat-sidenav-content>
      <grid-engine-grid-engine-component
        fxFlex
        class="layout-ag-grid-shell"
        [gridData]="users$ | async"
        [gridMeta]="mvSettings$ | async"
        [userPermissions]="userPermissions"
        (pageChange)="loadMoreUsers($event)"
        (dispatchMasterViewEngineEvent)="onDispatchMasterViewEngineEvent($event)"
      >
      </grid-engine-grid-engine-component> </mat-sidenav-content
  ></mat-sidenav-container>
</div>
