<form [formGroup]="tagsForm" fxFlex fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="10px" class="tag-editor-form-container">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <button
      *ngIf="mode === 'UPDATE'"
      mat-icon-button
      color="warn"
      class="regular-round-button"
      [matTooltip]="'TAG_EDITOR.REMOVE' | translate"
      matTooltipClass="warning-tooltip"
      matTooltipPosition="below"
      (click)="onRemove(tagCategory)"
    >
      <mat-icon>delete</mat-icon>
    </button>
    <mat-form-field fxFlex="70px" color="accent">
      <mat-select [(value)]="tagCategory.color" [placeholder]="'TAG_EDITOR.COLOR' | translate" formControlName="colorFormControl" required>
        <mat-select-trigger fxLayout="row" fxLayoutAlign="start center">
          <span class="tag-category-color-round" [style.background-color]="tagCategory?.color"></span>
        </mat-select-trigger>
        <mat-option *ngFor="let color of colors" [value]="color">
          <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <span class="tag-category-color-round" [style.background-color]="color"></span>
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-form-field fxFlex="200px" color="accent">
    <input
      #categoryInput
      matInput
      required
      maxlength="20"
      [placeholder]="'TAG_EDITOR.CATEGORY_NAME' | translate"
      [value]="tagCategory.name"
      formControlName="nameFormControl"
      (keyup.enter)="chipList.focus(); $event.stopPropagation()"
    />
    <mat-error *ngIf="nameFormControl.invalid && nameFormControl.errors.duplicate">
      {{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_CATEGORY' | translate }}
    </mat-error>
    <mat-hint align="end"
      ><span>{{ categoryInput.value.length || 0 }}/{{ tagNameMaxLength }}</span></mat-hint
    >
  </mat-form-field>

  <mat-form-field fxFlex color="accent">
    <mat-chip-list #chipList fxLayout="column">
      <mat-chip
        *ngFor="let label of tagCategory.labels"
        [ngStyle]="{ backgroundColor: tagCategory.color }"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="removeLabel(tagCategory, label)"
        (dblclick)="onEditLabel(label)"
      >
        {{ label.name }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        #labelInput
        [placeholder]="'TAG_EDITOR.TAG_NAMES' | translate"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="addLabel(tagCategory, $event)"
        maxlength="20"
      />
    </mat-chip-list>
    <mat-hint align="end" fxLayoutGap="20px">
      <span class="tag-editor-duplicate-warning" *ngIf="checkDuplicateLabel(labelInput.value)">{{
        'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_LABEL' | translate
      }}</span>
      <span *ngIf="labelInput.value">{{ labelInput.value.length || 0 }}/{{ tagNameMaxLength }}</span>
      <span [ngClass]="{ 'max-reached': tagCategory.labels?.length === maximumTagPerCategory }"
        >{{ 'TAG_EDITOR.TAGS_PER_CATEGORY' | translate }} {{ tagCategory.labels?.length || 0 }}/{{ maximumTagPerCategory }}</span
      >
    </mat-hint>
  </mat-form-field>
  <button
    *ngIf="mode === 'UPDATE'"
    mat-raised-button
    color="accent"
    class="button-regular"
    [disabled]="!tagsForm.valid || (tagsForm.valid && !tagsForm.dirty)"
    (click)="onSave(tagCategory)"
  >
    {{ 'TAG_EDITOR.UPDATE' | translate | uppercase }}
  </button>
  <button *ngIf="mode === 'ADD'" mat-raised-button color="accent" class="button-regular" [disabled]="!tagsForm.valid" (click)="onSave(tagCategory)">
    {{ 'TAG_EDITOR.ADD' | translate | uppercase }}
  </button>
</form>
