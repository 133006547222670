import { Action, createReducer, on } from '@ngrx/store';
import { ContactsDbActions, ContactsUiActions } from '../actions';

export const contactsUiFeatureKey = 'contactsUi';

export interface State {
  contactsLoaded: boolean;
  contactsLoading: boolean;
}

export const initialState: State = {
  contactsLoaded: false,
  contactsLoading: false
};

const contactsUiReducer = createReducer(
  initialState,
  on(ContactsUiActions.loadContacts, ContactsUiActions.addContact, ContactsUiActions.updateContact, ContactsUiActions.deleteContact, (state: State) => ({
    ...state,
    contactsLoaded: false,
    contactsLoading: true
  })),
  on(
    ContactsDbActions.loadContactsSuccess,
    ContactsDbActions.addContactSuccess,
    ContactsDbActions.updateContactSuccess,
    ContactsDbActions.deleteContactSuccess,
    (state: State) => ({ ...state, contactsLoaded: true, contactsLoading: false })
  ),
  on(
    ContactsDbActions.loadContactsFailure,
    ContactsDbActions.addContactFailure,
    ContactsDbActions.updateContactFailure,
    ContactsDbActions.deleteContactFailure,
    (state: State) => ({ ...state, contactsLoaded: false, contactsLoading: false })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return contactsUiReducer(state, action);
}

export const getContactsLoaded = (state: State) => state.contactsLoaded;
export const getContactsLoading = (state: State) => state.contactsLoading;
