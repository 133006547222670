import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { IconModule } from '@iot-platform/iot-platform-ui';
import { TranslateModule } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { VariableChartModule } from '../../../../../shared/src/lib/variable-chart/variable-chart.module';
import { SharedModule } from '../../../../../shared/src/lib/shared.module';
import { TimeseriesWidgetComponent } from './components/timeseries-widget/timeseries-widget.component';
import { WidgetItemComponent } from './components/widget-item/widget-item.component';
import { WidgetsContainerComponent } from './components/widgets-container/widgets-container.component';
import { WidgetLoaderDirective } from './directives/widget-loader.directive';
import { IotPlatformPipesModule } from '@iot-platform/pipes';

@NgModule({
  imports: [SharedModule, TranslateModule, FlexLayoutModule, IconModule, MatCardModule, IotPlatformPipesModule, HighchartsChartModule, VariableChartModule],
  declarations: [WidgetLoaderDirective, TimeseriesWidgetComponent, WidgetsContainerComponent, WidgetItemComponent],
  exports: [WidgetsContainerComponent, TimeseriesWidgetComponent]
})
export class WidgetsModule {}
