import {
  CellClassParams,
  ColDef,
  ColumnApi,
  FirstDataRenderedEvent,
  GridApi,
  GridColumnsChangedEvent,
  GridOptions,
  ICellRendererParams,
  RowSelectedEvent,
  SortChangedEvent,
  ValueGetterParams
} from '@ag-grid-community/core';
import { ColumnState } from '@ag-grid-community/core/dist/cjs/es5/columns/columnModel';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SortModelAdapter } from '@iot-platform/iot-platform-utils';
import { gridSortModel, Pagination, PlatformResponse } from '@iot-platform/models/common';
import { ExportParams, HeaderType, I4BCellType, RichVariableColumn } from '@iot-platform/models/grid-engine';
import { MasterView } from '@iot-platform/models/i4b';
import { TranslateService } from '@ngx-translate/core';
import { get as _get, isEqual } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SortUtil } from '../../../../../../shared/src/lib/utils/sort.util';
import {
  FRAMEWORK_COMPONENTS,
  GRID_GROUP_HEADER_HEIGHT,
  GRID_HEADER_HEIGHT,
  GRID_ROW_HEIGHT
} from '../../containers/grid-page/grid-page.component';
import { GridExportDialogComponent } from '../grid-export/grid-export-dialog/grid-export-dialog.component';
import { GridExportService } from '../grid-export/grid-export.service';
import { ConditionProcessorUtil } from './../../../../../../table-engine/src/lib/utils/condition-processor';
import { GridEngineService } from './grid-engine.service';

@Component({
  selector: 'grid-engine-grid-engine-component',
  templateUrl: './grid-engine.component.html',
  styleUrls: ['./grid-engine.component.scss']
})
export class GridEngineComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() gridData: PlatformResponse;
  @Input() gridMeta = [];
  @Input() userPermissions;
  @Input() visibleNodeId: string;
  @Input() displayPagination = true;
  @Input() disableAutoFit = false;
  @Input() masterViewType: {
    name: string;
    useStaticMetaData: boolean;
  };

  @Output() dispatchMasterViewEngineEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChange: EventEmitter<Pagination> = new EventEmitter<Pagination>();
  @Output() sortChange: EventEmitter<gridSortModel[]> = new EventEmitter<gridSortModel[]>();

  pagination$: BehaviorSubject<Pagination> = new BehaviorSubject<Pagination>({ limit: 10, currentPage: 0, total: 0, maxPage: 0, hasMore: false });
  rowData = [];
  columnDefs: ColDef[] = [];
  gridOptions: GridOptions;
  rowSelected$ = new Subject<RowSelectedEvent>();
  destroyed$ = new Subject();
  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;

  @ViewChild('exportViewRef', { read: ViewContainerRef }) private readonly exportViewRef: ViewContainerRef;

  constructor(
    private readonly translateService: TranslateService,
    private readonly renderer: Renderer2,
    private readonly gridEngineService: GridEngineService,
    private readonly gridExportService: GridExportService,
    private readonly dialog: MatDialog,
    @Inject(DOCUMENT) private readonly document: HTMLDocument
  ) {
    this.gridOptions = {
      headerHeight: GRID_HEADER_HEIGHT,
      rowHeight: GRID_ROW_HEIGHT,
      groupHeaderHeight: GRID_GROUP_HEADER_HEIGHT,
      rowSelection: 'multiple',
      rowBuffer: 20,
      debounceVerticalScrollbar: true,
      animateRows: false,
      enableCellTextSelection: true,
      suppressRowDeselection: true,
      suppressRowClickSelection: true,
      getRowId: (params) => {
        return params.data.id;
      },
      onRowDoubleClicked: (event) => {
        this.dispatchMasterViewEngineEvent.emit({
          type: 'open',
          options: { selected: event.context },
          rawData: event.data
        });
      },
      pagination: false,
      defaultColDef: {
        minWidth: 80
      }
    } as GridOptions;
    this.gridOptions.components = FRAMEWORK_COMPONENTS;
  }

  ngOnInit() {
    this.handleRowSelection();
    if (this.masterViewType?.useStaticMetaData) {
      this.loadStaticMetaData();
    }
    this.gridExportService.onExport$.pipe(takeUntil(this.destroyed$)).subscribe((params: ExportParams) => {
      this.openExportForm(params);
    });
  }

  ngAfterViewInit(): void {
    this.gridExportService.setViewRef(this.exportViewRef);
  }

  onSortChanged(event: SortChangedEvent) {
    this.sortChange.emit(event.columnApi.getColumnState().map(({ colId, sort }) => ({ colId, sort })));
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setFlexColumnClass();
    if (this.visibleNodeId) {
      this.gridApi.ensureNodeVisible((data) => data.id === this.visibleNodeId);
    }
  }

  onGridColumnsChanged(event: GridColumnsChangedEvent) {
    // if (this.disableAutoFit === false) {
    this.sizeColumnsToFit();
    // }
    this.initHeaderSize();
  }

  handleRowSelection() {
    this.rowSelected$.pipe(debounceTime(100), takeUntil(this.destroyed$)).subscribe((row: RowSelectedEvent) => {
      this.gridApi.refreshHeader();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.gridMeta?.currentValue &&
      !this.masterViewType?.useStaticMetaData &&
      !isEqual(changes.gridMeta?.currentValue, changes.gridMeta?.previousValue)
    ) {
      this.setColumnDefs(changes.gridMeta?.currentValue);
    }
    if (changes.gridData?.currentValue) {
      this.setData(changes.gridData.currentValue);
    }
  }

  sizeColumnsToFit() {
    let rowWidth = 0;
    setTimeout(() => {
      document.querySelectorAll('.ag-header-row .ag-header-cell').forEach((cell: HTMLElement) => {
        rowWidth += cell.offsetWidth;
      });
      /*const viewport = this.document.querySelector('[ref="eViewport"]') as HTMLElement;
      if (rowWidth && rowWidth < viewport.offsetWidth - 1) {
        this.gridApi.sizeColumnsToFit();
      }*/
      this.gridApi.sizeColumnsToFit();
    }, 0);
  }

  onPageChange(pagination: Pagination): void {
    this.pageChange.emit(pagination);
    this.gridApi.ensureIndexVisible(0, 'top');
  }

  ngOnDestroy() {
    const sideNavContent: HTMLElement = this.document.querySelector('.main-sidenav-content');
    if (sideNavContent) {
      this.renderer.removeClass(sideNavContent, 'flex-column');
    }
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  onFirstDataRendered(_: FirstDataRenderedEvent) {
    this.setSort(SortModelAdapter.toGridSortModel(this.gridData?.initialSort));
  }

  private initHeaderSize(): void {
    const headerElement: HTMLElement = this.document.querySelector('.ag-header.ag-focus-managed');
    if (headerElement && headerElement.offsetHeight > 40) {
      const height = GRID_HEADER_HEIGHT + GRID_GROUP_HEADER_HEIGHT + 10;
      this.renderer.setStyle(headerElement, 'height', `${height}px`);
    }
  }

  private openExportForm(params: ExportParams): void {
    this.gridExportService.setPagination(this.pagination$.getValue());
    this.gridExportService.setRowData([...this.rowData]);
    this.gridExportService.setGridMeta(this.gridMeta);
    this.gridExportService.setParams(params);

    this.dialog.open(GridExportDialogComponent, {
      width: '500px',
      disableClose: true,
      data: { totalElements: params.totalElements }
    });
  }

  private setData(platformResponse: PlatformResponse) {
    this.setRowData(platformResponse.data);
    this.setPagination(platformResponse);
  }

  private setSort(initialSort: gridSortModel[]) {
    if (this.gridApi && initialSort) {
      this.gridColumnApi.applyColumnState({ state: initialSort.map((e) => ({ colId: e.colId, sort: e.sort })) as ColumnState[] });
      this.gridApi.refreshHeader();
    }
  }

  private setColumnDefs(colDefs) {
    this.columnDefs = colDefs.masterViewTable.bluePrint.columns.sort(SortUtil.sortByOrder).map((col) => {
      const gridRow: ColDef = {
        field: col.id,
        headerName: col.name,
        headerValueGetter: this.localizedHeader.bind(this),
        sortable: col.sortable,
        filter: true,
        pinned: col.pinned ? col.pinned : undefined,
        resizable: true,
        width: col.cellWidth ? parseInt(col.cellWidth) : undefined,
        maxWidth: col.maxWidth ? col.maxWidth : undefined,
        suppressMenu: false,
        headerComponent: col.headerType,
        headerComponentParams: { headerIcon: col.headerIcon ?? '', headerTooltip: col.headerTooltip ?? '' },
        cellRenderer: col.cellType,
        cellRendererParams: {
          eventConfiguration: { type: col.clickEvent?.type ?? '', options: col.clickEvent?.options ?? '' },
          cellOptions: col.cellOptions ? col.cellOptions : col.cellTypeOptions ?? '',
          userPermissions: this.userPermissions,
          dispatchEvent: (event) => {
            this.dispatchMasterViewEngineEvent.emit(event);
          }
        }
      };

      if (col.valueGetter) {
        gridRow.valueGetter = (params: ValueGetterParams) => _get(params.data, col.valueGetter);
      }

      if (col.cellType === I4BCellType.RICH_VARIABLE) {
        gridRow.cellStyle = (params: CellClassParams) => new RichVariableColumn().cellStyle(params);
      }

      return gridRow;
    });

    const isCallToActionVisible = ConditionProcessorUtil.processConditionsWithPermission(
      colDefs.masterViewTable.bluePrint.buttonColumn?.visibleConditions,
      this.userPermissions
    );
    const buttonColumn = colDefs.masterViewTable.bluePrint.buttonColumn;
    if (buttonColumn && isCallToActionVisible) {
      this.columnDefs = [
        ...this.columnDefs,
        {
          field: buttonColumn.id,
          headerName: buttonColumn.name,
          headerValueGetter: this.localizedHeader.bind(this),
          sortable: buttonColumn.sortable,
          suppressMenu: true,
          width: 40,
          maxWidth: 40,
          pinned: 'right',
          headerComponent: HeaderType.CALL_TO_ACTION,
          headerComponentParams: {
            bulkActions: colDefs.masterViewTable.bluePrint.buttonColumn.bulkActions,
            visibleConditions: colDefs.masterViewTable.bluePrint.buttonColumn.visibleConditions,
            userPermissions: this.userPermissions,
            dispatchEvent: (event) => {
              this.dispatchMasterViewEngineEvent.emit(event);
            }
          },
          cellRenderer: I4BCellType.CALL_TO_ACTION,
          cellRendererParams: {
            actions: colDefs.masterViewTable.bluePrint.buttonColumn.singleActions,
            userPermissions: this.userPermissions,
            dispatchEvent: (event) => {
              this.dispatchMasterViewEngineEvent.emit(event);
            }
          },
          valueGetter: (params) => JSON.stringify(params.data)
        }
      ];
    }

    if (colDefs.masterViewTable.bluePrint.selectionColumn && isCallToActionVisible) {
      const selectionCol = colDefs.masterViewTable.bluePrint.selectionColumn;
      this.columnDefs.unshift({
        headerCheckboxSelection: true,
        checkboxSelection: true,
        field: selectionCol.id,
        headerValueGetter: this.localizedHeader.bind(this),
        width: 30,
        minWidth: 30
      } as ColDef);
    }
  }

  private setFlexColumnClass() {
    const mainSidenavContentEl = this.document.querySelector('.main-sidenav-content');
    if (mainSidenavContentEl) {
      this.renderer.addClass(mainSidenavContentEl, 'flex-column');
    }
  }

  private setRowData(data) {
    this.rowData = data;
  }

  private setPagination(paginationInfo) {
    this.pagination$.next({
      ...this.pagination$.getValue(),
      total: paginationInfo.total,
      currentPage: paginationInfo.currentPage,
      hasMore: paginationInfo.hasMore,
      maxPage: paginationInfo.maxPage,
      limit: paginationInfo.limit
    });
  }

  private localizedHeader(parameters: ICellRendererParams): string {
    const headerIdentifier = parameters.colDef.headerName;
    return headerIdentifier ? this.translateService.instant(headerIdentifier) : '';
  }

  private loadStaticMetaData(): void {
    this.gridEngineService
      .loadStaticMetaData(this.masterViewType.name)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((masterView: MasterView) => {
        this.setColumnDefs(masterView);
      });
  }
}
