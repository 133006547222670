export enum AuthorizationType {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete'
}

export enum AuthorizationConcept {
  SITE = 'site',
  ASSET = 'asset',
  DEVICE = 'device',
  CONNECTOR = 'connector',
  TAG = 'TAG',
  EVENT = 'event',
  BUSINESS_PROFILE = 'BUSINESS_PROFILE',
  PO_EVENT_RULE = 'PO_EVENT_RULE',
  ORGANIZATION_TREE = 'ORGANIZATION_TREE',
  USER = 'USER',
  USER_STATUS = 'USER_STATUS',
  TOPIC = 'TOPIC',
  CALENDAR = 'CALENDAR',
  TEAM_PLANNING = 'TEAM_PLANNING',
  ESCALATION_PROTOCOL = 'ESCALATION_PROTOCOL',
  PRODUCT_CATALOG = 'PRODUCT_CATALOG',
  REPORTING = 'REPORTING',
  GRAPH = 'GRAPH',
  TRANSLATION = 'TRANSLATION',
  SCHEDULER = 'SCHEDULER',
  NOTIFICATION_TEMPLATE = 'NOTIFICATION_TEMPLATE',
  AUDIT_TRAIL = 'AUDIT_TRAIL',
  CONTACT = 'CONTACT'
}
