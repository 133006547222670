import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthInterceptor } from '@iot-platform/auth';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { UiModule } from '@iot-platform/iot-platform-ui';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { TableEngineModule } from '@iot-platform/table-engine';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ExportsModule } from 'libs/iot-platform-ui/src/lib/ui/components/exports/exports.module';
import { DateFormatPipe } from '../../../../../iot-platform-pipes/src/lib/date-format/date-format.pipe';
import { UserNamePipe } from '../../../../../iot-platform-pipes/src/lib/user-name/user-name.pipe';
import { DetailPopupCardsModule } from '../../../../../iot-platform-ui/src/lib/ui/components/detail-popup-cards/detail-popup-cards.module';
import { FavoriteViewsModule } from '../../../../../shared/src/lib/favorite-views/favorite-views.module';
import { MvToolbarModule } from '../../../../../shared/src/lib/mv-toolbar/mv-toolbar.module';
import { DeviceEventsService } from '../../../../../shared/src/lib/services/device-events.service';
import { VariableChartModule } from '../../../../../shared/src/lib/variable-chart/variable-chart.module';
import { VariableChartService } from '../../../../../shared/src/lib/variable-chart/variable-chart.service';
import { EventCommentsModule } from '../../components/event-comments/event-comments.module';
import { EventDetailPopupModule } from '../../components/event-detail-popup/event-detail-popup.module';
import { MvCellModule } from '../../components/mv-cell/mv-cell.module';
import { DirectivesModule } from '../../directives/directives.module';
import { DeviceEventsApi } from './+state/device-events.api';
import { DeviceEventsLogsEffects } from './+state/effects/device-events-logs.effects';
import { DeviceEventsEffects } from './+state/effects/device-events.effects';
import * as fromDeviceEvents from './+state/reducers';
import { DeviceEventCommentComponent } from './components/device-event-comment/device-event-comment.component';
import { DeviceEventCommentsComponent } from './components/device-event-comments/device-event-comments.component';
import { DeviceEventTimelineLogComponent } from './components/device-event-timeline-log/device-event-timeline-log.component';
import { DeviceEventTimelineComponent } from './components/device-event-timeline/device-event-timeline.component';
import { DeviceEventsShellComponent } from './containers/device-events-shell/device-events-shell.component';

const deviceEventsRoutes: Routes = [{ path: '', component: DeviceEventsShellComponent }];

@NgModule({
  imports: [
    SharedModule,
    UiModule,
    IotPlatformPipesModule,
    HttpClientModule,
    MvCellModule,
    DetailPopupCardsModule,
    MvToolbarModule,
    DirectivesModule,
    StoreModule.forFeature(fromDeviceEvents.deviceEventsFeatureKey, fromDeviceEvents.reducers),
    EffectsModule.forFeature([DeviceEventsEffects, DeviceEventsLogsEffects]),
    RouterModule.forChild(deviceEventsRoutes),
    FavoriteViewsModule,
    VariableChartModule,
    ExportsModule,
    TableEngineModule,
    EventCommentsModule,
    EventDetailPopupModule,
    GridEngineModule
  ],
  declarations: [
    DeviceEventsShellComponent,
    DeviceEventTimelineComponent,
    DeviceEventTimelineLogComponent,
    DeviceEventCommentComponent,
    DeviceEventCommentsComponent
  ],
  exports: [RouterModule],
  providers: [
    DeviceEventsService,
    DeviceEventsApi,
    DeviceEventsEffects,
    DeviceEventsLogsEffects,
    VariableChartService,
    DateFormatPipe,
    UserNamePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class DeviceEventsModule {}
