<div class="home-container" >
  <section class="">
    <div class="home-favorite-view-list-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <h2 class="">{{ 'MY_PAGE.TITLE.SHARED' | translate | uppercase }}</h2>
      <div fxFlex class="regular-1px-line"></div>
    </div>

    <div class="views-container" fxLayout="row wrap" fxLayoutGap="20px">
      <div *ngIf="(sharedFavoriteViews$ | async).length === 0" class="no-favorite-view-message">{{ 'MY_PAGE.NO_BP_FV' | translate | uppercase }}</div>

      <iot4bos-ui-home-favorite-view
        *ngFor="let favoriteView of sharedFavoriteViews$ | async"
        [favoriteView]="favoriteView"
        (navigateTo)="navigateTo($event)"
        [isLoading]="fvLoading"
      >
      </iot4bos-ui-home-favorite-view>
    </div>
  </section>

  <section class="">
    <div class="home-favorite-view-list-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <h2>{{ 'MY_PAGE.TITLE.NOT_SHARED' | translate | uppercase }}</h2>
      <div fxFlex class="regular-1px-line"></div>
    </div>

    <div class="views-container" fxLayout="row wrap" fxLayoutGap="20px">
      <div *ngIf="(notSharedFavoriteViews$ | async).length === 0" class="no-favorite-view-message">{{ 'MY_PAGE.NO_PERSONAL_FV' | translate | uppercase }}</div>

      <iot4bos-ui-home-favorite-view
        *ngFor="let favoriteView of notSharedFavoriteViews$ | async"
        [favoriteView]="favoriteView"
        (navigateTo)="navigateTo($event)"
        [isLoading]="fvLoading"
      >
      </iot4bos-ui-home-favorite-view>
    </div>
  </section>
</div>
