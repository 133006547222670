<div [ngClass]="{ 'breadcrumb-item': false, passive: true }" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" (click)="onClick()">
  <!--<iot-platform-ui-icon [ngClass]="['breadcrumb-item-icon', 'breadcrumb-site-icon']" name="site" color="#4f78b7"></iot-platform-ui-icon>-->
  <mat-icon svgIcon="site" color="accent"></mat-icon>
  <div fxLayout="column">
    <span class="secondary" *ngIf="site.entity && site.entity.name" fxLayoutAlign="start center">
      {{ site.entity.name }}
    </span>
    <span class="main" *ngIf="site.name" fxLayoutAlign="start center">{{ site.name }}</span>
  </div>
</div>
