import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomEncoder, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { Connector, PlatformRequest, PlatformResponse } from '@iot-platform/models/common';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeviceConnectorsService {
  constructor(@Inject('environment') private environment, public httpClient: HttpClient, private storage: LocalStorageService) {}

  getAll(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient.get(`${this.environment.api.url}${this.environment.api.endpoints.connectorConnectors}`, { params }).pipe(
      map((data: { page: any; content: Connector[] }) => {
        return {
          data: data.content,
          currentPage: data.page.curPage,
          hasMore: data.page.hasMore,
          limit: data.page.limit,
          maxPage: data.page.maxPage,
          total: data.page.total
        };
      })
    );
  }

  getConnectorsByDeviceId(deviceId: string): Observable<Connector[]> {
    return this.httpClient
      .get(`${this.environment.api.url}${this.environment.api.endpoints.devices}/${deviceId}` + '/connectors')
      .pipe(map((data: { page: any; content: Connector[] }) => data.content));
  }

  saveConnector(connector: Connector): Observable<Connector> {
    return this.httpClient.post<Connector>(`${this.environment.api.url}${this.environment.api.endpoints.connectorConnectors}`, connector);
  }

  updateConnector(connector: Connector): Observable<Connector> {
    return this.httpClient.patch<Connector>(`${this.environment.api.url}${this.environment.api.endpoints.connectorConnectors}/${connector.id}`, {
      name: connector.name,
      description: connector.description,
      entities: connector.entities
    });
  }

  deleteConnector(connector: Connector): Observable<Connector> {
    return this.httpClient
      .delete<Connector>(`${this.environment.api.url}${this.environment.api.endpoints.connectorConnectors}/${connector.id}`)
      .pipe(map((_) => connector));
  }

  saveTableState(selectedConnector): Observable<Connector> {
    this.storage.set(LocalStorageKeys.STORAGE_MV_CONNECTORS_TABLE_STATE_KEY, JSON.stringify(selectedConnector));
    return of(selectedConnector);
  }

  sendCommand(deviceId: string, command: any): Observable<any> {
    return this.httpClient
      .post(`${this.environment.api.url}${this.environment.api.endpoints.devices}/${deviceId}/commands`, command)
      .pipe(map((deviceId) => deviceId));
  }

  bulkSendCommand(deviceIds: string[], command: any) {
    return deviceIds.map((deviceId) => this.sendCommand(deviceId, command));
  }

  getAllCommands(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.environment.api.url}${this.environment.api.endpoints.connector}/commands`);
  }
}
