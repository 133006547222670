import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserNamePipe } from './user-name.pipe';

@NgModule({
  declarations: [UserNamePipe],
  imports: [CommonModule],
  exports: [UserNamePipe]
})
export class UserNameModule {}
