<div class="app-round-button-mv">
  <button data-cy='i4b-table-engine-call-to-action-menu-btn' mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"><mat-icon>more_vert</mat-icon></button>
  <mat-menu #menu="matMenu">
    <div *ngFor="let action of actions">
      <i4b-table-engine-call-to-action-menu-item
        [action]="action"
        [element]="element"
        [userPermissions]="userPermissions"
        (actionClicked)="actionClicked.emit($event)"
      >
      </i4b-table-engine-call-to-action-menu-item>
    </div>
  </mat-menu>
</div>
