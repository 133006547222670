import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PoEventRule } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-po-event-detail-popup',
  templateUrl: './po-event-detail-popup.component.html',
  styleUrls: ['./po-event-detail-popup.component.scss']
})
export class PoEventDetailPopupComponent {
  @Output() ruleNameClicked: EventEmitter<PoEventRule> = new EventEmitter<PoEventRule>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<PoEventDetailPopupComponent>) {}

  close() {
    this.dialogRef.close();
  }
}
