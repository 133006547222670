import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';

import { AuthValidators } from '../../validators/auth.validator';

@Component({
  selector: 'iot-platform-login-change-temporary-password-form',
  templateUrl: './login-change-temporary-password-form.component.html',
  styleUrls: ['./login-change-temporary-password-form.component.scss', '../../scss/style.scss']
})
export class LoginChangeTemporaryPasswordFormComponent implements OnInit {
  form: UntypedFormGroup;
  enableGauge: boolean;

  @Input() hasError: { message: string };

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() changeTemporaryPassword: EventEmitter<string> = new EventEmitter();

  password$ = new Subject<string>();

  ngOnInit() {
    this.form = new UntypedFormGroup({
      newPassword: new UntypedFormControl(null, [Validators.required, AuthValidators.checkPassword]),
      confirmNewPassword: new UntypedFormControl(null, [Validators.required, this.checkConfirmPassword.bind(this)])
    });
  }

  get newPassword(): AbstractControl {
    return this.form.get('newPassword');
  }

  get confirmNewPassword(): AbstractControl {
    return this.form.get('confirmNewPassword');
  }

  sendPasswordToRules(value: string): void {
    this.password$.next(value);
  }

  checkConfirmPassword(confirmPassword: UntypedFormControl | AbstractControl) {
    if (!this.form) {
      return null;
    }
    const password: AbstractControl = this.form.controls.newPassword;
    return confirmPassword.value === password.value ? null : { different: true };
  }

  onCancel() {
    this.cancel.emit();
  }

  onChangeTemporaryPassword() {
    this.changeTemporaryPassword.emit(this.newPassword.value);
  }
}
