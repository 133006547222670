import { Injectable } from '@angular/core';
import { Site } from '@iot-platform/models/oyan';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { SiteActions } from '../../../sites/state/actions';
import { SitesFacade } from '../../../sites/state/facades/sites.facade';
import { ActiveElementType } from '../../models/enums/active-element-type.enum';
import { NavigationsActions } from '../actions';

@Injectable()
export class NavigationsEffects {
  setActiveSite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteActions.setActiveSite),
      map(({ site }) =>
        NavigationsActions.setActiveElement({
          activeElement: {
            type: ActiveElementType.SITE,
            data: site
          }
        })
      )
    );
  });
  setActiveElement$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NavigationsActions.setActiveElement),
        concatLatestFrom(() => this.sitesFacade.wardsPagination$),
        tap(([action, wardsPagination]) => {
          const {
            activeElement: { type, data }
          } = action;
          if (type === ActiveElementType.SITE) {
            this.sitesFacade.loadGatewaysBySite(data as Site);
            this.sitesFacade.loadWardsBySite(data as Site, wardsPagination);
          }
        })
      );
    },
    { dispatch: false }
  );

  constructor(private readonly actions$: Actions, private readonly sitesFacade: SitesFacade) {}
}
