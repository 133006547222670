<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'DEVICES.MOVE_TO.TITLE' | translate }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="closeOnCancel()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content fxLayout="row" class="dialog-card-content">
    <section class="device-move-form-list p-20" fxFlex="40%" fxLayout="column">
      <div *ngIf="data.devices && data.devices.length === 1" class="device-move-form-list-title">{{ 'DEVICES.MOVE_TO.LIST_TITLE_SINGULAR' | translate }}</div>
      <div *ngIf="data.devices && data.devices.length > 1" class="device-move-form-list-title">
        {{ 'DEVICES.MOVE_TO.LIST_TITLE_PLURAL' | translate: { total: data.devices.length } }}
      </div>
      <ul>
        <li *ngFor="let device of data.devices">{{ device.name }}</li>
      </ul>
    </section>

    <section fxFlex="60%" fxLayout="column" fxLayoutAlign="space-between">
      <form [formGroup]="deviceMoveToForm" class="p-20" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field class="device-move-to-autocomplete" fxFlex="90%" color="accent">
          <input
            required
            matInput
            type="text"
            formControlName="destinationSite"
            [matAutocomplete]="auto"
            [placeholder]="'DEVICES.MOVE_TO.PLACEHOLDER' | translate"
            (keyup)="getFilteredSites(false)"
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option class="device-move-to-autocomplete-option" *ngFor="let site of filteredSites" [value]="site">
              <span class="auto-name">{{ site.name }}</span>
              <span class="auto-address">{{ site.address.address1 }} - {{ site.address | infoDisplay: 'brackets':'city':'zipCode' }}</span>
            </mat-option>
            <button *ngIf="hasMore" mat-button class="option-hasmore" fxLayout="row" fxLayoutAlign="space-around center" (click)="getFilteredSites(true)">
              <span>{{ 'DEVICES.MOVE_TO.LOAD_MORE_OPTIONS' | translate }}</span>
            </button>
          </mat-autocomplete>
        </mat-form-field>
        <mat-progress-spinner *ngIf="spinner" mode="indeterminate" [diameter]="20" color="accent"></mat-progress-spinner>
      </form>

      <div class="device-move-to-confirmation-zone" [class.visible]="deviceMoveToForm.valid && destinationSite.value.name">
        <div *ngIf="data.devices && data.devices.length === 1" class="device-move-to-summary">
          {{ 'DEVICES.MOVE_TO.CONFIRMATION_MESSAGE_SINGULAR' | translate }}
        </div>
        <div *ngIf="data.devices && data.devices.length > 1" class="device-move-to-summary">
          {{ 'DEVICES.MOVE_TO.CONFIRMATION_MESSAGE_PLURAL' | translate: { total: data.devices.length } }}
        </div>
        <div fxLayout="row" fxLayoutAlign="center">
          <div
            fxLayout="column"
            fxFlex="40"
            fxLayoutAlign="start center"
            class="device-move-to-origin"
            [ngClass]="{ 'customer-site': originSites.length === 1 && data.devices[0].site.type === 'customer_site' }"
          >
            <mat-icon svgIcon="alizent" color="#394C5A"></mat-icon>
            <div *ngIf="originSites.length === 1; else multipleOriginSites" fxLayout="column">
              <span class="device-move-to-site-type">{{ 'SITES.CARD.TYPES.' + data.devices[0].site.type | translate }}</span>
              <span class="device-move-to-site-name">{{ data.devices[0].site.name }}</span>
            </div>
            <ng-template #multipleOriginSites>
              <span class="device-move-to-site-type">{{ 'DEVICES.MOVE_TO.FROM_MULTIPLE_SITES' | translate: { total: originSites.length } }}</span>
            </ng-template>
          </div>
          <div fxLayout="column" fxFlex="20" fxLayoutAlign="start center" class="device-move-to-picto">
            <mat-icon>wifi_tethering</mat-icon>
            <mat-icon>arrow_right</mat-icon>
          </div>
          <div fxLayout="column" fxFlex="40" fxLayoutAlign="start center" class="device-move-to-destination">
            <mat-icon svgIcon="site" color="#394C5A"></mat-icon>
            <span class="device-move-to-site-type">{{ 'SITES.CARD.TYPES.' + destinationSite.value.type | translate }}</span>
            <span class="device-move-to-site-name">{{ destinationSite.value.name }}</span>
          </div>
        </div>
      </div>
    </section>
  </mat-card-content>

  <mat-card-actions fxLayoutAlign="end center" class="dialog-card-actions">
    <button mat-button class="button-regular" (click)="closeOnCancel()">{{ 'DEVICES.MOVE_TO.CANCEL' | translate }}</button>
    <button mat-raised-button class="button-regular" color="accent" (click)="apply()" [disabled]="!deviceMoveToForm.valid || !destinationSite.value.name">
      {{ 'IOT_DICTIONARY.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
