<div fxFlex fxLayout="column">
  <div class="sticky">
    <iot-platform-ui-toolbar-v2
      [total]="totalItems$ | async"
      [name]="'SITES.NAME_TOOLBAR' | translate"
      [isDataLoaded]="loaded$ | async"
      [buttonList]="toolbarButtonList"
      (dispatchToolbarEvent)="onToolbarEvent($event)"
    >
    </iot-platform-ui-toolbar-v2>

    <iot-platform-ui-filter-engine-container
      [masterView]="'sites'"
      [expanded]="filterEngineOpened$ | async"
      [clearAppliedFilters$]="clearAppliedFilters$"
      [currentFilters$]="filtersToDisplay$"
      [currentFavoriteView$]="currentFavoriteView$"
      (applyFilters)="onApplyFilters($event)"
    >
    </iot-platform-ui-filter-engine-container>
  </div>
  <div fxLayout="row" fxFlex>
    <i4b-table-engine-master-view-engine
      fxFlex
      [sticky]="true"
      [masterViewType]="{ name: 'sites', isPath: false }"
      [useExternalData]="true"
      [data]="platformResponse$ | async"
      (pageChange)="onPageChange($event)"
      [pageSizeOptions]="pageSizeOptions"
      (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
    >
    </i4b-table-engine-master-view-engine>
  </div>
</div>
