import { Site } from '@iot-platform/models/oyan';
import { ProductBuilder } from './product.builder';

export class SiteBuilder {
  public static DEFAULT_SITE = {
    id: 'e03b49bc-d878-47b7-86d7-7157e68872aa',
    entity: {
      id: '0334edd2-9b2f-4069-be39-bb56ee74b40d',
      name: 'ITSS-0010747171'
    },
    name: '0010747171',
    description: 'test description',
    type: 'customer_site',
    businessId: null,
    address: {
      address1: 'test address 1',
      address2: 'test address 2',
      city: 'test city',
      zipCode: 95200,
      country: 'FRA'
    },
    geolocation: {
      latitude: null,
      longitude: null
    },
    contacts: [],
    imageUrl: null,
    totalAssets: 32,
    totalDevices: 0,
    totalTags: 0,
    totalEvents: 0,
    createdAt: '2021-05-25T10:03:20.498689+00:00',
    createdBy: null,
    updatedAt: '2021-07-12T10:11:14.790910+00:00',
    updatedBy: 'bbef5cc6-c297-4e25-b399-d5d548e4bf77',
    products: [ProductBuilder.build('8d56f330-96f4-4e87-a152-f9a0b955aab4')],
    gateways: ['201a46b3-ac02-4768-98f7-d6b38962a27e', 'cdebf91a-324a-4987-b64b-5ea65cd077a3'],
    displayName: 'test demo 2',
    thresholdTimeExpiryDate: 5,
    timeBeforeLost: 5,
    timeBeforeNotDetected: 0,
    firstAssignThreshold: 30,
    assignThreshold: 60,
    checkOutThreshold: 0,
    checkInThreshold: 0,
    thresholdFull: 65
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static build = (id: string, name = '', params: Partial<Site> = SiteBuilder.DEFAULT_SITE): Site | any => ({
    ...params,
    id,
    name
  });
}
