import { Sort } from '@angular/material/sort';

import { gridSortModel, TagCategory } from '@iot-platform/models/common';
import { Asset, AssetEvent, AssetVariable, Device, DeviceVariable, Site } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { AssetEventsDbActions, AssetEventsLogsDbActions, AssetEventsUiActions } from '../actions';

export const assetEventsDbFeatureKey = 'assetEventsDb';

export interface State extends EntityState<AssetEvent> {
  selectedAssetEventId: string | null;
  checkedAssetEventIds: string[];
  error?: any;
  site?: Site | null;
  asset?: Asset | null;
  assetVariable?: AssetVariable | null;
  device?: Device | null;
  deviceVariable?: DeviceVariable | null;
  tags?: TagCategory[];
  status?: AssetEvent;
  settings: any;
  initialSort: gridSortModel[] | Sort;
  pagination: { currentPage: number; hasMore: boolean; limit: number; maxPage: number; total: number };
}

export const adapter: EntityAdapter<AssetEvent> = createEntityAdapter<AssetEvent>({
  selectId: (assetEvent: AssetEvent) => assetEvent.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedAssetEventId: null,
  checkedAssetEventIds: [],
  error: null,
  site: null,
  asset: null,
  device: null,
  tags: [],
  settings: null,
  initialSort: [],
  pagination: { currentPage: 0, hasMore: false, limit: 10, maxPage: 0, total: 0 }
});

const assetEventsDbReducer = createReducer(
  initialState,
  on(AssetEventsUiActions.loadAssetEvents, (state: State) => ({ ...state })),
  on(AssetEventsDbActions.loadAssetEventsSuccess, (state: State, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: {
        currentPage: response.currentPage,
        hasMore: response.hasMore,
        limit: response.limit,
        maxPage: response.maxPage,
        total: response.total
      }
    })
  ),
  // ****
  on(AssetEventsUiActions.loadSiteById, (state: State) => ({ ...state, site: null })),
  on(AssetEventsDbActions.loadSiteByIdSuccess, (state: State, { site }) => ({ ...state, site })),
  // ****
  on(AssetEventsDbActions.loadMVSettingsSuccess, (state: State, { settings }) => ({ ...state, settings })),
  on(AssetEventsDbActions.saveMVSettingsSuccess, (state: State, { settings }) => ({ ...state, settings })),
  // ****
  on(AssetEventsUiActions.loadAssetById, (state: State) => ({ ...state, asset: null })),
  on(AssetEventsDbActions.loadAssetByIdSuccess, (state: State, { asset }) => ({ ...state, asset })),
  // ****
  on(AssetEventsUiActions.loadDeviceById, (state: State) => ({ ...state, device: null })),
  on(AssetEventsDbActions.loadDeviceByIdSuccess, (state: State, { device }) => ({ ...state, device })),
  // ****
  on(AssetEventsUiActions.loadDeviceVariableById, (state: State) => ({ ...state, deviceVariable: null })),
  on(AssetEventsDbActions.loadDeviceVariableByIdSuccess, (state: State, { deviceVariable }) => ({ ...state, deviceVariable })),
  // ****
  on(AssetEventsUiActions.loadAssetVariableById, (state: State) => ({ ...state, assetVariable: null })),
  on(AssetEventsDbActions.loadAssetVariableByIdSuccess, (state: State, { assetVariable }) => ({ ...state, assetVariable })),
  // ****
  on(AssetEventsUiActions.loadTagsByAssetEventId, (state: State) => ({ ...state, tags: [] })),
  on(AssetEventsDbActions.loadTagsByAssetEventIdSuccess, (state: State, { tags }) => ({ ...state, tags })),
  // ****
  on(AssetEventsLogsDbActions.createLogByAssetEventIdSuccess, (state: State, { log, assetEvent }) => {
    return adapter.updateOne(
      {
        id: assetEvent.id,
        changes: { totalComments: assetEvent.totalComments + 1 }
      },
      state
    );
  }),
  // ****
  on(AssetEventsDbActions.updateStatusByAssetEventIdSuccess, (state: State, { assetEvent }) => {
    return adapter.updateOne({ id: assetEvent.id, changes: assetEvent }, { ...state, status: assetEvent });
  }),
  on(AssetEventsDbActions.bulkUpdateStatusByAssetEventIdSuccess, (state: State, { assetEvents }) => {
    const updates: Array<{ id: string; changes: any }> = assetEvents.reduce((acc, value) => {
      acc.push({ id: value.id, changes: value });
      return acc;
    }, []);

    return adapter.updateMany(updates, { ...state, status: assetEvents[0] });
  }),
  on(AssetEventsDbActions.saveTableStateSuccess, (state: State, { selectedId, checkedIds }) => ({
    ...state,
    selectedAssetEventId: selectedId,
    checkedAssetEventIds: checkedIds
  })),
  on(AssetEventsUiActions.saveMVSettings, (state: State, { name, type, values }) => adapter.removeAll({ ...state })),
  on(AssetEventsDbActions.saveMVSettingsSuccess, (state: State, { settings }) => ({ ...state, settings })),
  //
  on(AssetEventsUiActions.saveInitialSort, (state: State, { initialSort }) => ({ ...state, initialSort }))
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsDbReducer(state, action);
}

export const getSelectedId = (state: State) => state.selectedAssetEventId;
export const getCheckedIds = (state: State) => state.checkedAssetEventIds;
export const getSite = (state: State) => state.site;
export const getAsset = (state: State) => state.asset;
export const getAssetVariable = (state: State) => state.assetVariable;
export const getDevice = (state: State) => state.device;
export const getDeviceVariable = (state: State) => state.deviceVariable;
export const getTags = (state: State) => state.tags;
export const getStatus = (state: State) => state.status;
export const getSettings = (state: State) => state.settings;
