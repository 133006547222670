import { Injectable } from '@angular/core';
import { ApiSelector, Filter, Pagination } from '@iot-platform/models/common';
import { Asset } from '@iot-platform/models/oyan';
import * as fromMain from '../reducers';
import * as fromAssets from '../reducers/assets.reducer';

@Injectable({
  providedIn: 'root'
})
export class AssetsSelectors extends ApiSelector<fromMain.State, Asset, Pagination, Filter> {
  constructor() {
    super(fromMain.featureKey, fromAssets.featureKey, fromAssets.adapter);
  }
}
