import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TagCategory } from '@iot-platform/models/common';

@Component({
  selector: 'iot-platform-ui-tag-category-list-by-concept',
  templateUrl: './tag-category-list-by-concept.component.html',
  styleUrls: ['./tag-category-list-by-concept.component.scss']
})
export class TagCategoryListByConceptComponent implements OnInit {
  @Input() canManageTags = false;
  @Input() canCreate = false;
  @Input() canUpdate = false;
  @Input() canDelete = false;
  @Input() concept: string;
  @Input() labelOnly = false;
  @Input() tagCategoriesByConcept: TagCategory[];

  @Output() manageTags: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  onManageTags(concept: string): void {
    this.manageTags.emit(concept);
  }
}
