<div class="detail-card" fxLayout="column" fxLayoutGap="4px">
  <ul class="detail-card-list">
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_DEVICE_VARIABLE_CARD_COMPONENT.NAME' | translate }}">{{ device?.name | infoDisplay }}</li>
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_DEVICE_VARIABLE_CARD_COMPONENT.TYPE' | translate }}">
      {{ device?.type | infoDisplay: 'hyphen':'family':'model' }}
    </li>
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_DEVICE_VARIABLE_CARD_COMPONENT.VARIABLE' | translate }}">
      {{ deviceVariable?.name | infoDisplay }}
    </li>
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_DEVICE_VARIABLE_CARD_COMPONENT.EVENT_VALUE' | translate }}">
      <span *ngIf="!eventVariableProcessedUnit"> {{ event?.context?.deviceVariable?.value | numberFormat | infoDisplay }} {{ event?.context?.deviceVariable?.unit }} </span>
      <span *ngIf="eventVariableProcessedUnit"> {{ eventVariableProcessedUnit }} </span>
      <span *ngIf="event?.context?.deviceVariable?.value">({{ event?.occurrenceTime | dateFormat }})</span>
    </li>
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_DEVICE_VARIABLE_CARD_COMPONENT.COMMENT' | translate }}">
      {{ deviceVariable?.comment | infoDisplay }}
    </li>
  </ul>
  <ul class="detail-card-list">
    <li class="detail-card-item" attr.data-label="{{ 'DETAIL_DEVICE_VARIABLE_CARD_COMPONENT.CURRENT_VALUE' | translate }}">
      <span class="detail-asset-variable-card-value" *ngIf="deviceVariable?.lastValue && !deviceVariableProcessedUnit">
        {{ deviceVariable?.lastValue.value | numberFormat | infoDisplay }} {{ deviceVariable?.unit }}
      </span>
      <span *ngIf="deviceVariableProcessedUnit">
        {{ deviceVariableProcessedUnit }}
      </span>
      <span *ngIf="deviceVariable && deviceVariable?.lastValue">({{ deviceVariable.lastValue.datetime | dateFormat }})</span>
      <span *ngIf="!deviceVariable?.lastValue">-</span>
    </li>
  </ul>
</div>
