import { User } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const loadUserProfile = createAction('[Users/Profile] Load User Profile');
export const loadUserProfileSuccess = createAction('[Users/Profile] Load User Profile Success', props<{ user: User }>());
export const loadUserProfileFailure = createAction('[Users/Profile] Load User Profile Failure', props<{ error: any }>());

export const saveUserProfile = createAction('[Users/Profile] Save User Profile', props<{ userToUpdate: User }>());
export const saveUserProfileSuccess = createAction('[Users/Profile] Save User Profile Success', props<{ updatedUser: User }>());
export const saveUserProfileFailure = createAction('[Users/Profile] Save User Profile Failure', props<{ error: any }>());
