import { User } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AdminOrganizationsAdministratorsApiActions } from '../actions';

export const adminOrganizationsAdministratorsApiFeatureKey = 'adminOrganizationsAdministratorsApi';

export interface State extends EntityState<User> {
  selectedAdministratorId: string | null;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: (administrator: User) => administrator.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedAdministratorId: null
});

export const reducer = createReducer(
  initialState,
  on(AdminOrganizationsAdministratorsApiActions.listAdministratorsByOrganizationSuccess, (state: State, { administrators }) =>
    adapter.setAll(administrators, state)
  ),
  on(AdminOrganizationsAdministratorsApiActions.listAdministratorsByOrganizationFailure, (state: State, { error }) => ({ ...state, error })),
  on(AdminOrganizationsAdministratorsApiActions.addAdministratorToOrganizationSuccess, (state: State, { addedAdministrator }) =>
    adapter.addOne(addedAdministrator, state)
  ),
  on(AdminOrganizationsAdministratorsApiActions.removeAdministratorFromOrganizationSuccess, (state: State, { removedAdministratorId }) =>
    adapter.removeOne(removedAdministratorId, state)
  )
);

export const getSelectedId = (state: State) => state.selectedAdministratorId;
