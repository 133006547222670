import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '@iot-platform/auth';
import { ApiInterceptorModule, LocalStorageModule } from '@iot-platform/core';
import { I18nModule } from '@iot-platform/i18n';
import { NotificationModule } from '@iot-platform/notification';
import { ConfigModule as OyanConfigModule } from '@iot-platform/oyan-ui';
import { PreferencesModule } from '@iot-platform/users';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NxModule } from '@nrwl/angular';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRouterModule } from './app.router.module';

export function debug(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
  return function (state, action) {
    console.log('state', state);
    console.log('action', action);
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<unknown>[] = [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    LocalStorageModule.forRoot({ prefix: environment.storage.appPrefix }),
    AppRouterModule,
    BrowserAnimationsModule,
    NxModule.forRoot(),
    StoreModule.forRoot(
      {},
      {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
          strictStateSerializability: false,
          strictActionSerializability: false
        }
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 20 }) : [],
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer
    }),
    OyanConfigModule.forRoot({
      front: { version: '1.0.0' },
      back: { version: '1.0.0' }
    }),
    I18nModule.forRoot({ langs: ['fr', 'en', 'de', 'it'] }),
    I18nModule.getInstance(),
    NotificationModule,
    PreferencesModule,
    AuthModule.forRoot(),
    ApiInterceptorModule.forRoot({
      excludedApis: ['/admin-profiles', '/grids', '/favorite-views', '/directory/users']
    })
  ],
  providers: [{ provide: 'environment', useValue: environment }],
  bootstrap: [AppComponent]
})
export class AppModule {}
