<div *ngIf="role" fxFlexAlign="center center" [ngClass]="{'roles-crud-container': readonly}">
  <div *ngIf="!enabledConcepts.length && readonly" class="background no-profile">
    {{ 'ADMIN.ROLES_CRUD.ROLE_NOT_CONFIGURED' | translate}}
  </div>
  <section *ngIf="disabledConcepts.length > 0 || enabledConcepts.length > 0" fxLayoutAlign="space-between center" class="roles-crud-section" fxLayoutGap="30px">
    <div fxFlex></div>
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="20px" class="crud-icons">
      <mat-icon [matTooltip]="'ADMIN.ROLES_CRUD.TOOLTIPS.READ' | translate" matTooltipClass="regular-tooltip" matTooltipPosition="above">remove_red_eye</mat-icon>
      <mat-icon [matTooltip]="'ADMIN.ROLES_CRUD.TOOLTIPS.CREATE' | translate" matTooltipClass="regular-tooltip" matTooltipPosition="above">add</mat-icon>
      <mat-icon [matTooltip]="'ADMIN.ROLES_CRUD.TOOLTIPS.UPDATE' | translate" matTooltipClass="regular-tooltip" matTooltipPosition="above">edit</mat-icon>
      <mat-icon [matTooltip]="'ADMIN.ROLES_CRUD.TOOLTIPS.DELETE' | translate" matTooltipClass="regular-tooltip" matTooltipPosition="above">delete</mat-icon>
    </div>
    <div fxFlex="5%">&nbsp;</div>
  </section>

  <section *ngIf="enabledConcepts.length > 0" fxLayout="column" fxLayoutAlign="start stretch" class="roles-crud-section">
    <div *ngIf="!readonly" fxLayoutAlign="start center" fxLayoutGap="10px" class="roles-crud-section-title fw-600" >
      <h4 fxLayoutAlign="start center">{{ 'ADMIN.ROLES_CRUD.CONCEPTS_TO_MANAGE' | translate | uppercase }}</h4>
      <span fxFlex class="regular-1px-line"></span>
    </div>

    <div *ngFor="let concept of enabledConcepts" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="30px" class="roles-crud-section-concepts">
      <span fxFlex fxLayoutAlign="end" class="roles-crud-section-concept-name">{{
        'ADMIN.ORGANIZATIONS.CONCEPT.LIST.' + concept.name.toLowerCase() | translate
      }}</span>
      <iot4bos-backoffice-ui-roles-crud-checkbox
        fxLayoutAlign=" center"
        [authorizations]="concept.authorizations"
        [disabled]="!concept.enabled || !isAdmin || readonly"
        (toggleAuthorizations)="onToggleAuthorizations($event, concept)"
      >
      </iot4bos-backoffice-ui-roles-crud-checkbox>
      <div fxFlex="5%" fxLayoutAlign="center center">
        <mat-icon class="manage-concept-button" (click)="moveConceptToAvailable(concept)" *ngIf="isAdmin && !readonly">
          remove
        </mat-icon>
      </div>
    </div>
  </section>

  <section *ngIf="availableConcepts.length > 0 && isAdmin && !readonly" fxLayout="column" fxLayoutAlign="start stretch" class="roles-crud-section">
    <div fxLayoutAlign="start center" fxLayoutGap="10px" class="roles-crud-section-title">
      <h4 fxLayoutAlign="start center">
        {{ 'ADMIN.ROLES_CRUD.AVAILABLE_CONCEPTS' | translate | uppercase }}
      </h4>
      <span fxFlex class="regular-1px-line"></span>
    </div>

    <div *ngFor="let concept of availableConcepts" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="30px" class="roles-crud-section-concepts">
      <span fxFlex fxLayoutAlign="end" class="roles-crud-section-concept-name" class="concept-disabled">{{
        'ADMIN.ORGANIZATIONS.CONCEPT.LIST.' + concept.name.toLowerCase() | translate
      }}</span>
      <iot4bos-backoffice-ui-roles-crud-checkbox fxLayoutAlign=" center" [authorizations]="concept.authorizations" [disabled]="!concept.enabled || !isAdmin">
      </iot4bos-backoffice-ui-roles-crud-checkbox>
      <div fxFlex="5%" fxLayoutAlign="center center">
        <mat-icon class="manage-concept-button" (click)="moveConceptToEnabled(concept)" *ngIf="isAdmin">
          add
        </mat-icon>
      </div>
    </div>
  </section>

  <section *ngIf="disabledConcepts.length > 0" fxLayout="column" fxLayoutAlign="start stretch" class="roles-crud-section">
    <div fxLayoutAlign="start center" fxLayoutGap="10px" class="roles-crud-section-title">
      <h4 fxLayoutAlign="start center">{{ 'ADMIN.ROLES_CRUD.MANAGED_BY_HIGHER_ADMIN' | translate | uppercase }}</h4>
      <span fxFlex class="regular-1px-line"></span>
    </div>
    <div *ngFor="let concept of disabledConcepts" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="30px" class="roles-crud-section-concepts">
      <span fxFlex fxLayoutAlign="end" class="roles-crud-section-concept-name" class="concept-disabled">{{
        'ADMIN.ORGANIZATIONS.CONCEPT.LIST.' + concept.name.toLowerCase() | translate
      }}</span>
      <iot4bos-backoffice-ui-roles-crud-checkbox fxLayoutAlign=" center" [authorizations]="concept.authorizations" [disabled]="!concept.enabled || !isAdmin">
      </iot4bos-backoffice-ui-roles-crud-checkbox>
      <div fxFlex="5%" fxLayoutAlign="center center" class="concept-disabled" *ngIf="!concept.enabled || !isAdmin">
        <mat-icon>lock</mat-icon>
      </div>
    </div>
  </section>
</div>
