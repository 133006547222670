import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { MasterViewEngineEvent } from '@iot-platform/models/common';
import { ConditionProcessorUtil } from '../../../../../../../../table-engine/src/lib/utils/condition-processor';

@Component({
  selector: 'grid-engine-call-to-action-menu-item',
  templateUrl: './call-to-action-menu-item.component.html',
  styleUrls: ['./call-to-action-menu-item.component.scss']
})
export class CallToActionMenuItemComponent implements OnInit, OnChanges {
  @Input() element: any;
  @Input() action: any;
  @Input() userPermissions: Array<{ key: string; value: boolean }>;

  @Output() actionClicked: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();
  @Output() sendIsVisible: EventEmitter<{ action: any; isVisible: boolean }> = new EventEmitter();

  isVisible: boolean;
  isEnabled: boolean;
  isDisabled: boolean;

  ngOnInit() {
    this.setVisibleAndEnable(this.element, this.action);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.element?.currentValue) {
      this.setVisibleAndEnable(changes.element.currentValue, this.action);
    }
  }

  setVisibleAndEnable(element: any, action) {
    if (element && action) {
      this.isVisible = ConditionProcessorUtil.processConditionsWithPermission(action.visibleConditions, this.userPermissions);
      this.isEnabled = ConditionProcessorUtil.processConditionsWithElement(action.enableConditions, element, true);
      this.isDisabled = ConditionProcessorUtil.processConditionsWithElement(action.disableConditions, element, false);
      this.sendIsVisible.emit({ action: this.action, isVisible: this.isVisible });
    }
  }

  onSingleActionClick(actionKey: string): void {
    this.actionClicked.emit({ type: actionKey, options: { elementId: this.element.id, actionKey, element: this.element }, rawData: this.element });
  }
}
