import { NgModule } from '@angular/core';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../../../../../iot4bos-ui/src/lib/directives/directives.module';
import { MasterViewEngineModule } from '../../../../../table-engine/src/lib/components/master-view-engine/master-view-engine.module';
import { ReceiversShellComponent } from './containers/receivers-shell/receivers-shell.component';
import { ReceiversRoutingModule } from './receivers-routing.module';
import { ReceiversEffects } from './state/effects/receivers.effects';
import * as fromReceivers from './state/reducers';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule,
    IotPlatformPipesModule,
    IotPlatformUiModule,
    DirectivesModule,
    MasterViewEngineModule,
    ReceiversRoutingModule,
    StoreModule.forFeature(fromReceivers.featureKey, fromReceivers.reducers),
    EffectsModule.forFeature([ReceiversEffects])
  ],
  declarations: [ReceiversShellComponent]
})
export class ReceiversModule {
}
