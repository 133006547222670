import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@iot-platform/models/common';

@Component({
  selector: 'oyan-ui-user-profile-preferences',
  templateUrl: './user-profile-preferences.component.html',
  styleUrls: ['./user-profile-preferences.component.scss']
})
export class UserProfilePreferencesComponent {
  @Input() currentUser: User;
  @Output() changeUserPreferences: EventEmitter<void> = new EventEmitter<void>();
}
