import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fromAuth } from '@iot-platform/auth';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { Filter, MasterViewEngineEvent } from '@iot-platform/models/common';
import { DevicesGridData, I4BGrid, I4BGridOptions } from '@iot-platform/models/grid-engine';

import { Device, Site } from '@iot-platform/models/i4b';
import { select, Store } from '@ngrx/store';

import { AuthorizationConcept, AuthorizationType } from 'libs/auth/src/lib/authorization.types';
import { AuthorizationService } from 'libs/auth/src/lib/services/authorization.service';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { GridsDbActions } from '../../../../../../../grid-engine/src/lib/components/state/actions';
import * as fromGrids from '../../../../../../../grid-engine/src/lib/components/state/reducers';

import { NavigationApi } from '../../../../containers/+state/navigation.api';
import {
  DeviceDetailPopupComponent
} from '../../../devices/components/device-detail-popup/device-detail-popup.component';
import {
  DeviceMoveToFormComponent
} from '../../../devices/components/device-move-to-form/device-move-to-form.component';

@Component({
  selector: 'iot4bos-ui-stock-management-shell',
  templateUrl: './stock-management-shell.component.html',
  styleUrls: ['./stock-management-shell.component.scss']
})
export class StockManagementShellComponent implements OnInit, OnDestroy {
  origin = this.storage.get(LocalStorageKeys.STORAGE_ORIGIN_KEY);
  mvorigin = this.storage.get(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY);
  activeItemId = this.storage.get(LocalStorageKeys.STORAGE_ACTIVE_ITEM_ID_KEY);
  activeSiteId = this.storage.get(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY);

  site: Site;
  deviceCountByFamily$: Observable<Array<{ family: string; total: number }>> = this.navigationApi.deviceCountByFamily$;
  deviceCountByFamilyLoading$: Observable<boolean> = this.navigationApi.deviceCountByFamilyLoading$;

  // Grid
  gridConcept = 'stock-site-devices';
  grid$: Observable<I4BGrid<I4BGridOptions, DevicesGridData> | undefined> = this.store.pipe(select(fromGrids.selectDefaultStockSiteDevicesGrid));
  gridTotalElements$: Observable<number> = this.grid$.pipe(map((grid) => grid?.data?.response.pagination.total ?? 0));
  gridSort$ = this.grid$.pipe(switchMap((grid) => (grid ? this.store.select(fromGrids.getSortByGrid(grid.id as string)) : of([]))));
  requestEndpoint = '/devices';
  currentFilters: Filter[] = [{ criteriaKey: 'siteId', value: this.activeSiteId }];

  // Permissions
  canUpdateDevice = false;
  userPermissions: Array<{ key: string; value: boolean }> = [];

  //
  destroyed$: Subject<void> = new Subject();

  constructor(
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly navigationApi: NavigationApi,
    private readonly authorizationService: AuthorizationService,
    private readonly storage: LocalStorageService,
    private readonly store: Store
  ) {}

  ngOnInit() {
    this.canUpdateDevice = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.UPDATE);
    this.userPermissions = [{ key: 'canUpdateDevice', value: this.canUpdateDevice }];

    this.navigationApi.site$.pipe(takeUntil(this.destroyed$)).subscribe((site: Site) => (this.site = site));

    combineLatest([this.store.pipe(select(fromAuth.selectSelectedBusinessProfileForAccount)), this.store.pipe(select(fromGrids.getAllGrids)), this.grid$])
      .pipe(
        takeUntil(this.destroyed$),
        filter(([businessProfile, grids, grid]) => !!businessProfile)
      )
      .subscribe(([businessProfile, grids, grid]) => {
        if (businessProfile && grids && !grid) {
          this.store.dispatch(
            GridsDbActions.selectGridAndLoadData({
              gridId: 'default',
              masterview: this.gridConcept,
              filters: this.currentFilters,
              endPoint: this.requestEndpoint
            })
          );
        }
      });
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent) {
    switch (event.type) {
      case 'navigateToDevice':
        this.onOpenDeviceDetail(event.rawData);
        break;
      case 'open':
        this.openStockDetail(event.rawData);
        break;
      case 'moveTo':
        this.moveTo([event.rawData]);
        break;
      case 'bulkMoveTo':
        this.moveTo(event.rawData);
        break;
      default:
        break;
    }
  }

  openStockDetail(device: Device) {
    const deviceDetailDialog = this.dialog.open(DeviceDetailPopupComponent, {
      width: '900px',
      disableClose: false,
      data: { device }
    });

    deviceDetailDialog.componentInstance.selectSite.subscribe((site: Site) => {
      deviceDetailDialog.close();
    });
    deviceDetailDialog.componentInstance.selectDevice.subscribe((deviceSelected: Device) => {
      deviceDetailDialog.close();
      this.onOpenDeviceDetail(deviceSelected);
    });
  }

  onOpenDeviceDetail(device: Device) {
    this.navigationApi.selectDeviceAvecLeSite(device);
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, this.mvorigin);
  }

  moveTo(devices: Device[]) {
    const dialogRef = this.dialog.open(DeviceMoveToFormComponent, {
      width: '800px',
      disableClose: true,
      data: { devices: devices }
    });

    dialogRef.afterClosed().subscribe((devicesToUpdate: Device[]) => {
      if (devicesToUpdate) {
        this.navigationApi.moveDevices(devicesToUpdate, this.site);
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
