import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Asset } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-site-detail-assets',
  templateUrl: './site-detail-assets.component.html',
  styleUrls: ['./site-detail-assets.component.scss']
})
export class SiteDetailAssetsComponent implements OnInit {
  @Input() assets: Asset[] = [];
  @Input() totalAssets: number = 0;
  @Input() isAssetLoaded: boolean = false;

  @Output() selectAsset: EventEmitter<Asset> = new EventEmitter<Asset>();

  constructor() {}

  ngOnInit(): void {}
}
