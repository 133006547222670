import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';

import { ColorPickerComponent } from './color-picker.component';
import { ColorPickerService } from './color-picker.service';

@NgModule({
  imports: [CommonModule, MatGridListModule],
  providers: [ColorPickerService],
  declarations: [ColorPickerComponent],
  exports: [ColorPickerComponent]
})
export class ColorPickerModule {}
