import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

import { CustomCellParams } from '../custom-cell.params';

@Component({
  selector: 'grid-engine-color-cell',
  templateUrl: './color-cell.component.html',
  styleUrls: ['./color-cell.component.css']
})
export class ColorCellComponent implements ICellRendererAngularComp {
  params: CustomCellParams;

  agInit(params: CustomCellParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
