<nav mat-tab-nav-bar class='tab-nav-bar__list' [ngClass]='{ "centered": centered }' color='accent'>
 <ng-container *ngFor="let item of navLinks$ | async">
   <a mat-tab-link class='"tab-nav-bar__item'
      *ngIf='item?.visible'
      (click)='onTabLinkClick(item)'
      [routerLink]="item.link"
      routerLinkActive #rla="routerLinkActive"
      [active]="rla.isActive">
     {{ item.label | translate | uppercase }}
   </a>
 </ng-container>
</nav>
