import { Injectable } from '@angular/core';
import { ApiSelector, Filter, Pagination } from '@iot-platform/models/common';
import { Ward } from '@iot-platform/models/oyan';
import * as fromMain from '../reducers';
import * as fromSiteWards from '../reducers/site-wards.reducer';

@Injectable({
  providedIn: 'root'
})
export class SiteWardsSelectors extends ApiSelector<fromMain.State, Ward, Pagination, Filter> {
  constructor() {
    super(fromMain.featureKey, fromSiteWards.featureKey, fromSiteWards.adapter);
  }
}
