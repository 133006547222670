import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@iot-platform/iot-platform-ui';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import { Receiver, Ward } from '@iot-platform/models/oyan';
import { BehaviorSubject, Observable } from 'rxjs';
import { SortUtil } from '../../../../../../../shared/src/lib/utils/sort.util';
import { SitesFacade } from '../../state/facades/sites.facade';

@Component({
  selector: 'oyan-ui-link-receivers-form-dialog',
  templateUrl: './link-receivers-form-dialog.component.html',
  styleUrls: ['./link-receivers-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkReceiversFormDialogComponent extends BaseComponent implements OnInit {
  usersForm: UntypedFormGroup;
  unselectedReceivers$: BehaviorSubject<Receiver[]> = new BehaviorSubject<Receiver[]>([]);
  selectedReceivers$: BehaviorSubject<Receiver[]> = new BehaviorSubject<Receiver[]>([]);
  loader$: Observable<boolean> = this.sitesFacade.wardsLoading$;
  selectedReceiver: Receiver;

  constructor(private dialogRef: MatDialogRef<LinkReceiversFormDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private sitesFacade: SitesFacade) {
    super();
  }

  get isMainStock(): AbstractControl {
    return this.usersForm.get('isMainStock');
  }

  ngOnInit(): void {
    this.listenToReceiversChanges();
    this.initForm();
    this.initUnselectedReceivers(GetUtils.get(this.data, 'receivers', []));
    this.initSelectedReceivers(GetUtils.get(this.data, 'ward.receivers', []));
  }

  addReceiver(receiver: Receiver): void {
    this.selectedReceiver = receiver;
    this.sitesFacade.addReceiverToWard(receiver, GetUtils.get(this.data, 'ward', null), GetUtils.get(this.data, 'site'));
  }

  removeReceiver(receiver: Receiver): void {
    this.selectedReceiver = receiver;
    this.sitesFacade.removeReceiverFromWard(receiver, GetUtils.get(this.data, 'ward', null), GetUtils.get(this.data, 'site'));
  }

  exit(): void {
    this.dialogRef.close();
  }

  listenToReceiversChanges(): void {
    this.subscriptions.push(
      // This will be triggered every time we add or remove a receiver
      this.sitesFacade.currentWard$.subscribe((currentWard: Ward) => {
        if (this.selectedReceiver && currentWard) {
          // Set selected receivers
          this.initSelectedReceivers(currentWard.receivers);

          // Set unselected receivers
          let unselectedReceivers: Receiver[] = [];
          if (currentWard.receivers.find((r) => r.id === this.selectedReceiver.id)) {
            // When addReceiver is called
            // Selected receiver found in current ward
            // Remove selected receiver from unselected list
            unselectedReceivers = this.unselectedReceivers$.value.filter((r) => r.id !== this.selectedReceiver.id);
          } else {
            // When removeReceiver is called
            // Selected receiver not found in current ward
            // Add selected receiver to unselected list
            unselectedReceivers = [...this.unselectedReceivers$.value, this.selectedReceiver];
          }
          this.initUnselectedReceivers(unselectedReceivers);
          this.selectedReceiver = null;
        }
      })
    );
  }

  private initForm(): void {
    this.usersForm = new UntypedFormGroup({
      uf: new UntypedFormControl({ value: GetUtils.get(this.data, 'ward.UF', null), disabled: true }, []),
      name: new UntypedFormControl({ value: GetUtils.get(this.data, 'ward.name', null), disabled: true }, [Validators.required, Validators.maxLength(50)]),
      building: new UntypedFormControl({ value: GetUtils.get(this.data, 'ward.building', null), disabled: true }, []),
      floor: new UntypedFormControl({ value: GetUtils.get(this.data, 'ward.floor', null), disabled: true }, []),
      comment: new UntypedFormControl({ value: GetUtils.get(this.data, 'ward.comment', null), disabled: true }, []),
      isMainStock: new UntypedFormControl({ value: GetUtils.get(this.data, 'ward.primaryStock', false), disabled: true })
    });
  }

  private initSelectedReceivers(receivers: Receiver[]): void {
    this.selectedReceivers$.next(this.sortReceivers(receivers));
  }

  private initUnselectedReceivers(receivers: Receiver[]): void {
    this.unselectedReceivers$.next(this.sortReceivers(receivers));
  }

  private sortReceivers(receivers: Receiver[]): Receiver[] {
    return receivers.sort(SortUtil.sortByProperty('id'));
  }
}
