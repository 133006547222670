import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MasterViewEngineEvent, Pagination, PlatformResponse } from '@iot-platform/models/common';
import { Device, DeviceCallLog } from '@iot-platform/models/i4b';

import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import { DevicesFacade } from '../../+state/facades/devices.facade';
import { DateFormatPipe } from '../../../../../../../iot-platform-pipes/src/lib/date-format/date-format.pipe';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import {
  DeviceCallLogMessagePopupComponent
} from './device-call-log-message-popup/device-call-log-message-popup.component';
import { DeviceCallLogService } from './device-call-log.service';

@Component({
  selector: 'iot4bos-ui-device-call-log',
  templateUrl: './device-call-log.component.html',
  styleUrls: ['./device-call-log.component.scss']
})
export class DeviceCallLogComponent implements OnInit, OnDestroy {
  @Input() deviceName = '';

  mvSettings$: Observable<any> = this.devicesFacade.getCallLogsMVSettings$;
  maxPage!: number;
  limit = 100;
  callLogRaw: DeviceCallLog[] = [];
  currentCallLogResults: DeviceCallLog[] = [];
  callLogToDisplay$: Subject<PlatformResponse> = new BehaviorSubject<PlatformResponse>({
    data: [],
    total: 0,
    currentPage: 0,
    limit: 100,
    hasMore: false,
    maxPage: 1
  });
  initialFilters$: Subject<{
    statusList: Set<string>;
    minDate: Date;
    maxDate: Date;
  }> = new Subject<{
    statusList: Set<string>;
    minDate: Date;
    maxDate: Date;
  }>();

  deviceIdentifier: string;
  subscription: Subscription[] = [];

  constructor(
    public readonly dialog: MatDialog,
    private readonly deviceCallLogService: DeviceCallLogService,
    private readonly devicesFacade: DevicesFacade,
    private readonly navApi: NavigationApi,
    private readonly dateFormatPipe: DateFormatPipe
  ) {}

  ngOnInit() {
    this.devicesFacade.loadCallLogsMetadata();
    const navDeviceSub = this.navApi.selectedDevice$.subscribe((device: Device) => {
      if (device) {
        this.devicesFacade.loadDeviceCallLogById(device.id);
        this.deviceIdentifier = device.identifier;
      }
    });

    const callLogSubscription = this.devicesFacade.callLogs$.subscribe((callLogs: DeviceCallLog[]) => this.initCallLogComponents(callLogs));

    this.subscription.push(navDeviceSub, callLogSubscription);
  }

  initCallLogComponents(callLogs: DeviceCallLog[]): void {
    this.callLogRaw = callLogs;
    this.currentCallLogResults = this.callLogRaw.map((value) => ({ ...value, id: value.timestamp }));
    this.maxPage = Math.ceil(this.callLogRaw.length / this.limit) - 1;
    this.initialFilters$.next(this.deviceCallLogService.getInitialFilters(this.callLogRaw));
    this.callLogToDisplay$.next({
      data: this.currentCallLogResults.slice(0, this.limit),
      total: this.callLogRaw.length,
      currentPage: 0,
      limit: this.limit,
      hasMore: this.callLogRaw.length > this.limit,
      maxPage: this.maxPage
    });
  }

  filterCallLog(filters: { status: string; startDate: string; endDate: string }): void {
    const filtered: DeviceCallLog[] = this.deviceCallLogService.applyFilters(this.callLogRaw, {
      status: filters.status,
      startDate: new Date(filters.startDate),
      endDate: filters.endDate ? new Date(filters.endDate) : new Date()
    });
    this.maxPage = Math.ceil(filtered.length / this.limit) - 1;
    this.currentCallLogResults = filtered.map((value) => ({ ...value, id: value.timestamp }));
    this.callLogToDisplay$.next({
      data: this.currentCallLogResults.slice(0, this.limit),
      total: filtered.length,
      currentPage: 0,
      limit: this.limit,
      hasMore: filtered.length > this.limit,
      maxPage: this.maxPage
    });
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    switch (event.type) {
      case 'open':
        this.openCallLogMessage(event.rawData);
        break;
      case 'downloadMessage':
        this.downloadMessage(event.rawData);
        break;
      default:
        break;
    }
  }

  openCallLogMessage(callLog: DeviceCallLog): void {
    this.dialog.open(DeviceCallLogMessagePopupComponent, {
      width: '900px',
      disableClose: false,
      data: {
        direction: callLog.callDirection,
        deviceIdentifier: this.deviceIdentifier,
        timestamp: callLog.timestamp
      }
    });
  }

  downloadMessage(callLog: DeviceCallLog): void {
    const fileName = `${this.deviceName}_${this.dateFormatPipe.transform(callLog.timestamp, 'yyyy-MM-dd_HH-mm-ss')}.json`;
    this.deviceCallLogService.getCallLogMessage(callLog.callDirection, this.deviceIdentifier, callLog.timestamp).subscribe((data) => {
      const downloadURL = URL.createObjectURL(new Blob([JSON.stringify(data, null, 2)], { type: 'application/json; charset=utf-8' }));
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = fileName;
      link.click();
    });
  }

  onPageChange({ currentPage, limit }: Pagination) {
    this.callLogToDisplay$.next({
      data: this.currentCallLogResults.slice(currentPage * limit, (currentPage + 1) * limit),
      total: this.currentCallLogResults.length,
      hasMore: currentPage < this.maxPage,
      currentPage: currentPage,
      maxPage: this.maxPage,
      limit: this.limit
    });
  }

  ngOnDestroy(): void {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
}
