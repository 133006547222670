import { Component, Input, OnInit } from '@angular/core';
import { Device } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-device-details-connectors',
  templateUrl: './device-details-connectors.component.html',
  styleUrls: ['./device-details-connectors.component.scss']
})
export class DeviceDetailsConnectorsComponent implements OnInit {
  @Input() device: Device | null = null;

  constructor() {}

  ngOnInit(): void {}
}
