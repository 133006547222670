<mat-sidenav-container class="info-display-container">
  <mat-sidenav-content>
    <iot4bos-ui-breadcrumb
      [mvorigin]="mvorigin"
      [site]="navigationApi.site$ | async"
      [selectedAsset]="navigationApi.selectedAsset$ | async"
      [selectedDevice]="navigationApi.selectedDevice$ | async"
      [activeItemId]="activeItemId"
      [displayRefreshBtn]="displayRefreshBtn"
      (close)="onClose()"
      (returnToOrigin)="onReturnToOrigin()"
      (refreshSite)="refreshSite($event)"
      (refreshAsset)="refreshAsset($event)"
      (refreshDevice)="refreshDevice($event)"
      (toggleAssociationsPanel)="associations.open()"
    >
    </iot4bos-ui-breadcrumb>

    <router-outlet></router-outlet>
  </mat-sidenav-content>

  <mat-sidenav #associations mode="over" position="end">
    <div class="association-panel" fxLayout="column" fxLayoutAlign="start end">
      <button mat-icon-button class="regular-round-button close-button" (click)="associations.close()"><mat-icon>close</mat-icon></button>

      <div class="association-panel-component p-20">
        <iot4bos-ui-asset-device-associations
          [navigationMode]="true"
          [user]="currentUser$ | async"
          [associations]="siteFullAssociations$ | async"
          [associationsLoaded]="siteFullAssociationsLoaded$ | async"
          [selectedItem]="selectedItem$ | async"
          (dispatchAction)="associations.close(); onAssetDeviceAssociationAction($event)"
        ></iot4bos-ui-asset-device-associations>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
<iot-platform-notification></iot-platform-notification>
