import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HistoryLogsPopupComponent } from '@iot-platform/audit-trail';
import { TagCategory } from '@iot-platform/models/common';
import { CommandType, Device, DeviceVariable, Site } from '@iot-platform/models/i4b';
import { ManageTagsFormComponent } from 'libs/iot-platform-ui/src/lib/ui/components/manage-tags-form/manage-tags-form.component';
import { Observable, Subscription } from 'rxjs';

import { DevicesFacade } from '../../+state/facades/devices.facade';
import { PopupComponent } from '../../../../../../../iot-platform-ui/src/lib/ui/components/popup/popup.component';
import { VariableChartDialogComponent } from '../../../../../../../shared/src/lib/variable-chart/variable-chart-dialog/variable-chart-dialog.component';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { DeviceIccidComponent } from '../device-iccid/device-iccid.component';
import { DeviceInfoFormComponent } from '../device-info-form/device-info-form.component';
import { DeviceMoveToFormComponent } from '../device-move-to-form/device-move-to-form.component';

@Component({
  selector: 'iot4bos-ui-device-info',
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.scss']
})
export class DeviceInfoComponent implements OnInit, OnDestroy {
  @Input() canCreate = false;
  @Input() canDelete = false;
  @Input() canUpdate = false;
  @Input() canRead = false;
  @Input() canReadAuditTrail = false;
  @Input() site!: Site;

  @Output() configureDeviceConnector: EventEmitter<Device> = new EventEmitter<Device>();

  device!: Device;
  isDeviceVarLoaded = false;
  subscription: Subscription[] = [];

  tags: TagCategory[] = [];
  tagsLoaded = false;

  isResetDisabled$: Observable<boolean>;

  constructor(private readonly dialog: MatDialog, private readonly devicesFacade: DevicesFacade, private readonly navigationApi: NavigationApi) {}

  ngOnInit() {
    this.subscription.push(
      this.navigationApi.selectedDevice$.subscribe((device: Device) => (this.device = device)),
      this.navigationApi.deviceVariablesLoaded$.subscribe((value) => (this.isDeviceVarLoaded = value)),
      this.navigationApi.deviceTags$.subscribe((tags: TagCategory[]) => (this.tags = tags)),
      this.navigationApi.deviceTagsLoaded$.subscribe((value: boolean) => (this.tagsLoaded = value))
    );
  }

  openGraph(deviceVariable: DeviceVariable) {
    if (deviceVariable.lastValue) {
      this.dialog.open(VariableChartDialogComponent, {
        width: '990px',
        data: { variables: [deviceVariable], allVariables: [] }
      });
    }
  }

  editDevice() {
    const dialogRef = this.dialog.open(DeviceInfoFormComponent, {
      width: '990px',
      data: { device: this.device },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((device: Device) => {
      if (device) {
        this.devicesFacade.updateDevice(device);
      }
    });
  }

  moveDevice(device: Device): void {
    const dialogRef = this.dialog.open(DeviceMoveToFormComponent, {
      width: '800px',
      data: { devices: [device] },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((updatedDevices: Device[]) => {
      if (updatedDevices) {
        this.devicesFacade.moveDevices(updatedDevices, this.site);
      }
    });
  }

  resetDevice(deviceToReset: Device) {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      data: { type: 'reset', value: deviceToReset.name },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((validation) => {
      if (validation) {
        this.devicesFacade.resetDevice(deviceToReset);
      }
    });
  }

  manageTags() {
    const dialogRef = this.dialog.open(ManageTagsFormComponent, {
      width: '1230px',
      disableClose: true,
      data: {
        concepts: ['device'],
        selectedTags: this.tags,
        objectName: this.device.name,
        currentEntityId: this.device.entity.id,
        multiSelection: false,
        editable: false,
        withChildren: false,
        joinable: true,
        withParents: true
      }
    });

    dialogRef.afterClosed().subscribe((tags: TagCategory[]) => {
      if (tags) {
        this.devicesFacade.updateTagsByDeviceId(this.device.id, tags);
      }
    });
  }

  configureDevice(device: Device) {
    this.configureDeviceConnector.emit(device);
  }

  onCommandExec(device: Device, command: CommandType) {
    this.devicesFacade.sendConnectorCommand(device, { command: CommandType[command] });
  }

  decommissionDevice(): void {
    if (this.canDelete) {
      const dialogRef = this.dialog.open(PopupComponent, {
        width: '500px',
        data: { type: 'confirm', value: 'DEVICES.CARD.DECOMMISSION_CONFIRMATION_MESSAGE' },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe((validation) => {
        if (validation) {
          const deviceToUpdate: Device = { ...this.device, status: { ...this.device.status, name: 'decommissioned' } };
          this.devicesFacade.updateDevice(deviceToUpdate);
        }
      });
    }
  }

  deleteDevice(device: Device) {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      data: { type: 'delete', value: device.name },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((validation) => {
      if (validation) {
        this.devicesFacade.deleteDevice(device);
      }
    });
  }

  openChangeLog(): void {
    this.dialog.open(HistoryLogsPopupComponent, {
      data: { concept: 'device', elementId: this.device.id, elementName: this.device.name },
      disableClose: true,
      maxWidth: '1300px',
      minWidth: '1300px'
    });
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  openIccid(): void {
    this.dialog.open(DeviceIccidComponent, {
      data: { deviceId: this.device.id },
      disableClose: false,
      width: '900px'
    });
  }
}
