import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MasterViewEngineEvent, Pagination } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';

@Component({
  selector: 'iot4bos-backoffice-ui-grid-manager',
  templateUrl: './grid-manager-backoffice.component.html',
  styleUrls: ['./grid-manager-backoffice.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class GridManagerBackofficeComponent implements OnInit {
  @Input() grids: Array<I4BGrid<I4BGridOptions, I4BGridData>> = [];
  @Input() userPermissions: Array<{ key: string; value: boolean }> = [];
  @Input() masterViewList: string[] = [];

  @Output() addGrid: EventEmitter<string> = new EventEmitter();
  @Output() updateGrid: EventEmitter<I4BGrid<I4BGridOptions, I4BGridData>> = new EventEmitter();
  @Output() deleteGrid: EventEmitter<I4BGrid<I4BGridOptions, I4BGridData>> = new EventEmitter();
  @Output() changeGridPage: EventEmitter<Pagination> = new EventEmitter();

  canUpdateBusinessProfile = false;

  constructor() {}

  ngOnInit(): void {
    this.canUpdateBusinessProfile = this.userPermissions.find((p) => p.key === 'canUpdateBusinessProfile')?.value ?? false;
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    switch (event.type) {
      case 'edit':
        this.updateGrid.emit(event.rawData);
        break;
      case 'delete':
        this.deleteGrid.emit(event.rawData);
        break;
      default:
        break;
    }
  }
}
