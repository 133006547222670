import { NgModule } from '@angular/core';

import { LayoutModule } from './layout/layout.module';
import { UiModule } from './ui/ui.module';

@NgModule({
  imports: [UiModule, LayoutModule],
  exports: [UiModule, LayoutModule],
  declarations: []
})
export class IotPlatformUiModule {}
