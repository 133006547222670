import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BusinessProfile, FavoriteView, User } from '@iot-platform/models/common';
import * as moment from 'moment';

// tslint:disable-next-line:max-line-length
import {
  DialogBusinessProfileAddFormComponent
} from '../../../../components/dialogs/dialog-business-profile-add-form/dialog-business-profile-add-form.component';

@Component({
  selector: 'iot4bos-backoffice-ui-business-profiles-detail',
  templateUrl: './business-profiles-detail.component.html',
  styleUrls: ['./business-profiles-detail.component.scss', '../../../../style/admin.style.scss']
})
export class BusinessProfilesDetailComponent implements OnInit, OnChanges {
  @Input() businessProfile: BusinessProfile;
  @Input() users: User[];
  @Input() roles: any[];
  @Input() favoriteViews: FavoriteView[] = [];

  @Input() canCreateBusinessProfile: boolean;
  @Input() canUpdateBusinessProfile: boolean;

  @Input() rolesPendingStatus = false;
  @Input() membersPendingStatus = false;

  @Output() saveProfile: EventEmitter<{ businessProfileId: string; updatedProfile: BusinessProfile }> = new EventEmitter(null);
  @Output() addMemberToProfile: EventEmitter<true> = new EventEmitter<true>();
  @Output() addRoleToProfile: EventEmitter<true> = new EventEmitter<true>();

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    /*this.businessProfileFrom = new FormGroup({
      timezone: new FormControl(this.businessProfile ? { value: this.businessProfile.timezone, disabled: true } : { value: '', disabled: true }),
      chartPeriod: new FormControl(this.businessProfile ? { value: this.businessProfile.chartPeriod, disabled: true } : { value: null, disabled: true })
    });

    if (this.businessProfile && this.canUpdateBusinessProfile) {
      this.businessProfileFrom.controls.timezone.enable();
      this.businessProfileFrom.controls.chartPeriod.enable();
    }*/
  }

  ngOnChanges(changes: SimpleChanges): void {
    /*if (changes.hasOwnProperty('businessProfile')) {
      if (this.businessProfile && this.businessProfileFrom) {
        this.businessProfileFrom.setValue({ timezone: this.businessProfile.timezoneDetails, chartPeriod: this.businessProfile.chartPeriod });

        if (this.canUpdateBusinessProfile) {
          this.businessProfileFrom.controls.timezone.enable();
          this.businessProfileFrom.controls.chartPeriod.enable();
        }
      }
    }*/
  }

  onManageProfileMembers() {
    this.addMemberToProfile.emit(true);
  }

  onManageProfileRoles() {
    this.addRoleToProfile.emit(true);
  }

  editBusinessProfileName(businessProfile: BusinessProfile) {
    this.dialog
      .open(DialogBusinessProfileAddFormComponent, {
        width: '550px',
        disableClose: true,
        data: { businessProfile: businessProfile }
      })
      .afterClosed()
      .subscribe((updated) => {
        if (updated) {
          this.saveBPName(updated);
        }
      });
  }

  saveBPName(updated: BusinessProfile) {
    this.saveProfile.emit({ businessProfileId: this.businessProfile.id, updatedProfile: updated });
  }

  getTimezoneName(): string {
    if (this.businessProfile.timezoneDetails) {
      return `${this.businessProfile.timezoneDetails.name} ${moment().tz(this.businessProfile.timezoneDetails.name).format('Z')}`;
    } else {
      return 'PLEASE UPDATE THE TIMEZONE';
    }
  }
}
