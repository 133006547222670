import { Component, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'i4b-table-engine-number-cell',
  templateUrl: './number-cell.component.html',
  styleUrls: ['./number-cell.component.scss']
})
export class NumberCellComponent {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() unit: string;
  @Input() isLink = false;

  @Output() dispatchEvent;

  constructor() {}
}
