import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'iot-platform-ui-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() public name = '';
  @Input() public color = '';
  @Input() public isFromRegistery = false;

  public assetIcon: SafeHtml;

  constructor(private matIconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    try {
      this.matIconRegistry
        .getNamedSvgIcon(this.name)
        .pipe(
          catchError((err) => {
            this.isFromRegistery = false;
            return of(null);
          })
        )
        .subscribe((result) => {
          if (result) {
            this.isFromRegistery = true;
            this.assetIcon = this.sanitizer.bypassSecurityTrustHtml(result.outerHTML);
          }
        });
    } catch (error) {}
  }
}
