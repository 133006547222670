<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar style="display: flex; align-items: center; justify-content: space-between" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">GRAPHS FOR "{{ data.followedVariable.name }}"</h1>
      <div>
        <button mat-icon-button class="regular-round-button" cdkFocusInitial (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
      </div>
    </mat-toolbar>
  </mat-card-title>
</mat-card>
<mat-dialog-content>
  <iot-platform-ui-card-loader fxFlex [backgroundClass]="'h-140'" *ngIf="loading"></iot-platform-ui-card-loader>
  <iot4bos-ui-widgets-container *ngIf="!loading" [widgets]="widgets" (dispatchEvent)="onDispatchEvent($event)"></iot4bos-ui-widgets-container>
</mat-dialog-content>
