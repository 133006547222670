import { Component, Input, OnInit } from '@angular/core';
import { TagCategory } from '@iot-platform/models/common';

@Component({
  selector: 'iot-platform-ui-site-detail-tags',
  templateUrl: './site-detail-tags.component.html',
  styleUrls: ['./site-detail-tags.component.scss']
})
export class SiteDetailTagsComponent implements OnInit {
  @Input() tags: TagCategory[] = [];
  @Input() displayLoader = false;

  constructor() {}

  ngOnInit(): void {}
}
