<mat-card class='dialog-card link-business-profile'>
  <mat-card-title class='dialog-card-header'>
    <mat-toolbar fxLayout='row' fxLayoutAlign='space-between center' class='dialog-toolbar' color='accent'>
      <h1 class='dialog-card-title'>{{ 'ADMIN.DIALOG.LINK_BUSINESS_PROFILES_TO_USER.TITLE' | translate: param }}</h1>
      <button mat-icon-button class='regular-round-button' (click)='exit()'>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content fxLayout='row' fxLayoutGap='20px' class='dialog-card-content p-20'>
    <section fxLayout='column' fxFlex class=''>
      <mat-toolbar fxLayout='row' color='primary' fxLayoutGap='10px' fxLayoutAlign='start center'>
        <p>{{ unselectedBusinessProfiles.length }} {{ 'ADMIN.DIALOG.LINK_BUSINESS_PROFILES_TO_USER.AVAILABLE_BUSINESS_PROFILES' | translate | uppercase }}</p>
        <mat-progress-spinner color='accent' *ngIf='(loader$ | async) && !isDeleteActionClicked' mode='indeterminate'
                              [diameter]='20'></mat-progress-spinner>
      </mat-toolbar>

      <mat-form-field class='filter-input' color='accent'>
        <mat-label>{{ 'ADMIN.DIALOG.LINK_BUSINESS_PROFILES_TO_USER.FILTER_BUSINESS_PROFILES' | translate }}</mat-label>
        <mat-icon matPrefix class='search-icon'>search</mat-icon>
        <input matInput type='text' (keyup)='filterBusinessProfiles($event, unselectedBusinessProfiles, false)' />
      </mat-form-field>

      <mat-list fxFlex class='height-300 two-line-list'>
        <mat-list-item *ngFor='let businessProfile of filteredUnselectedBusinessProfiles$ | async' class='hoverable'>
          <mat-icon matListIcon>work_outline</mat-icon>
          <h3 matLine>{{ businessProfile?.name }}</h3>
          <button *ngIf='canCreateBusinessProfile' mat-icon-button class='regular-round-button'
                  (click)='isDeleteActionClicked = false; addBusinessProfile(businessProfile)'>
            <mat-icon>add</mat-icon>
          </button>
          <p matLine class='complementary-line'>
            <span> {{ 'ADMIN.DIALOG.LINK_BUSINESS_PROFILES_TO_USER.ENTITY' | translate }} : </span>
            <span>{{ businessProfile?.entityName }}</span>
          </p>
        </mat-list-item>
      </mat-list>
    </section>

    <section fxLayout='column' fxFlex class=''>
      <mat-toolbar fxLayout='row' color='primary' fxLayoutGap='10px' fxLayoutAlign='start center'>
        <p>{{ currentBusinessProfiles.length }} {{ 'ADMIN.DIALOG.LINK_BUSINESS_PROFILES_TO_USER.SELECTED_BUSINESS_PROFILES' | translate | uppercase }}</p>
        <mat-progress-spinner color='accent' *ngIf='(loader$ | async) && isDeleteActionClicked' mode='indeterminate'
                              [diameter]='20'></mat-progress-spinner>
      </mat-toolbar>

      <mat-form-field class='filter-input' color='accent'>
        <mat-label>{{ 'ADMIN.DIALOG.LINK_BUSINESS_PROFILES_TO_USER.FILTER_BUSINESS_PROFILES' | translate }}</mat-label>
        <mat-icon matPrefix class='search-icon'>search</mat-icon>
        <input matInput type='text' (keyup)='filterBusinessProfiles($event, currentBusinessProfiles, true)' />
      </mat-form-field>

      <mat-list fxFlex class='height-300 two-line-list'>
        <mat-list-item *ngFor='let businessProfile of filteredCurrentBusinessProfiles$ | async' class='hoverable'>
          <mat-icon matListIcon>work</mat-icon>
          <h3 matLine>{{ businessProfile?.name }}</h3>
          <button *ngIf='canDeleteBusinessProfile' mat-icon-button class='regular-round-button'
                  (click)='isDeleteActionClicked = true; removeBusinessProfile(businessProfile)'>
            <mat-icon>delete</mat-icon>
          </button>
          <p matLine class='complementary-line'>
            <span>{{ 'ADMIN.DIALOG.LINK_BUSINESS_PROFILES_TO_USER.ENTITY' | translate }} : </span>
            <span>{{ businessProfile?.entityName }}</span>
          </p>
        </mat-list-item>
      </mat-list>
    </section>
  </mat-card-content>

  <mat-card-actions class='dialog-card-actions' fxLayout='row' fxLayoutAlign='end center'>
    <button mat-raised-button color='accent' class='button-regular'
            (click)='exit()'>{{ 'ADMIN.DIALOG.LINK_BUSINESS_PROFILES_TO_USER.DONE' | translate }}</button>
  </mat-card-actions>
</mat-card>
