import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthorizationService, fromAuth } from '@iot-platform/auth';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import {
  ADD_BUTTON_CONFIG,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  REFRESH_BUTTON_CONFIG,
  TOGGLE_FILTER_ENGINE_BUTTON_CONFIG
} from '@iot-platform/iot-platform-ui';
import {
  BusinessProfile,
  FavoriteView,
  Filter,
  IotToolbarEvent,
  MasterViewEngineEvent,
  Pagination,
  PlatformRequest,
  PlatformResponse,
  ToolbarSize,
  User
} from '@iot-platform/models/common';
import { PoEventRule } from '@iot-platform/models/i4b';
import { fromUserPreferences } from '@iot-platform/users';
import { select, Store } from '@ngrx/store';
import { UserPreferencesService } from 'libs/users/src/lib/features/preferences/services/user-preferences.service';
import { PreferencesActions } from 'libs/users/src/lib/features/preferences/state/actions';
import { get } from 'lodash';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';

import { POEventRulesUiActions } from '../../+state/actions';
import * as fromPOEvent from '../../+state/reducers';
import { AuthorizationConcept, AuthorizationType } from '../../../../../../../auth/src/lib/authorization.types';
import { PopupComponent } from '../../../../../../../iot-platform-ui/src/lib/ui/components/popup/popup.component';
import {
  PoEventConfigureFormComponent
} from '../../components/po-event-configure-form/po-event-configure-form.component';
import { PoEventCreateFormComponent } from '../../components/po-event-create-form/po-event-create-form.component';
import { PoEventDetailPopupComponent } from '../../components/po-event-detail-popup/po-event-detail-popup.component';

@Component({
  selector: 'iot4bos-ui-events-shell',
  templateUrl: './po-events-shell.component.html',
  styleUrls: ['./po-events-shell.component.scss']
})
export class PoEventsShellComponent implements OnInit, OnDestroy {
  masterViewPoEventsButtonList!: IotToolbarDefaultButton[];

  canCreatePoEventRule = true;
  canUpdatePoEventRule = true;
  canDeletePoEventRule = true;

  poEventRules$: Observable<PlatformResponse>;
  poEventRulesLoaded$!: Observable<boolean>;
  totalRules$!: Observable<number>;
  businessProfile$!: Observable<BusinessProfile | null | undefined>;
  mvSettings$: Observable<{}>;
  pagination$!: Observable<Pagination>;

  filterEngineOpened = false;
  currentFilters$!: Observable<Filter[]>;
  currentFilters: Filter[] = [];
  currentFavoriteView$: Observable<FavoriteView | null> = of(null);
  pageSize = 1000;
  currentPage: number;

  user!: User;
  toolbarSize: string = ToolbarSize.SMALL;

  userPermissions: any;
  subs: Subscription[] = [];

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly authz: AuthorizationService,
    private readonly userPrefService: UserPreferencesService,
    private readonly storage: LocalStorageService
  ) {
    this.canCreatePoEventRule = this.authz.applyAuthorization(AuthorizationConcept.PO_EVENT_RULE, AuthorizationType.CREATE);
    this.canUpdatePoEventRule = this.authz.applyAuthorization(AuthorizationConcept.PO_EVENT_RULE, AuthorizationType.UPDATE);
    this.canDeletePoEventRule = this.authz.applyAuthorization(AuthorizationConcept.PO_EVENT_RULE, AuthorizationType.DELETE);

    this.userPermissions = [
      { key: 'canUpdatePoEventRule', value: this.canUpdatePoEventRule },
      { key: 'canDeletePoEventRule', value: this.canDeletePoEventRule }
    ];

    this.initToolbarButtonList();
  }

  ngOnInit() {
    this.poEventRulesLoaded$ = this.store.pipe(select(fromPOEvent.getPoEventRulesLoaded));
    this.totalRules$ = this.store.pipe(select(fromPOEvent.getTotalPoEventRules));
    this.currentFilters$ = this.store.pipe(select(fromPOEvent.getCurrentFilters));
    this.pagination$ = this.store.select(fromPOEvent.getPagination);
    this.businessProfile$ = this.store.pipe(select(fromAuth.selectSelectedBusinessProfileForAccount));
    this.mvSettings$ = this.businessProfile$.pipe(
      switchMap((businessProfile) => {
        if (businessProfile) {
          return this.userPrefService.loadSpecificDefaultSettingsWithDefaultColumnsOnly('poEventRules');
        } else {
          return of(null);
        }
      })
    );
    this.poEventRules$ = this.store.pipe(select(fromPOEvent.getFormattedData));

    this.subs.push(
      this.configurePopupData(),
      this.reloadRulesAfterChange(),
      this.poEventRulesLoaded$.subscribe((dataLoaded: boolean) => {
        this.masterViewPoEventsButtonList.map((button) => button.icon !== 'filter_list' ?? (button.disabled = !dataLoaded));
      }),
      this.store.pipe(select(fromUserPreferences.getCurrentUser)).subscribe((user) => {
        if (user && user.id) {
          this.user = user;
          this.filterEngineOpened = get(user, 'preferences.filterEngineOpenByDefault', false);
        }
      })
    );
  }

  initToolbarButtonList(): void {
    this.masterViewPoEventsButtonList = [
      new IotToolbarDefaultButton({ ...ADD_BUTTON_CONFIG, displayButton: this.canCreatePoEventRule }, 0),
      new IotToolbarDefaultButton(TOGGLE_FILTER_ENGINE_BUTTON_CONFIG, 1),
      new IotToolbarDefaultButton(REFRESH_BUTTON_CONFIG, 2)
    ];
  }

  reloadRulesAfterChange(): Subscription {
    return combineLatest([this.currentFilters$, this.businessProfile$])
      .pipe(withLatestFrom(this.pagination$))
      .subscribe(([[filters, businessProfile], pagination]) => {
        if (businessProfile) {
          this.pageSize = pagination.limit;
          this.currentPage = 0;
          this.currentFilters = filters ?? [];
          this.loadRules({ ...pagination, filters: this.currentFilters, page: 0, limit: this.pageSize });
        }

        this.masterViewPoEventsButtonList.map((button) => {
          if (button.dispatchAction.type === IotToolbarDispatchActionType.CLEAR_FILTERS_AND_RELOAD_DATA) {
            button.disabled = !this.currentFilters.length;
          }
          return button;
        });
      });
  }

  configurePopupData(): Subscription {
    return this.store
      .pipe(
        select(fromPOEvent.getConfigurePopupData),
        switchMap((response: { canOpen: boolean; rule: any }) => {
          if (response.canOpen) {
            return this.dialog
              .open(PoEventConfigureFormComponent, {
                width: '900px',
                disableClose: true,
                data: { element: { ...response.rule } }
              })
              .afterClosed();
          } else {
            return of(null);
          }
        })
      )
      .subscribe((value) => {
        if (value) {
          this.store.dispatch(POEventRulesUiActions.configurePOEventRule({ poEventRuleToConfigure: value }));
        } else {
          this.store.dispatch(POEventRulesUiActions.cancelAddPOEventRuleThenConfigure());
        }
      });
  }

  onToolbarEvent(event: IotToolbarEvent) {
    switch (event.type) {
      case IotToolbarDispatchActionType.REFRESH_PAGE:
        this.loadRules();
        break;
      case IotToolbarDispatchActionType.ADD_ELEMENT:
        this.addRule();
        break;
      case IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE:
        this.onShowFilter();
        break;
      default:
        break;
    }
  }

  onEventDispatched(event: MasterViewEngineEvent) {
    switch (event.type) {
      case 'open':
        this.openRule(event.rawData);
        break;
      case 'edit':
        this.editRule(event.rawData);
        break;
      case 'delete':
        this.deleteRule(event.rawData);
        break;
      case 'navigate':
      case 'configure':
        this.navigateToRule(event.rawData);
        break;
      default:
        break;
    }
  }

  navigateToRule(rule: PoEventRule): void {
    this.store.dispatch(POEventRulesUiActions.navigateToPOEventRuleDetails({ rule }));

    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, 'po-event-rule');
    this.storage.set(LocalStorageKeys.STORAGE_SELECTED_ROW_ID, rule.id as unknown as string);
  }

  onShowFilter() {
    if (this.user) {
      this.filterEngineOpened = !this.filterEngineOpened;
      this.user.preferences = { ...this.user.preferences, filterEngineOpenByDefault: this.filterEngineOpened };
      this.store.dispatch(PreferencesActions.saveUserPreferences({ user: this.user, preferences: this.user.preferences }));
    }
  }

  loadRules(request: PlatformRequest = { page: 0, filters: this.currentFilters, limit: this.pageSize }) {
    this.store.dispatch(POEventRulesUiActions.listPOEventRules({ request }));
  }

  openRule(rule: PoEventRule) {
    const eventRuleDialog: MatDialogRef<PoEventDetailPopupComponent> = this.dialog.open(PoEventDetailPopupComponent, {
      width: '700px',
      data: { selected: rule }
    });

    eventRuleDialog.componentInstance.ruleNameClicked.subscribe((rule: PoEventRule) => {
      eventRuleDialog.close();
      this.navigateToRule(rule);
    });
  }

  editRule(rule: PoEventRule) {
    this.dialog
      .open(PoEventCreateFormComponent, {
        width: '700px',
        data: { element: rule }
      })
      .afterClosed()
      .subscribe((value) => {
        if (value) {
          this.store.dispatch(POEventRulesUiActions.updatePOEventRule({ poEventRuleToUpdate: value.rule }));
        }
      });
  }

  deleteRule(rule: PoEventRule) {
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        disableClose: true,
        data: { type: 'delete', value: rule.name }
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.store.dispatch(POEventRulesUiActions.deletePOEventRule({ poEventRuleToDelete: rule }));
        }
      });
  }

  addRule() {
    this.dialog
      .open(PoEventCreateFormComponent, {
        width: '600px',
        disableClose: true
      })
      .afterClosed()
      .subscribe((value) => {
        if (value && value.action === 'ADD') {
          this.store.dispatch(POEventRulesUiActions.addPOEventRule({ poEventRuleToAdd: value.rule }));
        }
        if (value && value.action === 'CONFIGURE') {
          this.store.dispatch(POEventRulesUiActions.addPOEventRuleThenConfigure({ poEventRuleToAdd: value.rule }));
        }
      });
  }

  applyFilters(filters: Filter[]) {
    this.currentFilters = filters;
    this.store.dispatch(POEventRulesUiActions.savePOEventRuleFilters({ filters }));
  }

  onPageChange(pagination: Pagination) {
    const request: PlatformRequest = {
      page: pagination.currentPage,
      limit: pagination.limit,
      filters: this.currentFilters
    };

    this.loadRules(request);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
