import { CommonApiRequest, CommonApiResponse, CommonIndexedPagination } from '@iot-platform/models/common';
import { Gateway, Site } from '@iot-platform/models/oyan';
import { createAction, props } from '@ngrx/store';

export const loadGatewaysBySite = createAction('[OYAN SITE/GATEWAYS] Load gateways by site', props<{ request: CommonApiRequest }>());
export const loadGatewaysBySiteSuccess = createAction(
  '[OYAN SITE/GATEWAYS] Load Gateways By Site Success',
  props<{ response: CommonApiResponse<Gateway, CommonIndexedPagination> }>()
);
export const loadGatewaysBySiteFailure = createAction('[OYAN SITE/GATEWAYS] Load Gateways By Site Failure', props<{ error: unknown }>());

export const addGatewayToSite = createAction('[OYAN SITE/GATEWAYS] Add gateway to site', props<{ gateway: Gateway; site: Site }>());
export const addGatewayToSiteSuccess = createAction('[OYAN SITE/GATEWAYS] Add Gateway to site Success', props<{ gateway: Gateway; site: Site }>());
export const addGatewayToSiteFailure = createAction('[OYAN SITE/GATEWAYS] Add Gateway to site Failure', props<{ error: unknown }>());

export const removeGatewayFromSite = createAction('[OYAN SITE/GATEWAYS] Remove gateway from site', props<{ gateway: Gateway; site: Site }>());
export const removeGatewayFromSiteSuccess = createAction('[OYAN SITE/GATEWAYS] Remove Gateway from site Success', props<{ gateway: Gateway; site: Site }>());
export const removeGatewayFromSiteFailure = createAction('[OYAN SITE/GATEWAYS] Remove Gateway from site Failure', props<{ error: unknown }>());

export const synchronizeGatewaysBySite = createAction('[OYAN SITE/GATEWAYS] Synchronize Gateways By Site', props<{ site: Site }>());
export const synchronizeGatewaysBySiteSuccess = createAction('[OYAN SITE/GATEWAYS] Synchronize Gateways By Site Success', props<{ site: Site }>());
export const synchronizeGatewaysBySiteFailure = createAction('[OYAN SITE/GATEWAYS] Synchronize Gateways By Site Failure', props<{ error: unknown }>());
