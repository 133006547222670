import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { LoaderModule } from './loader/loader.module';
import { DISABLE_ERROR_MESSAGES_AFTER_LOGOUT, NotificationComponent } from './notification.component';
import { NotificationService } from './notification.service';

@NgModule({
  declarations: [NotificationComponent],
  imports: [CommonModule, LoaderModule, MatSnackBarModule, MatDialogModule],
  exports: [NotificationComponent],
  providers: [NotificationService]
})
export class NotificationModule {
  static forRoot(config: { disableErrorMessagesAfterLogout: boolean }): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [NotificationService, { provide: DISABLE_ERROR_MESSAGES_AFTER_LOGOUT, useValue: config.disableErrorMessagesAfterLogout }]
    };
  }
}
