import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService, fromAuth } from '@iot-platform/auth';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { BaseComponent } from '@iot-platform/iot-platform-ui';
import { HashUtils } from '@iot-platform/iot-platform-utils';
import {
  AppName,
  BusinessProfile,
  Environment,
  FavoriteView,
  User,
  UserPreferences
} from '@iot-platform/models/common';
import { SidenavMenuBlock, SidenavMenuItem } from '@iot-platform/models/i4b';
import { CachedUsersService } from '@iot-platform/shared';
import { fromUserPreferences } from '@iot-platform/users';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { AuthorizationService } from '../../../../../auth/src/lib/services/authorization.service';
import {
  AuthApiActions,
  AuthBusinessProfilesPageActions,
  AuthPageActions
} from '../../../../../auth/src/lib/state/actions';
import { GridsDbActions } from '../../../../../grid-engine/src/lib/components/state/actions';
import { selectGridAndLoadData } from '../../../../../grid-engine/src/lib/components/state/actions/grids-db.actions';
import {
  ReleaseNotesComponent
} from '../../../../../iot-platform-ui/src/lib/ui/components/release-notes/release-notes.component';
import { FavoriteViewsActions } from '../../../../../shared/src/lib/favorite-views/+state/actions';
import * as fromFavoriteViews from '../../../../../shared/src/lib/favorite-views/+state/reducers';
import { SortUtil } from '../../../../../shared/src/lib/utils/sort.util';
import {
  UserPreferencesService
} from '../../../../../users/src/lib/features/preferences/services/user-preferences.service';
import { NavigationTreeService } from '../../components/sidenav/services/navigation-tree.service';

@Component({
  selector: 'iot4bos-ui-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss']
})
export class AppShellComponent extends BaseComponent implements OnInit, OnDestroy {
  businessProfiles$: Observable<BusinessProfile[]>;
  businessProfile$: Observable<BusinessProfile>;
  currentUser$: Observable<User>;
  currentUserLoading$: Observable<boolean>;
  privileges$: Observable<any>;
  navTree: SidenavMenuBlock[] = [];
  sidenavState = {
    isOpen: this.storage.get(LocalStorageKeys.STORAGE_SIDEBAR_OPENED_KEY) ? this.storage.get(LocalStorageKeys.STORAGE_SIDEBAR_OPENED_KEY) === 'true' : true
  };
  destroyed$: Subject<boolean> = new Subject();
  enableBusinessProfileSwitching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  appTheme = 'default-theme';

  constructor(
    @Inject('environment') protected environment: Environment,
    protected authService: AuthService,
    protected translateService: TranslateService,
    protected router: Router,
    protected navigationTreeService: NavigationTreeService,
    protected store: Store,
    protected authorizationService: AuthorizationService,
    protected userPreferencesService: UserPreferencesService,
    protected dialog: MatDialog,
    protected storage: LocalStorageService,
    protected cachedUsersService: CachedUsersService
  ) {
    super();
    this.currentUser$ = this.store.select(fromAuth.selectCurrentUser);
    this.currentUserLoading$ = this.store.select(fromAuth.selectAccountLoading);
    this.businessProfiles$ = this.store.select(fromAuth.selectBusinessProfilesForAccount);
    this.businessProfile$ = this.store.select(fromAuth.selectSelectedBusinessProfileForAccount);
    this.privileges$ = this.store.select(fromAuth.selectPrivileges);
  }

  trackById(index: number, item: SidenavMenuBlock) {
    return item.id;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      combineLatest([this.businessProfile$, this.store.select(fromAuth.selectSelectedBusinessProfileForAccount)])
        .pipe(
          map(([oldBp, newBp]) => (oldBp && newBp && oldBp.id !== newBp.id) as boolean),
          filter((changed: boolean) => !!changed)
        )
        .subscribe(() => {
          this.store.dispatch(GridsDbActions.resetState());
        }),
      this.businessProfile$.pipe(filter((bp: BusinessProfile) => !!bp)).subscribe(() => {
        this.store.dispatch(GridsDbActions.loadGrids({}));
      }),
      this.store.select(fromUserPreferences.getPreferences).subscribe((preferences: UserPreferences) => {
        if (preferences) {
          this.appTheme = preferences.appTheme;
        }
      }),
      this.store.select(fromAuth.selectLoggedOut).subscribe((loggedOut) => {
        if (!loggedOut && this.authService.isLoggedInWithSSO()) {
          this.store.dispatch(AuthPageActions.retrieveSsoSession(this.authService.retrieveSsoTokens()));
        }
      }),
      this.currentUser$
        .pipe(
          tap((user) => {
            if (!!user) {
              this.cachedUsersService.loadUsers();
            }
          }),
          filter((currentUser) => !currentUser),
          withLatestFrom(this.store.select(fromAuth.selectLoggedOut), this.store.select(fromAuth.selectSignOutPending))
        )
        .subscribe(([_, loggedOut, logOutPending]) => {
          if (this.authService.isLoggedInWithSSO() || logOutPending) {
            return;
          }
          if (!loggedOut) {
            this.store.dispatch(AuthApiActions.retrieveSession());
          } else {
            this.storage.clear();
          }
        })
    );

    this.setNavigationTree();
  }

  logout(): void {
    this.store.dispatch(AuthPageActions.signOut());
  }

  returnHome(): void {
    this.router.navigate(['/', 'home']);
  }

  toggleSidenav(): void {
    this.sidenavState.isOpen = !this.sidenavState.isOpen;
    this.storage.set(LocalStorageKeys.STORAGE_SIDEBAR_OPENED_KEY, this.sidenavState.isOpen.toString());
  }

  closePreferences(): void {
    this.userPreferencesService.closePreferences();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onChangeBusinessProfile(selected: BusinessProfile, withRedirect = true): void {
    this.store.dispatch(
      AuthBusinessProfilesPageActions.selectBusinessProfile({
        selectedBusinessProfile: selected,
        withRedirect
      })
    );
  }

  openMyProfile(): void {
    this.router.navigate(['my-profile']);
  }

  refreshBusinessProfileList(): void {
    this.store.dispatch(AuthPageActions.loadAccount());
  }

  openReleaseNotes(): void {
    this.dialog.open(ReleaseNotesComponent, {
      width: '1200px',
      data: { appName: AppName.IOT4BOS }
    });
  }

  private setNavigationTree() {
    combineLatest([
      this.navigationTreeService.getNavigationTree(),
      this.store.select(fromAuth.selectPrivileges),
      combineLatest([
        this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewSites),
        this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewAssets),
        this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewDevices),
        this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewDeviceEvents),
        this.store.select(fromFavoriteViews.getFavoriteViewsForMasterViewAssetEvents)
      ])
    ])
      .pipe(
        map(([navTree, _, [fvSites, fvAssets, fvDevices, fvDeviceEvents, fvAssetEvents]]) => {
          return navTree.map((block) => {
            block.items.forEach((item) => {
              item.level = 0;
              switch (item.name) {
                case 'TAB_NAV.SITES':
                  if (fvSites.length) {
                    item.submenu = [
                      {
                        items: this.getSubmenuItems(fvSites, '/sites', [[{ name: 'SITE', right: 'READ' }]])
                      }
                    ];
                  } else {
                    item.submenu = [];
                  }
                  break;
                case 'TAB_NAV.ASSETS':
                  if (fvAssets.length) {
                    item.submenu = [
                      {
                        items: this.getSubmenuItems(fvAssets, '/assets', [[{ name: 'ASSET', right: 'READ' }]])
                      }
                    ];
                  } else {
                    item.submenu = [];
                  }
                  break;
                case 'TAB_NAV.DEVICES':
                  if (fvDevices.length) {
                    item.submenu = [
                      {
                        items: this.getSubmenuItems(fvDevices, '/devices', [[{ name: 'DEVICE', right: 'READ' }]])
                      }
                    ];
                  } else {
                    item.submenu = [];
                  }
                  break;
                case 'TAB_NAV.ASSET_EVENTS':
                  if (fvAssetEvents.length) {
                    item.submenu = [
                      {
                        items: this.getSubmenuItems(fvAssetEvents, '/asset-events', [[{ name: 'EVENT', right: 'READ' }], [{ name: 'ASSET', right: 'READ' }]])
                      }
                    ];
                  } else {
                    item.submenu = [];
                  }
                  break;
                case 'TAB_NAV.DEVICE_EVENTS':
                  if (fvDeviceEvents.length) {
                    item.submenu = [
                      {
                        items: this.getSubmenuItems(fvDeviceEvents, '/device-events', [[{ name: 'EVENT', right: 'READ' }], [{ name: 'DEVICE', right: 'READ' }]])
                      }
                    ];
                  } else {
                    item.submenu = [];
                  }
                  break;
              }
            });
            return { ...block };
          });
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((navTree) => {
        navTree = navTree.map((i) => ({ ...i, id: HashUtils.simpleHashFromObj(i) }));
        this.navTree = this.authorizationService.getNavigationTree(navTree);
      });
  }

  private handleClick(favoriteView: FavoriteView) {
    return () => {
      this.store.dispatch(FavoriteViewsActions.setCurrentFavoriteView({ masterView: favoriteView.masterView, favoriteView: favoriteView }));
      this.store.dispatch(
        selectGridAndLoadData({ gridId: favoriteView.gridId as string, masterview: favoriteView.masterView as string, filters: favoriteView.filters })
      );
    };
  }

  private getSubmenuItems(
    favoriteViews: FavoriteView[],
    path: string,
    concepts: {
      name: string;
      right: string;
    }[][]
  ): SidenavMenuItem[] {
    return favoriteViews
      .map((fv) => ({
        name: fv.name ?? '',
        icon: fv.shared ? 'business_profile' : 'person',
        rightIcons: fv.gridId ? ['link'] : [],
        concepts,
        path,
        handleClick: this.handleClick(fv).bind(this),
        level: 1,
        leftBorderColor: fv.color,
        disableActiveState: true
      }))
      .sort(SortUtil.sortByName);
  }
}
