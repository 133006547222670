<div fxFlex fxLayout="column">
  <div class="sticky">
    <iot-platform-ui-toolbar-v2
      (dispatchToolbarEvent)="onToolbarEvent($event)"
      [autoRefresh]="{
        counter: 100 - ((timerValue$ | async) / (deviceGridsConfiguration$ | async)?.currentGrid?.gridOptions?.autoRefresh?.delay) * 100,
        timeLeft: (deviceGridsConfiguration$ | async)?.currentGrid?.gridOptions?.autoRefresh?.delay - (timerValue$ | async),
        displaySpinner: (deviceGridsConfiguration$ | async)?.currentGrid?.gridOptions?.autoRefresh?.enabled
      }"
      [buttonList]="masterViewDevicesButtonList"
      [favoriteViewConfiguration]="deviceFavoriteViewsConfiguration$ | async"
      [gridsConfiguration]="deviceGridsConfiguration$ | async"
      [isDataLoaded]="devicesLoaded$ | async"
      [name]="'DEVICES.NAME_TOOLBAR' | translate"
      [pageTypeOptions]="{ visible: true, pageType: pageType$ | async }"
      [size]="toolbarSize"
      [total]="total$ | async"
    >
    </iot-platform-ui-toolbar-v2>

    <iot-platform-ui-filter-engine-container
      (applyFilters)="onApplyFilters($event)"
      [currentFavoriteView$]="currentFavoriteView$"
      [currentFilters$]="currentFilters$"
      [displayActionButtons]="true"
      [expanded]="filterEngineOpened"
      [masterView]="'devices'"
      [withFavoriteFilters]="true"
    >
    </iot-platform-ui-filter-engine-container>
  </div>

  <div [ngSwitch]="pageType$ | async" fxFlex fxLayout="column">
    <ng-container *ngSwitchCase="ToolbarPageType.GRID">
      <div class="layout-master-view-engine-container">
        <grid-engine-grid-manager
          (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
          (timerValueChanged)="_timerValue$.next($event)"
          [currentFilters]="currentFilters$ | async"
          [gridSort]="gridSort$ | async"
          [grid]="grid$ | async"
          [userPermissions]="userPermissions"
          [visibleNodeId]="selectedRowId"
          class="layout-ag-grid-shell"
          fxFlex
        ></grid-engine-grid-manager>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="ToolbarPageType.MAP">
      <iot-platform-maps-map
        (dispatchEvent)="onMapEvent($event)"
        (dispatchMapNavigationEvent)="onMapNavigationEvent($event)"
        *ngIf="currentBusinessProfile"
        [concept]="concept"
        [displayMode]="displayMode$ | async"
        [filters]="currentFilters$ | async"
        [zoom]="3"
        fxFlex
      ></iot-platform-maps-map>
    </ng-container>
  </div>
</div>
