import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { DateRangeComponent } from './date-range.component';

@NgModule({
  declarations: [DateRangeComponent],
  exports: [DateRangeComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule
  ]
})
export class DateRangeModule {
}
