<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'USERS.PROFILE_INFO_FORM.UI.POPUP_TITLE' | translate }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content p-20" fxLayout="row" fxLayoutGap="20px">
    <form [formGroup]="form" fxFlex fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
      <mat-form-field class="" fxFlex color="accent">
        <mat-select
          [placeholder]="'USERS.PROFILE_PREFERENCES.LANGUAGE' | translate"
          formControlName="appLanguage"
          ngDefaultControl
          matInput
          required
        >
          <mat-option *ngFor="let language of availableLanguages" [value]="language">
            {{ 'USERS.PROFILE_PREFERENCES.LANGUAGES.' + language | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex color="accent">
        <mat-select formControlName="addressCountry" [placeholder]="'IOT_DICTIONARY.COUNTRY' | translate">
          <mat-option [value]="country.key" *ngFor="let country of countries$ | async">{{ country.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="close()">{{ 'USERS.PROFILE_INFO_FORM.UI.BUTTONS.CANCEL' | translate }}</button>
    <button mat-raised-button color="accent" class="button-regular" [disabled]="!form.valid" (click)="save()">
      {{ 'USERS.PROFILE_INFO_FORM.UI.BUTTONS.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
