<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-title">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="" fxLayout="row" fxLayoutAlign="start center">
        {{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_ADMIN_SETTINGS.TITLE' | translate }} - {{ version$ | async }}
        <span style="margin-left: 20px"><mat-spinner mode="indeterminate" diameter="20" *ngIf="isLoading"></mat-spinner> </span>
      </h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content class="dialog-card-content" fxLayout="column">
    <form [formGroup]="mvSettingsForm" class="tp-popup" fxLayout="column">
      <mat-form-field appearance="fill" color="accent">
        <mat-label>{{ 'IOT_DICTIONARY.VIEW' | translate }}</mat-label>
        <mat-select (selectionChange)="initColumns($event)" [disabled]="changesMade">
          <mat-option *ngFor="let concept of concepts" [value]="concept">{{ concept }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div fxFlex fxLayout="column" fxLayoutAlign="space-between">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
          <div fxFlex="48">
            <p>{{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_ADMIN_SETTINGS.AVAILABLE_COLUMNS' | translate }}</p>
            <div
              cdkDropList
              #availableColumnsList="cdkDropList"
              [cdkDropListData]="availableColumns"
              [cdkDropListConnectedTo]="[selectedColumnsList]"
              class="dd-list"
              (cdkDropListDropped)="drop($event)"
            >
              <div class="dd-item" *ngFor="let column of availableColumns" cdkDrag>{{ column.catalogName }}</div>
            </div>
          </div>

          <div fxFlex="48">
            <p>{{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_ADMIN_SETTINGS.DEFAULT_COLUMNS' | translate }}</p>
            <div
              cdkDropList
              #selectedColumnsList="cdkDropList"
              [cdkDropListData]="selectedColumns"
              [cdkDropListConnectedTo]="[availableColumnsList]"
              class="dd-list"
              (cdkDropListDropped)="drop($event)"
            >
              <div class="dd-item" *ngFor="let column of selectedColumns; let index = index" cdkDrag>
                <p fxFlex>{{ column.catalogName }}</p>
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="clearAll()">
      {{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_ADMIN_SETTINGS.RESET_ALL_SETTINGS' | translate }}
    </button>
    <span fxFlex></span>
    <button mat-button class="button-regular" (click)="restoreDefaultSettings()" [disabled]="!selectedSettingName || isLoading || !changesMade">
      {{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_ADMIN_SETTINGS.RESET_MV_SETTINGS' | translate }}
    </button>
    <button mat-button class="button-regular" (click)="close()">{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button mat-raised-button class="button-regular" color="accent" (click)="save()" [disabled]="selectedColumns.length < 3 || isLoading || !changesMade">
      {{ 'IOT_DICTIONARY.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
