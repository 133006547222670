export const usersMetadataSettings = {
  metadata: {
    url: '/admin/users',
    params: {
      limit: '100',
      page: '0'
    }
  },
  masterViewToolbar: {},
  masterViewFilterEngine: {
    masterView: 'users',
    expanded: true
  },
  masterViewTable: {
    bluePrint: {
      autoRefresh: false,
      multipleSelection: false,
      enableConditions: [],
      visibleConditions: [],
      columns: [],
      expandedPanel: {},
      buttonColumn: {
        id: 'button',
        name: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.BUTTON',
        width: '5%',
        type: 'center center',
        enableConditions: [],
        visibleConditions: [
          [
            { key: 'canUpdateUserStatus', value: true },
            { key: 'canUpdateUser', value: true }
          ]
        ],
        singleActions: [
          {
            order: 1,
            key: 'enableUser',
            label: 'ADMIN.USERS.TABLE.ENABLE_USER',
            enableConditions: [[{ key: 'status', value: 'disabled' }]],
            visibleConditions: [[{ key: 'canUpdateUserStatus', value: true }]]
          },
          {
            order: 2,
            key: 'resetUser',
            label: 'ADMIN.USERS.TABLE.RESEND_CREDENTIALS',
            enableConditions: [[{ key: 'status', value: 'pending' }], [{ key: 'isShared', value: true }]],
            visibleConditions: [[{ key: 'canUpdateUser', value: true }]]
          },
          {
            order: 3,
            key: 'disableUser',
            label: 'ADMIN.USERS.TABLE.DISABLE_USER',
            enableConditions: [
              [
                { key: 'status', value: 'active' },
                { key: 'status', value: 'pending' }
              ]
            ],
            visibleConditions: [[{ key: 'canUpdateUserStatus', value: true }]]
          }
        ]
      }
    }
  },
  masterViewComments: {}
};
