import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { BusinessProfile, Organization } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import * as momentTimezones from 'moment-timezone';
import { Observable, of } from 'rxjs';

// https://github.com/BrianKopp/ngrx-cognito
// https://www.integralist.co.uk/posts/cognito/
// https://aws-amplify.github.io/docs/js/angular#subscribe-to-authentication-state-changes
// https://blog.angularindepth.com/top-10-ways-to-use-interceptors-in-angular-db450f8a62d6

@Injectable({
  providedIn: 'root'
})
export class AuthBusinessProfilesService {
  constructor(@Inject('environment') private environment, private httpClient: HttpClient, private store: Store, private storage: LocalStorageService) {}

  public loadAccount(): Observable<any> {
    // In account we have : User infos, preferences, business profiles, privileges
    return this.httpClient.get(`${this.environment.api.url}/account`);
  }

  public selectBusinessProfile(profile: BusinessProfile) {
    const zone = profile?.timezoneDetails?.name ? momentTimezones().tz(profile.timezoneDetails.name).format('Z') : null;
    const bp = { ...profile, timezoneDetails: { ...profile?.timezoneDetails, offset: zone } };
    this.storage.set(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY, JSON.stringify(bp));
    return of(profile);
  }

  private getBusinessProfileFromLocalStorage() {
    return JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY));
  }

  public getBusinessProfileId() {
    return this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)
      ? JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)).id
      : '';
  }

  public getSessionEntity(entityId: string): Observable<Organization> {
    return this.httpClient.get<Organization>(`${this.environment.api.url}/entities/${entityId}`);
  }
}
