import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

export class ErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request);
    // Dont kill me now, after bug bounty of the 21/06/2023
    // If I am here after 1t of july 2023, remove all this code
    if (
      (request.method === 'POST' || request.method === 'PUT' || request.method === 'PATCH' || request.method === 'DELETE') &&
      !request.url.includes('/oauth2/token')
    ) {
      const errorResponse = {
        error: { code: 400, message: 'une_erreur_custom', error: 'une_erreur_custom' },
        message: 'Une Erreur custom faite maison',
        name: 'Ma super belle erreur custom',
        ok: false,
        status: 400,
        statusText: 'Une Erreur Custom Faite Maison',
        url: request.url
      };
      return throwError(errorResponse) as any;
    } else {
      return next.handle(request);
    }
  }
}
