import { Action, createReducer, on } from '@ngrx/store';

import { ProductCatalogsDbActions, ProductCatalogsUiActions } from '../actions';
import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';

export const productsUiFeatureKey = 'productsUi';

export interface State {
  productsLoaded: boolean;
  productsLoading: boolean;
  error?: any;
}

export const initialState: State = {
  productsLoaded: false,
  productsLoading: false,
  error: null
};

const productsUiReducer = createReducer(
  initialState,
  on(ProductCatalogsUiActions.listProductCatalogs, (state: State) => ({ ...state, productsLoading: true, productsLoaded: false })),
  on(ProductCatalogsDbActions.listProductCatalogsSuccess, (state: State) => ({ ...state, productsLoading: false, productsLoaded: true })),
  on(ProductCatalogsDbActions.listProductCatalogsFailure, (state: State, { error }) => ({ ...state, error, productsLoaded: false, productsLoading: false })),
  //
  on(ProductCatalogsUiActions.addProductCatalog, (state: State) => ({ ...state, productsLoading: true, productsLoaded: false })),
  on(ProductCatalogsDbActions.addProductCatalogSuccess, (state: State) => ({ ...state, productsLoading: false, productsLoaded: true })),
  on(ProductCatalogsDbActions.addProductCatalogFailure, (state: State, { error }) => ({ ...state, error, productsLoaded: false, productsLoading: false })),
  //
  on(ProductCatalogsUiActions.updateProductCatalog, (state: State) => ({ ...state, productsLoading: true, productsLoaded: false })),
  on(ProductCatalogsDbActions.updateProductCatalogSuccess, (state: State) => ({ ...state, productsLoading: false, productsLoaded: true })),
  on(ProductCatalogsDbActions.updateProductCatalogFailure, (state: State, { error }) => ({ ...state, error, productsLoaded: false, productsLoading: false })),
  //
  on(ProductCatalogsUiActions.deleteProductCatalog, (state: State) => ({ ...state, productsLoading: true, productsLoaded: false })),
  on(ProductCatalogsDbActions.deleteProductCatalogSuccess, (state: State) => ({ ...state, productsLoading: false, productsLoaded: true })),
  on(ProductCatalogsDbActions.deleteProductCatalogFailure, (state: State, { error }) => ({ ...state, error, productsLoaded: false, productsLoading: false })),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return productsUiReducer(state, action);
}

export const getProductsLoaded = (state: State) => state.productsLoaded;
export const getProductsLoading = (state: State) => state.productsLoading;
