<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.CONFIGURE_TITLE' | translate: { ruleName: data.element.name } }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <form [formGroup]="poEventConfigureForm" fxLayout="column">
      <section class="form-field-container" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row">

        <mat-slide-toggle formControlName="active">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.ACTIVE' | translate }}</mat-slide-toggle>
        </div>
        <iot-platform-ui-detail-popup-display-property
          [property]="'IOT_DICTIONARY.RULE_TYPE' | translate"
          [value]="data.element.algo"
        ></iot-platform-ui-detail-popup-display-property>
        <div fxLayout="row" fxLayoutGap="20px">
          <mat-form-field color="accent" fxFlex>
            <mat-label>{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.CLASS' | translate }}</mat-label>
            <mat-select formControlName="class">
              <mat-option *ngFor="let supportedClass of selectedAlgo?.supportedClasses" [value]="supportedClass">{{ supportedClass }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field color="accent" fxFlex>
            <mat-label>{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.SEVERITY' | translate }}</mat-label>
            <mat-select formControlName="severity">
              <mat-option *ngFor="let sev of severities$ | async" [value]="sev">{{ 'EVENTS.SEVERITIES.' + sev | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex fxLayout="columns" fxLayoutGap="20px">
          <div *ngFor="let param of selectedAlgo?.params" fxFlex>
            <iot4bos-ui-string-input
              (valueChange)="onParamValueChange($event)"
              *ngIf="param.value.type === 'string' && param.value.hasOwnProperty('enum')"
              [data]="getStringData(param)"
            >
            </iot4bos-ui-string-input>

            <iot4bos-ui-text-input
              (valueChange)="onParamValueChange($event)"
              *ngIf="param.value.type === 'string' && !param.value.hasOwnProperty('enum')"
              [data]="getStringData(param)"
            >
            </iot4bos-ui-text-input>

            <iot4bos-ui-string-input
              (valueChange)="onParamValueChange($event)"
              *ngIf="param.value.type === 'integer' && param.value.hasOwnProperty('enum')"
              [data]="getStringData(param)"
            >
            </iot4bos-ui-string-input>

            <iot4bos-ui-number-input (valueChange)="onParamValueChange($event)" *ngIf="param.value.type === 'number'" [data]="getData(param)">
            </iot4bos-ui-number-input>

            <iot4bos-ui-number-input
              (valueChange)="onParamValueChange($event)"
              *ngIf="param.value.type === 'integer' && !param.value.hasOwnProperty('enum')"
              [data]="getData(param)"
            >
            </iot4bos-ui-number-input>
          </div>
        </div>
        <div fxFlex="25%">
          <mat-form-field *ngIf="selectedAlgo && selectedAlgo.concepts.length > 1" color="accent">
            <mat-label>{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.CONCEPT' | translate }}</mat-label>
            <mat-select (selectionChange)="onConceptChange($event)" formControlName="conceptList">
              <mat-option *ngFor="let concept of selectedAlgo.concepts" [value]="concept">{{ 'IOT_DICTIONARY.' + concept | translate }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="selectedAlgo && selectedAlgo.concepts.length === 1" class="" color="accent">
            <input #algoInput [placeholder]="'PO_EVENTS.CREATION_CONFIGURATION_POPUP.CONCEPT' | translate" formControlName="conceptSolo" matInput />
          </mat-form-field>
        </div>

        <mat-slide-toggle fxFlex formControlName="backToNormal">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.BACK_TO_NORMAL' | translate }}</mat-slide-toggle>
      </section>

      <iot-platform-ui-filter-engine
        #filterEngine
        (applyFilters)="onApplyFilters($event)"
        *ngIf="(pathToFilters$ | async) && selectedAlgo && currentConcept"
        [clearAppliedFilters$]="clearAppliedFilters$"
        [currentFavoriteView$]="currentFavoriteView$"
        [currentFilters$]="currentFilters$"
        [expanded]="true"
        [masterView]="pathToFilters$ | async"
        fxFlex
      >
      </iot-platform-ui-filter-engine>
      <p class="dialog-form-tips">{{ 'ASSETS.VARIABLE_FORM.MANDATORY_FIELDS' | translate }}</p>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button (click)="configure()" [disabled]="shouldDisableConfigureButton" class="button-regular" color="accent" mat-raised-button>
      {{ 'IOT_DICTIONARY.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
