import { RowSelectedEvent, SortChangedEvent } from '@ag-grid-community/core';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CommonApiRequest,
  CommonIndexedPagination,
  Environment,
  Filter,
  Pagination
} from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions, I4BGridSort } from '@iot-platform/models/grid-engine';
import { concatLatestFrom } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { get } from 'lodash';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GridsDbActions } from '../../state/actions';
import * as fromGrids from '../../state/reducers';

@Component({
  selector: 'grid-engine-grid-manager',
  templateUrl: './grid-manager.component.html',
  styleUrls: ['./grid-manager.component.scss']
})
export class GridManagerComponent implements OnChanges, OnInit, OnDestroy {
  @Input() grid: I4BGrid<I4BGridOptions, I4BGridData>;
  @Input() gridSort: I4BGridSort[];
  @Input() currentFilters: Filter[] = [];
  @Input() userPermissions;
  @Input() visibleNodeId: string;

  @Output() dispatchMasterViewEngineEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() timerValueChanged: EventEmitter<number> = new EventEmitter<number>();

  adminModeOn = false;

  allGrids$ = this.store.pipe(select(fromGrids.getAllGrids));
  internalGrid;

  selectedViewType: string = 'sites';
  refreshActivated$: Observable<boolean> = this.store.select(fromGrids.selectRefreshActivated);

  private readonly startTimer$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly destroyTimer$: Subject<void> = new Subject<void>();
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(@Inject('environment') private readonly environment: Environment, private readonly dialog: MatDialog, private readonly store: Store) {}

  get autoRefreshDelay(): number {
    return get(this.internalGrid, 'gridOptions.autoRefresh.delay') || 0;
  }

  get autoRefreshEnabled(): boolean {
    return get(this.internalGrid, 'gridOptions.autoRefresh.enabled') || false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['grid'] && changes['grid'].currentValue) {
      this.internalGrid = changes['grid'].currentValue;
      if (this.autoRefreshEnabled) {
        this.startTimer$.next(true);
      }
    }
    if (changes['gridSort'] && changes['gridSort'].currentValue) {
      this.gridSort = changes['gridSort'].currentValue;
    }
  }

  ngOnInit(): void {
    this.refreshActivated$
      .pipe(
        filter((activated: boolean) => activated),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        // Restart timer
        this.startTimer$.next(true);
      });
    this.getTimer()
      .pipe(
        concatLatestFrom(() => this.store.select(fromGrids.selectRefreshActivated)),
        filter(([_, refreshActivated]) => refreshActivated && this.autoRefreshEnabled),
        takeUntil(this.destroy$)
      )
      .subscribe(([current]) => {
        this.timerValueChanged.emit(current);
        if (current >= this.autoRefreshDelay) {
          this.loadData();
          this.startTimer$.next(true);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyTimer();
    this.destroy(this.destroy$);
  }

  loadData(): void {
    const request: CommonApiRequest = {
      limit: this.internalGrid.data ? (this.internalGrid.data.response.pagination as CommonIndexedPagination).limit : this.internalGrid.gridOptions.pageSize,
      page: this.internalGrid.data ? (this.internalGrid.data.response.pagination as CommonIndexedPagination).currentPage : 0,
      // TODO temporary workaround to sync filters between the master view and the selected grid
      // Should be updated once the new auto refresh US will be ready
      filters: this.currentFilters,
      concept: this.internalGrid.masterview.toLowerCase(),
      variables: this.internalGrid.gridOptions.variableNames,
      tags: this.internalGrid.gridOptions.tagIds
    };
    this.store.dispatch(GridsDbActions.loadGridData({ request }));
  }

  loadGridDetails(grid: I4BGrid<I4BGridOptions, I4BGridData>) {
    this.store.dispatch(GridsDbActions.loadGridDetails({ concept: grid.masterview, gridId: grid.id }));
  }

  onPageChange(pagination: Pagination) {
    const request: CommonApiRequest = {
      limit: pagination.limit,
      page: pagination.currentPage,
      filters: this.currentFilters,
      concept: this.grid.masterview.toLowerCase(),
      variables: this.grid.gridOptions.variableNames,
      tags: this.grid.gridOptions.tagIds,
      endPoint: this.grid.gridOptions.endPoint
    };
    this.store.dispatch(GridsDbActions.changeGridPage({ request }));
  }

  onSelectRow(event: RowSelectedEvent) {
    this.store.dispatch(GridsDbActions.selectItemInGridData({ gridId: this.grid.id, itemId: event.data.id }));
  }

  onSortChange(sortEvent: { event: SortChangedEvent; grid: I4BGrid<I4BGridOptions, I4BGridData> }) {
    const newSort: I4BGridSort[] = sortEvent.event.columnApi.getColumnState().map(({ colId, sort, sortIndex }) => ({ colId, sort, sortIndex }));
    this.store.dispatch(GridsDbActions.sortGridData({ gridId: sortEvent.grid.id, gridSort: newSort }));
  }

  onDispatchGridEvent(dispatched) {
    /*if (event && event.event.type === 'columnMoved') {
      SOON ^__^
    }*/
    const canEditGrid = !this.internalGrid.isAppDefault && !this.internalGrid.businessProfileId && this.internalGrid.userId;
    const resizeEnded = dispatched && dispatched.event.type === 'columnResized' && dispatched.event.column && dispatched.event.finished;

    if (canEditGrid && resizeEnded) {
      const cols = [...this.internalGrid.columns];
      const idx = cols.findIndex((col) => col.configuration.id === dispatched.event.column.getColId());
      const newCols = [...this.internalGrid.columns];
      newCols[idx] = { ...newCols[idx], options: { ...newCols[idx].options, width: dispatched.event.column.actualWidth } };

      const updateGrid: I4BGrid<I4BGridOptions, I4BGridData> = {
        ...this.internalGrid,
        columns: newCols,
        data: null,
        gridOptions: { ...this.internalGrid.gridOptions, filters: [] }
      };
      this.store.dispatch(GridsDbActions.updateSilentGrid({ toUpdate: updateGrid }));
    }
  }

  private getTimer(): Observable<number> {
    return this.startTimer$.pipe(
      tap(() => {
        this.destroyTimer();
      }),
      filter((enabled: boolean) => enabled && this.autoRefreshDelay > 0),
      switchMap(() => {
        return timer(0, 1 * 1000).pipe(takeUntil(this.destroyTimer$));
      })
    );
  }

  private destroyTimer(): void {
    this.destroy(this.destroyTimer$);
  }

  private destroy(subject: Subject<void>): void {
    subject.next();
    subject.complete();
  }
}
