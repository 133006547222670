import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';

export const poEventRulesColumnSettings = [
  {
    order: 1,
    id: 'name',
    name: 'IOT_DICTIONARY.NAME',
    catalogName: 'NAME',
    default: true,
    sortable: true,
    sortProperty: 'name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC_LINK,
    isLink: true,
    clickEvent: {
      type: 'navigate',
      options: {}
    },
    width: '30%',
    type: 'start center'
  },
  {
    order: 2,
    id: 'algo',
    name: 'IOT_DICTIONARY.RULE_TYPE',
    catalogName: 'RULE TYPE',
    default: true,
    sortable: true,
    sortProperty: 'algo',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '15%',
    type: 'start center'
  },
  {
    order: 3,
    id: 'severity',
    name: 'IOT_DICTIONARY.SEVERITY',
    catalogName: 'SEVERITY',
    default: true,
    sortable: true,
    sortProperty: 'severity',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.SEVERITY_CELL,
    cellTypeOptions: {},
    isLink: false,
    width: '15%',
    type: 'start center'
  },
  {
    order: 4,
    id: 'class',
    name: 'IOT_DICTIONARY.CLASS',
    catalogName: 'CLASS',
    default: true,
    sortable: true,
    sortProperty: 'class',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '10%',
    type: 'start center'
  },
  {
    order: 5,
    id: 'concept',
    name: 'IOT_DICTIONARY.CONCEPT',
    catalogName: 'CONCEPT',
    default: true,
    sortable: true,
    sortProperty: 'concept',
    headerType: HeaderType.BASIC,
    cellType: 'translatedCell',
    isLink: false,
    width: '10%',
    type: 'start center'
  },
  {
    order: 6,
    id: 'entity.name',
    name: 'IOT_DICTIONARY.ENTITY',
    catalogName: 'ENTITY',
    default: true,
    sortable: true,
    sortProperty: 'entity.name',
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BASIC,
    isLink: false,
    width: '10%',
    type: 'start center'
  },

  {
    order: 7,
    id: 'isActive',
    name: 'IOT_DICTIONARY.STATUS',
    catalogName: 'STATUS',
    default: true,
    sortable: false,
    headerType: HeaderType.BASIC,
    cellType: I4BCellType.BOOLEAN_CELL,
    cellTypeOptions: {
      mode: 'booleanAsText',
      dataToDisplayIfTrue: 'active',
      dataToDisplayIfFalse: null,
      class: {
        disabled: { color: '#262836', 'background-color': 'rgba(255, 0, 99, 0.1)', padding: '9px' },
        active: { color: '#262836', 'background-color': 'rgba(164, 255, 0, 0.1)', padding: '9px' },
        unconfirmed: { color: '#262836', 'background-color': 'rgba(255, 158, 0, 0.1)', padding: '9px' },
        pending: { color: '#262836', 'background-color': 'rgba(0, 121, 255, 0.1)', padding: '9px' }
      },
      withStatusPadding: true
    },
    isLink: false,
    width: '5%',
    type: 'start center'
  }
];
