import { PlatformRequest, PlatformResponse } from '@iot-platform/models/common';
import { Asset, Device, Site, VariablesTableFilters } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const clearData = createAction('[Navigation] Clear Data');

export const loadData = createAction('[Navigation] Load Data', props<{ origin: string; activeId: string; siteId: string; pageType: string }>());
export const loadDataSuccess = createAction('[Navigation] Load Data Success', props<{ site: Site; assets: Asset[]; devices: Device[] }>());
export const loadDataFailure = createAction('[Navigation] Load Data Failure', props<{ error: any }>());
//
export const openSiteDetail = createAction('[Navigation] Open Site Detail', props<{ origin: string; activeId: string; siteId: string; pageType: string }>());
export const openAssetDetail = createAction('[Navigation] Open Asset Detail', props<{ origin: string; activeId: string; siteId: string; pageType: string }>());
export const openDeviceDetail = createAction(
  '[Navigation] Open Device Detail',
  props<{ origin: string; activeId: string; siteId: string; pageType: string }>()
);
//
export const selectAsset = createAction('[Navigation] Select Asset', props<{ siteId: string; assetId: string }>());
export const selectLeSite = createAction('[Navigation] Select Le Site', props<{ selectedSite: Site }>());
export const selectLeStock = createAction('[Navigation] Select Le Stock', props<{ stock: Site; request: PlatformRequest }>());
export const selectAssetAvecLeSite = createAction('[Navigation] Select Asset Avec Le Site', props<{ selectedAsset: Asset }>());
export const selectDeviceAvecLeSite = createAction('[Navigation] Select Device Avec Le Site', props<{ selectedDevice: Device }>());
export const applyDeviceVariablesTableFilters = createAction(
  '[Navigation] save device variables table filters',
  props<{ filters: Partial<VariablesTableFilters> }>()
);
export const clearSelectedItem = createAction('[Navigation] Clear Selected Item', props<{ site: Site }>());
export const selectAssetSuccess = createAction('[Navigation] Select Asset Success', props<{ selectedAsset: Asset }>());
//
export const selectDevice = createAction('[Navigation] Select Device', props<{ siteId: string; deviceId: string }>());
export const selectDeviceSuccess = createAction('[Navigation] Select Device Success', props<{ selectedDevice: Device }>());

export const changeStockPage = createAction('[Navigation] Change Stock Page', props<{ request: PlatformRequest }>());
export const changeStockPageSuccess = createAction('[Navigation] Change Stock Page Success', props<{ response: PlatformResponse }>());
export const changeStockPageFailure = createAction('[Navigation] Change Stock Page Failure', props<{ error: any }>());

export const moveDevice = createAction('[Navigation] Move Device', props<{ deviceToMove: Device }>());
export const moveDeviceSuccess = createAction('[Navigation] Move Device Success', props<{ movedDevice: Device }>());
export const moveDeviceFailure = createAction('[Navigation] Move Device Failure', props<{ error: any }>());

export const moveDevices = createAction('[Navigation] Move Devices', props<{ devicesToMove: Device[] }>());
export const moveDevicesSuccess = createAction('[Navigation] Move Devices Success', props<{ movedDevices: Device[] }>());
export const moveDevicesFailure = createAction('[Navigation] Move Devices Failure', props<{ error: any }>());

export const loadDeviceCountByFamily = createAction('[Navigation] Load Device Count by Family', props<{ siteId: string }>());
export const loadDeviceCountByFamilySuccess = createAction(
  '[Navigation] Load Device Count by Family Success',
  props<{ deviceCountByFamily: Array<{ family: string; total: number }> }>()
);
export const loadDeviceCountByFamilyFailure = createAction('[Navigation] Load Device Count by Family Failure', props<{ error: any }>());
