<span *ngIf="displayMode === 'value'" class="value-cell" (click)="onDispatchEvent($event)">
  {{ variable?.lastValue?.value | numberFormat | infoDisplay }}&nbsp;{{ variable?.unit }}
</span>

<span
  *ngIf="displayMode === 'datetime'"
  class="date-cell"
  [ngClass]="{ recentDay: isRecentDay, recentHour: isRecentHour, futureDay: isFutureDay, nextDay: isNextDay }"
>
  {{ variable?.lastValue?.datetime | dateFormat | infoDisplay }}
</span>
