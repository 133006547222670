import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CustomOptionsCellParams } from '../custom-options-cell.params';

@Component({
  selector: 'grid-engine-comment-cell',
  templateUrl: './comment-cell.component.html',
  styleUrls: ['./comment-cell.component.scss']
})
export class CommentCellComponent implements ICellRendererAngularComp {
  params: CustomOptionsCellParams;

  constructor() {}

  agInit(params: CustomOptionsCellParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  onDispatchEvent(event: MouseEvent): void {
    event.stopPropagation();
    this.params.dispatchEvent({ type: 'openComments', options: {}, rawData: this.params.data });
  }
}
