import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TabNavBarItem } from '../../components/tab-nav-bar/tab-nav-bar-item.model';
import { AuthorizationService } from '../../features/auth/services/authorization.service';
import { AuthFacade } from '../../features/auth/state/facades/auth.facade';
import { OyanAuthorizationConcept, OyanAuthorizationType } from '../../features/auth/types/authorization.types';
import { OyanUiRoutingConstants } from '../../oyan-ui.router.constants';

@Component({
  selector: 'oyan-ui-tab-nav',
  templateUrl: './tab-nav.component.html',
  styleUrls: ['./tab-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabNavComponent {

  navLinks$: Observable<TabNavBarItem[]>;

  constructor(private authorizationService: AuthorizationService, private authFacade: AuthFacade) {
    this.navLinks$ = this.authFacade.privileges$
      .pipe(
        filter((privileges) => privileges !== null),
        map(() => {
          return [
            {
              label: 'TAB_NAV.SITES',
              link: `/${OyanUiRoutingConstants.SITES}`,
              visible: this.authorizationService.applyAuthorization(OyanAuthorizationConcept.SITE, OyanAuthorizationType.READ)
            },
            {
              label: 'TAB_NAV.GATEWAYS',
              link: `/${OyanUiRoutingConstants.GATEWAYS}`,
              visible: this.authorizationService.applyAuthorization(OyanAuthorizationConcept.GATEWAY, OyanAuthorizationType.READ)
            },
            {
              label: 'TAB_NAV.RECEIVERS',
              link: `/${OyanUiRoutingConstants.RECEIVERS}`,
              visible: this.authorizationService.applyAuthorization(OyanAuthorizationConcept.FORWARDER, OyanAuthorizationType.READ)
            },
            {
              label: 'TAB_NAV.ASSETS',
              link: `/${OyanUiRoutingConstants.ASSETS}`,
              visible: this.authorizationService.applyAuthorization(OyanAuthorizationConcept.ASSET, OyanAuthorizationType.READ)
            },
            {
              label: 'TAB_NAV.COMMUNICATIONS',
              link: `/${OyanUiRoutingConstants.COMMUNICATIONS}`,
              visible: this.authorizationService.applyAuthorization(OyanAuthorizationConcept.MESSAGE, OyanAuthorizationType.READ)
            }
          ];
        })
      );
  }
}
