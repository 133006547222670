import { Action, createReducer, on } from '@ngrx/store';

import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';

import { AssetEventsBySiteDbActions, AssetEventsBySiteUiActions } from '../../actions';

export const assetEventsBySiteUiFeatureKey = 'assetEventsBySiteUi';

export interface State {
  error?: any;
  assetEventsLoaded: boolean;
  assetEventsLoading: boolean;
  totalActiveEventsLoaded: boolean;
}

export const initialState: State = {
  error: null,
  assetEventsLoaded: false,
  assetEventsLoading: false,
  totalActiveEventsLoaded: false
};

const assetEventsBySiteUiReducer = createReducer(
  initialState,
  on(AssetEventsBySiteUiActions.loadAssetEventsBySite, (state: State) => ({ ...state, assetEventsLoading: true, assetEventsLoaded: false })),
  on(AssetEventsBySiteDbActions.loadAssetEventsBySiteSuccess, (state: State) => ({ ...state, assetEventsLoading: false, assetEventsLoaded: true })),
  on(AssetEventsBySiteDbActions.loadAssetEventsBySiteFailure, (state: State, { error }) => ({
    ...state,
    assetEventsLoading: false,
    assetEventsLoaded: false,
    error
  })),
  // ****
  on(AssetEventsBySiteUiActions.loadTotalActiveAssetEventsBySite, (state: State) => ({ ...state, totalActiveEventsLoaded: false })),
  on(AssetEventsBySiteDbActions.loadTotalActiveAssetEventsBySiteSuccess, (state: State) => ({ ...state, totalActiveEventsLoaded: true })),
  on(AssetEventsBySiteDbActions.loadTotalActiveAssetEventsBySiteFailure, (state: State, { error }) => ({ ...state, totalActiveEventsLoaded: false, error })),
  // ****
  on(AssetEventsBySiteUiActions.bulkUpdateStatusByAssetEventIdBySite, (state: State) => ({ ...state, assetEventsLoaded: false })),
  on(AssetEventsBySiteDbActions.updateStatusByAssetEventIdBySiteSuccess, (state: State) => ({ ...state, assetEventsLoaded: true })),
  on(AssetEventsBySiteDbActions.updateStatusByAssetEventIdBySiteFailure, (state: State, { error }) => ({ ...state, assetEventsLoaded: false, error })),
  // ****
  on(AssetEventsBySiteUiActions.loadMvAssetEventsBySiteSettings, (state: State) => ({ ...state, assetEventsLoading: true, assetEventsLoaded: false })),
  on(AssetEventsBySiteDbActions.loadMvAssetEventsBySiteSettingsSuccess, (state: State) => ({ ...state, assetEventsLoading: false, assetEventsLoaded: true })),
  on(AssetEventsBySiteDbActions.loadMvAssetEventsBySiteSettingsFailure, (state: State, { error }) => ({
    ...state,
    assetEventsLoading: false,
    assetEventsLoaded: false,
    error
  })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsBySiteUiReducer(state, action);
}

export const getAssetEventsLoaded = (state: State) => state.assetEventsLoaded;
export const getAssetEventsLoading = (state: State) => state.assetEventsLoading;
export const getTotalActiveEventsLoaded = (state: State) => state.totalActiveEventsLoaded;
