import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiHelpers } from '@iot-platform/iot-platform-utils';
import {
  CommonApiListResponse,
  CommonApiRequest,
  CommonApiResponse,
  CommonCRUDService,
  CommonIndexedPagination
} from '@iot-platform/models/common';
import { Asset } from '@iot-platform/models/oyan';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssetsService implements CommonCRUDService<Asset, CommonIndexedPagination> {
  constructor(@Inject('environment') private readonly environment, private readonly httpClient: HttpClient) {}

  getAll(request: CommonApiRequest): Observable<CommonApiResponse<Asset, CommonIndexedPagination>> {
    return this.httpClient
      .get(`${this.environment.api.url}${this.environment.api.endpoints.assets}`, { params: ApiHelpers.getHttpParams(request) })
      .pipe(map((response: CommonApiListResponse<Asset>) => ApiHelpers.getHttpResponse<Asset>(response)));
  }

  getById(id: string): Observable<Asset> {
    return this.httpClient.get<Asset>(`${this.environment.api.url}${this.environment.api.endpoints.assets}/${id}`);
  }

  // TODO
  addOne(asset: Asset): Observable<Asset> {
    return of(asset);
  }

  // TODO
  updateOne(asset: Asset): Observable<Asset> {
    return of(asset);
  }

  // TODO
  deleteOne(asset: Asset): Observable<Asset> {
    return of(asset);
  }
}
