<mat-card fxLayout="column" class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'ADMIN.PRODUCT_CATALOGS.FORM.TITLE_MANAGE_CATALOGS' | translate }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content fxLayout="column" class="dialog-card-content p-20" fxLayoutGap="10px">
    <div class="catalog-edition-section">
      <iot4bos-backoffice-ui-catalog-editor-form
        *ngFor="let catalog of catalogs$ | async"
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="10px"
        [mode]="'UPDATE'"
        [catalog]="catalog"
        [entityList]="entities"
        (changeValue)="onChangeValue($event)"
        (save)="onUpdateCatalog($event)"
        (remove)="onDeleteCatalog($event)"
      >
      </iot4bos-backoffice-ui-catalog-editor-form>
    </div>

    <iot4bos-backoffice-ui-catalog-editor-form
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
      [mode]="'ADD'"
      [catalog]="newCatalog"
      [entityList]="entities"
      (changeValue)="onChangeValue($event)"
      (save)="onAddCatalog($event)"
    >
    </iot4bos-backoffice-ui-catalog-editor-form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayoutAlign="end center">
    <button mat-raised-button color="accent" class="button-regular" [disabled]="!canClose" (click)="close()">{{ 'MANAGE_TAGS_FORM.DONE' | translate }}</button>
  </mat-card-actions>
</mat-card>
