import { Injectable } from '@angular/core';
import { ApiSelector, Contact, Filter, Pagination } from '@iot-platform/models/common';
import * as fromMain from '../reducers';
import * as fromSiteContacts from '../reducers/site-contacts.reducer';

@Injectable({
  providedIn: 'root'
})
export class SiteContactsSelectors extends ApiSelector<fromMain.State, Contact, Pagination, Filter> {
  constructor() {
    super(fromMain.featureKey, fromSiteContacts.featureKey, fromSiteContacts.adapter);
  }
}
