import { BasicColumnConfiguration } from '../configurations/i4b-basic-column-configuration';
import { I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { I4BBasicColumnHeader } from '../headers/i4b-basic-column-header';
import { I4BColumnHeader } from '../models/i4b-column-header.model';
import { BasicColumnOptions } from '../options/i4b-basic-column-options';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BColumn } from './i4b-column';

export class I4BBasicColumn implements I4BColumn<I4BBasicColumnHeader, I4BColumnConfiguration, I4BColumnOptions> {
  columnId = '65b4f54c-5b50-11ec-80a5-0023a40210d0-basic';
  className = I4BBasicColumn;
  dataLibs: DataLibrary[] = [
    DataLibrary.SITES,
    DataLibrary.ASSETS,
    DataLibrary.DEVICES,
    DataLibrary.TAGS,
    DataLibrary.VARIABLES,
    DataLibrary.TECHNICAL,
    DataLibrary.CONNECTORS
  ];
  header: I4BBasicColumnHeader = new I4BBasicColumnHeader();
  configuration: I4BColumnConfiguration = new BasicColumnConfiguration({ isConfigurable: true, isDefault: false });
  options: I4BColumnOptions = new BasicColumnOptions({ sortable: true });

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    if (customHeader) {
      this.header = new I4BBasicColumnHeader({
        ...this.header,
        ...customHeader,
        displayName: customOptions?.overrideHeaderName
          ? customOptions.overrideHeaderName
          : customHeader?.displayName
          ? customHeader.displayName
          : this.header.displayName
      });
    }

    if (customConfig) {
      this.configuration = { ...this.configuration, ...customConfig, width: customOptions?.width ? customOptions.width : customConfig.width };
    }

    if (customOptions) {
      this.options = { ...this.options, ...customOptions };
    }
  }
}
