import { Injectable } from '@angular/core';

import { Log } from '@iot-platform/models/i4b';

import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GridsDbActions } from 'libs/grid-engine/src/lib/components/state/actions';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AssetEventsService } from '../../../../../../../shared/src/lib/services/asset-events.service';
import { AssetEventsLogsDbActions, AssetEventsLogsUiActions } from '../actions';

@Injectable()
export class AssetEventsLogsEffects {
  loadLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsLogsUiActions.loadLogsByAssetEventId),
      switchMap((action) =>
        this.assetEventsService.getLogsById(action.assetEventId).pipe(
          map((logs: Log[]) => AssetEventsLogsDbActions.loadLogsByAssetEventIdSuccess({ logs })),
          catchError((error) => of(AssetEventsLogsDbActions.loadLogsByAssetEventIdFailure({ error })))
        )
      )
    )
  );

  loadLogsAfterCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsLogsDbActions.createLogByAssetEventIdSuccess),
      switchMap((action) => {
        return this.assetEventsService.getLogsById(action.assetEvent.id).pipe(
          map((logs: Log[]) => AssetEventsLogsDbActions.loadLogsByAssetEventIdSuccess({ logs })),
          catchError((error) => of(AssetEventsLogsDbActions.loadLogsByAssetEventIdFailure({ error })))
        );
      })
    )
  );

  createLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsLogsUiActions.createLogByAssetEventId),
      switchMap((action) =>
        this.assetEventsService.putLogById(action.comment).pipe(
          switchMap((log: Log) => [
            GridsDbActions.updateItemInAllGridsData({ updatedItem: action.comment.assetEvent }),
            AssetEventsLogsDbActions.createLogByAssetEventIdSuccess({ log, assetEvent: action.comment.assetEvent })
          ]),
          catchError((error) => of(AssetEventsLogsDbActions.createLogByAssetEventIdFailure({ error })))
        )
      )
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetEventsLogsDbActions.createLogByAssetEventIdSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetEventsLogsDbActions.loadLogsByAssetEventIdFailure, AssetEventsLogsDbActions.createLogByAssetEventIdFailure),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private assetEventsService: AssetEventsService,
    private notificationService: NotificationService
  ) {}
}
