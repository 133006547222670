import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@iot-platform/auth';
import { OyanUiRoutingConstants } from '../../oyan-ui.router.constants';
import { TabNavComponent } from '../tab-nav/tab-nav.component';
import { AppShellComponent } from './app-shell.component';

export const routes: Routes = [
  {
    path: '',
    component: AppShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: OyanUiRoutingConstants.ADMIN,
        loadChildren: () => import('../../features/admin/admin.module').then((m) => m.OyanUiAdminModule)
      },
      {
        path: OyanUiRoutingConstants.TRANSLATIONS,
        loadChildren: () => import('@iot-platform/i18n').then((m) => m.TranslationManagementModule)
      },
      {
        path: OyanUiRoutingConstants.PRODUCTS,
        loadChildren: () => import('../../features/products/products.module').then((m) => m.ProductsModule)
      },
      {
        path: '',
        component: TabNavComponent,
        children: [
          { path: '', redirectTo: OyanUiRoutingConstants.SITES, pathMatch: 'full' },
          {
            path: OyanUiRoutingConstants.SITES,
            loadChildren: () => import('../../features/sites/sites.module').then((m) => m.OyanUiSitesModule)
          },
          {
            path: OyanUiRoutingConstants.GATEWAYS,
            loadChildren: () => import('../../features/gateways/gateways.module').then((m) => m.GatewaysModule)
          },
          {
            path: OyanUiRoutingConstants.RECEIVERS,
            loadChildren: () => import('../../features/receivers/receivers.module').then((m) => m.ReceiversModule)
          },
          {
            path: OyanUiRoutingConstants.ASSETS,
            loadChildren: () => import('../../features/assets/assets.module').then((m) => m.AssetsModule)
          },
          {
            path: OyanUiRoutingConstants.COMMUNICATIONS,
            loadChildren: () => import('../../features/communications/communications.module').then((m) => m.CommunicationsModule)
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppShellRouterModule {}
