<mat-toolbar fxLayout='row' fxLayoutAlign='space-between center' fxLayoutGap='2px' color='primary'>
  <div class='regular-toolbar-section' fxFlex fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='10px'>
    <span
      class='regular-toolbar-label'>{{ (currentUser$ | async)?.firstname | uppercase }} {{ (currentUser$ | async)?.lastname | uppercase }}</span>
    <mat-progress-spinner *ngIf='loading$ | async' mode='indeterminate' [diameter]='20' class='regular-loader'
                          color='accent'></mat-progress-spinner>
  </div>
</mat-toolbar>

<div fxLayout='column' fxLayoutAlign='start center' fxLayoutGap='50px' class='user-profile-info-container' *ngIf='(currentUser$ | async)'>

  <div fxLayout='row' fxFlexFill fxLayoutGap='50px'>
    <oyan-ui-users-user-profile-info [currentUser]='currentUser$ | async' fxFlex='50%'
                                     (editUserProfile)='editUserProfile()'
    ></oyan-ui-users-user-profile-info>

    <oyan-ui-user-profile-preferences  fxFlex='50%'
                                       [currentUser]='currentUser$ | async'
                                       (changeUserPreferences)='changeUserPreferences()'
    ></oyan-ui-user-profile-preferences>

  </div>
  <div fxLayout='row' fxFlexFill>
    <iot-platform-users-user-related-business-profiles fxFlex [currentUser]="(currentUser$ | async)"></iot-platform-users-user-related-business-profiles>
  </div>
</div>

