import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterViewEngineEvent } from '@iot-platform/models/common';

@Component({
  selector: 'i4b-table-engine-asset-variable-threshold-cell',
  templateUrl: './asset-variable-threshold-cell.component.html',
  styleUrls: ['./asset-variable-threshold-cell.component.scss']
})
export class AssetVariableThresholdCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() columnId: string;
  @Input() clickEvent: any;
  @Input() cellOptions: any;
  @Input() userPermissions: Array<{ key: string; value: boolean }>;

  @Output() dispatchEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();

  thresholdValue!: number;

  constructor() {}

  ngOnInit(): void {
    if (!!this.data.length && this.cellOptions?.position > 0) {
      this.thresholdValue = this.data[this.cellOptions?.position - 1]?.value;
    } else {
      this.thresholdValue = null;
    }
  }
}
