import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';

export class I4BBasicColumnHeader {
  type = HeaderType.BASIC;
  icon = '';
  displayName = 'BASIC HEADER VALUE';

  constructor(customHeader?: Partial<I4BColumnHeader>) {
    if (customHeader) {
      this.displayName = customHeader.displayName ?? this.displayName;
      this.icon = customHeader.icon ?? '';
      this.type = customHeader.type ?? HeaderType.BASIC;
    }
  }

  // TODO : use for display only should not alter saved data
  /*private _displayName;

  get displayName(): string {
    return capitalize(this._displayName);
  }

  set displayName(displayName: string) {
    this._displayName = displayName;
  }*/
}
