import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AuthBusinessProfilesService } from '../../services/auth-business-profiles.service';
import { AuthService } from '../../services/auth.service';
import { AuthApiActions, AuthBusinessProfilesApiActions, AuthBusinessProfilesPageActions } from '../actions';

@Injectable()
export class AuthBusinessProfilesEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthBusinessProfilesPageActions.loadBusinessProfiles || AuthApiActions.retrieveSessionSuccess),
      switchMap(() =>
        this.authService.loadAccount().pipe(
          map((businessProfiles) => AuthBusinessProfilesApiActions.loadBusinessProfilesSuccess({ businessProfiles })),
          catchError((error) => of(AuthApiActions.signInFailure({ error })))
        )
      )
    )
  );

  selectProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthBusinessProfilesPageActions.selectBusinessProfile),
      switchMap((action) =>
        this.businessProfileService.selectBusinessProfile(action.selectedBusinessProfile).pipe(
          map((selectedBusinessProfile) =>
            AuthBusinessProfilesApiActions.selectBusinessProfileSuccess({ selectedBusinessProfile, withRedirect: action.withRedirect })
          ),
          catchError((error) => of(AuthBusinessProfilesApiActions.selectBusinessProfileFailure({ error })))
        )
      )
    )
  );

  navigateToHome$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess),
        map((action) => {
          if (action.withRedirect) {
            this.router.navigate(['/home']);
          }
        })
      ),
    { dispatch: false }
  );

  selectBusinessProfileSuccessThenLoadSessionEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess),
      switchMap((action) =>
        this.businessProfileService.getSessionEntity(action.selectedBusinessProfile?.entityId).pipe(
          map((selectedEntity) => AuthBusinessProfilesApiActions.loadSelectedEntityForSessionSuccess({ selectedEntity })),
          catchError((error) => of(AuthBusinessProfilesApiActions.loadSelectedEntityForSessionFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private businessProfileService: AuthBusinessProfilesService,
    private router: Router
  ) {}
}
