import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UserThumbnailComponent } from './user-thumbnail.component';

@NgModule({
  imports: [CommonModule],
  exports: [UserThumbnailComponent],
  declarations: [UserThumbnailComponent]
})
export class UserThumbnailModule {}
