import { Filter, PlatformRequest } from '@iot-platform/models/common';
import { PoEventRule } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const listPOEventRules = createAction('[POEvent Rules Ui] List PO Event Rules', props<{ request: PlatformRequest }>());
export const loadPOEventRule = createAction('[POEvent Rules Ui] Load PO Event Rule', props<{ ruleToLoadId: string }>());
export const addPOEventRule = createAction('[POEvent Rules Ui] Add PO Event Rule', props<{ poEventRuleToAdd: PoEventRule }>());
export const addPOEventRuleThenConfigure = createAction(
  '[POEvent Rules Ui] Add PO Event Rule then open configure popup',
  props<{ poEventRuleToAdd: PoEventRule }>()
);
export const cancelAddPOEventRuleThenConfigure = createAction('[POEvent Rules Ui] Cancel add PO Event Rule then open configure popup');

export const openConfigurePopup = createAction('[POEvent Rules Ui] Open configure popup', props<{ poEventRuleToUpdate: PoEventRule }>());
export const updatePOEventRule = createAction('[POEvent Rules Ui] Update PO Event Rule', props<{ poEventRuleToUpdate: PoEventRule }>());
export const configurePOEventRule = createAction('[POEvent Rules Ui] Configure PO Event Rule', props<{ poEventRuleToConfigure: PoEventRule }>());
export const deletePOEventRule = createAction('[POEvent Rules Ui] Delete PO Event Rule', props<{ poEventRuleToDelete: PoEventRule }>());
export const savePOEventRuleFilters = createAction('[POEvent Rules Ui] Save PO Event Rule Filters', props<{ filters: Filter[] }>());
export const navigateToPOEventRuleDetails = createAction('[POEvent Rules Ui] Navigate to PO Event Rule Details', props<{ rule: PoEventRule }>());
