import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { BusinessProfilesService } from '../../services/business-profiles.service';
import { BusinessProfilesRolesApiActions, BusinessProfilesRolesDialogActions } from '../actions';

@Injectable()
export class AdminBusinessProfilesRolesEffects {
  addRoleToBusinessProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesRolesDialogActions.addRoleToBusinessProfile),
      mergeMap(action =>
        this.businessProfilesService.addRoleToBusinessProfile(action.businessProfileId, action.roleToAdd).pipe(
          map(addedRole => BusinessProfilesRolesApiActions.addRoleToBusinessProfileSuccess({ addedRole })),
          catchError(error => of(BusinessProfilesRolesApiActions.addRoleToBusinessProfileFailure({ error })))
        )
      )
    )
  );

  removeRoleFromBusinessProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessProfilesRolesDialogActions.removeRoleFromBusinessProfile),
      mergeMap(action =>
        this.businessProfilesService.removeRoleFromBusinessProfile(action.businessProfileId, action.roleToRemove).pipe(
          map(removedRole => BusinessProfilesRolesApiActions.removeRoleFromBusinessProfileSuccess({ removedRole })),
          catchError(error => of(BusinessProfilesRolesApiActions.removeRoleFromBusinessProfileFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private businessProfilesService: BusinessProfilesService) {}
}
