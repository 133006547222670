import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { Asset } from '@iot-platform/models/i4b';

interface Hour {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'iot-platform-maps-map-panel-info-toolbox',
  templateUrl: './map-panel-info-toolbox.component.html',
  styleUrls: ['./map-panel-info-toolbox.component.scss']
})
export class MapPanelInfoToolboxComponent implements OnInit {
  @Input() asset: Asset;
  @Output() displayRoute: EventEmitter<{ asset: Asset; daysToDisplay: string }> = new EventEmitter<{ asset: Asset; daysToDisplay: string }>();
  hours: Hour[] = [
    { value: '60', viewValue: '1 hour' },
    { value: '1440', viewValue: '1 day' },
    { value: '2880', viewValue: '2 days' },
    { value: '4320', viewValue: '3 days' },
    { value: '7200', viewValue: '5 days' },
    { value: '14400', viewValue: '10 days' },
    { value: '43200', viewValue: '30 days' },
    { value: '86400', viewValue: '60 days' },
    { value: '259200', viewValue: '180 days' }
  ];

  selected = '1440';

  constructor(private readonly zone: NgZone) {}

  ngOnInit(): void {
    this.zone.run(() => {});
  }

  onDisplayRoute() {
    if (this.asset) {
      this.displayRoute.emit({ asset: this.asset, daysToDisplay: this.selected });
    }
  }
}
