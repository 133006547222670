import { GridEngineModule } from './../../../../../grid-engine/src/lib/grid-engine.module';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthInterceptor, AuthService } from '@iot-platform/auth';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { SharedModule } from '@iot-platform/shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CardLoaderModule } from '../../../../../iot-platform-ui/src/lib/ui/components/card-loader/card-loader.module';
import { DateFormatModule } from '../../../../../iot-platform-pipes/src/lib/date-format/date-format.module';
import { MasterViewEngineModule } from '../../../../../table-engine/src/lib/components/master-view-engine/master-view-engine.module';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { UsersTableComponent } from './components/users-table/users-table.component';
import { AdminUsersComponent } from './containers/admin-users.component';
import { UsersService } from './services/users.service';
import { AdminUsersEffects } from './state/effects/admin-users.effects';
import * as fromUsers from './state/reducers';
import { ErrorInterceptor } from '../../../../../auth/src/lib/error.interceptor';
import { UserDetailsShellComponent } from './containers/user-details-shell/user-details-shell.component';
import { EventCommentsModule } from '../../../../../iot4bos-ui/src/lib/components/event-comments/event-comments.module';
import { InfoDisplayModule, UserNameModule } from '@iot-platform/pipes';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    StoreModule.forFeature(fromUsers.adminUsersFeatureKey, fromUsers.reducers),
    EffectsModule.forFeature([AdminUsersEffects]),
    DateFormatModule,
    CardLoaderModule,
    MasterViewEngineModule,
    IotPlatformUiModule,
    GridEngineModule,
    EventCommentsModule,
    UserNameModule,
    InfoDisplayModule
  ],
  declarations: [AdminUsersComponent, UsersTableComponent, UserDetailComponent, UserDetailsShellComponent],
  exports: [AdminUsersComponent, UsersTableComponent],
  providers: [
    UsersService,
    AdminUsersEffects,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ]
})
export class AdminUsersModule {}
