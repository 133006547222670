<div *ngIf="gRoutes$" fxLayout="column">
  <section *ngFor="let dayRoute of gRoutes$ | async" class="day-section">
    <span class="main-route-section">
      <mat-checkbox (change)="setAll(dayRoute, $event.checked)"
                    [checked]="allSubRoutesChecked(dayRoute)"
                    [indeterminate]="someSubRoutesChecked(dayRoute)"
                    >
        {{dayRoute.day}}
      </mat-checkbox>
    </span>
    <span>
      <ul class="sub-route-section">
        <li *ngFor="let subRoute of dayRoute.routes">
          <mat-checkbox (change)="onRouteChanged($event, subRoute)"
                        [(ngModel)]="subRoute.checked">
            <span fxLayout="row" fxLayoutAlign="start center">
            <p>{{subRoute.layerLabel}}</p>
            <p [style.background-color]="subRoute.color" class="sub-route-color"></p>
            <p>{{ getDuration(subRoute) }}</p>
            </span>

          </mat-checkbox>

        </li>
      </ul>
    </span>
  </section>
</div>


