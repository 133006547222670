import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { ChipModule } from '../chip/chip.module';
import { ColorPickerModule } from '../color-picker/color-picker.module';
import { DetailPopupModule } from '../detail-popup/detail-popup.module';
import { FilterEngineModule } from '../filter-engine/filter-engine.module';
import { FavoriteViewFormComponent } from './favorite-view-form.component';
import { IotPlatformPipesModule } from '@iot-platform/pipes';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatRadioModule,
    MatCheckboxModule,
    MatChipsModule,
    MatIconModule,
    MatCardModule,
    MatToolbarModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    IotPlatformPipesModule,
    ChipModule,
    ColorPickerModule,
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    DetailPopupModule,
    MatTabsModule,
    MatSelectModule,
    FilterEngineModule,
    MatProgressSpinnerModule
  ],
  declarations: [FavoriteViewFormComponent],
  exports: [FavoriteViewFormComponent]
})
export class FavoriteViewFormModule {}
