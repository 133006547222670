import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { User } from '@iot-platform/models/common';
import { SortUtil } from '../../../../../shared/src/lib/utils/sort.util';

@Component({
  selector: 'iot4bos-backoffice-ui-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss', '../../style/admin.style.scss']
})
export class UsersListComponent implements OnInit, OnChanges {
  @Input() users: User[];

  @Output() selectUser: EventEmitter<User> = new EventEmitter<User>();

  sortedUsers: User[] = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('users')) {
      this.sortedUsers = [...changes.users.currentValue];
      this.sortedUsers.sort(SortUtil.sortByProperty('lastname'));
    }
  }

  onSelectUser(selected: User) {
    this.selectUser.emit(selected);
  }
}
