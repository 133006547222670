import { PlatformResponse } from '@iot-platform/models/common';
import { fromUserPreferences } from '@iot-platform/users';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAssetEventsDb from './asset-events-db.reducer';
import * as fromAssetEventsLogsDb from './asset-events-logs-db.reducer';
import * as fromAssetEventsLogsUi from './asset-events-logs-ui.reducer';
import * as fromAssetEventsUi from './asset-events-ui.reducer';

export const assetEventsFeatureKey = 'assetEvents';

export interface AssetEventsState {
  [fromAssetEventsDb.assetEventsDbFeatureKey]: fromAssetEventsDb.State;
  [fromAssetEventsUi.assetEventsUiFeatureKey]: fromAssetEventsUi.State;
  [fromAssetEventsLogsDb.assetEventsLogsDbFeatureKey]: fromAssetEventsLogsDb.State;
  [fromAssetEventsLogsUi.assetEventsLogsUiFeatureKey]: fromAssetEventsLogsUi.State;
}

export interface State {
  [assetEventsFeatureKey]: AssetEventsState;
}

export function reducers(state: AssetEventsState | undefined, action: Action) {
  return combineReducers({
    [fromAssetEventsDb.assetEventsDbFeatureKey]: fromAssetEventsDb.reducer,
    [fromAssetEventsUi.assetEventsUiFeatureKey]: fromAssetEventsUi.reducer,
    [fromAssetEventsLogsDb.assetEventsLogsDbFeatureKey]: fromAssetEventsLogsDb.reducer,
    [fromAssetEventsLogsUi.assetEventsLogsUiFeatureKey]: fromAssetEventsLogsUi.reducer
  })(state, action);
}

export const selectAssetEventsState = createFeatureSelector<AssetEventsState>(assetEventsFeatureKey);
const getUserPreferencesState = createFeatureSelector<fromUserPreferences.UserPreferencesState>(fromUserPreferences.userPreferencesFeatureKey);

export const selectAssetEventsDbState = createSelector(selectAssetEventsState, (state: AssetEventsState) => state[fromAssetEventsDb.assetEventsDbFeatureKey]);
export const selectAssetEventsUiState = createSelector(selectAssetEventsState, (state: AssetEventsState) => state[fromAssetEventsUi.assetEventsUiFeatureKey]);
export const selectAssetEventsLogsDbState = createSelector(
  selectAssetEventsState,
  (state: AssetEventsState) => state[fromAssetEventsLogsDb.assetEventsLogsDbFeatureKey]
);
export const selectAssetEventsLogsUiState = createSelector(
  selectAssetEventsState,
  (state: AssetEventsState) => state[fromAssetEventsLogsUi.assetEventsLogsUiFeatureKey]
);

export const {
  selectIds: getAssetEventsIds,
  selectEntities: getAssetEventsEntities,
  selectAll: getAllAssetEvents,
  selectTotal: getTotalAssetEvents
} = fromAssetEventsDb.adapter.getSelectors(selectAssetEventsDbState);

export const {
  selectIds: getLogsIds,
  selectEntities: getLogsEntities,
  selectAll: getAllLogs,
  selectTotal: getTotalLogs
} = fromAssetEventsLogsDb.adapter.getSelectors(selectAssetEventsLogsDbState);

export const getSelectedAssetEventId = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getSelectedId);

export const getSelectedAssetEvent = createSelector(
  getAssetEventsEntities,
  getSelectedAssetEventId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

// ***** DATA
export const getMVSettings = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getSettings);
export const getSite = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getSite);
export const getAsset = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getAsset);
export const getAssetVariable = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getAssetVariable);
export const getDevice = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getDevice);
export const getDeviceVariable = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getDeviceVariable);

export const getTags = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getTags);

export const getCheckedIds = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getCheckedIds);

export const getTableState = createSelector(
  selectAssetEventsDbState,
  getAllAssetEvents,
  getSelectedAssetEvent,
  getCheckedIds,
  (state, allEvents, selected, checkedIds) => {
    return { selected, checked: allEvents.filter((event) => checkedIds.find((c) => c === event.id)) };
  }
);

export const getLog = createSelector(selectAssetEventsLogsDbState, fromAssetEventsLogsDb.getLog);

export const getPagination = createSelector(selectAssetEventsDbState, getMVSettings, (state, settings) => {
  return settings && settings['masterViewTable']['bluePrint'].pageSize
    ? { ...state.pagination, limit: settings['masterViewTable']['bluePrint'].pageSize.toString() }
    : state.pagination;
});
export const getAutoRefresh = createSelector(getMVSettings, (settings) => {
  return settings ? settings['masterViewTable']['bluePrint'].autoRefresh : false;
});

export const getRefreshDelay = createSelector(getMVSettings, (settings) => {
  return settings ? settings['masterViewTable']['bluePrint'].refreshDelay : 120;
});
export const getStatus = createSelector(selectAssetEventsDbState, fromAssetEventsDb.getStatus);
export const getInitialSort = createSelector(selectAssetEventsDbState, (state: fromAssetEventsDb.State) => state.initialSort);

export const getFormattedAssetEvents = createSelector(getAllAssetEvents, getPagination, getInitialSort, (data, pagination, initialSort) => {
  const response: PlatformResponse = {
    data: data,
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total,
    initialSort
  };
  return response;
});

// ***** UI
export const getError = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getSiteLoaded);

export const getAssetEventsLoaded = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getAssetEventsLoaded);
export const getAssetEventsLoading = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getAssetEventsLoading);
export const getSiteLoaded = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getSiteLoaded);
export const getSiteLoading = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getSiteLoading);
export const getAssetLoaded = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getAssetLoaded);
export const getAssetLoading = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getAssetLoading);
export const getAssetVariableLoaded = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getAssetVariableLoaded);
export const getAssetVariableLoading = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getAssetVariableLoading);
export const getDeviceLoaded = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getDeviceLoaded);
export const getDeviceLoading = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getDeviceLoading);
export const getDeviceVariableLoaded = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getDeviceVariableLoaded);
export const getDeviceVariableLoading = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getDeviceVariableLoading);
export const getTagsLoaded = createSelector(selectAssetEventsUiState, fromAssetEventsUi.getTagsLoaded);
export const getLogsLoaded = createSelector(selectAssetEventsLogsUiState, fromAssetEventsLogsUi.getLogsLoaded);
