import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthorizationService, fromAuth } from '@iot-platform/auth';
import {
  DELETE_BUTTON_CONFIG,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  REFRESH_BUTTON_CONFIG
} from '@iot-platform/iot-platform-ui';
import { IotToolbarEvent, ToolbarSize } from '@iot-platform/models/common';
import { PoEventRule } from '@iot-platform/models/i4b';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { POEventRulesUiActions } from '../../+state/actions';
import * as fromPOEventRule from '../../+state/reducers';
import { AuthorizationConcept, AuthorizationType } from '../../../../../../../auth/src/lib/authorization.types';
import { PopupComponent } from '../../../../../../../iot-platform-ui/src/lib/ui/components/popup/popup.component';
import {
  PoEventConfigureFormComponent
} from '../../components/po-event-configure-form/po-event-configure-form.component';
import { PoEventCreateFormComponent } from '../../components/po-event-create-form/po-event-create-form.component';

@Component({
  selector: 'iot4bos-ui-po-event-details-shell',
  templateUrl: './po-event-details-shell.component.html',
  styleUrls: ['./po-event-details-shell.component.scss']
})
export class PoEventDetailsShellComponent implements OnInit, OnDestroy {
  selectedRule!: PoEventRule;

  // Privileges
  canUpdateRule = false;
  canDeleteRule = false;
  canReadEvent = false;
  canUpdateEvent = false;

  ruleDetailButtonList: IotToolbarDefaultButton[] = [];

  userPermissions: Array<{ key: string; value: boolean }> = [];
  toolbarSize = ToolbarSize.SMALL;

  destroy$: Subject<void> = new Subject();

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly authorizationService: AuthorizationService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.canUpdateRule = this.authorizationService.applyAuthorization(AuthorizationConcept.PO_EVENT_RULE, AuthorizationType.UPDATE);
    this.canDeleteRule = this.authorizationService.applyAuthorization(AuthorizationConcept.PO_EVENT_RULE, AuthorizationType.DELETE);
    this.canReadEvent = this.authorizationService.applyAuthorization(AuthorizationConcept.EVENT, AuthorizationType.READ);
    this.canUpdateEvent = this.authorizationService.applyAuthorization(AuthorizationConcept.EVENT, AuthorizationType.UPDATE);

    this.userPermissions = [
      { key: 'canUpdateRule', value: this.canUpdateRule },
      { key: 'canReadEvent', value: this.canReadEvent },
      { key: 'canUpdateEvent', value: this.canUpdateEvent }
    ];

    combineLatest([
      this.store.pipe(select(fromAuth.selectSelectedBusinessProfileForAccount)),
      this.store.pipe(select(fromAuth.selectAccount)),
      this.store.pipe(select(fromPOEventRule.selectSelectedPOEventRule))
    ])
      .pipe(
        takeUntil(this.destroy$),
        filter(([bp, user, selectedRule]) => !!bp && !!user),
        withLatestFrom(this.store.pipe(select(fromPOEventRule.selectSelectedPoEventRuleId)))
      )
      .subscribe(([[bp, user, selectedRule], selectedRuleId]) => {
        if (!selectedRule) {
          this.store.dispatch(
            POEventRulesUiActions.loadPOEventRule({ ruleToLoadId: selectedRuleId ?? (this.router.routerState.snapshot.url.split('/').pop() as string) })
          );
        }
      });
    this.store.pipe(select(fromPOEventRule.selectSelectedPOEventRule), takeUntil(this.destroy$)).subscribe((selectedRule) => {
      if (selectedRule) {
        this.selectedRule = selectedRule;
        this.initToolbarButtonList();
      }
    });
    this.initToolbarButtonList();
  }

  initToolbarButtonList(): void {
    this.ruleDetailButtonList = [
      new IotToolbarDefaultButton({ ...REFRESH_BUTTON_CONFIG, tooltip: 'PO_EVENTS.INFO_PAGE.REFRESH_BUTTON' }, 1),
      new IotToolbarDefaultButton({ ...DELETE_BUTTON_CONFIG, displayButton: this.canDeleteRule, disabled: this.selectedRule?.isActive }, 0)
    ];
  }

  onToolbarEvent(event: IotToolbarEvent): void {
    switch (event.type) {
      case IotToolbarDispatchActionType.REFRESH_PAGE:
        this.refreshRule();
        break;
      case IotToolbarDispatchActionType.DELETE_ELEMENT:
        this.deleteRule();
        break;
      default:
        break;
    }
  }

  onClickBack(): void {
    this.router.navigate(['po-event-rules']);
  }

  refreshRule(): void {
    this.store.dispatch(POEventRulesUiActions.loadPOEventRule({ ruleToLoadId: this.selectedRule.id as unknown as string }));
  }

  editRule(): void {
    this.dialog
      .open(PoEventCreateFormComponent, {
        width: '700px',
        data: { element: this.selectedRule }
      })
      .afterClosed()
      .subscribe((result: { action: string; rule: PoEventRule }) => {
        if (result && result.action === 'EDIT') {
          this.store.dispatch(POEventRulesUiActions.updatePOEventRule({ poEventRuleToUpdate: result.rule }));
        }
      });
  }

  configureRule(): void {
    this.dialog
      .open(PoEventConfigureFormComponent, {
        width: '900px',
        disableClose: true,
        data: { element: this.selectedRule }
      })
      .afterClosed()
      .subscribe((poEventRuleToConfigure: PoEventRule) => {
        if (poEventRuleToConfigure) {
          this.store.dispatch(POEventRulesUiActions.configurePOEventRule({ poEventRuleToConfigure }));
        }
      });
  }

  deleteRule(): void {
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        disableClose: true,
        data: { type: 'delete', value: this.selectedRule.name }
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.store.dispatch(POEventRulesUiActions.deletePOEventRule({ poEventRuleToDelete: this.selectedRule }));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
