import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { BuildInfoComponent } from '../build-info/build-info.component';
import { BuildInfoService } from '../build-info/services/build-info.service';
import { ReleaseNotesComponent } from './release-notes.component';
import { ReleaseNotesService } from './services/release-notes.service';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatCardModule,
    MatToolbarModule,
    TranslateModule
  ],
  providers: [ReleaseNotesService, BuildInfoService],
  declarations: [ReleaseNotesComponent, BuildInfoComponent],
  exports: [BuildInfoComponent, ReleaseNotesComponent]
})
export class ReleaseNotesModule {}
