import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ProgressBarButtonComponent } from './progress-bar-button.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatProgressBarModule, MatProgressSpinnerModule, MatIconModule, TranslateModule, MatTooltipModule],
  exports: [ProgressBarButtonComponent],
  declarations: [ProgressBarButtonComponent]
})
export class ProgressBarButtonModule {}
