import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceIccidService {
  constructor(@Inject('environment') private environment, private httpClient: HttpClient) {}

  getOperatorInfo(deviceId: string) {
    return this.httpClient.get(`${this.environment.api.url}/devices/${deviceId}${this.environment.api.endpoints.operatorInfo}`);
  }
}
