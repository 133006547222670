import { Injectable } from '@angular/core';
import { ApiSelector, Filter, Pagination } from '@iot-platform/models/common';
import { Product } from '@iot-platform/models/oyan';
import * as fromMain from '../reducers';
import * as fromProducts from '../reducers/products.reducer';

@Injectable({
  providedIn: 'root'
})
export class ProductsSelectors extends ApiSelector<fromMain.State, Product, Pagination, Filter> {
  constructor() {
    super(fromMain.featureKey, fromProducts.featureKey, fromProducts.adapter);
  }
}
