import { Injectable } from '@angular/core';
import { ApiSelector, Filter, Pagination } from '@iot-platform/models/common';
import { Gateway } from '@iot-platform/models/oyan';
import * as fromMain from '../reducers';
import * as fromGateways from '../reducers/gateways.reducer';

@Injectable({
  providedIn: 'root'
})
export class GatewaysSelectors extends ApiSelector<fromMain.State, Gateway, Pagination, Filter> {
  constructor() {
    super(fromMain.featureKey, fromGateways.featureKey, fromGateways.adapter);
  }
}
