import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Device } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-site-detail-devices',
  templateUrl: './site-detail-devices.component.html',
  styleUrls: ['./site-detail-devices.component.scss']
})
export class SiteDetailDevicesComponent implements OnInit {
  @Input() totalDevices: number = 0;

  @Input() isDeviceLoaded: boolean = false;

  @Output() selectDevice: EventEmitter<Device> = new EventEmitter<Device>();

  @Input() devices: Device[] = [];

  constructor() {}

  ngOnInit(): void {}
}
