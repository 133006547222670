import { CommonApiRequest, CommonApiResponse, CommonGenericModel, CommonIndexedPagination } from '@iot-platform/models/common';

export const transformApiRequest = (request: CommonApiRequest): CommonApiRequest => {
  const req = { ...request };
  if (Object.prototype.hasOwnProperty.call(request, 'page')) {
    req.page = request.page + 1;
  }
  return req;
};

export function transformApiResponse<T extends CommonGenericModel>(
  response: CommonApiResponse<T, CommonIndexedPagination>
): CommonApiResponse<T, CommonIndexedPagination> {
  return {
    ...response,
    pagination: {
      ...response.pagination,
      currentPage: response.pagination.currentPage - 1
    }
  };
}
