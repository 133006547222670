<div class="sticky">
  <div
    (click)="onClickBack()"
    class="origin"
    fxLayoutAlign="center center"
    matTooltipClass="regular-tooltip"
  >
    <mat-icon>keyboard_backspace</mat-icon>
  </div>
  <iot-platform-ui-toolbar-v2
    (dispatchToolbarEvent)="onToolbarEvent($event)"
    [breadCrumbConfiguration]="{ entityName: '', icon: 'person' }"
    [buttonList]="userDetailsButtonList"
    [name]="(userLabel | async)"
    [size]="ToolbarSize.SMALL"
    fxFlex="100"
  >
  </iot-platform-ui-toolbar-v2>

</div>

<mat-sidenav-container style="height: 100%">
    <mat-tab-group #matTabGroup color="accent" mat-align-tabs="center">
      <mat-tab [label]="'USERS.TABS.INFO' | translate | uppercase" fxLayoutAlign="start start">
        <ng-template matTabContent>
          <div class="topic-details-shell-tab-container" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
              <span class="fw-600 topic-info-section-title">{{ 'USERS.PROFILE_INFO.PERSONAL_INFO' | translate | uppercase}}</span>

              <hr class="regular-1px-line" fxFlex />
            </div>

            <div *ngIf="selectedUser$ | async as user" class="topic-info-section-content" fxLayout="row" fxLayoutAlign="start start">
              <div data-hj-suppress fxFlex="50" fxLayout="column" fxLayoutGap="10px">
                <iot-platform-ui-detail-popup-display-property
                  [property]="'USERS.PROFILE_INFO_FORM.FIRSTNAME' | translate"
                  [value]="user.firstname | infoDisplay"
                ></iot-platform-ui-detail-popup-display-property>
                <iot-platform-ui-detail-popup-display-property
                  [property]="'USERS.PROFILE_INFO_FORM.LASTNAME' | translate"
                  [value]="user.lastname | infoDisplay"
                ></iot-platform-ui-detail-popup-display-property>
              </div>

              <div data-hj-suppress fxFlex="50" fxLayout="column" fxLayoutGap="10px">
                <iot-platform-ui-detail-popup-display-property
                  [property]="'USERS.PROFILE_INFO_FORM.PRIMARY_EMAIL' | translate"
                  [value]="user.email | infoDisplay"
                ></iot-platform-ui-detail-popup-display-property>
                <iot-platform-ui-detail-popup-display-property
                  [property]="'USERS.PROFILE_INFO_FORM.SECONDARY_EMAIL' | translate"
                  [value]="user.secondaryEmail | infoDisplay"
                ></iot-platform-ui-detail-popup-display-property>
                <iot-platform-ui-detail-popup-display-property
                  [property]="'USERS.PROFILE_INFO_FORM.PHONE_NUMBER' | translate"
                  [value]="user.phoneNumber | infoDisplay"
                ></iot-platform-ui-detail-popup-display-property>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
              <span class="fw-600 topic-info-section-title">{{ 'USERS.PROFILE_INFO.ACCOUNT_INFO' | translate | uppercase}}</span>
              <span
                *ngIf="selectedUser$ | async as user"
                [ngClass]="{
                  'capitalize-first-letter': true,
                  'user-details-user-active': user.status?.toLowerCase() === 'active',
                  'user-details-user-pending': user.status?.toLowerCase() === 'pending',
                  'user-details-user-disabled': user.status?.toLowerCase() === 'disabled',
                  'user-details-user-unconfirmed': user.status?.toLowerCase() === 'unconfirmed'
                }"
              >{{ 'ADMIN.USERS.TABLE.STATUS_LIST.' + user.status | translate }}</span
              >
              <hr class="regular-1px-line" fxFlex />
            </div>
            <div *ngIf="selectedUser$ | async as user" class="topic-info-section-content" fxLayout="row" fxLayoutAlign="start start">
              <div data-hj-suppress fxFlex="50" fxLayout="column" fxLayoutGap="10px">
                <iot-platform-ui-detail-popup-display-property
                  property="{{ 'IOT_DICTIONARY.ENTITIES' | translate }}:"
                  value="{{ getFormattedEntities(user.entities) }}"
                ></iot-platform-ui-detail-popup-display-property>
                <iot-platform-ui-detail-popup-display-property
                  [property]="'ADMIN.USERS.TABLE.ACCOUNT_TYPE' | translate"
                  [value]="getAccountType(user.isShared)| translate"
                ></iot-platform-ui-detail-popup-display-property>
                <iot-platform-ui-detail-popup-display-property
                  [property]="'IOT_DICTIONARY.LAST_ACTIVITY' | translate"
                  [value]="user.lastActivity | dateFormat"
                ></iot-platform-ui-detail-popup-display-property>
              </div>
              <div data-hj-suppress fxFlex="50  " fxLayout="column" fxLayoutGap="10px">
                <iot-platform-ui-detail-popup-display-property
                  [property]="'IOT_DICTIONARY.CREATED_AT' | translate"
                  [value]="user.createdAt | dateFormat"
                ></iot-platform-ui-detail-popup-display-property>
                <iot-platform-ui-detail-popup-display-property
                  [property]="'IOT_DICTIONARY.UPDATED_AT' | translate"
                  [value]="user.updatedAt ? (user.updatedAt | dateFormat) : '-'"
                ></iot-platform-ui-detail-popup-display-property>
                <iot-platform-ui-detail-popup-display-property
                  [property]="'IOT_DICTIONARY.UPDATED_BY' | translate"
                  [value]="user.updatedBy | userName"
                ></iot-platform-ui-detail-popup-display-property>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
              <iot-platform-ui-detail-popup-section-header
                [headerTitle]="'USERS.PROFILE_PREFERENCES.TITLE' | translate | uppercase"
                fxFlex
              ></iot-platform-ui-detail-popup-section-header>
            </div>
            <div class="topic-info-section-content" fxLayout="row" fxLayoutAlign="start start">
              <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
                <iot-platform-ui-detail-popup-display-property
                  [property]="'USERS.PROFILE_PREFERENCES.LANGUAGE' | translate"
                  [value]="'USERS.PROFILE_PREFERENCES.LANGUAGES.' + (selectedUser$ | async)?.preferences?.appLanguage | translate"
                >
                </iot-platform-ui-detail-popup-display-property>
                <iot-platform-ui-detail-popup-display-property
                  [property]="'USERS.PROFILE_PREFERENCES.DATE_FORMAT' | translate"
                  [value]="today | dateFormat: (selectedUser$ | async)?.preferences?.appDateFormats?.momentFullFormat ?? defaultAppDateFormat | translate"
                ></iot-platform-ui-detail-popup-display-property>

              </div>

              <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
                <iot-platform-ui-detail-popup-display-property
                  [property]="'USERS.PROFILE_PREFERENCES.NUMBER_FORMAT' | translate"
                  [value]="appNumberFormat | async | translate"
                ></iot-platform-ui-detail-popup-display-property>
                <iot-platform-ui-detail-popup-display-property
                  [property]="'USERS.PROFILE_PREFERENCES.NUMBER_OF_DECIMALS' | translate"
                  [value]="(selectedUser$ | async)?.preferences?.appNumberOfDecimals ?? defaultAppNumberOfDecimal"
                ></iot-platform-ui-detail-popup-display-property>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
              <iot-platform-ui-detail-popup-section-header
                [headerTitle]="'USERS.PROFILE_PREFERENCES.NOTIFICATIONS' | translate | uppercase"
                fxFlex
              ></iot-platform-ui-detail-popup-section-header>
            </div>
            <div *ngIf="selectedUser$ | async as user" class="topic-info-section-content" fxLayout="row" fxLayoutAlign="start start">
              <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
                <iot-platform-ui-detail-popup-display-property
                  [property]="'USERS.PROFILE_PREFERENCES.NOTIFICATIONS' | translate"
                  [value]="(notifications$ | async)"
                ></iot-platform-ui-detail-popup-display-property>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
</mat-sidenav-container>
