import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IotToolbarDispatchActionType } from '@iot-platform/iot-platform-ui';
import {
  CommonApiRequest,
  Filter,
  IotToolbarEvent,
  MasterViewEngineEvent,
  PlatformResponse
} from '@iot-platform/models/common';
import { Receiver, Ward } from '@iot-platform/models/oyan';
import { SortUtil } from '../../../../../../../shared/src/lib/utils/sort.util';
import { BaseShellComponent } from '../../../../components/base-shell/base-shell.component';
import { OyanUiRoutingConstants } from '../../../../oyan-ui.router.constants';
import { AuthFacade } from '../../../auth/state/facades/auth.facade';
import { NavigationsFacade } from '../../../navigations/state/facades/navigations.facade';
import { ReceiversFacade } from '../../state/facades/receivers.facade';

/**
 * Business rules

 * The inactive items will be displayed first on the page
 * Status “Inactive” will be displayed in RED.
 */
@Component({
  selector: 'oyan-ui-receivers-shell',
  templateUrl: './receivers-shell.component.html',
  styleUrls: ['./receivers-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReceiversShellComponent extends BaseShellComponent<Receiver> {
  constructor(protected authFacade: AuthFacade, protected receiversFacade: ReceiversFacade, private readonly navigationsFacade: NavigationsFacade) {
    super(authFacade, receiversFacade);
  }

  reLoadMasterView(page: number = this.pagination$.value.currentPage): void {
    const filters: Filter[] = this.currentFilters;
    if (!filters.find((f) => f.criteriaKey === 'entityId' && f.value === this.businessProfile.entityId)) {
      // This filter should be excluded to prevent displayed in the filter engine
      filters.push({ criteriaKey: 'entityId', value: this.businessProfile.entityId, excluded: true });
    }
    const request: CommonApiRequest = {
      page,
      limit: this.pagination$.value.limit,
      filters
    };
    this.receiversFacade.getAll(request);
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    switch (event.type) {
      case 'onSiteClickEvent':
        this.navigationsFacade.goToSiteOverview(event.rawData.site, OyanUiRoutingConstants.RECEIVERS);
        break;
      case 'onLastMessageClickEvent':
        this.navigationsFacade.goToCommunicationViewFromReceivers(event.rawData as Receiver);
        break;
      case 'onAssetsClickEvent': {
        this.navigationsFacade.goToAssetsFromReceivers(event.rawData.ward as Ward);
        break;
      }
      default:
        break;
    }
  }

  onToolbarEvent(event: IotToolbarEvent): void {
    super.onToolbarEvent(event);
    if (event.type === IotToolbarDispatchActionType.FILTER_TEXT_CHANGE) {
      this.onFilterTextChange(event.options);
    }
  }

  onKerlinkClick(): void {
    this.navigationsFacade.openKerlink();
  }

  sortData(platformResponse: PlatformResponse): PlatformResponse {
    return {
      ...platformResponse,
      data: platformResponse.data.sort(SortUtil.sortByProperty('connected'))
    };
  }

  private onFilterTextChange(searchString: string): void {
    this.subscriptions.push(
      this.receiversFacade.all$.subscribe((receivers: Receiver[]) => {
        let filteredList: Receiver[] = receivers ? [...receivers] : [];
        if (searchString !== null) {
          filteredList = filteredList.filter((receiver: Receiver) => {
            return (
              `${receiver.devEui}`.toLowerCase().includes(searchString.toLowerCase()) ||
              (receiver.site &&
                (`${receiver.site.displayName}`.toLowerCase().includes(searchString.toLowerCase()) ||
                  `${receiver.site.name}`.toLowerCase().includes(searchString.toLowerCase())))
            );
          });
        }
        const platformResponse = {
          ...this.platformResponse$.value,
          data: filteredList
        };
        this.platformResponse$.next(this.sortData(platformResponse));
        this.totalItems$.next(filteredList.length);
      })
    );
  }
}
