import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from '@iot-platform/iot-platform-ui';
import { UserPreferences } from '@iot-platform/models/common';
import { fromUserPreferences } from '@iot-platform/users';
import { Store } from '@ngrx/store';
import { PreferencesActions } from 'libs/users/src/lib/features/preferences/state/actions';
import { UserProfileService } from 'libs/users/src/lib/features/profile/services/user-profile.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'oyan-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {
  private preferences$: Observable<UserPreferences> = this.store.select(fromUserPreferences.getPreferences);

  constructor(private store: Store, private userProfileService: UserProfileService, private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    super();
    this.iconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/iot4bos-icon-set.svg'));
    this.iconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/oyan-icon-set.svg'));
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.preferences$.subscribe((preferences: UserPreferences) => {
        if (preferences) {
          this.userProfileService.setThemeOverlay(preferences.appTheme);
          this.store.dispatch(PreferencesActions.changeLanguage({ lang: preferences.appLanguage }));
        }
      })
    );
  }
}
