import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(public eventName: string = 'i4b_events') {}

  log(category: string, label: string, value?: string) {
    gtag('event', this.eventName, {
      event_category: category,
      event_label: label,
      value: value,
      non_interaction: true
    });
  }

  logError(category: string, label: string, value?: string) {
    gtag('event', this.eventName, {
      event_category: category,
      event_label: label,
      value: value,
      non_interaction: true
    });
  }
}
