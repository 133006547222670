import { Action, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../../auth/src/lib/state/actions';
import { AssetEventsByTopicDbActions, AssetEventsByTopicUiActions } from '../../actions';
//
export const assetEventsByTopicUiFeatureKey = 'assetEventsByTopicUi';

export interface State {
  error?: any;
  assetEventsLoaded: boolean;
  assetEventsLoading: boolean;
  totalActiveEventsLoaded: boolean;
}

export const initialState: State = {
  error: null,
  assetEventsLoaded: false,
  assetEventsLoading: false,
  totalActiveEventsLoaded: false
};

const assetEventsByTopicUiReducer = createReducer(
  initialState,
  on(AssetEventsByTopicUiActions.loadAssetEventsByTopic, (state: State) => ({ ...state, assetEventsLoading: true, assetEventsLoaded: false })),
  on(AssetEventsByTopicDbActions.loadAssetEventsByTopicSuccess, (state: State) => ({ ...state, assetEventsLoading: false, assetEventsLoaded: true })),
  on(AssetEventsByTopicDbActions.loadAssetEventsByTopicFailure, (state: State, { error }) => ({
    ...state,
    assetEventsLoading: false,
    assetEventsLoaded: false,
    error
  })),
  // ****
  on(AssetEventsByTopicUiActions.loadTotalActiveAssetEventsByTopic, (state: State) => ({ ...state, totalActiveEventsLoaded: false })),
  on(AssetEventsByTopicDbActions.loadTotalActiveAssetEventsByTopicSuccess, (state: State) => ({ ...state, totalActiveEventsLoaded: true })),
  on(AssetEventsByTopicDbActions.loadTotalActiveAssetEventsByTopicFailure, (state: State, { error }) => ({ ...state, totalActiveEventsLoaded: false, error })),
  // ****
  on(AssetEventsByTopicUiActions.bulkUpdateStatusByAssetEventIdByTopic, (state: State) => ({ ...state, assetEventsLoaded: false })),
  on(AssetEventsByTopicDbActions.updateStatusByAssetEventIdByTopicSuccess, (state: State) => ({ ...state, assetEventsLoaded: true })),
  on(AssetEventsByTopicDbActions.updateStatusByAssetEventIdByTopicFailure, (state: State, { error }) => ({ ...state, assetEventsLoaded: false, error })),
  // ****
  on(AssetEventsByTopicUiActions.loadMvAssetEventsByTopicSettings, (state: State) => ({ ...state, assetEventsLoading: true, assetEventsLoaded: false })),
  on(AssetEventsByTopicDbActions.loadMvAssetEventsByTopicSettingsSuccess, (state: State) => ({ ...state, assetEventsLoading: false, assetEventsLoaded: true })),
  on(AssetEventsByTopicDbActions.loadMvAssetEventsByTopicSettingsFailure, (state: State, { error }) => ({
    ...state,
    assetEventsLoading: false,
    assetEventsLoaded: false,
    error
  })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsByTopicUiReducer(state, action);
}

export const getAssetEventsLoaded = (state: State) => state.assetEventsLoaded;
export const getAssetEventsLoading = (state: State) => state.assetEventsLoading;
export const getTotalActiveEventsLoaded = (state: State) => state.totalActiveEventsLoaded;
