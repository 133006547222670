import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LastValue, PlatformRequest, PlatformResponse, Record } from '@iot-platform/models/common';

import {
  Asset,
  AssetTemplateVariable,
  AssetVariable,
  AssetVariableAlgo,
  AssetVariableAlgoPayload,
  AssetVariableThresholdRecord,
  Device,
  DeviceVariable,
  Formula,
  I4BBulkOperationApiResponse
} from '@iot-platform/models/i4b';
import { get } from 'lodash';

import { EMPTY, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormulaType } from '../../../../iot4bos-ui/src/lib/features/assets/components/asset-variable-configure-form/formula-type.enum';

@Injectable({
  providedIn: 'root'
})
export class AssetVariablesService {
  formula$: Subject<Formula> = new Subject<Formula>();
  formulaResult$: Subject<LastValue[]> = new Subject<LastValue[]>();
  formulaResultForTemplateConfiguration$: Subject<{ estimationRecord?: LastValue[]; assetVariable: AssetTemplateVariable; error?: Error }> = new Subject();
  assetVariableData$: Subject<{ formula: Formula; lastRecordsCalculated: LastValue[] }> = new Subject<{
    formula: Formula;
    lastRecordsCalculated: LastValue[];
  }>();

  constructor(@Inject('environment') private readonly environment: any, public httpClient: HttpClient) {}

  getAll(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient
      .get<AssetVariable[]>(`${this.environment.api.url}${this.environment.api.endpoints.assets}/${request.urlParam}/variables`, { params })
      .pipe(
        map((data: any) => {
          return {
            data: data.content,
            currentPage: data.page.curPage,
            hasMore: data.page.hasMore,
            limit: data.page.limit,
            maxPage: data.page.maxPage,
            total: data.page.total
          };
        })
      );
  }

  getAssetsBySiteId(siteId: string, limit = 100, page = 0): Observable<Asset[]> {
    return this.httpClient
      .get<Asset[]>(`${this.environment.api.url + this.environment.api.endpoints.assets}?siteId=${siteId}&limit=${limit}&page=${page}`)
      .pipe(map((results: any) => results.content));
  }

  getManyByAssetId(assetId: string, limit = 100, page = 0): Observable<AssetVariable[]> {
    return this.httpClient
      .get<AssetVariable[]>(`${this.environment.api.url + this.environment.api.endpoints.assets}/${assetId}/variables?limit=${limit}&page=${page}`)
      .pipe(map((data: any) => data.content));
  }

  getDevicesBySiteId(siteId: string, limit = 100, page = 0): Observable<Device[]> {
    return this.httpClient
      .get<Device[]>(`${this.environment.api.url + this.environment.api.endpoints.devices}?siteId=${siteId}&limit=${limit}&page=${page}`)
      .pipe(map((results: any) => results.content));
  }

  getDeviceVariablesByDeviceId(deviceId: string, limit = 3000, page = 0): Observable<DeviceVariable[]> {
    if (!deviceId) {
      return EMPTY;
    }
    return this.httpClient
      .get<any>(`${this.environment.api.url + this.environment.api.endpoints.devices}/${deviceId}/variables?limit=${limit}&page=${page}`)
      .pipe(map((data: any) => data.content));
  }

  getUnits(): Observable<Array<{ name: string; units: string[] }>> {
    return this.httpClient.get<any>(this.environment.api.url + this.environment.api.endpoints.assetVariablesUnits);
  }

  post(assetVariable: Partial<AssetVariable>): Observable<AssetVariable> {
    return this.httpClient.post<AssetVariable>(`${this.environment.api.url}${this.environment.api.endpoints.assetVariables}`, assetVariable);
  }

  put(assetVariable: AssetVariable): Observable<AssetVariable> {
    return this.httpClient.put<AssetVariable>(`${this.environment.api.url}${this.environment.api.endpoints.assetVariables}/${assetVariable.id}`, assetVariable);
  }

  delete(assetVariable: AssetVariable): Observable<AssetVariable> {
    return this.httpClient
      .delete<any>(this.environment.api.url + this.environment.api.endpoints.assetVariables + '/' + assetVariable.id)
      .pipe(map(() => assetVariable));
  }

  bulkDelete(assetVariables: AssetVariable[]): Observable<AssetVariable>[] {
    return assetVariables.map((assetVariable) => this.delete(assetVariable));
  }

  deleteAssetVariables(assetVariables: AssetVariable[]): Observable<I4BBulkOperationApiResponse> {
    return this.httpClient.post<I4BBulkOperationApiResponse>(`${this.environment.api.url}${this.environment.api.endpoints.deleteAssetsVariables}`, {
      variablesIds: assetVariables.map((variable) => variable.id)
    });
  }

  getDeviceVariableById(id: string): Observable<DeviceVariable> {
    return this.httpClient.get<DeviceVariable>(`${this.environment.api.url + this.environment.api.endpoints.deviceVariables}/${id}`);
  }

  getDeviceById(id: string): Observable<Device | null> {
    if (!id) {
      return of(null);
    }
    return this.httpClient.get<Device>(`${this.environment.api.url + this.environment.api.endpoints.devices}/${id}`);
  }

  getAssetVariableById(id: string): Observable<AssetVariable> {
    return this.httpClient.get<AssetVariable>(`${this.environment.api.url + this.environment.api.endpoints.assetVariables}/${id}`);
  }

  getAssetById(id: string): Observable<Asset> {
    return this.httpClient.get<Asset>(`${this.environment.api.url + this.environment.api.endpoints.assets}/${id}`);
  }

  calculateFormulaResult(data: { formula: Formula; records: Record[] }): void {
    this.httpClient.post(this.environment.api.url + this.environment.api.endpoints.assetVariablesCalculator, data).subscribe(
      (result: LastValue[]) => this.formulaResult$.next(result),
      () => this.formulaResult$.next(undefined)
    );
  }

  calculateFormulaResultForTemplate(data: { formula: any; records: Record[] }, assetVariable: AssetTemplateVariable): void {
    this.httpClient.post(this.environment.api.url + this.environment.api.endpoints.assetVariablesCalculator, data).subscribe(
      (result: LastValue[]) => this.formulaResultForTemplateConfiguration$.next({ estimationRecord: result, assetVariable }),
      (error) => this.formulaResultForTemplateConfiguration$.next({ error, assetVariable })
    );
  }

  getGasList(): Observable<Array<{ key: string; value: number }>> {
    return this.httpClient.get<Array<{ key: string; value: number }>>(
      `${this.environment.api.url + this.environment.api.endpoints.assetVariables}` + '/densities'
    );
  }

  getAssetVariableAlgosByFormulaType(formulaType: FormulaType): Observable<AssetVariableAlgo[]> {
    return this.httpClient.get<AssetVariableAlgo[]>(`${this.environment.api.url + this.environment.api.endpoints.thresholds}/algos?formulas=${formulaType}`);
  }

  calculateDynamicThreshold(assetVariable: AssetVariable, algo: AssetVariableAlgo): Observable<AssetVariableThresholdRecord> {
    const payload: AssetVariableAlgoPayload = {
      name: algo.name,
      parameters: {
        asset: {
          id: get(assetVariable, 'asset.id')
        },
        assetVariableId: get(assetVariable, 'id'),
        revaluationOffsetInDays: 30
      }
    };
    return this.httpClient.post<AssetVariableThresholdRecord>(`${this.environment.api.url + this.environment.api.endpoints.thresholds}/calculator`, payload);
  }
}
