import { CommonApiRequest, CommonApiResponse, CommonIndexedPagination, Filter } from '@iot-platform/models/common';
import { Site } from '@iot-platform/models/oyan';
import { createAction, props } from '@ngrx/store';

export const loadSites = createAction('[OYAN SITE] Load Sites', props<{ request: CommonApiRequest }>());
export const loadSitesSuccess = createAction('[OYAN SITE] Load Sites Success', props<{ response: CommonApiResponse<Site, CommonIndexedPagination> }>());
export const loadSitesFailure = createAction('[OYAN SITE] Load Sites Failure', props<{ error: unknown }>());

export const loadSiteById = createAction('[OYAN SITE] Load Site By Id', props<{ id: string }>());
export const loadSiteByIdSuccess = createAction('[OYAN SITE] Load Site By Id Success', props<{ response: Site }>());
export const loadSiteByIdFailure = createAction('[OYAN SITE] Load Site By Id Failure', props<{ error: unknown }>());

export const addSite = createAction('[OYAN SITE] Add site', props<{ site: Site }>());
export const addSiteSuccess = createAction('[OYAN SITE] Add site Success', props<{ response: Site }>());
export const addSiteFailure = createAction('[OYAN SITE] Add site Failure', props<{ error: unknown }>());

export const updateSite = createAction('[OYAN SITE] Update site', props<{ site: Site }>());
export const updateSiteSuccess = createAction('[OYAN SITE] Update site Success', props<{ response: Site }>());
export const updateSiteFailure = createAction('[OYAN SITE] Update site Failure', props<{ error: unknown }>());

export const deleteSite = createAction('[OYAN SITE] Delete site', props<{ site: Site }>());
export const deleteSiteSuccess = createAction('[OYAN SITE] Delete site Success', props<{ response: Site }>());
export const deleteSiteFailure = createAction('[OYAN SITE] Delete site Failure', props<{ error: unknown }>());

export const setActiveSite = createAction('[OYAN SITE] Set Active Site', props<{ site: Site }>());

export const setFilters = createAction('[OYAN SITE] Set Filters', props<{ filters: Filter[] }>());
