<mat-toolbar class="breadcrumb" color="primary" fxLayoutAlign="start center">
  <div class="breadcrumb__back-button" *ngIf='!backButtonHidden' fxFlex fxLayoutAlign="center center" (click)="back?.emit(data);">
    <mat-icon>keyboard_backspace</mat-icon>
  </div>
  <div class='breadcrumb__item' [ngClass]="{ 'breadcrumb__item--passive': passive }" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <ng-container *ngIf="!loading">
      <mat-icon [svgIcon]="icon" color="accent"></mat-icon>
      <div fxLayout="column">
        <span class="breadcrumb__item-label" fxLayoutAlign="start center">{{ label }}</span>
        <span class="breadcrumb__item-title" fxLayoutAlign="start center">{{ title }}</span>
      </div>
    </ng-container>
    <mat-progress-spinner color='accent' *ngIf="loading" mode='indeterminate' [diameter]='20'></mat-progress-spinner>
  </div>
  <span fxFlex></span>
  <ng-content [breadcrumpActions]></ng-content>
</mat-toolbar>
