import { CommonApiRequest, CommonApiResponse, CommonIndexedPagination } from '@iot-platform/models/common';
import { Receiver, Site, Ward } from '@iot-platform/models/oyan';
import { createAction, props } from '@ngrx/store';

export const loadWardsBySite = createAction('[OYAN SITE/WARDS] Load wards by site', props<{ request: CommonApiRequest }>());
export const loadWardsBySiteSuccess = createAction(
  '[OYAN SITE/WARDS] Load Wards By Site Success',
  props<{ response: CommonApiResponse<Ward, CommonIndexedPagination> }>()
);
export const loadWardsBySiteFailure = createAction('[OYAN SITE/WARDS] Load Wards By Site Failure', props<{ error: unknown }>());

export const addReceiverToWard = createAction('[OYAN SITE/WARDS] Add receiver to ward', props<{ receiver: Receiver; ward: Ward; site: Site }>());
export const addReceiverToWardSuccess = createAction('[OYAN SITE/WARDS] Add receiver to ward Success', props<{ ward: Ward; receiver: Receiver }>());
export const addReceiverToWardFailure = createAction('[OYAN SITE/WARDS] Add receiver to ward Failure', props<{ error: unknown }>());

export const removeReceiverFromWard = createAction('[OYAN SITE/WARDS] Remove receiver from ward', props<{ receiver: Receiver; ward: Ward; site: Site }>());
export const removeReceiverFromWardSuccess = createAction('[OYAN SITE/WARDS] Remove receiver from ward Success', props<{ ward: Ward; receiver: Receiver }>());
export const removeReceiverFromWardFailure = createAction('[OYAN SITE/WARDS] Remove receiver from ward Failure', props<{ error: unknown }>());
