import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { FavoriteView, Filter } from '@iot-platform/models/common';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { FilterComponentFactory } from './filter-component-factory';
import { FilterEngineDirective } from './filter-engine.directive';
import { FilterEngineService } from './filter-engine.service';
import { FilterComponent } from './filter.component';

@Component({
  selector: 'iot-platform-ui-filter-engine',
  templateUrl: './filter-engine.component.html',
  styleUrls: ['./filter-engine.component.scss']
})
export class FilterEngineComponent implements OnInit, OnChanges, OnDestroy {
  categories: any;
  currentFavoriteView: FavoriteView | null = null;

  filterCriteriaButtonDefaultTitle = 'FILTER_ENGINE.FILTER_CRITERIA_DEFAULT_TITLE';
  filterCriteriaButtonTitle!: string;
  subs: Subscription[] = [];

  currentFilters: Filter[] = [];
  currentFiltersNotHidden: Filter[] = [];
  maxFilters = 20;
  disableFavoriteViewButton = true;

  @Input() masterView!: string;
  @Input() expanded: boolean = false;
  @Input() readonly: boolean = false;
  @Input() displayActionButtons: boolean = false;
  @Input() clearAppliedFilters$!: Observable<boolean>;
  @Input() currentFavoriteView$!: Observable<FavoriteView>;
  @Input() currentFilters$!: Observable<Filter[]>;

  @Output() applyFilters: EventEmitter<Filter[]> = new EventEmitter<Filter[]>();

  @ViewChild(FilterEngineDirective, { static: true }) iotPlatformUiFilterEngine!: FilterEngineDirective;

  constructor(protected readonly filterEngineService: FilterEngineService, protected readonly filterComponentFactory: FilterComponentFactory) {}

  ngOnInit() {
    this.resetFilterCriteriaButtonTitle();

    this.subs.push(
      this.currentFavoriteView$.subscribe((fV: FavoriteView) => {
        this.currentFavoriteView = fV;
        if (fV) {
          this.onClearAppliedFilters();
          this.currentFilters = [...fV.filters];
          this.setCurrentFiltersNotHidden();
        }
      }),

      this.currentFilters$.subscribe((filters: Filter[]) => {
        if (filters) {
          this.currentFilters = [...filters];
          this.setCurrentFiltersNotHidden();
        }
      })
    );

    if (this.clearAppliedFilters$) {
      this.clearAppliedFilters$.subscribe((event) => {
        if (event) {
          this.onClearAppliedFilters();
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.masterView?.currentValue) {
      this.filterEngineService.getFilterCriteriaByConcept(changes.masterView.currentValue).subscribe((categories) => {
        this.categories = categories;
        this.onClearAppliedFilters();
      });
    }
  }

  setCurrentFiltersNotHidden(): void {
    this.currentFiltersNotHidden = this.currentFilters.filter((f) => !f.isHidden && f.criteriaKey !== 'includeSubEntities');
  }

  displayResetButton(): boolean {
    if (this.currentFilters && this.currentFavoriteView) {
      const array1 = JSON.stringify(this.currentFilters.sort());
      const array2 = JSON.stringify(this.currentFavoriteView.filters.sort());

      return array1 !== array2;
    } else {
      return false;
    }
  }

  resetFilterCriteriaButtonTitle(): void {
    this.filterCriteriaButtonTitle = this.filterCriteriaButtonDefaultTitle;
  }

  public initViewContainerRef(): ViewContainerRef {
    const viewContainerRef = this.iotPlatformUiFilterEngine.viewContainerRef;
    viewContainerRef.clear();
    return viewContainerRef;
  }

  addField(criteria: any) {
    this.filterCriteriaButtonTitle = criteria.fullLabel;
    const componentRef: ComponentRef<FilterComponent> | null = this.filterComponentFactory.createComponent(criteria.element, this.initViewContainerRef());

    if (componentRef) {
      componentRef.instance.data = criteria.options;
      componentRef.instance.currentFiltersSize = this.currentFiltersNotHidden.length;
      componentRef.instance.maxFilters = this.maxFilters;

      if (criteria.options.multiSelect) {
        if (criteria.key === 'eventType' && criteria.options.criteriaKey === 'algo') {
          componentRef.instance.currentFilters$ = this.currentFilters$.pipe(map((filters) => filters.filter((filter) => filter.criteriaKey === 'algo')));
        } else if (criteria.key === 'eventStatus' && criteria.options.criteriaKey === 'isActive') {
          componentRef.instance.currentFilters$ = this.currentFilters$.pipe(map((filters) => filters.filter((filter) => filter.criteriaKey === 'isActive')));
        } else if (criteria.key === 'timezone' && criteria.options.criteriaKey === 'teamPlanningTimezoneDetailsName') {
          componentRef.instance.currentFilters$ = this.currentFilters$.pipe(
            map((filters) => filters.filter((filter) => filter.criteriaKey === 'teamPlanningTimezoneDetailsName'))
          );
        } else {
          componentRef.instance.currentFilters$ = this.currentFilters$.pipe(
            map((filters) => filters.filter((filter) => filter.criteriaKey === criteria.options.criteriaKey))
          );
        }
      }

      componentRef.instance.dispatchFilterValue.subscribe((componentOutput: Filter | Filter[]) => {
        if (componentOutput) {
          if (componentOutput instanceof Array) {
            componentOutput.forEach((filter: Filter) => {
              this.checkFilterDuplicate(filter, criteria.options.multiSelect ?? false);
            });
          } else {
            this.checkFilterDuplicate(componentOutput, criteria.options.multiSelect ?? false);
          }
          this.onApplyFilters();
        }
      });
    }
  }

  checkFilterDuplicate(filter: Filter, multiSelect = false): void {
    if (this.currentFiltersNotHidden.length < this.maxFilters) {
      if (!this.currentFilters.find((f) => f.criteriaKey === filter.criteriaKey && f.value === filter.value)) {
        this.currentFilters.push(filter);
        this.setCurrentFiltersNotHidden();
      } else {
        if (multiSelect) {
          if (filter.criteriaKey !== 'includeSubEntities') {
            const index = this.currentFilters.findIndex(
              (currentFilter: Filter) => currentFilter.criteriaKey === filter.criteriaKey && currentFilter.value === filter.value
            );
            this.currentFilters.splice(index, 1);
          }
          if (filter.criteriaKey === 'includeSubEntities' && !this.currentFilters.find((f) => f.criteriaKey === 'entityId')) {
            const indexHidden = this.currentFilters.findIndex((currentFilter: Filter) => currentFilter.criteriaKey === 'includeSubEntities');
            if (indexHidden >= 0) {
              this.currentFilters.splice(indexHidden, 1);
            }
          }
          if (this.currentFilters.length === 0) {
            this.onClearAppliedFilters();
          }
        }
      }
    }
  }

  onDeletePreFilter(filter: Filter): void {
    const index: number = this.currentFilters.findIndex((currentFilter: Filter) => currentFilter === filter);
    this.currentFilters.splice(index, 1);

    if (filter.criteriaKey === 'entityId' && !this.currentFilters.find((f) => f.criteriaKey === 'entityId')) {
      const indexHidden: number = this.currentFilters.findIndex((currentFilter: Filter) => currentFilter.criteriaKey === 'includeSubEntities');
      if (indexHidden >= 0) {
        this.currentFilters.splice(indexHidden, 1);
      }
    }

    this.onApplyFilters();

    if (this.currentFilters.length === 0) {
      this.onClearAppliedFilters();
    }
  }

  onClearAppliedFilters(): void {
    this.initViewContainerRef();
    this.resetFilterCriteriaButtonTitle();
  }

  onApplyFilters() {
    this.applyFilters.emit(this.currentFilters);
  }

  onClearAllFilters(): void {
    this.currentFilters = [];
    this.onApplyFilters();
    this.onClearAppliedFilters();
  }

  onResetFavoriteView(): void {
    this.currentFilters = [...this.currentFavoriteView?.filters];
    this.onApplyFilters();
    this.onClearAppliedFilters();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
