import { Injectable } from '@angular/core';
import { User } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import {
  AdminUsersPageActions
} from '../../../../../../../iot4bos-backoffice-ui/src/lib/features/admin-users/state/actions';

@Injectable({
  providedIn: 'root'
})
export class AdminUsersFacade {
  constructor(private readonly store: Store) {}

  getBusinessProfilesByUserId(userId: string): void {
    this.store.dispatch(AdminUsersPageActions.getBusinessProfilesByUserId({ userId }));
  }

  addUser(user: User): void {
    this.store.dispatch(AdminUsersPageActions.addUser({ newUser: user }));
  }

  updateUser(user: User): void {
    this.store.dispatch(AdminUsersPageActions.updateUser({ updatedUser: user }));
  }
}
