import { Component, Inject, OnInit } from '@angular/core';
import { NotificationService } from '@iot-platform/notification';
import { TranslateService } from '@ngx-translate/core';
import { PopupComponent } from '../../../../../../../iot-platform-ui/src/lib/ui/components/popup/popup.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceIccidService } from './device-iccid.service';

@Component({
  selector: 'iot4bos-ui-device-iccid',
  templateUrl: './device-iccid.component.html',
  styleUrls: ['./device-iccid.component.scss']
})
export class DeviceIccidComponent implements OnInit {
  message;
  loading = true;

  constructor(
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    public readonly dialog: MatDialog,
    public matDialogRef: MatDialogRef<DeviceIccidComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { deviceId: string },
    private readonly deviceIccidService: DeviceIccidService
  ) {}

  ngOnInit(): void {
    this.deviceIccidService.getOperatorInfo(this.data.deviceId).subscribe(
      (message) => {
        this.message = message;
        this.loading = false;
      },
      (error) => {
        this.matDialogRef.close();
        this.dialog.open(PopupComponent, {
          width: '500px',
          disableClose: true,
          data: {
            type: 'error',
            value: error
          }
        });
      }
    );
  }

  copyMessageToClipboard() {
    navigator.clipboard
      .writeText(JSON.stringify(this.message, null, 2))
      .then((t) => this.notificationService.displaySuccess(this.translateService.instant('DEVICES.CALL_LOG.MESSAGE_COPIED')));
  }
}
