import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { UserByIdComponent } from './user-by-id.component';
import { UserByIdService } from './user-by-id.service';

@NgModule({
  imports: [CommonModule, FlexModule, IotPlatformPipesModule, TranslateModule, MatProgressSpinnerModule],
  declarations: [ UserByIdComponent ],
  providers: [ UserByIdService ],
  exports: [ UserByIdComponent ]
})
export class UserByIdModule {}
