import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncAutocompleteModule } from 'libs/iot-platform-ui/src/lib/ui/components/async-autocomplete/async-autocomplete.module';
import { MatMenuListComponent } from './mat-menu-list.component';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    AsyncAutocompleteModule,
    TranslateModule,
    MatDividerModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule
  ],
  declarations: [MatMenuListComponent],
  exports: [MatMenuListComponent]
})
export class MatMenuListModule {
}
