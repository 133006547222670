import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonApiRequest, Filter, MasterViewEngineEvent, PlatformResponse } from '@iot-platform/models/common';
import { Product } from '@iot-platform/models/oyan';
import { BehaviorSubject } from 'rxjs';
import { SortUtil } from '../../../../../../../shared/src/lib/utils/sort.util';
import { BaseShellComponent } from '../../../../components/base-shell/base-shell.component';
import { AuthorizationService } from '../../../auth/services/authorization.service';
import { AuthFacade } from '../../../auth/state/facades/auth.facade';
import { OyanAuthorizationConcept, OyanAuthorizationType } from '../../../auth/types/authorization.types';
import { ProductEditFormComponent } from '../../components/product-edit-form/product-edit-form.component';
import { ProductsFacade } from '../../state/facades/products.facade';

@Component({
  selector: 'oyan-ui-products-catalog',
  templateUrl: './products-catalog.component.html',
  styleUrls: ['./products-catalog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsCatalogComponent extends BaseShellComponent<Product> implements OnInit {
  userPermissions$: BehaviorSubject<Array<{ key: string; value: boolean }>> = new BehaviorSubject<Array<{ key: string; value: boolean }>>([]);

  constructor(
    protected authFacade: AuthFacade,
    protected productsFacade: ProductsFacade,
    private readonly authorizationService: AuthorizationService,
    private readonly dialog: MatDialog
  ) {
    super(authFacade, productsFacade);
  }

  ngOnInit() {
    super.ngOnInit();
    this.userPermissions$.next([
      { key: 'canUpdateProduct', value: this.authorizationService.applyAuthorization(OyanAuthorizationConcept.PRODUCT, OyanAuthorizationType.UPDATE) }
    ]);
  }

  reLoadMasterView(page: number = this.pagination$.value.currentPage): void {
    const filters: Filter[] = this.currentFilters;
    if (!filters.find((f) => f.criteriaKey === 'entityId' && f.value === this.businessProfile.entityId)) {
      filters.push({ criteriaKey: 'entityId', value: this.businessProfile.entityId, excluded: true });
    }
    const request: CommonApiRequest = {
      page,
      limit: this.pagination$.value.limit,
      filters
    };
    this.productsFacade.getAll(request);
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    if (event.type === 'editProduct') {
      this.editProduct(event.rawData);
    }
  }

  sortData(platformResponse: PlatformResponse): PlatformResponse {
    return {
      ...platformResponse,
      data: platformResponse.data.sort(SortUtil.sortByProperty('name'))
    };
  }

  editProduct(product: Product): void {
    this.subscriptions.push(
      this.dialog
        .open(ProductEditFormComponent, {
          width: '700px',
          disableClose: true,
          data: {
            product
          }
        })
        .afterClosed()
        .subscribe((productToUpdate: Product) => {
          if (productToUpdate) {
            this.productsFacade.updateProduct(productToUpdate);
          }
        })
    );
  }
}
