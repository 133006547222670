export enum I4bCellConcept {
  SITES = 'SITES',
  ASSETS = 'ASSETS',
  DEVICES = 'DEVICES',
  VARIABLES = 'VARIABLES',
  DEVICE_VARIABLES = 'DEVICE_VARIABLES',
  ASSET_VARIABLES = 'ASSET_VARIABLES',
  FOLLOWED_VARIABLES = 'FOLLOWED VARIABLES',
  GROUPED_VARIABLES = 'GROUPED VARIABLES',
  SITE_TAGS = 'SITE TAGS',
  ASSET_TAGS = 'ASSET TAGS',
  DEVICE_TAGS = 'DEVICE TAGS',
  EVENTS_TAGS = 'EVENTS TAGS',
  DEVICE_EVENTS = 'DEVICE EVENTS',
  DEVICE_EVENTS_TAGS = 'DEVICE EVENTS TAGS',
  ASSET_EVENTS = 'ASSET EVENTS',
  EVENT_TAGS = 'EVENT TAGS',
  EVENTS = 'EVENTS',
  TAGS = 'TAGS',
  EMAIL_TEMPLATES = 'EMAIL TEMPLATES',
  CONNECTORS = 'CONNECTORS',
  GEOLOCATION = 'GEOLOCATION',
  DIAGNOSTIC_VARIABLES = 'DIAGNOSTIC VARIABLES',
  TECHNICAL = 'TECHNICAL'
}
