<div class="device-card">
  <div class="device-card-row" fxLayout="row" fxLayout.lt-md="column">
    <div class="device-card-col" fxFlex="360px" fxFlex.lt-md="100%"><img [src]="imgPath" alt="" class="device-card-img" /></div>
    <div class="device-card-col" fxFlex="100%">
      <div class="device-card-header" fxLayout="row" fxLayoutAlign="start end">
        <span class="device-card-name">{{ device?.name | infoDisplay }}</span>
        <span class="device-card-type">{{ device?.type | infoDisplay: 'hyphen':'family':'model':'firmware' }}</span>
        <div *ngIf="getDisplayCTA()" class="device-card-buttons">
          <button [matMenuTriggerFor]="menu" class="regular-round-button" mat-icon-button><mat-icon>more_vert</mat-icon></button>
          <mat-menu #menu="matMenu">
            <button (click)="onEdit()" *ngIf="canUpdate && device?.status?.name !== 'decommissioned'" mat-menu-item>
              {{ 'DEVICES.CARD.EDIT' | translate | uppercase }}
            </button>
            <button (click)="onMoveTo()" *ngIf="canUpdate" mat-menu-item>{{ 'DEVICES.CARD.MOVE_TO' | translate | uppercase }}</button>
            <button (click)="reset.emit(device)" *ngIf="canUpdate && device?.status?.name !== 'decommissioned'" [disabled]="isResetDisabled" mat-menu-item>
              {{ 'DEVICES.CARD.RESET' | translate | uppercase }}
            </button>
            <button (click)="decommissionDevice.emit()" *ngIf="canDelete && device.status?.name !== 'decommissioned'" mat-menu-item>
              {{ 'DEVICES.CARD.DECOMMISSION' | translate | uppercase }}
            </button>
            <button (click)="deleteDevice.emit(device)" *ngIf="canDelete" [disabled]="isDeleteDisabled()" mat-menu-item>
              {{ 'DEVICES.CARD.DELETE' | translate | uppercase }}
            </button>
            <hr class="action-separation" />
            <button
              (click)="configure.emit(device)"
              *ngIf="canCreate && device?.incomingConnector?.configuration?.url && device.status?.name !== 'decommissioned'"
              mat-menu-item
            >
              {{ 'DEVICES.CARD.CONFIGURE' | translate | uppercase }}
            </button>
            <div *ngIf="device.status?.name !== 'decommissioned'">
              <div
                *ngFor="let command of commandList"
                [ngClass]="{ 'device-card-button-tooltip-wrap': isCommandDisabled }"
                fxLayoutAlign="start center"
                fxLayoutGap="6px"
              >
                <button (click)="commandExec.emit(command)" [disabled]="isCommandDisabled" mat-menu-item>
                  {{ 'DEVICES.CARD.' + command | translate | uppercase }}
                </button>
                <mat-icon *ngIf="isCommandDisabled" [matTooltip]="'DEVICES.CARD.ACTION_INFORMATION_TOOLTIP' | translate" matTooltipClass="regular-tooltip"
                  >info_outline</mat-icon
                >
              </div>
            </div>
            <hr class="action-separation" />
            <button (click)="onOpenChangeLog()" *ngIf="canReadAuditTrail" class='device-card__history-btn' mat-menu-item>
              {{ 'AUDIT_TRAIL.CTA.HISTORY' | translate | uppercase }}
            </button>
          </mat-menu>
        </div>
      </div>

      <ul class="device-card-list" fxLayout="row wrap">
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.IDENTIFIER' | translate }}<span class="device-card-value">{{ device?.identifier | infoDisplay }}</span>
        </li>
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.INCOMING_CONNECTOR' | translate }}<span class="device-card-value">{{ device?.incomingConnector?.name | infoDisplay }}</span>
        </li>
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.ENDPOINT' | translate }}
          <span *ngIf="device?.communication && device.communication?.support !== 'IP'" class="device-card-value">{{
            device?.communication.endpoint | infoDisplay
          }}</span>
          <a
            *ngIf="device?.communication && device?.communication?.support === 'IP'"
            [href]="computedURL"
            class="device-card-value device-endpoint-link"
            target="_blank"
            >{{ device.communication.endpoint | infoDisplay }}</a
          >
        </li>
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.OUTGOING_CONNECTOR' | translate }}<span class="device-card-value">{{ device?.outgoingConnector?.name | infoDisplay }}</span>
        </li>
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.STATUS' | translate }}
          <span *ngIf="device.status" class="device-card-value">
            {{ 'DEVICES.CARD.STATUS_LIST.' + device?.status.name | infoDisplay | translate }} ({{ device?.status.datetime | dateFormat | infoDisplay }})
          </span>
        </li>
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.SUPPORT' | translate }}
          <span *ngIf="device?.communication" class="device-card-value">{{ device?.communication?.support | infoDisplay }}</span>
        </li>
        <li *ngIf="device?.outgoingConnector?.requestConfiguration?.authentication === 'login'" class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.LOGIN' | translate }}<span class="device-card-value">{{ device?.credential?.login | infoDisplay }}</span>
        </li>
        <li *ngIf="device?.outgoingConnector?.requestConfiguration?.authentication === 'login'" class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.PASSWORD' | translate }}<span class="device-card-value">{{ device?.credential?.password ? '•••••' : '-' }}</span>
        </li>
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.ICCID' | translate }}
          <span (click)="onIccidClick()" *ngIf="device?.communication?.operator && device?.communication?.iccid"
                class="device-card-value device-endpoint-link with-cursor">{{ device?.communication?.iccid | infoDisplay }}</span>
          <span *ngIf="!device?.communication?.operator || !device?.communication?.iccid" class="device-card-value">{{ device?.communication?.iccid | infoDisplay }}</span>
        </li>
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.OPERATOR' | translate }}<span class="device-card-value">{{ device?.communication?.operator | infoDisplay }}</span>
        </li>
        <li class="device-card-item" fxFlex="50%" fxFlex.lt-md="100%">
          {{ 'DEVICES.CARD.AFFILIATE' | translate }}<span class="device-card-value">{{ device?.affiliate | infoDisplay }}</span>
        </li>
      </ul>

      <p [innerHTML]="device?.description | linkify | infoDisplay" class="device-card-description"></p>

      <div class="device-card-footer"></div>
    </div>
  </div>
</div>
