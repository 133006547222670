import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Device } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-device-details-basic-info',
  templateUrl: './device-details-basic-info.component.html',
  styleUrls: ['./device-details-basic-info.component.scss']
})
export class DeviceDetailsBasicInfoComponent implements OnInit {
  @Input() device: Device | null = null;
  @Input() displayMode: 'vertical' | 'horizontal' | 'default' = 'default';
  @Output() selectDevice: EventEmitter<Device> = new EventEmitter<Device>();

  constructor() {}

  ngOnInit(): void {}
}
