<div class="grid-icon-cell" fxLayoutAlign="center center">
  <div
    [matTooltip]="
      'DEVICES.TABLE_CONTENT.CYCLICAL_CALL_FAULT_TOOLTIP' | translate: { dateValue: (params?.data.cyclicalCallFault?.datetime | dateFormat) ?? 'n/a' }
    "
    [matTooltipClass]="'warning-tooltip'"
    [matTooltipDisabled]="!params.value"
    matTooltipPosition="above"
  >
    <mat-icon *ngIf="params?.value" svgIcon="cyclicalCallFault" color="warn" class="grid-icon"></mat-icon>
  </div>
</div>
