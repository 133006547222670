import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthorizationService, fromAuth } from '@iot-platform/auth';
import {
  Filter,
  MasterViewEngineEvent,
  Pagination,
  PlatformRequest,
  PlatformResponse
} from '@iot-platform/models/common';
import { Device, DeviceEvent, Log, Site } from '@iot-platform/models/i4b';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

import { AuthorizationConcept, AuthorizationType } from '../../../../../../../auth/src/lib/authorization.types';
import {
  VariableChartDialogComponent
} from '../../../../../../../shared/src/lib/variable-chart/variable-chart-dialog/variable-chart-dialog.component';
import { EventDetailPopupComponent } from '../../../../components/event-detail-popup/event-detail-popup.component';
import { NavigationApi } from '../../../../containers/+state/navigation.api';

@Component({
  selector: 'iot4bos-ui-device-overview',
  templateUrl: './device-overview.component.html',
  styleUrls: ['./device-overview.component.scss']
})
export class DeviceOverviewComponent implements OnInit, OnDestroy {
  deviceEventMasterViewType = 'device-events-by-device';
  eventType = 'device-events';
  filteredBy = 'device';

  externalDeviceEventsByDevice$!: Observable<PlatformResponse>;
  deviceEventsByDeviceLoaded$!: Observable<boolean>;
  totalActiveDeviceEventsByDevice$!: Observable<number>;
  totalActiveDeviceEventsByDeviceLoaded$!: Observable<boolean>;
  deviceEventByDeviceTableState$!: Observable<{ selected: DeviceEvent; checked: DeviceEvent[] }>;
  deviceEventByDevicePageSize$: Observable<number> = of(10);
  selectedDeviceEvent: DeviceEvent;
  checkedDeviceEvents: DeviceEvent[];
  currentDeviceEvent = null;
  deviceEventsPageSize: number;
  deviceEventsCurrentPage: number;
  currentDeviceEventsFilters: Filter[] = [];
  logsByDeviceEvent$!: Observable<Log[]>;
  mvDeviceEventsByDeviceSettings$!: Observable<any>;

  /* Auto refresh settings */
  REFRESH_DELAY = 10;
  refreshEventsTimer$ = timer(0, 1 * this.REFRESH_DELAY * 1000);
  refreshSpinnerTimer$ = timer(0, 1 * 1 * 1000);
  spinnerTimerValue$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  refreshActivated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  autoRefreshEnabled$ = this.navigationApi.deviceEventsByDeviceAutoRefresh$;
  autoRefreshEnabled = false;
  refreshDelay$ = this.navigationApi.deviceEventsByDeviceRefreshDelay$;
  device$ = this.navigationApi.selectedDevice$;

  /* Filter Engine parameters for device event */
  filterEngineOpened = true;
  clearAppliedFilters$ = of(false);
  currentFavoriteView$ = of(undefined);
  deviceEventsByDeviceCurrentFilters$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);
  mandatoryFilters: Filter[] = [];

  resetLogs$: Subject<boolean> = new Subject();
  destroyed$: Subject<boolean> = new Subject();

  canCreate: boolean;
  canRead: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  canUpdateEvent: boolean;
  canReadEvent: boolean;
  canReadAuditTrail: boolean;
  userPermissions: { key: string; value: boolean }[] = [];

  configurationUrl$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  site$: Observable<Site> = this.navigationApi.site$;

  subscriptions: Subscription[] = [];

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  constructor(
    private readonly navigationApi: NavigationApi,
    private readonly authorizationService: AuthorizationService,
    private readonly store: Store,
    private readonly dialog: MatDialog
  ) {
    this.canCreate = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.CREATE);
    this.canRead = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.READ);
    this.canUpdate = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.UPDATE);
    this.canDelete = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.DELETE);
    this.canUpdateEvent = this.authorizationService.applyAuthorization(AuthorizationConcept.EVENT, AuthorizationType.UPDATE);
    this.canReadEvent = this.authorizationService.applyAuthorization(AuthorizationConcept.EVENT, AuthorizationType.READ);
    this.canReadAuditTrail = this.authorizationService.applyAuthorization(AuthorizationConcept.AUDIT_TRAIL, AuthorizationType.READ);

    this.userPermissions = [
      { key: 'canUpdateEvent', value: this.canUpdateEvent },
      { key: 'canReadEvent', value: this.canReadEvent }
    ];
  }

  ngOnInit(): void {
    this.externalDeviceEventsByDevice$ = this.navigationApi.formattedDeviceEventsByDevice$;
    this.deviceEventsByDeviceLoaded$ = this.navigationApi.formattedDeviceEventsByDeviceLoaded$;
    this.deviceEventByDeviceTableState$ = this.navigationApi.deviceEventsByDeviceTableState$;
    this.logsByDeviceEvent$ = this.navigationApi.deviceEventsByDeviceLogsByDeviceEventId$;
    this.totalActiveDeviceEventsByDevice$ = this.navigationApi.totalActiveDeviceEventsByDevice$;
    this.totalActiveDeviceEventsByDeviceLoaded$ = this.navigationApi.totalActiveDeviceEventsByDeviceLoaded$;
    this.mvDeviceEventsByDeviceSettings$ = this.navigationApi.mvDeviceEventsByDeviceSettings$;

    this.autoRefreshEnabled$.subscribe((isAutoRefreshEnabled) => {
      if (isAutoRefreshEnabled) {
        this.autoRefreshEnabled = isAutoRefreshEnabled;
        this.refreshActivated$.next(isAutoRefreshEnabled);
      }
    });

    this.deviceEventByDeviceTableState$.subscribe((state) => {
      this.selectedDeviceEvent = state.selected;
      this.checkedDeviceEvents = state.checked;
    });

    this.loadMetadata();
    this.updateDeviceEvents();
    this.updateRefreshDelay();
    this.manageDeviceEventsAutoRefresh();
    this.updateDeviceEventsByPagination();
    this.updateIncomingConnector();
    this.loadLogsByEventId();
  }

  onConfigureDeviceConnector(device: Device) {
    if (device.outgoingConnector?.configuration?.sharedAuth) {
      window.open(this.configurationUrl$.getValue());
    } else {
      window.open(device.incomingConnector?.configuration?.url);
    }
  }

  onCloseComments() {
    this.sidenav.close();
  }

  loadDeviceEvents(
    request: PlatformRequest = {
      page: this.deviceEventsCurrentPage,
      limit: this.deviceEventsPageSize,
      filters: this.currentDeviceEventsFilters
    }
  ): void {
    this.spinnerTimerValue$.next(0);
    this.navigationApi.loadEvents(request, this.eventType, this.filteredBy);
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent) {
    switch (event.type) {
      case 'open':
        this.onSelectEvent({ selected: event.rawData, checked: this.checkedDeviceEvents });
        this.openEventDetail(event.rawData);
        break;
      case 'checkElements':
        this.onSelectEvent({ selected: this.selectedDeviceEvent, checked: event.rawData });
        break;
      case 'openGraph':
        this.openGraph(event.rawData);
        break;
      case 'snooze':
        const eventsToSnooze = this.transformEventsInArray(event.rawData).filter((e) => e.status === 'active' && e.snoozeQuota !== 0);
        this.updateEventsStatus(eventsToSnooze, 'snooze');
        break;
      case 'acknowledge':
        const eventsToAcknowledge = this.transformEventsInArray(event.rawData).filter((e) => e.status === 'active');
        this.updateEventsStatus(eventsToAcknowledge, 'acknowledge');
        break;
      case 'close':
        const eventsToClose = this.transformEventsInArray(event.rawData).filter((e) => e.status === 'active' || e.status === 'acknowledged');
        this.updateEventsStatus(eventsToClose, 'close');
        break;
      case 'openComments':
        this.onSelectEvent({ selected: event.rawData, checked: this.checkedDeviceEvents });
        this.onOpenComments(event.rawData);
        break;
      default:
        break;
    }
  }

  onSelectEvent(tableState: { checked: DeviceEvent[]; selected: DeviceEvent }) {
    this.navigationApi.saveEventsTableState(tableState, this.eventType, this.filteredBy);
  }

  openEventDetail(event: DeviceEvent) {
    this.currentDeviceEvent = event;
    const popupData = { event: event, eventType: this.eventType, filteredBy: this.filteredBy, canUpdateEvent: this.canUpdateEvent };
    this.navigationApi.loadEventDetailPopupDataByEvent(event, this.eventType, this.filteredBy);

    const detailPopup = this.dialog.open(EventDetailPopupComponent, {
      width: '1100px',
      disableClose: false,
      data: popupData
    });

    detailPopup.componentInstance.updateStatus.subscribe((status: string) => {
      this.updateEventsStatus([event], status);
    });

    detailPopup.componentInstance.addComment.subscribe((value: string) => {
      this.onAddComment(value);
    });
  }

  onAddComment(value: string) {
    this.navigationApi.createLogByEventId(this.currentDeviceEvent, value, this.eventType, this.filteredBy);
  }

  openGraph(event: DeviceEvent) {
    const variableToDisplay = {
      ...event.context.deviceVariable,
      device: { id: event.context.device.id, name: event.context.device.name }
    };

    this.dialog.open(VariableChartDialogComponent, {
      width: '990px',
      data: {
        variables: [variableToDisplay],
        variableType: 'deviceVariable'
      }
    });
  }

  transformEventsInArray(events): DeviceEvent[] {
    if (events.length > 0) {
      return events;
    }
    const eventAsArray = [];
    eventAsArray.push(events);
    return eventAsArray;
  }

  updateEventsStatus(events: DeviceEvent[], status: string) {
    this.navigationApi.updateEventsStatus(
      events.map((event) => event.id),
      status,
      this.eventType,
      this.filteredBy
    );
  }

  onOpenComments(event: DeviceEvent) {
    this.currentDeviceEvent = event;
    this.resetLogs$.next(true);
    this.navigationApi.loadLogsByEventId(event.id, this.eventType, this.filteredBy);
    this.sidenav.open();
  }

  onEventsPageChange(pagination: Pagination) {
    const request: PlatformRequest = {
      page: pagination.currentPage,
      limit: pagination.limit,
      filters: this.currentDeviceEventsFilters
    };
    this.navigationApi.loadEvents(request, this.eventType, this.filteredBy);
  }

  onApplyFilters(filters: Filter[]): void {
    this.deviceEventsByDeviceCurrentFilters$.next(filters);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private loadMetadata() {
    combineLatest([this.store.pipe(select(fromAuth.selectSelectedBusinessProfileForAccount)), this.deviceEventByDevicePageSize$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([bp, pageSize]) => {
        if (bp && pageSize) {
          this.navigationApi.loadMetadata(this.eventType, this.filteredBy);
        }
      });
  }

  private manageDeviceEventsAutoRefresh() {
    combineLatest([this.autoRefreshEnabled$, this.refreshActivated$, this.refreshEventsTimer$, this.refreshSpinnerTimer$])
      .pipe(
        filter(([isAutoRefreshEnabled, _]) => this.canReadEvent && isAutoRefreshEnabled),
        takeUntil(this.destroyed$)
      )
      .subscribe(([_, isRefreshActivated]) => {
        if (isRefreshActivated) {
          if (this.spinnerTimerValue$.getValue() === this.REFRESH_DELAY) {
            this.spinnerTimerValue$.next(0);
            this.loadDeviceEvents();
          } else {
            this.spinnerTimerValue$.next(this.spinnerTimerValue$.getValue() + 1);
          }
        } else {
          this.spinnerTimerValue$.next(0);
        }
      });
  }

  private updateRefreshDelay() {
    this.refreshDelay$.pipe(takeUntil(this.destroyed$)).subscribe((delay) => {
      if (delay) {
        this.REFRESH_DELAY = delay;
      }
    });
  }

  private updateDeviceEvents() {
    combineLatest([this.device$, this.deviceEventsByDeviceCurrentFilters$])
      .pipe(
        filter(() => this.canReadEvent),
        takeUntil(this.destroyed$)
      )
      .subscribe(([device, filters]) => {
        if (device && this.deviceEventsPageSize) {
          this.mandatoryFilters = [{ criteriaKey: 'deviceId', value: device.id }];
          this.currentDeviceEventsFilters = [...this.mandatoryFilters, ...filters];
          const request: PlatformRequest = {
            page: this.deviceEventsCurrentPage ?? 0,
            limit: this.deviceEventsPageSize,
            filters: this.currentDeviceEventsFilters
          };
          this.loadDeviceEvents(request);
        }
      });
  }

  private updateDeviceEventsByPagination() {
    combineLatest([
      this.store.pipe(select(fromAuth.selectAccount)),
      this.navigationApi.deviceEventsByDevicePagination$,
      this.deviceEventsByDeviceCurrentFilters$
    ])
      .pipe(
        withLatestFrom(this.device$),
        filter(() => this.canReadEvent),
        takeUntil(this.destroyed$)
      )
      .subscribe(([[account, pagination, filters], device]) => {
        if (account && pagination?.limit && pagination.limit !== this.deviceEventsPageSize && device) {
          this.mandatoryFilters = [{ criteriaKey: 'deviceId', value: device.id }];
          this.currentDeviceEventsFilters = [...this.mandatoryFilters, ...filters];
          this.deviceEventsPageSize = pagination.limit;
          this.deviceEventByDevicePageSize$ = of(pagination.limit);
          this.deviceEventsCurrentPage = pagination.currentPage ?? 0;
          const request: PlatformRequest = { page: pagination.currentPage, limit: this.deviceEventsPageSize, filters: this.currentDeviceEventsFilters };
          this.loadDeviceEvents(request);
        }
      });
  }

  private loadLogsByEventId() {
    this.navigationApi.deviceEventByDeviceStatus$.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (!!event) {
        this.navigationApi.loadLogsByEventId(event.id, this.eventType, this.filteredBy);
      }
    });
  }

  private updateIncomingConnector() {
    combineLatest([
      this.device$,
      this.store.select(fromAuth.selectRefreshToken),
      this.store.select(fromAuth.selectSelectedBusinessProfileForAccount),
      this.store.select(fromAuth.selectUserId)
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([device, token, bP, userId]) => {
        if (device?.incomingConnector && token && bP && userId) {
          this.configurationUrl$.next(
            device.incomingConnector.configuration?.url + 'device/' + userId + '/' + bP.id + '?' + 'refreshToken=' + token + '&deviceName=' + device.name
          );
        }
      });
  }
}
