<form [formGroup]="dynamicListForm" fxLayout="row" fxLayoutGap="20px">
  <ng-container *ngIf="!data.multiSelect">
    <div *ngFor="let input of data.inputs">
      <mat-form-field class="select-field" *ngIf="input.display && !showLoader" color="accent">
        <mat-select
          data-cy="iot-platform-ui-dynamic-list-field-multiple-select"
          [formControlName]="input.id"
          [placeholder]="'FILTER_ENGINE.TAG_PLACEHOLDER_CATEGORY' | translate"
          (selectionChange)="onSelectionChange(input, dynamicListForm.get(input.id).value)"
        >
          <mat-option data-cy="iot-platform-ui-dynamic-list-field-multiple-select-option" [value]="option" *ngFor="let option of input.display">{{
            option[input.selectByProperty]
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-progress-spinner *ngIf="showLoader && !input.parentListId" mode="indeterminate" [diameter]="20" color="accent"></mat-progress-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="data.multiSelect">
    <mat-form-field class="select-field" color="accent">
      <mat-label fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
        <span fxFlex="90" class="filter-label">{{ data.criteriaLabel | translate }}</span>
        <mat-progress-spinner
          fxFlex="10"
          *ngIf="showLoader && !categoryList.parentListId"
          mode="indeterminate"
          [diameter]="16"
          color="accent"
        ></mat-progress-spinner>
      </mat-label>
      <mat-select
        data-cy="iot-platform-ui-dynamic-list-field-multiple-select"
        [formControlName]="categoryList.id"
        (selectionChange)="onSelectionChange(categoryList, dynamicListForm.get(categoryList.id).value)"
      >
        <ng-container *ngIf="categoryList.display && !showLoader">
          <mat-option data-cy="iot-platform-ui-dynamic-list-field-multiple-select-option" [value]="option" *ngFor="let option of categoryList.display">{{
            option[categoryList.selectByProperty]
          }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <iot-platform-ui-async-autocomplete-multiple-selects
      *ngIf="labelList.display"
      [data]="labelList.display"
      [standaloneMode]="true"
      [showSpinner]="showLoader"
      [displaySearchIcon]="false"
      [minLength]="1"
      [displayKey]="labelList.selectByProperty"
      [placeholder]="labelList.placeholder"
      [filterKey]="labelList.selectByProperty"
      [currentFilters$]="currentFilters$"
      [currentFiltersSize]="currentFiltersSize"
      [multiSelect]="data.multiSelect"
      [maxFilters]="maxFilters"
      [type]="'tag'"
      (selectionChanged)="onMultiSelectionChange($event)"
    >
    </iot-platform-ui-async-autocomplete-multiple-selects>
  </ng-container>
</form>
