import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Contact } from '@iot-platform/models/common';
import { SpreadsheetExport } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  selectSpreadsheetExportLoaded,
  selectSpreadsheetExportLoading,
  selectSpreadsheetExportsByContactId
} from '../../+state/reducers';
import { getSpreadsheetExport } from './../../+state/actions/spreadsheet-exports-ui.actions';
import { selectSpreadsheetExportError } from './../../+state/reducers';

@Component({
  selector: 'iot4bos-ui-site-contact',
  templateUrl: './site-contact.component.html',
  styleUrls: ['./site-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteContactComponent implements OnInit, OnDestroy, OnChanges {
  @Input() contact!: Contact;
  @Input() canUpdate?: boolean;
  @Input() canDelete?: boolean;
  @Input() minimalDisplay = true; // display only fullname, job, phone and email

  @Output() toEdit: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() configNotif: EventEmitter<any> = new EventEmitter();
  @Output() configExport: EventEmitter<any> = new EventEmitter();

  spreadsheetExport$ = new BehaviorSubject<SpreadsheetExport | {}>({});
  loading$!: Observable<boolean>;
  loaded$!: Observable<boolean>;
  fetchingError$!: Observable<HttpErrorResponse | null | undefined>;
  destroyed$ = new Subject();

  constructor(private readonly store: Store) {}

  ngOnInit() {
    if (this.minimalDisplay === false && this.contact.id) {
      this.store
        .select(selectSpreadsheetExportsByContactId(this.contact.id))
        .pipe(takeUntil(this.destroyed$))
        .subscribe((spreadsheetExport) => {
          this.spreadsheetExport$.next(spreadsheetExport || {});
        });
      this.loading$ = this.store.select(selectSpreadsheetExportLoading(this.contact.id));
      this.loaded$ = this.store.select(selectSpreadsheetExportLoaded(this.contact.id));
      this.fetchingError$ = this.store.select(selectSpreadsheetExportError(this.contact.id));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.minimalDisplay === false && changes.contact?.currentValue?.id !== changes.contact?.previousValue?.id) {
      this.store.dispatch(getSpreadsheetExport({ contactId: changes.contact.currentValue.id }));
    }
  }

  get initials(): string {
    return this.contact?.firstname?.[0]?.toUpperCase() + this.contact?.lastname?.[0]?.toUpperCase();
  }

  getFilterByKey(key: string): string[] {
    return this.contact.notificationFilter?.filter((item) => item.criteriaKey === key).map((i) => i.value) ?? [];
  }

  edit() {
    this.toEdit.emit();
  }

  configureNotif() {
    this.configNotif.emit();
  }

  configureExport() {
    this.configExport.emit(this.spreadsheetExport$.value);
  }

  onDelete() {
    this.delete.emit();
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
