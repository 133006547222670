<div class="table-container" [ngClass]="{ 'table-container--sticky': sticky }">
  <table i4b-table-engine-master-view-table-full-height [observerSelectors]='observerSelectors' [sticky]='sticky' [data]='tableData' [pagination]='(pagination$ | async)' mat-table matSort (matSortChange)="onSortChange($event)" multiTemplateDataRows [dataSource]="masterViewDataSource">
    <ng-container *ngIf="bluePrint?.multipleSelection" [matColumnDef]="bluePrint?.selectionColumn.id">
      <th mat-header-cell *matHeaderCellDef [fxLayoutAlign]="bluePrint?.selectionColumn.type" [fxFlex]="bluePrint?.selectionColumn.width">
        <mat-checkbox
          class="mve-select-checkbox"
          (change)="toggleAllSelection()"
          [checked]="isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        ></mat-checkbox>
        <span class="table-total-selected fw-600" [textContent]="totalSelected" *ngIf="totalSelected > 1"></span>
      </th>
      <td mat-cell *matCellDef="let element" [fxLayoutAlign]="bluePrint?.selectionColumn.type" [fxFlex]="bluePrint?.selectionColumn.width">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleSelection(element)" [checked]="isChecked(element)"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="column.id" *ngFor="let column of columnsToDisplay">
      <th
        mat-header-cell
        *matHeaderCellDef
        [fxLayoutAlign]="column.type"
        [fxFlex]="column.width"
        [disabled]="!column.sortable"
        [mat-sort-header]="column.sortProperty"
      >
        <i4b-table-engine-master-view-header-container [column]="column"></i4b-table-engine-master-view-header-container>
      </th>
      <td data-cy='i4b-table-engine-master-view-cell-container' mat-cell *matCellDef="let element" [fxLayoutAlign]="column.type" [fxFlex]="column.width">
        <i4b-table-engine-master-view-cell-container
          [column]="column"
          [element]="element"
          [userPermissions]="userPermissions"
          (dispatchEvent)="onDispatchEvent($event)"
        ></i4b-table-engine-master-view-cell-container>
      </td>
    </ng-container>

    <ng-container *ngIf="bluePrint?.multipleSelection" [matColumnDef]="bluePrint?.buttonColumn.id">
      <th mat-header-cell class="table-buttons" *matHeaderCellDef [fxLayoutAlign]="bluePrint?.buttonColumn.type" [fxFlex]="bluePrint?.buttonColumn.width">
        <div class="app-round-button-mv action" *ngIf="totalSelected > 1">
          <button *ngIf="isCallToActionVisible" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
          <mat-menu #menu="matMenu">
            <div *ngFor="let action of bluePrint?.buttonColumn.bulkActions">
              <button mat-menu-item *ngIf="bulkActionVisibility[action.key]" (click)="onBulkActionClick(action.key)">
                {{ action.label | translate | uppercase }}
              </button>
            </div>
          </mat-menu>
        </div>
      </th>
      <td data-cy='i4b-table-engine-call-to-action-cell' mat-cell *matCellDef="let element" [fxLayoutAlign]="bluePrint?.buttonColumn.type" [fxFlex]="bluePrint?.buttonColumn.width" class="table-buttons">
        <i4b-table-engine-call-to-action-cell
          *ngIf="isCallToActionVisible"
          [element]="element"
          [userPermissions]="userPermissions"
          [actions]="bluePrint?.buttonColumn.singleActions"
          (actionClicked)="onSingleActionClick($event)"
        >
        </i4b-table-engine-call-to-action-cell>
      </td>
    </ng-container>

    <ng-container *ngIf="!bluePrint?.multipleSelection && bluePrint?.buttonColumn" [matColumnDef]="bluePrint?.buttonColumn.id">
      <th mat-header-cell class="table-buttons" *matHeaderCellDef [fxLayoutAlign]="bluePrint?.buttonColumn.type" [fxFlex]="bluePrint?.buttonColumn.width"></th>
      <td data-cy='i4b-table-engine-call-to-action-cell' mat-cell *matCellDef="let element" [fxLayoutAlign]="bluePrint?.buttonColumn.type" [fxFlex]="bluePrint?.buttonColumn.width" class="table-buttons">
        <i4b-table-engine-call-to-action-cell
          *ngIf="isCallToActionVisible"
          [element]="element"
          [userPermissions]="userPermissions"
          [actions]="bluePrint?.buttonColumn.singleActions"
          (actionClicked)="onSingleActionClick($event)"
        >
        </i4b-table-engine-call-to-action-cell>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplayIds" class="table-row-header"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplayIds"
      data-cy='i4b-table-engine-row'
      class="table-row-content"
      [class.selected]="isRowSelected(element)"
      [class.checked]="isChecked(element)"
      (click)="openDetail(element)"
    ></tr>
    <!--    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-row-detail"></tr>-->
  </table>

  <div *ngIf='withLoadMoreButton else paginatorTmpl;' class='load-more' fxLayout="column" fxLayoutAlign="center center">
    <button *ngIf='(pagination$ | async)?.hasMore' mat-button class="button-regular" (click)="onLoadMore()" [disabled]='loading'>
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <mat-progress-spinner
          *ngIf="loading"
          color="accent"
          mode="indeterminate"
          [diameter]="20"></mat-progress-spinner>
        {{ 'IOT_DICTIONARY.LOAD_MORE' | translate }}
      </div>
    </button>
  </div>

  <ng-template #paginatorTmpl>
    <mat-paginator
      #fullDatasetPaginator
      *ngIf="useFullyLoadedDataset"
      [length]="(pagination$ | async).total"
      [pageIndex]="(pagination$ | async).currentPage"
      [pageSize]="(pagination$ | async).limit"
      [pageSizeOptions]="pageSizeOptions"
      [showFirstLastButtons]="true"
      (page)="onPageChange($event)"
    >
    </mat-paginator>

    <mat-paginator
      #onePageDatasetPaginator
      *ngIf="!useFullyLoadedDataset"
      [length]="(pagination$ | async).total"
      [pageIndex]="(pagination$ | async).currentPage"
      [pageSize]="(pagination$ | async).limit"
      [pageSizeOptions]="pageSizeOptions"
      [showFirstLastButtons]="true"
      [disabled]="pendingRequest$"
      (page)="onPageChange($event)"
    >
    </mat-paginator>
  </ng-template>
</div>
<ng-template #exportViewRef></ng-template>
