import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'iot-platform-login-send-code-form',
  templateUrl: './login-send-code-form.component.html',
  styleUrls: ['./login-send-code-form.component.scss', '../../scss/style.scss']
})
export class LoginSendCodeFormComponent implements OnInit {
  form: UntypedFormGroup;

  @Input() hasError: { message: string };
  @Input() isI4BLogin = false;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() sendCode: EventEmitter<string> = new EventEmitter();

  ngOnInit() {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.email])
    });
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  onChangeEmail(value: string) {
    this.email.setValue(value.toLowerCase());
  }

  onCancel() {
    this.cancel.emit();
  }

  onSendCode() {
    this.sendCode.emit(this.email.value);
  }
}
