import { Injectable } from '@angular/core';

import { Log } from '@iot-platform/models/i4b';

import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { GridsDbActions } from '../../../../../../../grid-engine/src/lib/components/state/actions';
import * as fromGrids from '../../../../../../../grid-engine/src/lib/components/state/reducers';

import { DeviceEventsService } from '../../../../../../../shared/src/lib/services/device-events.service';
import { DeviceEventsLogsDbActions, DeviceEventsLogsUiActions } from '../actions';

@Injectable()
export class DeviceEventsLogsEffects {
  loadLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsLogsUiActions.loadLogsByDeviceEventId),
      switchMap((action) =>
        this.deviceEventsService.getLogsById(action.deviceEventId).pipe(
          map((logs: Log[]) => DeviceEventsLogsDbActions.loadLogsByDeviceEventIdSuccess({ logs })),
          catchError((error) => of(DeviceEventsLogsDbActions.loadLogsByDeviceEventIdFailure({ error })))
        )
      )
    )
  );

  loadLogsAfterCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsLogsDbActions.createLogByDeviceEventIdSuccess),
      switchMap((action) => {
        return this.deviceEventsService.getLogsById(action.deviceEvent.id).pipe(
          map((logs: Log[]) => DeviceEventsLogsDbActions.loadLogsByDeviceEventIdSuccess({ logs })),
          catchError((error) => of(DeviceEventsLogsDbActions.loadLogsByDeviceEventIdFailure({ error })))
        );
      })
    )
  );

  createLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceEventsLogsUiActions.createLogByDeviceEventId),
      switchMap((action) =>
        this.deviceEventsService.putLogById(action.comment).pipe(
          switchMap((log: Log) => [
            GridsDbActions.updateItemInAllGridsData({ updatedItem: action.comment.deviceEvent }),
            DeviceEventsLogsDbActions.createLogByDeviceEventIdSuccess({ log, deviceEvent: action.comment.deviceEvent })
          ]),
          catchError((error) => of(DeviceEventsLogsDbActions.createLogByDeviceEventIdFailure({ error })))
        )
      )
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceEventsLogsDbActions.createLogByDeviceEventIdSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceEventsLogsDbActions.loadLogsByDeviceEventIdFailure, DeviceEventsLogsDbActions.createLogByDeviceEventIdFailure),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private gridsStore: Store<fromGrids.State>,
    private deviceEventsService: DeviceEventsService,
    private notificationService: NotificationService
  ) {}
}
