<div class="map-panel-info" style="overflow: hidden">
  <button (click)="closeSidebar()" class="regular-round-button" mat-icon-button style="z-index: 999; position: absolute; top: 8px; right: 10px;"><mat-icon>close</mat-icon></button>
  <iot-platform-ui-card-loader *ngIf="(loading$ | async)" [backgroundClass]="'mv-detail-card h-140'"
                               style="padding: 20px"></iot-platform-ui-card-loader>

  <div *ngIf="!(loading$ | async)" [ngSwitch]="feature?.properties?.concept" class="map-panel-info-content mve-detail-popup-container" >

    <div *ngSwitchCase="'site'" fxLayout="column" fxLayoutGap="8px">
      <iot-platform-ui-site-detail-basic-info (selectSite)="selectElement.emit({element: $event, destination: 'site'})" [site]="(site$  | async)" displayMode="vertical"></iot-platform-ui-site-detail-basic-info>
      <iot-platform-ui-site-detail-tags [displayLoader]="!(loading$ | async)"  [tags]="tags$ | async"></iot-platform-ui-site-detail-tags>
      <iot-platform-ui-site-detail-contacts [canReadContacts]="canReadContacts" [contacts]="contacts$ | async" ></iot-platform-ui-site-detail-contacts>
      <iot-platform-ui-site-detail-assets (selectAsset)="selectElement.emit({element: $event, destination: 'asset'})" [assets]="(assetsBySite$ | async)" [isAssetLoaded]="true"
                                          [totalAssets]="(assetsBySite$ | async)?.length"></iot-platform-ui-site-detail-assets>
      <iot-platform-ui-site-detail-devices  (selectDevice)="selectElement.emit({element: $event, destination: 'device'})" [devices]="(devicesBySite$ | async)"
                                            [isDeviceLoaded]="true" [totalDevices]="(devicesBySite$ | async)?.length"></iot-platform-ui-site-detail-devices>
    </div>
    <div *ngSwitchCase="'asset'" fxLayoutGap="8px">
      <iot-platform-ui-asset-details-basic-info (selectAsset)="selectElement.emit({element: $event, destination: 'asset'})" [asset]="(asset$ | async)" displayMode="vertical"></iot-platform-ui-asset-details-basic-info>
      <iot-platform-maps-map-panel-info-toolbox (displayRoute)="displayRoute.emit($event)" *ngIf="(asset$ | async).site.type === 'mobile_fleet'" [asset]="asset$ | async" ></iot-platform-maps-map-panel-info-toolbox>
      <iot-platform-ui-site-detail-tags [displayLoader]="true" [tags]="tags$ | async"></iot-platform-ui-site-detail-tags>
      <iot-platform-ui-site-details-full-info (selectSite)="selectElement.emit({element: $event, destination: 'site'})" [isSiteLoaded]="true" [site]="complementarySite$ | async" ></iot-platform-ui-site-details-full-info>
      <iot-platform-ui-asset-details-followed-variables [asset]="(asset$ | async)"
                                                       ></iot-platform-ui-asset-details-followed-variables>
    </div>
    <div *ngSwitchCase="'device'" fxLayoutGap="8px">
      <iot-platform-ui-device-details-basic-info (selectDevice)="selectElement.emit({element: $event, destination: 'device'})" [device]="(device$ | async)" displayMode="vertical"></iot-platform-ui-device-details-basic-info>
      <iot-platform-ui-site-detail-tags [displayLoader]="!(loading$ | async)" [tags]="tags$ | async"></iot-platform-ui-site-detail-tags>
      <iot-platform-ui-site-details-full-info (selectSite)="selectElement.emit({element: $event, destination: 'site'})" [isSiteLoaded]="!(loading$ | async)" [site]="complementarySite$ | async"></iot-platform-ui-site-details-full-info>
      <iot-platform-ui-device-details-connectors [device]="(device$ | async)" fxLayoutGap="10px" style="padding-top:10px;"></iot-platform-ui-device-details-connectors>
    </div>

    <div *ngSwitchDefault>
      <p>click on the map</p>
    </div>
  </div>
</div>






