// Code reference
// https://www.bennadel.com/blog/3565-providing-module-configuration-using-forroot-and-ahead-of-time-compiling-in-angular-7-2-0.htm
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { LocalStorageOptions } from './local-storage.service';

export interface ModuleOptions {
  prefix?: string;
}

export const FOR_ROOT_OPTIONS_TOKEN = new InjectionToken<ModuleOptions>('forRoot() LocalStorageService configuration.');

export function provideLocalStorageOptions(options?: ModuleOptions): LocalStorageOptions {
  const localStorageOptions = new LocalStorageOptions();
  // If the optional options were provided via the .forRoot() static method, then apply
  // them to the LocalStorageOptions Type provider.
  if (options) {
    if (typeof (options.prefix) === 'string') {
      localStorageOptions.prefix = options.prefix;
    }
  }
  return (localStorageOptions);
}

@NgModule()
export class LocalStorageModule {
  static forRoot(options?: ModuleOptions): ModuleWithProviders<LocalStorageModule> {
    return ({
      ngModule: LocalStorageModule,
      providers: [
        // In order to translate the raw, optional OPTIONS argument into an
        // instance of the LocalStorageOptions TYPE, we have to first provide it as
        // an injectable so that we can inject it into our FACTORY FUNCTION.
        {
          provide: FOR_ROOT_OPTIONS_TOKEN,
          useValue: options
        },
        // Once we've provided the OPTIONS as an injectable, we can use a FACTORY
        // FUNCTION to then take that raw configuration object and use it to
        // configure an instance of the LocalStorageOptions TYPE (which will be
        // implicitly consumed by the LocalStorageService constructor).
        {
          provide: LocalStorageOptions,
          useFactory: provideLocalStorageOptions,
          deps: [FOR_ROOT_OPTIONS_TOKEN]
        }
      ]
    });
  }
}
