import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarButtonComponent } from './toolbar-button.component';

@NgModule({
  declarations: [ToolbarButtonComponent],
  imports: [CommonModule, MatButtonModule, TranslateModule, MatTooltipModule, MatIconModule, MatMenuModule],
  exports: [ToolbarButtonComponent]
})
export class ToolbarButtonModule {}
