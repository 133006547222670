import { Pagination, PlatformResponse, User } from '@iot-platform/models/common';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromUsersApi from './admin-users-api.reducer';
import * as fromUsersPage from './admin-users-page.reducer';

export const adminUsersFeatureKey = 'adminUsers';

export interface UsersState {
  [fromUsersPage.adminUsersPageFeatureKey]: fromUsersPage.State;
  [fromUsersApi.adminUsersApiFeatureKey]: fromUsersApi.State;
}

export interface State {
  [adminUsersFeatureKey]: UsersState;
}

export function reducers(state: UsersState | undefined, action: Action) {
  return combineReducers({
    [fromUsersPage.adminUsersPageFeatureKey]: fromUsersPage.reducer,
    [fromUsersApi.adminUsersApiFeatureKey]: fromUsersApi.reducer
  })(state, action);
}

export const selectUsersState = createFeatureSelector<UsersState>(adminUsersFeatureKey);

export const selectUsersPageState = createSelector(selectUsersState, (state: UsersState) => state[fromUsersPage.adminUsersPageFeatureKey]);

export const selectUsersApiState = createSelector(selectUsersState, (state: UsersState) => state[fromUsersApi.adminUsersApiFeatureKey]);

export const {
  selectIds: getUsersIds,
  selectEntities: getUsersEntities,
  selectAll: getAllUsers,
  selectTotal: getTotalUsers
} = fromUsersApi.adapter.getSelectors(selectUsersApiState);

export const getSelectedUserId = createSelector(selectUsersApiState, fromUsersApi.getSelectedId);
export const getLoadedUser = createSelector(selectUsersApiState, fromUsersApi.getLoadedUser);

export const getSelectedUser = createSelector(getUsersEntities, getSelectedUserId, getLoadedUser, (entities, selectedId, loaded) => {
  if (entities && selectedId) {
    return selectedId && entities[selectedId];
  } else {
    return loaded;
  }
});

export const getBusinessProfiles = createSelector(selectUsersApiState, fromUsersApi.getBusinessProfiles);

export const getUsersPageError = createSelector(selectUsersPageState, fromUsersPage.getError);

export const getUsersPagePending = createSelector(selectUsersPageState, fromUsersPage.getPending);

export const getCurrentFilters = createSelector(selectUsersPageState, fromUsersPage.getCurrentFilters);

export const getPagination = createSelector(selectUsersApiState, fromUsersApi.getPagination);

export const getTotal = createSelector(getPagination, (pagination: Pagination) => {
  return pagination.total;
});

export const getUsersAsPlatformResponse = createSelector(getAllUsers, getPagination, (users: User[], pagination: Pagination) => {
  const response: PlatformResponse = {
    currentPage: pagination.currentPage,
    maxPage: pagination.maxPage,
    hasMore: pagination.hasMore,
    total: pagination.total,
    limit: pagination.limit,
    data: users
  };
  return response;
});
