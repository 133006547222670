import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Organization } from '@iot-platform/models/common';

@Component({
  selector: 'iot4bos-backoffice-ui-dialog-manage-concepts-form',
  templateUrl: './dialog-manage-concepts-form.component.html',
  styleUrls: ['./dialog-manage-concepts-form.component.scss']
})
export class DialogManageConceptsFormComponent implements OnInit {
  conceptsForm: UntypedFormGroup;
  params;

  constructor(
    private dialogRef: MatDialogRef<DialogManageConceptsFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { organization: Organization; adminConceptsFromEntitySession: string[] }
  ) {}

  ngOnInit(): void {
    this.conceptsForm = new UntypedFormGroup({ conceptsToUpdate: new UntypedFormControl(this.data.organization.adminConcepts) });
    this.params = { org: this.data.organization.name };
  }

  get conceptsToUpdate(): AbstractControl {
    return this.conceptsForm.get('conceptsToUpdate');
  }

  onChangeConcepts(newConcepts: string[]) {
    this.conceptsToUpdate.setValue(Object.assign([], newConcepts));
    this.conceptsForm.markAsDirty();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const organizationToUpdate: Organization = { ...this.data.organization, adminConcepts: this.conceptsToUpdate.value };
    this.dialogRef.close(organizationToUpdate);
  }
}
