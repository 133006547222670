<div
  *ngIf="params.value"
  [ngClass]="{
    'last-command-status-cell': true,
    'capitalize-first-letter': true,
    'last-command-status-cell-success': params.value === 'success',
    'last-command-status-cell-failure': params.value === 'failure'
  }"
>
  <span>
    {{ 'IOT_DICTIONARY.' + params.value | translate }}
  </span>
</div>
<div *ngIf="!params.value" class="last-command-status-cell">-</div>
