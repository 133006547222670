import { Component, Input } from '@angular/core';

@Component({
  selector: 'iot-platform-maps-map-spinner',
  templateUrl: './map-spinner.component.html',
  styleUrls: ['./map-spinner.component.scss']
})
export class MapSpinnerComponent {
  @Input() loading = false;
  @Input() label!: string;
}
