import { User } from '@iot-platform/models/common';
import { createReducer, on } from '@ngrx/store';

import { PreferencesActions } from '../../../preferences/state/actions';
import { ProfileActions } from '../actions';

export const userProfileDbFeatureKey = 'userProfileDb';

export interface State {
  currentUser: User;
}

export const initialState: State = {
  currentUser: null
};

export const reducer = createReducer(
  initialState,
  on(ProfileActions.loadUserProfile, (state: State) => ({ ...state })),
  on(ProfileActions.loadUserProfileSuccess, (state: State, { user }) => ({ ...state, currentUser: user })),
  on(ProfileActions.saveUserProfile, (state: State, { userToUpdate }) => ({ ...state, currentUser: userToUpdate })),
  on(ProfileActions.saveUserProfileSuccess, (state: State, { updatedUser }) => ({ ...state, currentUser: updatedUser })),

  on(PreferencesActions.saveUserPreferencesSuccess, (state: State, { user, preferences }) => ({ ...state, currentUser: user }))
);

export const getCurrentUser = (state: State) => state.currentUser;
