import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Filter, PlatformRequest } from '@iot-platform/models/common';
import { AssetVariable, DeviceVariable, FormulaParameters } from '@iot-platform/models/i4b';
import { BehaviorSubject, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AssetVariablesService } from '../../../../../../../shared/src/lib/services/asset-variables.service';
import {
  ProcessMultipleStateVariablesUtil
} from '../../../../../../../shared/src/lib/utils/process-multiple-state-variables.util';

@Component({
  selector: 'iot4bos-ui-asset-variable-configure-read-popup',
  templateUrl: './asset-variable-configure-read-popup.component.html',
  styleUrls: ['./asset-variable-configure-read-popup.component.scss']
})
export class AssetVariableConfigureReadPopupComponent implements OnInit {
  assetVariable!: AssetVariable;
  parameters: Array<{ key: string; value: number }> = [];

  isLoading = false;

  isMultiStateVariable = false;
  processedValue: string | null = null;

  displaySourceAssetVariables = false;
  displaySourceDeviceVariable = false;
  variablesToDisplay: AssetVariable[] | DeviceVariable[] = [];

  metadata$: BehaviorSubject<AssetVariable> = new BehaviorSubject(this.data.assetVariable);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { assetVariable: AssetVariable }, private assetVariableService: AssetVariablesService) {}

  ngOnInit(): void {
    this.isLoading = true;
    if (this.data && this.data.assetVariable.id) {
      this.assetVariableService
        .getAssetVariableById(this.data.assetVariable.id)
        .pipe(
          tap((assetVariable: AssetVariable) => this.metadata$.next(assetVariable)),
          switchMap((assetVariable: AssetVariable) => {
            this.assetVariable = assetVariable;
            this.parameters = AssetVariableConfigureReadPopupComponent.getFormattedParameters(this.assetVariable.formula?.parameters as FormulaParameters);
            this.isMultiStateVariable = ProcessMultipleStateVariablesUtil.isUnitMultipleState(assetVariable.unit);

            if (this.isMultiStateVariable) {
              this.processedValue = ProcessMultipleStateVariablesUtil.getProcessedUnit(assetVariable);
            }

            this.displaySourceAssetVariables = Object.values(assetVariable.formula?.srcVariables ?? {})
              .map((srcVar) => srcVar.type)
              .includes('asset-variable');
            this.displaySourceDeviceVariable = Object.values(assetVariable.formula?.srcVariables ?? {})
              .map((srcVar) => srcVar.type)
              .includes('device-variable');

            if (this.displaySourceAssetVariables) {
              const assetId: string = Object.values(assetVariable.formula?.srcVariables ?? {})[0].originId as string;
              const assetVariablesIdsFilters: Filter[] = [
                ...new Set(
                  Object.values(assetVariable.formula?.srcVariables ?? {}).map((srcVar) => ({ criteriaKey: 'assetVariableId', value: srcVar.variableId }))
                )
              ];
              const request: PlatformRequest = { filters: assetVariablesIdsFilters, page: 0, limit: assetVariablesIdsFilters.length, urlParam: assetId };
              return this.assetVariableService.getAll(request).pipe(map((response) => response.data));
            } else if (this.displaySourceDeviceVariable) {
              return this.assetVariableService
                .getDeviceVariableById(Object.values(assetVariable.formula?.srcVariables ?? {})[0].variableId ?? '')
                .pipe(map((deviceVar) => [deviceVar]));
            } else {
              return of(undefined);
            }
          })
        )
        .subscribe((variablesToDisplay?: AssetVariable[] | DeviceVariable[]) => {
          this.isLoading = false;

          if (variablesToDisplay) {
            this.variablesToDisplay = variablesToDisplay;
          }
        });
    }
  }

  static getFormattedParameters(parameters: FormulaParameters): Array<{ key: string; value: number }> {
    const parametersEntries = Object.entries(parameters);
    return parametersEntries.map((p) => ({ key: p[0], value: p[1] }));
  }

  getTextualOperator(operator: string | undefined) {
    switch (operator) {
      case '=':
        return 'Equals';
      case '<':
        return 'Lower than';
      case '>':
        return 'Greater than';
      default:
        return '';
    }
  }
}
