import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { FOR_ROOT_OPTIONS_TOKEN, HttpLoaderFactory, I18nOptions, ModuleOptions, provideI18nOptions, TranslateInitializerFactory } from './i18n.config';

@NgModule({
  imports: [CommonModule, HttpClientModule, I18nModule.getInstance(), AgGridModule],
  providers: [
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: TranslateInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }
  ],
  exports: [TranslateModule]
})
export class I18nModule {
  // Workaround to load module in app main module
  static getInstance() {
    return TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    });
  }

  static forRoot(options?: ModuleOptions): ModuleWithProviders<I18nModule> {
    return {
      ngModule: I18nModule,
      providers: [
        {
          provide: FOR_ROOT_OPTIONS_TOKEN,
          useValue: options
        },
        {
          provide: I18nOptions,
          useFactory: provideI18nOptions,
          deps: [FOR_ROOT_OPTIONS_TOKEN]
        }
      ]
    };
  }
}
