import { Action, combineReducers } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';

import * as fromProducts from './products.reducer';

export const featureKey = 'oyanProducts';

export interface ProductsState {
  [fromProducts.featureKey]: fromProducts.State;
}

export interface State {
  [featureKey]: ProductsState;
}

export function reducers(state: ProductsState, action: Action): ActionReducer<ProductsState> | unknown {
  return combineReducers({
    [fromProducts.featureKey]: fromProducts.reducer
  })(state, action);
}
