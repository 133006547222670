import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IotToolbarDispatchActionType } from '@iot-platform/iot-platform-ui';
import {
  CommonApiRequest,
  Filter,
  IotToolbarEvent,
  MasterViewEngineEvent,
  PlatformResponse
} from '@iot-platform/models/common';
import { Gateway } from '@iot-platform/models/oyan';
import { SortUtil } from '../../../../../../../shared/src/lib/utils/sort.util';
import { BaseShellComponent } from '../../../../components/base-shell/base-shell.component';
import { OyanUiRoutingConstants } from '../../../../oyan-ui.router.constants';
import { AuthFacade } from '../../../auth/state/facades/auth.facade';
import { NavigationsFacade } from '../../../navigations/state/facades/navigations.facade';
import { GatewaysFacade } from '../../state/facades/gateways.facade';

/**
 * Business rules

 * The inactive items will be displayed first on the page
 * Status “Inactive” will be displayed in RED.
 */
@Component({
  selector: 'oyan-ui-gateways-shell',
  templateUrl: './gateways-shell.component.html',
  styleUrls: ['./gateways-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GatewaysShellComponent extends BaseShellComponent<Gateway> {
  constructor(protected authFacade: AuthFacade, protected gatewaysFacade: GatewaysFacade, private readonly navigationsFacade: NavigationsFacade) {
    super(authFacade, gatewaysFacade);
  }

  reLoadMasterView(page: number = this.pagination$.value.currentPage): void {
    const filters: Filter[] = this.currentFilters;
    if (!filters.find((f) => f.criteriaKey === 'entityId' && f.value === this.businessProfile.entityId)) {
      // This filter should be excluded to prevent displayed in the filter engine
      filters.push({ criteriaKey: 'entityId', value: this.businessProfile.entityId, excluded: true });
    }
    const request: CommonApiRequest = {
      page,
      limit: this.pagination$.value.limit,
      filters
    };
    this.gatewaysFacade.getAll(request);
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    switch (event.type) {
      case 'onSiteClickEvent':
        this.navigationsFacade.goToSiteOverview(event.rawData.site, OyanUiRoutingConstants.GATEWAYS);
        break;
      default:
        break;
    }
  }

  onToolbarEvent(event: IotToolbarEvent): void {
    super.onToolbarEvent(event);
    if (event.type === IotToolbarDispatchActionType.FILTER_TEXT_CHANGE) {
      this.onFilterTextChange(event.options);
    }
  }

  onKerlinkClick(): void {
    this.navigationsFacade.openKerlink();
  }

  sortData(platformResponse: PlatformResponse): PlatformResponse {
    return {
      ...platformResponse,
      data: platformResponse.data.sort(SortUtil.sortByProperty('connected'))
    };
  }

  private onFilterTextChange(searchString: string): void {
    this.subscriptions.push(
      this.gatewaysFacade.all$.subscribe((gateways: Gateway[]) => {
        let filteredList: Gateway[] = gateways ? [...gateways] : [];
        if (searchString !== null) {
          filteredList = filteredList.filter((gateway: Gateway) => {
            return (
              `${gateway.eui}`.toLowerCase().includes(searchString.toLowerCase()) ||
              (gateway.site &&
                (`${gateway.site.displayName}`.toLowerCase().includes(searchString.toLowerCase()) ||
                  `${gateway.site.name}`.toLowerCase().includes(searchString.toLowerCase())))
            );
          });
        }
        const platformResponse = {
          ...this.platformResponse$.value,
          data: filteredList
        };
        this.platformResponse$.next(this.sortData(platformResponse));
        this.totalItems$.next(filteredList.length);
      })
    );
  }
}
