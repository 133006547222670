import {
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  IotToolbarMenuButton,
  IotToolbarMenuButtonOption
} from '@iot-platform/iot-platform-ui';
import { FavoriteView, Filter } from '@iot-platform/models/common';

export function compareFilters(currentFilters: Filter[], currentFavoriteViewFilters: Filter[]): boolean {
  if (currentFilters && currentFavoriteViewFilters) {
    const array1 = JSON.stringify(currentFilters.sort());
    const array2 = JSON.stringify(currentFavoriteViewFilters.sort());
    return array1 === array2;
  } else {
    return false;
  }
}

export function setDisableFavoriteViewButtons(
  masterViewButtonList: Array<IotToolbarDefaultButton | IotToolbarMenuButton>,
  currentFilters: Filter[],
  currentFavoriteView: FavoriteView,
  canUpdateBusinessProfile: boolean,
  userId: string
): void {
  masterViewButtonList.forEach((button) => {
    if (button instanceof IotToolbarMenuButton) {
      button.menuOptions.forEach((option: IotToolbarMenuButtonOption) => {
        if (option.dispatchAction.type === IotToolbarDispatchActionType.CREATE_FAVORITE_VIEW) {
          option.disableOption = compareFilters(currentFilters, currentFavoriteView.filters);
        }
        if (
          option.dispatchAction.type === IotToolbarDispatchActionType.EDIT_FAVORITE_VIEW ||
          option.dispatchAction.type === IotToolbarDispatchActionType.DELETE_FAVORITE_VIEW
        ) {
          if (!canUpdateBusinessProfile) {
            if (currentFavoriteView?.shared) {
              option.disableOption = true;
              return;
            }
            option.disableOption = !currentFavoriteView.filters || currentFavoriteView?.owner !== userId;
          } else {
            option.disableOption = !currentFavoriteView.filters;
          }
        }
      });
    }
  });
}
