import { Component, Input } from '@angular/core';

@Component({
  selector: 'oyan-ui-cards-container',
  templateUrl: './cards-container.component.html',
  styleUrls: ['./cards-container.component.scss']
})
export class CardsContainerComponent {
  @Input() horizontalLayout: boolean;
  @Input() virtualScroll: boolean;
}
