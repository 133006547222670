<mat-card class="dialog-card variable-chart">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ data?.title ? data.title : data.variables[0]?.name }}</h1>
      <span fxFlex></span>
      <div>
        <button
          (click)="toggleTableView()"
          mat-icon-button
          [ngClass]="{ 'display-table-active': displayTableView }"
          [matTooltip]="(displayTableView ? 'VARIABLE_CHART.HIDE_TABLE_VIEW' : 'VARIABLE_CHART.DISPLAY_TABLE_VIEW') | translate"
          [matTooltipClass]="'regular-tooltip'"
        >
          <mat-icon>table_chart</mat-icon>
        </button>
        <button *ngIf="!fullscreen" (click)="enterFullscreen()" mat-icon-button class="fullscreen-button">
          <mat-icon class="material-icons"> fullscreen </mat-icon>
        </button>
        <button *ngIf="fullscreen" (click)="exitFullscreen()" mat-icon-button class="fullscreen-button">
          <mat-icon class="material-icons"> fullscreen_exit </mat-icon>
        </button>
        <button mat-icon-button class="regular-round-button" cdkFocusInitial (click)="close()"><mat-icon>close</mat-icon></button>
      </div>
    </mat-toolbar>
  </mat-card-title>

  <iot4bos-ui-variable-chart [data]="data?.variables" [variableType]="data.variableType" [options]="data?.options"></iot4bos-ui-variable-chart>
</mat-card>
