<div  class="detail-card" fxLayoutAlign="start center">
  <div fxFlex fxLayout="row" >
    <div >
      <mat-icon>filter_{{ followedNumber }}</mat-icon>
    </div>
    <span *ngIf="!followedVariable; else hasVariable" class="no-var">-</span>
    <ng-template #hasVariable>
      <div class="detail-card-item" fxLayout="column" >
        <div>
          <span >{{ followedVariable?.name | infoDisplay }}</span>
        </div>
        <div fxFlex fxLayout="row">
          <span *ngIf="!isMultipleStateVariable" class="fw-600">
            {{ followedVariable?.value | numberFormat | infoDisplay }} {{ followedVariable?.unit }}
          </span>
          <span *ngIf="isMultipleStateVariable">{{ processedValue }}</span>
          <span fxFlex>&nbsp;</span>
          <span>{{ followedVariable?.datetime | dateFormat }}</span>
        </div>
      </div>
    </ng-template>
  </div>
</div>
