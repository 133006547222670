import { Asset, DetectionStatus, FillingStatus } from '@iot-platform/models/oyan';
import { ProductBuilder } from './product.builder';
import { SiteBuilder } from './site.builder';

export class AssetBuilder {
  public static DEFAULT_ASSET = {
    id: '6f5db433-0e71-4053-a7e1-b22c3ba2c846',
    site: SiteBuilder.build('e03b49bc-d878-47b7-86d7-7157e68872aa', '0010747171'),
    name: '0758ITAC_1_2021078_094-21013509',
    product: ProductBuilder.build('f3aad3d0-4fc3-4be8-9b0b-23e358e1c64f', 'OXYGEN MED Cy-S Oyan Smart 11/200 NF'),
    createdAt: '2021-05-25T13:29:05.303996+00:00',
    updatedAt: null,
    updatedBy: null,
    alerts: {
      ventilator: false,
      leakage: false,
      battery: false
    },
    wardId: '192d7edf-e5cf-44ef-920c-e4c01327f522',
    originShipToId: 'A803509',
    receiverId: null,
    barcode: 'A803509',
    identifier: '21013509',
    level: 100,
    firstWardDetection: null,
    expiredAt: '2024-01-01T00:00:00+00:00',
    lotNumber: '0758ITAC_1_2021078_094',
    xDetection: null,
    lastMessageReceptionAt: null,
    detectionStatus: DetectionStatus.Detected,
    fillingStatus: FillingStatus.FULL
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static build = (id: string, params: Partial<Asset> = AssetBuilder.DEFAULT_ASSET): Asset | any => ({
    ...params,
    id
  });
}
