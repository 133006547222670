<div data-cy="iot-platform-ui-async-autocomplete" [formGroup]="searchForm">
  <mat-form-field class="full-width form-field" color="accent">
    <mat-icon *ngIf="displaySearchIcon" matPrefix class="search-icon">search</mat-icon>
    <ng-container *ngIf="autocomplete; else defaultInput">
      <input
        data-cy="iot-platform-ui-async-autocomplete-input-field"
        matInput
        type="text"
        formControlName="searchKey"
        [required]="required"
        [placeholder]="placeholder | translate"
        [matAutocomplete]="auto"
        #inputAutoComplete
        #trigger="matAutocompleteTrigger"
      />
    </ng-container>
    <ng-template #defaultInput>
      <input
        data-cy="iot-platform-ui-async-autocomplete-input-field"
        matInput
        type="text"
        formControlName="searchKey"
        [required]="required"
        [placeholder]="placeholder | translate"
      />
    </ng-template>
    <button
      type="button"
      mat-button
      *ngIf="!showSpinner && control?.value && !control?.disabled"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="resetControl($event)"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-hint>{{ hintMessage }}</mat-hint>
    <mat-spinner *ngIf="showSpinner" class="form-field_spinner" mode="indeterminate" diameter="16" color="accent"></mat-spinner>
    <mat-error *ngIf="required && errorMessage && control.invalid">{{ errorMessage | translate }}</mat-error>
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWrapper" (optionSelected)="onOptionSelected($event)">
    <ng-container *ngIf="!showSpinner">
      <mat-option data-cy="iot-platform-ui-async-autocomplete-option" *ngFor="let item of standaloneMode ? (filteredData$ | async) : data" [value]="item">
        <span>{{ displayWrapper(item) }}</span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</div>
