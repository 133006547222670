import { IotPlatformPipesModule, LinkifyPipeModule } from '@iot-platform/pipes';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { IotPlatformUiModule, UiModule } from '@iot-platform/iot-platform-ui';
import { SharedModule } from '@iot-platform/shared';
import { TableEngineModule } from '@iot-platform/table-engine';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { MasterViewCellModule } from '../../../../../../../table-engine/src/lib/components/table/cells/master-view-cell.module';
import { EventCommentsModule } from '../../../../components/event-comments/event-comments.module';
import { PanelModule } from '../../../../components/panel/panel.module';
import { DateFormatPipe } from '../../../../../../../iot-platform-pipes/src/lib/date-format/date-format.pipe';
import { DeviceCallLogModule } from '../../components/device-call-log/device-call-log.module';
import { DeviceCardComponent } from '../../components/device-card/device-card.component';
import { DeviceInfoFormComponent } from '../../components/device-info-form/device-info-form.component';
import { DeviceInfoToolbarModule } from '../../components/device-info-toolbar/device-info-toolbar.module';
import { DeviceInfoComponent } from '../../components/device-info/device-info.component';
import { DeviceLatestCommandStatusComponent } from '../../components/device-latest-command-status/device-latest-command-status.component';
import { DeviceVariablesTableComponent } from '../../components/device-variables-table/device-variables-table.component';
import { DeviceConnectorsService } from '../../../../../../../shared/src/lib/services/device-connectors.service';
import { DeviceStatusService } from '../../../../../../../shared/src/lib/services/device-status.service';
import { DeviceOverviewComponent } from './device-overview.component';
import { DeviceInfoStatusModule } from '../../../../../../../iot-platform-ui/src/lib/ui/components/device-details/device-info-status/device-info-status.module';

@NgModule({
  imports: [
    CommonModule,
    PanelModule,
    SharedModule,
    IotPlatformUiModule,
    UiModule,
    IotPlatformPipesModule,
    TranslateModule,
    DeviceCallLogModule,
    TableEngineModule,
    EventCommentsModule,
    HighchartsChartModule,
    MasterViewCellModule,
    DeviceInfoToolbarModule,
    GridEngineModule,
    DeviceInfoStatusModule,
    LinkifyPipeModule,
    DeviceLatestCommandStatusComponent
  ],
  declarations: [DeviceOverviewComponent, DeviceInfoComponent, DeviceInfoFormComponent, DeviceCardComponent, DeviceVariablesTableComponent],
  exports: [DeviceOverviewComponent, DeviceInfoComponent, DeviceCardComponent, DeviceVariablesTableComponent, DeviceLatestCommandStatusComponent],
  providers: [DeviceConnectorsService, DeviceStatusService, DateFormatPipe, TranslateService]
})
export class DeviceOverviewModule {}
