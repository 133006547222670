import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User, UserDateFormats } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserProfileService } from '../../services/user-profile.service';

@Component({
  selector: 'iot-platform-users-user-profile-preferences-form',
  templateUrl: './user-profile-preferences-form.component.html',
  styleUrls: ['./user-profile-preferences-form.component.scss']
})
export class UserProfilePreferencesFormComponent implements OnInit, OnDestroy {
  userPreferencesForm: UntypedFormGroup;

  availableLanguages: string[] = [];
  numberFormatList: string[] = ['fr', 'en'];
  dateFormatList: UserDateFormats[] = [];
  defaultNumberOfDecimals = 3;
  maxNumberOfDecimals = 15;
  numberOfDecimalsList: number[] = [...Array(this.maxNumberOfDecimals).keys()].map((x) => ++x);

  today = moment();

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly dialogRef: MatDialogRef<UserProfilePreferencesFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User; withNotifications: boolean },
    private readonly translateService: TranslateService,
    private readonly userProfileService: UserProfileService
  ) {}

  ngOnInit() {
    this.availableLanguages = this.translateService.langs;
    this.userProfileService
      .getAppDateFormats()
      .pipe(takeUntil(this.destroy$))
      .subscribe((formats) => {
        this.dateFormatList = formats;
        if (this.userPreferencesForm) {
          this.appDateFormats.setValue(
            this.dateFormatList.find((f) => this.data?.user?.preferences?.appDateFormats?.momentFullFormat === f.momentFullFormat) ?? this.dateFormatList[0]
          );
        }
      });

    this.userPreferencesForm = new UntypedFormGroup({
      appLanguage: new UntypedFormControl(this.data?.user?.preferences?.appLanguage ?? 'en'),
      appNumberFormat: new UntypedFormControl(this.data?.user?.preferences?.appNumberFormat ?? 'fr'),
      appNumberOfDecimals: new UntypedFormControl(this.data?.user?.preferences?.appNumberOfDecimals ?? this.defaultNumberOfDecimals),
      appDateFormats: new UntypedFormControl(this.data?.user?.preferences?.appDateFormats ?? []),
      notificationEmail: new UntypedFormControl(this.data?.user?.notificationEmail ?? false),
      notificationSMS: new UntypedFormControl(this.data?.user?.notificationSMS ?? false),
      notificationSecondaryEmail: new UntypedFormControl(this.data?.user?.notificationSecondaryEmail ?? false)
    });
  }

  get appLanguage(): AbstractControl {
    return this.userPreferencesForm.get('appLanguage');
  }

  get appNumberFormat(): AbstractControl {
    return this.userPreferencesForm.get('appNumberFormat');
  }

  get appNumberOfDecimals(): AbstractControl {
    return this.userPreferencesForm.get('appNumberOfDecimals');
  }

  get appDateFormats(): AbstractControl {
    return this.userPreferencesForm.get('appDateFormats');
  }

  get notificationSMS(): AbstractControl {
    return this.userPreferencesForm.get('notificationSMS');
  }

  get notificationEmail(): AbstractControl {
    return this.userPreferencesForm.get('notificationEmail');
  }

  get notificationSecondaryEmail(): AbstractControl {
    return this.userPreferencesForm.get('notificationSecondaryEmail');
  }

  onModelChange(): void {
    this.userPreferencesForm.markAsTouched();
  }

  save(): void {
    const updated: User = {
      ...this.data.user,
      preferences: {
        ...this.data.user.preferences,
        appLanguage: this.appLanguage.value,
        appNumberFormat: this.appNumberFormat.value,
        appNumberOfDecimals: this.appNumberOfDecimals.value,
        appDateFormats: this.appDateFormats.value
      },
      notificationEmail: this.notificationEmail.value,
      notificationSMS: this.notificationSMS.value,
      notificationSecondaryEmail: this.notificationSecondaryEmail.value
    };

    this.dialogRef.close(updated);
  }

  exit() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
