import { Component, Input, OnInit, Output } from '@angular/core';
import { UserCellService } from './user-cell.service';

@Component({
  selector: 'i4b-table-engine-user-cell',
  templateUrl: './user-cell.component.html',
  styleUrls: ['./user-cell.component.scss']
})
export class UserCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;
  @Input() cellInfo: any;

  @Output() dispatchEvent;

  isDataLoaded = false;
  dataToDisplay;

  constructor(private userCellService: UserCellService) {}

  ngOnInit() {
    if (this.data) {
      this.userCellService.getUserById(this.data).subscribe((user) => {
        this.isDataLoaded = true;
        this.dataToDisplay = user.firstname ? user.firstname + ' ' + user.lastname : user.lastname;
      });
    } else {
      this.isDataLoaded = true;
    }
  }
}
