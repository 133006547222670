import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Environment, User } from '@iot-platform/models/common';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserByIdService {
  constructor(@Inject('environment') private readonly environment: Environment, private readonly http: HttpClient) {}

  public getUserById(userId: string): Observable<string> {
    return this.http
      .get<User>(`${this.environment.api.url}/users/${userId}`)
      .pipe(map((user: User) => (user.firstname ? `${user.firstname} ${user.lastname}` : user.lastname)));
  }
}
