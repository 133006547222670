<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ title$ | async }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content" fxLayout="row">
    <iot-platform-ui-image-selector
      class=""
      (selectImage)="onSelectImage($event)"
      [galleryPath]="'devices-gallery.json'"
      [lastImageSaved]="imageUrl"
    ></iot-platform-ui-image-selector>

    <form class="full-width p-20" fxLayout="column" [formGroup]="deviceForm">
      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex color="accent">
          <input
            formControlName="name"
            matInput
            #nameInput
            required
            maxlength="30"
            pattern="\S.*"
            [placeholder]="'DEVICES.INFO_FORM.DEVICE_NAME' | translate"
          />
          <mat-error *ngIf="name.invalid && (name.errors.maxlength || name.errors.pattern)">{{ 'DEVICES.INFO_FORM.ERROR_MESSAGE.NAME' | translate }}</mat-error>
          <mat-error *ngIf="name.invalid && name.errors.duplicate">{{ 'DEVICES.INFO_FORM.ERROR_MESSAGE.DUPLICATE' | translate }}</mat-error>
          <mat-hint align="end">{{ nameInput.value?.length || 0 }}/30</mat-hint>
        </mat-form-field>

        <mat-form-field fxFlex color="accent">
          <input formControlName="identifier" matInput [placeholder]="'DEVICES.INFO_FORM.IDENTIFIER' | translate" />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex color="accent">
          <input formControlName="family" matInput required maxlength="20" [placeholder]="'DEVICES.INFO_FORM.FAMILY' | translate" />
          <mat-error *ngIf="family.invalid">{{ 'DEVICES.INFO_FORM.ERROR_MESSAGE.FAMILY' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex color="accent">
          <input formControlName="model" matInput maxlength="20" [placeholder]="'DEVICES.INFO_FORM.MODEL' | translate" />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex color="accent">
          <mat-select formControlName="incomingConnector" [placeholder]="'DEVICES.INFO_FORM.INCOMING_CONNECTOR' | translate">
            <mat-option [value]="connector" *ngFor="let connector of allConnectors">{{ connector.name }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex color="accent">
          <mat-select
            formControlName="outgoingConnector"
            [placeholder]="'DEVICES.INFO_FORM.OUTGOING_CONNECTOR' | translate"
            (selectionChange)="onOutgoingSelectorChange($event.value)"
          >
            <mat-option [value]="connector" *ngFor="let connector of allConnectors">{{ connector.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex color="accent">
          <mat-select formControlName="status" required [placeholder]="'DEVICES.INFO_FORM.STATUS' | translate">
            <mat-option [value]="status" *ngFor="let status of allStatusAllowed">{{ 'DEVICES.CARD.STATUS_LIST.' + status | translate }}</mat-option>
          </mat-select>
          <mat-error *ngIf="status.invalid">{{ 'DEVICES.INFO_FORM.ERROR_MESSAGE.STATUS' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex color="accent">
          <input #endpointInput formControlName="endpoint" matInput required maxlength="50" [placeholder]="'DEVICES.INFO_FORM.ENDPOINT' | translate" />
          <mat-error *ngIf="endpoint.invalid">{{ 'DEVICES.INFO_FORM.ERROR_MESSAGE.ENDPOINT' | translate }}</mat-error>
          <mat-hint align="end">{{ endpointInput.value?.length || 0 }}/50</mat-hint>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex color="accent">
          <input formControlName="login" matInput [placeholder]="'DEVICES.INFO_FORM.LOGIN' | translate" />
        </mat-form-field>

        <mat-form-field fxFlex color="accent">
          <input [type]="hide ? 'password' : 'text'" formControlName="password" matInput [placeholder]="'DEVICES.INFO_FORM.PASSWORD' | translate" />
          <mat-icon matSuffix (click)="togglePassword()" class="password-toggle">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex color="accent">
          <input formControlName="support" matInput maxlength="30" [placeholder]="'DEVICES.INFO_FORM.SUPPORT' | translate" />
        </mat-form-field>

        <mat-form-field fxFlex color="accent">
          <textarea #descriptionInput formControlName="description" matInput maxlength="300" [placeholder]="'DEVICES.INFO_FORM.DESCRIPTION' | translate"></textarea>
          <mat-hint align="end">{{ descriptionInput.value?.length || 0 }}/300</mat-hint>
        </mat-form-field>
      </div>

      <p class="dialog-form-tips">{{ 'DEVICES.INFO_FORM.MANDATORY_FIELDS' | translate }}</p>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="close()">{{ 'DEVICES.INFO_FORM.CANCEL' | translate }}</button>
    <button mat-raised-button class="button-regular" color="accent" [disabled]="!deviceForm.valid" (click)="save()">{{ action$ | async }}</button>
  </mat-card-actions>
</mat-card>
