import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BaseComponent,
  CLEAR_FILTERS_BUTTON_CONFIG,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  REFRESH_BUTTON_CONFIG,
  TOGGLE_FILTER_ENGINE_BUTTON_CONFIG
} from '@iot-platform/iot-platform-ui';
import {
  BaseFacade,
  BusinessProfile,
  CommonApiRequest,
  FavoriteView,
  Filter,
  IotToolbarEvent,
  MasterViewEngineEvent,
  Pagination,
  PlatformResponse
} from '@iot-platform/models/common';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthFacade } from '../../features/auth/state/facades/auth.facade';

@Component({
  template: ''
})
export abstract class BaseShellComponent<T> extends BaseComponent implements OnInit, OnDestroy {
  loaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  totalItems$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  pagination$: BehaviorSubject<Pagination> = new BehaviorSubject<Pagination>(null);
  platformResponse$: BehaviorSubject<PlatformResponse> = new BehaviorSubject<PlatformResponse>({
    total: 0,
    maxPage: 0,
    limit: 0,
    hasMore: false,
    data: [],
    currentPage: 0
  });
  currentFilters$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);
  filtersToDisplay$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);
  clearAppliedFilters$: Subject<boolean> = new Subject<boolean>();
  currentFavoriteView$: Observable<FavoriteView> = of(null);
  filterEngineOpened$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  currentFilters: Filter[] = [];
  excludedFilters: Filter[] = [];
  businessProfile: BusinessProfile;
  pageSize: number;
  pageSizeOptions: number[] = [10, 20, 30, 50, 100];

  toolbarButtonList: IotToolbarDefaultButton[] = [
    new IotToolbarDefaultButton({ ...CLEAR_FILTERS_BUTTON_CONFIG, tooltip: 'MV_TOOLBAR.TOOLTIP.CLEAR_APPLIED_FILTERS' }, 1),
    new IotToolbarDefaultButton({ ...TOGGLE_FILTER_ENGINE_BUTTON_CONFIG, tooltip: 'MV_TOOLBAR.TOOLTIP.SHOW_FILTER_ENGINE' }, 2),
    new IotToolbarDefaultButton({ ...REFRESH_BUTTON_CONFIG, tooltip: 'MV_TOOLBAR.TOOLTIP.REFRESH_MV' }, 3)
  ];

  protected constructor(protected authFacade: AuthFacade, protected facade: BaseFacade<T, Pagination, Filter>) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.facade.loaded$.subscribe((loaded: boolean) => this.loaded$.next(loaded)),
      this.facade.filters$.subscribe((filters: Filter[]) => this.currentFilters$.next(filters)),
      this.facade.pagination$.subscribe((pagination: Pagination) => this.pagination$.next(pagination)),
      this.facade.total$.subscribe((total: number) => this.totalItems$.next(total)),
      this.facade.platformResponse$.subscribe((response: PlatformResponse) => this.platformResponse$.next(this.sortData(response))),
      this.facade.loading$.subscribe((loading: boolean) => {
        this.toolbarButtonList.find((button: IotToolbarDefaultButton) => button.dispatchAction.type === IotToolbarDispatchActionType.REFRESH_PAGE).disabled =
          loading;
      }),
      this.filtersToDisplay$.subscribe((filters: Filter[]) => {
        this.toolbarButtonList.find(
          (button: IotToolbarDefaultButton) => button.dispatchAction.type === IotToolbarDispatchActionType.CLEAR_FILTERS_AND_RELOAD_DATA
        ).disabled = !filters.length;
      }),
      combineLatest([this.authFacade.selectedBusinessProfile$, this.pagination$, this.currentFilters$]).subscribe(
        ([businessProfile, pagination, currentFilters]) => {
          this.currentFilters = [...currentFilters];
          this.filtersToDisplay$.next(currentFilters.filter((f: Filter) => !f.excluded));
          this.businessProfile = businessProfile;
          if (businessProfile && pagination.limit && pagination.limit !== this.pageSize) {
            this.filterEngineOpened$.next(!!this.filtersToDisplay$.value.length);
            this.excludedFilters = currentFilters.filter((f: Filter) => f.excluded);
            this.pageSize = pagination.limit;
            this.onPageChange({
              ...pagination,
              currentPage: 0,
              maxPage: 0,
              total: 0
            });
          }
        }
      )
    );

    // Reload data when business profile changed
    if (this.authFacade.isBusinessProfileChanged$) {
      this.subscriptions.push(
        this.authFacade.isBusinessProfileChanged$.pipe(filter((changed: boolean) => changed)).subscribe(() => {
          this.clearAppliedFilters();
          this.onPageChange({
            ...this.pagination$.value,
            currentPage: 0,
            maxPage: 0,
            total: 0
          });
        })
      );
    }
  }

  onPageChange(pagination: Pagination): void {
    // pageSize should be set first to prevent reloading data twice
    this.pageSize = pagination.limit;
    this.pagination$.next(pagination);
    this.reLoadMasterView();
  }

  onShowFilter(): void {
    this.filterEngineOpened$.next(!this.filterEngineOpened$.value);
  }

  onRefreshClicked(): void {
    this.reLoadMasterView();
  }

  clearAppliedFilters(): void {
    this.clearAppliedFilters$.next(true);
    this.currentFilters$.next([]);
    this.currentFilters = [];
    this.excludedFilters = [];
  }

  onClearAppliedFiltersClicked(): void {
    this.clearAppliedFilters();
    this.reLoadMasterView();
  }

  onApplyFilters(filters: Filter[]): void {
    if (!filters || !filters.length) {
      this.excludedFilters = [];
    }
    const _filters: Filter[] = [...this.excludedFilters];
    filters.forEach((cf: Filter) => {
      if (!_filters.find((f: Filter) => f.criteriaKey === cf.criteriaKey && f.value === cf.value)) {
        _filters.push(cf);
      }
    });
    this.currentFilters = [..._filters];
    this.reLoadMasterView(0);
  }

  onToolbarEvent(event: IotToolbarEvent): void {
    switch (event.type) {
      case IotToolbarDispatchActionType.CLEAR_FILTERS_AND_RELOAD_DATA:
        this.onClearAppliedFiltersClicked();
        break;
      case IotToolbarDispatchActionType.REFRESH_PAGE:
        this.onRefreshClicked();
        break;
      case IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE:
        this.onShowFilter();
        break;
      default:
        break;
    }
  }

  sortData(platformResponse: PlatformResponse): PlatformResponse {
    return platformResponse;
  }

  reLoadMasterView(page: number = this.pagination$.value.currentPage): void {
    const request: CommonApiRequest = {
      page,
      limit: this.pagination$.value.limit,
      filters: this.currentFilters
    };
    this.facade.getAll(request);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.facade.setFilters([]);
  }

  abstract onMasterViewEngineEvent(event: MasterViewEngineEvent): void;
}
