import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AuthInterceptor, AuthService } from '@iot-platform/auth';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';

import { SharedModule } from '@iot-platform/shared';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthEffects } from '../../../../../auth/src/lib/state/effects/auth.effects';

import { IconModule } from '../../../../../iot-platform-ui/src/lib/ui/components/icon/icon.module';
import { TagCategoryListByConceptModule } from '../../../../../iot-platform-ui/src/lib/ui/components/tag-list-by-concept/tag-category-list-by-concept.module';

import { BusinessProfilesListModule } from '../../components/business-profiles-list/business-profiles-list.module';
// tslint:disable-next-line:max-line-length
import { OrganizationConceptsSelectionModule } from '../../components/organization-concepts-selection/organization-concepts-selection.module';
import { RolesCrudModule } from '../../components/roles-crud/roles-crud.module';
import { RolesListModule } from '../../components/roles-list/roles-list.module';
import { TagEditorModule } from '../../components/tag-editor/tag-editor.module';
import { UsersListModule } from '../../components/users-list/users-list.module';
import { OrganizationDetailComponent } from './components/organization-detail/organization-detail.component';
import { OrganizationsListComponent } from './components/organizations-list/organizations-list.component';
import { AdminOrganizationsComponent } from './containers/admin-organizations.component';
import { OrganizationsService } from './services/organizations.service';
import { AdminOrganizationsAdministratorsEffects } from './state/effects/admin-organizations-administrators.effects';
import { AdminOrganizationsRolesEffects } from './state/effects/admin-organizations-roles.effects';
import { AdminOrganizationsTagsEffects } from './state/effects/admin-organizations-tags.effects';
import { AdminOrganizationsEffects } from './state/effects/admin-organizations.effects';
import * as fromOrganizations from './state/reducers';
import { ErrorInterceptor } from '../../../../../auth/src/lib/error.interceptor';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    StoreModule.forFeature(fromOrganizations.adminOrganizationsFeatureKey, fromOrganizations.reducers),
    EffectsModule.forFeature([
      AdminOrganizationsEffects,
      AdminOrganizationsRolesEffects,
      AdminOrganizationsAdministratorsEffects,
      AdminOrganizationsRolesEffects,
      AdminOrganizationsTagsEffects
    ]),
    BusinessProfilesListModule,
    RolesListModule,
    RolesCrudModule,
    UsersListModule,
    TagEditorModule,
    TagCategoryListByConceptModule,
    IconModule,
    OrganizationConceptsSelectionModule,
    IotPlatformUiModule
  ],
  declarations: [AdminOrganizationsComponent, OrganizationsListComponent, OrganizationDetailComponent],
  exports: [AdminOrganizationsComponent],
  providers: [
    OrganizationsService,
    AdminOrganizationsEffects,
    AuthEffects,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ]
})
export class AdminOrganizationsModule {}
