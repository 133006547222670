<mat-card fxLayout="column" class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ title$ | async }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="closeOnCancel()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content fxLayout="column" class="dialog-card-content">
    <div class="p-20">
      <section [formGroup]="manageTagForm" fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="10px">
        <mat-form-field class="concept-select" color="accent">
          <mat-select formControlName="concept" [placeholder]="'MANAGE_TAGS_FORM.CONCEPT_PLACEHOLDER' | translate">
            <mat-select-trigger fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <!--<iot-platform-ui-icon [name]="concept.value.toLowerCase()" [color]="'#394C5A'" [ngClass]="['concept-icon', 'concept-icon' + concept.value.toLowerCase()]"></iot-platform-ui-icon>-->
              <mat-icon [svgIcon]="concept.value.toLowerCase()" [color]="'#394C5A'" [ngClass]="['concept-icon', 'concept-icon' + concept.value.toLowerCase()]"></mat-icon>
              <span class="concept-name">{{ concept.value }}</span>
            </mat-select-trigger>
            <mat-option [value]="concept" *ngFor="let concept of data.concepts">
              <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <!--<iot-platform-ui-icon [name]="concept" [color]="'#394C5A'" [ngClass]="['concept-icon', 'concept-icon' + concept]"></iot-platform-ui-icon>-->
                <mat-icon [svgIcon]="concept" [color]="'#394C5A'"></mat-icon>
                <span class="concept-name">{{ concept | uppercase }}</span>
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-progress-spinner *ngIf="!areTagsLoaded" [diameter]="20" mode="indeterminate" color="accent"></mat-progress-spinner>
      </section>

      <section class="tag-lists">
        <mat-accordion [multi]="true" class="tag-list-accordion">
          <mat-expansion-panel
            *ngFor="let tagsByEntity of allTags[concept.value.toUpperCase()]"
            [hideToggle]="
              (!tagsByEntity.tagCategories?.length && !data.editable) ||
              (!tagsByEntity.tagCategories?.length && data.editable && tagsByEntity.entityId !== data.currentEntityId)
            "
            [disabled]="
              (!tagsByEntity.tagCategories?.length && !data.editable) ||
              (!tagsByEntity.tagCategories?.length && data.editable && tagsByEntity.entityId !== data.currentEntityId)
            "
          >
            <mat-expansion-panel-header class="entity-header">
              <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                <span
                  class="entity-name-selected"
                  [ngClass]="{
                    'no-tag':
                      (!tagsByEntity.tagCategories?.length && !data.editable) ||
                      (!tagsByEntity.tagCategories?.length && data.editable && tagsByEntity.entityId !== data.currentEntityId)
                  }"
                >
                  <span class="entity-name">{{ tagsByEntity.entityName }}</span>
                  <span *ngIf="getSelectedTagsByEntityTotal(tagsByEntity.tagCategories) !== 0" class="entity-tag-selected">
                    {{ getSelectedTagsByEntityTotal(tagsByEntity.tagCategories) }}{{ 'MANAGE_TAGS_FORM.TAG_SELECTED' | translate }}
                  </span>
                </span>
                <div fxFlex class="panel-header-line"></div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let tagCategory of tagsByEntity.tagCategories" fxLayout="row" fxLayoutAlign="start start" class="tag-by-entity-container">
              <div
                *ngIf="(tagCategory?.entityId === data.currentEntityId && !data.editable) || tagCategory?.entityId !== data.currentEntityId"
                class="tag-by-entity"
                fxLayout="row"
              >
                <div class="tag-category-color-name" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                  <div class="tag-category-color" [ngStyle]="{ backgroundColor: tagCategory?.color }"></div>
                  <span class="tag-category-name capitalize-first-letter">{{ tagCategory?.name }}</span>
                </div>
                <mat-chip-list
                  *ngIf="(tagCategory?.entityId === data.currentEntityId && !data.editable) || tagCategory?.entityId !== data.currentEntityId"
                  fxFlex
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="4px"
                  class="tag-list-wrapper"
                  [disabled]="data.editable"
                >
                  <mat-chip
                    *ngFor="let tag of tagCategory?.labels"
                    [ngStyle]="{ backgroundColor: tagCategory?.color }"
                    [class.disabled]="tag.selected"
                    [class.not-disabled]="!tag.selected"
                    (click)="!data.editable && !tag.selected ? addSelectedTag(tagCategory, tag) : ''"
                    disableRipple="true"
                  >
                    {{ tag.name }}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <iot4bos-backoffice-ui-tag-editor
              *ngIf="tagsByEntity?.entityId === data.currentEntityId && data.editable"
              [concept]="concept.value"
              [organizationId]="data.currentEntityId"
              (changeValue)="onEditorChangeValue($event)"
            ></iot4bos-backoffice-ui-tag-editor>
          </mat-expansion-panel>
        </mat-accordion>
      </section>
    </div>

    <section *ngIf="!data.editable" class="selected-tags" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div class="fw-600">{{ 'MANAGE_TAGS_FORM.SELECTED' | translate }}</div>
      <mat-chip-list fxFlex *ngIf="selectedTags.length">
        <mat-chip
          *ngFor="let selectedTag of selectedTags"
          [ngStyle]="{ backgroundColor: selectedTag.color, cursor: canRemove ? 'pointer' : 'default' }"
          [class.selected]="selectedTag.selected"
          [class.removed]="!selectedTag.selected"
          (click)="canRemove && removeSelectedTag(selectedTag)"
          disableRipple="true"
          fxLayoutAlign="start center"
          fxLayoutGap="4px"
        >
          <mat-icon [svgIcon]="selectedTag.concept | lowercase"></mat-icon>
          <span>{{ selectedTag.categoryName }} : {{ selectedTag.name }}</span>
          <mat-icon *ngIf='canRemove'>close</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </section>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayoutAlign="end center" *ngIf="!data.editable">
    <button mat-button class="button-regular" (click)="closeOnCancel()">{{ 'MANAGE_TAGS_FORM.CANCEL' | translate }}</button>
    <button mat-raised-button color="accent" class="button-regular" [disabled]="disableSaveButton()" (click)="save()">
      {{ 'MANAGE_TAGS_FORM.CONFIRM' | translate }}
    </button>
  </mat-card-actions>

  <mat-card-actions class="dialog-card-actions" fxLayoutAlign="end center" *ngIf="data.editable">
    <button mat-raised-button color="accent" class="button-regular" [disabled]="!canClose" (click)="closeOnCancel()">{{ 'MANAGE_TAGS_FORM.DONE' | translate }}</button>
  </mat-card-actions>
</mat-card>
