import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { SharedModule } from '@iot-platform/shared';
import { MvToolbarModule } from 'libs/shared/src/lib/mv-toolbar/mv-toolbar.module';
import { EmailTemplatesShellComponent } from './containers/email-templates-shell/email-templates-shell.component';

@NgModule({
  imports: [CommonModule, SharedModule, IotPlatformUiModule, GridEngineModule, MvToolbarModule],
  declarations: [EmailTemplatesShellComponent],
  providers: [],
  exports: [EmailTemplatesShellComponent]
})
export class EmailTemplatesModule {}
