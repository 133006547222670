<mat-chip
  disableRipple="true"
  class="chip"
  [ngStyle]="{ color: color, backgroundColor: bckgColor }"
  (click)="onChipClick($event, chip)"
>
  <div fxLayout="row" fxLayoutAlign="start center"
       fxLayoutGap="4px">

  <mat-icon *ngIf="iconName" [svgIcon]="iconName"></mat-icon>
  <span class="chip-text">
    <span class="chip-category">{{ category | translate }}</span> : <span *ngIf="dateLabel">{{ dateLabel | dateFormat | translate }}</span
    ><span *ngIf="!dateLabel">{{ label | translate }}</span>
  </span>
  <mat-icon class="chip-button" (click)="onChipButtonClick($event, chip)" *ngIf="chipButton.display">{{ chipButton.name }}</mat-icon>
  </div>
</mat-chip>
