import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NavigationsEffects } from './state/effects/navigations.effects';
import * as fromNavigations from './state/reducers';

@NgModule({
  imports: [StoreModule.forFeature(fromNavigations.featureKey, fromNavigations.reducers), EffectsModule.forFeature([NavigationsEffects])]
})
export class NavigationsModule {}
