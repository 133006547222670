<mat-toolbar color="primary" class="breadcrumb-container" fxLayoutAlign="start center">
  <div class="origin" fxFlex fxLayoutAlign="center center" (click)="onReturnToOriginClick()">
    <mat-icon>keyboard_backspace</mat-icon>
  </div>

  <iot4bos-ui-site-breadcrumb-item *ngIf="site" [site]="site" (navigate)="navigate('site', $event)"> </iot4bos-ui-site-breadcrumb-item>

  <iot4bos-ui-asset-breadcrumb-item *ngIf="selectedAsset" [asset]="selectedAsset" (navigate)="close.emit()"> </iot4bos-ui-asset-breadcrumb-item>

  <iot4bos-ui-device-breadcrumb-item *ngIf="selectedDevice" [device]="selectedDevice" (navigate)="close.emit()"> </iot4bos-ui-device-breadcrumb-item>

  <span fxFlex></span>

  <button
    *ngIf="!selectedDevice && !selectedAsset && displayRefreshBtn"
    mat-mini-fab
    class="reverse-accent-button"
    [matTooltip]="'BREADCRUMB.TOOLTIPS.REFRESH_SITE_PAGE' | translate"
    matTooltipClass="regular-tooltip"
    (click)="onRefreshSiteClick()"
  >
    <mat-icon>refresh</mat-icon>
  </button>
  <button
    *ngIf="selectedDevice && displayRefreshBtn"
    mat-mini-fab
    class="reverse-accent-button"
    [matTooltip]="'BREADCRUMB.TOOLTIPS.REFRESH_DEVICE_PAGE' | translate"
    matTooltipClass="regular-tooltip"
    (click)="onRefreshDeviceClick()"
  >
    <mat-icon>refresh</mat-icon>
  </button>
  <button
    *ngIf="selectedAsset && displayRefreshBtn"
    mat-mini-fab
    class="reverse-accent-button"
    [matTooltip]="'BREADCRUMB.TOOLTIPS.REFRESH_ASSET_PAGE' | translate"
    matTooltipClass="regular-tooltip"
    (click)="onRefreshAssetClick()"
  >
    <mat-icon>refresh</mat-icon>
  </button>
  <button
    *ngIf="site?.type !== 'stock'"
    mat-mini-fab
    class="reverse-accent-button"
    [matTooltip]="'SITES.ASSOCIATIONS.BUTTON_TOOLTIP' | translate"
    matTooltipClass="regular-tooltip"
    (click)="onOpenAssociations()"
  >
    <mat-icon>account_tree</mat-icon>
  </button>
</mat-toolbar>
