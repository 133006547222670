import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'i4b-table-engine-icon-cell',
  templateUrl: './icon-cell.component.html',
  styleUrls: ['./icon-cell.component.scss']
})
export class IconCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;

  @Output() dispatchEvent;
  constructor() {}

  ngOnInit() {}
}
