import { I4BCellType, I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { I4bCellConcept } from '../enums/i4b-cell-concept.enum';
import { I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { NameColumn } from './i4b-name.column';

export class AssetNameColumn extends NameColumn {
  columnId = '8e1e28b0-76d9-11ec-8703-acde48001122-asset-name';
  className = AssetNameColumn;
  defaultOptions: I4BColumnOptions = { order: 0 };

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      {
        displayName: customOptions?.overrideHeaderName
          ? customOptions?.overrideHeaderName
          : customHeader?.displayName
          ? customHeader?.displayName
          : 'ASSET NAME',
        icon: 'asset'
      },
      {
        id: 'name',
        translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ASSET_NAME',
        concept: I4bCellConcept.ASSETS,
        isDefault: true,
        isConfigurable: true,
        width: customOptions?.width ? customOptions.width : 150,
        cell: {
          type: I4BCellType.BASIC_LINK,
          event: { type: 'navigateToAsset' }
        }
      },
      { ...customOptions }
    );
  }
}
