import { Sort } from '@angular/material/sort';
import {
  FavoriteView,
  Filter,
  gridSortModel,
  PlatformRequest,
  PlatformResponse,
  TagCategory
} from '@iot-platform/models/common';
import { I4BBulkOperationApiResponse, Site } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const addSite = createAction('[Sites] Add Site', props<{ siteToAdd: Site }>());
export const addSiteSuccess = createAction('[Sites] Add Site Success', props<{ siteAdded: Site }>());
export const addSiteFailure = createAction('[Sites] Add Site Failure', props<{ error: any }>());

export const deleteSite = createAction('[Sites] Delete Site', props<{ siteToDelete: Site }>());
export const deleteSiteSuccess = createAction('[Sites] Delete Site Success', props<{ deletedSite: Site }>());
export const deleteSiteFailure = createAction('[Sites] Delete Site Failure', props<{ error: any }>());

export const filterSites = createAction('[SITES] Filter Sites', props<{ filter: string }>());

export const loadSiteById = createAction('[Sites] Load Site By Id', props<{ siteId: string }>());
export const loadSiteByIdSuccess = createAction('[Sites] Load Site By Id Success', props<{ site: Site }>());
export const loadSiteByIdFailure = createAction('[Sites] Load Site By Id Failure', props<{ error: any }>());

export const loadAssetsBySiteId = createAction('[Sites] Load Assets By Site Id', props<{ request: PlatformRequest }>());
export const loadAssetsBySiteIdSuccess = createAction('[Sites] Load Assets By Site Id Success', props<{ response: PlatformResponse }>());
export const loadAssetsBySiteIdFailure = createAction('[Sites] Load Assets By Site Id Failure', props<{ error: any }>());

export const loadDevicesBySiteId = createAction('[Sites] Load Devices By Site Id', props<{ request: PlatformRequest }>());
export const loadDevicesBySiteIdSuccess = createAction('[Sites] Load Devices By Site Id Success', props<{ response: PlatformResponse }>());
export const loadDevicesBySiteIdFailure = createAction('[Sites] Load Devices By Site Id Failure', props<{ error: any }>());

export const loadAssociationsBySiteId = createAction('[Sites] Load Associations By Site Id', props<{ siteId: string }>());
export const loadAssociationsBySiteIdSuccess = createAction('[Sites] Load Associations By Site Id Success', props<{ associations: any }>());
export const loadAssociationsBySiteIdFailure = createAction('[Sites] Load Associations By Site Id Failure', props<{ error: any }>());

export const loadTagsBySiteId = createAction('[Sites] Load Tags By Site Id', props<{ siteId: string }>());
export const loadTagsBySiteIdSuccess = createAction('[Sites] Load Tags By Site Id Success', props<{ tags: TagCategory[] }>());
export const loadTagsBySiteIdFailure = createAction('[Sites] Load Tags By Site Id Failure', props<{ error: any }>());

export const loadSites = createAction('[SITES] Load Sites', props<{ request: PlatformRequest }>());
export const loadSitesSuccess = createAction('[SITES] Load Sites Success', props<{ response: PlatformResponse }>());
export const loadSitesFailure = createAction('[SITES] Load Sites Failure', props<{ error: any }>());

// TODO : Replace the 3 following actions by one openDetails
export const openSiteDetail = createAction('[Sites] Open Site Detail', props<{ siteId: string }>());
export const openSiteDetailSuccess = createAction('[Sites] Open Site Detail Success', props<{ site: Site }>());
export const openSiteDetailFailure = createAction('[Sites] Open Site Detail Failure', props<{ error: any }>());

export const openAssetDetail = createAction('[Sites] Open Asset Detail', props<{ siteId: string }>());
export const openAssetDetailSuccess = createAction('[Sites] Open Asset Detail Success', props<{ site: Site }>());
export const openAssetDetailFailure = createAction('[Sites] Open Asset Detail Failure', props<{ error: any }>());

export const openDeviceDetail = createAction('[Sites] Open Device Detail', props<{ siteId: string }>());
export const openDeviceDetailSuccess = createAction('[Sites] Open Device Detail Success', props<{ site: Site }>());
export const openDeviceDetailFailure = createAction('[Sites] Open Device Detail Failure', props<{ error: any }>());

export const saveCurrentFavoriteView = createAction('[Sites] Save Current Favorite View', props<{ currentFavoriteView: FavoriteView }>());
export const saveCurrentFilters = createAction('[Sites] Save Current Filters', props<{ currentFilters: Filter[] }>());

// TODO : Replace this action to be more explicit as the store is a table state...
export const selectSite = createAction('[Sites] Select Site', props<{ selectedSite: Site }>());
export const selectSiteSuccess = createAction('[Sites] Select Site Success', props<{ selectedSite: Site }>());
export const selectSiteFailure = createAction('[Sites] Select Site Failure', props<{ error: any }>());

export const updateSite = createAction('[Sites] Update Site', props<{ siteToUpdate: Site }>());
export const updateSiteSuccess = createAction('[Sites] Update Site Success', props<{ updatedSite: Site }>());
export const updateSiteFailure = createAction('[Sites] Update Site Failure', props<{ error: any }>());

export const updateTagsBySiteId = createAction('[Sites] Update Tags By Site Id', props<{ siteId: string; tags: TagCategory[] }>());
export const updateTagsBySiteIdSuccess = createAction('[Sites] Update Tags By Site Id Success', props<{ tags: TagCategory[] }>());
export const updateTagsBySiteIdFailure = createAction('[Sites] Update Tags By Site Id Failure', props<{ error: any }>());

export const loadMVSettings = createAction('[Sites] Load Sites Settings', props<{ settingName: string }>());
export const loadMVSettingsSuccess = createAction('[Sites] Load MV Sites Settings Success', props<{ settings: any }>());
export const loadMVSettingsFailure = createAction('[Sites] Load MV Sites Settings Failure', props<{ error: any }>());
//
export const saveMVSettings = createAction('[Sites] Save Sites Settings', props<{ name: string; values: any }>());
export const saveMVSettingsSuccess = createAction('[Sites] Save MV Sites Settings Success', props<{ settings: any }>());
export const saveMVSettingsFailure = createAction('[Sites] Save MV Sites Settings Failure', props<{ error: any }>());

//
export const bulkAddOrRemoveTag = createAction('[Sites] Bulk Add or Remove Tag', props<{ isAddition: boolean; tagId: string; sitesIds: string[] }>());
export const bulkAddTagSuccess = createAction('[Sites] Bulk Add Tag - ', props<{ response: I4BBulkOperationApiResponse }>());
export const bulkAddTagFailure = createAction('[Sites] Bulk Add Tag Failure', props<{ error: any }>());
export const bulkRemoveTagSuccess = createAction('[Sites] Bulk Remove Tag - ', props<{ response: I4BBulkOperationApiResponse }>());
export const bulkRemoveTagFailure = createAction('[Sites] Bulk Remove Tag Failure', props<{ error: any }>());

export const saveInitialSort = createAction('[Sites] Save Initial sort', props<{ initialSort: gridSortModel[] | Sort }>());
