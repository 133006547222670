<mat-checkbox *ngIf="cellOptions?.mode === 'checkbox'" [(ngModel)]="data" color="accent" [disabled]="cellOptions.disabled"></mat-checkbox>

<div
  *ngIf="cellOptions?.mode !== 'checkbox' && data"
  [ngClass]="{ 'translated-cell': true, withStatusPadding: this.params.cellOptions?.withStatusPadding }"
  [ngStyle]="translateStyle"
>
  <span>
    {{ translateKey + (cellOptions?.applyLowerCase ? data.toLowerCase() : data) | translate }}
  </span>
</div>
<div *ngIf="!data" class="translated-cell" [ngStyle]="translateStyle">-</div>
