<mat-expansion-panel [expanded]="expanded">
  <div class="filter-engine-row" fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between stretch">
    <div class="filter-engine-col" fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
      <div class="filter-engine-edition" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div class="filter-engine-title" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon>filter_list</mat-icon>
          <span>{{ 'FILTER_ENGINE.TITLE' | translate }}</span>
          <div class="filter-engine-form" fxLayout="row wrap" fxLayoutAlign="start center">
            <button
              [disabled]="readonly"
              [matMenuTriggerFor]="filterCriteria"
              class="filter-engine-menu"
              data-cy="iot-platform-ui-filter-engine-menu-btn"
              mat-button
              type="button"
            >
              <span>{{ filterCriteriaButtonTitle | translate | uppercase }}</span>
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #filterCriteria="matMenu">
              <ng-container *ngFor="let category of categories">
                <button
                  (click)="addField(category, true)"
                  *ngIf="category.root; else cascadeMenu"
                  data-cy="iot-platform-ui-filter-engine-menu-item-btn"
                  mat-menu-item
                >
                  {{ category.label | translate | uppercase }}
                </button>
                <ng-template #cascadeMenu
                  ><button [matMenuTriggerFor]="subMenu" data-cy="iot-platform-ui-filter-engine-cascade-menu-btn" mat-menu-item>
                    {{ category.label | translate | uppercase }}
                  </button>
                  <mat-menu #subMenu="matMenu">
                    <button
                      (click)="addField(option)"
                      *ngFor="let option of category.options"
                      data-cy="iot-platform-ui-filter-engine-cascade-menu-item-btn"
                      mat-menu-item
                    >
                      {{ option.label | translate | uppercase }}
                    </button>
                  </mat-menu></ng-template
                >
              </ng-container>
            </mat-menu>
            <span class="filter-engine-field"><ng-template iotPlatformUiFilterEngine></ng-template></span>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
        <span
          *ngIf="currentFiltersNotHidden?.length"
          [ngClass]="{ 'filter-engine-max-capacity': currentFiltersNotHidden.length === maxFilters }"
          class="filters-number"
          >{{ currentFiltersNotHidden.length }}/{{ maxFilters }}</span
        >
        <mat-chip-list *ngIf="currentFiltersNotHidden?.length !== 0" class="filter-engine-selected" fxLayout="row wrap" fxLayoutAlign="start center">
          <iot-platform-ui-chip
            (chipButtonClick)="onDeletePreFilter($event)"
            *ngFor="let filter of currentFiltersNotHidden"
            [chipButton]="{ display: !readonly, name: 'close' }"
            [chip]="filter"
          ></iot-platform-ui-chip>
          <button
            (click)="onClearAllFilters()"
            *ngIf="currentFiltersNotHidden?.length && displayActionButtons"
            class="action-link"
            color="accent"
            mat-stroked-button
          >
            {{ 'FILTER_ENGINE.CLEAR_ALL' | translate }}
          </button>
          <button (click)="onResetFavoriteView()" *ngIf="displayResetButton() && displayActionButtons" class="action-link" color="accent" mat-stroked-button>
            {{ 'FILTER_ENGINE.RESET' | translate }}
          </button>
        </mat-chip-list>
      </div>
    </div>
  </div>
</mat-expansion-panel>

<div
  *ngIf="!expanded && currentFiltersNotHidden?.length"
  class="filter-engine-row filter-engine-applied"
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutGap="10px"
>
  <span [ngClass]="{ 'filter-engine-max-capacity': currentFiltersNotHidden.length === maxFilters }" class="filters-number"
    >{{ currentFiltersNotHidden.length }}/{{ maxFilters }}</span
  >
  <mat-chip-list>
    <iot-platform-ui-chip *ngFor="let filter of currentFiltersNotHidden" [chipButton]="{ display: false }" [chip]="filter"></iot-platform-ui-chip>
    <button (click)="onClearAllFilters()" *ngIf="displayActionButtons" class="action-link" color="accent" mat-stroked-button>
      {{ 'FILTER_ENGINE.CLEAR_ALL' | translate }}
    </button>
    <button (click)="onResetFavoriteView()" *ngIf="displayResetButton() && displayActionButtons" class="action-link" color="accent" mat-stroked-button>
      {{ 'FILTER_ENGINE.RESET' | translate }}
    </button>
  </mat-chip-list>
</div>
