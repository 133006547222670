import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Asset } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-asset-details-followed-variables',
  templateUrl: './asset-details-followed-variables.component.html',
  styleUrls: ['./asset-details-followed-variables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetDetailsFollowedVariablesComponent implements OnInit {
  @Input() asset: Asset | null = null;
  @Input() vars: any = {};

  maxFollowedNumber = 3;
  arrayToIterateForFollowedNumbers = new Array(this.maxFollowedNumber).fill(null).map((x, i) => i + 1);

  constructor() {}

  ngOnInit(): void {}
}
