<div
  [matTooltip]="tooltipMessage"
  matTooltipPosition="above"
  matTooltipClass="regular-tooltip"
  *ngIf="mostRecentCommand"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="10px"
  class="dlcs-container dlcs-step-ongoing"
  [ngClass]="{
    'dlcs-step-validated': mostRecentCommand.state === 'completed',
    'dlcs-step-error': mostRecentCommand.state === 'error'
  }"
>
  <mat-progress-spinner *ngIf="mostRecentCommand.state === 'ongoing'" [diameter]="20" mode="indeterminate"></mat-progress-spinner>
  <mat-icon *ngIf="mostRecentCommand.state !== 'ongoing'" [svgIcon]="mostRecentCommand.iconToDisplay?.name" class="dlcs-icon"></mat-icon>
  <div fxLayout="column">
    <div class="capitalize-first-letter">{{ 'DEVICES.COMMANDS_STATUSES.LAST_OUTGOING_CALL' | translate }}{{ mostRecentCommand.commandName }}</div>
    <div class="capitalize-first-letter">
      {{ 'DEVICES.COMMANDS_STATUSES.' + (mostRecentCommand.state === 'error' ? 'error' : mostRecentCommand.commandStatus.status.toUpperCase()) | translate }}:
      {{ mostRecentCommand.commandStatus.timestamp | dateFormat }}
    </div>
  </div>
</div>
