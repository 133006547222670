import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomEncoder } from '@iot-platform/core';
import { HistoryLog, HistoryLogDetail } from '@iot-platform/models/audit-trail';
import { CommonIndexedPagination, User } from '@iot-platform/models/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailService {
  users$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  userIds$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  constructor(private readonly httpClient: HttpClient, @Inject('environment') private readonly environment) {}

  getHistoryLogByConceptAndConceptId(concept: string, conceptId: string): Observable<HistoryLog[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });

    params = params.set('concept', concept);
    params = params.set('conceptId', conceptId);

    return this.httpClient
      .get<{ content: HistoryLog[]; page: CommonIndexedPagination }>(`${this.environment.api.url}${this.environment.api.endpoints.auditTrail}`, { params })
      .pipe(map((response) => response.content));
  }

  getHistoryLogById(logId: string): Observable<HistoryLogDetail> {
    return this.httpClient.get<HistoryLogDetail>(`${this.environment.api.url}${this.environment.api.endpoints.auditTrail}/${logId}`);
  }
}
