import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { CustomEncoder } from '@iot-platform/core';

import {
  BusinessProfile,
  CommonApiListResponse,
  Entity,
  Organization,
  Role,
  TagCategory,
  User
} from '@iot-platform/models/common';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortEntitiesUtil } from '../../../../../../shared/src/lib/utils/sort-entities.util';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  constructor(@Inject('environment') private environment, private http: HttpClient) {}

  getOrganizations(): Observable<Organization[]> {
    return this.http
      .get<CommonApiListResponse<Entity>>(`${this.environment.api.url}/entities`)
      .pipe(map((response) => SortEntitiesUtil.sortHierarchically(response.content) as Organization[]));
  }

  getOrganizationById(organizationId): Observable<Organization> {
    return this.http.get<Organization>(`${this.environment.api.url}/entities/${organizationId}`);
  }

  updateOrganization(organizationToUpdate: Organization): Observable<Organization> {
    return this.http.put<Organization>(`${this.environment.api.url}/entities/${organizationToUpdate.id}`, organizationToUpdate);
  }

  addOrganization(organizationToAdd: Organization): Observable<Organization> {
    return this.http.post<Organization>(`${this.environment.api.url}/entities`, organizationToAdd);
  }

  deleteOrganization(organizationToDelete: Organization): Observable<Organization> {
    return this.http.delete(`${this.environment.api.url}/entities/${organizationToDelete.id}`).pipe(map((_) => organizationToDelete));
  }

  getConcepts(): Observable<[string]> {
    return this.http.get<[string]>(`${this.environment.api.url}/concepts`).pipe(map((response: [string]) => response));
  }

  updateConceptInOrganization(organizationToUpdateId: string, concepts: string[]): Observable<Organization> {
    return this.http.put<Organization>(`${this.environment.api.url}/entities/${organizationToUpdateId}`, {
      adminConcepts: concepts
    });
  }

  getRolesByOrganization(organizationId: string): Observable<Role[]> {
    return this.http.get(`${this.environment.api.url}/roles?entityId=${organizationId}`).pipe(map((response: { page: any; content: any }) => response.content));
  }

  updateRoleForOrganization(roleToUpdate: Role): Observable<Role> {
    return this.http.put<Role>(`${this.environment.api.url}/roles/${roleToUpdate.id}`, {
      rights: roleToUpdate.rights,
      name: roleToUpdate.name,
      entityId: roleToUpdate.entityId
    });
  }

  addRoleToOrganization(roleToAdd: Role): Observable<Role> {
    return this.http.post<Role>(`${this.environment.api.url}/roles`, roleToAdd);
  }

  removeRoleFromOrganization(roleToDelete: Role): Observable<Role> {
    return this.http.delete(`${this.environment.api.url}/roles/${roleToDelete.id}`).pipe(map((_) => roleToDelete));
  }

  getBusinessProfilesByOrganization(organizationId: string): Observable<BusinessProfile[]> {
    return this.http
      .get(`${this.environment.api.url}/business-profiles?entityId=${organizationId}`)
      .pipe(map((response: { page: any; content: any }) => response.content));
  }

  saveBusinessProfile(businessProfileToSave: BusinessProfile): Observable<BusinessProfile> {
    return this.http.post<BusinessProfile>(`${this.environment.api.url}/business-profiles/`, businessProfileToSave);
  }

  deleteBusinessProfile(businessProfile: BusinessProfile): Observable<BusinessProfile> {
    return this.http.delete(`${this.environment.api.url}/business-profiles/${businessProfile.id}`).pipe(map((_) => businessProfile));
  }

  getTagsByOrganization(organizationId: string, withParents: boolean, concept?: string): Observable<TagCategory[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('entityId', organizationId);
    params = params.set('withParents', withParents.toString());

    if (concept) {
      params = params.set('concept', concept.toUpperCase());
    }

    return this.http.get(`${this.environment.api.url}/tag-categories`, { params }).pipe(map((response: { page: any; content: any }) => response.content));
  }

  addTagToOrganization(newTag: TagCategory): Observable<TagCategory> {
    return this.http.post<TagCategory>(`${this.environment.api.url}/tag-categories/`, newTag).pipe(map((response) => response));
  }

  updateTagInOrganization(updatedTag: TagCategory): Observable<TagCategory> {
    return this.http.put<TagCategory>(`${this.environment.api.url}/tag-categories/${updatedTag.id}`, updatedTag).pipe(map((response) => response));
  }

  removeTagFromOrganization(tagToRemove: TagCategory): Observable<TagCategory> {
    return this.http.delete<TagCategory>(`${this.environment.api.url}/tag-categories/${tagToRemove.id}`).pipe(map((response) => tagToRemove));
  }

  getAdministratorsByOrganization(organizationId: string): Observable<User[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', '100');

    return this.http
      .get<{ page: any; content: User[] }>(`${this.environment.api.url}/entities/${organizationId}/admin-profiles`, { params })
      .pipe(map((response: { page: any; content: User[] }) => response.content));
  }

  addAdministratorToOrganization(organizationId: string, administratorToAdd: User): Observable<User> {
    return this.http
      .post<User>(`${this.environment.api.url}/entities/${organizationId}/admin-profiles/${administratorToAdd.id}`, {})
      .pipe(map((_) => administratorToAdd));
  }

  removeAdministratorFromOrganization(organizationId: string, administratorToRemove: User): Observable<string> {
    return this.http
      .delete(`${this.environment.api.url}/entities/${organizationId}/admin-profiles/${administratorToRemove.id}`)
      .pipe(map((_) => administratorToRemove.id));
  }
}
