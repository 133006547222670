import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationManagementService } from '../../services/translation-management.service';

@Component({
  selector: 'i18n-translation-toolbar',
  templateUrl: './translation-toolbar.component.html',
  styleUrls: ['./translation-toolbar.component.scss']
})
export class TranslationToolbarComponent {
  @Input() public isEditMode: boolean;
  @Input() public keyCount: number;
  @Input() public showClearSelectedButton: boolean;
  @Input() public languages: string[] = [];

  @Output() public editMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public clear: EventEmitter<void> = new EventEmitter<void>();
  @Output() public export: EventEmitter<string> = new EventEmitter<string>();
  @Output() public addLanguage: EventEmitter<string> = new EventEmitter<string>();
  @Output() public deleteLanguage: EventEmitter<string> = new EventEmitter<string>();

  constructor(private readonly translationManagementService: TranslationManagementService) {}

  public get languagesToRemove(): string[] {
    const defaultLanguages = this.translationManagementService.getLanguages();
    return this.languages.filter((l) => !defaultLanguages.includes(l));
  }

  public toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
    this.editMode.emit(this.isEditMode);
  }

  public onExport(lang: string): void {
    this.export.emit(lang);
  }

  public onClear(): void {
    this.clear.emit();
  }

  public onAddLanguage(): void {
    this.addLanguage.emit();
  }

  public onDeleteLanguage(lang: string): void {
    this.deleteLanguage.emit(lang);
  }
}
