import { Role } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { BusinessProfilesPageActions, BusinessProfilesRolesApiActions } from '../actions/';

export const adminBusinessProfilesRolesApiFeatureKey = 'adminBusinessProfilesRolesApi';

export interface State extends EntityState<Role> {
  selectedRoleId: string;
  error: any;
}

export const adapter: EntityAdapter<Role> = createEntityAdapter<Role>({
  selectId: (role: Role) => role.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedRoleId: null,
  error: null
});

export const adminBusinessProfilesRolesReducer = createReducer(
  initialState,
  on(BusinessProfilesPageActions.listRolesByBusinessProfile, (state, { businessProfileId }) => adapter.removeAll(state)),
  on(BusinessProfilesRolesApiActions.listRolesByBusinessProfileSuccess, (state, { roles }) => adapter.setAll(roles, state)),

  on(BusinessProfilesRolesApiActions.addRoleToBusinessProfileSuccess, (state, { addedRole }) => adapter.addOne(addedRole, state)),
  on(BusinessProfilesRolesApiActions.addRoleToBusinessProfileFailure, (state, { error }) => ({ ...state, error: error })),

  on(BusinessProfilesRolesApiActions.removeRoleFromBusinessProfileSuccess, (state, { removedRole }) => adapter.removeOne(removedRole.id, state)),
  on(BusinessProfilesRolesApiActions.removeRoleFromBusinessProfileFailure, (state, { error }) => ({ ...state, error: error }))
);

export function reducer(state: State | undefined, action: Action) {
  return adminBusinessProfilesRolesReducer(state, action);
}

export const getSelectedRoleId = (state: State) => state.selectedRoleId;
