import { AfterContentInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { Store } from '@ngrx/store';

import * as fromUserPreferences from '../../preferences/state/reducers';
import { PreferencesActions } from '../state/actions';

@Component({
  selector: 'iot-platform-users-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss']
})
export class UserPreferencesComponent implements OnInit, AfterContentInit {
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  userPrefsForm: UntypedFormGroup = new UntypedFormGroup({
    mvSites: new UntypedFormControl(''),
    mvAssets: new UntypedFormControl(''),
    mvDevices: new UntypedFormControl('')
  });

  user;

  constructor(public store: Store<fromUserPreferences.State>, private dialogRef: MatDialogRef<UserPreferencesComponent>) {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.store.select(fromUserPreferences.getCurrentUser).subscribe((user) => {
      if (user && user.preferences) {
        this.user = user;
        this.userPrefsForm.get('mvSites').setValue(user.preferences.mvSites ? user.preferences.mvSites.toString(10) : '25');
        this.userPrefsForm.get('mvAssets').setValue(user.preferences.mvAssets ? user.preferences.mvAssets.toString(10) : '25');
        this.userPrefsForm.get('mvDevices').setValue(user.preferences.mvDevices ? user.preferences.mvDevices.toString(10) : '25');
      }
    });
  }

  closePopup() {
    this.dialogRef.close();
  }
  onSubmit() {
    const updated = {
      ...this.user,
      preferences: {
        ...this.user.preferences,
        mvSites: parseInt(this.userPrefsForm.get('mvSites').value, 10),
        mvAssets: parseInt(this.userPrefsForm.get('mvAssets').value, 10),
        mvDevices: parseInt(this.userPrefsForm.get('mvDevices').value, 10)
      }
    };

    this.store.dispatch(PreferencesActions.saveUserPreferences({ user: updated, preferences: updated.preferences }));
    this.closePopup();
    // this.close.emit(true);
  }
}
