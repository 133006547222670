import { ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { HeaderType, I4BCellType } from '@iot-platform/models/grid-engine';
import { ActiveEventsCellComponent } from '../../table/cells/active-events-cell/active-events-cell.component';
import { ArrayOfObjectsCellComponent } from '../../table/cells/array-of-objects-cell/array-of-objects-cell.component';
import { AssetVariableCellComponent } from '../../table/cells/asset-variable-cell/asset-variable-cell.component';
import { AssetVariableThresholdCellComponent } from '../../table/cells/asset-variable-threshold-cell/asset-variable-threshold-cell.component';
import { BasicCellComponent } from '../../table/cells/basic-cell/basic-cell.component';
import { BasicLinkCellComponent } from '../../table/cells/basic-link-cell/basic-link-cell.component';
import { BooleanCellComponent } from '../../table/cells/boolean-cell/boolean-cell.component';
import { ButtonCellComponent } from '../../table/cells/button-cell/button-cell.component';
import { ColorCellComponent } from '../../table/cells/color-cell/color-cell.component';
import { CommentCellComponent } from '../../table/cells/comment-cell/comment-cell.component';
import { CountCellComponent } from '../../table/cells/count-cell/count-cell.component';
import { CyclicalCallFaultCellComponent } from '../../table/cells/cyclical-call-fault-cell/cyclical-call-fault-cell.component';
import { DateCellComponent } from '../../table/cells/date-cell/date-cell.component';
import { DynamicDataComponent } from '../../table/cells/dynamic-data/dynamic-data.component';
import { DynamicIconCellComponent } from '../../table/cells/dynamic-icon-cell/dynamic-icon-cell.component';
import { FollowedNumberCellComponent } from '../../table/cells/followed-number-cell/followed-number-cell.component';
import { GridNameCellComponent } from '../../table/cells/grid-name-cell/grid-name-cell.component';
import { IconCellComponent } from '../../table/cells/icon-cell/icon-cell.component';
import { IconLinkCellComponent } from '../../table/cells/icon-link-cell/icon-link-cell.component';
import { LastCommandStatusCellComponent } from '../../table/cells/last-command-status-cell/last-command-status-cell.component';
import { LinkedCellComponent } from '../../table/cells/linked-cell/linked-cell.component';
import { NumberCellComponent } from '../../table/cells/number-cell/number-cell.component';
import { ProductCellComponent } from '../../table/cells/product-cell/product-cell.component';
import { RatingCellComponent } from '../../table/cells/rating-cell/rating-cell.component';
import { RichVariableUnitCellComponent } from '../../table/cells/rich-variable-unit-cell/rich-variable-unit-cell.component';
import { RichVariableValueCellComponent } from '../../table/cells/rich-variable-value-cell/rich-variable-value-cell.component';
import { SeverityCellComponent } from '../../table/cells/severity-cell/severity-cell.component';
import { ThresholdIconCellComponent } from '../../table/cells/threshold-icon-cell/threshold-icon-cell.component';
import { TopicSelfSubscriptionCellComponent } from '../../table/cells/topic-self-subscription-cell/topic-self-subscription-cell.component';
import { TranslatedCellComponent } from '../../table/cells/translated-cell/translated-cell.component';
import { UserCellComponent } from '../../table/cells/user-cell/user-cell.component';
import { ValueToIconCellComponent } from '../../table/cells/value-to-icon-cell/value-to-icon-cell.component';
import { VariableDispatchCellComponent } from '../../table/cells/variable-dispatch-cell/variable-dispatch-cell.component';
import { VariableValueCellComponent } from '../../table/cells/variable-value-cell/variable-value-cell.component';
import { BasicHeaderComponent } from '../../table/header-cells/basic-header/basic-header.component';
import { ButtonHeaderComponent } from '../../table/header-cells/button-header/button-header.component';
import { CheckboxHeaderComponent } from '../../table/header-cells/checkbox-header/checkbox-header.component';
import { IconAndTextHeaderComponent } from '../../table/header-cells/icon-and-text-header/icon-and-text-header.component';
import { IconHeaderComponent } from '../../table/header-cells/icon-header/icon-header.component';
import { MasterViewComponent } from './master-view.component';

@Injectable()
export class MasterViewComponentFactory {
  readonly selector: string;
  readonly componentType: Type<any>;
  readonly ngContentSelectors: string[];
  readonly inputs: {
    propName: string;
    templateName: string;
  }[];
  readonly outputs: {
    propName: string;
    templateName: string;
  }[];

  createHeaderComponent(elementType: string, viewContainerRef: ViewContainerRef): ComponentRef<MasterViewComponent> {
    switch (elementType) {
      case HeaderType.BASIC:
        return viewContainerRef.createComponent<BasicHeaderComponent>(BasicHeaderComponent);
      case HeaderType.ICON_AND_TEXT:
        return viewContainerRef.createComponent<IconAndTextHeaderComponent>(IconAndTextHeaderComponent);
      case HeaderType.ICON:
        return viewContainerRef.createComponent<IconHeaderComponent>(IconHeaderComponent);
      case HeaderType.CHECKBOX:
        return viewContainerRef.createComponent<CheckboxHeaderComponent>(CheckboxHeaderComponent);
      case HeaderType.BUTTON:
        return viewContainerRef.createComponent<ButtonHeaderComponent>(ButtonHeaderComponent);
      default:
        return viewContainerRef.createComponent<BasicCellComponent>(BasicCellComponent);
    }
  }

  createCellComponent(elementType: string, viewContainerRef: ViewContainerRef): ComponentRef<MasterViewComponent> {
    switch (elementType) {
      case I4BCellType.BASIC:
        return viewContainerRef.createComponent<BasicCellComponent>(BasicCellComponent);
      case I4BCellType.NUMBER:
        return viewContainerRef.createComponent<NumberCellComponent>(NumberCellComponent);
      case I4BCellType.COUNT:
        return viewContainerRef.createComponent<CountCellComponent>(CountCellComponent);
      case I4BCellType.DATE:
        return viewContainerRef.createComponent<DateCellComponent>(DateCellComponent);
      case I4BCellType.ICON:
        return viewContainerRef.createComponent<IconCellComponent>(IconCellComponent);
      case I4BCellType.DYNAMIC_ICON_CELL:
        return viewContainerRef.createComponent<DynamicIconCellComponent>(DynamicIconCellComponent);
      case I4BCellType.COMMENT:
        return viewContainerRef.createComponent<CommentCellComponent>(CommentCellComponent);
      case I4BCellType.BASIC_LINK:
        return viewContainerRef.createComponent<BasicLinkCellComponent>(BasicLinkCellComponent);
      case I4BCellType.ICON_LINK:
        return viewContainerRef.createComponent<IconLinkCellComponent>(IconLinkCellComponent);
      case I4BCellType.COLOR_CELL:
        return viewContainerRef.createComponent<ColorCellComponent>(ColorCellComponent);
      case I4BCellType.DYNAMIC_DATA:
        return viewContainerRef.createComponent<DynamicDataComponent>(DynamicDataComponent);
      case I4BCellType.BOOLEAN_CELL:
        return viewContainerRef.createComponent<BooleanCellComponent>(BooleanCellComponent);
      case I4BCellType.TRANSLATED_CELL:
        return viewContainerRef.createComponent<TranslatedCellComponent>(TranslatedCellComponent);
      case I4BCellType.VARIABLE_VALUE_CELL:
        return viewContainerRef.createComponent<VariableValueCellComponent>(VariableValueCellComponent);
      case I4BCellType.RICH_VARIABLE:
        return viewContainerRef.createComponent<RichVariableValueCellComponent>(RichVariableValueCellComponent);
      case I4BCellType.RICH_VARIABLE_UNIT_CELL:
        return viewContainerRef.createComponent<RichVariableUnitCellComponent>(RichVariableUnitCellComponent);
      case I4BCellType.CYCLICAL_CALL_FAULT:
        return viewContainerRef.createComponent<CyclicalCallFaultCellComponent>(CyclicalCallFaultCellComponent);
      case I4BCellType.USER_CELL:
        return viewContainerRef.createComponent<UserCellComponent>(UserCellComponent);
      case I4BCellType.PRODUCT_CELL:
        return viewContainerRef.createComponent<ProductCellComponent>(ProductCellComponent);
      case I4BCellType.TOPIC_SELF_SUBSCRIPTION_CELL:
        return viewContainerRef.createComponent<TopicSelfSubscriptionCellComponent>(TopicSelfSubscriptionCellComponent);
      case I4BCellType.ARRAY_OF_OBJECTS:
        return viewContainerRef.createComponent<ArrayOfObjectsCellComponent>(ArrayOfObjectsCellComponent);
      case I4BCellType.SEVERITY_CELL:
        return viewContainerRef.createComponent<SeverityCellComponent>(SeverityCellComponent);
      case I4BCellType.FOLLOWED_NUMBER_CELL:
        return viewContainerRef.createComponent<FollowedNumberCellComponent>(FollowedNumberCellComponent);
      case I4BCellType.GRID_NAME_CELL:
        return viewContainerRef.createComponent<GridNameCellComponent>(GridNameCellComponent);
      case I4BCellType.ACTIVE_EVENTS_CELL:
        return viewContainerRef.createComponent<ActiveEventsCellComponent>(ActiveEventsCellComponent);
      case I4BCellType.LINKED_CELL:
        return viewContainerRef.createComponent<LinkedCellComponent>(LinkedCellComponent);
      case I4BCellType.VARIABLE_DISPATCH_CELL:
        return viewContainerRef.createComponent<VariableDispatchCellComponent>(VariableDispatchCellComponent);
      case I4BCellType.THRESHOLD_ICON_CELL:
        return viewContainerRef.createComponent<ThresholdIconCellComponent>(ThresholdIconCellComponent);
      case I4BCellType.LAST_COMMAND_STATUS_CELL:
        return viewContainerRef.createComponent<LastCommandStatusCellComponent>(LastCommandStatusCellComponent);
      case I4BCellType.RATING:
        return viewContainerRef.createComponent<RatingCellComponent>(RatingCellComponent);
      case I4BCellType.VALUE_TO_ICON:
        return viewContainerRef.createComponent<ValueToIconCellComponent>(ValueToIconCellComponent);
      case I4BCellType.ASSET_VARIABLE_CELL:
        return viewContainerRef.createComponent<AssetVariableCellComponent>(AssetVariableCellComponent);
      case I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL:
        return viewContainerRef.createComponent<AssetVariableThresholdCellComponent>(AssetVariableThresholdCellComponent);
      case I4BCellType.BUTTON_CELL:
        return viewContainerRef.createComponent<ButtonCellComponent>(ButtonCellComponent);
      default:
        return viewContainerRef.createComponent<BasicCellComponent>(BasicCellComponent);
    }
  }
}
