import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { TagCategory } from '@iot-platform/models/common';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SortUtil } from '../../../../../../shared/src/lib/utils/sort.util';

import { TagsByEntity } from './manage-tags-form.component';

@Injectable({
  providedIn: 'root'
})
export class ManageTagsFormService {
  constructor(private httpClient: HttpClient, @Inject('environment') private environment) {}

  getTags(): Observable<TagCategory[]> {
    return this.httpClient.get<TagCategory[]>(this.environment.api.url + this.environment.api.endpoints.tags).pipe(map((tags: any) => tags.content));
  }

  getTagCategoriesByConcept(
    concepts: string[],
    entityId: string,
    withChildren: boolean,
    withParents: boolean,
    joinable: boolean
  ): Observable<{ [concept: string]: TagCategory[] }> {
    let params: HttpParams = new HttpParams();
    params = params.set('entityId', entityId);
    params = params.set('withParents', withParents.toString());
    params = params.set('withChildren', withParents.toString());
    params = params.set('joinable', joinable.toString());

    const result: { [concept: string]: TagCategory[] } = {};

    if (concepts) {
      concepts.forEach((concept) => {
        params = params.append('concept', concept.toUpperCase());
      });

      concepts.forEach((concept) => {
        result[concept.toUpperCase()] = [];
      });
    }

    return this.httpClient.get<TagCategory[]>(`${this.environment.api.url}${this.environment.api.endpoints.tags}`, { params }).pipe(
      map((tags: any) => {
        if (tags.content) {
          return tags.content.reduce((acc: { [concept: string]: TagCategory[] }, value: TagCategory) => {
            acc[value.concept].push(value);
            return acc;
          }, result);
        } else {
          return result;
        }
      })
    );
  }

  getTagCategoriesByConceptSortedByEntity(
    concepts: string[],
    currentEntityId: string,
    withChildren: boolean = false,
    withParents: boolean,
    joinable: boolean
  ): Observable<{ [concept: string]: TagsByEntity[] }> {
    const result: any[] = [];
    const combined$ = combineLatest([
      this.getEntityTree(currentEntityId, withChildren),
      this.getTagCategoriesByConcept(concepts, currentEntityId, withChildren, withParents, joinable)
    ]);

    const finalResult: { [concept: string]: TagCategory[] } = {};
    concepts.forEach((concept) => {
      finalResult[concept.toUpperCase()] = [];
    });

    return combined$.pipe(
      map(([entityTree, tagCategoriesGroupedByConcept]) => {
        for (const [enty, val] of Object.entries(entityTree)) {
          for (const [concept, tagCategories] of Object.entries(tagCategoriesGroupedByConcept)) {
            const categories: TagCategory[] = [];
            if (Array.isArray(tagCategories)) {
              tagCategories.forEach((cat) => {
                if (cat.entityId === val.id) {
                  categories.push(cat);
                }
              });
            }
            result.push({
              entityName: val.name,
              entityId: val.id,
              index: val.level,
              concept: concept,
              tagCategories: categories
            });
          }
        }

        return result.reduce((acc, value) => {
          acc[value.concept].push({
            entityName: value.entityName,
            entityId: value.entityId,
            index: value.index,
            tagCategories: this.fullySortTagCategories(value.tagCategories)
          });
          return acc;
        }, finalResult);
      })
    );
  }

  getEntityTree(
    currentEntityId: string,
    withChildren: boolean
  ): Observable<Array<{ id: string; name: string; parentId: string; level: number; label: string }>> {
    return this.httpClient
      .get<Array<{ id: string; name: string; parentId: string; level: number; label: string }>>(
        this.environment.api.url + this.environment.api.endpoints.entities + '/' + currentEntityId + '/tree?withChildren=' + withChildren
      )
      .pipe(map((entities) => entities.sort(SortUtil.sortByProperty('level'))));
  }

  fullySortTagCategories(categories: TagCategory[]): TagCategory[] {
    const fullySortedTags: TagCategory[] = [...categories];
    fullySortedTags.sort(SortUtil.sortByName);
    fullySortedTags.forEach((category: TagCategory) => {
      category.labels.sort(SortUtil.sortByName);
    });
    return fullySortedTags;
  }
}
