<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-title">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="">MANAGE PAGINATION </h1>
      <!--<mat-label>{{ 'USERS.PREFERENCES_FORM.MASTER_VIEWS' | translate }}</mat-label>-->
      <button mat-icon-button class="regular-round-button" (click)="closePopup()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content" color="accent" fxLayout="column">
    <form [formGroup]="userPrefsForm" (ngSubmit)="onSubmit()" fxFlex fxLayout="column" fxLayoutGap="8px" class="p-20">

      <mat-form-field color="accent">
        <mat-select formControlName="mvSites" [placeholder]="'USERS.PREFERENCES_FORM.SITES' | translate">
          <mat-option value="10">10</mat-option>
          <mat-option value="25">25</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field color="accent">
        <mat-select formControlName="mvAssets" [placeholder]="'USERS.PREFERENCES_FORM.ASSETS' | translate">
          <mat-option value="10">10</mat-option>
          <mat-option value="25">25</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field color="accent">
        <mat-select formControlName="mvDevices" [placeholder]="'USERS.PREFERENCES_FORM.DEVICES' | translate">
          <mat-option value="10">10</mat-option>
          <mat-option value="25">25</mat-option>
        </mat-select>
      </mat-form-field>

      <br />

    </form>

  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="closePopup()">CANCEL</button>
    <button mat-raised-button color="accent" [disabled]="!userPrefsForm.valid" type="submit" class="button-regular"
    (click)="onSubmit()"
    >{{ 'USERS.PREFERENCES_FORM.SAVE' | translate }}</button>
  </mat-card-actions>
</mat-card>




