import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Entity } from '@iot-platform/models/common';
import { PoEventAlgorithm, PoEventRule } from '@iot-platform/models/i4b';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EntitiesService } from '../../../../../../../shared/src/lib/entities.service';
import { PoEventsService } from '../../../../../../../shared/src/lib/services/po-events.service';

@Component({
  selector: 'iot4bos-ui-po-event-create-form',
  templateUrl: './po-event-create-form.component.html',
  styleUrls: ['./po-event-create-form.component.scss']
})
export class PoEventCreateFormComponent implements OnInit, OnDestroy {
  poEventCreateForm: UntypedFormGroup;
  dataLoading = true;
  entities: Entity[] = [];
  initialEntity?: Entity;
  initialName = '';
  algos: PoEventAlgorithm[] = [];
  isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  displayDuplicateMessage$: Subject<boolean> = new Subject<boolean>();
  destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { element?: PoEventRule },
    private readonly translateService: TranslateService,
    private readonly dialogRef: MatDialogRef<PoEventCreateFormComponent>,
    private readonly entitiesService: EntitiesService,
    private readonly poEventsService: PoEventsService
  ) {
    const keyboard$ = fromEvent(document, 'keyup');
    keyboard$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.isDisabled$.next(true)),
        debounceTime(500),
        switchMap(() => {
          if (this.eventLabel.value && this.entity.value) {
            return this.checkTopicNameUnicity();
          } else {
            return of(null);
          }
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.buildForm();

    const dataReady$ = combineLatest([this.poEventsService.getPoEventAlgorithms(), this.entitiesService.getHierarchicallySortedEntities()]);

    dataReady$.pipe(takeUntil(this.destroy$)).subscribe(([algos, entities]) => {
      this.algos = algos;
      this.entities = entities;
      this.dataLoading = false;
      if (this.data && this.poEventCreateForm) {
        this.poEventCreateForm.get('eventLabel').patchValue(this.data.element.name);
        this.initialEntity = this.entities.find((e) => e.id === this.data.element.entity.id);
        this.poEventCreateForm.get('entity').patchValue(this.initialEntity);
        this.poEventCreateForm.get('eventType').setValue(this.algos.find((a) => a.name === this.data.element.algo));

        this.poEventCreateForm.get('entity').disable();
        this.poEventCreateForm.get('eventType').disable();
        this.initialName = this.data.element.name.trim();
        this.checkNameUniqueness();
      }
    });
  }

  buildForm() {
    this.poEventCreateForm = new UntypedFormGroup({
      eventLabel: new UntypedFormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('\\S.*')]),
      entity: new UntypedFormControl({ value: '', disabled: false }, [Validators.required]),
      eventType: new UntypedFormControl({ value: '', disabled: false }, [Validators.required])
    });
  }

  get title$(): Observable<string> {
    return this.data?.element
      ? this.translateService.get('PO_EVENTS.CREATION_CONFIGURATION_POPUP.EDIT_TITLE', { ruleName: this.data.element.name })
      : this.translateService.get('PO_EVENTS.CREATION_CONFIGURATION_POPUP.CREATE_TITLE');
  }

  get eventLabel(): AbstractControl {
    return this.poEventCreateForm.get('eventLabel');
  }

  get entity(): AbstractControl {
    return this.poEventCreateForm.get('entity');
  }

  get eventType(): AbstractControl {
    return this.poEventCreateForm.get('eventType');
  }

  get errorMessage$() {
    return this.translateService.get('ASSETS.VARIABLE_FORM.ERROR_MESSAGE.REQUIRED');
  }

  get actionButtonLabel(): string {
    return this.data ? 'IOT_DICTIONARY.UPDATE' : 'IOT_DICTIONARY.CREATE';
  }

  checkTopicNameUnicity() {
    this.isDisabled$.next(true);

    return this.poEventsService.getRuleNameUniqueness(this.initialName, this.eventLabel.value.trim(), this.entity.value.id).pipe(
      tap((b) => {
        this.isDisabled$.next(b);
        this.displayDuplicateMessage$.next(b);
      })
    );
  }

  checkNameUniqueness() {
    if (this.eventLabel.value && this.entity.value) {
      this.checkTopicNameUnicity().pipe(takeUntil(this.destroy$)).subscribe();
    }
  }

  onEntitySelection(entity: Entity) {
    if (!!entity) {
      this.entity.setValue(entity);
      this.checkNameUniqueness();
    }
  }

  resetEntity() {
    this.entity.reset();
  }

  closeOnCancel() {
    this.dialogRef.close();
  }

  save(action: string) {
    if (!this.data) {
      this.dialogRef.close({ action: action, rule: { name: this.eventLabel.value.trim(), entity: this.entity.value, algo: this.eventType.value } });
    } else {
      this.dialogRef.close({ action: 'EDIT', rule: { ...this.data.element, name: this.eventLabel.value.trim() } });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
