import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AuthorizationService, fromAuth } from '@iot-platform/auth';
import { GridManagerAdminPopupComponent, GridsService } from '@iot-platform/grid-engine';
import {
  ADD_BUTTON_CONFIG,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  IotToolbarMenuButton,
  MANAGE_MASTER_VIEWS_BUTTON_CONFIG
} from '@iot-platform/iot-platform-ui';

import {
  BusinessProfile,
  Entity,
  IotToolbarEvent,
  Organization,
  Role,
  TagCategory,
  ToolbarSize
} from '@iot-platform/models/common';

import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';

import { map, switchMap, tap } from 'rxjs/operators';
import { AuthorizationConcept, AuthorizationType } from '../../../../../../auth/src/lib/authorization.types';

import {
  ManageTagsFormComponent
} from '../../../../../../iot-platform-ui/src/lib/ui/components/manage-tags-form/manage-tags-form.component';
import { PopupComponent } from '../../../../../../iot-platform-ui/src/lib/ui/components/popup/popup.component';
import {
  AdminSettingsPopupComponent
} from '../../../../../../table-engine/src/lib/components/table/settings/admin-settings-popup/admin-settings-popup.component';

import {
  DialogAdministratorAddFormComponent
} from '../../../components/dialogs/dialog-administrator-add-form/dialog-administrator-add-form.component';
import {
  DialogBusinessProfileAddFormComponent
} from '../../../components/dialogs/dialog-business-profile-add-form/dialog-business-profile-add-form.component';
import {
  DialogManageConceptsFormComponent
} from '../../../components/dialogs/dialog-manage-concepts-form/dialog-manage-concepts-form.component';
import {
  DialogOrganizationAddFormComponent
} from '../../../components/dialogs/dialog-organization-add-form/dialog-organization-add-form.component';
import {
  DialogRoleAddFormComponent
} from '../../../components/dialogs/dialog-role-add-form/dialog-role-add-form.component';
import * as fromOrganizations from '../../admin-organizations/state/reducers';

import {
  AdminOrganizationsBusinessProfilesPageActions,
  AdminOrganizationsPageActions,
  AdminOrganizationsRolesPageActions,
  AdminOrganizationsTagsPageActions
} from '../state/actions';

@Component({
  selector: 'iot4bos-backoffice-ui-admin-organizations',
  templateUrl: './admin-organizations.component.html',
  styleUrls: ['../../../style/admin.style.scss', './admin-organizations.component.scss']
})
export class AdminOrganizationsComponent implements OnInit {
  organizations$ = this.store.pipe(select(fromOrganizations.getAllOrganizations));
  selectedOrganization$ = this.store.pipe(select(fromOrganizations.getSelectedOrganization));

  roles$ = this.store.pipe(select(fromOrganizations.getAllRoles));
  businessProfiles$ = this.store.pipe(select(fromOrganizations.getAllBusinessProfiles));
  tags$ = this.store.pipe(select(fromOrganizations.getAllTagsWithSortedLabels));
  administrators$ = this.store.pipe(select(fromOrganizations.getAllAdministrators));
  selectedRole$ = this.store.pipe(select(fromOrganizations.getSelectedRole));
  isAdmin$ = this.store.pipe(select(fromAuth.selectIsUserAdmin));

  administratorsPendingStatus$ = this.store.pipe(select(fromOrganizations.getAdministratorsPendingStatus));
  businessProfilesPendingStatus$ = this.store.pipe(select(fromOrganizations.getBusinessProfilesPendingStatus));
  rolesPendingStatus$ = this.store.pipe(select(fromOrganizations.getRolesPendingStatus));
  tagsPendingStatus$ = this.store.pipe(select(fromOrganizations.getTagsPendingStatus));
  organizationsPendingStatus$ = this.store.pipe(select(fromOrganizations.getAdminOrganizationsPagePending));

  canCreateOrganization: boolean;
  canUpdateOrganization: boolean;
  isTopLevelAdmin$ = this.store.pipe(select(fromAuth.selectUserIsTopLevelAdmin));

  adminConceptsFromEntitySession$ = this.store.pipe(
    select(fromAuth.selectSelectedEntityForSession),
    map((organization) => organization.adminConcepts)
  );

  organizationsButtonList!: Array<IotToolbarDefaultButton | IotToolbarMenuButton>;

  private selectedOrganization;
  private selectedOrganizationForSession: Entity;

  toolbarSize: string = ToolbarSize.SMALL;

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly authz: AuthorizationService,
    private readonly gridsService: GridsService,
    private readonly translateService: TranslateService
  ) {
    this.canCreateOrganization = this.authz.applyAuthorization(AuthorizationConcept.ORGANIZATION_TREE, AuthorizationType.CREATE);
    this.canUpdateOrganization = this.authz.applyAuthorization(AuthorizationConcept.ORGANIZATION_TREE, AuthorizationType.UPDATE);
    this.initToolbarButtonList();
  }

  ngOnInit() {
    this.adminConceptsFromEntitySession$ = this.store.pipe(
      select(fromAuth.selectSelectedEntityForSession),
      tap((orga) => (this.selectedOrganizationForSession = orga)),
      map((organization) => (organization ? organization.adminConcepts : []))
    );

    this.store.pipe(select(fromAuth.selectAccount)).subscribe((account) => {
      if (account) {
        this.store.dispatch(AdminOrganizationsPageActions.listOrganizations());
      }
    });

    this.selectedOrganization$.subscribe((org) => (this.selectedOrganization = org));

    this.isTopLevelAdmin$.subscribe((isTopLevelAdmin: boolean) => {
      this.organizationsButtonList.map((button) => {
        if (button instanceof IotToolbarMenuButton) {
          button.displayButton = isTopLevelAdmin;
        }
      });
    });
  }

  initToolbarButtonList(): void {
    this.organizationsButtonList = [
      new IotToolbarDefaultButton(
        { ...ADD_BUTTON_CONFIG, displayButton: this.canCreateOrganization, tooltip: 'ADMIN.ORGANIZATIONS.DETAIL.ADD_ORGANIZATION' },
        1
      ),
      new IotToolbarMenuButton(MANAGE_MASTER_VIEWS_BUTTON_CONFIG, 0)
    ];
  }

  onToolbarEvent(event: IotToolbarEvent) {
    switch (event.type) {
      case IotToolbarDispatchActionType.ADD_ELEMENT:
        this.onAddOrganization(null);
        break;
      case IotToolbarDispatchActionType.OPEN_MASTER_VIEW_ADMIN_SETTINGS:
        this.openAdminSettings();
        break;
      case IotToolbarDispatchActionType.OPEN_GRID_ADMIN_SETTINGS:
        this.openGridSettings();
        break;
      default:
        break;
    }
  }

  onSelectOrganization(selectedOrganization: Organization) {
    this.selectedOrganization = selectedOrganization;
    this.store.dispatch(AdminOrganizationsPageActions.selectOrganization({ selectedOrganizationId: selectedOrganization.id }));
  }

  onSelectRole(selectedRole: Role) {
    this.store.dispatch(AdminOrganizationsRolesPageActions.selectRoleInOrganization({ selectedRoleId: selectedRole.id }));
  }

  onRenameRole(selectedRole: Role) {
    const dialogRef = this.dialog.open(DialogRoleAddFormComponent, {
      width: '550px',
      data: { organization: this.selectedOrganization, roleToUpdate: selectedRole }
    });

    dialogRef.afterClosed().subscribe((updated: Role) => {
      this.store.dispatch(AdminOrganizationsRolesPageActions.updateRoleForOrganization({ roleToUpdate: updated }));
    });
  }

  onAuthorizationsChanged(roleToUpdate: Role) {
    this.store.dispatch(AdminOrganizationsRolesPageActions.updateRoleForOrganization({ roleToUpdate }));
  }

  onAddBusinessProfile(organization: Organization) {
    const dialogRef = this.dialog.open(DialogBusinessProfileAddFormComponent, {
      width: '550px',
      data: { organization: organization }
    });

    dialogRef.afterClosed().subscribe((newBP: BusinessProfile) => {
      if (newBP) {
        this.store.dispatch(AdminOrganizationsBusinessProfilesPageActions.addBusinessProfileToOrganization({ businessProfileToAdd: newBP }));
      }
    });
  }

  onAddRole(organization: Organization) {
    const dialogRef = this.dialog.open(DialogRoleAddFormComponent, {
      width: '550px',
      data: { organization: organization }
    });

    dialogRef.afterClosed().subscribe((newRole: Role) => {
      if (newRole) {
        this.store.dispatch(AdminOrganizationsRolesPageActions.addRoleToOrganization({ roleToAdd: newRole }));
      }
    });
  }

  onAddAdministratorToOrganization() {
    const dialogRef = this.dialog.open(DialogAdministratorAddFormComponent, {
      width: '900px',
      data: { organization: this.selectedOrganization }
    });

    /*dialogRef.afterClosed().subscribe((newAdministrator: User) => {
      if (newAdministrator) {
        this.store.dispatch(OrganizationsPageActions.addAdministratorToOrganization({ administratorToAdd: newAdministrator }));
      }
    });*/
  }

  onUpdateOrganization(updatedOrganization: Organization) {
    this.store.dispatch(AdminOrganizationsPageActions.updateOrganization({ organizationToUpdate: updatedOrganization }));
  }

  onDeleteBusinessProfile(businessProfile: BusinessProfile) {
    this.store.dispatch(AdminOrganizationsBusinessProfilesPageActions.removeBusinessProfileFromOrganization({ businessProfileToRemove: businessProfile }));
  }

  onSelectBusinessProfile(businessProfile: BusinessProfile) {
    this.store.dispatch(AdminOrganizationsBusinessProfilesPageActions.navigateToSelectedBusinessProfile({ businessProfile }));
  }

  onDeleteRole(role: Role) {
    this.store.dispatch(AdminOrganizationsRolesPageActions.removeRoleFromOrganization({ roleToRemove: role }));
  }

  onAddOrganization(parentOrganization: Organization | null) {
    this.dialog
      .open(DialogOrganizationAddFormComponent, {
        data: {
          type: 'organization',
          organization: parentOrganization
        },
        width: '500px'
      })
      .afterClosed()
      .subscribe((newOrganization) => {
        if (newOrganization) {
          this.store.dispatch(AdminOrganizationsPageActions.addOrganization({ newOrganization }));
        }
      });
  }

  onDeleteOrganization(organizationToDelete: Organization) {
    this.store.dispatch(AdminOrganizationsPageActions.deleteOrganization({ organizationToDelete: organizationToDelete }));
  }

  onAddTags(tagCategory: TagCategory) {
    if (tagCategory.id) {
      this.store.dispatch(AdminOrganizationsTagsPageActions.addTagToOrganization({ tagToAdd: tagCategory }));
    } else {
      this.store.dispatch(AdminOrganizationsTagsPageActions.updateTagInOrganization({ tagToUpdate: tagCategory }));
    }
  }

  onManageTagsForOrganization(event: { concept: string; organization: Organization; conceptList: string[] }) {
    const dialogRef = this.dialog.open(ManageTagsFormComponent, {
      width: '1230px',
      disableClose: true,
      data: {
        concepts: event.conceptList,
        openOnConcept: event.concept,
        selectedTags: [],
        objectName: event.organization.name,
        currentEntityId: event.organization.id,
        multiSelection: true,
        editable: true,
        withChildren: false,
        joinable: false,
        withParents: true
      }
    });

    /*dialogRef.componentInstance.saveTagCategory.subscribe(tagCategory => {
      if (!tagCategory.id) {
        this.store.dispatch(AdminOrganizationsTagsPageActions.addTagToOrganization({ tagToAdd: tagCategory }));
      } else {
        this.store.dispatch(AdminOrganizationsTagsPageActions.updateTagInOrganization({ tagToUpdate: tagCategory }));
      }
      dialogRef.componentInstance.loadAllTags();
    });

    dialogRef.componentInstance.removeTagCategory.subscribe((tagCategory: TagCategory) => {
      if (tagCategory) {
        this.store.dispatch(AdminOrganizationsTagsPageActions.removeTagFromOrganization({ tagToRemove: tagCategory }));
        dialogRef.componentInstance.loadAllTags();
      }
    });*/

    dialogRef.afterClosed().subscribe((tags: TagCategory[]) => {
      this.store.dispatch(AdminOrganizationsTagsPageActions.listTagsByOrganization({ organizationId: event.organization.id, withParents: false }));
    });
  }

  onManageConceptsForOrganization(data: { organization: Organization; adminConceptsFromEntitySession: any[] }): void {
    this.dialog
      .open(DialogManageConceptsFormComponent, {
        width: '500px',
        disableClose: true,
        data: { organization: data.organization, adminConceptsFromEntitySession: data.adminConceptsFromEntitySession }
      })
      .afterClosed()
      .subscribe((organizationToUpdate) => {
        if (organizationToUpdate) {
          this.onUpdateOrganization(Object.assign({}, organizationToUpdate));
        }
      });
  }

  openAdminSettings() {
    this.dialog
      .open(AdminSettingsPopupComponent, {
        width: '850px',
        data: {
          settingName: 'assets'
        }
      })
      .afterClosed()
      .subscribe((settings) => {});
  }

  openGridSettings() {
    this.dialog
      .open(GridManagerAdminPopupComponent, {
        width: '1350px',
        maxWidth: '1350px',
        disableClose: true,
        data: { grid: null, selectedOrganization: this.selectedOrganizationForSession }
      })
      .afterClosed()
      .pipe(
        switchMap((gridToUpdate) => {
          if (!!gridToUpdate) {
            return this.gridsService.updateGrid(gridToUpdate);
          }
          return EMPTY;
        })
      )
      .subscribe();
  }

  onLockUnlockOrganization({ organization, isLocked }): void {
    const confirmMessage = isLocked
      ? 'ADMIN.ORGANIZATIONS.DETAIL.LOCK_ORGANIZATION_CONFIRM_MESSAGE'
      : 'ADMIN.ORGANIZATIONS.DETAIL.UNLOCK_ORGANIZATION_CONFIRM_MESSAGE';
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        disableClose: true,
        data: { type: 'confirm', value: this.translateService.instant(confirmMessage, { name: organization.name }) }
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.onUpdateOrganization({ ...organization, isLocked });
        }
      });
  }
}
