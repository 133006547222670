<div fxLayout="column" fxFlex fxLayoutAlign="space-between stretch">
  <h2 class="layout-login-regular-title login-select-authentication--title">{{ 'LOGIN.TITLE.LOGIN' | translate }}</h2>

  <section fxFlex *ngIf="!ssoDisabled" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center stretch">
    <div class="btn-container" fxLayout="column" fxLayoutAlign="center stretch">
      <button mat-raised-button type="button" (click)="signInWithSSO.emit()" class="sso-action"><mat-icon [svgIcon]="'air_liquide_logo'"></mat-icon>&nbsp;{{ 'LOGIN.SSO_CONNECT' | translate }}</button>
    </div>
    <div class="btn-container" fxLayout="column" fxLayoutAlign="center stretch">
      <button mat-button type="button" (click)="signInWithEmailPassword.emit()" class="email-password-action">
        {{ 'LOGIN.LOGIN_WITH_EMAIL_PASSWORD' | translate }}
      </button>
    </div>
  </section>

  <section *ngIf="ssoDisabled" class="btn-container" fxLayout="column" fxLayoutAlign="center stretch">
    <button mat-button type="button" (click)="signInWithEmailPassword.emit()" class="">{{ 'LOGIN.LOGIN_WITH_EMAIL_PASSWORD' | translate }}</button>
  </section>

  <div *ngIf="carmUrl" class="login-select-authentication--no-account-message" fxLayoutAlign="start center">
    <span>{{ 'LOGIN.NO_ACCOUNT' | translate }}</span
    ><a fxLayoutAlign="start center" [href]="carmUrl" target="_blank">&nbsp;{{ 'LOGIN.REQUEST_ACCOUNT' | translate }}&nbsp;<mat-icon>open_in_new</mat-icon></a>
  </div>
</div>
