<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'USERS.PROFILE_INFO_FORM.UI.POPUP_TITLE' | translate }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="exit()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content p-20" fxLayout="row" fxLayoutGap="20px">
    <form [formGroup]="userProfileForm" fxFlex fxLayout="column">
      <div fxLayout="row" fxLayoutGap="20px" fxFlex>
        <mat-form-field class="full-width" color="accent">
          <input formControlName="firstname" matInput maxlength="30" required [placeholder]="'USERS.PROFILE_INFO_FORM.FIRSTNAME' | translate" />
        </mat-form-field>

        <mat-form-field class="full-width" color="accent">
          <input formControlName="lastname" matInput maxlength="30" required [placeholder]="'USERS.PROFILE_INFO_FORM.LASTNAME' | translate" />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="20px" fxFlex>
        <mat-form-field class="full-width" color="accent">
          <input formControlName="uuid" matInput maxlength="30" [placeholder]="'USERS.PROFILE_INFO_FORM.UUID' | translate" />
        </mat-form-field>
        <mat-form-field class="full-width" color="accent">
          <input formControlName="email" matInput maxlength="30" [placeholder]="'USERS.PROFILE_INFO_FORM.PRIMARY_EMAIL' | translate" />
        </mat-form-field>

      </div>

      <div fxLayout="row" fxLayoutGap="20px" fxFlex>
        <mat-form-field class="full-width" fxFlex="48%" color="accent">
          <input formControlName="phoneNumber" matInput maxlength="50" [placeholder]="'USERS.PROFILE_INFO_FORM.PHONE_NUMBER' | translate" />
          <span matPrefix>+ </span>
          <mat-error *ngIf="phoneNumber.hasError('pattern')">
            {{ 'USERS.PROFILE_INFO_FORM.UI.ERRORS.VALID_PHONE_NUMBER' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" color="accent">
          <input formControlName="secondaryEmail" matInput maxlength="50" [placeholder]="'USERS.PROFILE_INFO_FORM.SECONDARY_EMAIL' | translate" />
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="exit()">{{ 'USERS.PROFILE_INFO_FORM.UI.BUTTONS.CANCEL' | translate }}</button>
    <button mat-raised-button color="accent" class="button-regular" [disabled]="!userProfileForm.valid" (click)="save()">
      {{ 'USERS.PROFILE_INFO_FORM.UI.BUTTONS.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
