import { User } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const listAdministratorsByOrganization = createAction(
  '[Organizations/Administrators Page] List Administrators By Organization',
  props<{ organizationId: string }>()
);
export const addAdministratorToOrganization = createAction(
  '[Organizations/Administrators Page] Add Administrator to Organization',
  props<{ organizationId: string; administratorToAdd: User }>()
);
export const removeAdministratorFromOrganization = createAction(
  '[Organizations/Administrators Page] Remove administrator From Organization',
  props<{ organizationId: string; administratorToRemove: User }>()
);
