import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildInfo } from '@iot-platform/models/common';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService {
  constructor(private httpClient: HttpClient) {}

  getCurrentVersion(): Observable<string> {
    return this.httpClient.get<BuildInfo>(`assets/data/build.json`).pipe(map((data: BuildInfo) => data.version));
  }

  getReleaseNotes(): Observable<any> {
    return this.httpClient.get(`assets/data/release-notes.html`, { responseType: 'text' });
  }
}
