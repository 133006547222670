import { TimeseriesWidgetOptions } from './widget-options.model';
import { WidgetSize } from './widget-size.enum';
import { WidgetTypes } from './widget-types.enum';

export type WidgetOptions = TimeseriesWidgetOptions;

export interface IWidgetInstance {
  type: WidgetTypes;
  data: any;
  options?: WidgetOptions;
}

export class WidgetInstance implements IWidgetInstance {
  public type: WidgetTypes;
  public data: any;
  public options: WidgetOptions;

  constructor(init?: Partial<IWidgetInstance>) {
    Object.assign(this, init);
    this.options = {
      ...this.defaultOptions,
      ...this.options
    };
  }

  get defaultOptions(): WidgetOptions {
    return {
      size: WidgetSize.XL,
      menu: {
        enabled: false,
        actions: []
      },
      variables: [],
      header: {
        enabled: false,
        title: null
      },
      footer: {
        enabled: false,
        legend: null
      },
      chart: {
        searchBar: {
          enabled: false
        },
        legend: {
          enabled: false,
          labelFormat: null
        },
        export: {
          enabled: false
        }
      }
    };
  }
}
