import { GetUtils } from '@iot-platform/iot-platform-utils';
import { BaseState, CommonIndexedPagination, Filter, INITIAL_BASE_STATE } from '@iot-platform/models/common';
import { Receiver, Ward } from '@iot-platform/models/oyan';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SiteWardsActions } from '../actions';

export const featureKey = 'sitesWardsApiFeatureKey';

export type State = BaseState<Ward, CommonIndexedPagination, Filter>;

export const adapter: EntityAdapter<Ward> = createEntityAdapter<Ward>({
  selectId: (entity: Ward) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, hasMore: false, limit: 10, maxPage: 0, total: 0 }
});

export const reducer = createReducer(
  initialState,

  on(
    SiteWardsActions.loadWardsBySiteSuccess,
    (state: State, { response }): State => ({
      ...adapter.setAll(GetUtils.get(response, 'data', []), { ...state, pagination: response.pagination, selectedId: null, entity: null })
    })
  ),

  on(SiteWardsActions.addReceiverToWardSuccess, (state: State, { ward, receiver }): State => {
    const wardToUpdate: Ward = state.entities[ward.id];
    const receivers: Receiver[] = GetUtils.get(wardToUpdate, 'receivers', []);
    wardToUpdate.receivers = [...receivers, receiver];
    return adapter.updateOne({ id: wardToUpdate.id, changes: wardToUpdate }, { ...state, selectedId: ward.id, entity: wardToUpdate });
  }),

  on(SiteWardsActions.removeReceiverFromWardSuccess, (state: State, { ward, receiver }): State => {
    const wardToUpdate: Ward = state.entities[ward.id];
    const receivers: Receiver[] = GetUtils.get(wardToUpdate, 'receivers', []);
    wardToUpdate.receivers = [...receivers.filter((r) => r.id !== receiver.id)];
    return adapter.updateOne({ id: wardToUpdate.id, changes: wardToUpdate }, { ...state, selectedId: ward.id, entity: wardToUpdate });
  }),

  on(
    SiteWardsActions.loadWardsBySite,
    SiteWardsActions.addReceiverToWard,
    SiteWardsActions.removeReceiverFromWard,
    (state: State): State => ({
      ...state,
      loading: true
    })
  ),
  on(
    SiteWardsActions.loadWardsBySiteSuccess,
    SiteWardsActions.addReceiverToWardSuccess,
    SiteWardsActions.removeReceiverFromWardSuccess,
    (state: State): State => ({
      ...state,
      loading: false,
      loaded: true
    })
  ),

  on(
    SiteWardsActions.loadWardsBySiteFailure,
    SiteWardsActions.addReceiverToWardFailure,
    SiteWardsActions.removeReceiverFromWardFailure,
    (state: State, { error }): State => ({
      ...state,
      loading: false,
      loaded: false,
      error
    })
  )
);
