<iot-platform-ui-timezone-autocomplete *ngIf="!data.multiSelect" (selectionChange)="onSelectionChange($event)"></iot-platform-ui-timezone-autocomplete>

<iot-platform-ui-timezone-autocomplete-multiple-selects
  *ngIf="data.multiSelect"
  [data]="data"
  [currentFilters$]="currentFilters$"
  [currentFiltersSize]="currentFiltersSize"
  [maxFilters]="maxFilters"
  (selectionChange)="onSelectionChange($event)"
></iot-platform-ui-timezone-autocomplete-multiple-selects>
