import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { fromAuth } from '@iot-platform/auth';
import {
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  REFRESH_BUTTON_CONFIG
} from '@iot-platform/iot-platform-ui';
import {
  CommonApiRequest,
  CommonIndexedPagination,
  Environment,
  Filter,
  IotToolbarEvent,
  MasterViewEngineEvent,
  ToolbarSize
} from '@iot-platform/models/common';

import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GridsDbActions } from 'libs/grid-engine/src/lib/components/state/actions';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import * as fromGrids from '../../../../../../../grid-engine/src/lib/components/state/reducers';

@Component({
  selector: 'iot4bos-ui-email-templates-shell',
  templateUrl: './email-templates-shell.component.html',
  styleUrls: ['./email-templates-shell.component.scss']
})
export class EmailTemplatesShellComponent implements OnInit, OnDestroy {
  emailTemplatesButtonList!: IotToolbarDefaultButton[];
  grid$ = this.store.pipe(select(fromGrids.getDefaultEmailTemplatesGrid));
  total$ = this.grid$.pipe(map((grid) => (grid?.data?.response?.pagination as CommonIndexedPagination)?.total | 0));
  templatesLoaded$: Observable<boolean> = this.grid$.pipe(
    switchMap((grid) => {
      if (grid) {
        return this.store.select(fromGrids.getDataLoadedByGrid(grid.id as string));
      } else {
        return of(false);
      }
    })
  );

  gridSort$ = this.grid$.pipe(
    switchMap((grid) => {
      if (grid) {
        return this.store.select(fromGrids.getSortByGrid(grid.id as string));
      } else {
        return of([]);
      }
    })
  );

  filters: Filter[] = [];
  entityId?: string;

  toolbarSize: string = ToolbarSize.SMALL;

  destroyed$: Subject<boolean> = new Subject();

  constructor(
    @Inject('environment') private readonly environment: Environment,
    private readonly translateService: TranslateService,
    private readonly store: Store
  ) {
    this.initToolbarButtonList();
  }

  ngOnInit(): void {
    combineLatest([this.store.pipe(select(fromAuth.selectSelectedBusinessProfileForAccount)), this.store.pipe(select(fromGrids.getAllGrids)), this.grid$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([businessProfile, grids, grid]) => {
        this.entityId = businessProfile?.entityId;
        this.filters = [
          { criteriaKey: 'type', value: 'email' },
          { criteriaKey: 'includeParentEntities', value: true },
          { criteriaKey: 'includeSubEntities', value: true },
          { criteriaKey: 'entityId', value: this.entityId }
        ];
        if (businessProfile && grids && !grid) {
          this.selectGridAndLoadData();
        }
      });

    this.templatesLoaded$.pipe(takeUntil(this.destroyed$)).subscribe((dataLoaded: boolean) => {
      this.emailTemplatesButtonList.map((button) => {
        if (button.dispatchAction.type === IotToolbarDispatchActionType.REFRESH_PAGE) {
          button.disabled = !dataLoaded;
        }
        return button;
      });
    });
  }

  initToolbarButtonList(): void {
    this.emailTemplatesButtonList = [new IotToolbarDefaultButton(REFRESH_BUTTON_CONFIG, 4)];
  }

  selectGridAndLoadData() {
    this.store.dispatch(
      GridsDbActions.selectGridAndLoadData({
        gridId: 'default',
        masterview: 'email-templates',
        filters: this.filters,
        endPoint: this.environment.api.endpoints.notificationTemplates
      })
    );
  }

  onToolbarEvent(event: IotToolbarEvent): void {
    switch (event.type) {
      case IotToolbarDispatchActionType.REFRESH_PAGE:
        this.loadData();
        break;
      default:
        break;
    }
  }

  onRefreshClicked() {
    this.loadData();
  }

  loadData(): void {
    this.grid$.pipe(takeUntil(this.destroyed$), take(1)).subscribe((grid) => {
      if (grid?.data) {
        const pagination: CommonIndexedPagination = grid.data.response.pagination as CommonIndexedPagination;
        const request: CommonApiRequest = {
          limit: pagination.limit,
          page: pagination.currentPage,
          filters: this.filters,
          concept: grid.masterview.toLowerCase(),
          variables: grid.gridOptions.variableNames,
          tags: grid.gridOptions.tagIds,
          endPoint: this.environment.api.endpoints.notificationTemplates
        };
        this.store.dispatch(GridsDbActions.loadGridData({ request: { concept: 'email-templates', ...request } }));
      }
    });
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent) {
    // TODO
    switch (event.type) {
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
