import { Component, Input } from '@angular/core';
import { Site } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-stock-card',
  templateUrl: './stock-card.component.html',
  styleUrls: ['./stock-card.component.scss']
})
export class StockCardComponent {
  @Input() site: Site;
  @Input() totalDevices: number;
  @Input() totalDevicesLoading = true;
  @Input() deviceCountByFamily: Array<{ family: string; total: number }> = [];
  @Input() deviceCountByFamilyLoading = true;
}
