import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe extends DecimalPipe implements PipeTransform {
  private readonly defaultDigitsInfo = '1.0-3';
  private readonly defaultLocale = 'fr';
  private userAppNumberFormat?: string = JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.appNumberFormat;
  private userAppNumberOfDecimals?: number = JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.appNumberOfDecimals;

  constructor(@Inject(LOCALE_ID) private readonly locale: string, private readonly storage: LocalStorageService) {
    super(locale);
  }

  override transform(value: any, digitsInfo?: string, locale?: string): any {
    let digitsInfoToUse = '';
    if (digitsInfo) {
      digitsInfoToUse = digitsInfo;
    } else if (!!this.userAppNumberOfDecimals) {
      digitsInfoToUse = this.defaultDigitsInfo.substring(0, this.defaultDigitsInfo.length - 1) + this.userAppNumberOfDecimals.toString();
    } else {
      digitsInfoToUse = this.defaultDigitsInfo;
    }
    return super.transform(value, digitsInfoToUse, this.userAppNumberFormat ?? this.defaultLocale);
  }
}
