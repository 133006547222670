import { PlatformResponse } from '@iot-platform/models/common';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { SortUtil } from '../../../../../../../shared/src/lib/utils/sort.util';

import { fromUserPreferences } from '../../../../../../../users/src/lib/features/preferences';
import * as fromDeviceConnectorsDb from '../../../devices/+state/reducers/device-connectors-db.reducer';
import * as fromDeviceConnectorsUi from '../../../devices/+state/reducers/device-connectors-ui.reducer';
import * as fromDevicesDb from '../../../devices/+state/reducers/devices-db.reducer';
import * as fromDevicesUi from '../../../devices/+state/reducers/devices-ui.reducer';

export const devicesFeatureKey = 'devices';

export interface DevicesState {
  [fromDevicesDb.devicesDbFeatureKey]: fromDevicesDb.State;
  [fromDevicesUi.devicesUiFeatureKey]: fromDevicesUi.State;
  [fromDeviceConnectorsDb.deviceConnectorsDbFeatureKey]: fromDeviceConnectorsDb.State;
  [fromDeviceConnectorsUi.deviceConnectorsUiFeatureKey]: fromDeviceConnectorsUi.State;
}

export interface State {
  [devicesFeatureKey]: DevicesState;
}

export function reducers(state: DevicesState | undefined, action: Action) {
  return combineReducers({
    [fromDevicesDb.devicesDbFeatureKey]: fromDevicesDb.reducer,
    [fromDevicesUi.devicesUiFeatureKey]: fromDevicesUi.reducer,
    [fromDeviceConnectorsDb.deviceConnectorsDbFeatureKey]: fromDeviceConnectorsDb.reducer,
    [fromDeviceConnectorsUi.deviceConnectorsUiFeatureKey]: fromDeviceConnectorsUi.reducer
  })(state, action);
}

export const selectDevicesState = createFeatureSelector<DevicesState>(devicesFeatureKey);
const getUserPreferencesState = createFeatureSelector<fromUserPreferences.UserPreferencesState>(fromUserPreferences.userPreferencesFeatureKey);

//Devices
export const selectDevicesDbState = createSelector(selectDevicesState, (state: DevicesState) => {
  return state[fromDevicesDb.devicesDbFeatureKey];
});
export const selectDevicesUiState = createSelector(selectDevicesState, (state: DevicesState) => {
  return state[fromDevicesUi.devicesUiFeatureKey];
});

//Connectors
export const getDeviceConnectorsDbState = createSelector(selectDevicesState, (state: DevicesState) => {
  return state[fromDeviceConnectorsDb.deviceConnectorsDbFeatureKey];
});
export const getDeviceConnectorsUiState = createSelector(selectDevicesState, (state: DevicesState) => {
  return state[fromDeviceConnectorsUi.deviceConnectorsUiFeatureKey];
});

//*************//
//   DEVICES   //
//*************//

export const {
  selectIds: getDevicesIds,
  selectEntities: getDevicesEntities,
  selectAll: getAllDevices,
  selectTotal: getTotalDevices
} = fromDevicesDb.adapter.getSelectors(selectDevicesDbState);

export const getSelectedDeviceId = createSelector(selectDevicesDbState, fromDevicesDb.getSelectedDeviceId);
export const getSelectedDevice = createSelector(getDevicesEntities, getSelectedDeviceId, (entities, selectedId) => selectedId && entities[selectedId]);
export const getMVSettings = createSelector(selectDevicesDbState, (state: fromDevicesDb.State) => state.settings);
export const getCallLogsMVSettings = createSelector(selectDevicesDbState, (state: fromDevicesDb.State) => state.callLogsSettings);
export const getStatusList = createSelector(selectDevicesDbState, fromDevicesDb.getStatus);
export const getTags = createSelector(selectDevicesDbState, fromDevicesDb.getTags);
export const getSite = createSelector(selectDevicesDbState, fromDevicesDb.getSite);
export const getCallLogs = createSelector(selectDevicesDbState, fromDevicesDb.getCallLogs);
export const getAllVariables = createSelector(selectDevicesDbState, fromDevicesDb.getVariables);
export const getVariable = createSelector(selectDevicesDbState, fromDevicesDb.getVariable);
export const getDevicesUpdated = createSelector(selectDevicesDbState, fromDevicesDb.getDevicesUpdated);
export const getDevice = createSelector(selectDevicesDbState, fromDevicesDb.getDevice);
export const getTimeseries = createSelector(selectDevicesDbState, fromDevicesDb.getTimeSeries);

export const getPagination = createSelector(selectDevicesDbState, getMVSettings, (state, settings) => {
  return settings && settings['masterViewTable']['bluePrint'].pageSize
    ? { ...state.pagination, limit: settings['masterViewTable']['bluePrint'].pageSize.toString() }
    : state.pagination;
});
export const getInitialSort = createSelector(selectDevicesDbState, (state: fromDevicesDb.State) => state.initialSort);

export const getDevicesAsPlatformResponse = createSelector(getAllDevices, getPagination, getInitialSort, (data, pagination, initialSort) => {
  const response: PlatformResponse = {
    data: data.map((device) => {
      return {
        ...device,
        isRefreshCommandEnabled: !!(
          device.outgoingConnector?.requestConfiguration?.commands?.includes('refresh') &&
          (device.outgoingConnector?.requestConfiguration?.authentication !== 'login' ||
            (device.outgoingConnector?.requestConfiguration?.authentication === 'login' && device.credential?.login && device.credential?.password))
        ),
        isConfigureCommandEnabled: !!device.incomingConnector?.configuration?.url
      };
    }),
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total,
    initialSort
  };
  return response;
});

//
//
export const getTagsLoaded = createSelector(selectDevicesUiState, fromDevicesUi.getTagsLoaded);
export const getCallLogsLoading = createSelector(selectDevicesUiState, fromDevicesUi.getCallLogsLoading);
export const getCallLogsLoaded = createSelector(selectDevicesUiState, fromDevicesUi.getCallLogsLoaded);
export const getDeviceLoaded = createSelector(selectDevicesUiState, fromDevicesUi.getDeviceLoaded);
export const getDeviceLoading = createSelector(selectDevicesUiState, fromDevicesUi.getDeviceLoading);
export const getDeviceVarLoaded = createSelector(selectDevicesUiState, fromDevicesUi.getDeviceVarLoaded);
export const getSiteLoaded = createSelector(selectDevicesUiState, fromDevicesUi.getSiteLoaded);

//****************//
//   CONNECTORS   //
//****************//

export const {
  selectIds: getConnectorsIds,
  selectEntities: getConnectorsEntities,
  selectAll: getAllConnectors,
  selectTotal: getTotalConnectors
} = fromDeviceConnectorsDb.adapter.getSelectors(getDeviceConnectorsDbState);

export const getSelectedConnectorId = createSelector(getDeviceConnectorsDbState, fromDeviceConnectorsDb.getSelectedConnectorId);
export const getConnectorsPagination = createSelector(getDeviceConnectorsDbState, (state) => {
  return state.pagination;
});
export const getConnectorsAsPlatformResponse = createSelector(getAllConnectors, getConnectorsPagination, (connectors, pagination) => {
  const sortedData = [...connectors].sort(SortUtil.sortByProperty('id'));
  return {
    data: sortedData,
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total,
    next: pagination.next,
    previous: pagination.prev
  };
});

export const getConnectorLoaded = createSelector(getDeviceConnectorsUiState, fromDeviceConnectorsUi.getConnectorLoaded);
export const getConnectorLoading = createSelector(getDeviceConnectorsUiState, fromDeviceConnectorsUi.getConnectorLoading);
