<mat-card class="dialog-card catalog-panel-container mat-elevation-z0">
  <mat-card-title class="dialog-card-header sticky">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'ADMIN.PRODUCT_CATALOGS.CATALOG_PANEL.TITLE' | translate }}</h1>
      <button
        mat-icon-button
        class="regular-round-button"
        *ngIf="canUpdate || canDelete"
        [matTooltip]="
          (catalogs.length ? 'ADMIN.PRODUCT_CATALOGS.CATALOG_PANEL.EDIT_CATALOGS' : 'ADMIN.PRODUCT_CATALOGS.CATALOG_PANEL.CREATE_CATALOG') | translate
        "
        matTooltipClass="regular-tooltip"
        (click)="editCatalogs.emit()"
      >
        <mat-icon>{{ catalogs.length ? 'edit' : 'add' }}</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="catalog-panel-content p-20" fxLayout="column" fxLayoutGap="16px">
    <div *ngFor="let catalog of catalogs" class="catalog-panel-catalog-card" fxLayout="column" fxLayoutGap="6px">
      <iot-platform-ui-detail-popup-display-property
        property="{{ 'IOT_DICTIONARY.NAME' | translate }}:"
        value="{{ catalog.name }}"
      ></iot-platform-ui-detail-popup-display-property>
      <iot-platform-ui-detail-popup-display-property
        property="{{ 'DEVICES.CONNECTORS.DETAIL_POPUP.ATTACHED_ENTITIES' | translate }}:"
        value="{{ displayEntities(catalog.entities) | infoDisplay }}"
      ></iot-platform-ui-detail-popup-display-property>
    </div>
  </mat-card-content>
</mat-card>
