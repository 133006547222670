import { Gateway } from '@iot-platform/models/oyan';
import { SiteBuilder } from './site.builder';

export class GatewayBuilder {
  public static DEFAULT_GATEWAY = {
    id: '5b957b45-d375-4d35-92ec-9512a670eaa8',
    entityId: 'ecec940a-f2cf-4d74-b277-ccbf9e0087f2',
    site: {
      ...SiteBuilder.build('29bdc9eb-435c-4668-a310-5f9d08300988', '001103912'),
      displayName: 'ITSS-001103912-test'
    },
    fleetId: 'None',
    eui: '12345DE455667777',
    connected: false,
    name: '001103912_001',
    description: null,
    ip: null,
    port: null,
    createdAt: '2021-06-17T09:40:54.300340+00:00',
    createdBy: null,
    updatedAt: '2021-07-07T00:00:27.561590+00:00',
    updatedBy: null
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static build = (id: string, eui = '', params: Partial<Gateway> = GatewayBuilder.DEFAULT_GATEWAY): Gateway | any => ({
    ...params,
    id,
    eui
  });
}
