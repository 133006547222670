import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PoEventRule } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-po-event-info',
  templateUrl: './po-event-info.component.html',
  styleUrls: ['./po-event-info.component.scss']
})
export class PoEventInfoComponent {
  @Input() selectedRule!: PoEventRule;
  @Input() canUpdateRule = false;

  @Output() editRule: EventEmitter<void> = new EventEmitter();
  @Output() configureRule: EventEmitter<void> = new EventEmitter();

  getTypeof(variable: unknown): string {
    return typeof variable;
  }
}
