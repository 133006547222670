<section fxLayout="row" fxLayoutGap="10px" style="font-size: 10px;">
  <div fxLayout="row" fxLayoutGap="2px" fxLayoutAlign="start center" style="color: #B0B0B0;">
    <span class="capitalize-first-letter">{{ 'IOT_DICTIONARY.CREATED_AT' | translate }}</span>
    <span class="capitalize-first-letter fw-600">{{ data?.createdAt | dateFormat }}</span>
    <span *ngIf="hasCreator">{{'IOT_DICTIONARY.BY' | translate }}</span>
    <span class="capitalize-first-letter fw-600" *ngIf="hasCreator">{{ data?.createdBy | userName }}</span>
  </div>

  <div fxLayout="row" fxLayoutGap="2px" fxLayoutAlign="start center" style="color: #B0B0B0;" *ngIf="data?.updatedAt">
    <span class="capitalize-first-letter">{{ 'IOT_DICTIONARY.UPDATED_AT' | translate }}</span>
    <span class="capitalize-first-letter fw-600">{{ data?.updatedAt | dateFormat }}</span>
    <span *ngIf="hasUpdator">{{ 'IOT_DICTIONARY.BY' | translate }}</span>
    <span class="capitalize-first-letter fw-600" *ngIf="hasUpdator">{{ data?.updatedBy | userName }}</span>
  </div>
</section>
