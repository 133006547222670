import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { NotificationService } from '@iot-platform/notification';
import { CachedUsersService } from '@iot-platform/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { of } from 'rxjs';
import { catchError, concatAll, concatMap, exhaustMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
  BusinessProfilesApiActions,
  BusinessProfilesMembersApiActions
} from '../../../../../iot4bos-backoffice-ui/src/lib/features/admin-business-profiles/state/actions';
import { AdminOrganizationsAdministratorsApiActions } from '../../../../../iot4bos-backoffice-ui/src/lib/features/admin-organizations/state/actions';
import { PreferencesActions } from '../../../../../users/src/lib/features/preferences/state/actions';
import { AuthService } from '../../services/auth.service';
import { AuthorizationService } from '../../services/authorization.service';
import { AuthApiActions, AuthBusinessProfilesApiActions, AuthBusinessProfilesPageActions, AuthPageActions } from '../actions';
import * as fromAuth from '../reducers';

@Injectable()
export class AuthEffects {
  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.signIn),
      mergeMap((action) =>
        this.authService.signIn(action.username, action.password).pipe(
          map((user: CognitoUser) => {
            if (user['challengeName'] === 'NEW_PASSWORD_REQUIRED') {
              return AuthPageActions.requireNewPassword({
                username: user.getUsername()
              });
            } else {
              return AuthApiActions.signInSuccess({ cognitoUser: user });
            }
          }),
          catchError((error) => of(AuthApiActions.signInFailure({ error })))
        )
      )
    )
  );

  signInSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.signInSuccess),
      switchMap((action) =>
        of([AuthApiActions.storeSession({ session: action.cognitoUser }), AuthPageActions.loadAccount(), PreferencesActions.loadUserPreferences()]).pipe(
          concatAll()
        )
      )
    )
  );

  validateSsoTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.validateSsoTokens),
      switchMap(({ idToken, accessToken, refreshToken, expiresIn }) => {
        return this.authService.validateSsoTokens(idToken).pipe(
          map(() => AuthApiActions.signInWithSSOSuccess({ idToken, accessToken, refreshToken, expiresIn })),
          catchError((error: HttpErrorResponse) => {
            let errorMsg: string;
            switch (error.status) {
              case 401:
                errorMsg = 'Account not found please contact your key user';
                break;
              case 403:
                errorMsg = 'Your account is disabled please contact your key user';
                break;
              default:
                errorMsg = 'Authentication failed please contact your key user';
            }
            setTimeout(() => this.notificationService.displayError(errorMsg));

            return of(AuthApiActions.signInWithSSOFailure({ error }), AuthPageActions.signOut());
          })
        );
      })
    )
  );

  refreshSsoTokensSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.refreshSsoTokensSuccess),
      tap(({ idToken, accessToken, tokenType, expiresIn }) => {
        this.authService.storeSsoTokens({ idToken, accessToken, tokenType, expiresIn: String(expiresIn) });
      }),
      mergeMap(() => {
        return [AuthPageActions.loadAccount()];
      })
    )
  );
  retrieveSsoSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.retrieveSsoSession),
      mergeMap(({ idToken, accessToken, refreshToken }) => {
        return [
          AuthApiActions.retrieveSsoSessionSuccess({ idToken, accessToken, refreshToken }),
          AuthPageActions.loadAccount(),
          PreferencesActions.loadUserPreferences(),
          AuthBusinessProfilesPageActions.selectBusinessProfile({
            selectedBusinessProfile: JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)),
            withRedirect: false
          })
        ];
      }),
      catchError((error) => of(AuthApiActions.retrieveSsoSessionFailure({ error })))
    )
  );

  signInWithSSOSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.signInWithSSOSuccess),
      mergeMap(() => {
        this.storage.set(LocalStorageKeys.STORAGE_SSO_LOGGED_IN_AT, `${Date.now()}`);
        this.router.navigate(['/login']);
        return [AuthPageActions.loadAccount(), PreferencesActions.loadUserPreferences()];
      })
    )
  );

  retrieveSessionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.retrieveSessionSuccess),
      mergeMap((action) => {
        return [
          AuthApiActions.storeSession({ session: action.cognitoUser }),
          AuthPageActions.loadAccount(),
          PreferencesActions.loadUserPreferences(),
          AuthBusinessProfilesPageActions.selectBusinessProfile({
            selectedBusinessProfile: JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)),
            withRedirect: false
          })
        ];
      })
    )
  );

  checkIfUserIsAdminAfterSelectBusinessProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(fromAuth.selectSelectedBusinessProfileForAccount), this.store.select(fromAuth.selectUserId)))
      ),
      map(([, selectedBusinessProfile, currentUserId]) =>
        AuthApiActions.checkIfUserIsAdmin({
          entityId: selectedBusinessProfile?.entityId,
          userId: currentUserId
        })
      )
    )
  );

  checkIfUserIsAdminAfterLoadSelectedEntityForSessionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthBusinessProfilesApiActions.loadSelectedEntityForSessionSuccess,
        AuthApiActions.loadAccountSuccess,
        AdminOrganizationsAdministratorsApiActions.removeAdministratorFromOrganizationSuccess,
        AdminOrganizationsAdministratorsApiActions.addAdministratorToOrganizationSuccess
      ),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(fromAuth.selectSelectedEntityForSession), this.store.select(fromAuth.selectUserId)))
      ),
      exhaustMap(([_, selectedEntityForSession, currentUserId]) =>
        of(
          AuthApiActions.checkIfUserIsAdmin({
            entityId: selectedEntityForSession?.id,
            userId: currentUserId
          })
        )
      )
    )
  );

  checkIfUserIsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.checkIfUserIsAdmin),
      switchMap((action) =>
        this.authService.isUserAdmin(action.entityId, action.userId).pipe(
          map((isUserAdmin) =>
            AuthApiActions.checkIfUserIsAdminSuccess({
              isAdminUser: isUserAdmin
            })
          ),
          catchError((error) => of(AuthApiActions.checkIfUserIsAdminFailure(error)))
        )
      )
    )
  );

  // TODO
  selectBusinessProfileSuccessThenLoadPrivileges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess),
      map(() => AuthPageActions.loadPrivileges())
    )
  );

  // TODO : use withLastestFrom
  storeSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.storeSession),
        switchMap((action) => {
          this.storage.set(LocalStorageKeys.STORAGE_ID_TOKEN_KEY, action.session.getSignInUserSession().getIdToken().getJwtToken());
          return this.store.select(fromAuth.selectAuthApiState);
        }),
        tap((state) => {
          if (state && state.cognitoUser) {
            this.authService.storeSession(JSON.stringify(state.cognitoUser.getSignInUserSession().getIdToken().getJwtToken()));
          }
        })
      ),
    { dispatch: false }
  );

  retrieveSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.retrieveSession),
      switchMap(() => {
        return this.authService.retrieveCognitoUser().pipe(
          map((cognitoUser) => {
            return AuthApiActions.retrieveSessionSuccess({
              cognitoUser: cognitoUser
            });
          }),
          catchError((error) => {
            return of(AuthApiActions.retrieveSessionFailure({ error }));
          })
        );
      })
    )
  );

  loadAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.loadAccount),
      switchMap(() =>
        this.authService.loadAccount().pipe(
          map((account) => {
            this.storage.set(LocalStorageKeys.STORAGE_USER_PREFERENCES, JSON.stringify(account.preferences));
            return AuthApiActions.loadAccountSuccess({ account });
          }),
          catchError((error) => of(AuthApiActions.loadAccountFailure({ error })))
        )
      )
    )
  );

  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.signOut),
      mergeMap(() =>
        this.authService.logout().pipe(
          map(() => AuthApiActions.signOutSuccess()),
          catchError((error) => of(AuthApiActions.signOutFailure({ error })))
        )
      )
    )
  );

  signOutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.signOutSuccess),
        tap(() => {
          this.storage.clear();
          this.dialog.closeAll();
          this.router.navigate(['/login']);
          this.notificationService.hideLoader();
        })
      ),
    { dispatch: false }
  );

  signOutFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.signOutFailure),
        tap(() => {
          this.notificationService.hideLoader();
        })
      ),
    { dispatch: false }
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.refreshToken),
      switchMap(() => {
        return this.authService.retrieveCognitoUser().pipe(
          map((refreshed) => AuthApiActions.refreshTokenSuccess({ refreshed })),
          catchError((error) => of(AuthApiActions.refreshTokenFailure({ error })))
        );
      })
    )
  );

  retrieveSessionFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.retrieveSessionFailure),
        tap(() => {
          this.router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.changePassword),
      mergeMap((action) =>
        this.authService.changePassword(action.user, action.newPassword).pipe(
          map((result) => AuthApiActions.changePasswordSuccess({ result })),
          catchError((error) => of(AuthApiActions.changePasswordFailure({ error })))
        )
      )
    )
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.forgotPassword),
      mergeMap((action) =>
        this.authService.forgotPassword(action.username).pipe(
          map((result) => AuthApiActions.forgotPasswordSuccess({ result })),
          catchError((error) => of(AuthApiActions.forgotPasswordFailure({ error })))
        )
      )
    )
  );

  forgotPasswordSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.updatePasswordWithCode),
      mergeMap((action) =>
        this.authService
          .forgotPasswordSubmit({
            username: action.username,
            password: action.password,
            code: action.code
          })
          .pipe(
            map((result) => AuthApiActions.updatePasswordWithCodeSuccess({ result })),
            catchError((error) => of(AuthApiActions.updatePasswordWithCodeFailure({ error })))
          )
      )
    )
  );

  loadAuthorizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.loadPrivileges),
      switchMap(() =>
        this.authorizationsService.getAuthorizations().pipe(
          map((privileges) => AuthApiActions.loadPrivilegesSuccess({ privileges })),
          catchError((error) => of(AuthApiActions.loadPrivilegesFailure({ error })))
        )
      )
    )
  );

  reloadOnBusinessProfileUpdatedOrMembersChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BusinessProfilesApiActions.updateBusinessProfileSuccess,
        BusinessProfilesMembersApiActions.addMemberToBusinessProfileSuccess,
        BusinessProfilesMembersApiActions.removeMemberFromBusinessProfileSuccess
      ),
      map(() => AuthPageActions.loadAccount())
    )
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.updatePasswordWithCodeSuccess),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly authorizationsService: AuthorizationService,
    private readonly notificationService: NotificationService,
    private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly storage: LocalStorageService,
    private readonly cachedUsersService: CachedUsersService
  ) {}
}
