import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Entity } from '@iot-platform/models/common';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SortEntitiesUtil } from './utils/sort-entities.util';

@Injectable({
  providedIn: 'root'
})
export class EntitiesService {
  constructor(@Inject('environment') private environment, public httpClient: HttpClient) {}

  getAll(): Observable<Entity[]> {
    return this.httpClient.get<Entity[]>(this.environment.api.url + this.environment.api.endpoints.entities).pipe(map((entities: any) => entities.content));
  }

  getHierarchicallySortedEntities(): Observable<Entity[]> {
    return this.getAll().pipe(map((entities: any) => SortEntitiesUtil.sortHierarchically(entities)));
  }

  getEntityNameDuplicatesTotal(entityName: string): Observable<number> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', '0');
    params = params.set('page', '0');
    params = params.set('name', entityName);

    return this.httpClient
      .get(this.environment.api.url + this.environment.api.endpoints.entities, { params })
      .pipe(map((data: { page: any; content: any }) => data.page.total));
  }
}
