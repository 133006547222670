import { Injectable } from '@angular/core';
import { NotificationService } from '@iot-platform/notification';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import {
  BusinessProfilesApiActions as I4bBusinessProfilesApiActions,
  BusinessProfilesMembersApiActions as I4bBusinessProfilesMembersApiActions,
  BusinessProfilesMembersDialogActions as I4bBusinessProfilesMembersDialogActions
} from '../../../../../../../iot4bos-backoffice-ui/src/lib/features/admin-business-profiles/state/actions';
import { AdminUsersApiActions } from '../../../../../../../iot4bos-backoffice-ui/src/lib/features/admin-users/state/actions';
import { of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AdminBusinessProfilesActions } from '../actions';
import { AdminBusinessProfilesFacade } from '../facades/admin-business-profiles.facade';

@Injectable()
export class AdminBusinessProfilesEffects {
  setUserBusinessProfiles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminUsersApiActions.getBusinessProfilesByUserIdSuccess),
      map(({ businessProfiles }) => AdminBusinessProfilesActions.setUserBusinessProfiles({ businessProfiles }))
    );
  });
  setSelectedBusinessProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(I4bBusinessProfilesMembersDialogActions.addMemberToBusinessProfile, I4bBusinessProfilesMembersDialogActions.removeMemberFromBusinessProfile),
      concatLatestFrom(() => this.adminBusinessProfilesFacade.allBusinessProfilesEntities$),
      mergeMap(([action, businessProfilesEntities]) =>
        of(AdminBusinessProfilesActions.setSelectedBusinessProfile({ businessProfile: businessProfilesEntities[action.businessProfileId] }))
      )
    );
  });
  linkBusinessProfileToUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(I4bBusinessProfilesMembersApiActions.addMemberToBusinessProfileSuccess),
      concatLatestFrom(() => this.adminBusinessProfilesFacade.selectedBusinessProfile$),
      mergeMap(([action, businessProfile]) => of(AdminBusinessProfilesActions.linkBusinessProfileToUserSuccess({ businessProfile, user: action.addedMember })))
    );
  });
  unlinkBusinessProfileFromUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(I4bBusinessProfilesMembersApiActions.removeMemberFromBusinessProfileSuccess),
      concatLatestFrom(() => this.adminBusinessProfilesFacade.selectedBusinessProfile$),
      mergeMap(([action, businessProfile]) =>
        of(AdminBusinessProfilesActions.unlinkBusinessProfileFromUserSuccess({ businessProfile, user: action.removedMember }))
      )
    );
  });
  linkBusinessProfileToUserSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminBusinessProfilesActions.linkBusinessProfileToUserSuccess),
      map(({ user }) => {
        user.totalBusinessProfile++;
        return AdminUsersApiActions.updateUserSuccess({ updatedUser: { id: user.id, changes: user } });
      })
    );
  });
  unlinkBusinessProfileFromUserSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminBusinessProfilesActions.unlinkBusinessProfileFromUserSuccess),
      map(({ user }) => {
        user.totalBusinessProfile--;
        return AdminUsersApiActions.updateUserSuccess({ updatedUser: { id: user.id, changes: user } });
      })
    );
  });
  displayLoader$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          I4bBusinessProfilesMembersDialogActions.addMemberToBusinessProfile,
          I4bBusinessProfilesMembersDialogActions.removeMemberFromBusinessProfile,
          I4bBusinessProfilesApiActions.listBusinessProfilesFailure
        ),
        tap(() => {
          this.notificationService.displayLoader(true);
        })
      );
    },
    { dispatch: false }
  );
  hideLoader$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          I4bBusinessProfilesMembersApiActions.addMemberToBusinessProfileSuccess,
          I4bBusinessProfilesMembersApiActions.addMemberToBusinessProfileFailure,
          I4bBusinessProfilesMembersApiActions.removeMemberFromBusinessProfileSuccess,
          I4bBusinessProfilesMembersApiActions.removeMemberFromBusinessProfileFailure
        ),
        tap(() => {
          this.notificationService.displayLoader(false);
        })
      );
    },
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          I4bBusinessProfilesMembersApiActions.addMemberToBusinessProfileSuccess,
          I4bBusinessProfilesMembersApiActions.removeMemberFromBusinessProfileSuccess
        ),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      );
    },
    { dispatch: false }
  );
  displayError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          I4bBusinessProfilesMembersApiActions.addMemberToBusinessProfileFailure,
          I4bBusinessProfilesMembersApiActions.removeMemberFromBusinessProfileFailure
        ),
        tap((action) => {
          this.notificationService.displayError(action);
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly notificationService: NotificationService,
    private readonly adminBusinessProfilesFacade: AdminBusinessProfilesFacade
  ) {}
}
