<div class="default-theme">
  <mat-sidenav-container class="main-container" (backdropClick)="closePreferences()">
    <mat-sidenav #sidenav mode="side" [opened]="sidenavState.isOpen" class="navigation-bar">
      <div fxFlex fxLayout="column" fxLayoutAlign="space-between" class="navigation-bar-content">
        <mat-nav-list *ngIf="navTree" class="sidenav">
          <iot4bos-ui-sidenav-block *ngFor="let navBlock of navTree; trackBy: trackById" [navBlock]="navBlock"></iot4bos-ui-sidenav-block>
        </mat-nav-list>
        <iot-platform-ui-build-info class="sticky" (openReleaseNotes)="openReleaseNotes()"></iot-platform-ui-build-info>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="main-sidenav-content">
      <div class="sticky">
        <iot-platform-ui-header
          [currentUser]="currentUser$ | async"
          [currentUserLoading]="currentUserLoading$ | async"
          [businessProfiles]="businessProfiles$ | async"
          [selectedBusinessProfile]="businessProfile$ | async"
          [enableBusinessProfileSwitching]="enableBusinessProfileSwitching$ | async"
          [logo]="'assets/images/LOGO_AIR_LIQUIDE.png'"
          (logout)="logout()"
          (logoClicked)="returnHome()"
          (menuClicked)="toggleSidenav()"
          (myProfile)="openMyProfile()"
          (businessProfileListClicked)="refreshBusinessProfileList()"
          (changeBusinessProfile)="onChangeBusinessProfile($event)"
        >
        </iot-platform-ui-header>
      </div>
      <div class="main-sidenav-body">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
