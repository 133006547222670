import { Component, Input, OnInit } from '@angular/core';
import { Contact } from '@iot-platform/models/common';

@Component({
  selector: 'iot-platform-ui-site-detail-contacts',
  templateUrl: './site-detail-contacts.component.html',
  styleUrls: ['./site-detail-contacts.component.scss']
})
export class SiteDetailContactsComponent implements OnInit {
  @Input() contacts: Contact[] = [];
  @Input() canReadContacts: boolean = false;
  constructor() {}

  ngOnInit(): void {}
}
