import { PlatformResponse } from '@iot-platform/models/common';
import { CommandType } from '@iot-platform/models/i4b';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import { SortUtil } from '../../../../../../../shared/src/lib/utils/sort.util';

import * as fromAssetsDb from './assets-db.reducer';
import * as fromAssetsUi from './assets-ui.reducer';

export const assetsFeatureKey = 'assets';

export interface AssetsState {
  [fromAssetsDb.assetsDbFeatureKey]: fromAssetsDb.State;
  [fromAssetsUi.assetsUiFeatureKey]: fromAssetsUi.State;
}

export interface State {
  [assetsFeatureKey]: AssetsState;
}

export function reducers(state: AssetsState | undefined, action: Action) {
  return combineReducers({
    [fromAssetsDb.assetsDbFeatureKey]: fromAssetsDb.reducer,
    [fromAssetsUi.assetsUiFeatureKey]: fromAssetsUi.reducer
  })(state, action);
}

export const selectAssetsState = createFeatureSelector<AssetsState>(assetsFeatureKey);
export const selectAssetsDbState = createSelector(selectAssetsState, (state: AssetsState) => {
  return state[fromAssetsDb.assetsDbFeatureKey];
});

export const selectAssetsUiState = createSelector(selectAssetsState, (state: AssetsState) => state[fromAssetsUi.assetsUiFeatureKey]);

export const {
  selectIds: getAssetsIds,
  selectEntities: getAssetsEntities,
  selectAll: getAllAssets,
  selectTotal: getTotalAssets
} = fromAssetsDb.adapter.getSelectors(selectAssetsDbState);

export const getSelectedAssetId = createSelector(selectAssetsDbState, fromAssetsDb.getSelectedId);

export const getSelectedAsset = createSelector(getAssetsEntities, getSelectedAssetId, (entities, selectedId) => selectedId && entities[selectedId]);

// ***** DATA
export const getVariable = createSelector(selectAssetsDbState, (state: fromAssetsDb.State) => state.variable);

export const getVariablesByAssetId = createSelector(selectAssetsDbState, (state: fromAssetsDb.State) =>
  state ? state.allVariables?.sort(SortUtil.sortByName) : []
);

export const getSite = createSelector(selectAssetsDbState, fromAssetsDb.getSite);
export const getAsset = createSelector(selectAssetsDbState, fromAssetsDb.getAsset);

export const getTags = createSelector(selectAssetsDbState, (state: fromAssetsDb.State) => state.tags);

export const getMVSettings = createSelector(selectAssetsDbState, (state: fromAssetsDb.State) => state.settings);

export const getPagination = createSelector(selectAssetsDbState, getMVSettings, (state, settings) => {
  return settings && settings['masterViewTable']['bluePrint'].pageSize
    ? { ...state.pagination, limit: settings['masterViewTable']['bluePrint'].pageSize.toString() }
    : state.pagination;
});
export const getInitialSort = createSelector(selectAssetsDbState, (state: fromAssetsDb.State) => state.initialSort);

export const getFormattedData = createSelector(getAllAssets, getPagination, getInitialSort, (data, pagination, initialSort) => {
  const response: PlatformResponse = {
    data: data.map((asset) => {
      return {
        ...asset,
        isRefreshCommandEnabled: !!asset.commands?.includes(CommandType.REFRESH)
      };
    }),
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total,
    initialSort
  };
  return response;
});

export const getCurrentFavoriteView = createSelector(selectAssetsDbState, (state: fromAssetsDb.State) => state.currentFavoriteView);

export const getCurrentFilters = createSelector(selectAssetsDbState, (state: fromAssetsDb.State) => state.currentFilters);

// ***** UI
export const getSiteLoaded = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) => state.siteLoaded);

export const getError = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) => state.error);

export const getAssetLoaded = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) => state.assetLoaded);

export const getAssetLoading = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) => state.assetLoading);

export const getAssetVarLoaded = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) => (state ? state.assetVarLoaded : false));

export const getAssetVarLoading = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) => state.assetVarLoading);

export const getTagsLoaded = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) => (state ? state.tagsLoaded : false));

export const selectAssetCreationByTemplateStatuses = createSelector(selectAssetsUiState, (state: fromAssetsUi.State) =>
  state ? state.assetCreationByTemplateStatuses : { assetCreationByTemplateCompleted: false, assetCreationByTemplatePending: false }
);
