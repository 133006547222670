import { createReducer, on } from '@ngrx/store';
import { BusinessProfilesMembersApiActions, BusinessProfilesPageActions, BusinessProfilesTagsApiActions, BusinessProfilesTagsPageActions } from '../actions';

export const adminBusinessProfilesMembersPageFeatureKey = 'adminBusinessProfilesMembersPage';

export interface State {
  error: any;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false
};

export const getError = (state: State) => state.error;

export const reducer = createReducer(
  initialState,
  on(BusinessProfilesPageActions.listMembersByBusinessProfile, (state: State) => ({ ...state, error: null, pending: true })),
  on(BusinessProfilesMembersApiActions.listMembersByBusinessProfileSuccess, (state: State, { members }) => ({ ...state, error: null, pending: false })),
  on(BusinessProfilesMembersApiActions.listMembersByBusinessProfileFailure, (state: State, { error }) => ({ ...state, error, pending: false }))
);
export const getPending = (state: State) => state.pending;
