import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Graph } from '@iot-platform/models/common';
import { Asset, FollowedVariable } from '@iot-platform/models/i4b';
import {
  WidgetAction,
  WidgetEvent,
  WidgetInstance,
  WidgetOptions,
  WidgetSize,
  WidgetTypes
} from '@iot-platform/models/widgets';
import { get } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GraphsService } from '../../../../../shared/src/lib/services/graphs.service';

import {
  VariableChartDialogComponent
} from '../../../../../shared/src/lib/variable-chart/variable-chart-dialog/variable-chart-dialog.component';

@Component({
  selector: 'iot4bos-ui-graph-list-popup',
  templateUrl: './graph-list-popup.component.html',
  styleUrls: ['./graph-list-popup.component.css']
})
export class GraphListPopupComponent implements OnInit, OnDestroy {
  widgets: WidgetInstance[] = [];
  loading = true;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private readonly dialog: MatDialog,
    public dialogRef: MatDialogRef<GraphListPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: { asset: Asset; followedVariable: FollowedVariable; siteId: string },
    private readonly graphsService: GraphsService
  ) {}

  ngOnInit(): void {
    this.graphsService
      .getGraphsByAssetVariableId(get(this.data, 'followedVariable.id'), get(this.data, 'siteId'))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (graphs: Graph[]) => {
          this.loading = false;
          this.initWidgets(graphs);
        },
        () => {
          this.loading = false;
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onDispatchEvent(event: WidgetEvent): void {
    switch (event.action) {
      case WidgetAction.PREVIEW:
        this.openGraph(event.widget.data);
        break;
      default:
        break;
    }
  }

  openGraph(graph: Graph) {
    this.dialog.open(VariableChartDialogComponent, {
      width: '990px',
      data: {
        variables: graph.variables,
        variableType: 'assetVariable',
        title: graph.name
      }
    });
  }

  private initWidgets(graphList: Graph[]): void {
    this.widgets = graphList.map((graph: Graph) => this.buildWidget(graph));
  }

  private buildWidget(graph: Graph): WidgetInstance {
    return new WidgetInstance({
      type: WidgetTypes.TIME_SERIES,
      data: graph,
      options: this.getWidgetOptions(graph)
    });
  }

  private getWidgetOptions(graph: Graph): WidgetOptions {
    const { name, variables } = graph;
    return {
      size: WidgetSize.XS,
      footer: {
        enabled: true,
        legend: `${name} (${variables.length})`
      },
      menu: {
        enabled: false,
        actions: []
      },
      chart: { searchBar: { enabled: false }, legend: { labelFormat: (params: any) => `${params.asset.name} - ${params.name}` } },
      variables
    };
  }
}
