<mat-card class="dialog-card site-info">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ title$ | async }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content site-info__content" fxLayout="row" fxLayout.lt-md="column">
    <div class="site-info__image" fxLayout="column wrap" fxLayoutAlign="center center" fxFlex="360px" fxFlex.lt-md="100%"><img [src]="imgPath" alt="" /></div>
    <form class="site-info__form" fxLayout="column" fxFlex="100%" class="full-width p-20" [formGroup]="siteForm">

      <div fxLayout="row">
        <mat-form-field fxFlex color="accent">
          <input formControlName="name" matInput #nameInput required maxlength="60" pattern="\S.*" [placeholder]="'SITES.INFO_FORM.NAME' | translate" />
          <mat-error *ngIf="name.invalid && name.errors.required">{{ 'SITES.INFO_FORM.ERROR_MESSAGE.NAME_REQUIRED' | translate }}</mat-error>
          <mat-error *ngIf="name.invalid && (name.errors.maxlength || name.errors.pattern)">{{ 'SITES.INFO_FORM.ERROR_MESSAGE.NAME' | translate }}</mat-error>
          <mat-error *ngIf="name.invalid && name.errors.duplicate">{{ 'SITES.INFO_FORM.ERROR_MESSAGE.DUPLICATE' | translate }}</mat-error>
          <mat-hint align="end">{{ nameInput.value?.length || 0 }}/60</mat-hint>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex color="accent">
          <input #shipToIdInput formControlName="shipToId" required matInput maxlength="30" [placeholder]="'SITES.INFO_FORM.SHIP_TO_ID' | translate" />
          <mat-error *ngIf="shipToId.invalid && shipToId.errors.required">{{ 'SITES.INFO_FORM.ERROR_MESSAGE.SHIP_TO_ID' | translate }}</mat-error>
          <mat-hint align="end">{{ shipToIdInput.value?.length || 0 }}/30</mat-hint>
        </mat-form-field>

        <mat-form-field fxFlex color="accent">
          <input #address1Input formControlName="address1" required matInput maxlength="50" [placeholder]="'SITES.INFO_FORM.ADDRESS_1' | translate" />
          <mat-error *ngIf="address1.invalid && address1.errors.required">{{ 'SITES.INFO_FORM.ERROR_MESSAGE.ADDRESS_1' | translate }}</mat-error>
          <mat-hint align="end">{{ address1Input.value?.length || 0 }}/50</mat-hint>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex color="accent">
          <mat-select formControlName="entity" required [placeholder]="'SITES.INFO_FORM.ENTITY' | translate">
            <mat-select-trigger *ngIf="entity.value">{{ entity.value.name }}</mat-select-trigger>
            <mat-option [value]="entity" *ngFor="let entity of sortedEntities">{{ entity.label }}</mat-option>
          </mat-select>
          <mat-error *ngIf="entity.invalid">{{ 'SITES.INFO_FORM.ERROR_MESSAGE.ENTITY' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex color="accent">
          <input #address2Input formControlName="address2" matInput maxlength="50" [placeholder]="'SITES.INFO_FORM.ADDRESS_2' | translate" />
          <mat-hint align="end">{{ address2Input.value?.length || 0 }}/50</mat-hint>

        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="20px">
        <div fxLayout="row" fxFlex="50%"></div>
        <div fxLayout="row" fxFlex="50%" fxLayoutGap="20px">
          <mat-form-field fxFlex color="accent">
            <input #cityInput formControlName="city" required matInput maxlength="30" [placeholder]="'SITES.INFO_FORM.CITY' | translate" />
            <mat-error *ngIf="city.invalid && city.errors.required">{{ 'SITES.INFO_FORM.ERROR_MESSAGE.CITY' | translate }}</mat-error>
            <mat-hint align="end">{{ cityInput.value?.length || 0 }}/30</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="20px">
        <div fxLayout="row" fxFlex="50%"></div>
        <div fxLayout="row" fxFlex="50%" fxLayoutGap="20px">
          <mat-form-field fxFlex color="accent">
            <input #zipCodeInput formControlName="zipCode" required matInput maxlength="10" [placeholder]="'SITES.INFO_FORM.ZIP_CODE' | translate" />
            <mat-error *ngIf="zipCode.invalid && zipCode.errors.required">{{ 'SITES.INFO_FORM.ERROR_MESSAGE.ZIP_CODE' | translate }}</mat-error>
            <mat-hint align="end">{{ zipCodeInput.value?.length || 0 }}/10</mat-hint>
          </mat-form-field>

          <mat-form-field fxFlex color="accent">
            <mat-select formControlName="country" required [placeholder]="'IOT_DICTIONARY.COUNTRY' | translate">
              <mat-option [value]="country.key" *ngFor="let country of countries$ | async">{{ country.value }}</mat-option>
            </mat-select>
            <mat-error *ngIf="country.invalid && country.errors.required">{{ 'SITES.INFO_FORM.ERROR_MESSAGE.COUNTRY' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <mat-form-field fxFlex color="accent">
        <input #descriptionInput formControlName="description" matInput maxlength="300" [placeholder]="'SITES.INFO_FORM.DESCRIPTION' | translate" />
        <mat-hint align="end">{{ descriptionInput.value?.length || 0 }}/300</mat-hint>
      </mat-form-field>

      <p class="dialog-form-tips">{{ 'SITES.INFO_FORM.MANDATORY_FIELDS' | translate }}</p>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="close()">{{ 'SITES.INFO_FORM.CANCEL' | translate }}</button>
    <button mat-raised-button class="button-regular" color="accent" [disabled]="!siteForm.valid" (click)="save()">{{ action$ | async }}</button>
  </mat-card-actions>
</mat-card>
