<div class="login-shell default-theme">
  <div class="login-shell-row" fxLayout="row" fxLayout.lt-md="column">
    <div class="login-shell-col" fxFlex="50%" fxFlex.lt-md="100%"></div>
    <div class="login-shell-col" fxFlex="50%" fxLayout="column" fxFlex.lt-md="100%">
      <h1 class="login-shell-title fw-600">{{ authConfig.appName }}</h1>

      <ng-container
        *ngIf="
          (loggedIn$ | async) === false && (stepEmail$ | async) === false && (stepPassword$ | async) === false && (loginWithEmailPassword$ | async) === false
        "
      >
        <iot-platform-login-select-authentication
          fxFlex
          [ssoDisabled]="(authConfig$ | async)?.ssoDisabled"
          (signInWithSSO)="onSignInWithSSO()"
          (signInWithEmailPassword)="onSignInWithEmailPassword()"
        ></iot-platform-login-select-authentication>
      </ng-container>

      <ng-container *ngIf="loginWithEmailPassword$ | async">
        <iot-platform-login-form
          fxFlex
          [isI4BLogin]="true"
          [hasError]="errorSignIn$ | async"
          (cancel)="onCancel()"
          (resetPassword)="onResetPassword()"
          (signIn)="onSignIn($event)"
        ></iot-platform-login-form>
      </ng-container>

      <ng-container *ngIf="(loggedIn$ | async) && (requireNewPassword$ | async)">
        <iot-platform-login-change-temporary-password-form
          [hasError]="errorPassword$ | async"
          (cancel)="onCancel()"
          (changeTemporaryPassword)="onChangeTemporaryPassword($event)"
        ></iot-platform-login-change-temporary-password-form>
      </ng-container>

      <ng-container *ngIf="stepEmail$ | async">
        <iot-platform-login-send-code-form
          [hasError]="errorEmail$ | async"
          [isI4BLogin]="true"
          (cancel)="onCancel()"
          (sendCode)="onSendCode($event)"
        ></iot-platform-login-send-code-form>
      </ng-container>

      <ng-container *ngIf="stepPassword$ | async">
        <iot-platform-login-reset-password-form
          [username]="username"
          [hasError]="errorPassword$ | async"
          (cancel)="onCancel()"
          (sendCode)="onSendCode($event)"
          (changePassword)="onChangeForgottenPassword($event)"
        ></iot-platform-login-reset-password-form>
      </ng-container>

      <ng-container *ngIf="(loggedIn$ | async) && (requireNewPassword$ | async) === false">
        <iot-platform-login-select-profile
          [businessProfiles$]="businessProfiles$"
          [filterProfiles]="authConfig.appName && authConfig.appName === AppName.KERCOM"
          [loading]="this.pending$ | async"
          (selectProfile)="onSelectProfile($event)"
        ></iot-platform-login-select-profile>
      </ng-container>
    </div>
  </div>
</div>
