export enum DataLibrary {
  SITES = 'SITES',
  ASSETS = 'ASSETS',
  DEVICES = 'DEVICES',
  TAGS = 'TAGS',
  VARIABLES = 'VARIABLES',
  DEVICE_EVENTS = 'DEVICE_EVENTS',
  ASSET_EVENTS = 'ASSET_EVENTS',
  TECHNICAL = 'TECHNICAL',
  CONNECTORS = 'CONNECTORS',
  STOCK_SITE_DEVICES = 'STOCK_SITE_DEVICES'
}
