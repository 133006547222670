import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BusinessProfile, Entity, Organization } from '@iot-platform/models/common';

import { TranslateService } from '@ngx-translate/core';

import * as momentTimezones from 'moment-timezone';

import { BehaviorSubject, fromEvent, Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

import { EntitiesService } from '../../../../../../shared/src/lib/entities.service';

import { BusinessProfilesService } from '../../../features/admin-business-profiles/services/business-profiles.service';

@Component({
  selector: 'iot4bos-backoffice-ui-dialog-business-profile-add-form',
  templateUrl: './dialog-business-profile-add-form.component.html',
  styleUrls: ['./dialog-business-profile-add-form.component.scss']
})
export class DialogBusinessProfileAddFormComponent implements OnInit, OnDestroy {
  businessProfileFrom: UntypedFormGroup;
  sortedEntities: Entity[] = [];
  initialName = '';
  initialEntity?: Entity;
  initialTZ?: string;
  entitiesLoading = true;
  isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  displayDuplicateMessage$: Subject<boolean> = new Subject<boolean>();

  timezones;
  chartDefaultPeriod;

  destroy$ = new Subject<void>();

  constructor(
    private readonly dialogRef: MatDialogRef<DialogBusinessProfileAddFormComponent>,
    private readonly entitiesService: EntitiesService,
    public readonly businessProfilesService: BusinessProfilesService,
    private readonly translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      organization: Organization;
      businessProfile: BusinessProfile;
      type?: string;
    }
  ) {
    this.timezones = momentTimezones.tz.names();

    const keyboard$ = fromEvent(document, 'keyup');

    keyboard$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.isDisabled$.next(true)),
        debounceTime(500),
        switchMap(() => {
          if (this.nameControl.value && this.organizationControl.value) {
            return this.checkBusinessProfileNameUnicity();
          } else {
            return of(null);
          }
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.businessProfileFrom = new UntypedFormGroup({
      organizationControl: new UntypedFormControl(
        this.data.organization || this.data.businessProfile ? { value: '', disabled: true } : { value: '', disabled: false },
        [Validators.required]
      ),
      nameControl: new UntypedFormControl(this.data.businessProfile ? this.data.businessProfile.name : '', [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern('\\S.*')
      ]),
      timezoneControl: new UntypedFormControl(
        this.data.businessProfile && this.data.businessProfile.timezoneDetails ? this.data.businessProfile.timezoneDetails.name : '',
        [Validators.required]
      ),
      chartPeriodControl: new UntypedFormControl(this.data.businessProfile ? this.data.businessProfile.chartPeriod : 1),
      stockVisibilityControl: new UntypedFormControl(this.data.businessProfile ? this.data.businessProfile.siteStocksVisible : true, [Validators.required])
    });

    this.entitiesService
      .getHierarchicallySortedEntities()
      .pipe(takeUntil(this.destroy$))
      .subscribe((entities) => {
        this.sortedEntities = entities;
        this.entitiesLoading = false;
        if (this.data.organization) {
          this.initialEntity = this.sortedEntities.find((e) => e.id === this.data.organization.id);
          this.organizationControl.setValue(this.initialEntity);
        } else if (this.data.businessProfile) {
          this.initialEntity = this.sortedEntities.find((e) => e.id === this.data.businessProfile.entityId);
          this.organizationControl.setValue(this.initialEntity);
        }
      });

    if (this.data.businessProfile) {
      this.initialName = this.data.businessProfile.name.trim();
      this.initialTZ = this.data.businessProfile.timezoneDetails?.name.trim();
    }
  }

  checkBusinessProfileNameUnicity() {
    this.isDisabled$.next(true);

    return this.businessProfilesService.getBusinessProfileUniqueness(this.initialName, this.nameControl.value.trim(), this.organizationControl.value.id).pipe(
      tap((b) => {
        this.isDisabled$.next(b && this.nameControl.touched);
        this.displayDuplicateMessage$.next(b);
      })
    );
  }

  get title$(): Observable<string> {
    return this.data.businessProfile
      ? this.translateService.get('ADMIN.DIALOG.ADD_BP.TITLE_EDIT', {
          businessProfileName: this.data.businessProfile.name
        })
      : this.translateService.get('ADMIN.DIALOG.ADD_BP.TITLE_ADD');
  }

  get nameControl(): AbstractControl {
    return this.businessProfileFrom.get('nameControl');
  }

  get organizationControl(): AbstractControl {
    return this.businessProfileFrom.get('organizationControl');
  }

  get timezoneControl(): AbstractControl {
    return this.businessProfileFrom.get('timezoneControl');
  }

  get chartPeriodControl(): AbstractControl {
    return this.businessProfileFrom.get('chartPeriodControl');
  }

  get stockVisibilityControl(): AbstractControl {
    return this.businessProfileFrom.get('stockVisibilityControl');
  }

  checkNameUnicity() {
    if (this.nameControl.value && this.organizationControl.value) {
      this.checkBusinessProfileNameUnicity().pipe(takeUntil(this.destroy$)).subscribe();
    }
  }

  onEntitySelection(entity: Entity) {
    if (!!entity) {
      this.organizationControl.setValue(entity);
      this.checkNameUnicity();
    }
  }

  resetEntity() {
    this.organizationControl.reset();
  }

  onTimezoneSelection(timezone: string): void {
    if (!!timezone) {
      this.timezoneControl.setValue(timezone);
    }
  }

  resetTimezone() {
    this.timezoneControl.reset();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const newBP: BusinessProfile = {
      name: this.nameControl.value.trim(),
      entityName: this.organizationControl.value.name,
      entityId: this.organizationControl.value.id,
      timezoneDetails: {
        name: this.timezoneControl.value,
        offset: momentTimezones().tz(this.timezoneControl.value).format('Z')
      },
      chartPeriod: this.chartPeriodControl.value,
      siteStocksVisible: this.stockVisibilityControl.value
    };
    this.dialogRef.close(newBP);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.businessProfilesService.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
