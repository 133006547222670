<div class="stock-card">
  <div class="stock-card-row" fxLayout="row" fxLayout.lt-md="column">
    <div class="stock-card-col" fxFlex="340px" fxFlex.lt-md="100%"></div>
    <div class="stock-card-col" fxFlex="100%">
      <span class="stock-card-name">{{ site?.name | infoDisplay }}</span>
      <div class="stock-card-content" fxLayout="row wrap">
        <ul class="stock-card-list" fxFlex="40%" fxFlex.lt-sm="100%">
          <li class="stock-card-item">
            <span class="stock-card-label">{{ 'SITES.CARD.TYPE' | translate }}</span
            >{{ 'SITES.CARD.TYPES.' + site.type | infoDisplay | translate }}
          </li>
          <li class="stock-card-item">
            <span class="stock-card-label">{{ 'SITES.CARD.ENTITY' | translate }}</span
            >{{ site?.entity?.name | infoDisplay }}
          </li>
        </ul>
        <div fxFlex="60%" fxFlex.lt-sm="100%">
          <div class="stock-card-total capitalize-first-letter">
            <mat-progress-spinner *ngIf="totalDevicesLoading" [diameter]="14" color="accent" mode="indeterminate"></mat-progress-spinner>
            <span *ngIf="!totalDevicesLoading" class="fw-600"> {{ totalDevices | numberFormat }}</span>
            {{ 'IOT_DICTIONARY.devices' | translate }}
          </div>

          <mat-progress-spinner *ngIf="deviceCountByFamilyLoading && (totalDevices > 0)" [diameter]="14" color="accent" mode="indeterminate"></mat-progress-spinner>
          <ul class="stock-card-types" *ngIf="totalDevices > 0 && !deviceCountByFamilyLoading" fxLayout="row wrap">
            <li fxFlex="25%" *ngFor="let item of deviceCountByFamily">
              <div *ngIf="item.total > 0">
                <span class="fw-600">{{ item.total | numberFormat }}</span> {{ item.family }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
