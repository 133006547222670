<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ title$ | async }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="closeOnCancel()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content" fxLayout="column">
    <form [formGroup]="editTagLabelForm" class="full-width p-20">
      <mat-form-field fxFlex color="accent">
        <input
          matInput
          formControlName="label"
          required
          autofocus
          maxlength="20"
          [placeholder]="'TAG_CATEGORY_LIST_COMPONENT.EDIT_TAG_LABEL_DIALOG.PLACEHOLDER' | translate"
        />
        <mat-hint align="end">{{ label.value.length || 0 }}/{{ data.tagNameMaxLength }}</mat-hint>
        <mat-error *ngIf="label.invalid && label.errors.duplicate">{{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_LABEL' | translate }}</mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-card-actions fxLayoutAlign="end center" class="dialog-card-actions">
    <button mat-raised-button color="accent" class="button-regular" [disabled]="!editTagLabelForm.valid" (click)="save()">
      {{ 'TAG_CATEGORY_LIST_COMPONENT.EDIT_TAG_LABEL_DIALOG.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
