import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

import { CustomCellParams } from '../custom-cell.params';

@Component({
  selector: 'grid-engine-basic-link-cell',
  templateUrl: './basic-link-cell.component.html',
  styleUrls: ['./basic-link-cell.component.scss']
})
export class BasicLinkCellComponent implements ICellRendererAngularComp {
  params: CustomCellParams;

  constructor() {}

  agInit(params: CustomCellParams): void {
    this.params = params;
  }

  refresh(params: CustomCellParams): boolean {
    return false;
  }

  onDispatchEvent(event: MouseEvent) {
    event.stopPropagation();
    this.params.dispatchEvent({ type: this.params.eventConfiguration.type, options: this.params.eventConfiguration.options, rawData: this.params.data });
  }
}
