import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';

import { Subject } from 'rxjs';

@Component({
  selector: 'iot-platform-login-reset-password-form',
  templateUrl: './login-reset-password-form.component.html',
  styleUrls: ['./login-reset-password-form.component.scss', '../../scss/style.scss']
})
export class LoginResetPasswordFormComponent implements OnInit {
  form: UntypedFormGroup;
  enableGauge: boolean;
  password$ = new Subject<string>();

  @Input() username: string;
  @Input() hasError: { message: string };

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() sendCode: EventEmitter<string> = new EventEmitter();
  @Output() changePassword: EventEmitter<{ username: string; code: string; password: string }> = new EventEmitter();

  ngOnInit() {
    this.form = new UntypedFormGroup({
      code: new UntypedFormControl(null, [Validators.required]),
      password: new UntypedFormControl(null, [Validators.required, this.checkPassword, this.checkConfirmPassword.bind(this)]),
      confirmPassword: new UntypedFormControl(null, [Validators.required, this.checkConfirmPassword.bind(this)])
    });
  }

  get code(): AbstractControl {
    return this.form.get('code');
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  get confirmPassword(): AbstractControl {
    return this.form.get('confirmPassword');
  }

  get errorPassword(): any {
    return this.form.getError('format', ['password']);
  }

  sendPasswordToRules(value: string): void {
    this.password$.next(value);
  }

  checkPassword(password: UntypedFormControl): ValidationErrors | null {
    let hasError = false;
    const errors = {
      format: {
        size: false,
        lowercase: false,
        uppercase: false,
        number: false,
        special: false
      }
    };
    if (!/^.{8,128}$/.test(password.value)) {
      hasError = true;
      errors.format.size = true;
    }
    if (!/^(?=.*[a-z]).*$/.test(password.value) || password.value === null) {
      hasError = true;
      errors.format.lowercase = true;
    }
    if (!/^(?=.*[A-Z]).*$/.test(password.value)) {
      hasError = true;
      errors.format.uppercase = true;
    }
    if (!/^(?=.*[0-9]).*$/.test(password.value)) {
      hasError = true;
      errors.format.number = true;
    }
    if (!/^(?=.*[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]).*$/.test(password.value)) {
      hasError = true;
      errors.format.special = true;
    }
    return hasError ? errors : null;
  }

  checkConfirmPassword(control: UntypedFormControl): ValidationErrors | null {
    if (!this.form) {
      return null;
    }
    const password = this.form.controls.password;
    const confirmPassword = this.form.controls.confirmPassword;
    const errorsOrNull = confirmPassword.value === password.value ? null : { different: true };

    if (control === this.password) {
      this.confirmPassword.setErrors(errorsOrNull);
      this.confirmPassword.markAsDirty();
      return null;
    }

    return errorsOrNull;
  }

  onCancel() {
    this.cancel.emit();
  }

  onSendCode($event) {
    $event.stopPropagation();
    this.sendCode.emit(this.username);
  }

  onChangePassword() {
    this.changePassword.emit({ username: this.username, code: this.code.value, password: this.password.value });
  }
}
