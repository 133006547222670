import { I4BCellType } from '../configurations';
import { I4bCellConcept } from '../enums';
import { HeaderType } from '../models';
import { I4BButtonColumn } from './i4b-button.column';

export class I4BDeviceButtonColumn extends I4BButtonColumn {
  constructor() {
    super(
      {
        type: HeaderType.CALL_TO_ACTION,
        displayName: 'BUTTON',
        icon: ''
      },
      {
        concept: I4bCellConcept.DEVICES,
        isConfigurable: false,
        isDefault: true,
        isLink: false,
        position: '',
        sortProperty: '',
        width: 10,
        id: 'button',
        cell: {
          type: I4BCellType.CALL_TO_ACTION,
          singleActions: [
            {
              order: 10,
              key: 'moveTo',
              label: 'IOT_DICTIONARY.MOVE_TO',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
            },
            {
              order: 20,
              key: 'refresh',
              label: 'DEVICES.CARD.REFRESH',
              enableConditions: [[{ key: 'isRefreshCommandEnabled', value: true }]],
              visibleConditions: [[{ key: 'canReadDevice', value: true }]]
            },
            {
              order: 30,
              key: 'configure',
              label: 'DEVICES.CARD.CONFIGURE',
              enableConditions: [[{ key: 'isConfigureCommandEnabled', value: true }]],
              visibleConditions: [[{ key: 'canCreateDevice', value: true }]]
            }
          ],
          bulkActions: [
            {
              order: 10,
              key: 'bulkAddTag',
              label: 'GRID_ENGINE.COLUMNS.CTA.ADD_TAGS',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
            },
            {
              order: 20,
              key: 'bulkRemoveTag',
              label: 'GRID_ENGINE.COLUMNS.CTA.REMOVE_TAGS',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
            },
            {
              order: 30,
              key: 'bulkRefresh',
              label: 'DEVICES.CARD.REFRESH',
              enableConditions: [[{ key: 'isRefreshCommandEnabled', value: true }]],
              visibleConditions: [[{ key: 'canReadDevice', value: true }]]
            },
            {
              order: 40,
              key: 'bulkMoveTo',
              label: 'IOT_DICTIONARY.MOVE_TO',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateDevice', value: true }]]
            }
          ],
          visibleConditions: [
            [
              { key: 'canUpdateDevice', value: true },
              { key: 'canReadDevice', value: true }
            ]
          ],
          enableConditions: []
        }
      },
      { order: 1000 }
    );
  }
}
