import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxInitDirective } from './ngx-init.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [NgxInitDirective],
  exports: [NgxInitDirective]
})
export class NgxInitModule {}
