<mat-card-content *ngIf="event" class="p-20 mve-detail-popup-container full-height" fxLayout="column" fxLayoutAlign="" fxLayoutGap="10px">
  <section fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="25" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="6px">
      <div class="mve-detail-popup-title" id="title-event">
        <span class="capitalize-first-letter">{{ 'EVENTS.DETAIL_POPUP.EVENT_ID' | translate }}:&nbsp;</span>
        <span cdkFocusInitial class="fw-600">{{ event.id | infoDisplay }}</span>
      </div>
      <div class="event-detail-popup-status">
        <span class="capitalize-first-letter">{{ 'IOT_DICTIONARY.STATUS' | translate }}:</span>
        <span [class]="'fw-600 capitalize-first-letter event-detail-popup-status-' + event?.status" class="fw-600">{{
          'EVENTS.STATUS.' + event?.status | translate
        }}</span>
      </div>
    </div>
    <div fxFlex="75" fxLayout="row" fxLayoutGap="6px" style="height: fit-content">
      <div *ngIf="event" class="with-border" fxFlex="65%" fxLayout="column">
        <iot-platform-ui-detail-popup-display-property
          property="{{ 'EVENTS.DETAIL_POPUP.LABEL' | translate }}:"
          value="{{ event?.label | infoDisplay }}"
        ></iot-platform-ui-detail-popup-display-property>
        <iot-platform-ui-detail-popup-display-property
          property="{{ 'IOT_DICTIONARY.TYPE' | translate }}:"
          value="{{ 'EVENTS.EVENT_TYPES.' + event?.type | translate }}"
        ></iot-platform-ui-detail-popup-display-property>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
          <span class="capitalize-first-letter">{{ 'IOT_DICTIONARY.SEVERITY' | translate }}:</span>
          <iot-platform-ui-event-severity-display [severity]="event?.severity"></iot-platform-ui-event-severity-display>
        </div>
        <iot-platform-ui-detail-popup-display-property
          property="{{ 'IOT_DICTIONARY.NOTE' | translate }}:"
          value="{{ event?.eventNote | infoDisplay }}"
        ></iot-platform-ui-detail-popup-display-property>
      </div>
      <div class="with-border" fxFlex="35%" fxLayout="column">
        <iot-platform-ui-detail-popup-display-property
          property="{{ 'EVENTS.DETAIL_POPUP.OCCURRENCE_DATE' | translate }}:"
          value="{{ event?.occurrenceTime | dateFormat | infoDisplay }}"
        ></iot-platform-ui-detail-popup-display-property>
        <iot-platform-ui-detail-popup-display-property
          property="{{ 'EVENTS.DETAIL_POPUP.RECEPTION_DATE' | translate }}:"
          value="{{ event?.receptionTime | dateFormat | infoDisplay }}"
        ></iot-platform-ui-detail-popup-display-property>
        <iot-platform-ui-detail-popup-display-property
          property="{{ 'EVENTS.DETAIL_POPUP.ACKNOWLEDGE_DATE' | translate }}:"
          value="{{ event?.acknowledge?.datetime | dateFormat | infoDisplay }}"
        ></iot-platform-ui-detail-popup-display-property>
        <iot-platform-ui-detail-popup-display-property
          property="{{ 'EVENTS.DETAIL_POPUP.CLOSE_DATE' | translate }}:"
          value="{{ event?.close?.datetime | dateFormat | infoDisplay }}"
        ></iot-platform-ui-detail-popup-display-property>
      </div>
    </div>
  </section>

  <section>
    <mat-tab-group color="accent" mat-align-tabs="center">
      <mat-tab label="{{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.INFORMATION' | translate | uppercase }}">
        <ng-template matTabContent>
          <div class="event-detail-popup-tab-container" fxLayout="column" fxLayoutGap="20px">
            <section fxLayout="column" fxLayoutGap="10px">
              <iot-platform-ui-detail-popup-section-header
                headerTitle="{{ (eventTags?.length <= 1 ? 'IOT_DICTIONARY.TAG' : 'IOT_DICTIONARY.TAGS') | translate }} ({{ eventTags?.length }})"
              ></iot-platform-ui-detail-popup-section-header>

              <div class="" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <iot-platform-ui-card-loader *ngIf="!isTagsLoaded" [backgroundClass]="'tag-loader mv-detail'"></iot-platform-ui-card-loader>
                <mat-chip-list *ngIf="isTagsLoaded" class="event-detail-popup-tag-list">
                  <iot-platform-ui-chip
                    *ngFor="let tag of eventTags"
                    [chipButton]="{ display: false }"
                    [chip]="tag"
                    [isTag]="true"
                    [ngClass]="{ invisible: !isTagsLoaded, visible: isTagsLoaded }"
                  ></iot-platform-ui-chip>
                  <span *ngIf="eventTags.length === 0">{{ 'SITES.TABLE_CONTENT.EXPANDED_PANEL.NO_TAG' | translate }}</span>
                </mat-chip-list>
              </div>
            </section>
            <div fxLayout="row" fxLayoutGap="10px">
              <section fxFlex="33%" fxLayout="column" fxLayoutGap="10px">
                <iot-platform-ui-detail-popup-section-header
                  headerTitle="{{ 'IOT_DICTIONARY.site' | translate }}"
                ></iot-platform-ui-detail-popup-section-header>
                <iot-platform-ui-card-loader *ngIf="!isSiteLoaded" [backgroundClass]="'mv-detail-card h-140'"></iot-platform-ui-card-loader>
                <iot4bos-ui-detail-site-card *ngIf="isSiteLoaded" [site]="eventSite" (selectSite)="navigateToSite.emit($event)"></iot4bos-ui-detail-site-card>
              </section>

              <section fxFlex="33%" fxLayout="column" fxLayoutGap="10px">
                <iot-platform-ui-detail-popup-section-header
                  headerTitle="{{ 'IOT_DICTIONARY.asset' | translate }}"
                ></iot-platform-ui-detail-popup-section-header>
                <iot-platform-ui-card-loader *ngIf="!isAssetLoaded" [backgroundClass]="'mv-detail-card h-140'"></iot-platform-ui-card-loader>
                <iot-platform-ui-detail-asset-variable-card
                  *ngIf="isAssetLoaded"
                  [assetVariable]="eventAssetVariable"
                  [asset]="eventAsset"
                  [event]="event"
                  [ngClass]="{ invisible: !isAssetLoaded, visible: isAssetLoaded }"
                ></iot-platform-ui-detail-asset-variable-card>
              </section>

              <section fxFlex="33%" fxLayout="column" fxLayoutGap="10px">
                <iot-platform-ui-detail-popup-section-header
                  headerTitle="{{ 'IOT_DICTIONARY.device' | translate }}"
                ></iot-platform-ui-detail-popup-section-header>
                <iot-platform-ui-card-loader *ngIf="!isDeviceLoaded" [backgroundClass]="'mv-detail-card h-140'"></iot-platform-ui-card-loader>
                <iot-platform-ui-detail-device-variable-card
                  *ngIf="isDeviceLoaded"
                  [deviceVariable]="eventDeviceVariable"
                  [device]="eventDevice"
                  [event]="event"
                  [ngClass]="{ invisible: !isDeviceLoaded, visible: isDeviceLoaded }"
                ></iot-platform-ui-detail-device-variable-card>
              </section>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.TIMELINE' | translate | uppercase }}">
        <ng-template matTabContent>
          <div class="event-detail-popup-tab-container">
            <iot-platform-ui-card-loader *ngIf="!isLogsLoaded" [backgroundClass]="'mv-detail-no-bg h-240'"></iot-platform-ui-card-loader>
            <iot4bos-ui-event-timeline
              (updateStatus)="updateStatus.emit($event)"
              *ngIf="isLogsLoaded"
              [canUpdateEvent]="data.canUpdateEvent"
              [event]="event"
              [logs]="eventLogs"
              [ngClass]="{ invisible: !isLogsLoaded, visible: isLogsLoaded }"
            ></iot4bos-ui-event-timeline>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template *ngIf="event.totalComments === 0" matTabLabel>{{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.COMMENTS' | translate | uppercase }}</ng-template>
        <ng-template *ngIf="event.totalComments !== 0" matTabLabel>
          {{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.COMMENTS' | translate | uppercase }} ({{ event.totalComments }})
        </ng-template>
        <ng-template matTabContent>
          <div class="event-detail-popup-tab-container">
            <iot4bos-ui-event-comments
              (addComment)="addComment.emit($event)"
              [canUpdateEvent]="data.canUpdateEvent"
              [displayMode]="'detail-popup'"
              [logsByEvent]="eventLogs"
            >
            </iot4bos-ui-event-comments>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template matTabLabel>
          <span *ngIf="!eventRelatedTopicsLoaded || eventRelatedTopics?.length < 2">
            {{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.TOPIC' | translate | uppercase }} <span>({{ eventRelatedTopics?.length | infoDisplay }})</span>
          </span>
          <span *ngIf="eventRelatedTopicsLoaded && eventRelatedTopics?.length >= 2">
            {{ 'EVENTS.DETAIL_POPUP.TABS_LABEL.TOPICS' | translate | uppercase }} ({{ eventRelatedTopics?.length | infoDisplay }})
          </span>
        </ng-template>
        <ng-template matTabContent>
          <div class="event-detail-popup-tab-container">
            <iot-platform-ui-card-loader *ngIf="!eventRelatedTopicsLoaded" [backgroundClass]="'mv-detail-no-bg h-240'"></iot-platform-ui-card-loader>

            <iot4bos-ui-event-topic-list *ngIf="eventRelatedTopicsLoaded" [topics]="eventRelatedTopics"></iot4bos-ui-event-topic-list>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </section>
</mat-card-content>
