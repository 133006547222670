import { Product } from '@iot-platform/models/oyan';

export class ProductBuilder {
  public static DEFAULT_PRODUCT = {
    id: '3844f908-80cb-49b2-b877-bdc3632f5b7a',
    identifier: '57a821e9-4835-4a26-a64a-16263efd5924',
    name: 'name',
    displayName: 'name',
    cylinderSize: 1,
    type: 'type'
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static build = (id: string, name = '', params: Partial<Product> = ProductBuilder.DEFAULT_PRODUCT): Product | any => ({
    ...params,
    id,
    name
  });
}
