import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreferencesModule } from './features/preferences/preferences.module';
import { UserProfileFormComponent } from './features/profile/components/user-profile-form/user-profile-form.component';
import { ProfileModule } from './features/profile/profile.module';

@NgModule({
    imports: [CommonModule, ProfileModule, PreferencesModule],
    exports: [ProfileModule, PreferencesModule]
})
export class UsersModule {}
