<div *ngIf="currentUser" fxLayout="column" class="user-profile-info-container" fxLayoutGap="30px">
  <div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="user-profile-info-section-title">
      <h2 class="">{{ 'USERS.PROFILE_INFO.PERSONAL_INFO' | translate }}</h2>
      <hr class="regular-1px-line" fxFlex/>
      <button
        mat-icon-button
        [matTooltip]="'USERS.PROFILE_INFO.EDIT_TOOLTIP' | translate"
        matTooltipClass="regular-tooltip"
        matTooltipPosition="above"
        class="regular-round-button"
        (click)="editUserProfile.emit()"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <div class="user-profile-info-name">{{ currentUser.firstname }} {{ currentUser.lastname }}</div>

    <div fxLayout="column" fxLayoutGap="6px">
      <iot-platform-ui-detail-popup-display-property
        [property]="'USERS.PROFILE_INFO_FORM.PRIMARY_EMAIL' | translate"
        [value]="currentUser.email | infoDisplay"
      >
      </iot-platform-ui-detail-popup-display-property>
      <iot-platform-ui-detail-popup-display-property
        [property]="'USERS.PROFILE_INFO_FORM.SECONDARY_EMAIL' | translate"
        [value]="currentUser.secondaryEmail | infoDisplay"
      >
      </iot-platform-ui-detail-popup-display-property>

      <iot-platform-ui-detail-popup-display-property
        [property]="'USERS.PROFILE_INFO_FORM.PHONE_NUMBER' | translate"
        [value]="currentUser.phoneNumber | infoDisplay"
      >
      </iot-platform-ui-detail-popup-display-property>
    </div>

  </div>
</div>
