import { I4BCellType, I4BColumnConfiguration } from '../configurations/i4b-column-configuration';
import { I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { TotalColumn } from './i4b-total.column';

export class I4bTotalAssetsColumn extends TotalColumn {
  columnId = '3a655890-5c1e-11ec-80a5-0023a40210d0-total-asset';
  className = I4bTotalAssetsColumn;
  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    super(
      { displayName: 'TOTAL ASSETS', icon: 'asset', ...customHeader },
      {
        ...customConfig,
        id: customConfig.id ? customConfig.id : 'totalAssets',
        cell: { type: I4BCellType.NUMBER },
        isDefault: true,
        width: customOptions?.width ? customOptions.width : 60
      },
      { customId: customConfig.id ? customConfig.id : 'totalAssets', ...customOptions }
    );
  }
}
