import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BuildInfo } from '@iot-platform/models/common';
import { EMPTY } from 'rxjs';
import { LocalStorageKeys, LocalStorageService } from '../local-storage';
import { API_INTERCEPTOR } from './api-interceptor.module';
import { ApiInterceptorOptions } from './api.interceptor-options';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(@Inject(API_INTERCEPTOR) private readonly options: ApiInterceptorOptions, private localStorage: LocalStorageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (this.options.excludedApis.some((url) => request.urlWithParams.toLocaleLowerCase().includes(url))) {
      return EMPTY;
    }

    if (this.options.includeAppVersionInHeader) {
      const buildInfo: BuildInfo = JSON.parse(this.localStorage.get(LocalStorageKeys.STORAGE_APP_BUILD_INFO));
      if (buildInfo && buildInfo.versionHeaderName) {
        const req = request.clone({
          setHeaders: {
            [buildInfo.versionHeaderName]: buildInfo.version ?? 'N/A'
          }
        });
        return next.handle(req);
      }
      return next.handle(request);
    } else {
      return next.handle(request);
    }
  }
}
