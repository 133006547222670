import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomEncoder } from '@iot-platform/core';
import {
  CommonApiRequest,
  CommonApiResponse,
  CommonGenericModel,
  CommonIndexedPagination,
  CommonPagination,
  Environment,
  TagCategory
} from '@iot-platform/models/common';
import {
  ColumnFactory,
  getAllColumnsByConcept,
  HeaderType,
  I4BAssetButtonColumn,
  I4BAssetEventsButtonColumn,
  I4BBasicColumn,
  I4BCellType,
  I4BColumn,
  I4BColumnConfiguration,
  I4BColumnHeader,
  I4BColumnOptions,
  I4BDeviceButtonColumn,
  I4BDeviceEventsButtonColumn,
  I4BGrid,
  I4BGridData,
  I4BGridOptions,
  I4BSelectionColumn,
  I4BSitesButtonColumn,
  I4BStockSiteDevicesButtonColumn
} from '@iot-platform/models/grid-engine';
import { CommandType } from '@iot-platform/models/i4b';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  I4BConnectorsButtonColumn
} from '../../../../../iot-platform-models/src/lib/grid-engine/columns/definitions/i4b-connectors-button.column';

@Injectable({
  providedIn: 'root'
})
export class GridsService {
  constructor(@Inject('environment') private readonly environment: Environment, private readonly http: HttpClient) {}

  loadAllGrids(): Observable<CommonApiResponse<I4BGrid<I4BGridOptions, I4BGridData>, CommonIndexedPagination>> {
    return this.http.get<any>(`${this.environment.api.url}/grids`).pipe(
      map((result) => {
        const response: CommonApiResponse<I4BGrid<I4BGridOptions, I4BGridData>, CommonIndexedPagination> = {
          data: result.content.map((grid: I4BGrid<I4BGridOptions, I4BGridData>) => {
            return {
              ...grid,
              columns: this.getColumnsDefinition(grid),
              gridOptions: this.getGridOptions(grid)
            };
          }),
          pagination: {
            limit: result.page.limit,
            currentPage: result.page.curPage,
            hasMore: result.page.hasMore,
            maxPage: result.page.maxPage,
            total: result.page.total
          }
        };
        return response;
      })
    );
  }

  loadGridDetails(concept: string, gridId: string): Observable<any> {
    return this.http.get<any>(`${this.environment.api.url}/grids/${concept}/${gridId}`).pipe(
      map((grid: I4BGrid<I4BGridOptions, I4BGridData>) => {
        const returnedGrid = { ...grid, columns: this.getColumnsDefinition(grid) };
        return returnedGrid;
      })
    );
  }

  loadGridData(request: CommonApiRequest): Observable<CommonApiResponse<CommonGenericModel, CommonPagination>> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    if (request.concept && (request.concept === 'assets' || request.concept === 'devices') && request.variables && request.variables.length > 0) {
      request.variables.forEach((variable) => {
        params = params.append('expandVariableName', variable.toLowerCase());
      });
    }

    if (request.tags && request.tags.length > 0) {
      request.tags.forEach((tagCategory) => {
        params = params.append('expandTagCategory', tagCategory);
      });
    }
    return this.http
      .get<CommonApiResponse<CommonGenericModel, CommonPagination>>(
        `${this.environment.api.url}${request.endPoint ? request.endPoint : '/' + request.concept}`,
        {
          params
        }
      )
      .pipe(
        map((backResponse: any) => {
          const response: CommonApiResponse<CommonGenericModel, CommonIndexedPagination> = {
            data: this.toData(request.concept, backResponse.content),
            pagination: {
              limit: backResponse.page.limit,
              currentPage: backResponse.page.curPage,
              hasMore: backResponse.page.hasMore,
              maxPage: backResponse.page.maxPage,
              total: backResponse.page.total
            }
          };
          return response;
        })
      );
  }

  loadGridsByConcept(concept: string): Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> {
    return this.http.get<{ page: any; content: I4BGrid<I4BGridOptions, I4BGridData>[] }>(`${this.environment.api.url}/grids/${concept}`).pipe(
      map((response) =>
        response.content.reduce((acc, value) => {
          const updateGrid = { ...value, columns: this.getColumnsDefinition(value), gridOptions: this.getGridOptions(value) };
          acc.push(updateGrid);
          return acc;
        }, [])
      )
    );
  }

  loadGridsByConceptAndBusinessProfile(concept: string, businessProfileId?: string): Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> {
    let params: HttpParams = new HttpParams();
    if (businessProfileId) {
      params = params.set('businessProfileId', businessProfileId);
    }
    return this.http.get<{ page: any; content: I4BGrid<I4BGridOptions, I4BGridData>[] }>(`${this.environment.api.url}/grids/${concept}`, { params }).pipe(
      map((response) =>
        response.content.reduce((acc, value) => {
          const updateGrid = { ...value, columns: this.getColumnsDefinition(value), gridOptions: this.getGridOptions(value) };
          acc.push(updateGrid);
          return acc;
        }, [])
      )
    );
  }

  saveGrid(newGrid: Partial<I4BGrid<I4BGridOptions, I4BGridData>>) {
    return this.http
      .post<any>(`${this.environment.api.url}/grids/${newGrid.masterview}`, {
        ...newGrid,
        data: null
      })
      .pipe(
        map((grid: I4BGrid<I4BGridOptions, I4BGridData>) => {
          return { ...grid, columns: this.getColumnsDefinition(grid), gridOptions: this.getGridOptions(grid) };
        })
      );
  }

  updateGrid(updatedGrid: Partial<I4BGrid<I4BGridOptions, I4BGridData>>) {
    const isAppDefault = updatedGrid.isDefault && !updatedGrid.userId;
    if (isAppDefault) {
      return this.http
        .put<any>(`${this.environment.api.url}/grids/${updatedGrid.masterview}/default/app`, {
          ...updatedGrid,
          data: null
        })
        .pipe(
          map((grid: I4BGrid<I4BGridOptions, I4BGridData>) => {
            return { ...grid, columns: this.getColumnsDefinition(grid), gridOptions: this.getGridOptions(grid) };
          })
        );
    } else {
      return this.http
        .put<any>(`${this.environment.api.url}/grids/${updatedGrid.masterview}/${updatedGrid.id}`, {
          ...updatedGrid,
          data: null
        })
        .pipe(
          map((grid: I4BGrid<I4BGridOptions, I4BGridData>) => {
            return { ...grid, columns: this.getColumnsDefinition(grid), gridOptions: this.getGridOptions(grid) };
          })
        );
    }
  }

  updateSilentGrid(updatedGrid: Partial<I4BGrid<I4BGridOptions, I4BGridData>>) {
    const isUserGrid = !updatedGrid.isAppDefault;
    if (isUserGrid) {
      return this.http
        .put<any>(`${this.environment.api.url}/grids/${updatedGrid.masterview}/${updatedGrid.id}`, {
          ...updatedGrid,
          data: null
        })
        .pipe(
          map((grid: I4BGrid<I4BGridOptions, I4BGridData>) => {
            return { ...grid, columns: this.getColumnsDefinition(grid), gridOptions: this.getGridOptions(grid) };
          })
        );
    }
  }

  deleteGrid(toDeleteGrid: Partial<I4BGrid<I4BGridOptions, I4BGridData>>) {
    return this.http.delete<any>(`${this.environment.api.url}/grids/${toDeleteGrid.masterview.toLowerCase()}/${toDeleteGrid.id}`);
  }

  loadTagsByConceptsAndEntity(concepts: string[], entityId: string): Observable<TagCategory[]> {
    let params: HttpParams = new HttpParams();
    concepts.forEach((concept) => {
      params = params.append('concept', concept);
    });
    params = params.set('entityId', entityId);
    params = params.set('withParents', true);
    params = params.set('withChildren', true);
    return this.http.get<TagCategory[]>(`${this.environment.api.url}${this.environment.api.endpoints.tags}`, { params }).pipe(map((tags: any) => tags.content));
  }

  getDefaultGridByConcept(concept: string) {
    return this.http.get<any>(`${this.environment.api.url}/grids/${concept}/default`).pipe(
      map((grid: I4BGrid<I4BGridOptions, I4BGridData>) => {
        return {
          ...grid,
          columns: this.getColumnsDefinition(grid),
          gridOptions: this.getGridOptions(grid)
        };
      })
    );
  }

  getAppDefaultGridByConcept(concept: string) {
    return this.http.get<any>(`${this.environment.api.url}/grids/${concept}/default/app`).pipe(
      map((grid: I4BGrid<I4BGridOptions, I4BGridData>) => {
        return { ...grid, columns: this.getColumnsDefinition(grid), gridOptions: this.getGridOptions(grid) };
      })
    );
  }

  private getGridOptions(grid: I4BGrid<I4BGridOptions, I4BGridData>): I4BGridOptions {
    // TODO : on doit largement pouvoir optimiser ça !
    switch (grid.masterview) {
      case 'sites':
        return {
          ...grid.gridOptions,
          buttonColumn: { enabled: true, className: new I4BSitesButtonColumn() },
          selectionColumn: { enabled: true, className: new I4BSelectionColumn() }
        };
      case 'assets':
        return {
          ...grid.gridOptions,
          buttonColumn: { enabled: true, className: new I4BAssetButtonColumn() },
          selectionColumn: { enabled: true, className: new I4BSelectionColumn() }
        };
      case 'devices':
        return {
          ...grid.gridOptions,
          buttonColumn: { enabled: true, className: new I4BDeviceButtonColumn() },
          selectionColumn: { enabled: true, className: new I4BSelectionColumn() }
        };
      case 'device-events':
      case 'po-event-generated-device-events':
        return {
          ...grid.gridOptions,
          buttonColumn: { enabled: true, className: new I4BDeviceEventsButtonColumn() },
          selectionColumn: { enabled: true, className: new I4BSelectionColumn() }
        };
      case 'asset-events':
      case 'active-asset-events-popup':
      case 'po-event-generated-asset-events':
        return {
          ...grid.gridOptions,
          buttonColumn: { enabled: true, className: new I4BAssetEventsButtonColumn() },
          selectionColumn: { enabled: true, className: new I4BSelectionColumn() }
        };
      case 'connectors':
        return {
          ...grid.gridOptions,
          buttonColumn: { enabled: true, className: new I4BConnectorsButtonColumn() }
        };
      case 'stock-site-devices':
        return {
          ...grid.gridOptions,
          buttonColumn: { enabled: true, className: new I4BStockSiteDevicesButtonColumn() },
          selectionColumn: { enabled: true, className: new I4BSelectionColumn() }
        };
      default:
        return { ...grid.gridOptions };
    }
  }

  private getColumnsDefinition(grid: I4BGrid<I4BGridOptions, I4BGridData>): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] {
    return grid.columns.map((column: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>) => {
      return this.getConsolidatedColumn(column, grid);
    });
  }

  private getConsolidatedColumn(
    userColumn: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>,
    grid: I4BGrid<I4BGridOptions, I4BGridData>
  ): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions> {
    let columnConfig = ColumnFactory.getUserColumnClass(userColumn.columnId);
    let consolidatedColumn: I4BBasicColumn;
    if (columnConfig.options.allowUserOverride) {
      // AllowUserOveride : all the conf comes from the back. No consolidation here,
      consolidatedColumn = ColumnFactory.createColumn(columnConfig.class, userColumn.header, userColumn.configuration, userColumn.options);
    } else {
      if (columnConfig.options && columnConfig.options.allowSystemOverride) {
        // systemOverride : the conf come from the developer (in default-config-definition file. Consolidation needed with the customization of the user (like header name) and developer configuration
        // so, we need to look up in the columns catalog to retrieve default conf and apply customization.

        const defaultViewDefCol = getAllColumnsByConcept(grid.masterview.toLowerCase()).filter((viewDefColumn) => {
          return userColumn.options.customId
            ? viewDefColumn.columnId === userColumn.columnId && viewDefColumn.configuration?.id === userColumn.options.customId
            : userColumn.configuration?.id
            ? viewDefColumn.columnId === userColumn.columnId && viewDefColumn.configuration?.id === userColumn.configuration?.id
            : false;
        })[0];

        if (
          userColumn.columnId === '3f58dd72-5f3e-11ec-9de0-acde48001122-asset-followed-variable-group' ||
          (userColumn.columnId === '3f58dd72-5f3e-11ec-9de0-acde48001122-asset-followed-variable-with-comment-group' && defaultViewDefCol)
        ) {
          consolidatedColumn = ColumnFactory.createColumn(
            defaultViewDefCol.className,
            defaultViewDefCol.header,
            defaultViewDefCol.configuration,
            userColumn.options
          );
        } else if (defaultViewDefCol) {
          consolidatedColumn = ColumnFactory.createColumn(defaultViewDefCol.className, defaultViewDefCol.header, defaultViewDefCol.configuration, {
            ...userColumn.options
          });
        } else {
          consolidatedColumn = ColumnFactory.createColumn(columnConfig.class, {}, {}, userColumn.options);
        }
      } else {
        consolidatedColumn = ColumnFactory.createColumn(columnConfig.class, {}, {}, userColumn.options);
      }
    }

    if ((consolidatedColumn.header.type as string) === 'basic') {
      consolidatedColumn.header.type = HeaderType.BASIC;
    }

    switch (consolidatedColumn.configuration.cell.type as string) {
      case 'basic':
        consolidatedColumn.configuration.cell.type = I4BCellType.BASIC;
        break;
      case 'number':
        consolidatedColumn.configuration.cell.type = I4BCellType.NUMBER;
        break;
      case 'date':
        consolidatedColumn.configuration.cell.type = I4BCellType.DATE;
        break;
    }
    return consolidatedColumn;
  }

  private toData(concept: string, content) {
    switch (concept) {
      case 'assets':
        return content.map((c) => {
          return { ...c, isRefreshCommandEnabled: !!c.commands?.includes(CommandType.REFRESH) };
        });
      case 'devices':
        return content.map((c) => {
          return {
            ...c,
            isRefreshCommandEnabled: !!(
              c.outgoingConnector?.requestConfiguration?.commands?.includes('refresh') &&
              (c.outgoingConnector?.requestConfiguration?.authentication !== 'login' ||
                (c.outgoingConnector?.requestConfiguration?.authentication === 'login' && c.credential?.login && c.credential?.password))
            ),
            isConfigureCommandEnabled: !!c.incomingConnector?.configuration?.url
          };
        });
      default:
        return content;
    }
  }
}
