import { BusinessProfile, Organization } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const loadBusinessProfilesSuccess = createAction(
  '[Business-Profiles/Auth API] Load Business Profiles Success',
  props<{ businessProfiles: BusinessProfile[] }>()
);
export const loadBusinessProfilesFailure = createAction('[Business-Profiles/Auth API] Load Business Profiles Failure', props<{ error: any }>()); //

export const selectBusinessProfileSuccess = createAction(
  '[Business-Profiles/Auth API] Select Business Profile Success',
  props<{ selectedBusinessProfile: BusinessProfile; withRedirect: boolean }>()
);
export const selectBusinessProfileFailure = createAction('[Business-Profiles/Auth API] Select Business Profile Failure', props<{ error: any }>()); //

export const loadSelectedEntityForSession = createAction('[Business-Profiles/Auth API] LoadSelected Entity For Session', props<{ selectedEntityId: string }>());

export const loadSelectedEntityForSessionSuccess = createAction(
  '[Business-Profiles/Auth API] LoadSelected Entity For Session Success',
  props<{ selectedEntity: Organization }>()
);

export const loadSelectedEntityForSessionFailure = createAction(
  '[Business-Profiles/Auth API] LoadSelected Entity For Session Failure',
  props<{ error: any }>()
);
