<mat-progress-bar mode="indeterminate" class="login-select-profile-loader" *ngIf="loading || filterProfilesLoading" color="accent"></mat-progress-bar>
<div class="bp-found" *ngIf="(bpByEntities$ | async)?.length > 0">
  <h2 class="login-select-profile-title">{{ 'LOGIN.TITLE.SELECT_PROFILE' | translate }}</h2>
  <ul class="login-select-profile-entities">
    <li class="login-select-profile-entity" *ngFor="let bpByEntity of bpByEntities$ | async">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span class="login-select-profile-entity-name">{{ bpByEntity.entityName }}</span>
        <hr class="login-select-profile-line" />
      </div>
      <ul class="login-select-profile-business-profiles">
        <li class="login-select-profile-business-profile" *ngFor="let businessProfile of bpByEntity.businessProfiles">
          <button
            data-cy="login-select-profile-business-profile-btn"
            class="login-select-profile-button"
            mat-button
            (click)="onSelectProfile(businessProfile)"
            [disabled]="businessProfile.notCoveredByUserEntityAt"
          >
            <span class="login-select-profile--bp-name">{{ businessProfile.name }}</span>
            <mat-icon
              class="login-select-profile--info-icon"
              *ngIf="businessProfile.notCoveredByUserEntityAt"
              [matTooltip]="'HEADER.BUSINESS_PROFILE_OUT_OF_SCOPE' | translate"
              matTooltipClass="regular-tooltip"
              >info_outlined</mat-icon
            >
          </button>
        </li>
      </ul>
    </li>
  </ul>
</div>

<div *ngIf="!loading && !(businessProfiles$ | async)?.length" class="login-select-profile-no-bp-message">
  <br />
  <mat-icon class="icon-flag" [svgIcon]="'flag'"> </mat-icon>
  <p class="title">One more step to use IoT-4BOS</p>
  <br />
  <p class="fw-600">account created <mat-icon class="icon-check"> done </mat-icon></p>
  <p class="fw-600">Business profile assigned <span style="color: #bf5f68">pending...</span></p>
  <br /><br />
  <p>Business profile is assigned by your IoT-4BOS key user</p>
  <br /><br />
  <p>You will be informed by email once done.</p>
  <p>Feel free to contact your key user if necessary</p>
</div>
