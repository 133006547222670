import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NumberFormatPipeModule, TruncateModule } from '@iot-platform/pipes';
import { FocusInitialDirective } from '@iot-platform/shared';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbItemModule } from '../breadcrumb-item/breadcrumb.item.module';
import { SubscriptionButtonModule } from '../subscription-button/subscription-button.module';
import { ToolbarButtonModule } from './toolbar-button/toolbar-button.module';
import { ToolbarV2Component } from './toolbar-v2.component';

@NgModule({
  declarations: [ToolbarV2Component],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    MatButtonModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatIconModule,
    ReactiveFormsModule,
    NumberFormatPipeModule,
    BreadcrumbItemModule,
    SubscriptionButtonModule,
    ToolbarButtonModule,
    MatButtonToggleModule,
    FormsModule,
    FocusInitialDirective,
    TruncateModule
  ],
  exports: [ToolbarV2Component]
})
export class ToolbarV2Module {}
