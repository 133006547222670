<div class="iccid-message-content">
  <div *ngIf="loading" fxFlex fxLayoutAlign="center center">
    <iot-platform-ui-card-loader cdkFocusInitial fxFlex></iot-platform-ui-card-loader>
  </div>

  <pre *ngIf="!loading">{{ message | json }}</pre>

  <button
    (click)="copyMessageToClipboard()"
    class="reverse-accent-button copy-button"
    mat-icon-button
    matTooltipClass="regular-tooltip"
  >
    <mat-icon>content_copy</mat-icon>
  </button>
</div>
