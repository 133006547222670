import { Action, createReducer, on } from '@ngrx/store';

import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';

import { AssetEventsByAssetLogsDbActions, AssetEventsByAssetLogsUiActions } from '../../actions';

export const assetEventsByAssetLogsUiFeatureKey = 'assetEventsByAssetLogsUi';

export interface State {
  error?: any;
  loaded?: boolean;
}

export const initialState: State = {
  error: null,
  loaded: false
};

const assetEventsByAssetLogsUiReducer = createReducer(
  initialState,
  on(AssetEventsByAssetLogsUiActions.loadLogsByAssetEventId, (state: State) => ({ ...state, error: null, loaded: false })),
  on(AssetEventsByAssetLogsDbActions.loadLogsByAssetEventIdSuccess, (state: State) => ({ ...state, loaded: true })),
  on(AssetEventsByAssetLogsDbActions.loadLogsByAssetEventIdFailure, (state: State, { error }) => ({ ...state, loaded: false, error })),
  // ****
  on(AssetEventsByAssetLogsUiActions.createLogByAssetEventId, (state: State) => ({ ...state, error: null })),
  on(AssetEventsByAssetLogsDbActions.createLogByAssetEventIdSuccess, (state: State) => ({ ...state, loaded: true })),
  on(AssetEventsByAssetLogsDbActions.createLogByAssetEventIdFailure, (state: State, { error }) => ({ ...state, loaded: false, error })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsByAssetLogsUiReducer(state, action);
}

export const getError = (state: State) => state.error;
export const getLogsLoaded = (state: State) => state.loaded;
