<div class="panel-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
  <h2 class="panel-title">{{ title }}</h2>
  <hr fxFlex class="regular-1px-line" />
  <div *ngIf="hasButton" class="panel-button app-round-button" [matTooltip]="buttonTooltip | translate" matTooltipClass="regular-tooltip">
    <button mat-icon-button (click)="onClick()"><mat-icon>{{ icon }}</mat-icon></button>
  </div>
  <ng-content *ngIf="withCustomAction" select="[panelCustomAction]"></ng-content>
</div>
<div class="panel-toolbar" *ngIf="hasToolbar">
  <ng-content select="[panelToolbar]"></ng-content>
</div>
<div class="panel-grid" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="15px" fxLayoutGap.lt-md="0">
  <ng-content select="[panelGrid]"></ng-content>
</div>
<div class="panel-content">
  <ng-content select="[panelContent]"></ng-content>
</div>
