import { I4BCellType } from '../configurations';
import { I4bCellConcept } from '../enums';
import { HeaderType } from '../models';
import { I4BButtonColumn } from './i4b-button.column';

export class I4BAssetButtonColumn extends I4BButtonColumn {
  constructor() {
    super(
      {
        type: HeaderType.CALL_TO_ACTION,
        displayName: 'BUTTON',
        icon: ''
      },
      {
        concept: I4bCellConcept.ASSETS,
        isConfigurable: false,
        isDefault: true,
        isLink: false,
        position: '',
        sortProperty: '',
        width: 10,
        id: 'button',
        cell: {
          type: I4BCellType.CALL_TO_ACTION,
          singleActions: [
            {
              order: 20,
              key: 'refresh',
              label: 'ASSETS.CARD.REFRESH',
              enableConditions: [[{ key: 'isRefreshCommandEnabled', value: true }]],
              visibleConditions: [[{ key: 'canReadAsset', value: true }]]
            }
          ],
          bulkActions: [
            {
              order: 10,
              key: 'bulkAddTag',
              label: 'GRID_ENGINE.COLUMNS.CTA.ADD_TAGS',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateAsset', value: true }]]
            },
            {
              order: 20,
              key: 'bulkRemoveTag',
              label: 'GRID_ENGINE.COLUMNS.CTA.REMOVE_TAGS',
              enableConditions: [],
              visibleConditions: [[{ key: 'canUpdateAsset', value: true }]]
            },
            {
              order: 30,
              key: 'bulkRefresh',
              label: 'ASSETS.CARD.REFRESH',
              enableConditions: [],
              visibleConditions: [[{ key: 'canReadAsset', value: true }]]
            }
          ],
          visibleConditions: [[{ key: 'canReadAsset', value: true }]],
          enableConditions: []
        }
      },
      { order: 1000 }
    );
  }
}
