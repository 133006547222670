import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { AssetEvent, DeviceEvent, Log } from '@iot-platform/models/i4b';

import * as moment from 'moment';

@Component({
  selector: 'iot4bos-ui-event-timeline',
  templateUrl: './event-timeline.component.html',
  styleUrls: ['./event-timeline.component.scss']
})
export class EventTimelineComponent implements OnInit, OnChanges {
  time?: {
    occurrenceTime: Date;
    receptionTime: { days: number; hours: number; minutes: number; seconds: number };
    snooze?: { days: number; hours: number; minutes: number; seconds: number };
    acknowledge?: { days: number; hours: number; minutes: number; seconds: number };
    close?: { days: number; hours: number; minutes: number; seconds: number };
  };

  status = '';
  snoozedLogs: Log[] = [];
  acknowledgedLogs: Log[] = [];
  closedLogs: Log[] = [];

  snoozeButtonDisableStatus = true;
  acknowledgeButtonDisableStatus = true;
  closeButtonDisableStatus = true;

  @Input() logs: Log[] = [];
  @Input() event: AssetEvent | DeviceEvent = {} as AssetEvent;
  @Input() canUpdateEvent = false;

  @Output() updateStatus: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('event') && changes['event'].currentValue) {
      this.status = changes['event'].currentValue.status;
      this.time = this.getTime();
      this.setStatusChangeButtonsDisableStatus(changes['event'].currentValue.status);
    }

    if (changes.hasOwnProperty('logs') && changes['logs'].currentValue) {
      this.snoozedLogs = changes['logs'].currentValue.filter((log: Log) => log.currentStatus === 'snoozed' && log.isStatusChange);
      this.acknowledgedLogs = changes['logs'].currentValue.filter((log: Log) => log.currentStatus === 'acknowledged' && log.isStatusChange);
      this.closedLogs = changes['logs'].currentValue.filter((log: Log) => log.currentStatus === 'closed' && log.isStatusChange);
    }
  }

  getTimelineState() {
    return {
      active: this.status === 'active',
      snoozed: this.status === 'snoozed',
      acknowledged: this.status === 'acknowledged' && !!this.event?.snooze,
      acknowledgedWithoutSnoozed: this.status === 'acknowledged' && !this.event?.snooze,
      closed: this.status === 'closed' && !!this.event.snooze && !!this.event?.acknowledge,
      closedWithoutSnoozed: this.status === 'closed' && !this.event?.snooze && !!this.event?.acknowledge,
      closedWithoutAcknowledged: this.status === 'closed' && !!this.event?.snooze && !this.event?.acknowledge,
      closedWithoutSnoozedAndAcknowledged: this.status === 'closed' && !this.event?.snooze && !this.event?.acknowledge
    };
  }

  getSnoozeState(index: number): any {
    return {
      current: this.status === 'snoozed' && index === 0,
      disabled: this.status === 'active'
    };
  }

  setStatusChangeButtonsDisableStatus(status: string): void {
    this.snoozeButtonDisableStatus = status !== 'active' || !this.event?.snoozeQuota || !this.canUpdateEvent;
    this.acknowledgeButtonDisableStatus = status !== 'active' || !this.canUpdateEvent;
    this.closeButtonDisableStatus = status !== 'acknowledged' || !this.canUpdateEvent;
  }

  private getTime(): any {
    const occurrenceTime = new Date(this.event?.occurrenceTime as string);
    const receptionTime = this.computeElapsedTime(occurrenceTime, new Date(this.event?.receptionTime));
    const snooze = this.event.snooze ? this.computeElapsedTime(occurrenceTime, new Date(this.event?.snooze?.datetime)) : null;
    const acknowledge = this.event.acknowledge ? this.computeElapsedTime(occurrenceTime, new Date(this.event?.acknowledge?.datetime)) : null;
    const close = this.event.close ? this.computeElapsedTime(occurrenceTime, new Date(this.event?.close?.datetime)) : null;

    return {
      occurrenceTime,
      receptionTime,
      snooze,
      acknowledge,
      close
    };
  }

  private computeElapsedTime(start: Date, end: Date): { days: number; hours: number; minutes: number; seconds: number } {
    const endMoment = moment(end);
    const startMoment = moment(start);
    const diffMoment = moment.duration(endMoment.diff(startMoment));

    return {
      days: Math.floor(diffMoment.asDays()),
      hours: diffMoment.hours(),
      minutes: diffMoment.minutes(),
      seconds: diffMoment.seconds()
    };
  }
}
