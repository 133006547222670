import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { UserNamePipe } from '../../../../../iot-platform-pipes/src/lib/user-name/user-name.pipe';
import { DeviceDetailsModule } from '../../../../../iot-platform-ui/src/lib/ui/components/device-details/device-details.module';
import { DeviceInfoStatusModule } from '../../../../../iot-platform-ui/src/lib/ui/components/device-details/device-info-status/device-info-status.module';

import { FavoriteViewsModule } from '../../../../../shared/src/lib/favorite-views/favorite-views.module';

import { DeviceConnectorsEffects } from './+state/effects/device-connectors.effects';
import { DevicesEffects } from './+state/effects/devices.effects';
import * as fromDevices from './+state/reducers';
import { DeviceInfoToolbarModule } from './components/device-info-toolbar/device-info-toolbar.module';
import { DeviceInfoComponent } from './components/device-info/device-info.component';
import { DeviceConnectorsShellComponent } from './containers/device-connectors-shell/device-connectors-shell.component';
import { DeviceConnectorsShellModule } from './containers/device-connectors-shell/device-connectors-shell.module';
import { DeviceOverviewComponent } from './containers/device-overview/device-overview.component';
import { DeviceOverviewModule } from './containers/device-overview/device-overview.module';
import { DevicesShellComponent } from './containers/devices-shell/devices-shell.component';
import { DevicesShellModule } from './containers/devices-shell/devices-shell.module';
import { DeviceIccidComponent } from './components/device-iccid/device-iccid.component';
import { CardLoaderModule } from '@iot-platform/iot-platform-ui';
import { FlexModule } from '@angular/flex-layout';
import { JsonPipe, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

const devicesRoutes: Routes = [
  { path: '', component: DevicesShellComponent },
  { path: 'connectors', component: DeviceConnectorsShellComponent },
  { path: ':deviceId', component: DeviceOverviewComponent, children: [{ path: '', component: DeviceInfoComponent }] }
];

@NgModule({
  imports: [
    StoreModule.forFeature(fromDevices.devicesFeatureKey, fromDevices.reducers),
    EffectsModule.forFeature([DevicesEffects, DeviceConnectorsEffects]),
    RouterModule.forChild(devicesRoutes),
    DeviceOverviewModule,
    DevicesShellModule,
    DeviceConnectorsShellModule,
    FavoriteViewsModule,
    DeviceInfoToolbarModule,
    TranslateModule,
    DeviceInfoStatusModule,
    DeviceDetailsModule,
    CardLoaderModule,
    FlexModule,
    JsonPipe,
    MatButtonModule,
    MatIconModule,
    NgIf
  ],
  declarations: [DeviceIccidComponent],
  exports: [RouterModule],
  providers: [UserNamePipe]
})
export class DevicesModule {}
