import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { BusinessProfile } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';
import {
  HeaderComponent as I4bHeaderComponent
} from 'libs/iot-platform-ui/src/lib/layout/components/header/header.component';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'oyan-ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['../../../../../../iot-platform-ui/src/lib/layout/components/header/header.component.scss', './header.component.scss']
})
export class HeaderComponent extends I4bHeaderComponent implements OnChanges {
  @Input()
  appLogo: string;

  constructor(translateService: TranslateService, storage: LocalStorageService) {
    super(translateService, storage);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const bp: BusinessProfile = cloneDeep(this.selectedBusinessProfile);
    super.ngOnChanges(changes);
    if (bp) {
      this.storage.set(
        LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY,
        JSON.stringify({
          ...bp,
          timezone: moment(new Date()).format('Z'),
          timezoneDetails: { name: null, offset: null }
        })
      );
    }
  }
}
