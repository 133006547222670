import { Action, combineReducers } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';

import * as fromGateways from './gateways.reducer';

export const featureKey = 'oyanGateways';

export interface GatewaysState {
  [fromGateways.featureKey]: fromGateways.State;
}

export interface State {
  [featureKey]: GatewaysState;
}

export function reducers(state: GatewaysState, action: Action): ActionReducer<GatewaysState> | unknown {
  return combineReducers({
    [fromGateways.featureKey]: fromGateways.reducer
  })(state, action);
}
