<oyan-ui-simple-card class='card' [element]='element' [withMenu]="withMenu" [icon]='icon' [cssClasses]="cssClasses" [actions]='actions'
   (dispatchEvent)='onDispatchEvent($event)'>
  <div cardContent fxLayout='column'>
    <h3 class='card__title'> {{ element?.name | infoDisplay }} </h3>
   <div class='card__description' fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='6px'>
     <label>{{ 'SITES.PRODUCTS.CARD.CONNECTOR' | translate }} : </label>
     <span> {{ element?.type | infoDisplay }} </span>
   </div>
  </div>
</oyan-ui-simple-card>
