<div class="event-timeline" [ngClass]="getTimelineState()">
  <ul class="event-timeline-list">
    <li class="event-timeline-item">
      <div class="event-timeline-container">
        <div class="event-timeline-status">
          <span class="event-timeline-name">{{ 'EVENTS.TIMELINE.STATE.CREATE' | translate }}</span>
          <span class="event-timeline-icon"><mat-icon>wifi_tethering</mat-icon></span>
          <span class="event-timeline-time" *ngIf="time?.occurrenceTime">{{ time?.occurrenceTime | dateFormat }}</span>
        </div>
      </div>
      <div class="event-timeline-info">
        <div class="event-timeline-card">
          <span class="event-timeline-card-title">{{ 'EVENTS.TIMELINE.OCCURRENCE_TIME' | translate }}</span>
          <span class="event-timeline-card-content">{{ event?.occurrenceTime | dateFormat }}</span>
        </div>
        <div class="event-timeline-card">
          <span class="event-timeline-card-title">{{ 'EVENTS.TIMELINE.RAW_DATA' | translate }}</span>
          <span class="event-timeline-card-code"><code>{{ event?.rawData | json }}</code></span>
        </div>
      </div>
    </li>
    <li class="event-timeline-item">
      <div class="event-timeline-container">
        <div class="event-timeline-status">
          <span class="event-timeline-name">{{ 'EVENTS.TIMELINE.STATE.ACTIVE' | translate }}</span>
          <span class="event-timeline-icon"><mat-icon>notifications_active</mat-icon></span>
          <span class="event-timeline-time" *ngIf="time.receptionTime?.days >= 0">
            <span *ngIf="time.receptionTime.days">{{ time.receptionTime.days }}{{ 'EVENTS.TIMELINE.TIME.DAY' | translate }}</span>
            <span *ngIf="time.receptionTime.hours">{{ time.receptionTime.hours }}{{ 'EVENTS.TIMELINE.TIME.HOUR' | translate }}</span>
            <span *ngIf="time.receptionTime.minutes">{{ time.receptionTime.minutes }}{{ 'EVENTS.TIMELINE.TIME.MINUTE' | translate }}</span>
            <span *ngIf="time.receptionTime.seconds">{{ time.receptionTime.seconds }}{{ 'EVENTS.TIMELINE.TIME.SECOND' | translate }}</span>
          </span>
          <span class="event-timeline-time" *ngIf="time.receptionTime?.days < 0">
           N/A
          </span>
        </div>
      </div>
      <div class="event-timeline-info">
        <div class="event-timeline-card">
          <span class="event-timeline-card-title">{{ 'EVENTS.TIMELINE.RECEPTION_TIME' | translate }}</span>
          <span class="event-timeline-card-content">{{ event.receptionTime | dateFormat }}</span>
        </div>
        <div class="event-timeline-card">
          <span class="event-timeline-card-title">{{ 'EVENTS.TIMELINE.CONNECTOR' | translate }}</span>
          <span class="event-timeline-card-content">{{ event.connector | infoDisplay }}</span>
        </div>
      </div>
    </li>
    <li class="event-timeline-item">
      <div class="event-timeline-container">
        <div class="event-timeline-status">
          <span class="event-timeline-name">
            <button
              mat-raised-button
              class="event-timeline-status-change-button"
              color="accent"
              [disabled]="snoozeButtonDisableStatus"
              (click)="updateStatus.emit('snooze')"
            >
              {{ 'EVENTS.TIMELINE.STATE.SNOOZE' | translate }}
            </button>
          </span>
          <span class="event-timeline-icon"><mat-icon>notifications_paused</mat-icon></span>
          <span class="event-timeline-time" *ngIf="time.snooze">
            <span *ngIf="time.snooze.days">{{ time.snooze.days }}{{ 'EVENTS.TIMELINE.TIME.DAY' | translate }}</span>
            <span *ngIf="time.snooze.hours">{{ time.snooze.hours }}{{ 'EVENTS.TIMELINE.TIME.HOUR' | translate }}</span>
            <span *ngIf="time.snooze.minutes">{{ time.snooze.minutes }}{{ 'EVENTS.TIMELINE.TIME.MINUTE' | translate }}</span>
            <span *ngIf="time.snooze.seconds">{{ time.snooze.seconds }}{{ 'EVENTS.TIMELINE.TIME.SECOND' | translate }}</span>
          </span>
        </div>
      </div>
      <div
        class="event-timeline-info"
        *ngIf="!!snoozedLogs.length">
        <iot4bos-ui-event-timeline-log
          [log]="snoozedLog"
          *ngFor="let snoozedLog of snoozedLogs; index as i"
          [ngClass]="getSnoozeState(i)"
        ></iot4bos-ui-event-timeline-log>
      </div>
    </li>
    <li class="event-timeline-item">
      <div class="event-timeline-container">
        <div class="event-timeline-status">
          <span class="event-timeline-name">
            <button
              mat-raised-button
              class="event-timeline-status-change-button"
              color="accent"
              [disabled]="acknowledgeButtonDisableStatus"
              (click)="updateStatus.emit('acknowledge')"
            >
              {{ 'EVENTS.TABLE.BUTTONS.ACKNOWLEDGE' | translate }}
            </button>
          </span>
          <span class="event-timeline-icon"><mat-icon>notifications_none</mat-icon></span>
          <span class="event-timeline-time" *ngIf="time.acknowledge">
            <span *ngIf="time.acknowledge.days">{{ time?.acknowledge?.days }}{{ 'EVENTS.TIMELINE.TIME.DAY' | translate }}</span>
            <span *ngIf="time.acknowledge.hours">{{ time?.acknowledge?.hours }}{{ 'EVENTS.TIMELINE.TIME.HOUR' | translate }}</span>
            <span *ngIf="time.acknowledge.minutes">{{ time?.acknowledge?.minutes }}{{ 'EVENTS.TIMELINE.TIME.MINUTE' | translate }}</span>
            <span *ngIf="time.acknowledge.seconds">{{ time?.acknowledge?.seconds }}{{ 'EVENTS.TIMELINE.TIME.SECOND' | translate }}</span>
          </span>
        </div>
      </div>
      <div class="event-timeline-info" *ngIf="!!acknowledgedLogs.length">
        <iot4bos-ui-event-timeline-log
          [log]="acknowledgedLog"
          *ngFor="let acknowledgedLog of acknowledgedLogs; index as i"
          [class.current]="status === 'acknowledged' && i === 0"
        ></iot4bos-ui-event-timeline-log>
      </div>
    </li>
    <li class="event-timeline-item">
      <div class="event-timeline-container">
        <div class="event-timeline-status">
          <span class="event-timeline-name">
            <button
              mat-raised-button
              class="event-timeline-status-change-button"
              color="accent"
              [disabled]="closeButtonDisableStatus"
              (click)="updateStatus.emit('close')"
            >
              {{ 'EVENTS.TABLE.BUTTONS.CLOSE' | translate }}
            </button>
          </span>
          <span class="event-timeline-icon"><mat-icon>notifications_off</mat-icon></span>
          <span class="event-timeline-time" *ngIf="time.close">
            <span *ngIf="time.close.days">{{ time.close.days }}{{ 'EVENTS.TIMELINE.TIME.DAY' | translate }}</span>
            <span *ngIf="time.close.hours">{{ time.close.hours }}{{ 'EVENTS.TIMELINE.TIME.HOUR' | translate }}</span>
            <span *ngIf="time.close.minutes">{{ time.close.minutes }}{{ 'EVENTS.TIMELINE.TIME.MINUTE' | translate }}</span>
            <span *ngIf="time.close.seconds">{{ time.close.seconds }}{{ 'EVENTS.TIMELINE.TIME.SECOND' | translate }}</span>
          </span>
        </div>
      </div>
      <div class="event-timeline-info" *ngIf="!!closedLogs.length">
        <iot4bos-ui-event-timeline-log
          [log]="closedLog"
          *ngFor="let closedLog of closedLogs; index as i"
          [class.current]="status === 'closed' && i === 0"
        ></iot4bos-ui-event-timeline-log>
      </div>
    </li>
  </ul>
</div>
