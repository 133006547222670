import { Injectable } from '@angular/core';
import { AuthApiActions, AuthBusinessProfilesPageActions, AuthPageActions, fromAuth } from '@iot-platform/auth';
import { BusinessProfile, User } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthFacade {
  authPending$: Observable<boolean> = this.store.select(fromAuth.selectAuthPagePending);
  currentUser$: Observable<User> = this.store.select(fromAuth.selectCurrentUser);
  privileges$: Observable<{ [key: string]: string[] }> = this.store.select(fromAuth.selectPrivileges);
  selectedBusinessProfile$: Observable<BusinessProfile> = this.store.select(fromAuth.selectSelectedBusinessProfileForAccount);
  businessProfiles$: Observable<BusinessProfile[]> = this.store.select(fromAuth.selectBusinessProfilesForAccount);
  isBusinessProfileChanged$: Observable<boolean>;
  eligibleBusinessProfiles$: Observable<BusinessProfile[]>;

  constructor(private readonly store: Store) {
    this.listenForBusinessProfileChanges();
    this.initEligibleBusinessProfiles();
  }

  listenForBusinessProfileChanges(): void {
    this.isBusinessProfileChanged$ = combineLatest([this.selectedBusinessProfile$, this.store.select(fromAuth.selectSelectedBusinessProfileForAccount)]).pipe(
      map(([oldBp, newBp]) => {
        return oldBp && newBp && oldBp.id !== newBp.id;
      })
    );
  }

  initEligibleBusinessProfiles(): void {
    this.eligibleBusinessProfiles$ = this.businessProfiles$.pipe(
      map((businessProfiles: BusinessProfile[]) => {
        return businessProfiles && businessProfiles.filter((bp) => bp.level === 2);
      })
    );
  }

  loadPrivilegesSuccess(privileges): void {
    this.store.dispatch(AuthApiActions.loadPrivilegesSuccess({ privileges }));
  }

  selectBusinessProfile(selectedBusinessProfile: BusinessProfile, withRedirect: boolean): void {
    this.store.dispatch(
      AuthBusinessProfilesPageActions.selectBusinessProfile({
        selectedBusinessProfile,
        withRedirect
      })
    );
  }

  signOut(): void {
    this.store.dispatch(AuthPageActions.signOut());
  }
}
