import { Injectable } from '@angular/core';
import { Contact } from '@iot-platform/models/common';
import { SpreadsheetExport } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import { ContactsService } from '../../../../../../../shared/src/lib/services/contacts.service';
import {
  ContactsDbActions,
  ContactsUiActions,
  SpreadsheetExportsDbActions,
  SpreadsheetExportsUiActions
} from '../actions';

@Injectable()
export class ContactsEffects {
  loadContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactsUiActions.loadContacts),
      concatMap((action) => {
        return this.contactsService.loadContacts(action.siteId).pipe(
          map((contacts: Contact[]) => ContactsDbActions.loadContactsSuccess({ contacts: contacts })),
          catchError((error) => of(ContactsDbActions.loadContactsFailure({ error })))
        );
      })
    )
  );

  addContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactsUiActions.addContact),
      concatMap((action) => {
        return this.contactsService.addContact(action.contact).pipe(
          map((addedContact: Contact) => ContactsDbActions.addContactSuccess({ addedContact })),
          catchError((error) => of(ContactsDbActions.addContactFailure({ error: error })))
        );
      })
    )
  );

  updateContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactsUiActions.updateContact),
      concatMap((action) => {
        return this.contactsService.updateContact(action.contact).pipe(
          map((updatedContact: Contact) => ContactsDbActions.updateContactSuccess({ updatedContact })),
          catchError((error) => of(ContactsDbActions.updateContactFailure({ error: error })))
        );
      })
    )
  );

  deleteContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactsUiActions.deleteContact),
      concatMap((action) => {
        return this.contactsService.deleteContact(action.contact).pipe(
          map((deletedContact) => ContactsDbActions.deleteContactSuccess({ deletedContact })),
          catchError((error) => of(ContactsDbActions.deleteContactFailure({ error: error })))
        );
      })
    )
  );

  configureSpreadsheetExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpreadsheetExportsUiActions.configureSpreadsheetExport),
      concatMap((action) => {
        return this.contactsService.configureSpreadsheetExport(action.spreadsheetExport).pipe(
          map((response: SpreadsheetExport) => SpreadsheetExportsDbActions.configureSpreadsheetExportSuccess({ response })),
          catchError((error) => of(SpreadsheetExportsDbActions.configureSpreadsheetExportFailure({ error: error })))
        );
      })
    )
  );

  getSpreadsheetExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpreadsheetExportsUiActions.getSpreadsheetExport),
      concatMap(({ contactId }) => {
        return this.contactsService.getSpreadsheetExport(contactId).pipe(
          map((response: SpreadsheetExport) => SpreadsheetExportsDbActions.getSpreadsheetExportSuccess({ response: { contactId, ...response } })),
          catchError((error) => of(SpreadsheetExportsDbActions.getSpreadsheetExportFailure({ error, contactId })))
        );
      })
    )
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ContactsUiActions.addContact,
          ContactsUiActions.updateContact,
          ContactsUiActions.deleteContact,
          SpreadsheetExportsUiActions.configureSpreadsheetExport
        ),
        tap((_) => {
          this.notificationService.displayLoader(true);
        })
      ),
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ContactsDbActions.addContactSuccess,
          ContactsDbActions.updateContactSuccess,
          ContactsDbActions.deleteContactSuccess,
          SpreadsheetExportsDbActions.configureSpreadsheetExportSuccess,
          ContactsDbActions.addContactFailure,
          ContactsDbActions.updateContactFailure,
          ContactsDbActions.deleteContactFailure,
          SpreadsheetExportsDbActions.configureSpreadsheetExportFailure
        ),
        tap((_) => {
          this.notificationService.displayLoader(false);
        })
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ContactsDbActions.addContactSuccess,
          ContactsDbActions.updateContactSuccess,
          ContactsDbActions.deleteContactSuccess,
          SpreadsheetExportsDbActions.configureSpreadsheetExportSuccess
        ),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ContactsDbActions.loadContactsFailure,
          ContactsDbActions.addContactFailure,
          ContactsDbActions.updateContactFailure,
          ContactsDbActions.deleteContactFailure,
          SpreadsheetExportsDbActions.configureSpreadsheetExportFailure
        ),
        tap((action) => {
          this.notificationService.displayError(action);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly contactsService: ContactsService,
    private readonly notificationService: NotificationService
  ) {}
}
