<div class="site-overview">

  <oyan-ui-site-card
    [canDelete]="canDeleteSite"
    [canUpdate]="canUpdateSite"
    [site]="currentSite$ | async"
    [loading]="siteLoading$ | async"
    (update)="onEditSite()"
    (delete)="onDeleteSite()"
  ></oyan-ui-site-card>

  <oyan-ui-site-contacts
    [loading]="(siteLoading$ | async) || (contactsLoading$ | async)"
    [contacts]="contactsBySite$ | async"
    [canUpdateSite]="canUpdateSite"
    [canUpdateContact]="canUpdateContact"
    [canAddContact]="canAddContact"
    [canDeleteContact]="canDeleteContact"
    (dispatchEvent)="onDispatchCardEvent($event)"></oyan-ui-site-contacts>

  <oyan-ui-site-products
    [loading]="(siteLoading$ | async) || (productsLoading$ | async)"
    [products]="products$ | async"
    [canUpdateSite]="canUpdateSite"
    [canAddProduct]="canAddProduct"
    [canDeleteProduct]="canDeleteProduct"
    [productsBySite]="productsBySite$ | async"
    (dispatchEvent)="onDispatchCardEvent($event)"></oyan-ui-site-products>

  <oyan-ui-site-configurations
    [site]='currentSite$ | async'
    [canUpdate]="canUpdateSite"
    (dispatchEvent)="onDispatchCardEvent($event)"></oyan-ui-site-configurations>

  <oyan-ui-site-gateways
    [loading]="gatewaysLoading$ | async"
    [gateways]="gateways$ | async"
    [canUpdateSite]="canUpdateSite"
    [canAddGateway]="canAddGateway"
    [canDeleteGateway]="canDeleteGateway"
    [gatewaysBySite]="gatewaysBySite$ | async"
    (dispatchEvent)="onDispatchCardEvent($event)"></oyan-ui-site-gateways>

  <oyan-ui-site-stocks
    [stocksAsPlatformResponse]='wardsBySiteAsPlatformResponse$ | async'
    [loading]="(wardsLoading$ | async) && !(wardsLoaded$ | async)"
    [userPermissions]='userPermissions'
    (pageChange)='onStocksPageChangeEvent($event)'
    (dispatchMasterViewEngineEvent)="onDispatchStocksMasterViewEngineEvent($event)"></oyan-ui-site-stocks>

</div>
