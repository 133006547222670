<span
  [matTooltip]="dataToDisplay$ | async"
  [matTooltipPosition]="cellOptions?.tooltipPosition ? cellOptions?.tooltipPosition : 'above'"
  matTooltipClass="regular-tooltip"
  [ngClass]="cssClasses$ | async"
  class="dynamic-data-cell"
  *ngIf="(isDataLoaded$ | async) && !displayErrorMessage"
  (click)="onClick($event)"
  >{{ dataToDisplay$ | async | infoDisplay }}</span
>
<span *ngIf="(isDataLoaded$ | async) && displayErrorMessage" class="dynamic-data-cell--error-message">{{ errorMessage }}</span>
<mat-progress-spinner *ngIf="!(isDataLoaded$ | async)" color="accent" mode="indeterminate" [diameter]="20"></mat-progress-spinner>
