<mat-card class='dialog-card link-receivers'>
  <mat-card-title class='dialog-card-header'>
    <mat-toolbar fxLayout='row' fxLayoutAlign='space-between center' class='dialog-toolbar' color='accent'>
      <div fxLayout='row' fxLayoutAlign='start center' fxLayoutGap='20px'>
        <h1 class='dialog-card-title'>{{ 'SITES.LINK_RECEIVERS_FORM.TITLE' | translate }}</h1>
        <mat-progress-spinner *ngIf='loader$ | async'  mode='indeterminate' [diameter]='20'></mat-progress-spinner>
      </div>
      <button mat-icon-button class='regular-round-button' (click)='exit()'>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content fxLayout='column' class='dialog-card-content' fxLayoutGap="20px">
    <section fxFlex class='p-20 link-receivers__form'>
      <form [formGroup]="usersForm" fxLayout="row" fxFlex fxLayoutGap="10px">
        <div fxFlex='16%'>
          <mat-radio-group
            formControlName="isMainStock"
            aria-labelledby="is-main-stock-radio-group-label"
            color="accent"
            fxLayout="column"
            fxLayoutGap="10px"
            [value]="isMainStock"
          >
            <mat-radio-button [value]="false">{{ 'SITES.WARDS.TABLE.WARD_TYPE.DEFAULT' | translate }}</mat-radio-button>
            <mat-radio-button [value]="true">{{ 'SITES.WARDS.TABLE.WARD_TYPE.PRIMARY' | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field fxFlex='16%' color="accent">
          <input matInput formControlName="uf" maxlength="50" [placeholder]="'SITES.WARDS.TABLE.UF' | translate" />
        </mat-form-field>
        <mat-form-field fxFlex='16%' color="accent">
          <input matInput formControlName="name" required maxlength="50" [placeholder]="'SITES.WARDS.TABLE.NAME' | translate" />
        </mat-form-field>
        <mat-form-field fxFlex='16%' color="accent">
          <input matInput formControlName="building" maxlength="50" [placeholder]="'SITES.WARDS.TABLE.BUILDING' | translate" />
        </mat-form-field>
        <mat-form-field fxFlex='16%' color="accent">
          <input matInput formControlName="floor" maxlength="50" [placeholder]="'SITES.WARDS.TABLE.FLOOR' | translate" />
        </mat-form-field>
        <mat-form-field fxFlex='16%' color="accent">
          <input matInput formControlName="comment" maxlength="50" [placeholder]="'SITES.WARDS.TABLE.LOCATION' | translate" />
        </mat-form-field>
      </form>
    </section>

    <div fxLayout='row' fxLayoutGap='20px' fxFlex  class="link-receivers__list">
      <section fxLayout='column' fxFlex='50%'>
        <mat-toolbar class="mat-toolbar--gray" fxLayout='row' fxLayoutGap='10px' fxLayoutAlign='start center'>
          <p>{{ (unselectedReceivers$ | async)?.length }} {{ 'SITES.LINK_RECEIVERS_FORM.UNPAIR_RECEIVERS' | translate | uppercase }}</p>
        </mat-toolbar>

        <mat-list fxLayout='column' fxFlex='calc(50% - 20px)' class='p-20 height-300 two-line-list'>
          <mat-list-item *ngFor='let receiver of unselectedReceivers$ | async' class='hoverable' [matTooltip]="receiver?.name" matTooltipClass="regular-tooltip">
            <mat-icon matListIcon>work_outline</mat-icon>
            <h3 matLine>{{ receiver?.devEui }}</h3>
            <button mat-icon-button class='regular-round-button' [disabled]='loader$ | async'
                    (click)='addReceiver(receiver)'>
              <mat-icon>add</mat-icon>
            </button>
            <p matLine class='complementary-line'>
              <span>{{ receiver?.name }}</span>
            </p>
          </mat-list-item>
        </mat-list>
      </section>

      <section fxLayout='column' fxFlex='calc(50% - 20px)'>
        <mat-toolbar class="mat-toolbar--gray" fxLayout='row' fxLayoutGap='10px' fxLayoutAlign='start center'>
          <p>{{ (selectedReceivers$ | async)?.length }} {{ 'SITES.LINK_RECEIVERS_FORM.SELECTED_RECEIVERS' | translate | uppercase }}</p>
        </mat-toolbar>

        <mat-list fxLayout='column' fxFlex class='p-20 height-300 two-line-list'>
          <mat-list-item *ngFor='let receiver of selectedReceivers$ | async' class='hoverable' [matTooltip]="receiver?.name" matTooltipClass="regular-tooltip">
            <mat-icon matListIcon>work</mat-icon>
            <h3 matLine>{{ receiver?.devEui }}</h3>
            <button mat-icon-button class='regular-round-button' [disabled]='loader$ | async'
                    (click)='removeReceiver(receiver)'>
              <mat-icon>delete</mat-icon>
            </button>
            <p matLine class='complementary-line'>
              <span>{{ receiver?.name }}</span>
            </p>
          </mat-list-item>
        </mat-list>
      </section>
    </div>

  </mat-card-content>

  <mat-card-actions class='dialog-card-actions' fxLayout='row' fxLayoutAlign='end center'>
    <button mat-raised-button color='accent' class='button-regular' (click)="exit()">{{ 'SITES.LINK_RECEIVERS_FORM.DONE' | translate }}</button>
  </mat-card-actions>
</mat-card>
