import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { CustomHeaderParams } from '../custom-headers.params';

@Component({
  selector: 'grid-engine-icon-header-cell',
  templateUrl: './icon-header-cell.component.html',
  styleUrls: ['./icon-header-cell.component.scss']
})
export class IconHeaderCellComponent implements IHeaderAngularComp, OnDestroy {
  public params;
  public sorted: string;
  private elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  agInit(params: CustomHeaderParams): void {
    this.params = params;
    this.params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
    this.onSortChanged();
  }

  refresh(params: IHeaderParams): boolean {
    return false;
  }

  onMenuClick() {
    this.params.showColumnMenu(this.querySelector('.customHeaderMenuButton'));
  }

  onSortRequested(order, event) {
    this.params.setSort(order, event.shiftKey);
  }

  onSortChanged() {
    if (this.params.column.isSortAscending()) {
      this.sorted = 'asc';
    } else if (this.params.column.isSortDescending()) {
      this.sorted = 'desc';
    } else {
      this.sorted = '';
    }
  }

  private querySelector(selector: string) {
    return <HTMLElement>this.elementRef.nativeElement.querySelector('.customHeaderMenuButton', selector);
  }
  ngOnDestroy() {}
}
