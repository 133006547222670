<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'ADMIN.DIALOG.LINK_ROLES_TO_BP.TITLE' | translate: param }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="exit()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content p-20" fxLayout="row" fxLayoutGap="20px">
    <section fxLayout="column" fxFlex class="">
      <mat-toolbar fxLayout="row" color="primary" fxLayoutGap="10px" fxLayoutAlign="start center">
        <p>{{ unselectedRoles.length }}{{ 'ADMIN.DIALOG.LINK_ROLES_TO_BP.AVAILABLE_ROLES' | translate | uppercase }}</p>
        <mat-progress-spinner color="accent" *ngIf="unselectedRolesPending" mode="indeterminate" [diameter]="20"></mat-progress-spinner>
      </mat-toolbar>

      <mat-list fxFlex class="height-300 one-line-list">
        <mat-list-item *ngFor="let role of unselectedRoles" class="hoverable">
          <mat-icon matListIcon>work_outline</mat-icon>
          <h3 matLine>{{ role.name }}</h3>
          <button mat-icon-button class="regular-round-button" (click)="addRoleToProfile(role)">
            <mat-icon>add</mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </section>

    <section fxLayout="column" fxFlex class="">
      <mat-toolbar fxLayout="row" color="primary" fxLayoutAlign="start center">
        <p>{{ currentRoles.length }}{{ 'ADMIN.DIALOG.LINK_ROLES_TO_BP.SELECTED_ROLES' | translate | uppercase }}</p>
      </mat-toolbar>

      <mat-list fxFlex class="height-300 one-line-list">
        <mat-list-item *ngFor="let role of currentRoles" class="hoverable">
          <mat-icon matListIcon>work</mat-icon>
          <h3 matLine>{{ role.name }}</h3>
          <button mat-icon-button (click)="removeRoleFromProfile(role)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </section>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button color="accent" class="button-regular" (click)="exit()">{{ 'ADMIN.DIALOG.LINK_ROLES_TO_BP.DONE' | translate }}</button>
  </mat-card-actions>
</mat-card>
