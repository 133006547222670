import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardAction } from '../../models/card-action.model';
import { CardEvent } from '../../models/card-event.model';
import { CardEventType } from '../../models/enums/card-event-event-type.enum';

@Component({
  template: ''
})
export abstract class BaseCardComponent<T> {
  @Input() element: T;
  @Input() icon: string;
  @Input() image: string;
  @Input() withMenu = false;
  @Input() cssClasses: string;
  @Input() actions: CardAction[] = [];

  @Output() dispatchEvent: EventEmitter<CardEvent | CardAction> = new EventEmitter();

  onDispatchEvent(event: CardEvent | CardAction): void {
    this.dispatchEvent.emit(event);
  }

  onElementClicked(): void {
    this.dispatchEvent.emit({
      eventType: CardEventType.ELEMENT_CLICKED,
      data: this.element
    });
  }
}
