import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { User } from '@iot-platform/models/common';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UsersService } from '../../../features/admin-users/services/users.service';

@Component({
  selector: 'iot4bos-backoffice-ui-dialog-users-add-form',
  templateUrl: './dialog-users-add-form.component.html',
  styleUrls: ['./dialog-users-add-form.component.scss']
})
export class DialogUsersAddFormComponent implements OnInit {
  usersForm: UntypedFormGroup;
  carmURL = this.usersService.getCarmUrl();

  constructor(
    protected dialogRef: MatDialogRef<DialogUsersAddFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { externalEntityManager?: boolean },
    protected usersService: UsersService
  ) {}

  get firstnameControl(): AbstractControl {
    return this.usersForm.get('firstname');
  }

  get lastnameControl(): AbstractControl {
    return this.usersForm.get('lastname');
  }

  get emailControl(): AbstractControl {
    return this.usersForm.get('email');
  }

  get isShared(): AbstractControl {
    return this.usersForm.get('isShared');
  }

  ngOnInit() {
    this.usersForm = new UntypedFormGroup({
      firstname: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
      lastname: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
      email: new UntypedFormControl('', [Validators.required, Validators.email], [this.checkEmail.bind(this)]),
      isShared: new UntypedFormControl(this.data?.externalEntityManager)
    });
  }

  checkEmail(email: UntypedFormControl): Observable<{ used: boolean } | null | undefined> {
    if (!this.usersForm) {
      return of(null);
    }

    if (this.emailControl.value) {
      return this.usersService.getTotalUsersWithParams([{ key: 'exactEmail', value: email.value }]).pipe(
        map((usersWithThisEmail: number) => {
          return usersWithThisEmail > 0 ? { used: true } : null;
        }),
        take(1)
      );
    } else {
      return of(null);
    }
  }

  isUserFormDisabled(): boolean {
    if (this.isShared.value) {
      return !(this.lastnameControl.valid && this.emailControl.valid);
    } else {
      return !this.usersForm.valid;
    }
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const newUser: User = {
      firstname: this.isShared.value ? '' : this.firstnameControl.value,
      lastname: this.lastnameControl.value,
      email: this.emailControl.value,
      isShared: this.isShared.value
    };
    this.dialogRef.close(newUser);
  }
}
