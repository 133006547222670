import { PlatformResponse } from '@iot-platform/models/common';
import { DeviceEvent } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';
//
export const loadDeviceEventsByTopicSuccess = createAction(
  '[DeviceEventsByTopic] Load Device Events By Topic Success',
  props<{ response: PlatformResponse; topicId: string }>()
);
export const loadDeviceEventsByTopicFailure = createAction('[DeviceEventsByTopic] Load Device Events By Topic Failure', props<{ error: any }>());
export const loadTotalActiveDeviceEventsByTopicSuccess = createAction(
  '[DeviceEventsByTopic] Load Total Active Device Events By Topic Success',
  props<{ totalActiveEvents: number }>()
);
export const loadTotalActiveAssetEventsByTopicFailure = createAction(
  '[DeviceEventsByTopic] Load Total Active Device Events By Topic Failure',
  props<{ error: any }>()
);
export const saveTableByTopicStateSuccess = createAction(
  '[DeviceEventsByTopic] Save Table State Success',
  props<{ selectedId: string; checkedIds: string[] }>()
);
export const saveTableByTopicStateFailure = createAction('[DeviceEventsByTopic] Save Table State Failure', props<{ error: any }>());

export const updateStatusByDeviceEventIdByTopicSuccess = createAction(
  '[DeviceEventsByTopic] Update Status By Device Event Id Success',
  props<{ deviceEvent: DeviceEvent }>()
);
export const updateStatusByDeviceEventIdByTopicFailure = createAction(
  '[DeviceEventsByTopic] Update Status By Device Event Id Failure',
  props<{ error: any }>()
);
export const bulkUpdateStatusByDeviceEventIdByTopicSuccess = createAction(
  '[DeviceEventsByTopic] Bulk Update Status By Device Event Id Success',
  props<{ deviceEvents: DeviceEvent[] }>()
);
export const bulkUpdateStatusByDeviceEventIdFailure = createAction(
  '[DeviceEventsByTopic] Bulk Update Status By Device Event Id Failure',
  props<{ error: any }>()
);
export const loadMvDeviceEventsByTopicSettingsSuccess = createAction(
  '[DeviceEventsByTopic] Load MV DeviceEventsByTopic Settings Success',
  props<{ settings: any }>()
);
export const loadMvDeviceEventsByTopicSettingsFailure = createAction(
  '[DeviceEventsByTopic] Load MV DeviceEventsByTopic Settings Failure',
  props<{ error: any }>()
);
