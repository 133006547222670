import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'iot4bos-ui-device-call-log-toolbar',
  templateUrl: './device-call-log-toolbar.component.html',
  styleUrls: ['./device-call-log-toolbar.component.scss']
})
export class DeviceCallLogToolbarComponent implements OnInit, OnDestroy {
  filterForm: UntypedFormGroup;
  sub: Subscription[] = [];
  minDate: Date;
  maxDate: Date;
  statusList: Set<string>;

  @Input() initialFilters$: Observable<{ statusList: Set<string>; minDate: Date; maxDate: Date }>;

  @Output() applyFilters: EventEmitter<{ status: string; startDate: Date; endDate: Date }> = new EventEmitter<{
    status: string;
    startDate: Date;
    endDate: Date;
  }>();

  constructor() {}

  ngOnInit() {
    this.filterForm = new UntypedFormGroup({
      status: new UntypedFormControl(),
      startDate: new UntypedFormControl(),
      endDate: new UntypedFormControl()
    });

    this.sub.push(
      this.initialFilters$.subscribe((filters: { statusList: Set<string>; minDate: Date; maxDate: Date }) => {
        this.statusList = filters.statusList;
        this.minDate = filters.minDate;
        this.maxDate = filters.maxDate;
      })
    );
  }

  resetFilters() {
    this.filterForm.reset();
    this.onFilterChange();
  }

  onFilterChange(): void {
    this.applyFilters.emit({
      status: this.status.value,
      startDate: this.startDate.value,
      endDate: this.endDate.value
    });
  }

  get status(): AbstractControl {
    return this.filterForm.get('status');
  }

  get startDate(): AbstractControl {
    return this.filterForm.get('startDate');
  }

  get endDate(): AbstractControl {
    return this.filterForm.get('endDate');
  }

  ngOnDestroy() {
    this.sub.forEach((s: Subscription) => s.unsubscribe());
  }
}
