<div class="organization-list-container full-height">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [matTreeNodePaddingIndent]="16" [ngClass]="{ selected: node && selectedOrganization ? selectedOrganization.id === node.id : false }">
      <!-- use a disabled button to provide padding for tree leaf -->
      <button mat-icon-button disabled></button>
      <div (click)="onSelectOrganization(node)" fxFlex fxLayout="row" fxLayoutAlign="start center" class="organization-list-node-name">
        <span>{{ node.name }}</span>
        <mat-icon *ngIf='node.isLocked' class='organization-list-node-name__lock-icon'>lock_outline</mat-icon>
        <span fxFlex></span>
        <button
          *ngIf="canUpdateOrganization && (node.isLocked || isTopLevelAdmin)"
          [matTooltip]="(node.isLocked ? 'ADMIN.ORGANIZATIONS.DETAIL.UNLOCK_ORGANIZATION' : 'ADMIN.ORGANIZATIONS.DETAIL.LOCK_ORGANIZATION') | translate"
          matTooltipClass="regular-tooltip"
          matTooltipPosition="below"
          mat-icon-button
          class="regular-round-button add-orga-button organization-list-node-name__lock-btn"
          [disabled]="!isTopLevelAdmin"
          (click)="onLockUnlockOrganization(node, !node?.isLocked)"
        >
          <mat-icon>{{ node.isLocked ? 'lock_open_outline' : 'lock_outline' }}</mat-icon>
        </button>
        <button
          [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.ADD_ORGANIZATION' | translate"
          *ngIf="canCreateOrganization"
          matTooltipClass="regular-tooltip"
          matTooltipPosition="below"
          mat-icon-button
          class="regular-round-button add-orga-button"
          (click)="onAddOrganization(node.id)"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="16" [ngClass]="{ selected: node && selectedOrganization ? selectedOrganization.id === node.id : false }">
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <div (click)="onSelectOrganization(node)" fxFlex fxLayout="row" fxLayoutAlign="start center" class="organization-list-node-name">
        <span>{{ node.name }}</span>
        <mat-icon *ngIf='node.isLocked' class='organization-list-node-name__lock-icon'>lock_outline</mat-icon>
        <span fxFlex></span>

        <button
          *ngIf="canUpdateOrganization && (node.isLocked || isTopLevelAdmin)"
          [matTooltip]="(node.isLocked ? 'ADMIN.ORGANIZATIONS.DETAIL.UNLOCK_ORGANIZATION' : 'ADMIN.ORGANIZATIONS.DETAIL.LOCK_ORGANIZATION') | translate"
          matTooltipClass="regular-tooltip"
          matTooltipPosition="below"
          mat-icon-button
          class="regular-round-button add-orga-button organization-list-node-name__lock-btn"
          [disabled]="!isTopLevelAdmin"
          (click)="onLockUnlockOrganization(node, !node?.isLocked)"
        >
          <mat-icon>{{ node.isLocked ? 'lock_open_outline' : 'lock_outline' }}</mat-icon>
        </button>
        <button
          [matTooltip]="'ADMIN.ORGANIZATIONS.DETAIL.ADD_ORGANIZATION' | translate"
          *ngIf="canCreateOrganization"
          matTooltipClass="regular-tooltip"
          matTooltipPosition="below"
          mat-icon-button
          class="regular-round-button add-orga-button"
          (click)="onAddOrganization(node.id)"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </mat-tree-node>
  </mat-tree>
</div>
