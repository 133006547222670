<mat-card-content class="p-20 mve-detail-popup-container" fxLayout="column" fxLayoutGap="20px">
  <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
    <div fxFlex fxLayout="column" fxLayoutGap="6px">
      <span (click)="onUsernameClick(data.user)" class="mve-detail-popup-title fw-600 mve-detail-popup-title-clickable">{{ data?.user.firstname }} {{ data?.user.lastname }}</span>
      <iot-platform-ui-detail-popup-display-property
        property="{{ 'IOT_DICTIONARY.ENTITIES' | translate }}:"
        value="{{ getFormattedEntities(data?.user.entities) }}"
      ></iot-platform-ui-detail-popup-display-property>
      <div class="user-detail-popup-status">
        <span class="capitalize-first-letter">{{ 'IOT_DICTIONARY.STATUS' | translate }}:</span>
        <span [class]="'fw-600 capitalize-first-letter user-detail-popup-status-' + data?.user.status.toLowerCase()" class="fw-600">{{
          'IOT_DICTIONARY.' + data?.user.status.toLowerCase() | translate
        }}</span>
      </div>
    </div>
    <div fxFlex fxLayout="column" fxLayoutGap="6px">
      <iot-platform-ui-detail-popup-display-property
        property="{{ 'IOT_DICTIONARY.EMAIL' | translate }}:"
        value="{{ data?.user.email }}"
      ></iot-platform-ui-detail-popup-display-property>
      <iot-platform-ui-detail-popup-display-property
        property="{{ 'ADMIN.USERS.TABLE.ACCOUNT_TYPE' | translate }}:"
        value="{{ (data?.user.isShared ? 'ADMIN.USERS.TABLE.TYPE.SHARED_ACCOUNT' : 'ADMIN.USERS.TABLE.TYPE.USER_ACCOUNT') | translate }}"
      ></iot-platform-ui-detail-popup-display-property>
    </div>
  </section>

  <section fxLayout="column" fxLayoutGap="10px">
    <iot-platform-ui-detail-popup-section-header
      headerTitle="{{ (data?.user.totalBusinessProfile <= 1 ? 'IOT_DICTIONARY.BUSINESS_PROFILE' : 'IOT_DICTIONARY.BUSINESS_PROFILES') | translate }} ({{
        data?.user.totalBusinessProfile
      }})"
    ></iot-platform-ui-detail-popup-section-header>
    <div class="user-detail-business-profile-list">
      <ul
      *ngIf="loadingBusinessProfiles"
        [ngClass]="{ visible: loadingBusinessProfiles, invisible: !loadingBusinessProfiles }"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="20px"
      >
        <li
          *ngFor="let profile of selectedUser?.businessProfiles"
          [ngClass]="{ 'out-of-scope-bp': profile.notCoveredByUserEntityAt !== null }"
          class="user-detail-business-profile-item"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="14px"
        >
          <mat-icon>people</mat-icon>
          <div fxLayout="column" fxLayoutAlign="start start">
            <span>{{ profile.name }}</span>
            <span *ngIf="profile.notCoveredByUserEntityAt === null; else outOfScopeMessage" class="grey">
              <span> {{ profile.entityName }} </span>
            </span>
            <ng-template #outOfScopeMessage>
              <span class="out-of-scope-bp-message">
                {{ 'ADMIN.USERS.TABLE.BUSINESS_PROFILE_OUT_OF_SCOPE' | translate }}
              </span>
            </ng-template>
          </div>
        </li>
      </ul>
      <iot-platform-ui-card-loader *ngIf="!loadingBusinessProfiles" [backgroundClass]="'h-31 users-detail-bp'" fxFlex="25%"></iot-platform-ui-card-loader>
      <span
        *ngIf="loadingBusinessProfiles && data?.user?.totalBusinessProfile === 0"
        [ngClass]="{ visible: loadingBusinessProfiles, invisible: !loadingBusinessProfiles }"
        class="user-detail-no-bp-message"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        {{ 'ADMIN.USERS.TABLE.NO_BUSINESS_PROFILE_ASSOCIATED' | translate }}
      </span>
    </div>
  </section>
  <iot-platform-ui-detail-popup-metadata-footer [data]="data?.user"></iot-platform-ui-detail-popup-metadata-footer>
</mat-card-content>
