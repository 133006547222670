import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'iot4bos-backoffice-ui-filter-bp-by-name',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() searchString: string;

  @Output() filter: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
