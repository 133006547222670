import { Component, Input, OnInit } from '@angular/core';

import { Contact } from '@iot-platform/models/common';

@Component({
  selector: 'iot-platform-ui-detail-contact-card',
  templateUrl: './detail-contact-card.component.html',
  styleUrls: ['./detail-contact-card.component.scss']
})
export class DetailContactCardComponent implements OnInit {
  @Input() contacts: Contact[] = [];

  displayLimit = 2;

  constructor() {}

  get sortedContacts(): Contact[] {
    return this.contacts.sort((a, b) =>
      a.lastname.toLowerCase() + a.firstname.toLowerCase() + a.id > b.lastname.toLowerCase() + b.firstname.toLowerCase() + b.id ? 1 : -1
    );
  }

  ngOnInit() {}
}
