import { Injectable } from '@angular/core';

import { PlatformResponse, TagCategory } from '@iot-platform/models/common';
import {
  Asset,
  AssetEvent,
  AssetVariable,
  Device,
  DeviceVariable,
  I4BBulkOperationApiResponse,
  I4BBulkOperationApiResponseStatuses,
  Site
} from '@iot-platform/models/i4b';

import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { GridsDbActions } from '../../../../../../../grid-engine/src/lib/components/state/actions';
import * as fromGrids from '../../../../../../../grid-engine/src/lib/components/state/reducers';
import * as fromFavoriteViews from '../../../../../../../shared/src/lib/favorite-views/+state/reducers';

import { AssetEventsService } from '../../../../../../../shared/src/lib/services/asset-events.service';

import {
  UserPreferencesService
} from '../../../../../../../users/src/lib/features/preferences/services/user-preferences.service';
import { AssetEventsDbActions, AssetEventsLogsUiActions, AssetEventsUiActions } from '../actions';

@Injectable()
export class AssetEventsEffects {
  loadAssetEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.loadAssetEvents),
      switchMap((action) =>
        this.assetEventsService.getAssetEvents(action.request).pipe(
          map((response: PlatformResponse) => AssetEventsDbActions.loadAssetEventsSuccess({ response })),
          catchError((error) => of(AssetEventsDbActions.loadAssetEventsFailure({ error })))
        )
      )
    )
  );

  loadSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.loadSiteById),
      switchMap((action) =>
        this.assetEventsService.getSiteById(action.siteId).pipe(
          map((site: Site) => AssetEventsDbActions.loadSiteByIdSuccess({ site })),
          catchError((error) => of(AssetEventsDbActions.loadSiteByIdFailure({ error })))
        )
      )
    )
  );

  loadAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.loadAssetById),
      switchMap((action) =>
        this.assetEventsService.getAssetById(action.assetId).pipe(
          map((asset: Asset) => AssetEventsDbActions.loadAssetByIdSuccess({ asset })),
          catchError((error) => of(AssetEventsDbActions.loadAssetByIdFailure({ error })))
        )
      )
    )
  );

  loadAssetVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.loadAssetVariableById),
      switchMap((action) =>
        this.assetEventsService.getAssetVariableById(action.assetVariableId).pipe(
          map((assetVariable: AssetVariable) => AssetEventsDbActions.loadAssetVariableByIdSuccess({ assetVariable })),
          catchError((error) => of(AssetEventsDbActions.loadAssetVariableByIdFailure({ error })))
        )
      )
    )
  );

  loadDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.loadDeviceById),
      switchMap((action) =>
        this.assetEventsService.getDeviceById(action.deviceId).pipe(
          map((device: Device) => AssetEventsDbActions.loadDeviceByIdSuccess({ device })),
          catchError((error) => of(AssetEventsDbActions.loadDeviceByIdFailure({ error })))
        )
      )
    )
  );

  loadDeviceVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.loadDeviceVariableById),
      switchMap((action) =>
        this.assetEventsService.getDeviceVariableById(action.deviceVariableId).pipe(
          map((deviceVariable: DeviceVariable) => AssetEventsDbActions.loadDeviceVariableByIdSuccess({ deviceVariable })),
          catchError((error) => of(AssetEventsDbActions.loadDeviceVariableByIdFailure({ error })))
        )
      )
    )
  );

  loadTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.loadTagsByAssetEventId),
      switchMap((action) => {
        return this.assetEventsService.getTagsByAssetEventId(action.assetEventId).pipe(
          map((tags: TagCategory[]) => AssetEventsDbActions.loadTagsByAssetEventIdSuccess({ tags })),
          catchError((error) => of(AssetEventsDbActions.loadTagsByAssetEventIdFailure({ error })))
        );
      })
    )
  );

  updateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.updateStatusByAssetEventId),
      withLatestFrom(this.store.select(fromGrids.getDefaultAssetEventsGrid)),
      switchMap(([action, grid]) =>
        this.assetEventsService.putStatus(action.status).pipe(
          switchMap((assetEvent: AssetEvent) =>
            grid?.id
              ? [
                  AssetEventsDbActions.updateStatusByAssetEventIdSuccess({ assetEvent }),
                  GridsDbActions.updateItemInGridData({ gridId: grid.id, item: assetEvent })
                ]
              : [AssetEventsDbActions.updateStatusByAssetEventIdSuccess({ assetEvent })]
          ),
          catchError((error) => of(AssetEventsDbActions.updateStatusByAssetEventIdFailure({ error })))
        )
      )
    )
  );

  bulkUpdateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.bulkUpdateStatusByAssetEventId),
      switchMap((action) => this.assetEventsService.bulkUpdateStatus(action.assetEventIds, action.status)),
      mergeMap((results) =>
        results.pipe(
          switchMap((assetEvent) => [
            GridsDbActions.updateItemInAllGridsData({ updatedItem: assetEvent }),
            AssetEventsDbActions.updateStatusByAssetEventIdSuccess({ assetEvent })
          ]),
          catchError((error) => of(AssetEventsDbActions.updateStatusByAssetEventIdFailure({ error })))
        )
      )
    )
  );

  newBulkUpdateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.newBulkUpdateStatusByAssetEventId),
      withLatestFrom(
        this.store.pipe(select(fromGrids.getDefaultAssetEventsGrid)),
        this.store.pipe(select(fromFavoriteViews.getFiltersForMasterViewAssetEvents))
      ),
      switchMap(([action, grid, filters]) =>
        this.assetEventsService.newBulkUpdateStatusByEventType('asset-events', action.assetEventIds, action.status).pipe(
          concatMap((response: I4BBulkOperationApiResponse) => [
            AssetEventsDbActions.newBulkUpdateStatusByAssetEventIdSuccess({ response }),
            GridsDbActions.loadGridData({
              request: {
                filters,
                limit: grid?.data.response.pagination.limit,
                concept: grid?.masterview.toLowerCase(),
                page: grid?.data.response.pagination.currentPage,
                variables: grid?.gridOptions.variableNames,
                tags: grid?.gridOptions.tagIds,
                endPoint: grid?.gridOptions.endPoint
              }
            })
          ]),
          catchError((error) => of(AssetEventsDbActions.newBulkUpdateStatusByAssetEventIdFailure({ error })))
        )
      )
    )
  );

  displaySuccessAfterBulkUpdateStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetEventsDbActions.newBulkUpdateStatusByAssetEventIdSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type + I4BBulkOperationApiResponseStatuses[action.response.status]);
        })
      ),
    { dispatch: false }
  );

  loadLogsAfterUpdateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsDbActions.updateStatusByAssetEventIdSuccess),
      map((action) => AssetEventsLogsUiActions.loadLogsByAssetEventId({ assetEventId: action.assetEvent.id }))
    )
  );

  saveTableState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetEventsUiActions.saveTableState),
      switchMap((action) =>
        this.assetEventsService.saveTableState(action.tableState).pipe(
          map((tableState: { selected: AssetEvent; checked: AssetEvent[] }) =>
            AssetEventsDbActions.saveTableStateSuccess({
              selectedId: tableState.selected ? tableState.selected.id : null,
              checkedIds: tableState.checked ? tableState.checked.map((c) => c.id) : []
            })
          ),
          catchError((error) => of(AssetEventsDbActions.saveTableStateFailure({ error })))
        )
      )
    )
  );

  succeededActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetEventsDbActions.updateStatusByAssetEventIdSuccess, AssetEventsDbActions.saveMVSettingsSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );

  failedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetEventsDbActions.saveTableStateFailure,
          AssetEventsDbActions.loadAssetEventsFailure,
          AssetEventsDbActions.updateStatusByAssetEventIdFailure,
          AssetEventsDbActions.loadMVSettingsFailure,
          AssetEventsDbActions.saveMVSettingsFailure
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  pendingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetEventsUiActions.loadAssetEvents,
          AssetEventsUiActions.updateStatusByAssetEventId,
          AssetEventsUiActions.loadMVSettings,
          AssetEventsUiActions.saveMVSettings,
          AssetEventsUiActions.newBulkUpdateStatusByAssetEventId
        ),
        map(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  completedActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssetEventsDbActions.loadAssetEventsSuccess,
          AssetEventsDbActions.updateStatusByAssetEventIdSuccess,
          AssetEventsDbActions.loadAssetEventsFailure,
          AssetEventsDbActions.updateStatusByAssetEventIdFailure,
          AssetEventsDbActions.saveMVSettingsSuccess,
          AssetEventsDbActions.saveMVSettingsFailure,
          AssetEventsDbActions.loadMVSettingsSuccess,
          AssetEventsDbActions.loadMVSettingsFailure,
          AssetEventsDbActions.newBulkUpdateStatusByAssetEventIdSuccess,
          AssetEventsDbActions.newBulkUpdateStatusByAssetEventIdFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly assetEventsService: AssetEventsService,
    private readonly notificationService: NotificationService,
    private readonly userPrefService: UserPreferencesService,
    private readonly store: Store
  ) {}
}
