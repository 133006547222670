import { Injectable } from '@angular/core';
import { ApiSelector, Filter, Pagination } from '@iot-platform/models/common';
import { Receiver } from '@iot-platform/models/oyan';
import * as fromMain from '../reducers';
import * as fromReceivers from '../reducers/receivers.reducer';

@Injectable({
  providedIn: 'root'
})
export class ReceiversSelectors extends ApiSelector<fromMain.State, Receiver, Pagination, Filter> {
  constructor() {
    super(fromMain.featureKey, fromReceivers.featureKey, fromReceivers.adapter);
  }
}
