import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Pagination } from '@iot-platform/models/common';

@Component({
  selector: 'grid-engine-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnChanges {
  @Input() pagination: any;

  @Output() pageChange: EventEmitter<Pagination> = new EventEmitter(null);

  currentPage = 0;

  ngOnChanges(changes: SimpleChanges): void {
    this.currentPage = changes['pagination'].currentValue?.currentPage;
  }

  navigateFirst() {
    this.changePagination({ ...this.pagination, currentPage: 0 });
  }

  navigatePrevious() {
    this.changePagination({ ...this.pagination, currentPage: this.currentPage - 1 });
  }

  navigateNext() {
    this.changePagination({ ...this.pagination, currentPage: this.currentPage + 1 });
  }

  navigateLast() {
    this.changePagination({ ...this.pagination, currentPage: this.pagination.maxPage });
  }

  changePagination(pagination: Pagination) {
    this.currentPage = pagination.currentPage;
    this.pageChange.emit(pagination);
  }

  getTotalElements(): number {
    if (this.pagination && !this.pagination.hasMore) {
      return this.pagination.total < this.pagination.limit * (this.pagination.currentPage + 1) ? this.pagination.total : this.pagination.limit;
    } else {
      return this.getRealLimit();
    }
  }

  getRealLimit() {
    return (this.pagination.currentPage + 1) * this.pagination.limit;
  }

  getPaginationFooter() {
    const footer = this.pagination ? `${this.pagination.currentPage * this.pagination.limit + 1} - ${this.getTotalElements()} of ${this.pagination.total}` : '';
    return footer;
  }
}
