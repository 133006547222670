import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-progress-bar-button',
  templateUrl: './progress-bar-button.component.html',
  styleUrls: ['./progress-bar-button.component.scss']
})
export class ProgressBarButtonComponent implements OnChanges {
  value = 50;
  bufferValue = 100;
  mode = 'indeterminate';

  @Input() title = '';
  @Input() disabled = false;
  @Input() enableGauge = false;
  @Input() gaugeColor = 'primary';

  @Input() fabIconButton = false;
  @Input() icon!: string;
  @Input() buttonColor!: string;
  @Input() spinnerColor = 'accent';
  @Input() cssClasses!: string;
  @Input() tooltip!: string;

  @Output() clickEvent: EventEmitter<Event> = new EventEmitter<Event>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.title) {
      this.title = changes.title.currentValue;
    }
    if (changes.enableGauge) {
      this.enableGauge = changes.enableGauge.currentValue;
    }
    if (changes.disabled) {
      this.disabled = changes.disabled.currentValue;
    }
  }

  onClick($event: Event) {
    this.clickEvent.emit($event);
  }
}
