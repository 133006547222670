import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Site } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-site-breadcrumb-item',
  templateUrl: './site-breadcrumb-item.component.html',
  styleUrls: ['./site-breadcrumb-item.component.scss', '../breadcrumb-item.scss']
})
export class SiteBreadcrumbItemComponent implements OnInit {
  @Input() site: Site;
  @Input() active = true;

  @Output() navigate: EventEmitter<{ activeId: string; requestedSiteId: string }> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClick() {
    this.navigate.emit({ activeId: this.site.id, requestedSiteId: this.site.id });
  }
}
