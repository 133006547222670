import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { DeviceCallLog } from '@iot-platform/models/i4b';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceCallLogService {
  constructor(@Inject('environment') private environment, private httpClient: HttpClient) {}

  getInitialFilters(callLogs: DeviceCallLog[]): { statusList: Set<string>; minDate: Date; maxDate: Date } {
    return {
      statusList: this.getStatusList(callLogs),
      minDate: this.getDateInterval(callLogs).minDate,
      maxDate: this.getDateInterval(callLogs).maxDate
    };
  }

  getStatusList(callLog: DeviceCallLog[]): Set<string> {
    return new Set(callLog.map((cL) => cL.callStatus));
  }

  getDateInterval(callLog: DeviceCallLog[]): { minDate: Date; maxDate: Date } {
    return {
      minDate: new Date(
        Math.min.apply(
          Math,
          callLog.map((cL) => cL.timestamp)
        )
      ),
      maxDate: new Date(
        Math.max.apply(
          Math,
          callLog.map((cL) => cL.timestamp)
        )
      )
    };
  }

  applyFilters(callLogRaw: DeviceCallLog[], filters: { status: string; startDate: Date; endDate: Date }): DeviceCallLog[] {
    let callLogFiltered: DeviceCallLog[];

    callLogFiltered = filters.status ? callLogRaw.filter((cL) => cL.callStatus === filters.status) : callLogRaw;
    callLogFiltered = filters.startDate ? callLogFiltered.filter((cL) => cL.timestamp >= filters.startDate.getTime()) : callLogFiltered;
    callLogFiltered = filters.endDate ? callLogFiltered.filter((cL) => cL.timestamp <= filters.endDate.getTime() + 86399000) : callLogFiltered;

    return callLogFiltered;
  }

  getCallLogMessage(direction: string, deviceIdentifier: string, callLogTimestamp: number): Observable<any> {
    return this.httpClient.get(
      `${this.environment.api.url_v2}${this.environment.api.endpoints.messages}/${direction}/devices/${deviceIdentifier}/timestamps/${callLogTimestamp}`
    );
  }
}
