import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizationService } from '@iot-platform/auth';
import { AnalyticsService } from '@iot-platform/core';
import { GridExportService } from '@iot-platform/grid-engine';
import {
  ADD_BUTTON_CONFIG,
  BaseComponent,
  EXPORT_BUTTON_CONFIG,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  REFRESH_BUTTON_CONFIG,
  TOGGLE_FILTER_ENGINE_BUTTON_CONFIG
} from '@iot-platform/iot-platform-ui';
import {
  BusinessProfile,
  FavoriteView,
  Filter,
  IotToolbarEvent,
  MasterViewEngineEvent,
  Pagination,
  PlatformRequest,
  PlatformResponse,
  ToolbarSize,
  User
} from '@iot-platform/models/common';
import { fromUserPreferences } from '@iot-platform/users';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UserPreferencesService } from 'libs/users/src/lib/features/preferences/services/user-preferences.service';
import { PreferencesActions } from 'libs/users/src/lib/features/preferences/state/actions';
import { get } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { AuthorizationConcept, AuthorizationType } from '../../../../../../auth/src/lib/authorization.types';
import { PopupComponent } from '../../../../../../iot-platform-ui/src/lib/ui/components/popup/popup.component';
import {
  DialogUsersAddFormComponent
} from '../../../components/dialogs/dialog-users-add-form/dialog-users-add-form.component';
import { UserDetailComponent } from '../components/user-detail/user-detail.component';
import { AdminUsersPageActions } from '../state/actions';
import * as fromUsers from '../state/reducers';

@Component({
  selector: 'iot4bos-backoffice-ui-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['../../../style/admin.style.scss', './admin-users.component.scss']
})
export class AdminUsersComponent extends BaseComponent implements OnInit {
  analytics: AnalyticsService = new AnalyticsService('admin_users');
  adminUsersButtonList!: IotToolbarDefaultButton[];
  users$: Observable<PlatformResponse> = of(null);
  totalUsers$: Observable<number> = of(0);
  businessProfiles$: Observable<BusinessProfile[]>;
  pagination$: Observable<Pagination>;
  pagination: Pagination;
  usersPending$: Observable<boolean>;

  currentFilters: Filter[] = [];
  currentFilters$: Observable<Filter[]>;
  currentFavoriteView$: Observable<FavoriteView> = of(null);
  filterEngineOpened = false;

  canUpdateUserStatus: boolean;
  canCreateUser: boolean;
  canUpdateUser: boolean;
  canDeleteUser: boolean;

  userPermissions;
  mvSettings$: Observable<any>;
  name$ = this.translateService.get('ADMIN.USERS.TOOLBAR_TITLE');

  user!: User;
  toolbarSize: string = ToolbarSize.SMALL;

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly userPrefService: UserPreferencesService,
    protected readonly store: Store,
    protected readonly dialog: MatDialog,
    protected readonly authorizationService: AuthorizationService,
    protected readonly gridExportService: GridExportService,
    @Inject('environment') protected readonly environment
  ) {
    super();
    this.canUpdateUserStatus = this.authorizationService.applyAuthorization(AuthorizationConcept.USER_STATUS, AuthorizationType.UPDATE);
    this.canCreateUser = this.authorizationService.applyAuthorization(AuthorizationConcept.USER, AuthorizationType.CREATE);
    this.canUpdateUser = this.authorizationService.applyAuthorization(AuthorizationConcept.USER, AuthorizationType.UPDATE);
    this.canDeleteUser = this.authorizationService.applyAuthorization(AuthorizationConcept.USER, AuthorizationType.DELETE);
    this.userPermissions = [
      { key: 'canCreateUser', value: this.canCreateUser },
      { key: 'canUpdateUser', value: this.canUpdateUser },
      { key: 'canDeleteUser', value: this.canDeleteUser },
      { key: 'canUpdateUserStatus', value: this.canUpdateUserStatus }
    ];

    this.mvSettings$ = this.userPrefService.loadSpecificDefaultSettingsWithDefaultColumnsOnly('users').pipe(
      map((setting: any) => ({
        ...setting,
        metadata: {
          url: '/users'
        }
      }))
    );

    this.initToolbarButtonList();
  }

  ngOnInit() {
    this.users$ = this.store.pipe(select(fromUsers.getUsersAsPlatformResponse)).pipe(map((response) => ({ ...response, maxPage: response.maxPage - 1 })));
    this.businessProfiles$ = this.store.pipe(select(fromUsers.getBusinessProfiles));
    this.totalUsers$ = this.store.pipe(select(fromUsers.getTotal));
    this.currentFilters$ = this.store.pipe(select(fromUsers.getCurrentFilters));
    this.pagination$ = this.store.pipe(select(fromUsers.getPagination));
    this.totalUsers$ = this.pagination$.pipe(map((pagination) => pagination.total));
    this.usersPending$ = this.store.pipe(select(fromUsers.getUsersPagePending));

    this.subscriptions.push(
      this.currentFilters$.pipe(withLatestFrom(this.pagination$)).subscribe(([filters, pagination]) => {
        this.currentFilters = filters ?? [];
        this.store.dispatch(AdminUsersPageActions.listUsers({ request: { page: 0, limit: pagination.limit, filters: this.currentFilters } }));

        this.adminUsersButtonList.map((button) => {
          if (button.dispatchAction.type === IotToolbarDispatchActionType.CLEAR_FILTERS_AND_RELOAD_DATA) {
            button.disabled = !this.currentFilters.length;
          }
          return button;
        });
      }),
      this.usersPending$.subscribe((pending) => {
        this.adminUsersButtonList.map((button) => {
          if (button.dispatchAction.type === IotToolbarDispatchActionType.REFRESH_PAGE) {
            button.disabled = pending;
          }
          return button;
        });
      }),
      this.store.pipe(select(fromUserPreferences.getCurrentUser)).subscribe((user) => {
        if (user && user.id) {
          this.user = user;
          this.filterEngineOpened = get(user, 'preferences.filterEngineOpenByDefault', false);
        }
      })
    );
  }

  initToolbarButtonList(): void {
    this.adminUsersButtonList = [
      new IotToolbarDefaultButton({ ...ADD_BUTTON_CONFIG, displayButton: this.canCreateUser }, 0),
      new IotToolbarDefaultButton(REFRESH_BUTTON_CONFIG, 4),
      new IotToolbarDefaultButton(EXPORT_BUTTON_CONFIG, 5),
      new IotToolbarDefaultButton(TOGGLE_FILTER_ENGINE_BUTTON_CONFIG, 2)
    ];
  }

  onToolbarEvent(event: IotToolbarEvent) {
    switch (event.type) {
      case IotToolbarDispatchActionType.ADD_ELEMENT:
        this.addUser();
        break;
      case IotToolbarDispatchActionType.REFRESH_PAGE:
        this.onRefreshClicked();
        break;
      case IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE:
        this.onShowFilter();
        break;
      case IotToolbarDispatchActionType.EXPORT_DATA:
        this.onExportData();
        break;
      default:
        break;
    }
  }

  onExportData(): void {
    this.analytics.log('toolbar_actions', 'export_data');
    this.gridExportService.export({ filters: this.currentFilters });
  }

  addUser() {
    this.analytics.log('toolbar_actions', 'open_add_user');
    this.subscriptions.push(
      this.dialog
        .open(DialogUsersAddFormComponent, {
          width: '800px',
          disableClose: true,
          data: { externalEntityManager: this.environment.externalEntityManager }
        })
        .afterClosed()
        .subscribe((newUser) => {
          if (newUser) {
            this.analytics.log('toolbar_actions', 'add_user');
            this.store.dispatch(AdminUsersPageActions.addUser({ newUser }));
          }
        })
    );
  }

  onShowFilter(): void {
    if (this.user) {
      this.filterEngineOpened = !this.filterEngineOpened;
      this.user.preferences = { ...this.user.preferences, filterEngineOpenByDefault: this.filterEngineOpened };
      this.analytics.log('toolbar_actions', this.filterEngineOpened ? 'close_filter_engine' : 'open_filter_engine');
      this.store.dispatch(PreferencesActions.saveUserPreferences({ user: this.user, preferences: this.user.preferences }));
    }
  }

  onRefreshClicked(): void {
    this.analytics.log('toolbar_actions', 'refresh');
    this.reLoadMasterView();
  }

  reLoadMasterView(): void {
    const request: PlatformRequest = {
      page: 0,
      limit: 100,
      filters: this.currentFilters
    };
    this.store.dispatch(AdminUsersPageActions.listUsers({ request }));
  }

  onApplyFilters(filters: Filter[]) {
    this.currentFilters = filters;
    this.store.dispatch(AdminUsersPageActions.saveUsersFilters({ filters }));
  }

  onResetUser(user: User) {
    this.analytics.log('change_user_status', 'reset_user');
    this.store.dispatch(AdminUsersPageActions.resetUser({ user }));
  }

  onDeleteUser(user: User) {
    this.analytics.log('toolbar_actions', 'open_delete_user');
    this.subscriptions.push(
      this.dialog
        .open(PopupComponent, {
          width: '550px',
          disableClose: true,
          data: { type: 'delete', value: user.firstname + ' ' + user.lastname }
        })
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.analytics.log('toolbar_actions', 'delete_user');
            this.store.dispatch(AdminUsersPageActions.deleteUser({ user }));
          }
        })
    );
  }

  onChangeUserStatus(user: User): void {
    if (user.status.toLowerCase() === 'active') {
      this.analytics.log('change_user_status', 'disable_user_from_active');
      this.store.dispatch(AdminUsersPageActions.disableUser({ user }));
    }

    if (user.status.toLowerCase() === 'disabled') {
      this.analytics.log('change_user_status', 'activate_user');
      this.store.dispatch(AdminUsersPageActions.activateUser({ user }));
    }

    if (user.status.toLowerCase() === 'pending') {
      this.analytics.log('change_user_status', 'disable_user_from_pending');
      this.store.dispatch(AdminUsersPageActions.disableUser({ user }));
    }
  }

  openUserDetail(user: User): void {
    this.analytics.log('grid_actions', 'open_user_details');
    const userDetailPopup = this.dialog.open(UserDetailComponent, {
      width: '700px',
      disableClose: false,
      data: { user: user }
    });

    userDetailPopup.componentInstance.usernameClick.pipe().subscribe((user: User) => {
      userDetailPopup.close();
      this.analytics.log('user_popup_actions', 'navigate_to_user_details_from_user_popup');
      this.store.dispatch(AdminUsersPageActions.navigateToUser({ selectedUserId: user.id }));
    });
  }

  onDispatchMasterViewEngineEvent(event: MasterViewEngineEvent) {
    switch (event.type) {
      case 'open':
        this.store.dispatch(AdminUsersPageActions.selectUser({ selectedUserId: event.rawData.id }));
        this.store.dispatch(AdminUsersPageActions.getBusinessProfilesByUserId({ userId: event.rawData.id }));
        this.openUserDetail(event.rawData);
        break;
      case 'navigate':
        this.analytics.log('grid_actions', 'navigate_to_user_details');
        this.store.dispatch(AdminUsersPageActions.navigateToUser({ selectedUserId: event.rawData.id }));
        break;
      case 'enableUser':
        this.onChangeUserStatus(event.options.element);
        break;
      case 'disableUser':
        this.onChangeUserStatus(event.options.element);
        break;
      case 'resetUser':
        this.onResetUser(event.options.element);
        break;
      case 'deleteUser':
        this.onDeleteUser(event.options.element);
        break;
      default:
        break;
    }
  }

  loadMoreUsers(pagination: Pagination) {
    const request: PlatformRequest = {
      page: pagination.currentPage,
      limit: pagination.limit,
      filters: this.currentFilters
    };
    this.store.dispatch(AdminUsersPageActions.listUsers({ request }));
  }
}
