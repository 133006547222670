import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatSelectChange } from '@angular/material/select';
import {
  ADD_BUTTON_CONFIG,
  CONFIGURE_GRIDS_BUTTON_CONFIG,
  DELETE_BUTTON_CONFIG,
  EDIT_BUTTON_CONFIG,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  IotToolbarMenuButton
} from '@iot-platform/iot-platform-ui';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import { FavoriteView, IotToolbarEvent, ToolbarSize } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { SortUtil } from '../../../../../../shared/src/lib/utils/sort.util';
import {
  IotToolbarMenuButtonOption,
  ToolbarAutoRefreshOptions,
  ToolbarFilterTextOptions,
  ToolbarPageType
} from './models';

interface FVGroup {
  name: string;
  favoriteViews: FavoriteView[];
}

interface GridGroup {
  name: string;
  grids: I4BGrid<any, any>[];
  shared: boolean;
}

@Component({
  selector: 'iot-platform-ui-toolbar-v2',
  templateUrl: './toolbar-v2.component.html',
  styleUrls: ['./toolbar-v2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarV2Component implements OnInit, OnChanges, OnDestroy {
  @Input() name!: string;
  @Input() total!: number;
  @Input() pageTypeOptions: {
    visible: boolean;
    pageType: ToolbarPageType;
  } = { visible: false, pageType: ToolbarPageType.GRID };

  @Input() autoRefresh: ToolbarAutoRefreshOptions = { counter: 0, timeLeft: 0, displaySpinner: false };
  @Input() isDataLoaded = true;

  @Input() buttonList?: Array<IotToolbarDefaultButton | IotToolbarMenuButton> = [];

  @Input() favoriteViewConfiguration?: {
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  };
  @Input() gridsConfiguration?: {
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  };
  @Input() breadCrumbConfiguration?: { entityName: string; icon: string };

  @Input() filterText: Partial<ToolbarFilterTextOptions> = {
    visible: false,
    debounceTime: 300,
    minLength: 0,
    autoFocus: false
  };

  @Input() size: string = ToolbarSize.REGULAR;

  @Output() dispatchToolbarEvent: EventEmitter<IotToolbarEvent> = new EventEmitter<IotToolbarEvent>();

  groupedFavoriteViews: FVGroup[] = [
    { name: 'IOT_TOOLBAR.MY_FAVORITE_VIEWS', favoriteViews: [] },
    { name: 'IOT_TOOLBAR.SHARED', favoriteViews: [] }
  ];

  groupedGrids: GridGroup[] = [
    { name: 'IOT_TOOLBAR.MY_GRIDS', grids: [], shared: false },
    {
      name: 'IOT_TOOLBAR.SHARED',
      grids: [],
      shared: true
    }
  ];

  fvButtonList = [
    new IotToolbarDefaultButton(
      {
        ...ADD_BUTTON_CONFIG,
        tooltip: 'FILTER_ENGINE.CREATE_FAVORITE_VIEW',
        disabled: false,
        dispatchAction: { type: IotToolbarDispatchActionType.CREATE_FAVORITE_VIEW, options: undefined }
      },
      1
    ),
    new IotToolbarDefaultButton(
      {
        ...EDIT_BUTTON_CONFIG,
        tooltip: 'FILTER_ENGINE.EDIT_FAVORITE_VIEW',
        dispatchAction: { type: IotToolbarDispatchActionType.EDIT_FAVORITE_VIEW, options: undefined }
      },
      1
    ),
    new IotToolbarDefaultButton(
      {
        ...DELETE_BUTTON_CONFIG,
        tooltip: 'FILTER_ENGINE.DELETE_FAVORITE_VIEW',
        dispatchAction: { type: IotToolbarDispatchActionType.DELETE_FAVORITE_VIEW, options: undefined }
      },
      1
    )
  ];

  gridButtons: Partial<IotToolbarDefaultButton>[] = [
    new IotToolbarDefaultButton(
      {
        ...CONFIGURE_GRIDS_BUTTON_CONFIG,
        displayButton: true,
        tooltip: 'IOT_TOOLBAR.TOOLTIP.CONFIGURE_GRIDS.DEFAULT',
        dispatchAction: { type: IotToolbarDispatchActionType.MANAGE_GRID_SETTINGS, options: undefined }
      },
      1
    )
  ];
  filterTextControl: UntypedFormControl = new UntypedFormControl('');
  displayButtonSection = false;
  ToolbarPageType = ToolbarPageType;

  private readonly destroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.listenForFilterInputChanges();
    this.manageExportButton();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterText?.currentValue && changes.filterText.currentValue.defaultValue !== this.filterTextControl.getRawValue()) {
      this.filterTextControl.setValue(changes.filterText.currentValue.defaultValue, { emitEvent: false });
    }
    if (
      changes.hasOwnProperty('favoriteViewConfiguration') &&
      changes.favoriteViewConfiguration.currentValue &&
      !changes.favoriteViewConfiguration.currentValue.currentFavoriteView
    ) {
      this.setButtonStatus(this.buttonList);
    }

    if (changes.hasOwnProperty('buttonList') && changes.buttonList.currentValue) {
      this.buttonList = changes.buttonList.currentValue.sort(SortUtil.sortByOrder);
      this.buttonList = this.buttonList?.map((button) => {
        if (button instanceof IotToolbarMenuButton) {
          button.menuOptions = button.menuOptions.sort(SortUtil.sortByOrder);
        }
        return button;
      });
      if (this.buttonList) {
        this.setDisplayButtonSection(this.buttonList);
        this.setButtonStatus(this.buttonList);
      }
    }

    this.groupedFavoriteViews = this.favoriteViewConfiguration?.sortedFavoriteViews.reduce(
      (acc: FVGroup[], favoriteView: FavoriteView, index: number) => {
        if (favoriteView.shared) {
          acc.find((gr) => gr?.name === 'IOT_TOOLBAR.SHARED')?.favoriteViews.push(favoriteView);
        }
        if (!favoriteView.shared) {
          acc.find((gr) => gr?.name === 'IOT_TOOLBAR.MY_FAVORITE_VIEWS')?.favoriteViews.push(favoriteView);
        }
        return acc;
      },
      [
        { name: 'IOT_TOOLBAR.MY_FAVORITE_VIEWS', favoriteViews: [] },
        { name: 'IOT_TOOLBAR.SHARED', favoriteViews: [] }
      ]
    );

    this.groupedGrids = this.gridsConfiguration?.sortedGridsWithoutAppDefault.reduce(
      (acc: GridGroup[], grid: I4BGrid<any, any>, index: number) => {
        if (grid.businessProfileId !== null) {
          acc.find((gr) => gr?.name === 'IOT_TOOLBAR.SHARED')?.grids.push(grid);
        }
        if (grid.businessProfileId === null) {
          acc.find((gr) => gr?.name === 'IOT_TOOLBAR.MY_GRIDS')?.grids.push(grid);
        }
        return acc;
      },
      [
        { name: 'IOT_TOOLBAR.MY_GRIDS', grids: [], shared: false },
        { name: 'IOT_TOOLBAR.SHARED', grids: [], shared: true }
      ]
    );
  }

  setDisplayButtonSection(buttonList: Array<IotToolbarDefaultButton | IotToolbarMenuButton>): void {
    this.displayButtonSection = buttonList.reduce((acc: boolean, value) => {
      acc = acc || value.displayButton;
      return acc;
    }, false);
  }

  setButtonStatus(buttonList: Array<IotToolbarDefaultButton | IotToolbarMenuButton>): void {
    setTimeout(() => {
      buttonList.forEach((button) => {
        if (button instanceof IotToolbarMenuButton) {
          button.menuOptions.forEach((menuOption: IotToolbarMenuButtonOption) => {
            const idx = this.fvButtonList.findIndex((fvButton) => {
              return fvButton.tooltip === menuOption.label && fvButton.tooltip !== 'FILTER_ENGINE.CREATE_FAVORITE_VIEW';
            });
            this.fvButtonList[idx] = { ...this.fvButtonList[idx], disabled: menuOption.disableOption };
          });
        }
      });
    }, 1000);
  }

  onApplyFavoriteView(element: MatSelectChange) {
    if (this.buttonList) {
      this.setButtonStatus(this.buttonList);
    }
    this.dispatchToolbarEvent.emit({ type: IotToolbarDispatchActionType.APPLY_FAVORITE_VIEW, options: element.value ? element.value : null });
  }

  onApplyGrid(event: MatSelectChange) {
    this.dispatchToolbarEvent.emit({ type: IotToolbarDispatchActionType.APPLY_GRID, options: { grid: event.value } });
  }

  onTogglePageType(event: MatButtonToggleChange): void {
    this.pageTypeOptions.pageType = event.value;
    this.manageExportButton();
    this.dispatchToolbarEvent.emit({ type: IotToolbarDispatchActionType.TOGGLE_PAGE_TYPE, options: { pageType: event.value } });
  }

  private listenForFilterInputChanges(): void {
    this.filterTextControl.valueChanges
      .pipe(
        debounceTime(GetUtils.get(this.filterText, 'debounceTime', 300)),
        tap((term: string | null | undefined) => {
          if (term === null || term === undefined || !term.length || term.length < GetUtils.get(this.filterText, 'minLength', 0)) {
            this.dispatchToolbarEvent.emit({ type: IotToolbarDispatchActionType.FILTER_TEXT_CHANGE, options: null });
          }
        }),
        filter((term: string) => !!term && term.length >= GetUtils.get(this.filterText, 'minLength', 0))
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((term: string) => {
        this.dispatchToolbarEvent.emit({ type: IotToolbarDispatchActionType.FILTER_TEXT_CHANGE, options: term });
      });
  }

  manageExportButton(): void {
    this.buttonList?.map((button) => {
      if (button instanceof IotToolbarDefaultButton && button.dispatchAction.type === IotToolbarDispatchActionType.EXPORT_DATA) {
        button.disabled = this.pageTypeOptions.pageType === ToolbarPageType.MAP;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
