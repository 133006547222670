import { PlatformResponse } from '@iot-platform/models/common';
import { PoEventRule } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const listPOEventRulesSuccess = createAction('[POEvent Rules] list All PO Event Rules Success', props<{ response: PlatformResponse }>());
export const listPOEventRulesFailure = createAction('[POEvent Rules] list All PO Event Rules Failure', props<{ error: any }>());

export const loadPOEventRuleSuccess = createAction('[POEvent Rules] Load PO Event Rule Success', props<{ ruleLoaded: PoEventRule }>());
export const loadPOEventRuleFailure = createAction('[POEvent Rules] Load PO Event Rule Failure', props<{ error: any }>());

export const addPOEventRuleSuccess = createAction('[POEvent Rules] Add PO Event Rule Success', props<{ addedRule: PoEventRule }>());
export const addPOEventRuleFailure = createAction('[POEvent Rules] Add PO Event Rule Failure', props<{ error: any }>());

export const addPOEventRuleThenConfigureSuccess = createAction('[POEvent Rules] Add PO Event Rule Then Configure Success', props<{ addedRule: PoEventRule }>());
export const addPOEventRuleThenConfigureFailure = createAction('[POEvent Rules] Add PO Event Rule Then Configure Failure', props<{ error: any }>());

export const updatePOEventRuleSuccess = createAction('[POEvent Rules] Update PO Event Rule Success', props<{ updatedRule: PoEventRule }>());
export const updatePOEventRuleFailure = createAction('[POEvent Rules] Update PO Event Rule Failure', props<{ error: any }>());

export const deletePOEventRuleSuccess = createAction('[POEvent Rules] Delete PO Event Rule Success', props<{ deletedRule: PoEventRule }>());
export const deletePOEventRuleFailure = createAction('[POEvent Rules] Delete PO Event Rule Failure', props<{ error: any }>());
