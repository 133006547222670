  <ul *ngIf="contacts?.length > 0" style="overflow: auto; max-height: 224px; ">
    <li *ngFor="let contact of sortedContacts" class="detail-contact-card-item">
      <div class="detail-contact-card-container" fxLayout="column">
        <span class="detail-contact-card-item-info fw-600">{{ contact.firstname | infoDisplay }} {{ contact | infoDisplay: 'brackets':'lastname':'function' }}</span>
        <span class="detail-contact-card-item-info">{{ contact.phone | infoDisplay }}</span>
        <span class="detail-contact-card-item-info">{{ contact.email | infoDisplay }}</span>
      </div>
    </li>
  </ul>
<div *ngIf="contacts?.length === 0" class="detail-contact-card">
  <div class="detail-contact-card-empty" fxLayout="row" fxLayoutAlign="center center">
    {{ 'DETAIL_ASSETS_CARD_COMPONENT.NO_DATA' | translate }} {{ 'IOT_DICTIONARY.CONTACT' | translate }}
  </div>
</div>
