<div *ngIf="device" class="association-object-card" [ngClass]="{'selected': selectedItemId === device.id}" (click)="onClick()" fxLayout="row wrap">
  <div fxFlex="120px" *ngIf="!navigationMode"><img class="association-object-img" [src]="imgPath" alt="" /></div>
  <div fxFlex fxLayout="column" fxLayoutGap="6px" class="association-object-card-info">
    <span class="association-object-card-title">{{ device.name | infoDisplay }}</span>
    <div fxLayout="row" fxLayout.sm="column" fxLayoutGap="5px">
      <ul fxFlex fxLayout="column">
        <li fxLayout="row" fxLayoutGap="3px">
          <span fxFlex='13%' fxFlex.sm='20%' class="association-object-property">{{ 'SITES.ASSOCIATIONS.TYPE' | translate }}</span>
          <span fxFlex='87%' fxFlex.sm='80%' class="asset-card-value">{{ device.type | infoDisplay: 'hyphen':'family':'model' }}</span>
        </li>
        <li fxLayout="row" fxLayoutGap="3px">
          <span fxFlex='13%' fxFlex.sm='20%' class="association-object-property"> {{ 'SITES.ASSOCIATIONS.STATUS' | translate }}</span>
          <span fxFlex='87%' fxFlex.sm='80%' class="asset-card-value">{{ device.status.name | infoDisplay }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>
