import { Component, OnInit } from '@angular/core';
import { CustomCellParams } from '../custom-cell.params';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'grid-engine-asset-variable-threshold-cell',
  templateUrl: './asset-variable-threshold-cell.component.html',
  styleUrls: ['./asset-variable-threshold-cell.component.scss']
})
export class AssetVariableThresholdCellComponent implements ICellRendererAngularComp {
  params: CustomCellParams;
  thresholdValue?: number;

  constructor() {}

  agInit(params: CustomCellParams): void {
    this.params = params;
    if (!!this.params.value?.length && this.params.cellOptions?.position > 0) {
      this.thresholdValue = this.params.value.find((threshold) => threshold.position === this.params.cellOptions.position)?.value;
    } else {
      this.thresholdValue = null;
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
