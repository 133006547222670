<div  fxFlex fxLayoutGap="10px" fxLayout="column">
<!--      <iot-platform-ui-filter-engine-container-->
<!--        [masterView]="eventType"-->
<!--        [expanded]="true"-->
<!--        [displayActionButtons]="true"-->
<!--        [withFavoriteFilters]="true"-->
<!--        [currentFavoriteView$]="currentFavoriteView$"-->
<!--        [currentFilters$]="currentFilters$"-->
<!--        (applyFilters)="applyFilters($event)"-->
<!--      >-->
<!--      </iot-platform-ui-filter-engine-container>-->

  <mat-sidenav-container fxFlex (backdropClick)="onCloseComments()" hasBackdrop="true">
    <mat-sidenav-content class="layout-master-view-engine-container">
      <grid-engine-grid-manager
        style="height: 100%"
        [grid]="grid$ | async"
        [gridSort]="gridSort$ | async"
        [currentFilters]="currentFilters"
        [userPermissions]="userPermissions"
        (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
      ></grid-engine-grid-manager>
    </mat-sidenav-content>

    <mat-sidenav class="sidenav" #sidenav position="end" disableClose>
      <iot4bos-ui-event-comments
        [eventType]="eventType"
        [resetLogs$]="resetLogs$"
        [canUpdateEvent]="canUpdateEvent"
        [logsByAssetEvent]="logsByAssetEvent$ | async"
        [logsByDeviceEvent]="logsByDeviceEvent$ | async"
        (addComment)="onAddComment($event)"
        (closeComments)="onCloseComments()"
      >
      </iot4bos-ui-event-comments>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
