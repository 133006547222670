import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ListDisplayModule } from 'libs/iot4bos-ui/src/lib/components/list-display/list-display.module';
import { MultiSelectChipModule } from '../../../../components/multi-select-chip/multi-select-chip.module';
import { SiteContactComponent } from './site-contact.component';
import { IotPlatformPipesModule } from '@iot-platform/pipes';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    IotPlatformPipesModule,
    MultiSelectChipModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatMenuModule,
    MatButtonModule,
    ListDisplayModule,
    MatProgressSpinnerModule
  ],
  declarations: [SiteContactComponent],
  exports: [SiteContactComponent]
})
export class SiteContactModule {}
