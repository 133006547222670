import { DynamicDataResponse } from '@iot-platform/models/common';
import * as Leaflet from 'leaflet';
import { get } from 'lodash';
import { IotGeoJsonFeature } from './iot-geo-json-object.model';

export type IotMapMarkerPopupRawData = {
  data: DynamicDataResponse | undefined;
  feature: IotGeoJsonFeature | undefined;
};

export interface IotMapMarkerPopupTemplateRow {
  label: string;
  cb: (rawData: IotMapMarkerPopupRawData) => unknown;
}

export interface IotMapMarkerPopupOptions extends Leaflet.PopupOptions {
  data: DynamicDataResponse;
  feature: IotGeoJsonFeature;
  loadData: boolean;
  displayPopup: boolean;
}

export class IotMapMarkerPopup extends Leaflet.Popup {
  loadData: boolean;
  displayPopup: boolean;

  constructor(options?: Partial<IotMapMarkerPopupOptions>) {
    super(options);
    this._rawData = {
      data: get(options, 'data'),
      feature: get(options, 'feature')
    };
    this.loadData = get(options, 'loadData', false);
    this.displayPopup = get(options, 'displayPopup', true);
    this.options.minWidth = get(options, 'minWidth', 250);
  }

  private _rawData: IotMapMarkerPopupRawData;

  get rawData(): IotMapMarkerPopupRawData {
    return this._rawData;
  }

  set rawData(rawData: IotMapMarkerPopupRawData) {
    this._rawData = {
      data: rawData.data,
      feature: rawData.feature
    };
  }

  private _templateRows: IotMapMarkerPopupTemplateRow[] = [];

  get templateRows(): IotMapMarkerPopupTemplateRow[] {
    return this._templateRows;
  }

  set templateRows(templateRows: IotMapMarkerPopupTemplateRow[]) {
    this._templateRows = templateRows;
  }

  get content(): string {
    return this._templateRows
      .map(({ label, cb }) => {
        return `
       <p class='leaflet-popup-content__section'>
        <label class='leaflet-popup-content__section_label'><span>${label}</span> <span>:</span></label>
        <b class='leaflet-popup-content__section_value'>${cb(this.rawData) ?? '-'}</b>
       </p>
      `;
      })
      .join('');
  }

  build(): IotMapMarkerPopup {
    this.setContent(this.content);
    return this;
  }

  addTemplateRow(label: string, cb: (rawData: IotMapMarkerPopupRawData) => any): IotMapMarkerPopup {
    this._templateRows.push({
      label,
      cb
    });
    return this;
  }
}
