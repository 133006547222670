<section class='site-stocks'>
  <iot4bos-ui-panel
    [title]="'SITES.STOCKS.PANEL_TITLE' | translate"
    [hasButton]='false'
    [withCustomAction]='false'
  >
    <ng-container panelGrid *ngIf="!loading && !hasStocks; else stocksTmpl">
      <ng-container *ngTemplateOutlet="emptyStocksTmp"></ng-container>
    </ng-container>

  </iot4bos-ui-panel>
</section>

<ng-template #stocksTmpl>
  <div panelGrid *ngIf="loading" fxFlexLayout='row' fxLayoutAlign='center center' style='width: 100%'>
    <mat-progress-spinner color='accent' mode='indeterminate' [diameter]='50'></mat-progress-spinner>
  </div>

  <i4b-table-engine-master-view-engine panelGrid *ngIf="!loading"
     [masterViewType]=" { name: 'site-stocks', isPath: false, useExternalMetadata: false }"
     [useExternalData]="true"
     [data]="stocksAsPlatformResponse"
     [userPermissions]="userPermissions"
     (pageChange)="onPageChange($event)"
     (dispatchMasterViewEngineEvent)="onDispatchMasterViewEngineEvent($event)"
  >
  </i4b-table-engine-master-view-engine>
</ng-template>

<ng-template #emptyStocksTmp>
  <div class="no-data-message" fxFlexLayout='row' fxFlexAlign='center center'>
    <p>{{ 'SITES.STOCKS.NO_STOCKS' | translate }}</p>
  </div>
</ng-template>
