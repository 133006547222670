import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Site } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot-platform-ui-site-details-full-info',
  templateUrl: './site-details-full-info.component.html',
  styleUrls: ['./site-details-full-info.component.scss']
})
export class SiteDetailsFullInfoComponent implements OnInit {
  @Input() isSiteLoaded: boolean = false;

  @Input() site: Site | null = null;
  @Output() selectSite: EventEmitter<Site> = new EventEmitter<Site>();

  constructor() {}

  ngOnInit(): void {}
}
