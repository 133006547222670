import { Action, combineReducers } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';

import * as fromAdminBusinessProfiles from './admin-business-profiles.reducer';

export const featureKey = 'oyanAdminBusinessProfiles';

export interface AdminState {
  [fromAdminBusinessProfiles.featureKey]: fromAdminBusinessProfiles.State;
}

export interface State {
  [featureKey]: AdminState;
}

export function reducers(state: AdminState, action: Action): ActionReducer<AdminState> | unknown {
  return combineReducers({
    [fromAdminBusinessProfiles.featureKey]: fromAdminBusinessProfiles.reducer
  })(state, action);
}
