import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { SharedModule } from '@iot-platform/shared';
import { EditTagLabelDialogComponent } from './edit-tag-label-dialog/edit-tag-label-dialog.component';
import { TagEditorFormComponent } from './tag-editor-form/tag-editor-form.component';
import { TagEditorComponent } from './tag-editor.component';
import { TagEditorService } from './tag-editor.service';

@NgModule({
    declarations: [TagEditorComponent, TagEditorFormComponent, EditTagLabelDialogComponent],
    providers: [TagEditorService],
    imports: [CommonModule, SharedModule, FlexModule],
    exports: [TagEditorComponent]
})
export class TagEditorModule {}
