import { BusinessProfile, PlatformResponse, User } from '@iot-platform/models/common';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export const listUsersSuccess = createAction('[Users API] List Users Success', props<{ response: PlatformResponse }>());
export const listUsersFailure = createAction('[Users API] List Users Failure', props<{ error: any }>());

export const addUserSuccess = createAction('[Users API] Add User Success', props<{ addedUser: User }>());
export const addUserFailure = createAction('[[Users API] Add User Failure', props<{ error: any }>());

export const activateUserSuccess = createAction('[Users API] Activate User Success', props<{ updatedUser: Update<User> }>());
export const activateUserFailure = createAction('[Users API] Activate User Failure', props<{ error: any }>());

export const disableUserSuccess = createAction('[Users API] Disable User Success', props<{ updatedUser: Update<User> }>());
export const disableUserFailure = createAction('[Users API] Disable User Failure', props<{ error: any }>());

export const resetUserSuccess = createAction('[Users API] Reset User Success', props<{ updatedUser: Update<User> }>());
export const resetUserFailure = createAction('[Users API] Reset User Failure', props<{ error: any }>());

export const updateUserSuccess = createAction('[Users API] Update User Success', props<{ updatedUser: Update<User> }>());
export const updateUserFailure = createAction('[Users API] Update User Failure', props<{ error: any }>());

export const deleteUserSuccess = createAction('[Users API] Delete User Success', props<{ deletedUserId: string }>());
export const deleteUserFailure = createAction('[Users API] Delete User Failure', props<{ error: any }>());

export const selectUserSuccess = createAction('[Users API] Select User Success', props<{ selectedUser: User }>());
export const selectUserFailure = createAction('[[Users API] Select User Failure', props<{ error: any }>());

export const loadUserByIdSuccess = createAction('[Users API] Load User By Id Success', props<{ user: User }>());
export const loadUserByIdFailure = createAction('[[Users API] Load User By Id Failure', props<{ error: any }>());

export const filterUsersByStatusSuccess = createAction('[Users/API] Filter Users By Status Success', props<{ response: PlatformResponse }>());
export const filterUsersByStatusFailure = createAction('[Users/API] Filter Users By Status Failure', props<{ error: any }>());

export const getBusinessProfilesByUserIdSuccess = createAction(
  '[Users/Admin Page] GET Business Profiles By User Id Success',
  props<{ businessProfiles: BusinessProfile[] }>()
);
export const getBusinessProfilesByUserIdFailure = createAction('[Users/Admin Page] GET Business Profiles By User Id Failure', props<{ error: any }>());
