import { ICellRendererAngularComp } from '@ag-grid-community/angular';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'grid-engine-user-cell',
  templateUrl: './user-cell.component.html',
  styleUrls: ['./user-cell.component.scss']
})
export class UserCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
