import { createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';
import { DeviceEventsDbActions, DeviceEventsUiActions } from '../../../device-events/+state/actions';
import { SitesDbActions } from '../actions';

export const sitesUiFeatureKey = 'sitesUi';

export interface State {
  siteLoaded: boolean;
  siteLoading: boolean;
  assetsLoaded: boolean;
  assetsLoading: boolean;
  devicesLoaded: boolean;
  devicesLoading: boolean;
  tagsLoaded: boolean;
  tagsLoading: boolean;
  error: any;
}

export const initialState: State = {
  siteLoaded: false,
  siteLoading: false,
  assetsLoaded: false,
  assetsLoading: false,
  devicesLoaded: false,
  devicesLoading: false,
  tagsLoaded: false,
  tagsLoading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(SitesDbActions.loadSiteById, (state: State) => ({ ...state, siteLoaded: false, siteLoading: true })),
  on(SitesDbActions.loadSiteByIdSuccess, (state: State) => ({ ...state, siteLoaded: true, siteLoading: false })),
  on(SitesDbActions.loadSiteByIdFailure, (state: State, { error }) => ({ ...state, siteLoaded: false, siteLoading: false, error })),
  //
  on(SitesDbActions.loadAssetsBySiteId, (state: State) => ({ ...state, assetsLoaded: false, assetsLoading: true })),
  on(SitesDbActions.loadAssetsBySiteIdSuccess, (state: State) => ({ ...state, assetsLoaded: true, assetsLoading: false })),
  on(SitesDbActions.loadAssetsBySiteIdFailure, (state: State, { error }) => ({ ...state, assetsLoaded: false, assetsLoading: false, error })),
  //
  on(SitesDbActions.loadDevicesBySiteId, (state: State) => ({ ...state, devicesLoaded: false, devicesLoading: true })),
  on(SitesDbActions.loadDevicesBySiteIdSuccess, (state: State) => ({ ...state, devicesLoaded: true, devicesLoading: false })),
  on(SitesDbActions.loadDevicesBySiteIdFailure, (state: State, { error }) => ({ ...state, devicesLoaded: false, devicesLoading: false, error })),
  //
  on(SitesDbActions.loadTagsBySiteId, (state: State) => ({ ...state, tagsLoaded: false, tagsLoading: true })),
  on(SitesDbActions.loadTagsBySiteIdSuccess, (state: State) => ({ ...state, tagsLoaded: true, tagsLoading: false })),
  on(SitesDbActions.loadTagsBySiteIdFailure, (state: State, { error }) => ({ ...state, tagsLoaded: false, tagsLoading: false, error })),
  //
  on(SitesDbActions.loadSites, (state: State) => ({ ...state, siteLoading: true, siteLoaded: false })),
  on(SitesDbActions.loadSitesSuccess, (state: State) => ({ ...state, siteLoading: false, siteLoaded: true })),
  on(SitesDbActions.loadSitesFailure, (state: State, { error }) => ({ ...state, siteLoading: false, siteLoaded: false, error })),
  //
  on(SitesDbActions.addSite, (state: State) => ({ ...state, siteLoading: true })),
  on(SitesDbActions.addSiteSuccess, (state: State) => ({ ...state, siteLoading: false, siteLoaded: true })),
  on(SitesDbActions.addSiteFailure, (state: State, { error }) => ({ ...state, siteLoading: false, siteLoaded: false, error })),
  //
  on(SitesDbActions.updateTagsBySiteId, (state: State) => ({ ...state, tagsLoading: true, tagsLoaded: false })),
  on(SitesDbActions.updateTagsBySiteIdSuccess, (state: State) => ({ ...state, tagsLoading: false, tagsLoaded: true })),
  on(SitesDbActions.updateTagsBySiteIdFailure, (state: State, error) => ({ ...state, tagsLoaded: false, tagsLoading: false, error })),
  //
  on(SitesDbActions.loadMVSettings, (state: State) => ({ ...state, siteLoading: true, siteLoaded: false })),
  on(SitesDbActions.loadMVSettingsSuccess, (state: State) => ({ ...state, siteLoading: false, siteLoaded: true })),
  on(SitesDbActions.loadMVSettingsFailure, (state: State, { error }) => ({ ...state, siteLoading: false, siteLoaded: true, error })),
  // ****
  on(SitesDbActions.saveMVSettings, (state: State) => ({ ...state, siteLoading: true, siteLoaded: false })),
  on(SitesDbActions.saveMVSettingsSuccess, (state: State) => ({ ...state, siteLoading: false, siteLoaded: true })),
  on(SitesDbActions.saveMVSettingsFailure, (state: State, { error }) => ({ ...state, siteLoading: false, siteLoaded: true, error })),

  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export const getSiteLoading = (state: State) => state.siteLoading;
export const getSiteLoaded = (state: State) => state.siteLoaded;
export const getAssetsLoading = (state: State) => state.assetsLoading;
export const getAssetsLoaded = (state: State) => state.assetsLoaded;
export const getDevicesLoading = (state: State) => state.devicesLoading;
export const getDevicesLoaded = (state: State) => state.devicesLoaded;
export const getTagsLoading = (state: State) => state.tagsLoading;
export const getTagsLoaded = (state: State) => state.tagsLoaded;
export const getError = (state: State) => state.error;
