import { I4BCellType, I4BDynamicIconColumnConfiguration } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { I4bCellConcept } from '../enums/i4b-cell-concept.enum';
import { I4BBasicColumnHeader } from '../headers/i4b-basic-column-header';
import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';
import { BasicColumnOptions } from '../options/i4b-basic-column-options';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BColumn } from './i4b-column';

export class DynamicIconColumn implements I4BColumn<I4BBasicColumnHeader, I4BDynamicIconColumnConfiguration, I4BColumnOptions> {
  columnId = '1a8dec64-aabe-11ec-858e-acde48001122-dynamic-icon';
  className = DynamicIconColumn;
  dataLibs = [DataLibrary.DEVICE_EVENTS, DataLibrary.ASSET_EVENTS];

  header: I4BBasicColumnHeader = new I4BBasicColumnHeader({ displayName: 'DELAY', type: HeaderType.ICON, icon: 'delay' });
  configuration: I4BDynamicIconColumnConfiguration = {
    isLink: false,
    position: '',
    id: 'delay',
    sortProperty: 'delay',
    concept: I4bCellConcept.EVENTS,
    isDefault: true,
    isConfigurable: false,
    width: 40,
    cell: {
      type: I4BCellType.DYNAMIC_ICON_CELL,
      options: { icon: 'delay', class: 'table-icon-content', tooltip: { key: 'occurrenceTime' } }
    }
  };
  options: I4BColumnOptions = new BasicColumnOptions({ sortable: true });

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BDynamicIconColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    this.header = {
      ...this.header,
      ...customHeader,
      displayName: customOptions.overrideHeaderName ? customOptions.overrideHeaderName : customHeader.displayName ? customHeader.displayName : 'DELAY'
    };

    this.configuration = { ...this.configuration, ...customConfig };

    this.options = { ...this.options, ...customOptions };
  }
}
