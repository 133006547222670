import { Component } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';

import { ConditionProcessorUtil } from './../../../../../../../table-engine/src/lib/utils/condition-processor';

type CallToActionHeaderParams = IHeaderParams & { visibleConditions: []; bulkActions: []; userPermissions: []; dispatchEvent: Function };

@Component({
  selector: 'grid-engine-call-to-action-header',
  templateUrl: './call-to-action-header.component.html',
  styleUrls: ['./call-to-action-header.component.scss']
})
export class CallToActionHeaderComponent implements IHeaderAngularComp {
  params: CallToActionHeaderParams;
  totalSelected: number;
  bulkActions = [];
  visibleConditions: [];
  bulkActionVisibility: [];
  userPermissions = [];
  isCallToActionVisible = true;

  agInit(params: CallToActionHeaderParams): void {
    this.params = params;
    this.visibleConditions = params.visibleConditions;
    this.bulkActions = params.bulkActions;
    this.userPermissions = params.userPermissions;
    this.totalSelected = this.params.api.getSelectedRows().length;
    this.isCallToActionVisible = ConditionProcessorUtil.processConditionsWithPermission(this.visibleConditions, this.userPermissions);

    this.bulkActionVisibility = this.bulkActions?.reduce((acc, value) => {
      acc[value.key] = ConditionProcessorUtil.processConditionsWithPermission(value.visibleConditions, this.userPermissions);
      return acc;
    }, {});
  }

  refresh(): boolean {
    return false;
  }

  onBulkActionClick(type: string): void {
    this.params.dispatchEvent({ type, rawData: this.params.api.getSelectedRows(), options: {} });
  }
}
