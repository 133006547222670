import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BuildInfoService {
  constructor(private httpClient: HttpClient) {}

  getBuildInfo(): Observable<any> {
    return this.httpClient.get(`assets/data/build.json`).pipe(
      map(data => {
        return data;
      })
    );
  }
}
