import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';

import * as fromMap from './map.reducer';

export const featureKey = 'iotPlatformMaps';

export interface MapsState {
  [fromMap.featureKey]: fromMap.State;
}

export interface State {
  [featureKey]: MapsState;
}

export const selectState = createFeatureSelector<MapsState>(featureKey);
export const selectMapState = createSelector(selectState, (state: MapsState) => state[fromMap.featureKey]);

export function reducers(state: MapsState, action: Action): ActionReducer<MapsState> | unknown {
  return combineReducers({
    [fromMap.featureKey]: fromMap.reducer
  })(state, action);
}

export const mapUiState = createSelector(selectMapState, (state: fromMap.State) => state.mapUiState);
