import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Entity, User } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { UsersService } from '../../services/users.service';

import * as fromAdminUsers from '../../state/reducers';

@Component({
  selector: 'iot4bos-backoffice-ui-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {
  @Output() usernameClick: EventEmitter<User> = new EventEmitter<User>();
  selectedUser: User | undefined;
  loadingBusinessProfiles = false;

  subs: Subscription[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { user: User; source: string }, private usersStore: Store, private readonly usersService: UsersService) {}

  ngOnInit() {
    if (this.data.source && this.data.user.id) {
      this.subs.push(
        this.usersService.getById(this.data.user.id).subscribe((user) => {
          this.selectedUser = user;
          this.loadingBusinessProfiles = true;
        })
      );
    } else {
      this.subs.push(
        this.usersStore.select(fromAdminUsers.getSelectedUser).subscribe((user) => {
          this.selectedUser = user;
          this.loadingBusinessProfiles = true;
        })
      );
    }
  }

  getFormattedEntities(entities: Entity[]): string {
    return entities?.map((entity) => entity.name).join(', ');
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  onUsernameClick(user: User) {
    this.usernameClick.emit(user);
  }
}
