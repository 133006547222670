import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { FavoriteView, Filter, MasterViewEngineEvent } from '@iot-platform/models/common';
import { AssetEventsGridData, DeviceEventsGridData, I4BGrid, I4BGridOptions } from '@iot-platform/models/grid-engine';
import {
  Asset,
  AssetEvent,
  AssetVariable,
  Device,
  DeviceEvent,
  DeviceVariable,
  Log,
  PoEventRule,
  Site
} from '@iot-platform/models/i4b';
import { WidgetOptions } from '@iot-platform/models/widgets';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { GridsDbActions } from '../../../../../../../grid-engine/src/lib/components/state/actions';
import * as fromGrids from '../../../../../../../grid-engine/src/lib/components/state/reducers';
import { PopupComponent } from '../../../../../../../iot-platform-ui/src/lib/ui/components/popup/popup.component';
import {
  VariableChartDialogComponent
} from '../../../../../../../shared/src/lib/variable-chart/variable-chart-dialog/variable-chart-dialog.component';
import { EventDetailPopupComponent } from '../../../../components/event-detail-popup/event-detail-popup.component';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { AssetEventsApi } from '../../../asset-events/+state/asset-events.api';
import { DeviceEventsApi } from '../../../device-events/+state/device-events.api';

@Component({
  selector: 'iot4bos-ui-po-event-generated-events-tab',
  templateUrl: './po-event-generated-events-tab.component.html',
  styleUrls: ['./po-event-generated-events-tab.component.scss']
})
export class PoEventGeneratedEventsTabComponent implements OnInit, OnChanges, OnDestroy {
  @Input() userPermissions: Array<{ key: string; value: boolean }> = [];
  @Input() rule!: PoEventRule;

  @Output() navigateToSite: EventEmitter<{ rowId: string; site: Site }> = new EventEmitter();
  @Output() navigateToAsset: EventEmitter<{ rowId: string; asset: Asset; site: Site }> = new EventEmitter();
  @Output() navigateToDevice: EventEmitter<{ rowId: string; device: Device; site: Site }> = new EventEmitter();

  @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;

  grid$!: Observable<I4BGrid<I4BGridOptions, AssetEventsGridData> | I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined>;
  grid: I4BGrid<I4BGridOptions, AssetEventsGridData> | I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined;

  gridSort$;
  gridConcept!: string;
  selectedEvent$ = this.store.pipe(select(fromGrids.getSelectedItemInSelectedGrid)) as Observable<AssetEvent | DeviceEvent | undefined>;

  eventType!: string;
  reloadMasterViewAfterClose = false;

  // logs
  logsByAssetEvent$: Observable<Log[]> = of([]);
  logsByDeviceEvent$: Observable<Log[]> = of([]);

  currentFilters$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);
  mandatoryFilters: Filter[] = [];
  currentFilters: Filter[] = [];
  currentFavoriteView$: Observable<FavoriteView | null> = of(null);
  resetLogs$ = new Subject<boolean>();

  canUpdateEvent = false;

  destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly dialog: MatDialog,
    @Inject('environment') private readonly environment,
    private readonly assetEventsApi: AssetEventsApi,
    private readonly deviceEventsApi: DeviceEventsApi,
    private readonly navigationApi: NavigationApi,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentFilters$.pipe(takeUntil(this.destroy$)).subscribe((filters: Filter[]) => {
      if (!!this.rule && this.canUpdateEvent) {
        this.currentFilters = [...filters, ...this.mandatoryFilters];

        this.store.dispatch(
          GridsDbActions.selectGridAndLoadData({
            gridId: 'default',
            masterview: this.gridConcept,
            filters: this.currentFilters
          })
        );
      }
    });
    this.logsByAssetEvent$ = this.assetEventsApi.logs$;
    this.logsByDeviceEvent$ = this.deviceEventsApi.logs$;
    this.grid$
      .pipe(takeUntil(this.destroy$))
      .subscribe((grid: I4BGrid<I4BGridOptions, AssetEventsGridData> | I4BGrid<I4BGridOptions, DeviceEventsGridData> | undefined) => (this.grid = grid));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes?.rule?.currentValue) {
      this.mandatoryFilters = [{ criteriaKey: 'poEventRuleId', value: changes.rule.currentValue.id, isHidden: true }];

      this.selectGridAndLoadDataByConcept(changes.rule.currentValue);
    }

    if (!!changes?.userPermissions?.currentValue) {
      this.canUpdateEvent = !!changes?.userPermissions?.currentValue.find((p: { key: string; value: boolean }) => p.key === 'canReadEvent')?.value;
    }
  }

  private selectGridAndLoadDataByConcept(rule: PoEventRule): void {
    if (rule.concept?.toLowerCase() === 'asset-variable') {
      this.gridConcept = 'po-event-generated-asset-events';
      this.eventType = 'asset-events';
      this.grid$ = this.store.pipe(select(fromGrids.getDefaultPoEventGeneratedAssetEventsGrid));
    } else {
      this.gridConcept = 'po-event-generated-device-events';
      this.eventType = 'device-events';
      this.grid$ = this.store.pipe(select(fromGrids.getDefaultPoEventGeneratedDeviceEventsGrid));
    }

    this.gridSort$ = this.grid$.pipe(
      switchMap((grid) => {
        if (grid) {
          return this.store.select(fromGrids.getSortByGrid(grid.id as string));
        } else {
          return of([]);
        }
      })
    );

    this.store.dispatch(
      GridsDbActions.selectGridAndLoadData({
        gridId: 'default',
        masterview: this.gridConcept,
        filters: this.currentFilters
      })
    );
  }

  applyFilters(filters: Filter[]) {
    this.currentFilters$.next(filters);
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    switch (event.type) {
      case 'open':
        this.onSelectEvent(event.rawData.id);
        this.openEventDetail(event.rawData);
        break;
      case 'snooze':
      case 'acknowledge':
      case 'close':
        this.onSelectEvent(event.rawData.id);
        this.onUpdateStatus([event.rawData], event.type);
        break;
      case 'bulkSnooze':
        const eventsToSnooze = event.rawData.filter((e: AssetEvent) => e.status === 'active' && e.snoozeQuota !== 0);
        this.onBulkStatusUpdate(eventsToSnooze, 'snooze');
        break;
      case 'bulkAcknowledge':
        const eventsToAcknowledge = event.rawData.filter((e: AssetEvent) => e.status === 'active');
        this.onBulkStatusUpdate(eventsToAcknowledge, 'acknowledge');
        break;
      case 'bulkClose':
        const eventsToClose = event.rawData.filter((e: AssetEvent) => e.status === 'acknowledged');
        this.onBulkStatusUpdate(eventsToClose, 'close');
        break;

      case 'openComments':
        this.onSelectEvent(event.rawData.id);
        this.onOpenComments(event.rawData, true);
        break;
      case 'navigateToAsset':
        this.navigationApi.openAssetDetail({ ...event.rawData.context.asset, site: { ...event.rawData.context.site } }, `po-event-rules/${this.rule.id}`);
        break;
      case 'navigateToDevice':
        this.navigationApi.openDeviceDetail({ ...event.rawData.context.device, site: { ...event.rawData.context.site } }, `po-event-rules/${this.rule.id}`);
        break;
      case 'openGraph':
        this.openGraph(event.rawData);
        break;
      default:
        break;
    }
  }

  onSelectEvent(itemId: string) {
    if (this.grid && itemId) {
      this.store.dispatch(GridsDbActions.selectItemInGridData({ gridId: this.grid.id as string, itemId }));
    }
  }

  onUpdateStatus(events: AssetEvent[] | DeviceEvent[], status: string) {
    if (this.eventType === 'asset-events') {
      this.assetEventsApi.updateStatusByAssetEventId(
        events.map((assetEvent) => assetEvent.id),
        status
      );
    } else {
      this.deviceEventsApi.updateStatusByDeviceEventId(
        events.map((deviceEvent) => deviceEvent.id),
        status
      );
    }
    this.reloadMasterViewAfterClose = true;
  }

  onBulkStatusUpdate(events: AssetEvent[] | DeviceEvent[], status: string) {
    this.dialog
      .open(PopupComponent, {
        data: {
          type: 'confirm',
          value: this.translateService.instant('EVENTS.BULK_STATUS_UPDATE.CONFIRMATION_MESSAGES.' + status.toUpperCase(), { total: events.length })
        },
        disableClose: true,
        width: '600px'
      })
      .afterClosed()
      .subscribe((confirmation: boolean) => {
        if (confirmation && events.length) {
          this.onUpdateStatus(events, status);
        }
      });
  }

  onOpenComments(event: AssetEvent | DeviceEvent, loadComments: boolean) {
    if (loadComments) {
      this.eventType === 'asset-events' ? this.assetEventsApi.loadLogsByAssetEventId(event.id) : this.deviceEventsApi.loadLogsByDeviceEventId(event.id);
      this.resetLogs$.next(true);
    }
    this.sidenav.open();
  }

  private transformToArray(event: MasterViewEngineEvent) {
    return Array.isArray(event.rawData) ? event.rawData : [event.rawData];
  }

  openEventDetail(event: AssetEvent | DeviceEvent) {
    if (this.eventType === 'asset-events') {
      this.assetEventsApi.loadEventDetailPopupDataByAssetEvent(event);
      this.assetEventsApi.loadLogsByAssetEventId(event.id);
    } else {
      this.deviceEventsApi.loadEventDetailPopupDataByDeviceEvent(event);
      this.deviceEventsApi.loadLogsByDeviceEventId(event.id);
    }

    const detailPopup = this.dialog.open(EventDetailPopupComponent, {
      width: '1100px',
      disableClose: false,
      data: { eventType: this.eventType, canUpdateEvent: this.canUpdateEvent, gridId: this.grid.id, event: event }
    });

    detailPopup.componentInstance.updateStatus.subscribe((status: string) => {
      this.onSelectEvent(event.id);
      this.eventType === 'asset-events'
        ? this.assetEventsApi.updateStatusByAssetEventId([event.id], status)
        : this.deviceEventsApi.updateStatusByDeviceEventId([event.id], status);

      this.reloadMasterViewAfterClose = true;
    });

    detailPopup.componentInstance.addComment.subscribe((value: string) => {
      this.onSelectEvent(event.id);
      this.onAddComment(value);
    });

    detailPopup.componentInstance.navigateToSite.subscribe((site: Site) => {
      this.navigationApi.openSiteDetail(site, `po-event-rules/${this.rule.id}`);
      detailPopup.close();
    });
  }

  onAddComment(value: string) {
    const sub: Subscription = this.selectedEvent$.pipe(take(1)).subscribe((event) => {
      if (event) {
        event.totalComments += 1;

        if (this.eventType === 'asset-events') {
          this.assetEventsApi.createLogByAssetEventId({ assetEvent: event, value });
        } else {
          this.deviceEventsApi.createLogByDeviceEventId({ deviceEvent: event, value });
        }
      }
    });
    sub.unsubscribe();
  }

  onCloseComments() {
    this.sidenav.close();
  }

  openGraph(event: AssetEvent | DeviceEvent) {
    let data: { variables: DeviceVariable[] | AssetVariable[]; variableType: string; title?: string; options?: WidgetOptions };
    if (this.eventType === 'asset-events') {
      data = {
        variables: [
          {
            ...event.context.assetVariable,
            asset: { id: event.context.asset?.id, name: event.context.asset?.name }
          } as unknown as AssetVariable
        ],
        variableType: 'assetVariable'
      };
    } else {
      data = {
        variables: [
          {
            ...event.context.deviceVariable,
            device: { id: event.context.device?.id, name: event.context.device?.name }
          } as unknown as DeviceVariable
        ],
        variableType: 'deviceVariable'
      };
    }

    this.dialog.open(VariableChartDialogComponent, {
      width: '990px',
      data
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
