import { Action, combineReducers } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';

import * as fromAssets from './assets.reducer';

export const featureKey = 'oyanAssets';

export interface AssetsState {
  [fromAssets.featureKey]: fromAssets.State;
}

export interface State {
  [featureKey]: AssetsState;
}

export function reducers(state: AssetsState, action: Action): ActionReducer<AssetsState> | unknown {
  return combineReducers({
    [fromAssets.featureKey]: fromAssets.reducer
  })(state, action);
}
