import { SpreadsheetExport } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const getSpreadsheetExportSuccess = createAction('[SpreadsheetExports] get Spreadsheet Export Success', props<{ response: SpreadsheetExport }>());
export const getSpreadsheetExportFailure = createAction('[SpreadsheetExports] get Spreadsheet Export Failure', props<{ contactId: string; error: any }>());

export const configureSpreadsheetExportSuccess = createAction(
  '[SpreadsheetExports] Configure Spreadsheet Export Success',
  props<{ response: SpreadsheetExport }>()
);
export const configureSpreadsheetExportFailure = createAction('[SpreadsheetExports] Configure Spreadsheet Export Failure', props<{ error: any }>());
