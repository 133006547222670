<div *ngIf="device" class="device-info-wrapper">
  <iot4bos-ui-device-card
    (commandExec)="onCommandExec(device, $event)"
    (configure)="configureDevice($event)"
    (decommissionDevice)="decommissionDevice()"
    (deleteDevice)="deleteDevice($event)"
    (edit)="editDevice()"
    (iccidClick)="openIccid()"
    (moveTo)="moveDevice($event)"
    (openChangeLog)="openChangeLog()"
    (reset)="resetDevice($event)"
    [canCreate]="canCreate"
    [canDelete]="canDelete"
    [canReadAuditTrail]="canReadAuditTrail"
    [canRead]="canRead"
    [canUpdate]="canUpdate"
    [device]="device"
    [isResetDisabled]="isResetDisabled$ | async"
  >
  </iot4bos-ui-device-card>
  <div class="device-info-status-area" fxLayoutAlign="end center" fxLayoutGap="20px">
    <iot4bos-ui-device-latest-command-status [device]="device"></iot4bos-ui-device-latest-command-status>
    <iot-platform-ui-device-info-status [device]="device"></iot-platform-ui-device-info-status>
  </div>
  <iot4bos-ui-panel
    (clicked)="manageTags()"
    [buttonTooltip]="'MANAGE_TAGS_FORM.CONFIGURE_TAGS' | translate"
    [hasButton]="canUpdate && tagsLoaded && device?.status?.name !== 'decommissioned'"
    [icon]="'settings'"
    [title]="'MANAGE_TAGS_FORM.TAGS' | translate"
  >
    <mat-chip-list panelContent>
      <iot-platform-ui-chip
        *ngFor="let tag of tags"
        [chipButton]="{ display: false }"
        [chip]="tag"
        [isTag]="true"
        [ngClass]="{ invisible: !tagsLoaded, visible: tagsLoaded }"
      >
      </iot-platform-ui-chip>
    </mat-chip-list>
    <iot-platform-ui-card-loader *ngIf="!tagsLoaded" [backgroundClass]="'tag-loader info-page'" panelGrid> </iot-platform-ui-card-loader>
  </iot4bos-ui-panel>
</div>
