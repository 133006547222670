import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { OyanUiRoutingConstants } from 'libs/oyan-ui/src/lib/oyan-ui.router.constants';

export const routes: Routes = [
  { path: '', loadChildren: () => import('@iot-platform/oyan-ui').then((m) => m.OyanUiModule) },
  { path: OyanUiRoutingConstants.HOME, redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: OyanUiRoutingConstants.HOME }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRouterModule {}
