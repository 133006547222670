import { Action, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';
import { TopicsDbActions, TopicsUiActions } from '../actions';
//
export const topicUiFeatureKey = 'topicsUi';

export interface State {
  error?: any;
  topicLoaded: boolean;
  topicLoading: boolean;
  openConfigurePopup: boolean;
}

export const initialState: State = {
  error: null,
  topicLoaded: false,
  topicLoading: false,
  openConfigurePopup: false
};

const topicsUiReducer = createReducer(
  initialState,
  on(TopicsUiActions.listTopics, (state: State) => ({ ...state, topicLoading: true, topicLoaded: false, openConfigurePopup: false })),
  on(TopicsDbActions.listTopicsSuccess, (state: State) => ({ ...state, topicLoading: false, topicLoaded: true, openConfigurePopup: false })),
  on(TopicsDbActions.listTopicsFailure, (state: State) => ({ ...state, topicLoading: false, topicLoaded: true, openConfigurePopup: false })),
  //
  on(TopicsUiActions.getTopicById, (state: State) => ({ ...state, loading: true, loaded: false })),
  on(TopicsDbActions.getTopicByIdSuccess, (state: State) => ({ ...state, loading: false, loaded: true })),
  on(TopicsDbActions.getTopicByIdFailure, (state: State) => ({ ...state, loading: false, loaded: true })),
  //
  on(TopicsUiActions.addTopic, (state: State) => ({ ...state, topicLoading: true, topicLoaded: false, openConfigurePopup: false })),
  on(TopicsDbActions.addTopicSuccess, (state: State) => ({ ...state, topicLoading: false, topicLoaded: true, openConfigurePopup: false })),
  on(TopicsDbActions.addTopicFailure, (state: State) => ({ ...state, topicLoading: false, topicLoaded: true, openConfigurePopup: false })),
  //
  on(TopicsUiActions.addTopicThenConfigure, (state: State) => ({ ...state, topicLoading: true, topicLoaded: false, openConfigurePopup: false })),
  on(TopicsDbActions.addTopicThenConfigureSuccess, (state: State) => ({ ...state, topicLoading: false, topicLoaded: true, openConfigurePopup: true })),
  on(TopicsDbActions.addTopicThenConfigureFailure, (state: State) => ({
    ...state,
    topicLoading: false,
    topicLoaded: true,
    openConfigurePopup: false
  })),

  on(TopicsUiActions.cancelAddTopicThenConfigure, (state: State) => ({
    ...state,
    topicLoading: false,
    topicLoaded: true,
    openConfigurePopup: false
  })),

  on(TopicsUiActions.updateTopic, (state: State) => ({ ...state, topicLoading: true, topicLoaded: false, openConfigurePopup: false })),
  on(TopicsDbActions.updateTopicSuccess, (state: State) => ({ ...state, topicLoading: false, topicLoaded: true, openConfigurePopup: false })),
  on(TopicsDbActions.updateTopicFailure, (state: State) => ({ ...state, topicLoading: false, topicLoaded: true, openConfigurePopup: false })),

  on(TopicsUiActions.deleteTopic, (state: State) => ({ ...state, topicLoading: true, topicLoaded: false, openConfigurePopup: false })),
  on(TopicsDbActions.deleteTopicSuccess, (state: State) => ({ ...state, topicLoading: false, topicLoaded: true, openConfigurePopup: false })),
  on(TopicsDbActions.deleteTopicFailure, (state: State) => ({ ...state, topicLoading: false, topicLoaded: true, openConfigurePopup: false })),

  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return topicsUiReducer(state, action);
}

export const getTopicsLoaded = (state: State) => state.topicLoaded;
export const getTopicsLoading = (state: State) => state.topicLoading;
export const getOpenConfigurePopup = (state: State) => state.openConfigurePopup;
export const getTopicsError = (state: State) => state.error;
