import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Country, CountryFilterType } from '@iot-platform/models/common';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'iot-platform-ui-country-autocomplete',
  templateUrl: './country-autocomplete.component.html',
  styleUrls: ['./country-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountryAutocompleteComponent implements OnInit, OnDestroy {
  @Input() placeholder = 'IOT_DICTIONARY.COUNTRY';
  @Input() filterType: CountryFilterType = CountryFilterType.ALL;
  @Input() initialItem: string | null = null;
  @Input() clearOnSelect = true;
  @Input() required = false;
  @Input() errorMessage!: string;

  @Output() selectionChange: EventEmitter<Country> = new EventEmitter<Country>();
  @Output() reset: EventEmitter<void> = new EventEmitter<void>();

  countries: Country[] = [];
  filteredItems$: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>([]);
  selectedCountry$: BehaviorSubject<Country | undefined> = new BehaviorSubject<Country | undefined>(undefined);

  private subscriptions: Subscription[] = [];

  constructor(private readonly http: HttpClient, private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.http.get<Country[]>('/assets/data/countries.json').subscribe((countries: Country[]) => {
        this.countries = [...countries];
        this.filteredItems$.next([...countries]);
        if (this.initialItem && this.filterType !== CountryFilterType.ALL) {
          this.selectedCountry$.next(this.countries.find((c: any) => c[this.filterType] === this.initialItem));
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s && s.unsubscribe());
  }

  onSearch(searchTerm: string): void {
    const filtered: Country[] = this.countries.filter(({ name, countryCode, countryCodeAlpha3, phone }) => {
      let searchObject = {
        name,
        countryCode: '',
        countryCodeAlpha3: '',
        phone: ''
      };
      switch (this.filterType) {
        case CountryFilterType.CCA2:
          searchObject.countryCode = countryCode;
          break;
        case CountryFilterType.CCA3:
          searchObject.countryCodeAlpha3 = countryCodeAlpha3;
          break;
        case CountryFilterType.PHONE:
          searchObject.phone = phone;
          break;
        case CountryFilterType.NAME:
          break;
        default:
          searchObject.name = name;
          searchObject.countryCode = countryCode;
          searchObject.countryCodeAlpha3 = countryCodeAlpha3;
          searchObject.phone = phone;
          break;
      }
      return JSON.stringify(searchObject).toLowerCase().includes(searchTerm.toLowerCase());
    });
    this.filteredItems$.next([...filtered]);
  }

  onReset(): void {
    this.filteredItems$.next([...this.countries]);
    this.selectedCountry$.next(undefined);
    this.reset.emit();
  }

  onSelect(selected: Country): void {
    if (selected) {
      this.selectionChange.emit(selected);
    }
  }

  displayWrapper(country: Country): string {
    const _country: Country = cloneDeep(country);
    if (_country) {
      const countryName: string = this.getCountryName(_country);
      if (this.filterType === CountryFilterType.PHONE) {
        return _country.phone ? `${countryName} (${_country.phone})` : countryName;
      } else if (this.filterType !== CountryFilterType.NAME && this.filterType !== CountryFilterType.ALL) {
        return `${countryName} (${country[this.filterType]})`;
      }
      return countryName;
    }
    return '';
  }

  private getCountryName(country: Country): string {
    return this.translateService.instant('SITES.CARD.COUNTRIES.' + country.countryCodeAlpha3);
  }
}
