import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { GetUtils } from '@iot-platform/iot-platform-utils';
import { BusinessProfile } from '@iot-platform/models/common';
import { Asset, Message, Receiver, Site, Ward } from '@iot-platform/models/oyan';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { OyanUiRoutingConstants } from '../../../../oyan-ui.router.constants';
import { AssetsFacade } from '../../../assets/state/facades/assets.facade';
import { MessagesFacade } from '../../../communications/state/facades/messages.facade';
import { GatewaysFacade } from '../../../gateways/state/facades/gateways.facade';
import { ReceiversFacade } from '../../../receivers/state/facades/receivers.facade';
import { SitesFacade } from '../../../sites/state/facades/sites.facade';
import { ActiveElement } from '../../models/active-element.model';
import { NavigationsSelectors } from '../selectors/navigations.selectors';
import { NAVIGATIONS_FILTERS } from './navigations-filters.constants';

@Injectable({
  providedIn: 'root'
})
export class NavigationsFacade {
  activeElement$: Observable<ActiveElement> = this.store.select(this.selector.selectActiveElement);
  siteLoading$ = this.sitesFacade.loading$;

  // Used to prevent navigation when we change business profile
  excludedRoutes: string[] = [
    `/${OyanUiRoutingConstants.SITES}`,
    `/${OyanUiRoutingConstants.GATEWAYS}`,
    `/${OyanUiRoutingConstants.RECEIVERS}`,
    `/${OyanUiRoutingConstants.ASSETS}`,
    `/${OyanUiRoutingConstants.COMMUNICATIONS}`
  ];

  constructor(
    private router: Router,
    @Inject('environment') private readonly environment,
    private readonly store: Store,
    private readonly selector: NavigationsSelectors,
    private sitesFacade: SitesFacade,
    private gatewaysFacade: GatewaysFacade,
    private receiversFacade: ReceiversFacade,
    private assetsFacade: AssetsFacade,
    private messagesFacade: MessagesFacade,
    private storage: LocalStorageService
  ) {}

  goToSiteOverViewFromSites(site: Site): void {
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, OyanUiRoutingConstants.SITES);
    // Set current site in site store
    // This will trigger NavigationsActions.setActiveElement in navigation effect
    this.sitesFacade.setActiveSite(site);
    this.router.navigate(['/', OyanUiRoutingConstants.SITES, site.id]);
  }

  goToSiteOverview(site: Site, origin: string): void {
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, origin);
    if (GetUtils.get(site, 'id', null) !== null) {
      this.router.navigate(['/', OyanUiRoutingConstants.SITES, site.id]);
    }
  }

  returnToOrigin(): void {
    const route = this.storage.get(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY);
    this.router.navigate(route ? ['/', route] : ['/']);
  }

  goToGatewaysPage(site: Site, businessProfile: BusinessProfile): void {
    this.gatewaysFacade.setFilters(NAVIGATIONS_FILTERS.SITES.gatewayFilters(site, businessProfile));
    this.router.navigate(['/', OyanUiRoutingConstants.GATEWAYS]);
  }

  goToReceiversPage(site: Site, businessProfile: BusinessProfile): void {
    this.receiversFacade.setFilters(NAVIGATIONS_FILTERS.SITES.receiverFilters(site, businessProfile));
    this.router.navigate(['/', OyanUiRoutingConstants.RECEIVERS]);
  }

  goToAssetsFromSites(site: Site): void {
    this.assetsFacade.setFilters(NAVIGATIONS_FILTERS.SITES.assetFilters(site));
    this.router.navigate(['/', OyanUiRoutingConstants.ASSETS]);
  }

  onBusinessProfileChanged(changed: boolean): void {
    if (changed && !this.excludedRoutes.some((r) => this.router.routerState.snapshot.url === r)) {
      this.router.navigate(['/']);
    }
  }

  goToCommunicationViewFromReceivers(receiver: Receiver): void {
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, OyanUiRoutingConstants.RECEIVERS);
    this.messagesFacade.setFilters(NAVIGATIONS_FILTERS.RECEIVERS.communicationFilters(receiver));
    this.router.navigate(['/', OyanUiRoutingConstants.COMMUNICATIONS]);
  }

  goToCommunicationViewFromAssets(asset: Asset): void {
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, OyanUiRoutingConstants.ASSETS);
    this.messagesFacade.setFilters(NAVIGATIONS_FILTERS.ASSETS.communicationFilters(asset));
    this.router.navigate(['/', OyanUiRoutingConstants.COMMUNICATIONS]);
  }

  goToAssetsFromReceivers(ward: Ward): void {
    if (ward) {
      this.assetsFacade.setFilters(NAVIGATIONS_FILTERS.RECEIVERS.assetFilters(ward));
      this.router.navigate(['/', OyanUiRoutingConstants.ASSETS]);
    }
  }

  goToReceiversFromCommunications(message: Message): void {
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, OyanUiRoutingConstants.COMMUNICATIONS);
    this.receiversFacade.setFilters(NAVIGATIONS_FILTERS.COMMUNICATIONS.receiverFilters(message));
    this.router.navigate(['/', OyanUiRoutingConstants.RECEIVERS]);
  }

  openKerlink(): void {
    this.openUrl(this.environment.api.kerlinkUrl);
  }

  openCustomerApp(): void {
    this.openUrl(this.environment.api.customerAppAccessUrl);
  }

  private openUrl(url: string): void {
    window.open(url, '_blank');
  }
}
