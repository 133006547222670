import { Action, createReducer, on } from '@ngrx/store';

import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';

import { AssetEventsLogsDbActions, AssetEventsLogsUiActions } from '../actions';

export const assetEventsLogsUiFeatureKey = 'assetEventsLogsUi';

export interface State {
  error?: any;
  loaded?: boolean;
}

export const initialState: State = {
  error: null,
  loaded: false
};

const assetEventsUiReducer = createReducer(
  initialState,
  on(AssetEventsLogsUiActions.loadLogsByAssetEventId, (state: State) => ({ ...state, error: null, loaded: false })),
  on(AssetEventsLogsDbActions.loadLogsByAssetEventIdSuccess, (state: State) => ({ ...state, loaded: true })),
  on(AssetEventsLogsDbActions.loadLogsByAssetEventIdFailure, (state: State, { error }) => ({ ...state, loaded: false, error })),
  // ****
  on(AssetEventsLogsUiActions.createLogByAssetEventId, (state: State) => ({ ...state, error: null })),
  on(AssetEventsLogsDbActions.createLogByAssetEventIdSuccess, (state: State) => ({ ...state, loaded: true })),
  on(AssetEventsLogsDbActions.createLogByAssetEventIdFailure, (state: State, { error }) => ({ ...state, loaded: false, error })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsUiReducer(state, action);
}

export const getError = (state: State) => state.error;
export const getLogsLoaded = (state: State) => state.loaded;
