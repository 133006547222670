import { Sort } from '@angular/material/sort';
import { FavoriteView, Filter, gridSortModel, TagCategory } from '@iot-platform/models/common';

import { Device, DeviceCallLog, DeviceVariable, Site } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';

import { DevicesDbActions } from '../actions';

export const devicesDbFeatureKey = 'devicesDb';

export interface State extends EntityState<Device> {
  device?: Device;
  selectedDeviceId?: string;
  allDevices?: Device[];
  status?: string[];
  devicesUpdated?: Device[];
  selectedId?: string;
  site?: Site;
  variables?: DeviceVariable[];
  variable?: DeviceVariable;
  callLogs?: DeviceCallLog[];
  pagination: {
    currentPage: number;
    hasMore: boolean;
    limit: number;
    maxPage: number;
    total: number;
  };
  settings: any;
  callLogsSettings: any;
  currentFilters?: Filter[];
  initialSort: gridSortModel[] | Sort;
  currentFavoriteView?: FavoriteView;
  favoriteViews: FavoriteView[];
  tags?: TagCategory[];
  timeseries?: any;
}

export const adapter: EntityAdapter<Device> = createEntityAdapter<Device>({
  selectId: (device: Device) => device.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  device: null,
  selectedDeviceId: null,
  allDevices: [],
  status: [],
  devicesUpdated: [],
  selectedId: null,
  site: null,
  variables: [],
  variable: null,
  callLogs: [],
  pagination: {
    currentPage: 0,
    hasMore: false,
    limit: 10,
    maxPage: 0,
    total: 0
  },
  settings: null,
  callLogsSettings: null,
  currentFilters: [],
  initialSort: [],
  currentFavoriteView: null,
  favoriteViews: [],
  tags: [],
  timeseries: null
});

export const reducer = createReducer(
  initialState,
  on(DevicesDbActions.addDeviceSuccess, (state: State, { addedDevice }) => adapter.addOne(addedDevice, { ...state, device: addedDevice })),
  on(DevicesDbActions.deleteDeviceSuccess, (state: State, { deletedDevice }) => adapter.removeOne(deletedDevice.id, state)),
  on(DevicesDbActions.loadDeviceById, (state: State) => ({ ...state })),
  on(DevicesDbActions.loadDeviceByIdSuccess, (state: State, { loadedDevice }) => ({ ...state, selectedDeviceId: loadedDevice.id, device: loadedDevice })),
  on(DevicesDbActions.loadCallLogsByDeviceIdSuccess, (state: State, { loadedCallLogs }) => ({ ...state, callLogs: loadedCallLogs })),
  on(DevicesDbActions.loadDevicesSuccess, (state: State, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: { limit: response.limit, hasMore: response.hasMore, maxPage: response.maxPage, total: response.total, currentPage: response.currentPage }
    })
  ),
  on(DevicesDbActions.loadDeviceConnectorListSuccess, (state: State, { connectors }) => ({ ...state, connectors })),
  on(DevicesDbActions.loadDeviceStatusListSuccess, (state: State, { status }) => ({ ...state, status })),
  on(DevicesDbActions.loadSiteById, (state: State, { siteId }) => ({ ...state, site: null })),
  on(DevicesDbActions.loadSiteByIdSuccess, (state: State, { site }) => ({ ...state, site })),
  on(DevicesDbActions.selectDeviceSuccess, (state: State, { selectedDevice }) => ({ ...state, selectedDeviceId: selectedDevice.id })),
  on(DevicesDbActions.saveCurrentFavoriteView, (state: State, { currentFavoriteView }) => ({ ...state, currentFavoriteView })),
  on(DevicesDbActions.saveCurrentFilters, (state: State, { currentFilters }) => ({ ...state, currentFilters })),
  on(DevicesDbActions.loadVariablesByDeviceId, (state: State, { deviceId }) => ({ ...state, variables: [] })),
  on(DevicesDbActions.loadVariablesByDeviceIdSuccess, (state: State, { deviceVariables }) => ({ ...state, variables: deviceVariables })),
  on(DevicesDbActions.updateDeviceSuccess, (state: State, { updatedDevice }) =>
    adapter.updateOne({ id: updatedDevice.id, changes: updatedDevice }, { ...state, device: updatedDevice })
  ),
  on(DevicesDbActions.bulkUpdateDevicesSuccess, DevicesDbActions.moveDevicesSuccess, (state: State, { updatedDevices }) =>
    adapter.updateMany(
      updatedDevices.map((device) => {
        return { id: device.id, changes: device };
      }),
      { ...state, devicesUpdated: updatedDevices }
    )
  ),
  on(DevicesDbActions.updateDeviceVariable, (state: State, { deviceVariableToUpdate }) => ({
    ...state,
    variable: deviceVariableToUpdate
  })),
  on(DevicesDbActions.updateDeviceVariableSuccess, (state: State, { updatedDeviceVariable }) => {
    const i = state.variables.findIndex((dVar) => dVar.id === updatedDeviceVariable.id);
    const clonedDVariables: DeviceVariable[] = state.variables.slice(0, i);
    clonedDVariables.push(updatedDeviceVariable);
    const updatedDVariables = clonedDVariables.concat(state.variables.slice(i + 1));

    return {
      ...state,
      variable: updatedDeviceVariable,
      variables: [...updatedDVariables]
    };
  }),
  on(DevicesDbActions.loadTagsByDeviceId, (state: State, { deviceId }) => ({ ...state, tags: [] })),
  on(DevicesDbActions.loadTagsByDeviceIdSuccess, (state: State, { tags }) => ({ ...state, tags })),
  on(DevicesDbActions.updateTagsByDeviceIdSuccess, (state: State, { tags }) => ({ ...state, tags })),
  on(DevicesDbActions.loadTimeseriesByDeviceVariableId, (state: State) => ({ ...state, timeseries: [] })),
  on(DevicesDbActions.loadTimeseriesByDeviceVariableIdSuccess, (state: State, { timeseries }) => ({ ...state, timeseries })),
  //
  on(DevicesDbActions.loadMVSettingsSuccess, (state: State, { settings }) => ({ ...state, settings })),
  on(DevicesDbActions.saveMVSettingsSuccess, (state: State, { settings }) => ({ ...state, settings })),
  on(DevicesDbActions.saveMVSettings, (state: State, { name, type, values }) => adapter.removeAll({ ...state })),
  //
  on(DevicesDbActions.loadCallLogsMVSettingsSuccess, (state: State, { callLogsSettings }) => ({ ...state, callLogsSettings })),
  on(DevicesDbActions.saveCallLogsMVSettingsSuccess, (state: State, { callLogsSettings }) => ({ ...state, callLogsSettings })),
  on(DevicesDbActions.saveCallLogsMVSettings, (state: State, { name, values }) => adapter.removeAll({ ...state })),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState),
  //
  on(DevicesDbActions.saveInitialSort, (state: State, { initialSort }) => ({ ...state, initialSort }))
);

export const getSelectedDeviceId = (state: State) => state.selectedDeviceId;
export const getStatus = (state: State) => state.status;
export const getTimeSeries = (state: State) => state.timeseries;
export const getVariables = (state: State) => state.variables;
export const getVariable = (state: State) => state.variable;
export const getSite = (state: State) => state.site;
export const getDevice = (state: State) => state.device;
export const getAllDevices = (state: State) => state.allDevices;
export const getCallLogs = (state: State) => (state ? state.callLogs : []);
export const getPagination = (state: State) => state.pagination;
export const getCurrentFilters = (state: State) => state.currentFilters;
export const getCurrentFavoriteView = (state: State) => state.currentFavoriteView;
export const getFavoriteView = (state: State) => state.favoriteViews;
export const getDevicesUpdated = (state: State) => state.devicesUpdated;
export const getTags = (state: State) => state.tags;
