import { ProcessCellForExportParams } from '@ag-grid-community/core';
import { GetUtils } from '@iot-platform/iot-platform-utils';

export class ArrayOfObjectsCellHelpers {
  static processValue(params: ProcessCellForExportParams): string {
    const {
      cellRendererParams: { cellOptions }
    } = params.column.getColDef();

    return GetUtils.get(params, 'value', [])
      .filter((data: unknown[]) => {
        if (cellOptions && cellOptions.expressions && !!cellOptions.expressions.length) {
          return cellOptions.expressions.map((expression) => this.applyExpression(data, expression)).some((b: boolean) => !!b);
        }
        return data;
      })
      .map((data: unknown[]) => (cellOptions && cellOptions.attributes ? cellOptions.attributes.map((key: string) => data[key]).join(' ') : data))
      .join(cellOptions?.separator ?? ', ');
  }

  static applyExpression(elem: unknown, expression): boolean {
    if (expression.hasOwnProperty('key') && expression.hasOwnProperty('value')) {
      const { key, value } = expression;
      const _value = GetUtils.get(elem, key, null);
      if (_value !== null) {
        return _value === value;
      }
      console.error('Invalid expression : ', expression);
      return true;
    }
    return false;
  }
}
