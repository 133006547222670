<mat-sidenav-container class="main-container" (backdropClick)="closePreferences()">
  <mat-sidenav #sidenav mode="side" [opened]="sidenavState.isOpen" class="navigation-bar">
    <div fxFlex fxLayout="column" fxLayoutAlign="space-between" class="navigation-bar-content">
      <mat-nav-list *ngIf="navTree" class="sidenav">
        <iot4bos-ui-sidenav-block *ngFor="let navBlock of navTree" [navBlock]="navBlock"></iot4bos-ui-sidenav-block>
      </mat-nav-list>
      <div class='main-container__build-info'>
        <iot-platform-ui-build-info [label]="'TAB_NAV.BUILD_INFO.FRONTEND' | translate" [build]='appConfig?.front' [showBuildNumber]='false'></iot-platform-ui-build-info>
        <iot-platform-ui-build-info [label]="'TAB_NAV.BUILD_INFO.BACKEND' | translate" [build]='appConfig?.back' [showBuildNumber]='false'></iot-platform-ui-build-info>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="sticky">
      <oyan-ui-header
        [currentUser]="currentUser$ | async"
        [businessProfiles]="eligibleBusinessProfiles$ | async"
        [selectedBusinessProfile]="businessProfile$ | async"
        [enableBusinessProfileSwitching]="enableBusinessProfileSwitching$ | async"
        [logo]="'assets/images/logo-air-liquide.svg'"
        [appLogo]="'assets/images/oyan-smart.svg'"
        (logout)="logout()"
        (logoClicked)="returnHome()"
        (menuClicked)="toggleSidenav()"
        (myProfile)="openMyProfile()"
        (changeBusinessProfile)="onChangeBusinessProfile($event)"
      >
      </oyan-ui-header>
    </div>

    <router-outlet></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>
