import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'iot4bos-ui-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent {
  @Input() title: string;
  @Input() hasButton = false;
  @Input() hasToolbar = false;
  @Input() buttonTooltip: string;
  @Input() withCustomAction: boolean;
  @Input() icon = 'add';

  @Output() clicked: EventEmitter<any> = new EventEmitter();

  onClick() {
    this.clicked.emit();
  }
}
