import { Injectable } from '@angular/core';
import { BaseFacade, CommonApiRequest, Filter, Pagination } from '@iot-platform/models/common';
import { Product } from '@iot-platform/models/oyan';
import { Store } from '@ngrx/store';
import { ProductsActions } from '../actions';
import { ProductsSelectors } from '../selectors/products.selectors';

@Injectable({
  providedIn: 'root'
})
export class ProductsFacade extends BaseFacade<Product, Pagination, Filter> {
  constructor(protected store: Store, protected selector: ProductsSelectors) {
    super(store, selector);
  }

  getAll(request?: CommonApiRequest): void {
    this.store.dispatch(ProductsActions.loadProducts({ request }));
  }

  loadProductById(id: string): void {
    this.store.dispatch(ProductsActions.loadProductById({ id }));
  }

  addProduct(product: Product): void {
    this.store.dispatch(ProductsActions.addProduct({ product }));
  }

  updateProduct(product: Product): void {
    this.store.dispatch(ProductsActions.updateProduct({ product }));
  }

  deleteProduct(product: Product): void {
    this.store.dispatch(ProductsActions.deleteProduct({ product }));
  }

  setFilters(filters: Filter[]) {
    this.store.dispatch(ProductsActions.setFilters({ filters }));
  }
}
