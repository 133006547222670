import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { BusinessProfile } from '@iot-platform/models/common';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthorizationType } from '../../authorization.types';
import { AuthorizationService } from '../../services/authorization.service';

@Component({
  selector: 'iot-platform-login-select-profile',
  templateUrl: './login-select-profile.component.html',
  styleUrls: ['./login-select-profile.component.scss']
})
export class LoginSelectProfileComponent implements OnInit, OnDestroy {
  @Input() businessProfiles$: Observable<BusinessProfile[]>;
  @Input() filterProfiles = false;
  @Input() loading: boolean;

  @Output() selectProfile: EventEmitter<any> = new EventEmitter();

  subscription: Subscription;
  filterProfilesLoading: boolean = false;
  bpByEntities$: BehaviorSubject<Array<{ entityId: string; entityName: string; businessProfiles: BusinessProfile[] }>> = new BehaviorSubject<
    Array<{ entityId: string; entityName: string; businessProfiles: BusinessProfile[] }>
  >([]);

  constructor(private readonly authService: AuthorizationService) {}

  ngOnInit() {
    this.subscription = this.businessProfiles$
      .pipe(switchMap((businessProfiles: BusinessProfile[]) => this.filterBusinesProfiles(businessProfiles)))
      .subscribe((businessProfiles: BusinessProfile[]) => {
        if (businessProfiles && businessProfiles.length > 0) {
          this.bpByEntities$.next(this.getBpByEntity(this.sortBpByLevel(businessProfiles)));
        }
      });
  }

  // KERCOM Specific filtering function to only show BPs with create right on device concept
  public filterBusinesProfiles(businessProfiles: BusinessProfile[]): Observable<BusinessProfile[]> {
    if (this.filterProfiles && businessProfiles && businessProfiles.length > 0) {
      this.filterProfilesLoading = true;
      const privilegesPerBusinessProfile = businessProfiles.map((bp: BusinessProfile) => this.authService.getAuthorizationsByBusinessProfile(bp.id));
      return forkJoin(privilegesPerBusinessProfile).pipe(
        map((privileges: any[]) => {
          const filteredProfiles = businessProfiles.filter(
            (bp, index) => privileges[index].DEVICE && privileges[index].DEVICE.some((privilege: string) => privilege === AuthorizationType.CREATE)
          );
          return filteredProfiles;
        }),
        tap((res) => (this.filterProfilesLoading = false))
      );
    } else {
      return of(businessProfiles);
    }
  }

  private sortBpByLevel(businessProfiles: BusinessProfile[]): BusinessProfile[] {
    return businessProfiles.sort((a, b) => a.level - b.level);
  }

  private sortBpByAlphabeticalOrder(businessProfiles: BusinessProfile[]): BusinessProfile[] {
    return businessProfiles.sort((a, b) => a.name.localeCompare(b.name));
  }

  private getBpByEntity(businessProfiles: BusinessProfile[]): Array<{ entityId: string; entityName: string; businessProfiles: BusinessProfile[] }> {
    const bpByEntities = businessProfiles.map((businessProfile) => {
      return {
        entityId: businessProfile.entityId,
        entityName: businessProfile.entityName,
        businessProfiles: this.sortBpByAlphabeticalOrder(
          businessProfiles.filter((curBusinessProfile) => curBusinessProfile.entityId === businessProfile.entityId)
        )
      };
    });

    return bpByEntities.reduce(
      (resBpByEntities, bpByEntity) => {
        return !resBpByEntities.find((curBpByEntity) => curBpByEntity.entityId === bpByEntity.entityId) ? [...resBpByEntities, bpByEntity] : resBpByEntities;
      },
      [bpByEntities[0]]
    );
  }

  onSelectProfile(profile: BusinessProfile): void {
    this.selectProfile.emit(profile);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
