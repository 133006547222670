<div data-cy="iot-platform-ui-toolbar-button">
  <button
    (click)="onButtonClick(button.dispatchAction)"
    *ngIf="button.displayButton && !menuButton && !(button.disabled && button.removable)"
    [disabled]="button.disabled"
    [matTooltip]="button.tooltip | translate"
    [ngClass]="computedClasses"
    class="iot-platform-ui-toolbar-button-{{ button?.dispatchAction?.type }}"
    class="regular-round-button reverse-accent-button"
    mat-icon-button
    matTooltipClass="regular-tooltip"
  >
    <mat-icon [svgIcon]="button.svgIcon">{{ button.icon }}</mat-icon>
  </button>

  <button
    *ngIf="!!menuButton && menuButton.displayButton"
    [disabled]="menuButton.disabled"
    [matMenuTriggerFor]="buttonMenu"
    [matTooltip]="button.tooltip | translate"
    class="regular-round-button reverse-accent-button"
    mat-icon-button
    matTooltipClass="regular-tooltip"
  >
    <mat-icon>{{ menuButton.icon }}</mat-icon>
  </button>
  <mat-menu #buttonMenu="matMenu">
    <button
      (click)="onMenuOptionClick(menuOption.dispatchAction)"
      *ngFor="let menuOption of menuButton?.menuOptions"
      [disabled]="menuOption.disableOption"
      [matTooltip]="menuOption.tooltip"
      mat-menu-item
    >
      {{ menuOption.label | translate }}
    </button>
  </mat-menu>
</div>


