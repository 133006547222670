<div class='login-page' fxLayout='row' fxLayoutAlign='center center'>
  <div class='login-page__content' fxLayout='row' fxLayoutAlign='center'>
    <figure class='mat-figure' fxLayout='row' fxLayoutAlign='center center' fxFlex='50%'>
      <div class='login-page__form-layout'>
        <div class='login-page__bg-img'></div>
        <div class='login-page__info'></div>
        <div class='login-page__info-welcome'>
          <h2 translate>LOGIN.WELCOME_TITLE</h2>
          <div class='login-page__info-logo'>
            <mat-icon svgIcon='logo-login'></mat-icon>
          </div>
        </div>
      </div>
    </figure>
    <figure class='mat-figure' fxLayout='row' fxLayoutAlign='center center' fxFlex='50%'>
      <div class='login-page__form' [ngClass]="{
          'step-login': !(stepEmail$ | async) && !(stepPassword$ | async),
          'step-email': (stepEmail$ | async),
          'step-password': (stepPassword$ | async) || ((loggedIn$ | async) && (requireNewPassword$ | async)),
          'has-error': (errorSignIn$ | async),
          'step-select-bp': (loggedIn$ | async) && !(requireNewPassword$ | async),
          'loading-bps': (authPending$ | async)
        }">
        <mat-card>
          <mat-card-title>{{ 'LOGIN.TITLE.LOGIN' | translate }}</mat-card-title>

          <ng-container *ngIf='!(loggedIn$ | async) && !(stepEmail$ | async) && !(stepPassword$ | async)'>
            <iot-platform-login-form
              [hasError]='(errorSignIn$ | async)'
              (resetPassword)='onResetPassword()' (signIn)='onSignIn($event)'></iot-platform-login-form>
          </ng-container>

          <ng-container *ngIf='(loggedIn$ | async) && (requireNewPassword$ | async)'>
            <iot-platform-login-change-temporary-password-form
              [hasError]='(errorPassword$ | async)'
              (cancel)='onCancel()'
              (changeTemporaryPassword)='onChangeTemporaryPassword($event)'
            ></iot-platform-login-change-temporary-password-form>
          </ng-container>

          <ng-container *ngIf='(stepEmail$ | async)'>
            <iot-platform-login-send-code-form
              [hasError]='(errorEmail$ | async)'
              (cancel)='onCancel()' (sendCode)='onSendCode($event)'></iot-platform-login-send-code-form>
          </ng-container>

          <ng-container *ngIf='(stepPassword$ | async)'>
            <iot-platform-login-reset-password-form
              [username]='username'
              [hasError]='(errorPassword$ | async)'
              (cancel)='onCancel()'
              (sendCode)='onSendCode($event)'
              (changePassword)='onChangeForgottenPassword($event)'
            ></iot-platform-login-reset-password-form>
          </ng-container>

          <ng-container *ngIf='(loggedIn$ | async) && !(requireNewPassword$ | async)'>
            <iot-platform-login-select-profile [businessProfiles$]='eligibleBusinessProfiles$' [loading]='(authPending$ | async)' (selectProfile)='onSelectProfile($event)'>
            </iot-platform-login-select-profile>
          </ng-container>

        </mat-card>
      </div>
    </figure>
  </div>
  <div class='login-page__footer' [ngClass]="{'step-password': (stepPassword$ | async)}">
    <mat-icon svgIcon='al-colored-logo'></mat-icon>
  </div>
</div>
