import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { UiModule } from '@iot-platform/iot-platform-ui';
import { SharedModule } from '@iot-platform/shared';
import { TranslateModule } from '@ngx-translate/core';
import { SitesService } from '../../../../../shared/src/lib/services/sites.service';
import { AssetBreadcrumbItemComponent } from './asset-breadcrumb-item/asset-breadcrumb-item.component';
import { BreadcrumbComponent } from './breadcrumb.component';
import { DeviceBreadcrumbItemComponent } from './device-breadcrumb-item/device-breadcrumb-item.component';
import { SiteBreadcrumbItemComponent } from './site-breadcrumb-item/site-breadcrumb-item.component';

@NgModule({
  imports: [CommonModule, MatIconModule, RouterModule, UiModule, SharedModule, TranslateModule],
  declarations: [BreadcrumbComponent, SiteBreadcrumbItemComponent, AssetBreadcrumbItemComponent, DeviceBreadcrumbItemComponent],
  exports: [BreadcrumbComponent, SiteBreadcrumbItemComponent, AssetBreadcrumbItemComponent, DeviceBreadcrumbItemComponent],
  providers: [SitesService]
})
export class BreadcrumbModule {}
