import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { CustomEncoder, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { PlatformRequest, PlatformResponse, TagCategory } from '@iot-platform/models/common';

import { Asset, AssetVariable, Device, DeviceEvent, DeviceVariable, Log, Site } from '@iot-platform/models/i4b';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceEventsService extends EventsService {
  constructor(@Inject('environment') protected environment, protected httpClient: HttpClient, private storage: LocalStorageService) {
    super(environment, httpClient);
  }

  getDeviceEvents(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });

    params = params.set('limit', request.limit?.toString(10) ?? '100');
    params = params.set('page', request.page?.toString(10) ?? '0');

    if (request.filters) {
      request.filters.forEach((filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient.get<DeviceEvent[]>(`${this.environment.api.url + this.environment.api.endpoints.deviceEvents}`, { params }).pipe(
      map((data: any) => {
        return {
          data: data.content,
          currentPage: data.page.curPage,
          hasMore: data.page.hasMore,
          limit: data.page.limit,
          maxPage: data.page.maxPage,
          total: data.page.total
        };
      })
    );
  }

  getSiteById(siteId: string): Observable<Site> {
    return this.httpClient.get<Site>(this.environment.api.url + this.environment.api.endpoints.sites + '/' + siteId);
  }

  getDeviceById(deviceId: string): Observable<Device> {
    return this.httpClient.get<Device>(this.environment.api.url + this.environment.api.endpoints.devices + '/' + deviceId);
  }

  getDeviceVariableById(deviceVariableId: string): Observable<DeviceVariable | Error> {
    if (!deviceVariableId) {
      return of(null);
    }
    return this.httpClient.get<DeviceVariable>(this.environment.api.url + this.environment.api.endpoints.deviceVariables + '/' + deviceVariableId);
  }

  getAssetById(assetId: string): Observable<Asset | Error | undefined> {
    return !!assetId ? this.httpClient.get<Asset>(this.environment.api.url + this.environment.api.endpoints.assets + '/' + assetId) : of(undefined);
  }

  getAssetVariableById(assetVariableId: string): Observable<AssetVariable | Error | undefined> {
    return !!assetVariableId
      ? this.httpClient.get<AssetVariable>(this.environment.api.url + this.environment.api.endpoints.assetVariables + '/' + assetVariableId)
      : of(undefined);
  }

  getLogsById(deviceEventId: string): Observable<Log[]> {
    return this.httpClient
      .get<Log[]>(this.environment.api.url + this.environment.api.endpoints.deviceEvents + '/' + deviceEventId + '/logs')
      .pipe(map((data: any) => data.content));
  }

  putLogById(comment: { deviceEvent: DeviceEvent; value: string }): Observable<Log> {
    return this.httpClient.put<Log>(`${this.environment.api.url}${this.environment.api.endpoints.deviceEvents}/${comment.deviceEvent.id}/comments`, {
      comment: comment.value
    });
  }

  getTagsByDeviceEventId(deviceEventId: string): Observable<TagCategory[]> {
    return this.httpClient
      .get<TagCategory[]>(this.environment.api.url + this.environment.api.endpoints.deviceEvents + `/${deviceEventId}/tags`)
      .pipe(map((data: any) => data.content));
  }

  putStatus(status: { deviceEventId: string; value: string }): Observable<DeviceEvent> {
    return this.httpClient.put<DeviceEvent>(
      `${this.environment.api.url}${this.environment.api.endpoints.deviceEvents}/${status.deviceEventId}/${status.value}`,
      {
        comment: null
      }
    );
  }

  bulkUpdateStatus(deviceEventIds: string[], status: string): Observable<DeviceEvent>[] {
    return deviceEventIds.map((id) => this.putStatus({ deviceEventId: id, value: status }));
  }

  saveTableState(tableState: { selected: DeviceEvent; checked: DeviceEvent[] }): Observable<{ selected: DeviceEvent; checked: DeviceEvent[] }> {
    this.storage.set(LocalStorageKeys.STORAGE_MV_DEVICE_EVENTS_TABLE_STATE_KEY, JSON.stringify(tableState));
    return of(tableState);
  }
}
