<div (mouseleave)="toggleIconInfo(false, $event)" (mouseover)="toggleIconInfo(true, $event)" fxFlexFill fxLayout="row" fxLayoutAlign="start center">
  <div (click)="onDispatchEvent($event)" [class.clickable]="!minimalDisplay" class="basic-click-cell">
    <span *ngIf="!isMultipleStateVariable && params?.cellOptions?.dataType === 'lastRecord'" class="fw-600">
      {{ params.value[0]?.value | numberFormat | infoDisplay }} <span *ngIf="displayUnit">{{ params.data?.unit }}</span>
    </span>
    <span *ngIf="!isMultipleStateVariable && params?.cellOptions?.dataType === 'expandedVariable'" class="fw-600">
      {{ data?.lastValue?.value | numberFormat | infoDisplay }} <span *ngIf="displayUnit">{{ data?.unit }}</span>
    </span>
    <span
      *ngIf="!isMultipleStateVariable && params?.cellOptions?.dataType !== 'lastRecord' && params?.cellOptions?.dataType !== 'expandedVariable'"
      class="fw-600"
    >
      {{ data?.value | numberFormat | infoDisplay }} <span *ngIf="displayUnit">{{ data?.unit }}</span>
    </span>
    <span *ngIf="isMultipleStateVariable" class="fw-600">{{ processedValue }}</span>
  </div>
  <div *ngIf="!minimalDisplay">
    <mat-icon
      *ngIf="eventIconProperties.isDisplayed"
      [matTooltip]="eventIconProperties.tooltip | translate"
      [matTooltipClass]="'regular-tooltip'"
      matTooltipPosition="above"
      class="icon-notification"
      (click)="openFollowedVariableEvents($event)"
      [svgIcon]="eventIconProperties.iconName"
    ></mat-icon>
    <mat-icon
      *ngIf="canReadGraph && !!data?.totalGraphs"
      [matTooltip]="'GRID_ENGINE.CELLS.RICH_VARIABLE_VALUE_CELL.DISPLAY_GRAPH' | translate"
      [matTooltipClass]="'regular-tooltip'"
      matTooltipPosition="above"
      class="icon-graphs"
      (click)="openGraphsByVariable($event)"
      >assessment</mat-icon
    >
    <mat-icon
      *ngIf="displayInfoIcon && canDisplayInfoIcon()"
      [matTooltip]="'GRID_ENGINE.CELLS.RICH_VARIABLE_VALUE_CELL.DISPLAY_THE_VARIABLE_CONFIGURATION' | translate"
      [matTooltipClass]="'regular-tooltip'"
      matTooltipPosition="above"
      class="icon-configuration"
      (mouseover)="toggleIconInfo(true, $event)"
      (click)="openVariableConfiguration($event)"
      >info</mat-icon
    >
  </div>
</div>
