import {
  CommonApiRequest,
  CommonApiResponse,
  CommonGenericModel,
  CommonPagination,
  Filter
} from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions, I4BGridSort } from '@iot-platform/models/grid-engine';
import { createAction, props } from '@ngrx/store';

export const toggleRefreshActivated = createAction('[Grids] Toggle Refresh Activated', props<{ refreshActivated: boolean }>());
export const selectGrid = createAction('[Grids] Select Grid', props<{ gridId: string; masterview: string }>());
export const selectGridAndLoadData = createAction(
  '[Grids] Select Grid And Load Data',
  props<{ gridId: string; masterview: string; filters?: Filter[]; endPoint?: string }>()
);
export const loadGrids = createAction('[Grids] Load Grids', props<{ concept?: string }>());
export const loadGridsSuccess = createAction(
  '[Grids] Load Grids Success',
  props<{ response: CommonApiResponse<I4BGrid<I4BGridOptions, I4BGridData>, CommonPagination> }>()
);
export const loadGridsFailure = createAction('[Grids] Load Grids Failure', props<{ error: any }>());
// **************************************************
export const loadGridDetails = createAction('[Grids] Load Grid Details', props<{ concept: string; gridId: string }>());
export const loadGridDetailsSuccess = createAction('[Grids] Load Grid Details Success', props<{ grid: I4BGrid<I4BGridOptions, I4BGridData> }>());
export const loadGridDetailsFailure = createAction('[Grids] Load Grid Details Failure', props<{ error: any }>());
// **************************************************
export const loadGridData = createAction('[Grids] Load Grid Data', props<{ request: CommonApiRequest }>());
export const changeGridPage = createAction('[Grids] Change Grid Page', props<{ request: CommonApiRequest }>());
export const loadGridDataSuccess = createAction('[Grids] Load Grid Data Success', props<{ masterView: string; gridData: I4BGridData }>());
export const loadGridDataFailure = createAction('[Grids] Load Grid Data Failure', props<{ error: any }>());
// **************************************************
export const selectItemInGridData = createAction('[Grids] Select Item In Grid', props<{ gridId: string; itemId: string }>());
export const checkItemsInGridData = createAction('[Grids] Check Items In Grid', props<{ gridId: string; itemIds: string[] }>());
export const sortGridData = createAction('[Grids] Sort Grid Data', props<{ gridId: string; gridSort: I4BGridSort[] }>());
export const addItemInGridData = createAction('[Grids] ADD Item In Grid', props<{ gridId: string; item: CommonGenericModel }>());
export const updateItemInGridData = createAction('[Grids] Update Item In Grid', props<{ gridId: string; item: CommonGenericModel }>());
export const updateItemInAllGridsData = createAction('[Grids] Update Item In Grids', props<{ updatedItem: CommonGenericModel }>());
export const removeItemInGridData = createAction('[Grids] Remove Item In Grid', props<{ gridId: string; item: CommonGenericModel }>());
export const getGridsByConcept = createAction('[Grids] Get Grids By Concept', props<{ concept: string }>());
// **************************************************
export const getDefaultGridByConcept = createAction('[Grids] Get Default Grid By Concept', props<{ concept: string }>());
export const getDefaultGridByConceptSuccess = createAction(
  '[Grids] Get Default Grid By Concept Success',
  props<{ defaultGrid: I4BGrid<I4BGridOptions, I4BGridData> }>()
);
export const getDefaultGridByConceptFailure = createAction('[Grids] Get Default Grid By Concept Failure', props<{ error: any }>());
// **************************************************
export const addGrid = createAction('[Grids] Add Grid', props<{ toAdd: CommonGenericModel }>());
export const addGridSuccess = createAction('[Grids] Add Grid Success', props<{ grid: CommonGenericModel }>());
export const addGridFailure = createAction('[Grids] Add Grid Failure', props<{ error: any }>());
// **************************************************
export const removeGrid = createAction('[Grids] Remove Grid', props<{ toRemove: CommonGenericModel }>());
export const removeGridSuccess = createAction('[Grids] Remove Grid Success', props<{ removed: CommonGenericModel }>());
export const removeGridFailure = createAction('[Grids] Remove Grid Failure', props<{ error: any }>());
// **************************************************
export const updateGrid = createAction('[Grids] Update Grid', props<{ toUpdate: CommonGenericModel }>());
export const updateGridSuccess = createAction('[Grids] Update Grid Success', props<{ grid: CommonGenericModel }>());
export const updateGridFailure = createAction('[Grids] Update Grid Failure', props<{ error: any }>());
// **************************************************
export const updateSilentGrid = createAction('[Grids] Update Silent Grid', props<{ toUpdate: CommonGenericModel }>());
export const updateSilentGridSuccess = createAction('[Grids] Update Silent Grid Success', props<{ grid: CommonGenericModel }>());
export const updateSilentGridFailure = createAction('[Grids] Update Silent Grid Failure', props<{ error: any }>());
// **************************************************
export const updateGridData = createAction('[Grids] Update Grid Data', props<{ dataToUpdate: CommonGenericModel }>());
export const updateGridDataSuccess = createAction('[Grids] Update Grid Data Success', props<{ dataUpdated: CommonGenericModel }>());
export const updateGridDataFailure = createAction('[Grids] Update Grid Data Failure', props<{ error: any }>());

export const resetState = createAction('[Grids] Reset Grid State');
