import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUserProfileDb from './profile-db.reducer';
import * as fromUserProfileUi from './profile-ui.reducer';

export const userProfileFeatureKey = 'userProfile';

export interface UserProfileState {
  [fromUserProfileDb.userProfileDbFeatureKey]: fromUserProfileDb.State;
  [fromUserProfileUi.userProfileUiFeatureKey]: fromUserProfileUi.State;
}

export interface State {
  [userProfileFeatureKey]: UserProfileState;
}

export function reducers(state: UserProfileState | undefined, action: Action) {
  return combineReducers({
    [fromUserProfileDb.userProfileDbFeatureKey]: fromUserProfileDb.reducer,
    [fromUserProfileUi.userProfileUiFeatureKey]: fromUserProfileUi.reducer
  })(state, action);
}

export const selectUserProfileState = createFeatureSelector< UserProfileState>(userProfileFeatureKey);

export const selectUserProfileDbState = createSelector(selectUserProfileState, (state: UserProfileState) => state[fromUserProfileDb.userProfileDbFeatureKey]);

export const selectUserProfileUiState = createSelector(selectUserProfileState, (state: UserProfileState) => state[fromUserProfileUi.userProfileUiFeatureKey]);

export const getCurrentUser = createSelector(selectUserProfileDbState, fromUserProfileDb.getCurrentUser);

export const getUserProfileError = createSelector(selectUserProfileUiState, fromUserProfileUi.getError);

export const getUserProfileLoading = createSelector(selectUserProfileUiState, fromUserProfileUi.getLoading);
