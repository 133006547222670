<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ title$ | async }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="closeOnCancel()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <form [formGroup]="poEventCreateForm" fxLayout="column" fxLayoutAlign="space-between" class="p-20" fxFlex>
        <mat-form-field color="accent">
          <input
            matInput
            #eventLabelInput
            [placeholder]="'PO_EVENTS.CREATION_CONFIGURATION_POPUP.NAME' | translate"
            formControlName="eventLabel"
            required
            maxlength="50"
            pattern="\S.*"
          />
          <mat-error *ngIf="eventLabel.invalid && (eventLabel.errors.maxlength || eventLabel.errors.pattern)">{{ errorMessage$ | async }}</mat-error>
          <mat-hint align="end">{{ eventLabelInput.value?.length || 0 }}/50</mat-hint>
          <mat-hint *ngIf="displayDuplicateMessage$ | async">
            <span class="dialog-card-input-error">{{ 'FAVORITE_VIEW.FORM.ERROR_MESSAGE.DUPLICATE' | translate }}</span>
          </mat-hint>
        </mat-form-field>

      <iot-platform-ui-async-autocomplete
        [data]="entities"
        [standaloneMode]="true"
        [disabled]="!!data"
        [initialItem]="initialEntity"
        [showSpinner]="dataLoading"
        [displaySearchIcon]="false"
        [minLength]="1"
        [displayKey]="'label'"
        [placeholder]="'IOT_DICTIONARY.ENTITY'"
        [filterKey]="'label'"
        [required]="true"
        (selectionChanged)="onEntitySelection($event)"
        (search)="resetEntity()"
        (reset)="resetEntity()"
      >
      </iot-platform-ui-async-autocomplete>

        <mat-form-field color="accent">
          <mat-select [placeholder]="'IOT_DICTIONARY.RULE_TYPE' | translate" formControlName="eventType" required>
            <mat-option *ngFor="let algo of algos" [value]="algo">{{ algo.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <p class="dialog-form-tips">{{ 'ASSETS.VARIABLE_FORM.MANDATORY_FIELDS' | translate }}</p>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="closeOnCancel()">{{ 'ASSETS.VARIABLE_FORM.CANCEL' | translate }}</button>
    <button mat-raised-button class="button-regular" color="accent" (click)="save('ADD')" [disabled]="!poEventCreateForm.valid || (isDisabled$ | async)">
      {{ actionButtonLabel | translate }}
    </button>
    <button *ngIf="!data" mat-raised-button class="button-regular" color="accent" (click)="save('CONFIGURE')" [disabled]="!poEventCreateForm.valid || (isDisabled$ | async)">
      {{ 'ASSETS.VARIABLE_FORM.CREATE_CONFIGURE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
