import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Filter, FilterMultipleInputsOptions } from '@iot-platform/models/common';
import { SortUtil } from '../../../../../../../../shared/src/lib/utils/sort.util';

@Component({
  selector: 'iot-platform-ui-multiple-inputs-field',
  templateUrl: './multiple-inputs-field.component.html',
  styleUrls: ['./multiple-inputs-field.component.scss']
})
export class MultipleInputsFieldComponent implements OnInit {
  inputsForm: UntypedFormGroup;
  inputs;

  @Input() data: FilterMultipleInputsOptions;

  @Output() dispatchFilterValue: EventEmitter<Filter> = new EventEmitter<Filter>();

  constructor() {}

  ngOnInit() {
    this.initFormGroup();
    this.inputs = this.data.inputs.sort(SortUtil.sortByOrder);
  }

  initFormGroup(): void {
    const group = {};
    this.data.inputs.forEach((input) => {
      group[input.criteriaKey] = new UntypedFormControl(null);
    });

    this.inputsForm = new UntypedFormGroup(group);
  }

  onInputValidation(inputKey: string) {
    if (this.inputsForm.get(inputKey).value) {
      const filter: Filter = {
        criteriaKey: inputKey,
        criteriaLabel: this.data.inputs.find((i) => i.criteriaKey === inputKey).criteriaLabel,
        value: this.inputsForm.get(inputKey).value.trim(),
        label: this.inputsForm.get(inputKey).value.trim()
      };

      this.dispatchFilterValue.emit(filter);
      this.inputsForm.get(inputKey).reset();
    }
  }
}
