import { Pagination, ProductCatalog } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';
import { ProductCatalogsDbActions } from '../actions';

export const productCatalogsDbFeatureKey = 'productCatalogsDb';

export interface State extends EntityState<ProductCatalog> {
  selectedCatalogId: string;
  pagination: Pagination;
  error: any;
}

export const adapter: EntityAdapter<ProductCatalog> = createEntityAdapter<ProductCatalog>({
  selectId: (catalog: ProductCatalog) => catalog.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedCatalogId: null,
  pagination: { currentPage: 0, total: 0, maxPage: 0, hasMore: false, limit: 100 },
  error: null
});

export const productCatalogsDbReducer = createReducer(
  initialState,
  on(ProductCatalogsDbActions.listProductCatalogsSuccess, (state: State, { response }) =>
    adapter.setAll(response.data, {
      ...state,
      pagination: {
        currentPage: response.currentPage,
        hasMore: response.hasMore,
        limit: 100,
        maxPage: response.maxPage,
        total: response.total,
        next: null,
        prev: null
      }
    })
  ),
  on(ProductCatalogsDbActions.listProductCatalogsFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(ProductCatalogsDbActions.addProductCatalogSuccess, (state: State, { addedProductCatalog }) => adapter.addOne(addedProductCatalog, state)),
  on(ProductCatalogsDbActions.addProductCatalogFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(ProductCatalogsDbActions.updateProductCatalogSuccess, (state: State, { updatedProductCatalog }) =>
    adapter.updateOne({ id: updatedProductCatalog.id, changes: updatedProductCatalog }, state)
  ),
  on(ProductCatalogsDbActions.updateProductCatalogFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(ProductCatalogsDbActions.deleteProductCatalogSuccess, (state: State, { deletedProductCatalog }) => adapter.removeOne(deletedProductCatalog.id, state)),
  on(ProductCatalogsDbActions.deleteProductCatalogFailure, (state: State, { error }) => ({ ...state, error })),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return productCatalogsDbReducer(state, action);
}

export const getSelectedCatalogId = (state: State) => state.selectedCatalogId;
export const getPagination = (state: State) => state.pagination;
