import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'iot4bos-ui-list-display',
  templateUrl: './list-display.component.html',
  styleUrls: ['./list-display.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListDisplayComponent implements OnChanges {
  @Input() list!: string[];
  @Input() maxToShow = 3;

  firstElements: string[] = [];
  restOfElements: string[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.list?.currentValue) {
      this.maxToShow = Math.min(this.maxToShow, this.list.length);
      this.firstElements = this.list.slice(0, this.maxToShow);
      this.restOfElements = this.list.slice(this.maxToShow);
    }
  }
}
