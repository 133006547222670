<section fxLayout="column" fxLayoutGap="10px">
  <iot-platform-ui-detail-popup-section-header headerTitle="{{ (tags?.length <= 1 ? 'IOT_DICTIONARY.TAG' : 'IOT_DICTIONARY.TAGS') | translate }} ({{ tags?.length }})"
  ></iot-platform-ui-detail-popup-section-header>
  <div *ngIf="displayLoader && tags.length === 0" class="detail-no-tags-card">
    <div class="detail-no-tags-card" fxFlex fxLayout="row" fxLayoutAlign="center center">{{ 'SITES.TABLE_CONTENT.EXPANDED_PANEL.NO_TAG' | translate }}</div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <iot-platform-ui-card-loader *ngIf="!displayLoader" [backgroundClass]="'tag-loader mv-detail'"></iot-platform-ui-card-loader>
    <mat-chip-list *ngIf="displayLoader && tags && tags.length > 0">
      <iot-platform-ui-chip
        *ngFor="let tag of tags"
        [chipButton]="{ display: false }"
        [chip]="tag"
        [isTag]="true"
        [ngClass]="{ invisible: displayLoader, visible: !displayLoader }"
      ></iot-platform-ui-chip>
    </mat-chip-list>
  </div>

</section>

