import { Injectable, OnDestroy } from '@angular/core';
import { Filter, PlatformRequest, PlatformResponse } from '@iot-platform/models/common';
import { Asset, Device, Event } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DateFormatPipe } from 'libs/iot-platform-pipes/src/lib/date-format/date-format.pipe';
import { AssetEventsService } from 'libs/shared/src/lib/services/asset-events.service';
import { AssetsService } from 'libs/shared/src/lib/services/assets.service';
import { DeviceEventsService } from 'libs/shared/src/lib/services/device-events.service';
import { DevicesService } from 'libs/shared/src/lib/services/devices.service';
import { SitesService } from 'libs/shared/src/lib/services/sites.service';
import { Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import * as fromProducts
  from '../../../../../../iot4bos-backoffice-ui/src/lib/features/admin-product-catalogs/state/reducers';
import {
  AssetEventsFormatter,
  AssetsFormatter,
  DeviceEventsFormatter,
  DevicesFormatter,
  ExportsFormatter,
  ProductsFormatter,
  SitesFormatter
} from './exports.formatter';

@Injectable({
  providedIn: 'root'
})
export class ExportsService implements OnDestroy {
  public cancel$: Subject<boolean> = new Subject();
  public loaderValue$: Subject<number> = new Subject();
  public isFileDownloaded$: Subject<boolean> = new Subject();
  private data: Array<any>;
  private loaderValue: number;
  private loaderInterval: number;
  private subscription: Subscription;
  private loadData$: Subject<number> = new Subject();

  constructor(
    private sitesService: SitesService,
    private assetsService: AssetsService,
    private devicesService: DevicesService,
    private dateFormatPipe: DateFormatPipe,
    private translateService: TranslateService,
    private assetEventsService: AssetEventsService,
    private deviceEventsService: DeviceEventsService,
    private productsStore: Store<fromProducts.State>
  ) {}

  public getCSVFile(type: string, filters: Filter[] = [], fileName: string) {
    const params: PlatformRequest = { page: 0, limit: 100, filters: filters };
    this.isFileDownloaded$.next(false);
    this.data = [];
    this.loaderValue = 0;
    this.loaderInterval = 0;

    switch (type) {
      case 'sites': {
        this.buildFile(fileName, 'SITES', params, new SitesFormatter(this.sitesService));
        break;
      }

      case 'assets': {
        this.buildFile(fileName, 'ASSETS', params, new AssetsFormatter(this.assetsService));
        break;
      }

      case 'devices': {
        this.buildFile(fileName, 'DEVICES', params, new DevicesFormatter(this.devicesService));
        break;
      }

      case 'asset-events': {
        this.buildFile(fileName, 'EVENTS', params, new AssetEventsFormatter(this.assetEventsService));
        break;
      }

      case 'device-events': {
        this.buildFile(fileName, 'EVENTS', params, new DeviceEventsFormatter(this.deviceEventsService));
        break;
      }

      case 'products': {
        this.buildFile(fileName, 'ADMIN.PRODUCT_CATALOGS', params, new ProductsFormatter(this.productsStore));
        break;
      }
    }
  }

  ngOnDestroy() {
    this.cancel$.next(true);
    this.cancel$.complete();
  }

  private buildFile(fileName: string, translationKey: string, params: PlatformRequest, formatter: ExportsFormatter) {
    this.subscription = this.loadData$
      .pipe(
        switchMap(() => formatter.formatRequest(params)),
        takeUntil(this.cancel$)
      )
      .subscribe((response) => {
        this.handleLoader(response);
        this.data = [...this.data, ...response.data];
        if (response.hasMore) {
          this.loadData$.next(++params.page);
        } else {
          this.data = this.formatDate(this.data, translationKey);
          const titles = this.translateService.instant(translationKey);
          this.formatFile(this.data, titles, fileName, formatter);
        }
      });
    this.loadData$.next(params.page);
  }

  private formatDate(data: Array<any>, key: string): Array<any> {
    return key === 'EVENTS'
      ? data.map((element: Event) => {
          const formattedEvent = {
            ...element,
            receptionTime: this.dateFormatPipe.transform(element.receptionTime),
            occurrenceTime: this.dateFormatPipe.transform(element.occurrenceTime)
          };
          if (element.acknowledge) {
            formattedEvent.acknowledge.datetime = this.dateFormatPipe.transform(element.acknowledge.datetime);
          }
          if (element.close) {
            formattedEvent.close.datetime = this.dateFormatPipe.transform(element.close.datetime);
          }
          return formattedEvent;
        })
      : key === 'ASSETS'
      ? data.map((element: Asset) => {
          if (element.status.datetime) {
            element.status.datetime = this.dateFormatPipe.transform(element.status.datetime);
          }
          if (element.followedVariables) {
            if (element.followedVariables['1']) {
              element.followedVariables['1'].datetime = this.dateFormatPipe.transform(element.followedVariables['1'].datetime);
            }
            if (element.followedVariables['2']) {
              element.followedVariables['2'].datetime = this.dateFormatPipe.transform(element.followedVariables['2'].datetime);
            }
            if (element.followedVariables['3']) {
              element.followedVariables['3'].datetime = this.dateFormatPipe.transform(element.followedVariables['3'].datetime);
            }
            return element;
          }
          return element;
        })
      : key === 'DEVICES'
      ? data.map((element: Device) => {
          if (element.lastCallStatus) {
            element.lastCallStatus.datetime = this.dateFormatPipe.transform(element.lastCallStatus.datetime);
            return element;
          }
          return element;
        })
      : data;
  }

  private formatFile(data: Array<any>, titles: any, fileName: string, formatter: ExportsFormatter) {
    const header = formatter.formatHeader(titles);
    const body = data.map(formatter.formatBody);
    const file = [header, ...body];
    this.downloadFile(file, fileName);
  }

  private downloadFile(file: Array<any>, fileName: string) {
    const blob = new Blob(file, { endings: 'transparent', type: 'data:text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `${this.dateFormatPipe.transform(Date.now(), 'yyyyMMdd')}-${fileName}.csv`);
    a.click();
    this.isFileDownloaded$.next(true);
    this.subscription.unsubscribe();
  }

  private handleLoader({ currentPage, maxPage }: PlatformResponse) {
    if (currentPage === 0 && maxPage !== 0) {
      this.loaderInterval = 100 / (maxPage + 1);
    }
    if (currentPage === 0 && maxPage === 0) {
      this.loaderInterval = 100;
    }
    this.loaderValue$.next((this.loaderValue = this.loaderValue + this.loaderInterval));
  }
}
