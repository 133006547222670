import { User } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const loadUserPreferences = createAction('[Users/Preferences] Load UserPreferences');
export const loadUserPreferencesSuccess = createAction('[Users/Preferences] Load UserPreferences Success', props<{ user: User; preferences: any }>());
export const loadUserPreferencesFailure = createAction('[Users/Preferences] Load UserPreferences Failure', props<{ error: any }>());

export const saveUserPreferences = createAction('[Users/Preferences] Save UserPreferences', props<{ user: User; preferences: any }>());
export const saveUserPreferencesSuccess = createAction('[Users/Preferences] Save UserPreferences Success', props<{ user: User; preferences: any }>());
export const saveUserPreferencesFailure = createAction('[Users/Preferences] Save UserPreferences Failure', props<{ error: any }>());

export const changeLanguage = createAction('[Users/Preferences] Change Language', props<{ lang: string }>());
