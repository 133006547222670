<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ (data?.user ? 'ADMIN.DIALOG.USER_FORM.EDIT_TITLE' : 'ADMIN.DIALOG.USER_FORM.CREATE_TITLE') | translate }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content fxLayout="column" class="dialog-card-content p-20">
    <form [formGroup]="usersForm" fxLayout="column" fxFlex fxLayoutGap="10px">
      <div fxLayout="row" fxFlex fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <mat-form-field fxFlex color="accent">
          <input matInput formControlName="firstname" required maxlength="50" [placeholder]="'ADMIN.DIALOG.USER_FORM.FIRST_NAME_PLACEHOLDER' | translate" />
        </mat-form-field>
        <mat-form-field fxFlex color="accent">
          <input matInput formControlName="lastname" required maxlength="50" [placeholder]="'ADMIN.DIALOG.USER_FORM.LAST_NAME_PLACEHOLDER' | translate" />
        </mat-form-field>
        <mat-form-field fxFlex color="accent">
          <input
            matInput
            formControlName="email"
            (input)="emailControl.setValue($event.target.value.toLowerCase().trim())"
            required
            maxlength="50"
            [placeholder]="'ADMIN.DIALOG.USER_FORM.EMAIL_PLACEHOLDER' | translate"
          />
          <mat-error *ngIf="emailControl.invalid && emailControl.errors.used">{{ 'ADMIN.DIALOG.COMMON.ERROR_MESSAGE.DUPLICATE_EMAIL' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxFlex fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <mat-form-field class="" fxFlex color="accent">
          <mat-select
            [placeholder]="'USERS.PROFILE_PREFERENCES.LANGUAGE' | translate"
            formControlName="language"
            ngDefaultControl
            matInput
          >
            <mat-option *ngFor="let language of availableLanguages" [value]="language">
              {{ 'USERS.PROFILE_PREFERENCES.LANGUAGES.' + language | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex color="accent">
          <mat-select required formControlName="country" [placeholder]="'IOT_DICTIONARY.COUNTRY' | translate">
            <mat-option [value]="country.key" *ngFor="let country of countries$ | async">{{ country.value }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="close()">{{ 'ADMIN.DIALOG.USER_FORM.CANCEL' | translate | uppercase }}</button>
    <button mat-raised-button color="accent" class="button-regular" [disabled]="isUserFormDisabled()" (click)="save()">
      {{ 'ADMIN.DIALOG.USER_FORM.SAVE' | translate | uppercase }}
    </button>
  </mat-card-actions>
</mat-card>
