import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { OrganizationsService } from '../../services/organizations.service';
import { AdminOrganizationsAdministratorsApiActions, AdminOrganizationsAdministratorsPageActions } from '../actions';

@Injectable()
export class AdminOrganizationsAdministratorsEffects {
  addAdministrator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsAdministratorsPageActions.addAdministratorToOrganization),
      mergeMap(action =>
        this.organizationsService.addAdministratorToOrganization(action.organizationId, action.administratorToAdd).pipe(
          map(addedAdministrator => AdminOrganizationsAdministratorsApiActions.addAdministratorToOrganizationSuccess({ addedAdministrator })),
          catchError(error => of(AdminOrganizationsAdministratorsApiActions.addAdministratorToOrganizationFailure({ error })))
        )
      )
    )
  );

  removeAdministrator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrganizationsAdministratorsPageActions.removeAdministratorFromOrganization),
      mergeMap(action =>
        this.organizationsService.removeAdministratorFromOrganization(action.organizationId, action.administratorToRemove).pipe(
          map(removedAdministratorId => AdminOrganizationsAdministratorsApiActions.removeAdministratorFromOrganizationSuccess({ removedAdministratorId })),
          catchError(error => of(AdminOrganizationsAdministratorsApiActions.removeAdministratorFromOrganizationFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private organizationsService: OrganizationsService) {}
}
