<div *ngIf="currentUser" fxLayout="column" class="user-profile-info-container">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="user-profile-info-section-title">
    <h2 class="fw-600">{{ 'USERS.PROFILE_PREFERENCES.TITLE' | translate }}</h2>
    <hr class="user-profile-preferences-line" fxFlex />
    <button
      mat-icon-button
      [matTooltip]="'USERS.PROFILE_PREFERENCES.EDIT_TOOLTIP' | translate"
      matTooltipClass="regular-tooltip"
      matTooltipPosition="above"
      class="regular-round-button"
      (click)="changeUserPreferences.emit()"
    >
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <div fxLayout="column" fxLayoutGap="6px">
    <iot-platform-ui-detail-popup-display-property
      [property]="'USERS.PROFILE_PREFERENCES.LANGUAGE' | translate"
      [value]="'USERS.PROFILE_PREFERENCES.LANGUAGES.' + currentUser?.preferences?.appLanguage | translate"
    >
    </iot-platform-ui-detail-popup-display-property>

    <iot-platform-ui-detail-popup-display-property
      [property]="'USERS.PROFILE_PREFERENCES.NUMBER_FORMAT' | translate"
      [value]="'USERS.PROFILE_PREFERENCES.NUMBER_FORMATS.' + (currentUser?.preferences?.appNumberFormat ?? defaultAppNumberFormat) | translate"
    >
    </iot-platform-ui-detail-popup-display-property>

    <iot-platform-ui-detail-popup-display-property
      [property]="'USERS.PROFILE_PREFERENCES.NUMBER_OF_DECIMALS' | translate"
      [value]="currentUser?.preferences?.appNumberOfDecimals ?? defaultAppNumberOfDecimal"
    >
    </iot-platform-ui-detail-popup-display-property>

    <iot-platform-ui-detail-popup-display-property
      [property]="'USERS.PROFILE_PREFERENCES.DATE_FORMAT' | translate"
      [value]="today | dateFormat: currentUser?.preferences?.appDateFormats?.momentFullFormat ?? defaultAppDateFormat"
    >
    </iot-platform-ui-detail-popup-display-property>

    <iot-platform-ui-detail-popup-display-property
      *ngIf="withNotifications"
      [property]="'USERS.PROFILE_PREFERENCES.NOTIFICATIONS' | translate"
      [value]="getNotifications()"
    >
    </iot-platform-ui-detail-popup-display-property>
  </div>
</div>
