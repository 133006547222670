import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FavoriteView } from '@iot-platform/models/common';

import { NotificationService } from '@iot-platform/notification';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { AuthBusinessProfilesApiActions } from '../../../../../../auth/src/lib/state/actions';
import { GridsDbActions } from '../../../../../../grid-engine/src/lib/components/state/actions';
import {
  BusinessProfilesFavoriteViewsApiActions
} from '../../../../../../iot4bos-backoffice-ui/src/lib/features/admin-business-profiles/state/actions';

import { FavoriteViewsService } from '../../services/favorite-views.service';
import { FavoriteViewsActions } from '../actions';

@Injectable()
export class FavoriteViewsEffects {
  loadFavoriteViews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.loadFavoriteViews, FavoriteViewsActions.deleteFavoriteViewSuccess),
      switchMap(() =>
        this.favoriteViewsService.getFavoriteViews().pipe(
          concatMap((favoriteViews) => {
            if (this.router.url === '/home') {
              const pinnedFavoriteViews: FavoriteView[] = favoriteViews.filter((fV) => fV.pinned);
              return [
                FavoriteViewsActions.loadFavoriteViewsSuccess({ favoriteViews }),
                ...pinnedFavoriteViews.map((favoriteView: FavoriteView) => FavoriteViewsActions.getCountForFavoriteView({ favoriteView }))
              ];
            } else {
              return [FavoriteViewsActions.loadFavoriteViewsSuccess({ favoriteViews })];
            }
          }),
          catchError((error) => of(FavoriteViewsActions.loadFavoriteViewsError({ error })))
        )
      )
    )
  );

  loadFavoriteViewsWithCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.loadFavoriteViewsWithCount),
      switchMap(() =>
        this.favoriteViewsService.getFavoriteViews().pipe(
          concatMap((favoriteViews) => {
            const pinnedFavoriteViews: FavoriteView[] = favoriteViews.filter((fV) => fV.pinned);
            return [
              FavoriteViewsActions.loadFavoriteViewsSuccess({ favoriteViews }),
              ...pinnedFavoriteViews.map((favoriteView: FavoriteView) => FavoriteViewsActions.getCountForFavoriteView({ favoriteView }))
            ];
          }),
          catchError((error) => of(FavoriteViewsActions.loadFavoriteViewsError({ error })))
        )
      )
    )
  );

  getCountForFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.getCountForFavoriteView),
      mergeMap((action) =>
        this.favoriteViewsService.getFavoriteViewCount(action.favoriteView).pipe(
          map((favoriteView: FavoriteView) => {
            return FavoriteViewsActions.getCountForFavoriteViewSuccess({ favoriteView });
          }),
          catchError((error) => of(FavoriteViewsActions.getCountForFavoriteViewFailure({ error })))
        )
      )
    )
  );

  /*loadFavoriteViewsAfterSwitchingBp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FavoriteViewsActions.loadFavoriteViews,
        FavoriteViewsActions.deleteFavoriteViewSuccess,
      ),
      concatMap(() =>
        this.favoriteViewsService.getFavoriteViews().pipe(
          tap(_ => console.log('FavoriteViewsEffects::loadFavoriteViews$')),
          map(favoriteViews => {
            return FavoriteViewsActions.loadFavoriteViewsSuccess({ favoriteViews });
          }, catchError(error => of(FavoriteViewsActions.loadFavoriteViewsError({ error }))))
        )
      )
    )
  );*/

  setCurrentFavoriteView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FavoriteViewsActions.setCurrentFavoriteView),
        tap((action) => {
          if (action.favoriteView) {
            this.router.navigate(['/' + action.favoriteView.masterView]);
          }
        })
      ),
    { dispatch: false }
  );

  loadFavoriteViewsWhenSwitchingBusinessProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess),
      /*tap((_) => console.log('loadFavoriteViewsWithCountWhenSwitchingBusinessProfile$')),*/
      map(() => {
        /*console.log('FavoriteViewsActions.loadFavoriteViewsWithCount()');*/

        if (this.router.url === '/home') {
          return FavoriteViewsActions.loadFavoriteViewsWithCount();
        } else {
          return FavoriteViewsActions.loadFavoriteViews();
        }
      })
    )
  );

  /*navigateToMasterView(favoriteView: FavoriteView) {
    const request: PlatformRequest = {
      page: 0,
      limit: 25,
      filters: favoriteView.filters
    };

    switch (favoriteView.masterView) {
      case 'sites':
        this.sitesStore.dispatch(new SitesActions.LoadSites({ request }));
        break;
      case 'assets':
        this.assetsStore.dispatch(new AssetsActions.LoadAssets({ request }));
        break;
      case 'devices':
        this.devicesStore.dispatch(new DevicesActions.LoadDevices({ request }));
        break;
      case 'asset-events':
        this.assetEventsStore.dispatch(new AssetEventsActions.LoadAssetEvents({ request }));
        break;
      case 'device-events':
        this.deviceEventsStore.dispatch(new DeviceEventsActions.LoadDeviceEvents({ request }));
        break;

      default:
    }
    this.fVStore.dispatch(FavoriteViewsActions.setCurrentFavoriteView({ masterView: favoriteView.masterView, favoriteView: favoriteView }));

    // TODO : no logic in facade. This has to be moved in effects
    this.router.navigate(['/' + favoriteView.masterView]);
  }*/

  saveFavoriteViews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.addFavoriteView),
      switchMap(({ favoriteView }) =>
        this.favoriteViewsService.saveFavoriteView(favoriteView).pipe(
          concatMap((fV) => [
            FavoriteViewsActions.addFavoriteViewSuccess({ favoriteView: fV }),
            BusinessProfilesFavoriteViewsApiActions.addFavoriteViewSuccess({ addedFavoriteView: fV })
          ]),
          catchError((error) => of(FavoriteViewsActions.addFavoriteViewError({ error })))
        )
      )
    )
  );

  shareGridThenSaveFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.shareGridThenAddFavoriteView),
      switchMap((action) =>
        this.favoriteViewsService.shareGrid(action.grid, action.favoriteView).pipe(
          concatMap(({ grid, favoriteView }) => [FavoriteViewsActions.addFavoriteView({ favoriteView }), GridsDbActions.updateGridSuccess({ grid })]),
          catchError((error) => of(FavoriteViewsActions.shareGridThenAddFavoriteViewError))
        )
      )
    )
  );

  updateFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.updateFavoriteView),
      switchMap(({ favoriteView }) =>
        this.favoriteViewsService.updateFavoriteView(favoriteView).pipe(
          map((updatedFavoriteView) => FavoriteViewsActions.updateFavoriteViewSuccess({ favoriteView: updatedFavoriteView })),
          catchError((error) => of(FavoriteViewsActions.updateFavoriteViewError({ error })))
        )
      )
    )
  );

  shareGridThenUpdateFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.shareGridThenUpdateFavoriteView),
      switchMap((action) =>
        this.favoriteViewsService.shareGrid(action.grid, action.favoriteView).pipe(
          concatMap(({ grid, favoriteView }) => [FavoriteViewsActions.updateFavoriteView({ favoriteView }), GridsDbActions.updateGridSuccess({ grid })]),
          catchError((error) => of(FavoriteViewsActions.shareGridThenUpdateFavoriteViewError))
        )
      )
    )
  );

  deleteFavoriteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteViewsActions.deleteFavoriteView),
      switchMap(({ favoriteView }) =>
        this.favoriteViewsService.deleteFavoriteView(favoriteView).pipe(
          concatMap((deletedFavoriteView) => [FavoriteViewsActions.deleteFavoriteViewSuccess({ deletedFavoriteView })]),
          catchError((error) => of(FavoriteViewsActions.deleteFavoriteViewError({ error })))
        )
      )
    )
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          FavoriteViewsActions.addFavoriteViewSuccess,
          FavoriteViewsActions.updateFavoriteViewSuccess,
          FavoriteViewsActions.duplicateFavoriteViewSuccess,
          FavoriteViewsActions.deleteFavoriteViewSuccess
        ),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          FavoriteViewsActions.addFavoriteViewError,
          FavoriteViewsActions.updateFavoriteViewError,
          FavoriteViewsActions.duplicateFavoriteViewError,
          FavoriteViewsActions.deleteFavoriteViewError
        ),
        tap((action) => this.notificationService.displayError(action))
      ),
    { dispatch: false }
  );

  showLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FavoriteViewsActions.loadFavoriteViews),
        tap(() => this.notificationService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FavoriteViewsActions.loadFavoriteViewsError, FavoriteViewsActions.loadFavoriteViewsSuccess),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private favoriteViewsService: FavoriteViewsService,
    private notificationService: NotificationService,
    private router: Router
  ) {}
}
