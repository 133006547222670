import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CustomCellParams } from '../custom-cell.params';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'grid-engine-outgoing-connector-cell',
  templateUrl: './outgoing-connector-cell.component.html',
  styleUrls: ['./outgoing-connector-cell.component.scss']
})
export class OutgoingConnectorCellComponent implements ICellRendererAngularComp {
  params: CustomCellParams;
  cellOptions: any;
  displayInfoIcon$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  agInit(params: CustomCellParams): void {
    this.params = params;
    const t = params.data.hasOwnProperty('hasCredential') && typeof params.data.hasCredential === 'boolean' && params.data.hasCredential === false;
    this.displayInfoIcon$.next(t);
    this.cellOptions = this.params.cellOptions;
  }

  refresh(): boolean {
    return false;
  }
}
