import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ApiSelector } from './api-selector.model';

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/ban-types */
export abstract class BaseFacade<T, P, F> {
  all$: Observable<T[]>;
  total$: Observable<number>;
  currentEntity$: Observable<T | null>;
  pagination$: Observable<P | null>;
  platformResponse$: Observable<{
    data: T[];
    currentPage: number;
    hasMore: boolean;
    limit: number;
    maxPage: number;
    total: number;
  }>;
  loading$: Observable<boolean>;
  loaded$: Observable<boolean>;
  filters$: Observable<F[]>;
  error$: Observable<unknown>;

  protected constructor(protected store: Store, protected selector: ApiSelector<any, T, P, F>) {
    this.all$ = this.store.select(this.selector.selectAll);
    this.currentEntity$ = this.store.select(this.selector.selectCurrentEntity);
    this.total$ = this.store.select(this.selector.selectTotal);
    this.pagination$ = this.store.select(this.selector.selectPagination);
    this.platformResponse$ = this.store.select(this.selector.selectPlatformResponse);
    this.loading$ = this.store.select(this.selector.selectLoading);
    this.loaded$ = this.store.select(this.selector.selectLoaded);
    this.filters$ = this.store.select(this.selector.selectFilters);
    this.error$ = this.store.select(this.selector.selectError);
  }

  abstract setFilters(filters: F[]): void;
  abstract getAll(params?: any): void;
}
