import { Component, Input } from '@angular/core';

@Component({
  selector: 'iot4bos-ui-default-card',
  templateUrl: './default-card.component.html',
  styleUrls: ['./default-card.component.scss']
})
export class DefaultCardComponent {
  @Input() title: string;
  @Input() backgroundClass: string;
  @Input() withCustomButton: boolean;
}
