import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'iot-platform-ui-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() data: any;
  @Input() icon!: string;
  @Input() title!: string;
  @Input() label!: string;
  @Input() passive!: boolean;
  @Input() loading!: boolean;
  @Input() backButtonHidden!: boolean;

  @Output() back: EventEmitter<any> = new EventEmitter();
}
