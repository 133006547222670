<div fxFlex fxLayout="column" class="stock-management-shell-wrapper">
  <iot4bos-ui-stock-card
    *ngIf="site"
    [site]="site"
    [deviceCountByFamily]="deviceCountByFamily$ | async"
    [deviceCountByFamilyLoading]="deviceCountByFamilyLoading$ | async"
    [totalDevices]="gridTotalElements$ | async"
    [totalDevicesLoading]="!(grid$ | async)"
  ></iot4bos-ui-stock-card>

  <div class="layout-master-view-engine-container stock-grid-wrapper">
    <grid-engine-grid-manager
      fxFlex
      [grid]="grid$ | async"
      [gridSort]="gridSort$ | async"
      [currentFilters]="currentFilters"
      [userPermissions]="userPermissions"
      (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
    >
    </grid-engine-grid-manager>
  </div>
</div>
