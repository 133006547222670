<h2 class="login-regular-title">{{ 'LOGIN.CHANGE_PASSWORD.TITLE' | translate }}</h2>

<form [formGroup]="form" fxLayout="column">
  <mat-form-field fxFlex="100%" color="accent">
    <mat-icon matPrefix>verified_user</mat-icon>
    <input matInput formControlName="code" type="text" [placeholder]="'LOGIN.CODE' | translate" />
    <mat-icon
      class="layout-pointer-cursor layout-button-rotation"
      matSuffix
      [matTooltip]="'LOGIN.TOOLTIP' | translate"
      matTooltipClass="regular-tooltip"
      (click)="onSendCode($event)"
      >replay</mat-icon
    >
    <mat-error *ngIf="code.invalid">{{ 'LOGIN.ERROR_MESSAGE.CODE' | translate }}</mat-error>
    <mat-hint >{{ 'LOGIN.HELP_MESSAGE.CHECK_EMAIL' | translate }}</mat-hint>
  </mat-form-field>

  <mat-form-field fxFlex="100%" color="accent">
    <mat-icon matPrefix>lock</mat-icon>
    <input matInput formControlName="password" type="password" [placeholder]="'LOGIN.PASSWORD' | translate" (keyup)="sendPasswordToRules(password.value)" />
    <mat-error *ngIf="password.invalid">{{ 'LOGIN.ERROR_MESSAGE.PASSWORD' | translate }}</mat-error>
  </mat-form-field>

  <mat-form-field fxFlex="100%" color="accent">
    <mat-icon matPrefix>lock</mat-icon>
    <input matInput formControlName="confirmPassword" type="password" [placeholder]="'LOGIN.CONFIRM_PASSWORD' | translate" />
    <mat-error *ngIf="confirmPassword.invalid">{{ 'LOGIN.ERROR_MESSAGE.CONFIRM_PASSWORD' | translate }}</mat-error>
  </mat-form-field>

  <iot-platform-login-password-rules [password$]="password$"></iot-platform-login-password-rules>

  <div class="layout-login-error" fxLayout="column" [class.error]="hasError">
    <em>{{ 'LOGIN.ERROR_MESSAGE.RESETTING_PASSWORD' | translate }}</em>
    <span>{{ hasError?.message }}</span>
  </div>

  <div class="layout-login-form-actions">
    <button class="layout-login-button" type="button" mat-button (click)="onCancel()">{{ 'LOGIN.BUTTON.CANCEL' | translate }}</button>
    <iot-platform-ui-progress-bar-button
      type="submit"
      [title]="'LOGIN.BUTTON.CONFIRM' | translate"
      [disabled]="!form.valid"
      [enableGauge]="enableGauge"
      (clickEvent)="onChangePassword()"
    ></iot-platform-ui-progress-bar-button>
  </div>
</form>
