<form [formGroup]="dynamicListForm" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
  <iot-platform-ui-async-autocomplete
    *ngIf="data.autocomplete && !data.multiSelect"
    [data]="dynamicList"
    [standaloneMode]="true"
    [showSpinner]="showLoader"
    [displaySearchIcon]="false"
    [minLength]="1"
    [displayKey]="data.selectByProperty"
    [placeholder]="data.placeholder"
    [filterKey]="data.selectByProperty"
    (selectionChanged)="onSelectionChange($event)"
  >
  </iot-platform-ui-async-autocomplete>

  <iot-platform-ui-async-autocomplete-multiple-selects
    *ngIf="data.autocomplete && data.multiSelect"
    [data]="dynamicList"
    [standaloneMode]="true"
    [showSpinner]="showLoader"
    [displaySearchIcon]="false"
    [minLength]="1"
    [displayKey]="data.selectByProperty"
    [placeholder]="data.placeholder"
    [filterKey]="data.selectByProperty"
    [currentFilters$]="currentFilters$"
    [currentFiltersSize]="currentFiltersSize"
    [multiSelect]="data.multiSelect"
    (selectionChanged)="onSelectionChange($event)"
  >
  </iot-platform-ui-async-autocomplete-multiple-selects>

  <div *ngIf="!data.autocomplete">
    <mat-form-field class="select-field" color="accent">
      <mat-label fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
        <span fxFlex="90" class="filter-label">{{ data.placeholder ?? data.criteriaLabel | translate }}</span>
        <mat-progress-spinner fxFlex="10" *ngIf="showLoader" mode="indeterminate" [diameter]="16" color="accent"></mat-progress-spinner>
      </mat-label>
      <mat-select
        data-cy="iot-platform-ui-dynamic-list-field-single-select"
        formControlName="select"
        (selectionChange)="onSelectionChange()"
        [compareWith]="compareFn"
        [multiple]="data.multiSelect"
      >
        <ng-container *ngIf="!showLoader">
          <mat-option
            data-cy="iot-platform-ui-dynamic-list-field-single-select-option"
            [value]="option"
            *ngFor="let option of dynamicList"
            [disabled]="currentFiltersSize >= maxFilters"
            >{{ getOptionToDisplay(option) }}</mat-option
          >
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
</form>
