import { I4BCellType, I4BColumnConfiguration } from './configurations';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import {
  DeviceCommunicationGroupColumn,
  DeviceTypeGroupColumn,
  I4BBasicColumn,
  I4BColumn,
  I4BDeviceButtonColumn,
  I4BDeviceStatusColumn,
  I4BSelectionColumn,
  IdColumn,
  NameColumn,
  SiteAddressColumn,
  TotalColumn,
  TotalEventsColumn,
  TotalTagsColumn,
  UpdatedGroupColumn
} from './definitions';
import { DiagnosticVariableGroupColumn } from './definitions/i4b-diagnostic-variable-group.column';
import { I4bCellConcept } from './enums';
import { DevicesGridData, HeaderType, I4BColumnHeader, I4BGrid, I4BGridOptions } from './models';
import { I4BColumnOptions } from './options';

export const DEFAULT_SITES_COLUMNS_FOR_DEVICES_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new IdColumn(
    { displayName: 'SITE BUSINESS ID' },
    {
      id: 'site.businessId',
      concept: I4bCellConcept.SITES,
      width: 150
    }
  ),
  new NameColumn(
    { displayName: 'SITE NAME', type: HeaderType.ICON_AND_TEXT, icon: 'site' },
    {
      id: 'site.name',
      sortProperty: 'site.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE_NAME',
      concept: I4bCellConcept.SITES,
      isDefault: true,
      isConfigurable: true,
      isLink: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToSite' } }
    },
    { order: 4 }
  ),
  new NameColumn(
    { displayName: 'SITE NAME 2', type: HeaderType.ICON_AND_TEXT, icon: 'site' },
    {
      id: 'site.name2',
      sortProperty: 'site.name2',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE_NAME',
      concept: I4bCellConcept.SITES,
      isDefault: false,
      isLink: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToSite' } }
    },
    { order: 5 }
  ),

  new SiteAddressColumn({}, { id: 'site.address', concept: I4bCellConcept.SITES }, {}),
  new I4BBasicColumn({ displayName: 'ADDRESS1' }, { id: 'site.address.address1', width: 100, concept: I4bCellConcept.SITES, isDefault: false }, {}),
  new I4BBasicColumn({ displayName: 'ADDRESS2' }, { id: 'site.address.address2', width: 100, concept: I4bCellConcept.SITES, isDefault: false }, {}),
  new I4BBasicColumn({ displayName: 'ZIP CODE' }, { id: 'site.address.zipCode', width: 60, concept: I4bCellConcept.SITES, isDefault: false }, {}),
  new I4BBasicColumn({ displayName: 'STATE' }, { id: 'site.address.state', width: 40, concept: I4bCellConcept.SITES, isDefault: false }, {}),
  new I4BBasicColumn({ displayName: 'CITY' }, { id: 'site.address.city', width: 100, concept: I4bCellConcept.SITES, isDefault: false }, {}),
  new I4BBasicColumn(
    { displayName: 'COUNTRY' },
    {
      id: 'site.address.country',
      width: 100,
      concept: I4bCellConcept.SITES,
      isDefault: false,
      cell: { type: I4BCellType.TRANSLATED_CELL, options: { translateKey: 'SITES.CARD.COUNTRIES.' } }
    },
    {}
  ),
  new I4BBasicColumn(
    { displayName: 'SITE TYPE' },
    {
      id: 'site.type',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.SITE_TYPE',
      cell: { type: I4BCellType.TRANSLATED_CELL, options: { translateKey: 'SITES.CARD.TYPES.' } },
      isDefault: false,
      sortProperty: 'site.type',
      concept: I4bCellConcept.SITES,
      width: 100
    },
    {
      order: 12
    }
  ),
  new I4BBasicColumn(
    { displayName: 'DESCRIPTION' },
    {
      id: 'site.description',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DESCRIPTION',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'site.description',
      concept: I4bCellConcept.SITES,
      width: 100
    },
    {
      order: 12
    }
  )
];

export const DEFAULT_DEVICES_COLUMNS_FOR_DEVICES_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new IdColumn(
    { displayName: 'IDENTIFIER' },
    {
      id: 'identifier',
      concept: I4bCellConcept.DEVICES,
      width: 150,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToDevice' } }
    }
  ),
  new NameColumn(
    { displayName: 'NAME', type: HeaderType.ICON_AND_TEXT, icon: 'device' },
    {
      id: 'name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_NAME',
      concept: I4bCellConcept.DEVICES,
      isDefault: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToDevice' } }
    },
    { order: 1 }
  ),
  new I4BBasicColumn(
    { displayName: 'CYCLICAL CALL FAULT', type: HeaderType.EMPTY },
    {
      id: 'cyclicalCallFault',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DESCRIPTION',
      cell: { type: I4BCellType.CYCLICAL_CALL_FAULT, valueGetter: 'cyclicalCallFault.status' },
      isDefault: true,
      isConfigurable: false,
      sortProperty: 'cyclicalCallFault.status',
      concept: I4bCellConcept.DEVICES,
      width: 75
    },
    {
      order: 1
    }
  ),
  new I4BBasicColumn(
    { displayName: 'DESCRIPTION' },
    {
      id: 'description',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DESCRIPTION',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'description',
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    {
      order: 12
    }
  ),
  new DeviceTypeGroupColumn({ displayName: 'DEVICE TYPE FULL' }, { id: 'deviceType' }, { order: 13 }),
  new I4BBasicColumn(
    { displayName: 'FAMILY' },
    {
      id: 'type.family',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FAMILY',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      sortProperty: 'type.family',
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    {
      order: 14
    }
  ),
  new I4BBasicColumn(
    { displayName: 'MODEL' },
    {
      id: 'type.model',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_MODEL',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'type.model',
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    {
      order: 15
    }
  ),
  new I4BBasicColumn(
    { displayName: 'FIRMWARE' },
    {
      id: 'type.firmware',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FIRMWARE',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'type.firmware',
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    {
      order: 16
    }
  ),
  new I4BDeviceStatusColumn({}, { id: 'status.name', concept: I4bCellConcept.DEVICES }, { order: 13 }),
  new I4BBasicColumn(
    { displayName: 'LAST INCOMING CALL', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_INCOMING_CALL',
      id: 'lastCallStatus.datetime',
      isDefault: true,
      concept: I4bCellConcept.DEVICES,
      width: 165,
      cell: {
        type: I4BCellType.DATE
      }
    },
    {
      order: 9
    }
  ),
  new DeviceCommunicationGroupColumn({ displayName: 'COMMUNICATION FULL' }, { id: 'communication' }, { order: 10 }),
  new I4BBasicColumn(
    { displayName: 'SUPPORT' },
    {
      id: 'communication.support',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.COMMUNICATION_SUPPORT',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      sortProperty: 'communication.support',
      concept: I4bCellConcept.DEVICES,
      width: 120
    },
    {
      order: 12
    }
  ),
  new I4BBasicColumn(
    { displayName: 'ENDPOINT' },
    {
      id: 'communication.endpoint',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.COMMUNICATION_ENDPOINT',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'communication.endpoint',
      concept: I4bCellConcept.DEVICES,
      width: 120
    },
    {
      order: 13
    }
  ),
  new I4BBasicColumn(
    { displayName: 'INCOMING CONNECTOR NAME', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.INCOMING_CONNECTOR_NAME',
      id: 'incomingConnector.name',
      isDefault: false,
      sortProperty: 'incomingConnector.name',
      concept: I4bCellConcept.DEVICES,
      width: 120,
      cell: {
        type: I4BCellType.BASIC
      }
    },
    {
      order: 14
    }
  ),
  new I4BBasicColumn(
    { displayName: 'INCOMING CONNECTOR ID', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.INCOMING_CONNECTOR_ID',
      id: 'incomingConnector.id',
      isDefault: false,
      sortProperty: 'incomingConnector.id',
      concept: I4bCellConcept.DEVICES,
      width: 100,
      cell: {
        type: I4BCellType.BASIC
      }
    },
    {
      order: 15
    }
  ),
  new I4BBasicColumn(
    { displayName: 'OUTGOING CONNECTOR NAME', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OUTGOING_CONNECTOR_NAME',
      id: 'outgoingConnector.name',
      isDefault: false,
      sortProperty: 'outgoingConnector.name',
      concept: I4bCellConcept.DEVICES,
      width: 120,
      cell: {
        type: I4BCellType.BASIC
      }
    },
    {
      order: 15
    }
  ),
  new I4BBasicColumn(
    { displayName: 'OUTGOING CONNECTOR ID', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OUTGOING_CONNECTOR_ID',
      id: 'outgoingConnector.id',
      isDefault: false,
      sortProperty: 'outgoingConnector.id',
      concept: I4bCellConcept.DEVICES,
      width: 100,
      cell: {
        type: I4BCellType.OUTGOING_CONNECTOR
      }
    },
    {
      order: 15
    }
  ),
  new I4BBasicColumn(
    { displayName: 'ENTITY NAME', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ENTITY_NAME',
      id: 'entity.name',
      isDefault: true,
      sortProperty: 'entity.name',
      concept: I4bCellConcept.DEVICES,
      width: 100,
      cell: {
        type: I4BCellType.BASIC
      }
    },
    {
      order: 15
    }
  ),

  new TotalEventsColumn(
    { displayName: 'TOTAL DEVICE EVENTS', icon: 'device-event' },
    { id: 'totalActiveEvents', concept: I4bCellConcept.DEVICES, isDefault: true },
    { order: 20 }
  ),
  new TotalTagsColumn({}, { concept: I4bCellConcept.DEVICES, isDefault: true }, { order: 21 }),
  new TotalColumn(
    { displayName: 'TOTAL VARIABLES', icon: 'variable' },
    { id: 'totalVariables', concept: I4bCellConcept.DEVICES, isDefault: true },
    { order: 20 }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST COMMAND STATUS' },
    {
      id: 'lastCommandStatus.name',
      concept: I4bCellConcept.DEVICES,
      isDefault: false,
      sortProperty: 'lastCommandStatus.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_STATUS',
      cell: { type: I4BCellType.LAST_COMMAND_STATUS_CELL }
    },
    { order: 21 }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST COMMAND DATE', type: HeaderType.BASIC },
    {
      id: 'lastCommandStatus.datetime',
      cell: { type: I4BCellType.DATE },
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_DATE',
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 165
    },
    {
      order: 22
    }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST COMMAND SENT' },
    {
      id: 'lastCommandStatus.command',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_SENT',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    {
      order: 23
    }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST COMMAND MESSAGE' },
    {
      id: 'lastCommandStatus.status',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_MESSAGE',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    {
      order: 24
    }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST COMMAND ERROR' },
    {
      id: 'lastCommandStatus.commandErrorReason',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_ERROR',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    {
      order: 25
    }
  ),
  new I4BBasicColumn(
    { displayName: 'ICCID' },
    {
      id: 'communication.iccid',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ICCID',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    {
      order: 26
    }
  ),
  new I4BBasicColumn(
    { displayName: 'OPERATOR' },
    {
      id: 'communication.operator',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OPERATOR',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    {
      order: 27
    }
  ),
  new I4BBasicColumn(
    { displayName: 'AFFILIATE' },
    {
      id: 'affiliate',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.AFFILIATE',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    {
      order: 29
    }
  ),
  new I4BBasicColumn(
    { displayName: 'HAS CREDENTIAL' },
    {
      id: 'hasCredential',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.HAS_CREDENTIAL',
      cell: { type: I4BCellType.TRANSLATED_CELL },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    {
      order: 30
    }
  )
];

export const DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_DEVICE_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new DiagnosticVariableGroupColumn(
    { displayName: 'TEMPERATURE' },
    {
      id: 'TEMPERATURE',
      translationKey: 'TEMPERATURE',
      cell: { type: I4BCellType.DIAGNOSTIC_VARIABLE },
      isDefault: false,
      concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
      width: 100
    },
    {
      order: 30
    }
  ),
  new DiagnosticVariableGroupColumn(
    { displayName: 'BATTERY_LEVEL' },
    {
      id: 'BATTERY_LEVEL',
      translationKey: 'BATTERY_LEVEL',
      cell: { type: I4BCellType.DIAGNOSTIC_VARIABLE },
      isDefault: false,
      concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
      width: 100
    },
    {
      order: 31
    }
  ),
  new DiagnosticVariableGroupColumn(
    { displayName: 'RADIO_LEVEL' },
    {
      id: 'RADIO_LEVEL',
      translationKey: 'RADIO_LEVEL',
      cell: { type: I4BCellType.DIAGNOSTIC_VARIABLE },
      isDefault: false,
      concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
      width: 100
    },
    {
      order: 32
    }
  ),
  new DiagnosticVariableGroupColumn(
    { displayName: 'SMS_SENT' },
    {
      id: 'SMS_SENT',
      translationKey: 'SMS_SENT',
      cell: { type: I4BCellType.DIAGNOSTIC_VARIABLE },
      isDefault: false,
      concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
      width: 100
    },
    {
      order: 33
    }
  ),
  new DiagnosticVariableGroupColumn(
    { displayName: 'ENERGY_MODE' },
    {
      id: 'ENERGY_MODE',
      translationKey: 'ENERGY_MODE',
      cell: { type: I4BCellType.DIAGNOSTIC_VARIABLE },
      isDefault: false,
      concept: I4bCellConcept.DIAGNOSTIC_VARIABLES,
      width: 100
    },
    {
      order: 34
    }
  )
];

export const DEFAULT_TECHNICAL_COLUMNS_FOR_DEVICES_VIEW = [
  new IdColumn(
    { displayName: 'DEVICE TECHNICAL ID' },
    {
      id: 'id',
      concept: I4bCellConcept.DEVICES,
      isDefault: false,
      cell: {
        type: I4BCellType.BASIC_LINK,
        event: { type: 'navigateToDevice' }
      }
    },
    { order: 51 }
  ),
  new IdColumn(
    { displayName: 'SITE TECHNICAL ID' },
    {
      id: 'site.id',
      concept: I4bCellConcept.SITES,
      isDefault: false,
      cell: {
        type: I4BCellType.BASIC_LINK,
        event: { type: 'navigateToSite' }
      }
    },
    { order: 50 }
  ),
  new I4BBasicColumn(
    { displayName: 'CREATED AT', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CREATED_AT',
      id: 'createdAt',
      isDefault: false,
      sortProperty: 'createdAt',
      concept: I4bCellConcept.DEVICES,
      width: 165,
      cell: {
        type: I4BCellType.DATE
      }
    },
    {
      order: 980
    }
  ),
  new UpdatedGroupColumn({ displayName: 'UPDATED FULL' }, { id: 'updatedInfo', concept: I4bCellConcept.DEVICES }, { order: 53 })
];

export class DefaultDevicesGrid implements I4BGrid<I4BGridOptions, DevicesGridData> {
  masterview: string = 'devices';
  isDefault: boolean = false;
  isUserDefault: boolean = false;
  isAppDefault: boolean = false;
  name: string = null;
  userId: string = null;
  data: DevicesGridData;
  gridOptions: I4BGridOptions = {
    pageSize: 100,
    multipleSelection: true,
    autoRefresh: { enabled: false },
    gridSort: [
      { colId: 'entity.name', sort: 'asc', sortIndex: 0 },
      { colId: 'site.name', sort: 'asc', sortIndex: 1 },
      { colId: 'name', sort: 'asc', sortIndex: 2 }
    ],
    buttonColumn: { enabled: true, className: new I4BDeviceButtonColumn() },
    selectionColumn: { enabled: true, className: new I4BSelectionColumn() }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  export: () => {};
}
