import { ChangeDetectorRef, Component, EventEmitter, Inject, NgZone, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxChange, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { IotGeoJsonRouteFeature } from '@iot-platform/iot-platform-maps';
import * as Leaflet from 'leaflet';

export interface Route {
  start: string;
  end: string;
  durationMs: number;
  color: string;
  day: string;
  layer: Leaflet.Layer;
  layerLabel: string;
  checked: boolean;
  indeterminate: boolean;
  routes: Route[];
}

@Component({
  selector: 'iot-platform-maps-map-panel-info-popup',
  templateUrl: './map-panel-info-popup.component.html',
  styleUrls: ['./map-panel-info-popup.component.scss'],
  providers: [{ provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'check' } as MatCheckboxDefaultOptions }]
})
export class MapPanelInfoPopupComponent implements OnInit {
  routes: any;
  gRoutes$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  @Output() clickDansLaPopup: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { routeFeatures: IotGeoJsonRouteFeature[] },
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.zone.run(() => {
      this.initRoutes();
    });
  }

  ngOnInit(): void {}

  initRoutes() {
    let routes: Route[] = [];
    this.data?.routeFeatures
      ?.filter((feature) => feature?.properties['durationMs'] > 0 && feature.geometry.type === 'LineString')
      .forEach((route: IotGeoJsonRouteFeature, index) => {
        const colors: string[] = ['#0096FF', '#0047AB', '#6F8FAF', '#1434A4', '#6082B6', '#1F51FF', '#0F52BA', '#4169E1', '#4682B4', '#0437F2'];

        const routeColor = colors[index % colors.length];
        const routeLayer: Leaflet.GeoJSON = Leaflet.geoJson(route, {
          style: (f) => {
            return {
              stroke: true,
              weight: 5,
              color: routeColor
            };
          },
          onEachFeature: (f, layer) => {
            layer.bindPopup(
              '<div>start: ' + f.properties.start + '<br>' + 'end: ' + f.properties.end + '<br>' + 'duration: ' + f.properties.durationMs + ' ms</div>'
            );
            layer.on({});
          }
        });
        const layerLabel: string = moment(route.properties['start']).format('HH:mm:ss') + ' - ' + moment(route.properties['end']).format('HH:mm:ss');
        const day: string = moment(route.properties['start']).format('DD/MM/yyyy');
        routes.push({
          color: routeColor,
          layerLabel,
          layer: routeLayer,
          day,
          checked: false, // moment(route.properties['start']).isSame(moment(), 'day'), boxes are checked but routes are not added to map
          indeterminate: false,
          routes: [],
          start: route.properties['start'],
          end: route.properties['end'],
          durationMs: route.properties['durationMs']
        });
      });

    const gRoutes = routes.reduce((acc: any, route: any) => {
      const day = route.day;
      if (!acc.find((r) => r.day === day)) {
        acc.push({ day, routes: [route] });
      } else {
        acc.find((r) => r.day === day).routes.push(route);
      }

      const sortedRoutes = acc.sort((a: any, b: any) => {
        a = moment(a.date);
        b = moment(b.date);
        return a > b ? -1 : a < b ? 1 : 0;
      });
      return sortedRoutes;
    }, []);

    const todayRoute: Route = gRoutes.find((r) => r.day === moment().format('DD/MM/yyyy'));

    if (todayRoute) {
      console.log('todayRoute', todayRoute);
      this.clickDansLaPopup.emit({ layers: todayRoute.routes.map((route) => route.layer), action: 'add' });
    }

    this.gRoutes$.next(gRoutes);
  }

  someSubRoutesChecked(route: Route): boolean {
    return route.routes.some((t) => t.checked) && !route.routes.every((t) => t.checked);
  }

  allSubRoutesChecked(route: Route): boolean {
    return route.routes.every((t) => t.checked);
  }

  setAll(dayRoute: Route, checked: boolean) {
    if (dayRoute.routes === null) {
      return;
    }
    dayRoute.routes.forEach((t) => (t.checked = checked));
    this.clickDansLaPopup.emit({ layers: dayRoute.routes.map((route) => route.layer), action: checked ? 'add' : 'remove' });
  }

  onRouteChanged(event: MatCheckboxChange, selectedRoute: any) {
    this.clickDansLaPopup.emit({ layers: [selectedRoute.layer], action: event.checked ? 'add' : 'remove' });
  }

  getDuration(subRoute: Route) {
    return moment.duration(subRoute.durationMs).asHours().toFixed(0) + 'h';
  }
}
