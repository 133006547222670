import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { IotPlatformMapsModule } from '@iot-platform/iot-platform-maps';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { SharedModule } from '@iot-platform/shared';
import { TableEngineModule } from '@iot-platform/table-engine';
import { TranslateModule } from '@ngx-translate/core';
import { DetailPopupCardsModule } from '../../../../../../../iot-platform-ui/src/lib/ui/components/detail-popup-cards/detail-popup-cards.module';
import { MvCellModule } from '../../../../components/mv-cell/mv-cell.module';
import { MvToolbarModule } from '../../../../../../../shared/src/lib/mv-toolbar/mv-toolbar.module';
import { DirectivesModule } from '../../../../directives/directives.module';
import { DeviceDetailPopupComponent } from '../../components/device-detail-popup/device-detail-popup.component';
import { DeviceMoveToFormComponent } from '../../components/device-move-to-form/device-move-to-form.component';
import { DeviceOverviewModule } from '../device-overview/device-overview.module';
import { DevicesShellComponent } from './devices-shell.component';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { DeviceInfoStatusModule } from '../../../../../../../iot-platform-ui/src/lib/ui/components/device-details/device-info-status/device-info-status.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    IotPlatformPipesModule,
    MvToolbarModule,
    IotPlatformUiModule,
    DirectivesModule,
    MvCellModule,
    DetailPopupCardsModule,
    TableEngineModule,
    DeviceOverviewModule,
    GridEngineModule,
    DeviceInfoStatusModule,
    IotPlatformMapsModule
  ],
  declarations: [DevicesShellComponent, DeviceMoveToFormComponent, DeviceDetailPopupComponent],
  exports: [DevicesShellComponent]
})
export class DevicesShellModule {}
