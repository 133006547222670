import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'i18n-translation-preview-changes',
  templateUrl: './translation-preview-changes.component.html',
  styleUrls: ['./translation-preview-changes.component.scss']
})
export class TranslationPreviewChangesComponent implements OnInit {
  public translations: Array<{ key: string; languages: Array<{ key: string; value: string; missing: boolean }> }> = [];

  constructor(
    public matDialogRef: MatDialogRef<TranslationPreviewChangesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { updatedRows; languages: string[]; action: string }
  ) {}

  public ngOnInit(): void {
    this.translations = this.data.updatedRows.map((item) => {
      const languages = this.data.languages
        .filter((lang: string) => (this.data.action !== 'all' ? lang === this.data.action : true))
        .map((lang: string) => ({
          key: lang,
          ...item[lang]
        }));
      return {
        key: item.key,
        languages
      };
    });
  }

  public close(confirmed?: boolean): void {
    this.matDialogRef.close(confirmed);
  }
}
