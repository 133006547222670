import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { CustomEncoder } from '@iot-platform/core';

import {
  BusinessProfile,
  PlatformRequest,
  PlatformResponse,
  Role,
  TagCategory,
  User
} from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import * as moment from 'moment';

import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BusinessProfilesService {
  public subscriptions: Subscription[] = [];

  constructor(@Inject('environment') private environment, private http: HttpClient) {}

  public getBusinessProfiles(): Observable<BusinessProfile[]> {
    return this.http.get<{ page: any; content: any }>(`${this.environment.api.url}/business-profiles`).pipe(map((response) => response.content));
  }

  public getBusinessProfilesWithParams(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));

    if (request.filters) {
      request.filters.forEach((filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.http.get<{ page: any; content: any }>(`${this.environment.api.url}/business-profiles`, { params }).pipe(
      map((data: any) => {
        return {
          data: data.content,
          currentPage: data.page.curPage,
          hasMore: data.page.hasMore,
          limit: data.page.limit,
          maxPage: data.page.maxPage,
          total: data.page.total
        };
      })
    );
  }

  public getBusinessProfileUniqueness(initialName: string, name: string, entityId: string): Observable<boolean> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', '0');
    params = params.set('page', '0');
    params = params.append('name', name);
    params = params.append('entityId', entityId);

    return this.http.get<{ page: any; content: any }>(`${this.environment.api.url}/business-profiles`, { params }).pipe(
      map((data: any) => {
        return initialName.toLowerCase() === name.toLowerCase() ? false : data.page.total > 0;
      })
    );
  }

  public getBusinessProfileById(businessProfiledId: string): Observable<BusinessProfile> {
    return this.http.get<BusinessProfile>(`${this.environment.api.url}${this.environment.api.endpoints.businessProfiles}/${businessProfiledId}`).pipe(
      map((bp) => {
        const zone = bp.timezoneDetails && bp.timezoneDetails.name ? moment().tz(bp.timezoneDetails.name).format('Z') : '';
        const updatedBP: BusinessProfile = { ...bp, timezoneDetails: { ...bp.timezoneDetails, offset: zone } };
        return updatedBP;
      })
    );
  }

  public saveBusinessProfile(newProfile: BusinessProfile): Observable<BusinessProfile> {
    return this.http
      .post<{ page: any; content: any }>(`${this.environment.api.url}/business-profiles/`, { newProfile })
      .pipe(map((response) => response.content));
  }

  public updateBusinessProfile(updatedProfile: BusinessProfile): Observable<BusinessProfile> {
    return this.http.put<BusinessProfile>(`${this.environment.api.url}/business-profiles/${updatedProfile.id}`, {
      name: updatedProfile.name,
      chartPeriod: updatedProfile.chartPeriod,
      timezoneDetails: updatedProfile.timezoneDetails,
      siteStocksVisible: updatedProfile.siteStocksVisible
    });
  }

  public deleteBusinessProfile(businessProfileToDelete: BusinessProfile): Observable<BusinessProfile> {
    return this.http.delete(`${this.environment.api.url}/business-profiles/${businessProfileToDelete.id}`).pipe(map((_) => businessProfileToDelete));
  }

  public loadRolesByBusinessProfileId(businessProfileId: string): Observable<Role[]> {
    return this.http
      .get<{ page: any; content: any }>(`${this.environment.api.url}/roles?businessProfileId=${businessProfileId}`)
      .pipe(map((response) => response.content));
  }

  public loadTagsByBusinessProfileId(businessProfileId: string): Observable<TagCategory[]> {
    return this.http.get<{ page: any; content: any }>(`${this.environment.api.url}/business-profiles/${businessProfileId}/tags`).pipe(
      map((response) => {
        return response && response.content ? response.content : [];
      })
    );
  }

  getRolesByOrganization(organizationId: string): Observable<Role[]> {
    return this.http.get<{ page: any; content: any }>(`${this.environment.api.url}/roles?entityId=${organizationId}`).pipe(map((response) => response.content));
  }

  public loadMembersByBusinessProfileId(businessProfileId: string): Observable<User[]> {
    return this.http
      .get<{ page: any; content: any }>(`${this.environment.api.url}/users?businessProfileId=${businessProfileId}`)
      .pipe(map((response) => response.content));
  }

  public addRoleToBusinessProfile(businessProfileId: string, roleToAdd: Role): Observable<Role> {
    return this.http.post<Role>(`${this.environment.api.url}/business-profiles/${businessProfileId}/roles/${roleToAdd.id}`, {}).pipe(map((_) => roleToAdd));
  }

  public removeRoleFromBusinessProfile(businessProfileId: string, roleToDelete: Role): Observable<Role> {
    return this.http.delete(`${this.environment.api.url}/business-profiles/${businessProfileId}/roles/${roleToDelete.id}`).pipe(map((_) => roleToDelete));
  }

  public addMemberToBusinessProfile(businessProfileId: string, memberToAdd: User): Observable<User> {
    return this.http.post<User>(`${this.environment.api.url}/business-profiles/${businessProfileId}/users/${memberToAdd.id}`, {}).pipe(map((_) => memberToAdd));
  }

  public removeMemberFromBusinessProfile(businessProfileId: string, memberToDelete: User): Observable<User> {
    return this.http.delete(`${this.environment.api.url}/business-profiles/${businessProfileId}/users/${memberToDelete.id}`).pipe(map((_) => memberToDelete));
  }

  public updateTagsForBusinessProfile(businessProfileId: string, tagsToAdd: TagCategory[]): Observable<TagCategory[]> {
    return this.http
      .put<{ page: any; content: any }>(`${this.environment.api.url}/business-profiles/${businessProfileId}/tags`, {
        tags: tagsToAdd
      })
      .pipe(map((response) => response.content));
  }

  public loadFavoriteViewsByBusinessProfileId(businessProfileId: string): Observable<PlatformResponse> {
    return this.http.get<PlatformResponse>(`${this.environment.api.url_v2}/business-profiles/${businessProfileId}/favorite-views`).pipe(
      map((data: any) => {
        return {
          data: data.content,
          currentPage: data.page.curPage,
          hasMore: data.page.hasMore,
          limit: data.page.limit,
          maxPage: data.page.maxPage,
          total: data.page.total
        };
      })
    );
  }

  public loadGridsByBusinessProfileId(businessProfileId: string): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams();
    params = params.set('businessProfileId', businessProfileId);
    return this.http.get<PlatformResponse>(`${this.environment.api.url}${this.environment.api.endpoints.grids}`, { params }).pipe(
      map((data: any) => {
        return {
          data: data.content,
          currentPage: data.page.curPage,
          hasMore: data.page.hasMore,
          limit: data.page.limit,
          maxPage: data.page.maxPage,
          total: data.page.total
        };
      })
    );
  }

  public getGridNameById(masterView: string, gridId: string): Observable<string> {
    return this.http
      .get<I4BGrid<I4BGridOptions, I4BGridData>>(`${this.environment.api.url}${this.environment.api.endpoints.grids}/${masterView}/${gridId}`)
      .pipe(map((g) => g.name));
  }

  public getGridsByMasterView(masterView: string): Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> {
    return this.http
      .get<{ page: any; content: I4BGrid<I4BGridOptions, I4BGridData>[] }>(`${this.environment.api.url}${this.environment.api.endpoints.grids}/${masterView}`)
      .pipe(map((response) => response.content));
  }

  public addGrid(grid: I4BGrid<I4BGridOptions, I4BGridData>): Observable<I4BGrid<I4BGridOptions, I4BGridData>> {
    return this.http
      .post<I4BGrid<I4BGridOptions, I4BGridData>>(`${this.environment.api.url}${this.environment.api.endpoints.grids}/${grid.masterview}`, grid)
      .pipe(map((response) => response));
  }

  public updateGrid(grid: I4BGrid<I4BGridOptions, I4BGridData>): Observable<I4BGrid<I4BGridOptions, I4BGridData>> {
    return this.http
      .put<I4BGrid<I4BGridOptions, I4BGridData>>(`${this.environment.api.url}${this.environment.api.endpoints.grids}/${grid.masterview}/${grid.id}`, grid)
      .pipe(map((response) => response));
  }

  public deleteGrid(grid: I4BGrid<I4BGridOptions, I4BGridData>): Observable<I4BGrid<I4BGridOptions, I4BGridData>> {
    return this.http
      .delete<I4BGrid<I4BGridOptions, I4BGridData>>(`${this.environment.api.url}${this.environment.api.endpoints.grids}/${grid.masterview}/${grid.id}`)
      .pipe(map(() => grid));
  }
}
