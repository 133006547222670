import { Action, createReducer, on } from '@ngrx/store';

import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';

import { AssetEventsBySiteLogsDbActions, AssetEventsBySiteLogsUiActions } from '../../actions';

export const assetEventsBySiteLogsUiFeatureKey = 'assetEventsBySiteLogsUi';

export interface State {
  error?: any;
  loaded?: boolean;
}

export const initialState: State = {
  error: null,
  loaded: false
};

const assetEventsBySiteLogsUiReducer = createReducer(
  initialState,
  on(AssetEventsBySiteLogsUiActions.loadLogsByAssetEventId, (state: State) => ({ ...state, error: null, loaded: false })),
  on(AssetEventsBySiteLogsDbActions.loadLogsByAssetEventIdSuccess, (state: State) => ({ ...state, loaded: true })),
  on(AssetEventsBySiteLogsDbActions.loadLogsByAssetEventIdFailure, (state: State, { error }) => ({ ...state, loaded: false, error })),
  // ****
  on(AssetEventsBySiteLogsUiActions.createLogByAssetEventId, (state: State) => ({ ...state, error: null })),
  on(AssetEventsBySiteLogsDbActions.createLogByAssetEventIdSuccess, (state: State) => ({ ...state, loaded: true })),
  on(AssetEventsBySiteLogsDbActions.createLogByAssetEventIdFailure, (state: State, { error }) => ({ ...state, loaded: false, error })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return assetEventsBySiteLogsUiReducer(state, action);
}

export const getError = (state: State) => state.error;
export const getLogsLoaded = (state: State) => state.loaded;
