import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@iot-platform/core';
import { IotToolbarDefaultButton, IotToolbarMenuButton, REFRESH_BUTTON_CONFIG } from '@iot-platform/iot-platform-ui';
import { Entity, IotToolbarEvent, ToolbarSize, User } from '@iot-platform/models/common';
import { fromUserPreferences } from '@iot-platform/users';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment/moment';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { AdminUsersPageActions } from '../../state/actions';
import * as fromUsers from '../../state/reducers';

@Component({
  selector: 'iot4bos-backoffice-ui-user-details-shell',
  templateUrl: './user-details-shell.component.html',
  styleUrls: ['./user-details-shell.component.scss']
})
export class UserDetailsShellComponent implements OnInit, OnDestroy {
  analytics: AnalyticsService = new AnalyticsService('user_details_page');
  selectedUser$: Observable<User> = this.store.pipe(select(fromUsers.getLoadedUser));
  connectedUser$: Observable<User> = this.store.pipe(select(fromUserPreferences.getCurrentUser));

  userDetailsButtonList: Array<IotToolbarDefaultButton | IotToolbarMenuButton> = [
    new IotToolbarDefaultButton({ ...REFRESH_BUTTON_CONFIG, tooltip: 'IOT_TOOLBAR.TOOLTIP.REFRESH.USER' }, 0)
  ];

  today = moment();
  defaultAppDateFormat = 'yyyy-MM-DD HH:mm:ss';
  defaultAppNumberFormat = 'fr';
  defaultAppNumberOfDecimal = 3;
  destroy$: Subject<void> = new Subject();
  protected readonly ToolbarSize = ToolbarSize;

  constructor(protected readonly store: Store, private readonly translateService: TranslateService, private readonly route: ActivatedRoute) {}

  get userLabel(): Observable<string> {
    return this.selectedUser$.pipe(
      takeUntil(this.destroy$),
      map((user: User) => {
        if (user) {
          const f = user.firstname ? user.firstname : '';
          const l = user.lastname ? user.lastname : '';
          return f + ' ' + l;
        } else {
          return '-';
        }
      })
    );
  }

  get notifications$(): Observable<string> {
    return this.selectedUser$.pipe(
      takeUntil(this.destroy$),
      switchMap((user) => this.getNotificationsString(user))
    );
  }

  get appNumberFormat() {
    const numberFormatPrefix = 'USERS.PROFILE_PREFERENCES.NUMBER_FORMATS.';
    return this.selectedUser$.pipe(
      takeUntil(this.destroy$),
      map((user: User) => {
        return user?.preferences?.appNumberFormat
          ? `${numberFormatPrefix}${user.preferences.appNumberFormat}`
          : `${numberFormatPrefix}${this.defaultAppNumberFormat}`;
      })
    );
  }

  ngOnInit(): void {
    combineLatest([this.selectedUser$, this.connectedUser$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([selectedUser, connectedUser]) => {
        if (!selectedUser && connectedUser && this.route.snapshot.paramMap.get('user_id') !== null) {
          const userId: string = this.route.snapshot.paramMap.get('user_id');
          this.loadUser(userId);
        }
      });
  }

  onClickBack() {
    this.analytics.log('toolbar_actions', 'navigate_back');
    this.store.dispatch(AdminUsersPageActions.backToUsers());
  }

  onToolbarEvent(event: IotToolbarEvent) {
    switch (event.type) {
      case 'REFRESH':
        this.analytics.log('toolbar_actions', 'refresh_user');
        if (this.route.snapshot.paramMap.get('user_id') !== null) {
          this.loadUser(this.route.snapshot.paramMap.get('user_id'));
        }
        break;
    }
  }

  loadUser(userId: string) {
    this.store.dispatch(AdminUsersPageActions.loadUserById({ userIdToLoad: userId }));
  }

  editUser() {}

  getAccountType(isShared: undefined | boolean) {
    return isShared ? 'ADMIN.USERS.TABLE.TYPE.SHARED_ACCOUNT' : 'ADMIN.USERS.TABLE.TYPE.USER_ACCOUNT';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.store.dispatch(AdminUsersPageActions.clearloadedUser());
  }

  getFormattedEntities(entities: Entity[]): string {
    return entities?.map((entity) => entity.name).join(', ');
  }

  private getNotificationsString(user: User): Observable<string> {
    const sms = user.notificationSMS === true ? this.translateService.get('USERS.PROFILE_PREFERENCES.SMS') : of('');
    const primaryEmail = user.notificationEmail === true ? this.translateService.get('USERS.PROFILE_PREFERENCES.PRIMARY_EMAIL') : of('');
    const secondEmail = user.notificationSecondaryEmail === true ? this.translateService.get('USERS.PROFILE_PREFERENCES.SECONDARY_EMAIL') : of('');

    return combineLatest([sms, primaryEmail, secondEmail]).pipe(
      takeUntil(this.destroy$),
      map(([sms, primaryEmail, secondEmail]) => {
        const s = sms ? sms + ', ' : '';
        const e1 = primaryEmail ? primaryEmail + ', ' : '';
        const e2 = secondEmail ? secondEmail + ', ' : '';
        const sfinal = s + e1 + e2;
        return sfinal.length > 0 ? sfinal.substring(0, sfinal.length - 2) : '-';
      })
    );
  }
}
