import { CellClassParams } from '@ag-grid-community/core';
import { get, orderBy } from 'lodash';
import { I4BCellType, I4BRichVariableColumnConfiguration } from '../configurations/i4b-column-configuration';
import { DataLibrary } from '../enums/data-type.enum';
import { I4bCellConcept } from '../enums/i4b-cell-concept.enum';
import { I4BBasicColumnHeader } from '../headers/i4b-basic-column-header';
import { HeaderType, I4BColumnHeader } from '../models/i4b-column-header.model';
import { BasicColumnOptions } from '../options/i4b-basic-column-options';
import { I4BColumnOptions } from '../options/i4b-column-options';
import { I4BColumn } from './i4b-column';

export class RichVariableColumn implements I4BColumn<I4BBasicColumnHeader, I4BRichVariableColumnConfiguration, I4BColumnOptions> {
  columnId = '7a788742-6f09-11ec-87d5-acde48001122-rich-variable';
  className: RichVariableColumn;

  dataLibs: DataLibrary[] = [DataLibrary.ASSETS];

  header: I4BBasicColumnHeader = new I4BBasicColumnHeader({ type: HeaderType.BASIC });
  configuration: I4BRichVariableColumnConfiguration = {
    concept: I4bCellConcept.ASSETS,
    id: null,
    isConfigurable: false,
    isDefault: true,
    isLink: true,
    position: null,
    sortProperty: null,
    width: 160,
    cell: {
      type: I4BCellType.RICH_VARIABLE,
      options: {
        dataType: '',
        unit: 'unit',
        value: 'value',
        tooltip: 'datetime'
      },
      event: { type: 'openChart', options: null }
    }
  };
  options: I4BColumnOptions = new BasicColumnOptions({ sortable: false });

  constructor(customHeader?: Partial<I4BColumnHeader>, customConfig?: Partial<I4BRichVariableColumnConfiguration>, customOptions?: Partial<I4BColumnOptions>) {
    if (customHeader) {
      this.header = new I4BBasicColumnHeader({
        ...this.header,
        ...customHeader
      });
    }

    if (customConfig) {
      this.configuration = { ...this.configuration, ...customConfig };
    }

    if (customOptions) {
      this.options = { ...this.options, ...customOptions };
    }
    this.configuration.cell.cellStyle = this.cellStyle.bind(this);
  }

  cellStyle(params: CellClassParams) {
    const data = get(params.data, params.colDef.field);
    const nearestThreshold = this.getNearestThreshold(data, params.value);
    return this.isValidThresholdCondition(nearestThreshold) ? { backgroundColor: nearestThreshold?.cellColor ?? nearestThreshold?.cell_color } : null;
  }

  private getNearestThreshold(data, value) {
    const operator = get(data, 'thresholds.operator');
    const arr = orderBy(get(data, 'thresholds.values'), ['value'], [operator === '<' ? 'desc' : 'asc']);
    let result = null;
    arr.forEach((threshold) => {
      const _value = get(threshold, 'value');
      const isGreaterOrEqual = operator === '>' && _value >= value;
      const isLowerOrEqual = operator === '<' && _value <= value;
      const isGreeter = operator === '=' && _value > value;
      if (!isGreaterOrEqual && !isLowerOrEqual && !isGreeter) {
        result = {
          ...threshold,
          operator,
          cellValue: value
        };
      }
    });
    return result;
  }

  private isValidThresholdCondition(nearestThreshold): boolean {
    let valid = false;
    if (nearestThreshold) {
      const { value, cellValue, operator } = nearestThreshold;
      valid = (operator === '>' && cellValue > value) || (operator === '<' && cellValue < value) || (operator === '=' && cellValue === value);
    }
    return valid;
  }
}
