import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Site } from '@iot-platform/models/oyan';
import { CardEvent } from '../../../../components/cards/models/card-event.model';
import { ThresholdType } from '../../../../components/cards/models/enums/threshold-type';
import { ThresholdCard } from '../../../../components/cards/models/threshold-card.model';

@Component({
  selector: 'oyan-ui-site-configurations',
  templateUrl: './site-configurations.component.html',
  styleUrls: ['./site-configurations.component.scss']
})
export class SiteConfigurationsComponent implements OnChanges {
  @Input() site: Site;
  @Input() canUpdate: boolean;

  @Output() dispatchEvent: EventEmitter<CardEvent> = new EventEmitter();

  thresholdCards: ThresholdCard[] = [];
  horizontalLayout = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.prototype.hasOwnProperty.call(changes, 'site') && changes.site.currentValue) {
      this.initCards();
    }
  }

  initCards(): void {
    const { checkInThreshold, checkOutThreshold, timeBeforeNotDetected, thresholdFull } = this.site;
    this.thresholdCards = [
      {
        label: 'SITES.CONFIGURATIONS.CARD.CHECK_IN_THRESHOLD',
        type: ThresholdType.CHECK_IN_THRESHOLD,
        defaultValue: Number(checkInThreshold),
        required: true
      },
      {
        label: 'SITES.CONFIGURATIONS.CARD.CHECK_OUT_THRESHOLD',
        type: ThresholdType.CHECK_OUT_THRESHOLD,
        defaultValue: Number(checkOutThreshold),
        required: true
      },
      {
        label: 'SITES.CONFIGURATIONS.CARD.TIME_BEFORE_NOT_DETECTED',
        type: ThresholdType.TIME_BEFORE_NOT_DETECTED,
        defaultValue: Number(timeBeforeNotDetected),
        required: true
      },
      {
        label: 'SITES.CONFIGURATIONS.CARD.THRESHOLD_FULL',
        type: ThresholdType.THRESHOLD_FULL,
        defaultValue: Number(thresholdFull),
        required: true
      }
    ];
  }

  onDispatchEvent(event: CardEvent): void {
    this.dispatchEvent.emit(event);
  }
}
