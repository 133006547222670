import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ColorPickerService {
  constructor(private httpClient: HttpClient) {}

  getColorList(): Observable<string[]> {
    return this.httpClient.get<{ default: string; colors: string[] }>(`assets/data/color-list.json`).pipe(map(data => data.colors));
  }

  getDefaultColor(): Observable<string> {
    return this.httpClient.get<{ default: string; colors: string[] }>(`assets/data/color-list.json`).pipe(map(data => data.default));
  }
}
