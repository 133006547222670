import { CustomExportWrapper, PlatformResponse, Product, ProductCatalog } from '@iot-platform/models/common';
import { get, orderBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class ProductsCustomExportWrapper extends CustomExportWrapper<Product> {
  catalogs: ProductCatalog[] = [];
  products: Observable<PlatformResponse>;

  constructor(catalogs: ProductCatalog[], products: Observable<PlatformResponse>) {
    super();
    this.catalogs = catalogs;
    this.products = products;
  }

  transform(rawData: Product[]): Product[] {
    const products: Product[] = rawData.map((p: Product) => {
      return { ...p, catalog: this.catalogs.find((c: ProductCatalog) => get(c, 'id') === get(p, 'catalog.id')) };
    });
    return orderBy(products, ({ identifier }) => identifier, 'asc');
  }

  loadData(): Observable<Product[]> {
    return this.products.pipe(map(({ data }) => data));
  }
}
