import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthorizationService as I4bAuthorizationService } from '@iot-platform/auth';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { AuthorizationConcept, AuthorizationType } from '../../../../../../auth/src/lib/authorization.types';
import { OyanAuthorizationConcept, OyanAuthorizationType } from '../types/authorization.types';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService extends I4bAuthorizationService {
  constructor(@Inject('environment') environment, http: HttpClient, public storage: LocalStorageService) {
    super(environment, http, storage);
  }

  applyAuthorization(concept: AuthorizationConcept | OyanAuthorizationConcept, authorizationType: AuthorizationType | OyanAuthorizationType): boolean {
    const privileges: { [key: string]: string[] } = JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_PRIVILEGES_KEY));
    if (privileges && privileges[concept.toUpperCase()]) {
      return privileges[concept.toUpperCase()].includes(authorizationType.toLowerCase());
    }
    return false;
  }

  processConditionsOnConcepts(concepts: Array<Array<{ name: string; right: string }>>): boolean {
    if (!concepts || (concepts && concepts.length === 0)) {
      return true;
    } else {
      return concepts.reduce((andConditionArrayResult, orConditionArray) => {
        const orConditionResult = orConditionArray.reduce((conditionResult, condition) => {
          conditionResult = conditionResult || this.applyAuthorization(OyanAuthorizationConcept[condition.name], OyanAuthorizationType[condition.right]);
          return conditionResult;
        }, false);

        return andConditionArrayResult && orConditionResult;
      }, true);
    }
  }
}
