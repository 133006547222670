<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign=" center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'EXPORTS.TITLE' | translate }}</h1>
    </mat-toolbar>
  </mat-card-title>

  <mat-progress-bar class="exports-loader" mode="buffer" color="accent" [value]="progressBarValue$ | async" *ngIf="loading$ | async"> </mat-progress-bar>

  <form class="full-width" [formGroup]="form">
    <mat-card-content class="dialog-card-content p-20" fxLayout="column" fxLayoutGap="10px">

      <mat-form-field fxFlex color="accent">
        <mat-select formControlName="type" required [placeholder]="'SITES.INFO_FORM.TYPE' | translate" (selectionChange)='onTypeChange($event)'>
          <mat-option [value]="t" *ngFor="let t of types">{{ t }}</mat-option>
        </mat-select>
        <mat-error *ngIf="type.invalid">{{ 'SITES.INFO_FORM.ERROR_MESSAGE.TYPE' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field fxFlex color="accent" floatLabel="never">
        <span matPrefix>{{ time }}-</span>
        <input matInput formControlName="name" required pattern="\S.*" [placeholder]="'EXPORTS.NAME' | translate" />
        <mat-error *ngIf="name.invalid && name.errors.pattern">{{ 'EXPORTS.ERRORS.NAME' | translate }}</mat-error>
      </mat-form-field>
      <p class="export-limit-warning" *ngIf="data?.totalElements > 80000">{{ 'EXPORTS.LIMIT' | translate }}</p>
      <p class="dialog-form-tips">{{ 'EXPORTS.TIPS' | translate }}</p>
    </mat-card-content>

    <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
      <button mat-button type="button" class="button-regular" (click)="close()">{{ 'EXPORTS.CANCEL' | translate }}</button>
      <button
        mat-raised-button
        type="submit"
        class="button-regular"
        color="accent"
        [disabled]="form.invalid || (loading$ | async)"
        (click)="submit()"
      >
        {{ (canDownload ? 'EXPORTS.DOWNLOAD' : 'EXPORTS.CLOSE') | translate }}
      </button>
    </mat-card-actions>
  </form>
</mat-card>
