import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncAutocompleteModule } from '../async-autocomplete/async-autocomplete.module';
import { CountryAutocompleteComponent } from './country-autocomplete.component';

@NgModule({
  imports: [CommonModule, TranslateModule, AsyncAutocompleteModule],
  declarations: [CountryAutocompleteComponent],
  exports: [CountryAutocompleteComponent]
})
export class CountryAutocompleteModule {
}
