import { BusinessProfile, User } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const linkBusinessProfileToUserSuccess = createAction(
  '[Oyan Admin Business Profile] Link business profile to user Success',
  props<{ businessProfile: BusinessProfile; user: User }>()
);
export const linkBusinessProfileToUserFailure = createAction(
  '[Oyan Admin Business Profile] Link business profile to user Failure',
  props<{ error: unknown }>()
);

export const unlinkBusinessProfileFromUserSuccess = createAction(
  '[Oyan Admin Business Profile] Unlink business profile from user Success',
  props<{ businessProfile: BusinessProfile; user: User }>()
);

export const unlinkBusinessProfileFromUserFailure = createAction(
  '[Oyan Admin Business Profile] Unlink business profile from user Failure',
  props<{ error: unknown }>()
);

export const setUserBusinessProfiles = createAction(
  '[Oyan Admin Business Profile] Set User Business Profiles',
  props<{ businessProfiles: BusinessProfile[] }>()
);

export const setSelectedBusinessProfile = createAction(
  '[Oyan Admin Business Profile] Set selected business profile',
  props<{ businessProfile: BusinessProfile }>()
);
