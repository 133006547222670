import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CustomOptionsCellParams } from '../custom-options-cell.params';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'grid-engine-last-command-status-cell',
  templateUrl: './last-command-status-cell.component.html',
  styleUrls: ['./last-command-status-cell.component.scss']
})
export class LastCommandStatusCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
