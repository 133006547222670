import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { CommandType, Device } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-device-card',
  templateUrl: './device-card.component.html',
  styleUrls: ['./device-card.component.scss']
})
export class DeviceCardComponent implements OnInit, OnChanges {
  @Input() device!: Device;
  @Input() canCreate: boolean;
  @Input() canDelete: boolean;
  @Input() canUpdate: boolean;
  @Input() canRead: boolean;
  @Input() canReadAuditTrail: boolean;
  @Input() isResetDisabled: boolean;

  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() moveTo: EventEmitter<Device> = new EventEmitter<Device>();
  @Output() reset: EventEmitter<Device> = new EventEmitter<Device>();
  @Output() decommissionDevice: EventEmitter<void> = new EventEmitter();
  @Output() deleteDevice: EventEmitter<Device> = new EventEmitter<Device>();
  @Output() configure: EventEmitter<Device> = new EventEmitter<Device>();
  @Output() commandExec: EventEmitter<CommandType> = new EventEmitter<CommandType>();
  @Output() openChangeLog: EventEmitter<void> = new EventEmitter();
  @Output() iccidClick: EventEmitter<void> = new EventEmitter();

  CommandType = CommandType;
  commandList: string[] = [];
  isCommandDisabled = false;
  ALLOWED_COMMANDS: Array<{ command: CommandType; displayRight: boolean }> = [];
  computedURL = '';

  get imgPath(): string {
    return this.device.imageUrl ? this.device.imageUrl : 'assets/images/device/Device_Default.png';
  }

  ngOnInit() {
    this.ALLOWED_COMMANDS = [
      { command: CommandType.SELFCONF, displayRight: this.canUpdate },
      { command: CommandType.REFRESH, displayRight: this.canRead },
      { command: CommandType.UNBLOCK, displayRight: this.canUpdate },
      { command: CommandType.GET_ALARM, displayRight: this.canUpdate }
    ];
    this.setIsCommandDisabled(this.device);
    this.setCommandList(this.device);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.device?.currentValue) {
      this.setIsCommandDisabled(this.device);
      this.setCommandList(this.device);
      this.setComputedURL(changes.device?.currentValue);
    }
  }

  getDisplayCTA(): boolean {
    return (
      (this.canUpdate && this.device.status?.name !== 'decommissioned') ||
      (this.canDelete && this.device.status?.name !== 'decommissioned') ||
      (this.canCreate && this.device.incomingConnector?.configuration?.url && this.device.status?.name !== 'decommissioned') ||
      (this.device.status?.name !== 'decommissioned' && !!this.commandList.length) ||
      this.canUpdate ||
      this.canDelete ||
      this.canReadAuditTrail
    );
  }

  setCommandList(device: Device): void {
    this.commandList = Object.keys(CommandType).filter((command) => {
      const allowedCommand = this.ALLOWED_COMMANDS.find((aC) => aC.command === CommandType[command]);
      return device.outgoingConnector?.requestConfiguration?.commands?.includes(CommandType[command]) && allowedCommand?.displayRight;
    });
  }

  setIsCommandDisabled(device: Device): void {
    if (device.outgoingConnector?.requestConfiguration?.authentication === 'login') {
      this.isCommandDisabled = !device.credential?.login || !device.credential?.password;
    }
  }

  setComputedURL(device: Device): void {
    switch (device?.communication?.httpport) {
      case 80:
        this.computedURL = `http://${device?.communication?.endpoint}`;
        break;
      case 443:
        this.computedURL = `https://${device?.communication?.endpoint}`;
        break;
      default:
        this.computedURL = device?.communication?.httpport
          ? `http://${device?.communication?.endpoint}:${device?.communication?.httpport}`
          : `http://${device?.communication?.endpoint}`;
        break;
    }
  }

  onEdit(): void {
    this.edit.emit();
  }

  onMoveTo(): void {
    this.moveTo.emit(this.device);
  }

  isDeleteDisabled(): boolean {
    if (this.device && this.device.status) {
      return this.device.status.name !== 'decommissioned';
    }
    return true;
  }

  onOpenChangeLog(): void {
    this.openChangeLog.emit();
  }

  onIccidClick(): void {
    this.iccidClick.emit();
  }
}
