<header fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="20px">
  <button
    mat-icon-button
    class="regular-round-button"
    [matTooltip]="'HEADER.TOGGLE_NAVIGATION_TOOLTIP' | translate"
    [matTooltipClass]="'regular-tooltip'"
    (click)="onToggleMenuClick()"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <img [src]="logo" (click)="onLogoClick()" alt="" />

  <span class="border-left no-margin"></span>

  <img [src]="appLogo" (click)="onLogoClick()" alt=""/>

  <span fxFlex></span>

  <div class='business-profile-select'>
    <span class="border-left no-margin"></span>
    <mat-select class="border-right" [value]="selectedProfile" (selectionChange)="switchBusinessProfile($event)">
      <mat-option *ngFor="let profile of businessProfiles" [value]="profile">
        {{ profile.name }}
      </mat-option>
    </mat-select>
  </div>

  <button mat-mini-fab [matMenuTriggerFor]="menu" class="user-button" color="accent">{{ initials }}</button>
  <mat-menu #menu="matMenu" fxLayout="row">
    <button mat-menu-item (click)="onMyProfileClick()">{{ 'HEADER.MY_PROFILE' | translate }}</button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="onLogout()">{{ 'HEADER.LOGOUT' | translate }}</button>
  </mat-menu>
</header>
