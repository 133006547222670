import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BusinessProfile, Role } from '@iot-platform/models/common';

import { select, Store } from '@ngrx/store';
import { SortUtil } from '../../../../../../shared/src/lib/utils/sort.util';

import { BusinessProfilesService } from '../../../features/admin-business-profiles/services/business-profiles.service';
import { BusinessProfilesRolesDialogActions } from '../../../features/admin-business-profiles/state/actions';
import * as fromProfiles from '../../../features/admin-business-profiles/state/reducers';

@Component({
  selector: 'iot4bos-backoffice-ui-dialog-link-roles-to-business-profile',
  templateUrl: './dialog-link-roles-to-business-profile.component.html',
  styleUrls: ['./dialog-link-roles-to-business-profile.component.scss', '../../../style/admin.style.scss']
})
export class DialogLinkRolesToBusinessProfileComponent implements OnInit {
  allRoles: Role[] = [];
  unselectedRoles: Role[] = [];
  currentRoles: Role[] = [];
  param = { bPName: '' };
  unselectedRolesPending = true;

  constructor(
    private dialogRef: MatDialogRef<DialogLinkRolesToBusinessProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { businessProfile: BusinessProfile },
    private businessProfileService: BusinessProfilesService,
    private store: Store<fromProfiles.State>
  ) {
    this.store.pipe(select(fromProfiles.getAllRolesByBusinessProfile)).subscribe((roles: Role[]) => {
      this.currentRoles = roles;
      this.unselectedRoles = this.checkAvailableRoles(this.allRoles).sort(SortUtil.sortByName);
    });

    this.businessProfileService.getRolesByOrganization(this.data.businessProfile.entityId).subscribe((rolesByOrganization: Role[]) => {
      this.allRoles = rolesByOrganization;
      this.unselectedRoles = [...this.checkAvailableRoles(rolesByOrganization)].sort(SortUtil.sortByName);
      this.unselectedRolesPending = false;
    });
  }

  ngOnInit() {
    if (this.data && this.data.businessProfile) {
      this.param.bPName = this.data.businessProfile.name;
    }
  }

  private checkAvailableRoles(rolesByOrganization: Role[]): Array<Role> {
    const available: Role[] = [];
    rolesByOrganization.forEach((role) => {
      if (this.currentRoles.indexOf(this.currentRoles.find((r) => role.id === r.id)) === -1) {
        available.push(role);
      }
    });
    return available;
  }

  removeRoleFromProfile(roleToRemove: Role) {
    this.store.dispatch(
      BusinessProfilesRolesDialogActions.removeRoleFromBusinessProfile({
        businessProfileId: this.data.businessProfile.id,
        roleToRemove: roleToRemove
      })
    );
  }

  addRoleToProfile(roleToAdd: Role) {
    this.store.dispatch(
      BusinessProfilesRolesDialogActions.addRoleToBusinessProfile({
        businessProfileId: this.data.businessProfile.id,
        roleToAdd: roleToAdd
      })
    );
  }

  exit() {
    this.dialogRef.close(true);
  }
}
