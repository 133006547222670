import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { AddSomethingComponent } from './components/add-something/add-something.component';
import { AsyncAutocompleteModule } from './components/async-autocomplete/async-autocomplete.module';
import { AsyncAutocompleteMultipleSelectsModule } from './components/async-autocomplete-multiple-selects/async-autocomplete-multiple-selects.module';
import { BreadcrumbItemModule } from './components/breadcrumb-item/breadcrumb.item.module';
import { BreadcrumbModule } from './components/breadcrumb/breadcrumb.module';
import { CardLoaderModule } from './components/card-loader/card-loader.module';
import { ChipModule } from './components/chip/chip.module';
import { ColorPickerModule } from './components/color-picker/color-picker.module';
import { CountryAutocompleteModule } from './components/country-autocomplete/country-autocomplete.module';
import { DetailPopupModule } from './components/detail-popup/detail-popup.module';
import { EventSeverityDisplayModule } from './components/event-severity-display/event-severity-display.module';
import { FilterEngineContainerModule } from './components/filter-engine-container/filter-engine-container.module';
import { IconModule } from './components/icon/icon.module';
import { ImageSelectorModule } from './components/image-selector/image-selector.module';
import { ManageTagsFormModule } from './components/manage-tags-form/manage-tags-form.module';
import { PopupModule } from './components/popup/popup.module';
import { ProgressBarButtonModule } from './components/progress-bar-button/progress-bar-button.module';
import { ReleaseNotesModule } from './components/release-notes/release-notes.module';
import { SpinnerBackgroundModule } from './components/spinner-background/spinner-background.module';
import { SubscriptionButtonModule } from './components/subscription-button/subscription-button.module';
import { TagCategoryListByConceptModule } from './components/tag-list-by-concept/tag-category-list-by-concept.module';
import { TimezoneAutocompleteModule } from './components/timezone-autocomplete/timezone-autocomplete.module';
import { ToolbarV2Module } from './components/toolbar-v2/toolbar-v2.module';
import { UserByIdModule } from './components/user-by-id/user-by-id.module';
import { UserThumbnailModule } from './components/user-thumbnail/user-thumbnail.module';
import { SiteDetailsModule } from './components/site-details/site-details.module';
import { DetailPopupCardsModule } from './components/detail-popup-cards/detail-popup-cards.module';
import { AssetDetailsModule } from './components/asset-details/asset-details.module';
import { TimezoneAutocompleteMultipleSelectsModule } from './components/timezone-autocomplete-multiple-selects/timezone-autocomplete-multiple-selects.module';
import { DeviceDetailsModule } from './components/device-details/device-details.module';
import { YearPickerModule } from './components/year-picker/year-picker.module';

@NgModule({
  imports: [
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatGridListModule,
    MatProgressBarModule,
    FlexLayoutModule,
    CommonModule,
    TranslateModule,
    CardLoaderModule,
    UserThumbnailModule,
    ImageSelectorModule,
    ChipModule,
    IconModule,
    ColorPickerModule,
    FilterEngineContainerModule,
    PopupModule,
    ManageTagsFormModule,
    TagCategoryListByConceptModule,
    ProgressBarButtonModule,
    SpinnerBackgroundModule,
    MatProgressSpinnerModule,
    ReleaseNotesModule,
    DetailPopupModule,
    EventSeverityDisplayModule,
    AsyncAutocompleteModule,
    AsyncAutocompleteMultipleSelectsModule,
    BreadcrumbModule,
    BreadcrumbItemModule,
    TimezoneAutocompleteModule,
    TimezoneAutocompleteMultipleSelectsModule,
    CountryAutocompleteModule,
    UserByIdModule,
    SubscriptionButtonModule,
    ToolbarV2Module,
    SiteDetailsModule,
    DetailPopupCardsModule,
    AssetDetailsModule
  ],
  declarations: [AddSomethingComponent],
  exports: [
    AddSomethingComponent,
    ImageSelectorModule,
    UserThumbnailModule,
    CardLoaderModule,
    IconModule,
    ColorPickerModule,
    FilterEngineContainerModule,
    ChipModule,
    PopupModule,
    ManageTagsFormModule,
    TagCategoryListByConceptModule,
    ProgressBarButtonModule,
    SpinnerBackgroundModule,
    ReleaseNotesModule,
    DetailPopupModule,
    ManageTagsFormModule,
    AsyncAutocompleteModule,
    AsyncAutocompleteMultipleSelectsModule,
    TimezoneAutocompleteModule,
    TimezoneAutocompleteMultipleSelectsModule,
    CountryAutocompleteModule,
    BreadcrumbItemModule,
    UserByIdModule,
    SubscriptionButtonModule,
    ToolbarV2Module,
    SiteDetailsModule,
    AssetDetailsModule,
    DeviceDetailsModule,
    DetailPopupCardsModule,
    YearPickerModule
  ]
})
export class UiModule {}
