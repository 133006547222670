import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonIndexedPagination, MasterViewEngineEvent, PlatformResponse } from '@iot-platform/models/common';

@Component({
  selector: 'oyan-ui-site-stocks',
  templateUrl: './site-stocks.component.html',
  styleUrls: ['./site-stocks.component.scss']
})
export class SiteStocksComponent {
  @Input() stocksAsPlatformResponse: PlatformResponse;
  @Input() loading: boolean;
  @Input() userPermissions: Array<{ key: string; value: boolean }> = [];

  @Output() dispatchMasterViewEngineEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter();
  @Output() pageChange: EventEmitter<CommonIndexedPagination> = new EventEmitter();

  get hasStocks(): boolean {
    return this.stocksAsPlatformResponse.data && !!this.stocksAsPlatformResponse.data.length;
  }

  onPageChange(pagination: CommonIndexedPagination): void {
    this.pageChange.emit(pagination);
  }

  onDispatchMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    this.dispatchMasterViewEngineEvent.emit(event);
  }
}
