import { Component, Input } from '@angular/core';

import { Log } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-event-comment',
  templateUrl: './event-comment.component.html',
  styleUrls: ['./event-comment.component.scss']
})
export class EventCommentComponent {
  @Input() log: Log;

  constructor() {}
}
