import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AUTH_CONFIG, AuthConfig, AuthService, LoginShellComponent } from '@iot-platform/auth';
import { BusinessProfile } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthFacade } from '../../state/facades/auth.facade';

@Component({
  selector: 'oyan-ui-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent extends LoginShellComponent implements OnInit {
  authPending$: Observable<boolean> = this.authFacade.authPending$;
  eligibleBusinessProfiles$: BehaviorSubject<BusinessProfile[]> = new BehaviorSubject([]);

  constructor(
    private readonly ref: ChangeDetectorRef,
    protected authService: AuthService,
    protected store: Store,
    protected translateService: TranslateService,
    @Inject(AUTH_CONFIG) protected config: AuthConfig,
    private readonly authFacade: AuthFacade
  ) {
    super(authService, store, translateService, config);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.authFacade.eligibleBusinessProfiles$.subscribe((businessProfiles: BusinessProfile[]) => {
        this.eligibleBusinessProfiles$.next(businessProfiles);
        // Force refresh view
        this.ref.markForCheck();
      })
    );
  }
}
