import { GetUtils } from '@iot-platform/iot-platform-utils';
import { BaseState, CommonIndexedPagination, Contact, Filter, INITIAL_BASE_STATE } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ContactsDbActions, ContactsUiActions, SiteActions } from '../actions';

export const featureKey = 'sitesContactsApiFeatureKey';

export type State = BaseState<Contact, CommonIndexedPagination, Filter>;

export const adapter: EntityAdapter<Contact> = createEntityAdapter<Contact>({
  selectId: (entity: Contact) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  pagination: { currentPage: 0, hasMore: false, limit: 10, maxPage: 0, total: 0 }
});

export const reducer = createReducer(
  initialState,
  on(
    SiteActions.loadSiteByIdSuccess,
    (state: State, { response }): State => ({
      ...adapter.setAll(GetUtils.get(response, 'contacts', []), { ...state })
    })
  ),

  on(
    SiteActions.setActiveSite,
    (state: State, { site }): State => ({
      ...adapter.setAll(GetUtils.get(site, 'contacts', []), { ...state })
    })
  ),

  on(ContactsDbActions.addContactSuccess, (state: State, { addedContact }): State => {
    return adapter.addOne(addedContact, { ...state, entity: addedContact });
  }),

  on(
    ContactsDbActions.updateContactSuccess,
    (state: State, { updatedContact }): State => ({
      ...adapter.updateOne({ id: updatedContact.id, changes: updatedContact }, { ...state, entity: updatedContact })
    })
  ),

  on(
    ContactsDbActions.deleteContactSuccess,
    (state: State, { deletedContact }): State => ({
      ...adapter.removeOne(deletedContact.id, state)
    })
  ),

  on(
    ContactsUiActions.addContact,
    ContactsUiActions.updateContact,
    ContactsUiActions.deleteContact,
    (state: State): State => ({
      ...state,
      loading: true
    })
  ),
  on(
    ContactsDbActions.addContactSuccess,
    ContactsDbActions.updateContactSuccess,
    ContactsDbActions.deleteContactSuccess,
    (state: State): State => ({
      ...state,
      loading: false,
      loaded: true
    })
  ),
  on(
    ContactsDbActions.addContactFailure,
    ContactsDbActions.updateContactFailure,
    ContactsDbActions.deleteContactFailure,
    (state: State, { error }): State => ({
      ...state,
      loading: false,
      loaded: false,
      error
    })
  )
);
