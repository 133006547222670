import { Action, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';
import { AssetsDbActions, AssetsUiActions } from '../actions';

export const assetsUiFeatureKey = 'assetsUi';

export interface State {
  error?: any;
  assetLoaded: boolean;
  assetLoading: boolean;
  assetVarLoaded: boolean;
  assetVarLoading: boolean;
  tagsLoaded?: boolean;
  siteLoaded?: boolean;
  pagination: { currentPage: number; hasMore: boolean; limit: number; maxPage: number; total: number };
  assetCreationByTemplateStatuses: {
    assetCreationByTemplatePending: boolean;
    assetCreationByTemplateCompleted: boolean;
  };
}

export const initialState: State = {
  error: null,
  assetLoaded: false,
  assetLoading: false,
  assetVarLoaded: false,
  assetVarLoading: false,
  tagsLoaded: false,
  pagination: { currentPage: 0, hasMore: false, limit: 0, maxPage: 0, total: 0 },
  siteLoaded: false,
  assetCreationByTemplateStatuses: {
    assetCreationByTemplatePending: false,
    assetCreationByTemplateCompleted: false
  }
};

const assetsUiReducer = createReducer(
  initialState,
  on(AssetsUiActions.loadAssetById, (state: State) => ({ ...state, assetLoaded: false, assetLoading: true })),
  on(AssetsUiActions.loadAssets, (state: State) => ({ ...state, assetLoading: true, assetLoaded: false, siteLoaded: false, tagsLoaded: false })),
  on(AssetsUiActions.loadMVSettings, (state: State) => ({ ...state, assetLoading: true, assetLoaded: false })),
  on(AssetsUiActions.saveMVSettings, (state: State) => ({ ...state, assetLoading: true, assetLoaded: false })),
  on(AssetsUiActions.loadSiteById, (state: State) => ({ ...state, siteLoaded: false, assetLoading: true })),
  on(AssetsUiActions.loadTagsByAssetId, (state: State) => ({ ...state, tagsLoaded: false })),
  on(AssetsUiActions.loadVariablesByAssetId, (state: State) => ({ ...state, assetVarLoading: true, assetVarLoaded: false })),
  on(AssetsUiActions.addAsset, (state: State) => ({ ...state, assetLoading: true, assetLoaded: false })),
  on(AssetsUiActions.addAssetByTemplateId, (state: State) => ({ ...state, assetLoading: true, assetLoaded: false })),
  on(AssetsUiActions.addAssetVariable, (state: State) => ({ ...state, assetVarLoading: true, assetVarLoaded: false })),
  on(AssetsUiActions.updateTagsByAssetId, (state: State) => ({ ...state, tagsLoaded: false })),
  on(AssetsUiActions.updateAsset, (state: State) => ({ ...state, assetLoading: true })),
  on(AssetsUiActions.updateAssetVariable, (state: State) => ({ ...state, assetVarLoading: true })),
  on(AssetsUiActions.updateFollowedAssetVariable, (state: State) => ({ ...state, assetVarLoading: true })),
  on(AssetsUiActions.addAssetByTemplateId, (state: State) => ({
    ...state,
    assetCreationByTemplateStatuses: {
      assetCreationByTemplatePending: true,
      assetCreationByTemplateCompleted: false
    }
  })),
  // ****
  // ****
  // ****
  on(AssetsDbActions.loadAssetByIdSuccess, (state: State, { asset }) => ({ ...state, assetLoading: false, assetLoaded: true })),
  on(AssetsDbActions.loadAssetsSuccess, (state: State) => ({ ...state, assetLoading: false, assetLoaded: true })),
  on(AssetsDbActions.loadMVSettingsSuccess, (state: State) => ({ ...state, assetLoading: false, assetLoaded: true })),
  on(AssetsDbActions.saveMVSettingsSuccess, (state: State) => ({ ...state, assetLoading: false, assetLoaded: true })),
  on(AssetsDbActions.loadSiteByIdSuccess, (state: State) => ({ ...state, siteLoaded: true, assetLoading: false, assetLoaded: true })),
  on(AssetsDbActions.loadTagsByAssetIdSuccess, (state: State) => ({ ...state, tagsLoaded: true, assetLoaded: true, assetLoading: false })),
  on(AssetsDbActions.loadVariablesByAssetIdSuccess, (state: State) => ({ ...state, assetVarLoading: false, assetVarLoaded: true })),
  on(AssetsDbActions.addAssetSuccess, (state: State) => ({
    ...state,
    assetLoading: false,
    assetLoaded: true,
    assetCreationByTemplateStatuses: {
      assetCreationByTemplatePending: false,
      assetCreationByTemplateCompleted: true
    }
  })),
  on(AssetsDbActions.addAssetVariableSuccess, (state: State) => ({ ...state, assetVarLoading: false, assetVarLoaded: true })),
  on(AssetsDbActions.updateTagsByAssetIdSuccess, (state: State, { tags }) => ({ ...state, tagsLoaded: true })),
  on(AssetsDbActions.updateAssetSuccess, (state: State) => ({ ...state, assetLoading: false, assetLoaded: true })),
  on(AssetsDbActions.updateAssetVariableSuccess, (state: State) => ({ ...state, assetVarLoading: true, assetVarLoaded: true })),
  on(AssetsDbActions.updateFollowedAssetVariableSuccess, (state: State) => ({ ...state, assetVarLoading: true, assetVarLoaded: true })),
  // ****
  // ****
  // ****
  on(AssetsDbActions.loadAssetByIdFailure, (state: State, { error }) => ({ ...state, siteLoaded: false, error })),
  on(AssetsDbActions.loadAssetsFailure, (state: State, { error }) => ({ ...state, assetLoading: false, assetLoaded: false, error })),
  on(AssetsDbActions.loadMVSettingsFailure, (state: State, { error }) => ({ ...state, assetLoading: false, assetLoaded: false, error })),
  on(AssetsDbActions.saveMVSettingsFailure, (state: State, { error }) => ({ ...state, assetLoading: false, assetLoaded: false, error })),
  on(AssetsDbActions.loadSiteByIdFailure, (state: State, { error }) => ({ ...state, siteLoaded: false, error })),
  on(AssetsDbActions.loadTagsByAssetIdFailure, (state: State, { error }) => ({ ...state, tagsLoaded: true, error })),
  on(AssetsDbActions.loadVariablesByAssetIdFailure, (state: State, { error }) => ({ ...state, assetVarLoading: false, assetVarLoaded: false, error })),
  on(AssetsDbActions.addAssetFailure, (state: State, { error }) => ({
    ...state,
    assetLoading: false,
    assetLoaded: false,
    assetCreationByTemplateStatuses: {
      assetCreationByTemplatePending: false,
      assetCreationByTemplateCompleted: false
    },
    error
  })),
  on(AssetsDbActions.addAssetVariableFailure, (state: State, { error }) => ({ ...state, assetVarLoading: false, assetVarLoaded: false, error })),
  on(AssetsDbActions.updateTagsByAssetIdFailure, (state: State, { error }) => ({ ...state, tagsLoaded: true, error })),
  on(AssetsDbActions.updateAssetFailure, (state: State, { error }) => ({ ...state, assetLoading: false, assetLoaded: true, error })),
  on(AssetsDbActions.updateAssetVariableFailure, (state: State, { error }) => ({ ...state, assetVarLoading: true, assetVarLoaded: true, error })),
  on(AssetsDbActions.updateFollowedAssetVariableFailure, (state: State, { error }) => ({ ...state, assetVarLoading: true, assetVarLoaded: true, error })),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState),

  on(AssetsUiActions.resetAssetVariablesLastValues, (state: State) => ({ ...state, assetLoaded: false, assetLoading: true })),
  on(AssetsDbActions.resetAssetVariablesLastValuesSuccess, (state: State) => ({ ...state, assetLoaded: true, assetLoading: false })),
  on(AssetsDbActions.resetAssetVariablesLastValuesFailure, (state: State, { error }) => ({ ...state, assetLoaded: false, assetLoading: false, error })),
  //
  on(AssetsUiActions.deleteAssetVariables, (state: State) => ({ ...state, assetLoaded: false, assetLoading: true })),
  on(AssetsDbActions.deleteAssetVariablesSuccess, (state: State) => ({ ...state, assetLoaded: true, assetLoading: false })),
  on(AssetsDbActions.deleteAssetVariablesFailure, (state: State, { error }) => ({ ...state, assetLoaded: false, assetLoading: false, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return assetsUiReducer(state, action);
}
