import { Component, OnInit } from '@angular/core';

import { ICellRendererParams } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

import { CustomOptionsCellParams } from '../custom-options-cell.params';

@Component({
  selector: 'grid-engine-dynamic-icon-cell',
  templateUrl: './dynamic-icon-cell.component.html',
  styleUrls: ['./dynamic-icon-cell.component.scss']
})
export class DynamicIconCellComponent implements ICellRendererAngularComp {
  params: CustomOptionsCellParams;

  constructor() {}

  agInit(params: CustomOptionsCellParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  getTooltip() {
    return this.params.value ? this.params.data[this.params.cellOptions.tooltip.key] : '';
  }
}
