import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomEncoder } from '@iot-platform/core';
import {
  CommonApiRequest,
  CommonApiResponse,
  CommonCRUDService,
  CommonIndexedPagination,
  Entity,
  Filter
} from '@iot-platform/models/common';
import { Device, Scheduler } from '@iot-platform/models/i4b';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SchedulersService implements CommonCRUDService<Scheduler, CommonIndexedPagination> {
  constructor(@Inject('environment') private environment: any, private http: HttpClient) {}

  getAll(request: CommonApiRequest): Observable<CommonApiResponse<Scheduler, CommonIndexedPagination>> {
    return of(null);
  }

  getSchedulers(entityId: string, request: CommonApiRequest): Observable<CommonApiResponse<Scheduler, CommonIndexedPagination>> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', request.limit.toString(10));
    params = params.set('page', request.page.toString(10));
    params = params.set('includeSubEntities', 'true');
    params = params.set('includeDeviceIds', 'false');

    if (request.filters) {
      request.filters.forEach((filter: Filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.http
      .get<Scheduler>(`${this.environment.api.url}${this.environment.api.endpoints.entities}/${entityId}${this.environment.api.endpoints.schedulers}`, {
        params
      })
      .pipe(
        map((response: any) => ({
          data: response.content,
          pagination: {
            currentPage: response.page.curPage,
            hasMore: response.page.hasMore,
            limit: response.page.limit,
            maxPage: response.page.maxPage,
            total: response.page.total
          }
        }))
      );
  }

  addOne(toAdd: Scheduler): Observable<Scheduler> {
    return this.http.post<Scheduler>(
      `${this.environment.api.url}${this.environment.api.endpoints.entities}/${toAdd.entity.id}${this.environment.api.endpoints.schedulers}`,
      toAdd
    );
  }

  deleteOne(toDelete: Scheduler): Observable<Scheduler> {
    return this.http
      .delete<Scheduler>(
        `${this.environment.api.url}${this.environment.api.endpoints.entities}/${toDelete.entity.id}${this.environment.api.endpoints.schedulers}/${toDelete.id}`
      )
      .pipe(map((response) => toDelete));
  }

  updateOne(toUpdate: Scheduler): Observable<Scheduler> {
    return this.http.patch<Scheduler>(
      `${this.environment.api.url}${this.environment.api.endpoints.entities}/${toUpdate.entity.id}${this.environment.api.endpoints.schedulers}/${toUpdate.id}`,
      toUpdate
    );
  }

  manageDevices(toUpdate: Scheduler, idsToAdd: string[], idsToRemove: string[]): Observable<Scheduler> {
    return this.http.post<Scheduler>(
      `${this.environment.api.url}${this.environment.api.endpoints.entities}/${toUpdate.entity.id}${this.environment.api.endpoints.schedulers}/${toUpdate.id}/devices/manage`,
      { deviceIdsToAdd: idsToAdd, deviceIdsToRemove: idsToRemove }
    );
  }

  getOne(entityId: string, schedulerId: string): Observable<Scheduler> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('includeDeviceIds', 'true');
    return this.http.get<Scheduler>(
      `${this.environment.api.url}${this.environment.api.endpoints.entities}/${entityId}${this.environment.api.endpoints.schedulers}/${schedulerId}`,
      { params }
    );
  }

  checkName(entityId: string, name: string, initialName: string): Observable<boolean> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('name', name);
    params = params.set('exactParam', 'name');
    params = params.set('includeDeviceIds', 'false');

    return initialName.toLowerCase() === name.toLowerCase()
      ? of(false)
      : this.http
          .get<Scheduler[]>(`${this.environment.api.url}${this.environment.api.endpoints.entities}/${entityId}${this.environment.api.endpoints.schedulers}`, {
            params
          })
          .pipe(map((response: any) => response.page.total > 0));
  }

  launchNow(toLaunch: Scheduler): Observable<Scheduler> {
    return this.http.post<Scheduler>(
      `${this.environment.api.url}${this.environment.api.endpoints.entities}/${toLaunch.entity.id}${this.environment.api.endpoints.schedulers}/${toLaunch.id}/trigger`,
      toLaunch
    );
  }

  getEntity(entityId: string): Observable<Entity> {
    return this.http.get<Entity>(`${this.environment.api.url}${this.environment.api.endpoints.entities}/${entityId}`);
  }

  loadSelectedDevicesForScheduler(
    scheduler: Scheduler,
    filters: Filter[],
    page = 0,
    limit = 1000
  ): Observable<CommonApiResponse<Device, CommonIndexedPagination>> {
    const mandatoryFilters: Filter[] = [
      { criteriaKey: 'limit', value: limit },
      { criteriaKey: 'page', value: page },
      { criteriaKey: 'schedulerId', value: scheduler?.id },
      { criteriaKey: 'schedulerOpe', value: 'include' }
    ];

    return this.loadDevices(scheduler, [...mandatoryFilters, ...filters]);
  }

  loadTotalSelectedDevicesForScheduler(scheduler: Scheduler): Observable<number> {
    return this.loadSelectedDevicesForScheduler(scheduler, [], 0, 0).pipe(map((response) => response.pagination.total));
  }

  loadAvailableDevicesForScheduler(
    scheduler: Scheduler,
    filters: Filter[],
    page = 0,
    limit = 1000
  ): Observable<CommonApiResponse<Device, CommonIndexedPagination>> {
    const mandatoryFilters: Filter[] = [
      { criteriaKey: 'limit', value: limit },
      { criteriaKey: 'page', value: page },
      { criteriaKey: 'deviceStatus', value: 'running' },
      { criteriaKey: 'deviceStatus', value: 'standby' },
      { criteriaKey: 'deviceStatus', value: 'maintenance' },
      { criteriaKey: 'siteType', value: 'customer_site' },
      { criteriaKey: 'siteType', value: 'production_site' },
      { criteriaKey: 'schedulerId', value: scheduler?.id },
      { criteriaKey: 'schedulerOpe', value: 'exclude' },
      { criteriaKey: 'entityId', value: scheduler?.entity.id },
      { criteriaKey: 'withEntityChildren', value: true },
      { criteriaKey: 'connectorCommand', value: scheduler?.command }
    ];

    return this.loadDevices(scheduler, [...mandatoryFilters, ...filters]);
  }

  loadTotalAvailableDevicesForScheduler(scheduler: Scheduler): Observable<number> {
    return this.loadAvailableDevicesForScheduler(scheduler, [], 0, 0).pipe(map((response) => response.pagination.total));
  }

  private loadDevices(scheduler: Scheduler, filters: Filter[]): Observable<CommonApiResponse<Device, CommonIndexedPagination>> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    if (filters) {
      filters.forEach((filter: Filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.http
      .get<CommonApiResponse<Device, CommonIndexedPagination>>(`${this.environment.api.url}${this.environment.api.endpoints.devices}`, {
        params
      })
      .pipe(
        map((response: any) => {
          return {
            data: response.content,
            pagination: {
              currentPage: response.page.curPage,
              hasMore: response.page.hasMore,
              limit: response.page.limit,
              maxPage: response.page.maxPage,
              total: response.page.total
            }
          };
        })
      );
  }
}
