import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Filter, FilterSelectOptions } from '@iot-platform/models/common';
import { Observable, Subscription } from 'rxjs';
import { SortUtil } from '../../../../../../../shared/src/lib/utils/sort.util';

import { FilterComponent } from '../filter.component';

@Component({
  selector: 'iot-platform-ui-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss']
})
export class SelectFieldComponent implements OnInit, FilterComponent, OnDestroy {
  selectForm: UntypedFormGroup;
  selectedFilters: any = [];

  subscriptions: Subscription[] = [];

  @Input() data: FilterSelectOptions;
  @Input() currentFilters$!: Observable<Filter[]>;
  @Input() currentFiltersSize: number = 0;
  @Input() maxFilters: number = 20;

  @Output() dispatchFilterValue: EventEmitter<Filter> = new EventEmitter<Filter>();

  ngOnInit() {
    this.selectForm = new UntypedFormGroup({
      select: new UntypedFormControl('')
    });

    const hasOrderKey: boolean = this.data.list.some((e: { key: string; value: string; order?: number }) => Object.prototype.hasOwnProperty.call(e, 'order'));
    this.data.list.sort(SortUtil.sortByProperty(hasOrderKey ? 'order' : 'key'));

    if (this.data.multiSelect) {
      this.subscriptions.push(
        this.currentFilters$.subscribe((filters: Filter[]) => {
          this.selectedFilters = [];
          if (filters.length) {
            filters.forEach((filter: Filter) => {
              this.selectedFilters.push({ key: filter.value, value: filter.label });
            });
          }
          this.select.setValue(this.selectedFilters);
        })
      );
    }
  }

  get select(): AbstractControl {
    return this.selectForm.get('select');
  }

  onSelectionChange(): void {
    if (!this.data.multiSelect) {
      this.dispatchFilter(this.select.value);
      this.selectForm.reset();
    } else {
      let diff: { key: string; value: string };
      if (this.selectedFilters.length > this.select.value.length) {
        diff = this.getDifference(this.selectedFilters, this.select.value);
        this.currentFiltersSize--;
      } else {
        diff = this.getDifference(this.select.value, this.selectedFilters);
        this.currentFiltersSize++;
      }
      this.dispatchFilter(diff);
      this.selectedFilters = [...this.select.value];
    }
  }

  getDifference(array1: { key: string; value: string }[], array2: { key: string; value: string }[]): { key: string; value: string } {
    return array1.filter((obj1) => !array2.some((obj2) => obj1.key === obj2.key))[0];
  }

  dispatchFilter(value: { key: string; value: string }) {
    const filter: Filter = {};
    filter.value = value.key;
    filter.label = value.value;
    filter.criteriaKey = this.data.criteriaKey;
    filter.criteriaLabel = this.data.criteriaLabel;
    this.dispatchFilterValue.emit(filter);
  }

  compareFn(filter1: any, filter2: any): boolean {
    return filter1.key === filter2.key;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s && s.unsubscribe());
  }
}
