import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'grid-engine-active-events-cell',
  templateUrl: './active-events-cell.component.html',
  styleUrls: ['./active-events-cell.component.scss']
})
export class ActiveEventsCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  tooltip: string;

  constructor(private readonly translateService: TranslateService) {}

  agInit(params: ICellRendererParams) {
    this.params = params;

    if (this.params.value !== null) {
      this.tooltip = this.translateService.instant(this.params.value > 1 ? 'ASSETS.VARIABLE.TOOLTIPS.ACTIVE_EVENTS' : 'ASSETS.VARIABLE.TOOLTIPS.ACTIVE_EVENT', {
        totalEvents: this.params.value
      });
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
