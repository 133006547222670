<div class="event-comments" fxLayout="column" *ngIf="displayMode === 'side-panel'">
  <div fxFlex="60px" class="event-comments-header p-20" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-icon class="comment-icon">comment</mat-icon>
    <span fxFlex class="event-comments-title">{{ logs.length }} {{ 'EVENTS.COMMENTS.TITLE' | translate | uppercase }}</span>
    <button mat-icon-button (click)="onCloseComments()" class="close-button"><mat-icon>close</mat-icon></button>
  </div>

  <div fxFlex class="event-comments-content" [ngClass]="{ 'no-footer': !canUpdateEvent }" fxLayout="column" fxLayoutGap="14px">
    <iot4bos-ui-event-comment [log]="log" *ngFor="let log of logs"></iot4bos-ui-event-comment>
  </div>

  <form *ngIf="canUpdateEvent" fxFlex="80px" class="event-comments-footer p-20" [formGroup]="form" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <mat-form-field class="event-comments-field">
      <input class="event-comments-input" formControlName="comment" matInput maxlength="300" [placeholder]="'EVENTS.COMMENTS.MESSAGE' | translate" />
    </mat-form-field>
    <div class="app-round-button-mv">
      <button class="event-comments-send" mat-icon-button type="submit" (click)="onAddComment()"><mat-icon>send</mat-icon></button>
    </div>
  </form>
</div>

<div *ngIf="displayMode === 'detail-popup'" fxLayout="row" fxLayoutAlign="center end" fxLayoutGap="30px" class="event-comments-detail-popup-mode">
  <div fxFlex="30%" class="event-comments-content" [ngClass]="{ 'no-footer': !canUpdateEvent }" fxLayout="column" fxLayoutGap="14px">
    <iot4bos-ui-event-comment [log]="log" *ngFor="let log of logs"></iot4bos-ui-event-comment>
  </div>

  <form *ngIf="canUpdateEvent" fxFlex="30%" class="" [formGroup]="form" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-form-field class="" color="accent">
      <input class="" formControlName="comment" matInput maxlength="300" [placeholder]="'EVENTS.COMMENTS.MESSAGE' | translate" />
    </mat-form-field>
    <button class="regular-round-button" color="accent" mat-icon-button type="submit" (click)="onAddComment()"><mat-icon>send</mat-icon></button>
  </form>
</div>
