import { Injectable } from '@angular/core';
import { CommonApiRequest, FavoriteView, Filter } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { AssetEvent } from '@iot-platform/models/i4b';
import { select, Store } from '@ngrx/store';
import { GridsDbActions } from '../../../../../../grid-engine/src/lib/components/state/actions';
import {
  getAssetEventsGrids,
  getDefaultAssetEventsGrid,
  selectItemInGrid
} from '../../../../../../grid-engine/src/lib/components/state/reducers';

import { FavoriteViewsActions } from '../../../../../../shared/src/lib/favorite-views/+state/actions';
import * as fromFavoriteViews from '../../../../../../shared/src/lib/favorite-views/+state/reducers';
import { AssetEventsLogsUiActions, AssetEventsUiActions } from './actions';
import * as fromAssetEvents from './reducers';

@Injectable()
export class AssetEventsApi {
  /* formattedAssetEvents$ = this.store.pipe(select(fromAssetEvents.getFormattedAssetEvents));

  selectedAssetEvent$ = this.store.pipe(select(fromAssetEvents.getSelectedAssetEvent));

  assetEvents$ = this.store.pipe(select(fromAssetEvents.getAllAssetEvents));*/

  grid$ = this.store.select(getDefaultAssetEventsGrid);
  grids$ = this.store.select(getAssetEventsGrids);
  selectedAssetEvent$ = (gridId: string, itemId: string) => this.store.select(selectItemInGrid(gridId, itemId));

  assetEventsLoaded$ = this.store.pipe(select(fromAssetEvents.getAssetEventsLoaded));

  site$ = this.store.pipe(select(fromAssetEvents.getSite));
  siteLoaded$ = this.store.pipe(select(fromAssetEvents.getSiteLoaded));

  asset$ = this.store.pipe(select(fromAssetEvents.getAsset));
  assetLoaded$ = this.store.pipe(select(fromAssetEvents.getAssetLoaded));

  assetVariable$ = this.store.pipe(select(fromAssetEvents.getAssetVariable));
  assetVariableLoaded$ = this.store.pipe(select(fromAssetEvents.getAssetVariableLoaded));

  device$ = this.store.pipe(select(fromAssetEvents.getDevice));
  deviceLoaded$ = this.store.pipe(select(fromAssetEvents.getDeviceLoaded));

  deviceVariable$ = this.store.pipe(select(fromAssetEvents.getDeviceVariable));
  deviceVariableLoaded$ = this.store.pipe(select(fromAssetEvents.getDeviceVariableLoaded));

  logs$ = this.store.pipe(select(fromAssetEvents.getAllLogs));
  logsLoaded$ = this.store.pipe(select(fromAssetEvents.getLogsLoaded));
  log$ = this.store.pipe(select(fromAssetEvents.getLog));

  tags$ = this.store.pipe(select(fromAssetEvents.getTags));
  tagsLoaded$ = this.store.pipe(select(fromAssetEvents.getTagsLoaded));

  status$ = this.store.pipe(select(fromAssetEvents.getStatus));

  tableState$ = this.store.pipe(select(fromAssetEvents.getTableState));
  pagination$ = this.store.pipe(select(fromAssetEvents.getPagination));
  autoRefresh$ = this.store.pipe(select(fromAssetEvents.getAutoRefresh));
  refreshDelay$ = this.store.pipe(select(fromAssetEvents.getRefreshDelay));
  mvSettings$ = this.store.pipe(select(fromAssetEvents.getMVSettings));

  favoriteViews$ = this.store.pipe(select(fromFavoriteViews.getFavoriteViewsForMasterViewAssetEvents));
  currentFavoriteView$ = this.store.pipe(select(fromFavoriteViews.getSelectedFavoriteViewForMasterViewAssetEvents));
  currentFilters$ = this.store.pipe(select(fromFavoriteViews.getFiltersForMasterViewAssetEvents));
  loadingFavoriteViews$ = this.store.pipe(select(fromFavoriteViews.getLoading));

  //
  assetEventFavoriteViewsConfiguration$ = this.store.pipe(select(fromFavoriteViews.selectAssetEventFavoriteViewsConfiguration));
  //

  constructor(private readonly store: Store) {}

  loadMetadata() {
    this.store.dispatch(GridsDbActions.getDefaultGridByConcept({ concept: 'asset-events' }));
  }

  loadAssetEvents(request: CommonApiRequest) {
    this.store.dispatch(
      GridsDbActions.loadGridData({
        request: { concept: 'asset-events', ...request }
      })
    );
  }

  loadSiteById(siteId: string) {
    this.store.dispatch(AssetEventsUiActions.loadSiteById({ siteId }));
  }

  loadDeviceById(deviceId: string) {
    this.store.dispatch(AssetEventsUiActions.loadDeviceById({ deviceId }));
  }

  loadDeviceVariableById(deviceVariableId: string) {
    this.store.dispatch(AssetEventsUiActions.loadDeviceVariableById({ deviceVariableId }));
  }

  loadAssetById(assetId: string) {
    this.store.dispatch(AssetEventsUiActions.loadAssetById({ assetId }));
  }

  loadAssetVariableById(assetVariableId: string) {
    this.store.dispatch(AssetEventsUiActions.loadAssetVariableById({ assetVariableId }));
  }

  loadLogsByAssetEventId(assetEventId: string) {
    this.store.dispatch(AssetEventsLogsUiActions.loadLogsByAssetEventId({ assetEventId }));
  }

  loadTagsByAssetEventId(assetEventId: string) {
    this.store.dispatch(AssetEventsUiActions.loadTagsByAssetEventId({ assetEventId }));
  }

  loadEventDetailPopupDataByAssetEvent(assetEvent: AssetEvent) {
    this.loadSiteById(assetEvent.context.site.id);
    this.loadAssetById(assetEvent.context.asset.id);
    this.loadAssetVariableById(assetEvent.context.assetVariable.id);
    this.loadDeviceById(assetEvent.context.device.id);
    this.loadDeviceVariableById(assetEvent.context.deviceVariable.id);
  }

  createLogByAssetEventId(comment: { assetEvent: AssetEvent; value: string }) {
    this.store.dispatch(AssetEventsLogsUiActions.createLogByAssetEventId({ comment }));
  }

  bulkUpdateStatusByGridAndAssetEvent(grid, assetEvents: AssetEvent[], status: string) {
    assetEvents.forEach((aEvent) => {
      const updatedEvent = {
        ...aEvent,
        status: status
      };
      this.store.dispatch(GridsDbActions.updateItemInGridData({ gridId: grid.id, item: updatedEvent }));
    });
  }

  updateStatusByAssetEventId(assetEventIds: string[], status: string) {
    this.store.dispatch(AssetEventsUiActions.bulkUpdateStatusByAssetEventId({ assetEventIds, status }));
  }

  bulkUpdateStatusByAssetEventId(assetEventIds: string[], status: string): void {
    this.store.dispatch(AssetEventsUiActions.newBulkUpdateStatusByAssetEventId({ assetEventIds, status }));
  }

  saveTableState(tableState: { checked: AssetEvent[]; selected: AssetEvent }) {
    this.store.dispatch(AssetEventsUiActions.saveTableState({ tableState }));
  }

  saveFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenAddFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.addFavoriteView({ favoriteView: data.favoriteView }));
    }
  }

  updateFavoriteView(data: { grid?: I4BGrid<I4BGridOptions, I4BGridData>; favoriteView: FavoriteView }) {
    if (!!data.grid) {
      this.store.dispatch(FavoriteViewsActions.shareGridThenUpdateFavoriteView({ grid: data.grid, favoriteView: data.favoriteView }));
    } else {
      this.store.dispatch(FavoriteViewsActions.updateFavoriteView({ favoriteView: data.favoriteView }));
    }
  }
  deleteFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.deleteFavoriteView({ favoriteView }));
  }

  setCurrentFavoriteView(favoriteView: FavoriteView) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFavoriteView({ masterView: 'asset-events', favoriteView }));
  }

  setCurrentFilters(filters: Filter[]) {
    this.store.dispatch(FavoriteViewsActions.setCurrentFilters({ masterView: 'asset-events', filters }));
  }
}
