import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { AppConfig } from './app.config';
import { ConfigService } from './config.service';

export const APP_CONFIG = new InjectionToken<unknown>('OYAN_APP_CONFIG');

@NgModule({
  imports: [HttpClientModule],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: function (configService: ConfigService) {
        return async () => await configService.init().toPromise();
      },
      deps: [ConfigService],
      multi: true
    }
  ]
})
export class ConfigModule {
  static forRoot(config: AppConfig): ModuleWithProviders<ConfigModule> {
    return {
      ngModule: ConfigModule,
      providers: [
        {
          provide: APP_CONFIG,
          useValue: config
        }
      ]
    };
  }
}
