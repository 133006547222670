import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService, fromAuth } from '@iot-platform/auth';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { User } from '@iot-platform/models/common';
import { Asset, Device, Site } from '@iot-platform/models/i4b';
import { select, Store } from '@ngrx/store';
import { AuthorizationConcept, AuthorizationType } from 'libs/auth/src/lib/authorization.types';
import { combineLatest, Observable, Subject } from 'rxjs';
import { delay, map, mergeMap, takeUntil } from 'rxjs/operators';
import { NavigationApi } from '../+state/navigation.api';

@Component({
  selector: 'iot4bos-ui-info-display',
  templateUrl: './info-display.component.html',
  styleUrls: ['./info-display.component.scss']
})
export class InfoDisplayComponent implements OnInit, OnDestroy {
  site: Site;
  assets: Asset[];
  devices: Device[];
  activeItemId: string;
  displayRefreshBtn: boolean;
  mvorigin = this.storage.get(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY);
  activeSiteId = localStorage.getItem(LocalStorageKeys.STORAGE_ACTIVE_SITE_ID_KEY);
  destroyed$ = new Subject();

  siteFullAssociations$;
  siteFullAssociationsLoaded$;
  selectedItem$: Observable<{ type: string; id: string }>;
  currentUser$: Observable<User>;

  constructor(
    private readonly authStore: Store<fromAuth.State>,
    public readonly navigationApi: NavigationApi,
    private readonly router: Router,
    private readonly authorizationService: AuthorizationService,
    private readonly storage: LocalStorageService
  ) {
    this.setDevices();
    this.currentUser$ = this.authStore.pipe(select(fromAuth.selectCurrentUser));
    this.navigationApi.site$.pipe(takeUntil(this.destroyed$)).subscribe((site: Site) => {
      this.site = site;
      if (this.site) {
        this.displayRefreshBtn = this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.READ);
      }
    });
    this.siteFullAssociations$ = this.navigationApi.siteFullAssociations$;
    this.siteFullAssociationsLoaded$ = this.navigationApi.siteFullAssociationsLoaded$;
    this.setSelectedItem$();
    this.loadDataIfNotLoaded();
    this.navigationApi.selectedAsset$.pipe(takeUntil(this.destroyed$)).subscribe((asset) => {
      if (asset) {
        this.displayRefreshBtn = this.authorizationService.applyAuthorization(AuthorizationConcept.ASSET, AuthorizationType.READ);
      }
    });
    this.navigationApi.selectedDevice$.pipe(takeUntil(this.destroyed$)).subscribe((device) => {
      if (device) {
        this.displayRefreshBtn = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.READ);
      }
    });
  }

  onReturnToOrigin(): void {
    const origin: string = this.storage.get(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY);
    this.router.navigateByUrl(origin.split('/').length > 1 ? `/${origin}` : `/${origin}s`, { state: { keepScrollPosition: true } });
  }

  ngOnInit() {}

  onClose() {
    this.navigationApi.clearSelectedItem(this.site);
  }

  navigateToAsset(id: string) {
    this.navigationApi.selectAsset(this.site.id, id);
  }

  navigateToDevice(id: string) {
    this.navigationApi.selectDevice(this.site.id, id);
  }

  refreshAsset(asset: Asset) {
    this.navigationApi.selectAssetAvecLeSite(asset);
  }

  refreshSite(site: Site) {
    this.navigationApi.selectLeSite(site);
  }

  refreshDevice(device: Device) {
    this.navigationApi.selectDeviceAvecLeSite(device);
  }

  onAssetDeviceAssociationAction(event: { action: string; data?: Asset | Device }) {
    switch (event.action) {
      case 'open-asset':
        this.navigateToAsset(event.data.id);
        break;
      case 'open-device':
        this.navigateToDevice(event.data.id);
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.navigationApi.clearData();
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  private setSelectedItem$() {
    this.selectedItem$ = combineLatest([this.navigationApi.selectedAsset$, this.navigationApi.selectedDevice$]).pipe(
      map(([asset, device]) => {
        if (asset) {
          return { type: 'assets', id: asset.id };
        }
        if (device) {
          return { type: 'devices', id: device.id };
        }
        return null;
      })
    );
  }

  private setDevices() {
    this.navigationApi.devices$
      .pipe(
        mergeMap((devices: Device[]) =>
          this.navigationApi.site$.pipe(
            map((site: Site) => (site && site.type === 'stock' ? devices.filter((device: Device) => device.id === this.activeItemId) : devices))
          )
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe((devices: Device[]) => (this.devices = devices));
  }

  private loadDataIfNotLoaded() {
    this.navigationApi.siteLoaded.pipe(delay(200), takeUntil(this.destroyed$)).subscribe((loaded) => {
      if (loaded === false && this.site === null) {
        this.navigationApi.selectLeSite({
          id: this.activeSiteId,
          name: '',
          address: '',
          type: 'info'
        } as any);
      }
    });
  }
}
