import { Injectable } from '@angular/core';
import { BaseFacade, CommonApiRequest, Filter, Pagination } from '@iot-platform/models/common';
import { Asset } from '@iot-platform/models/oyan';
import { Store } from '@ngrx/store';
import { AssetsActions } from '../actions';
import { AssetsSelectors } from '../selectors/assets.selectors';

@Injectable({
  providedIn: 'root'
})
export class AssetsFacade extends BaseFacade<Asset, Pagination, Filter> {
  constructor(protected store: Store, protected selector: AssetsSelectors) {
    super(store, selector);
  }

  getAll(request?: CommonApiRequest) {
    this.store.dispatch(AssetsActions.loadAssets({ request }));
  }

  loadAssetById(id: string) {
    this.store.dispatch(AssetsActions.loadAssetById({ id }));
  }

  setFilters(filters: Filter[]) {
    this.store.dispatch(AssetsActions.setFilters({ filters }));
  }
}
