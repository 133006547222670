<div fxFlex fxLayout="column">
  <div class="sticky">
    <iot-platform-ui-toolbar-v2
      [name]="'ADMIN.USERS.TOOLBAR_TITLE' | translate"
      [total]="totalUsers$ | async"
      [isDataLoaded]="!(usersPending$ | async)"
      [buttonList]="adminUsersButtonList"
      (dispatchToolbarEvent)="onToolbarEvent($event)"
    >
    </iot-platform-ui-toolbar-v2>

    <iot-platform-ui-filter-engine-container
      [masterView]="'admin-users'"
      [expanded]="filterEngineOpened"
      [clearAppliedFilters$]="clearAppliedFilters$"
      [currentFilters$]="currentFilters$"
      [currentFavoriteView$]="currentFavoriteView$"
      (applyFilters)="onApplyFilters($event)"
    >
    </iot-platform-ui-filter-engine-container>

    <mat-sidenav-container>
      <mat-sidenav-content>
        <i4b-table-engine-master-view-engine
          [sticky]="true"
          [masterViewType]="{ name: 'admin-users', isPath: false }"
          [userPermissions]="userPermissions$ | async"
          [useExternalData]="true"
          [data]="users$ | async"
          (pageChange)="loadMoreUsers($event)"
          (dispatchMasterViewEngineEvent)="onDispatchMasterViewEngineEvent($event)"
        >
        </i4b-table-engine-master-view-engine> </mat-sidenav-content
    ></mat-sidenav-container>
  </div>
</div>
