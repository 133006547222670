<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.TITLE_ADMIN_POPUP' | translate }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <form [formGroup]="columnsForm" fxLayout="row" fxFlexAlign="start start" fxLayoutGap="10px" class="p-20">
      <div fxFlex="20" fxLayout="column" fxLayoutGap="10px">
        <!--        <mat-form-field color="accent">-->
        <!--          <input matInput #gridName formControlName="name" [placeholder]="'GRID_ENGINE.GRID_MANAGER_POPUP.GRID_NAME' | translate" maxlength="30" required />-->
        <!--          <mat-hint align="end">{{ gridName.value?.length ?? 0 }}/30</mat-hint>-->
        <!--        </mat-form-field>-->

        <mat-form-field color="accent">
          <mat-label>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.MASTER_VIEW' | translate }}</mat-label>
          <mat-select formControlName="viewType" (selectionChange)="onMasterViewChange($event)">
            <mat-option *ngFor="let gridDefinition of defaultGridsDefinitions" [value]="gridDefinition">{{ gridDefinition.viewType }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div fxLayout="column" fxLayoutGap="10px" fxFlex="65">
          <mat-label>{{ 'GRID_ENGINE.DATA_LIBRARIES' | translate }}</mat-label>
          <mat-radio-group
            formControlName="selectedLibrary"
            color="accent"
            fxLayout="column"
            fxLayoutGap="10px"
            class="data-libraries-selection"
            (change)="onLibraryChange($event)"
          >
            <mat-radio-button *ngFor="let datalib of (selectedViewDef$ | async)?.dataLibraries" [value]="datalib">{{
              'GRID_ENGINE.CONCEPT_LIST.' + datalib.libraryName | translate
            }}</mat-radio-button>
          </mat-radio-group>
        </div>

        <div fxLayout="row">
          <mat-form-field color="accent" fxFlex="50">
            <mat-select formControlName="pageSize" [placeholder]="'ADMIN.COLUMN_CATALOG.MASTER_VIEW_SETTINGS.PAGINATION' | translate">
              <mat-option *ngFor="let pageSize of gridPageSizeList" [value]="pageSize">{{ pageSize }}</mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <!--          <mat-checkbox formControlName="isAppDefault">-->
        <!--            &lt;!&ndash; {{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_SETTINGS.DEFAULT' | translate }}&ndash;&gt;-->
        <!--            APP DEFAULT-->
        <!--          </mat-checkbox>-->
        <!--          <mat-checkbox formControlName="isMineDefault">-->
        <!--            &lt;!&ndash; {{ 'ADMIN.COLUMN_CATALOG.MASTER_VIEW_SETTINGS.DEFAULT' | translate }}&ndash;&gt;-->
        <!--            MINE DEFAULT-->
        <!--          </mat-checkbox>-->
        <span class="dialog-form-tips">*{{ 'GRAPH.CREATE_FORM.MANDATORY_FIELDS' | translate }}</span>
      </div>

      <div fxFlex="80" fxFlexAlign="start start">
        <div fxFlex fxLayout="column" fxLayoutAlign="space-between">
          <!-- {{ selectedViewDef$ | async }}
            <iot-platform-ui-filter-engine-container *ngIf="selectedViewDef$ | async"
              [masterView]="'sites'"
             [currentFilters$]="getFilters()"
              expanded="true"
            >
            </iot-platform-ui-filter-engine-container>-->
          <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="38" fxLayout="column">
              <p style="height: 40px; line-height: 40px">
                {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.AVAILABLE_COLUMNS' | translate: { totalColumns: availableColumns?.length ?? '' } }}
              </p>
              <div
                cdkDropList
                #availableColumnsList="cdkDropList"
                [cdkDropListData]="availableColumns"
                [cdkDropListConnectedTo]="[selectedColumnsList]"
                class="dd-list available-list"
                [cdkDropListEnterPredicate]="allowDragColumns()"
                (cdkDropListDropped)="drop($event)"
              >
                <div class="accordeon-item dd-item" fxLayoutAlign="start center" *ngFor="let column of availableColumns" cdkDrag  [cdkDragData]="column">
                  <p fxFlex="35" class="concept">{{ 'GRID_ENGINE.CONCEPT_LIST.' + column.configuration?.concept | translate | uppercase }}</p>
                  <p fxFlex="65">{{ column.header.displayName }}</p>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="10px">
                <button mat-button color="accent" class="button-regular" (click)="addBasicColumn()" fxFlex [disabled]="!selectedViewDef$.getValue()">
                  {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.ADD_BASIC_COLUMN' | translate }}
                </button>
                <button
                  mat-button
                  color="accent"
                  class="button-regular"
                  (click)="addVariableColumn()"
                  fxFlex
                  [disabled]="!selectedViewDef$.getValue() || selectedViewDef$.getValue()?.viewType !== 'ASSETS'"
                >
                  {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.ADD_VARIABLE_COLUMN' | translate }}
                </button>
              </div>
            </div>

            <div fxFlex="58">
              <div fxLayoutAlign="space-between center">
                <p>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.SELECTED_COLUMNS' | translate: { totalColumns: selectedColumns.length ?? '' } }}</p>
                <p *ngIf="columnsForm.get('viewType').value" class="link" (click)="setDefaultView()">{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.DEFAULT_COLUMNS' | translate }}</p>
                <!--<button
                  mat-icon-button
                  color="accent"
                  [matTooltip]="'GRID_ENGINE.GRID_MANAGER_POPUP.DEFAULT_COLUMNS' | translate"
                  [matTooltipClass]="'regular-tooltip'"
                  [disabled]="!columnsForm.get('viewType').value"
                  (click)="setDefaultView()"
                >
                  <mat-icon>refresh</mat-icon>
                </button>-->
              </div>
              <div
                cdkDropList
                #selectedColumnsList="cdkDropList"
                [cdkDropListData]="selectedColumns"
                [cdkDropListConnectedTo]="[availableColumnsList]"
                class="dd-list selected-list"
                (cdkDropListDropped)="drop($event)"
              >
                <mat-expansion-panel
                  *ngFor="let column of selectedColumns; let index = index"
                  cdkDrag
                  fxLayout="column"
                  class="dd-item"
                  [cdkDragData]="column"
                  [hideToggle]="!column.configuration.isConfigurable"
                >
                  <mat-expansion-panel-header class="accordeon-item" fxLayoutGap="10px">
                    <mat-panel-title fxFlex="30" fxLayoutGap="10px">
                      <p>{{ index + 1 }}</p>
                      <p class="concept">{{ 'GRID_ENGINE.CONCEPT_LIST.' + column.configuration?.concept | translate | uppercase }}</p>
                    </mat-panel-title>
                    <mat-panel-description fxFlex="70">
                      {{ getHeaderValue(column) }}
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <div fxLayout="column">
                    <div
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      fxLayoutGap="10px"
                      class="dd-item--expanded-area"
                      *ngIf="column?.configuration?.isConfigurable"
                    >
                      <mat-form-field color="accent" fxFlex>
                        <mat-label>{{ 'GRID_ENGINE.GRID_MANAGER_POPUP.CUSTOM_HEADER' | translate }}</mat-label>
                        <input matInput [value]="column.options.overrideHeaderName ?? null" #customHeader />
                      </mat-form-field>
                      <button
                        mat-button
                        color="accent"
                        type="button"
                        [disabled]="!customHeader.value.trim()"
                        (click)="applyNewHeader(column, customHeader.value.trim())"
                      >
                        {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.APPLY' | translate }}
                      </button>
                      <button mat-icon-button color="warn" (click)="resetHeaderName(column)"><mat-icon>delete</mat-icon></button>
                    </div>
                    <div
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      fxLayoutGap="10px"
                      class="dd-item--expanded-area"
                      *ngIf="column?.configuration?.isConfigurable && column.configuration.name !== 'variable'"
                    >
                      <mat-form-field color="accent" fxFlex>
                        <mat-label>CUSTOM PROPERTY</mat-label>
                        <input matInput [value]="column.configuration.id ?? null" #customProperty />
                      </mat-form-field>
                      <button mat-button color="accent" type="button" (click)="applyNewProperty(column, customProperty.value)">
                        {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.APPLY' | translate }}
                      </button>
                    </div>

                    <div
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      fxLayoutGap="10px"
                      class="dd-item--expanded-area"
                      *ngIf="column?.configuration?.isConfigurable && column.configuration.name === 'variable'"
                    >
                      <mat-form-field color="accent" fxFlex>
                        <mat-label>CUSTOM VARIABLE</mat-label>
                        <input matInput [value]="column.configuration.id ?? null" #customVariable />
                      </mat-form-field>
                      <button mat-button color="accent" type="button" (click)="applyNewVariableName(column, customVariable.value)">
                        {{ 'GRID_ENGINE.GRID_MANAGER_POPUP.APPLY' | translate }}
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="close()">{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button mat-raised-button class="button-regular" color="accent" (click)="save()" [disabled]="isSubmissionDisabled()">
      {{ actionButtonLabel$ | async | translate }}
    </button>
  </mat-card-actions>
</mat-card>
