<div class="call-log-message-content">
  <div fxLayoutAlign="center center" fxFlex *ngIf="loading">
    <iot-platform-ui-card-loader fxFlex cdkFocusInitial></iot-platform-ui-card-loader>
  </div>

  <pre *ngIf="!loading">{{ message | json }}</pre>

  <button
    mat-icon-button
    class="reverse-accent-button copy-button"
    [matTooltip]="'DEVICES.CALL_LOG.COPY_MESSAGE' | translate"
    matTooltipClass="regular-tooltip"
    (click)="copyMessageToClipboard()"
  >
    <mat-icon>content_copy</mat-icon>
  </button>
</div>
