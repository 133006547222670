<div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="30px" class="full-width">
  <section fxFlex="50%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
    <iot4bos-ui-association-asset-card
      [asset]="mainItem"
      [navigationMode]="navigationMode"
      [selectedItemId]="selectedItemId"
      (clicked)="openSelectedItem.emit({ action: 'open-asset', data: mainItem })"
    ></iot4bos-ui-association-asset-card>

    <div *ngIf="!mainItem" class="no-association-message">{{ 'SITES.ASSOCIATIONS.NO_ASSOCIATED_ASSET' | translate }}</div>
  </section>

  <section fxFlex="50%" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
    <iot4bos-ui-association-device-card
      *ngFor="let associatedItem of associatedItems"
      [device]="associatedItem"
      [navigationMode]="navigationMode"
      [selectedItemId]="selectedItemId"
      (clicked)="openSelectedItem.emit({ action: 'open-device', data: associatedItem })"
    ></iot4bos-ui-association-device-card>

    <div *ngIf="!associatedItems.length" class="no-association-message">{{ 'SITES.ASSOCIATIONS.NO_ASSOCIATED_DEVICE' | translate }}</div>

  </section>
</div>
