import { SpreadsheetExport } from '@iot-platform/models/i4b';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { SpreadsheetExportsDbActions } from '../actions';

export const spreadsheetExportsDbFeatureKey = 'spreadsheetExportsDb';

export type State = EntityState<SpreadsheetExport>;

export const adapter: EntityAdapter<SpreadsheetExport> = createEntityAdapter<SpreadsheetExport>({
  selectId: (spreadsheetExport: SpreadsheetExport) => spreadsheetExport.contactId,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(SpreadsheetExportsDbActions.getSpreadsheetExportSuccess, SpreadsheetExportsDbActions.configureSpreadsheetExportSuccess, (state: State, { response }) => {
    if (state.entities[response?.contactId]) {
      return adapter.updateOne({ id: response.contactId, changes: response }, state);
    }
    if (response?.id) {
      return adapter.addOne(response, state);
    }
    return state;
  })
);
