import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InfoDisplayPipe } from './info-display.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [InfoDisplayPipe],
  exports: [InfoDisplayPipe]
})
export class InfoDisplayModule {}
