import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { NotificationService } from '@iot-platform/notification';

import { AdminProductsService } from '../../services/admin-products.service';
import { ProductsDbActions, ProductsUiActions } from '../actions';

@Injectable()
export class ProductsEffects {
  listProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsUiActions.listProducts),
      switchMap((action) =>
        this.productsService.getAllProducts(action.request).pipe(
          map((response) => ProductsDbActions.listProductsSuccess({ response })),
          catchError((error) => of(ProductsDbActions.listProductsFailure({ error })))
        )
      )
    )
  );
  addProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsUiActions.addProduct),
      switchMap((action) =>
        this.productsService.addProduct(action.productToAdd).pipe(
          map((addedProduct) => ProductsDbActions.addProductSuccess({ addedProduct })),
          catchError((error) => of(ProductsDbActions.addProductFailure({ error })))
        )
      )
    )
  );
  updateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsUiActions.updateProduct),
      switchMap((action) =>
        this.productsService.updateProduct(action.productToUpdate).pipe(
          map((updatedProduct) => ProductsDbActions.updateProductSuccess({ updatedProduct })),
          catchError((error) => of(ProductsDbActions.updateProductFailure({ error })))
        )
      )
    )
  );
  deleteProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsUiActions.deleteProduct),
      switchMap((action) =>
        this.productsService.deleteProduct(action.productToDelete).pipe(
          map((deletedProduct) => ProductsDbActions.deleteProductSuccess({ deletedProduct })),
          catchError((error) => of(ProductsDbActions.deleteProductFailure({ error })))
        )
      )
    )
  );
  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProductsDbActions.addProductSuccess, ProductsDbActions.updateProductSuccess, ProductsDbActions.deleteProductSuccess),
        tap((action) => {
          this.notificationService.displaySuccess(action.type);
        })
      ),
    { dispatch: false }
  );
  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProductsDbActions.addProductFailure,
          ProductsDbActions.updateProductFailure,
          ProductsDbActions.deleteProductFailure,
          ProductsDbActions.listProductsFailure
        ),
        tap((action) => {
          this.notificationService.displayError(action);
        })
      ),
    { dispatch: false }
  );
  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProductsUiActions.listProducts, ProductsUiActions.addProduct, ProductsUiActions.updateProduct, ProductsUiActions.deleteProduct),
        tap(() => {
          this.notificationService.showLoader();
        })
      ),
    { dispatch: false }
  );
  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProductsDbActions.listProductsSuccess,
          ProductsDbActions.listProductsFailure,
          ProductsDbActions.addProductSuccess,
          ProductsDbActions.addProductFailure,
          ProductsDbActions.updateProductSuccess,
          ProductsDbActions.updateProductFailure,
          ProductsDbActions.deleteProductSuccess,
          ProductsDbActions.deleteProductFailure
        ),
        tap(() => {
          this.notificationService.hideLoader();
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private productsService: AdminProductsService) {}
}
