<div class="tag-category-editor" fxFlex fxLayout="column" fxLayoutGap="6px">
  <iot4bos-backoffice-ui-tag-editor-form
    *ngFor="let tagCategory of categories$ | async"
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="10px"
    [mode]="'UPDATE'"
    [tagCategory]="tagCategory"
    [colors]="colors$ | async"
    (changeValue)="onChangeValue()"
    (save)="onUpdateTagCategory($event)"
    (remove)="onRemove($event)"
  >
  </iot4bos-backoffice-ui-tag-editor-form>

  <iot4bos-backoffice-ui-tag-editor-form
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="10px"
    [tagCategory]="newCategory"
    [colors]="colors$ | async"
    [mode]="'ADD'"
    (save)="onAddTagCategory($event)"
    (changeValue)="onChangeValue()"
  >
  </iot4bos-backoffice-ui-tag-editor-form>
</div>
