import { get } from 'lodash';
import * as moment from 'moment';
import { GetUtils } from './get.utils';

export class TemplateDefUtils {
  static objectValuePredicate = ({ value }): unknown => {
    const values = value ? Object.values(value) : [];
    return values?.length ? values[0] : null;
  };

  static concatPredicate = ({ element, def }): unknown => {
    return def.attrKeys.map((key: string) => GetUtils.get(element, key, '')).join(def.separator);
  };

  static booleanPredicate = ({ value }): string => {
    return value ? 'IOT_DICTIONARY.YES' : 'IOT_DICTIONARY.NO';
  };

  static timeFormatPredicate = ({ value }): string => {
    return moment(value * 1000).format('YYYY-MM-DD HH:mm:ss');
  };

  static dateFormatPredicate = ({ value }): string => {
    return moment(value).isValid() ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-';
  };

  static shortDateFormatPredicate = ({ value }): string => {
    return moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : '-';
  };

  static longDateFormatPredicate = ({ value }) => {
    return moment(value).format('LL');
  };

  static getQueryParams = ({ data, def }): string => {
    const params: { [key: string]: string } = this.getParams({ def }).reduce((acc: { [key: string]: string }, { attr, paramKey }) => {
      return { ...acc, [paramKey]: get(data, attr) };
    }, {});
    const _queryParams: string = Object.keys(params)
      .map((key: string) => `${key}=${params[key]}`)
      .join('&');
    return `?${_queryParams}`;
  };

  static getPathParams = ({ data, def }): string => {
    return this.getParams({ def }).reduce((acc: string, { attr }) => {
      return `${acc}/${get(data, attr)}`;
    }, '');
  };

  static hasParams = ({ data, def }): boolean => {
    const values = this.getParams({ def })
      .map(({ attr }) => {
        return get(data, attr);
      })
      .filter((value: string) => value !== null && value !== undefined);
    return !!values.length;
  };

  static trimPredicate = ({ value }) => {
    return value?.toString()?.trim() ?? null;
  };

  private static getParams({ def }): Array<{ attr: string; paramKey?: string }> {
    return def.queryParams ?? def.pathParams ?? [];
  }
}
