import { ProcessCellForExportParams } from '@ag-grid-community/core';
import {
  DiagnosticVariableCellOptionsDisplayModeType,
  I4BColumn,
  I4BColumnConfiguration,
  I4BColumnHeader,
  I4BColumnOptions
} from '@iot-platform/models/grid-engine';
import { DeviceVariable } from '@iot-platform/models/i4b';
import { get } from 'lodash';

const variableNamesByCategory: { [key: string]: string[] } = {
  TEMPERATURE: ['TLF_TEMP', 'TEMP', 'CAM_TEMP', 'µD_TEMP', 'TP'],
  BATTERY_LEVEL: ['TLF_VBATT', 'VBATT', 'µD_BATT_PCT', 'CAM_BATT_PCT', 'BL', 'BATTERY_LEVEL'],
  RADIO_LEVEL: ['RL'],
  SMS_SENT: ['SMS_DAY_COUNTER', 'SS'],
  ENERGY_MODE: ['EM']
};

export type DiagnosticVariableExportType = 'datetime' | 'value' | 'unit';

export class DiagnosticVariableCellHelper {
  static getVariableNamesByCategory(category: string): string[] {
    return variableNamesByCategory[category.toUpperCase()] ?? [];
  }

  static getVariableNamesByGridColumns(columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[]): string[] {
    const diagnosticVariableColumns = columns.filter((col) => col.columnId === '9374044f-8451-444e-8a16-6044eec9c112-diagnostic-variable-group');
    if (!!diagnosticVariableColumns.length) {
      return diagnosticVariableColumns.reduce((acc: string[], value) => {
        acc.push(...DiagnosticVariableCellHelper.getVariableNamesByCategory(value.configuration.id));
        return acc;
      }, []);
    } else {
      return [];
    }
  }

  static getDiagnosticVariableByCategory(category: string, expandedVariables: { [key: string]: Partial<DeviceVariable> }): Partial<DeviceVariable> | undefined {
    const variableNamesByCategory = DiagnosticVariableCellHelper.getVariableNamesByCategory(category);
    const firstVariableFound = variableNamesByCategory.find((v) => !!expandedVariables[v.toLowerCase()]);
    return firstVariableFound ? expandedVariables[firstVariableFound.toLowerCase()] : undefined;
  }

  static getValueToProcessByType(params: ProcessCellForExportParams): { value: string; type: DiagnosticVariableExportType } {
    const { column, node, value } = params;
    const cellCategory: string = get(column, 'colDef.cellRendererParams.cellOptions.variableCategory');
    const expandedVariables: { [key: string]: Partial<DeviceVariable> } = get(node, 'data.expandedVariables');
    const diagnosticVariable: Partial<DeviceVariable> = DiagnosticVariableCellHelper.getDiagnosticVariableByCategory(cellCategory, expandedVariables);
    const displayMode: DiagnosticVariableCellOptionsDisplayModeType = get(column, 'colDef.cellRendererParams.cellOptions.displayMode');

    if (!diagnosticVariable || !diagnosticVariable.lastValue) {
      return { type: displayMode, value: undefined };
    } else if (get(column, 'colDef.headerName') === 'UNIT') {
      return { type: 'unit', value: diagnosticVariable.unit };
    } else {
      return { type: displayMode, value: diagnosticVariable.lastValue[displayMode].toString() };
    }
  }
}
