import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { Asset, AssetEvent, AssetVariable, DeviceEvent } from '@iot-platform/models/i4b';
import {
  ProcessMultipleStateVariablesUtil
} from '../../../../../../../shared/src/lib/utils/process-multiple-state-variables.util';

@Component({
  selector: 'iot-platform-ui-detail-asset-variable-card',
  templateUrl: './detail-asset-variable-card.component.html',
  styleUrls: ['./detail-asset-variable-card.component.scss']
})
export class DetailAssetVariableCardComponent implements OnInit, OnChanges {
  @Input() asset: Asset;
  @Input() assetVariable: AssetVariable;
  @Input() event: AssetEvent | DeviceEvent;

  assetVariableProcessedUnit: string;
  eventVariableProcessedUnit: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('assetVariable') && changes.assetVariable.currentValue) {
      if (changes.assetVariable.currentValue.unit && changes.assetVariable.currentValue.unit[0] === '/') {
        this.assetVariableProcessedUnit = ProcessMultipleStateVariablesUtil.getProcessedUnit(changes.assetVariable.currentValue);
      }
    }

    if (changes.hasOwnProperty('event') && changes.event.currentValue) {
      if (changes.event.currentValue.context?.assetVariable?.unit && changes.event.currentValue.context?.assetVariable?.unit[0] === '/') {
        const lastRecord =
          changes.event.currentValue.context.assetVariable.value !== null
            ? [{ value: changes.event.currentValue.context.assetVariable.value, datetime: '' }]
            : [];

        this.eventVariableProcessedUnit = ProcessMultipleStateVariablesUtil.getProcessedUnit({
          ...changes.event.currentValue.context.assetVariable,
          lastRecords: lastRecord
        });
      }
    }
  }
}
