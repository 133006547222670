import { I4BCellType, I4BColumnConfiguration } from './configurations';
import { getDefaultColumnsByConcept } from './default-grids.definitions';
import {
  DeviceCommunicationGroupColumn,
  DeviceTypeGroupColumn,
  I4BBasicColumn,
  I4BColumn,
  I4BDeviceStatusColumn,
  I4BSelectionColumn,
  I4BStockSiteDevicesButtonColumn,
  IdColumn,
  NameColumn,
  TotalColumn,
  TotalEventsColumn,
  TotalTagsColumn,
  UpdatedGroupColumn
} from './definitions';
import { I4bCellConcept } from './enums';
import { DevicesGridData, HeaderType, I4BColumnHeader, I4BGrid, I4BGridOptions } from './models';
import { I4BColumnOptions } from './options';

export const DEFAULT_DEVICE_COLUMNS_FOR_STOCK_SITE_DEVICES_VIEW: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
  new NameColumn(
    { displayName: 'NAME', type: HeaderType.ICON_AND_TEXT, icon: 'device' },
    {
      id: 'name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_NAME',
      concept: I4bCellConcept.DEVICES,
      isDefault: true,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToDevice' } }
    },
    { order: 10 }
  ),

  new I4BBasicColumn(
    { displayName: 'FAMILY' },
    {
      id: 'type.family',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FAMILY',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      sortProperty: 'type.family',
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    { order: 20 }
  ),

  new I4BBasicColumn(
    { displayName: 'SUPPORT' },
    {
      id: 'communication.support',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.COMMUNICATION_SUPPORT',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      sortProperty: 'communication.support',
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    { order: 30 }
  ),

  new I4BBasicColumn(
    { displayName: 'LAST INCOMING CALL', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_INCOMING_CALL',
      id: 'lastCallStatus.datetime',
      isDefault: true,
      concept: I4bCellConcept.DEVICES,
      width: 165,
      cell: {
        type: I4BCellType.DATE
      }
    },
    { order: 40 }
  ),

  new I4BBasicColumn(
    { displayName: 'INCOMING CONNECTOR ID', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.INCOMING_CONNECTOR_ID',
      id: 'incomingConnector.id',
      isDefault: true,
      sortProperty: 'incomingConnector.id',
      concept: I4bCellConcept.DEVICES,
      width: 100,
      cell: {
        type: I4BCellType.BASIC
      }
    },
    { order: 50 }
  ),

  new I4BBasicColumn(
    { displayName: 'AFFILIATE' },
    {
      id: 'affiliate',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.AFFILIATE',
      cell: { type: I4BCellType.BASIC },
      isDefault: true,
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    { order: 60 }
  ),
  new I4BDeviceStatusColumn({}, { id: 'status.name', concept: I4bCellConcept.DEVICES, width: 100 }, { order: 70 }),

  new IdColumn(
    { displayName: 'IDENTIFIER' },
    {
      id: 'identifier',
      concept: I4bCellConcept.DEVICES,
      width: 150,
      isDefault: false,
      cell: { type: I4BCellType.BASIC_LINK, event: { type: 'navigateToDevice' } }
    },
    { order: 100 }
  ),
  new I4BBasicColumn(
    { displayName: 'CYCLICAL CALL FAULT', type: HeaderType.EMPTY },
    {
      id: 'cyclicalCallFault',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DESCRIPTION',
      cell: { type: I4BCellType.CYCLICAL_CALL_FAULT, valueGetter: 'cyclicalCallFault.status' },
      isDefault: false,
      isConfigurable: false,
      sortProperty: 'cyclicalCallFault.status',
      concept: I4bCellConcept.DEVICES,
      width: 75
    },
    { order: 110 }
  ),
  new I4BBasicColumn(
    { displayName: 'DESCRIPTION' },
    {
      id: 'description',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DESCRIPTION',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'description',
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    { order: 120 }
  ),
  new DeviceTypeGroupColumn({ displayName: 'DEVICE TYPE FULL' }, { id: 'deviceType' }, { order: 130 }),
  new I4BBasicColumn(
    { displayName: 'MODEL' },
    {
      id: 'type.model',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_MODEL',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'type.model',
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    { order: 150 }
  ),
  new I4BBasicColumn(
    { displayName: 'FIRMWARE' },
    {
      id: 'type.firmware',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_TYPE_FIRMWARE',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'type.firmware',
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    { order: 160 }
  ),
  new DeviceCommunicationGroupColumn({ displayName: 'COMMUNICATION FULL' }, { id: 'communication' }, { order: 170 }),
  new I4BBasicColumn(
    { displayName: 'ENDPOINT' },
    {
      id: 'communication.endpoint',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.COMMUNICATION_ENDPOINT',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      sortProperty: 'communication.endpoint',
      concept: I4bCellConcept.DEVICES,
      width: 120
    },
    { order: 180 }
  ),
  new I4BBasicColumn(
    { displayName: 'INCOMING CONNECTOR NAME', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.INCOMING_CONNECTOR_NAME',
      id: 'incomingConnector.name',
      isDefault: false,
      sortProperty: 'incomingConnector.name',
      concept: I4bCellConcept.DEVICES,
      width: 120,
      cell: {
        type: I4BCellType.BASIC
      }
    },
    { order: 190 }
  ),
  new I4BBasicColumn(
    { displayName: 'OUTGOING CONNECTOR NAME', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OUTGOING_CONNECTOR_NAME',
      id: 'outgoingConnector.name',
      isDefault: false,
      sortProperty: 'outgoingConnector.name',
      concept: I4bCellConcept.DEVICES,
      width: 120,
      cell: {
        type: I4BCellType.BASIC
      }
    },
    { order: 200 }
  ),
  new I4BBasicColumn(
    { displayName: 'OUTGOING CONNECTOR ID', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OUTGOING_CONNECTOR_ID',
      id: 'outgoingConnector.id',
      isDefault: false,
      sortProperty: 'outgoingConnector.id',
      concept: I4bCellConcept.DEVICES,
      width: 100,
      cell: {
        type: I4BCellType.BASIC
      }
    },
    { order: 210 }
  ),
  new TotalEventsColumn(
    { displayName: 'TOTAL DEVICE EVENTS', icon: 'device-event' },
    { id: 'totalActiveEvents', concept: I4bCellConcept.DEVICES, isDefault: false },
    { order: 220 }
  ),
  new TotalTagsColumn({}, { concept: I4bCellConcept.DEVICES, isDefault: false }, { order: 230 }),
  new TotalColumn(
    { displayName: 'TOTAL VARIABLES', icon: 'variable' },
    { id: 'totalVariables', concept: I4bCellConcept.DEVICES, isDefault: false },
    { order: 240 }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST COMMAND STATUS' },
    {
      id: 'lastCommandStatus.name',
      concept: I4bCellConcept.DEVICES,
      isDefault: false,
      sortProperty: 'lastCommandStatus.name',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_STATUS',
      cell: { type: I4BCellType.LAST_COMMAND_STATUS_CELL }
    },
    { order: 250 }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST COMMAND DATE', type: HeaderType.BASIC },
    {
      id: 'lastCommandStatus.datetime',
      cell: { type: I4BCellType.DATE },
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_DATE',
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 165
    },
    { order: 260 }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST COMMAND SENT' },
    {
      id: 'lastCommandStatus.command',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_SENT',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    { order: 270 }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST COMMAND MESSAGE' },
    {
      id: 'lastCommandStatus.status',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_MESSAGE',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    { order: 280 }
  ),
  new I4BBasicColumn(
    { displayName: 'LAST COMMAND ERROR' },
    {
      id: 'lastCommandStatus.commandErrorReason',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.LAST_COMMAND_ERROR',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    { order: 290 }
  ),
  new I4BBasicColumn(
    { displayName: 'ICCID' },
    {
      id: 'communication.iccid',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ICCID',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    { order: 300 }
  ),
  new I4BBasicColumn(
    { displayName: 'OPERATOR' },
    {
      id: 'communication.operator',
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.OPERATOR',
      cell: { type: I4BCellType.BASIC },
      isDefault: false,
      concept: I4bCellConcept.DEVICES,
      width: 100
    },
    { order: 310 }
  )
];

export const DEFAULT_TECHNICAL_COLUMNS_FOR_STOCK_SITE_DEVICES_VIEW = [
  new IdColumn(
    { displayName: 'DEVICE TECHNICAL ID' },
    {
      id: 'id',
      concept: I4bCellConcept.TECHNICAL,
      isDefault: false,
      cell: {
        type: I4BCellType.BASIC_LINK,
        event: { type: 'navigateToDevice' }
      }
    },
    { order: 10 }
  ),
  new I4BBasicColumn(
    { displayName: 'CREATED AT', type: HeaderType.BASIC },
    {
      translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.CREATED_AT',
      id: 'createdAt',
      isDefault: false,
      sortProperty: 'createdAt',
      concept: I4bCellConcept.TECHNICAL,
      width: 165,
      cell: { type: I4BCellType.DATE }
    },
    { order: 20 }
  ),
  new UpdatedGroupColumn({ displayName: 'UPDATED FULL' }, { id: 'updatedInfo', concept: I4bCellConcept.TECHNICAL }, { order: 30 })
];

export class DefaultStockSiteDevicesGrid implements I4BGrid<I4BGridOptions, DevicesGridData> {
  masterview: string = 'stock-site-devices';
  isDefault: boolean = false;
  isUserDefault: boolean = false;
  isAppDefault: boolean = false;
  name: string = null;
  userId: string = null;
  data: DevicesGridData;
  gridOptions: I4BGridOptions = {
    endPoint: '/devices',
    pageSize: 100,
    multipleSelection: true,
    autoRefresh: { enabled: false },
    gridSort: [{ colId: 'name', sort: 'asc', sortIndex: 0 }],
    buttonColumn: { enabled: true, className: new I4BStockSiteDevicesButtonColumn() },
    selectionColumn: { enabled: true, className: new I4BSelectionColumn() }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [...getDefaultColumnsByConcept(this.masterview)];

  export: () => {};
}
