<section class='site-contacts'>
  <iot4bos-ui-panel
    [title]="'SITES.CONTACT_FORM.PANEL_TITLE' | translate"
    [hasButton]='false'
    [withCustomAction]='true'
    [buttonTooltip]="'SITES.CONTACT_FORM.ADD' | translate"
  >
    <div panelCustomAction fxFlexLayout='row' fxLayoutAlign='center center'>
      <div *ngIf="contacts?.length > 3 || (contacts?.length > 2 && canAddContact)" class="panel-button app-round-button" [matTooltip]="(horizontalLayout ? 'PANELS.TOOLTIPS.HORIZONTAL_LAYOUT': 'PANELS.TOOLTIPS.VERTICAL_LAYOUT') | translate" matTooltipClass="regular-tooltip">
        <button mat-icon-button fxHide.lt-md (click)="horizontalLayout = !horizontalLayout">
          <mat-icon>{{ horizontalLayout ? 'apps' : 'swap_horiz' }}</mat-icon>
        </button>
      </div>
      <div class="panel-button app-round-button site-contacts__btn-add" *ngIf="canUpdateSite && canAddContact" [matTooltip]="'SITES.CONTACT_FORM.ADD' | translate" matTooltipClass="regular-tooltip">
        <button mat-icon-button (click)='onAddContact()'>
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>

    <ng-container panelGrid *ngIf="!loading && !hasContacts; else contactsTmpl">
      <ng-container *ngTemplateOutlet="emptyContactsTmp"></ng-container>
    </ng-container>

  </iot4bos-ui-panel>
</section>

<ng-template #contactsTmpl>
  <div panelGrid *ngIf="loading" fxFlexLayout='row' fxLayoutAlign='center center' style='width: 100%'>
    <mat-progress-spinner color='accent' mode='indeterminate' [diameter]='50'></mat-progress-spinner>
  </div>

  <oyan-ui-cards-container panelGrid [horizontalLayout]="horizontalLayout" *ngIf="!loading">
    <oyan-ui-contact-card class='card-sm' fxFlex.lt-md="100%"
                          [canUpdateSite]='canUpdateSite'
                          [canUpdate]='canUpdateContact'
                          [canDelete]='canDeleteContact'
                          *ngFor='let contact of contacts' [element]='contact'
                          (dispatchEvent)="onDispatchEvent($event)"
    ></oyan-ui-contact-card>

    <iot4bos-ui-default-card *ngIf="canUpdateSite && canAddContact" class='card-sm' fxFlex.lt-md="100%"
                             [withCustomButton]="false"
                             [backgroundClass]="'h-100'"
                             [title]="'SITES.CONTACT_FORM.ADD' | translate"
                             (click)='onAddContact()'>

    </iot4bos-ui-default-card>
  </oyan-ui-cards-container>
</ng-template>

<ng-template #emptyContactsTmp>
  <div class="no-data-message" fxFlexLayout='row' fxFlexAlign='center center'>
    <p>{{ 'SITES.CONTACT_FORM.NO_CONTACTS' | translate }}</p>
  </div>
</ng-template>
