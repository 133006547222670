<div class='info-display__header'>
  <iot-platform-ui-breadcrumb
      [data]="(activeElement$ | async)?.data"
      [icon]="(activeElement$ | async)?.icon"
      [title]="(activeElement$ | async)?.title"
      [label]="(activeElement$ | async)?.label"
      [loading]="siteLoading$ | async"
      (back)="onBack()"
  >
  </iot-platform-ui-breadcrumb>
</div>
<div class='info-display__content'>
  <router-outlet></router-outlet>
</div>
