import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Filter } from '@iot-platform/models/common';
import { Observable } from 'rxjs';
import { AsyncAutocompleteComponent } from '../async-autocomplete/async-autocomplete.component';

@Component({
  selector: 'iot-platform-ui-async-autocomplete-multiple-selects',
  templateUrl: './async-autocomplete-multiple-selects.component.html',
  styleUrls: ['./async-autocomplete-multiple-selects.component.scss']
})
export class AsyncAutocompleteMultipleSelectsComponent extends AsyncAutocompleteComponent implements OnInit, OnChanges {
  selectedFilters: any = [];
  filtersSize: number = 0;
  currentFilters: Filter[] = [];

  @Input() currentFilters$!: Observable<Filter[]>;
  @Input() currentFiltersSize: number = 0;
  @Input() multiSelect: boolean = true;
  @Input() maxFilters: number = 20;
  @Input() type: string = '';

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.filtersSize = this.currentFiltersSize;

    if (this.multiSelect) {
      this.currentFilters$.subscribe((filters: Filter[]) => {
        this.currentFilters = [...filters];
        if (this.data) {
          this.selectedFilters = [];
          this.data.forEach((option: any) => {
            if (filters.find((filter: Filter) => (option.id ? option.id === filter.value : option.key === filter.value))) {
              option.selected = true;
              this.selectedFilters.push(option);
            } else {
              option.selected = false;
            }
          });
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.standaloneMode && this.multiSelect && this.currentFilters.length) {
      if (changes?.hasOwnProperty('data') && changes.data.currentValue) {
        changes.data.currentValue.forEach((option: any) => {
          if (this.currentFilters.find((filter: Filter) => option.id === filter.value)) {
            option.selected = true;
            this.selectedFilters.push(option);
          }
        });
      }
    }

    if (this.multiSelect && this.type === 'tag' && changes?.hasOwnProperty('data') && changes.data.currentValue && changes.data.previousValue) {
      this.data = [...changes.data.currentValue];
      this.initFilteredData();
    }
  }

  onMultiOptionSelection(event: any, item: any): void {
    event.stopPropagation();
    this.toggleSelection(item);
  }

  toggleSelection(item: any): void {
    item.selected = !item.selected;
    if (item.selected) {
      this.selectedFilters.push(item);
      this.filtersSize++;
    } else {
      const i = this.selectedFilters.findIndex((option: any) => option[this.displayKey] === item[this.displayKey]);
      this.selectedFilters.splice(i, 1);
      this.filtersSize--;
    }
    this.selectionChanged.emit(item);
  }
}
