<ul
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="20px"
  class="related-business-profiles-bp-list"
>
  <li
    *ngFor="let profile of currentUser?.businessProfiles"
    class=""
    [ngClass]="{ 'out-of-scope-bp': profile.notCoveredByUserEntityAt !== null }"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="14px"
  >
    <mat-icon>people</mat-icon>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>{{ profile.name }}</span>
      <span class="grey" *ngIf="profile.notCoveredByUserEntityAt === null; else outOfScopeMessage">
              <span> {{ profile.entityName }} </span>
            </span>
      <ng-template #outOfScopeMessage>
              <span class="out-of-scope-bp-message">
                {{ 'ADMIN.USERS.TABLE.BUSINESS_PROFILE_OUT_OF_SCOPE' | translate }}
              </span>
      </ng-template>
    </div>
  </li>
</ul>
