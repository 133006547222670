import { I4BColumnConfiguration } from './configurations';
import { DefaultActiveAssetEventsPopupGrid } from './default-active-asset-events-popup-grids.definition';
import {
  DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_VIEW,
  DEFAULT_DEVICES_COLUMNS_FOR_ASSETS_EVENTS_VIEW,
  DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_VIEW,
  DEFAULT_SITES_COLUMNS_FOR_ASSET_EVENTS_VIEW,
  DefaultAssetEventsGrid
} from './default-asset-events-grids.definition';

import {
  DEFAULT_ASSETS_COLUMNS_FOR_ASSETS_VIEW,
  DEFAULT_SITES_COLUMNS_FOR_ASSETS_VIEW,
  DEFAULT_TECHNICAL_COLUMNS_FOR_ASSETS_VIEW,
  DefaultAssetsGrid,
  FOLLOWED_VARIABLES_COLUMNS_FOR_ASSETS_VIEW,
  VARIABLES_GROUPED_COLUMNS_FOR_ASSETS_VIEW
} from './default-asset-grid.definition';
import { DEFAULT_CONNECTORS_COLUMNS_FOR_CONNECTORS_VIEW, DefaultConnectorsGrid } from './default-connectors-grid.definition';
import {
  DEFAULT_ASSET_COLUMNS_FOR_DEVICE_EVENTS_VIEW,
  DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_VIEW,
  DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_VIEW,
  DEFAULT_SITES_COLUMNS_FOR_DEVICE_EVENTS_VIEW,
  DefaultDeviceEventsGrid
} from './default-device-events-grids.definition';
import {
  DEFAULT_DEVICES_COLUMNS_FOR_DEVICES_VIEW,
  DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_DEVICE_VIEW,
  DEFAULT_SITES_COLUMNS_FOR_DEVICES_VIEW,
  DEFAULT_TECHNICAL_COLUMNS_FOR_DEVICES_VIEW,
  DefaultDevicesGrid
} from './default-device-grid.definition';

import { DEFAULT_EMAIL_TEMPLATES_COLUMNS_FOR_EMAIL_TEMPLATES_VIEW, DefaultEmailTemplatesGrid } from './default-email-templates-grid.definition';
import {
  DEFAULT_ASSETS_COLUMNS_FOR_PO_EVENT_GENERATED_ASSET_EVENTS_VIEW,
  DEFAULT_EVENTS_COLUMNS_FOR_PO_EVENT_GENERATED_ASSET_EVENTS_VIEW,
  DefaultPoEventGeneratedAssetEventsGrid
} from './default-po-event-generated-asset-events-grids.definition';
import {
  DEFAULT_DEVICES_COLUMNS_FOR_PO_EVENT_GENERATED_DEVICE_EVENTS_VIEW,
  DEFAULT_EVENTS_COLUMNS_FOR_PO_EVENT_GENERATED_DEVICE_EVENTS_VIEW,
  DefaultPoEventGeneratedDeviceEventsGrid
} from './default-po-event-generated-device-events-grids.definition';
import {
  DEFAULT_GEOLOCATION_COLUMNS_FOR_SITES_VIEW,
  DEFAULT_SITES_COLUMNS_FOR_SITES_VIEW,
  DEFAULT_TECHNICAL_COLUMNS_FOR_SITES_VIEW,
  DefaultSitesGrid
} from './default-sites-grid.definition';
import {
  DEFAULT_DEVICE_COLUMNS_FOR_STOCK_SITE_DEVICES_VIEW,
  DEFAULT_TECHNICAL_COLUMNS_FOR_STOCK_SITE_DEVICES_VIEW,
  DefaultStockSiteDevicesGrid
} from './default-stock-site-devices-grid.definition';
import { I4BColumn } from './definitions';
import { I4bCellConcept } from './enums';
import { I4BColumnHeader } from './models';
import { I4BColumnOptions } from './options';

export const defaultGridsDefinitions: {
  viewType: string;
  dataLibraries: { libraryName: string; columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] }[];
}[] = [
  {
    viewType: 'SITES',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: DEFAULT_SITES_COLUMNS_FOR_SITES_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      },
      {
        libraryName: 'GEOLOCATION',
        columns: DEFAULT_GEOLOCATION_COLUMNS_FOR_SITES_VIEW
      },
      {
        libraryName: 'TECHNICAL',
        columns: DEFAULT_TECHNICAL_COLUMNS_FOR_SITES_VIEW
      }
    ]
  },
  {
    viewType: 'STOCK-SITE-DEVICES',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICE_COLUMNS_FOR_STOCK_SITE_DEVICES_VIEW
      },
      {
        libraryName: 'TECHNICAL',
        columns: DEFAULT_TECHNICAL_COLUMNS_FOR_STOCK_SITE_DEVICES_VIEW
      }
    ]
  },
  {
    viewType: 'ASSETS',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: DEFAULT_SITES_COLUMNS_FOR_ASSETS_VIEW
      },
      {
        libraryName: 'ASSETS',
        columns: DEFAULT_ASSETS_COLUMNS_FOR_ASSETS_VIEW
      },
      {
        libraryName: 'FOLLOWED VARIABLES',
        columns: FOLLOWED_VARIABLES_COLUMNS_FOR_ASSETS_VIEW
      },
      {
        libraryName: 'GROUPED VARIABLES',
        columns: VARIABLES_GROUPED_COLUMNS_FOR_ASSETS_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      },
      {
        libraryName: 'TECHNICAL',
        columns: DEFAULT_TECHNICAL_COLUMNS_FOR_ASSETS_VIEW
      }
    ]
  },
  {
    viewType: 'DEVICES',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: DEFAULT_SITES_COLUMNS_FOR_DEVICES_VIEW
      },
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_DEVICES_VIEW
      },
      {
        libraryName: 'DIAGNOSTIC VARIABLES',
        columns: DEFAULT_DIAGNOSTIC_VARIABLE_COLUMNS_FOR_DEVICE_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      },
      {
        libraryName: 'TECHNICAL',
        columns: DEFAULT_TECHNICAL_COLUMNS_FOR_DEVICES_VIEW
      }
    ]
  },
  {
    viewType: 'DEVICE-EVENTS',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: DEFAULT_SITES_COLUMNS_FOR_DEVICE_EVENTS_VIEW
      },
      {
        libraryName: 'ASSETS',
        columns: DEFAULT_ASSET_COLUMNS_FOR_DEVICE_EVENTS_VIEW
      },
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_DEVICE_EVENTS_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_DEVICE_EVENTS_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'ASSET-EVENTS',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: DEFAULT_SITES_COLUMNS_FOR_ASSET_EVENTS_VIEW
      },
      {
        libraryName: 'ASSETS',
        columns: DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_VIEW
      },
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_ASSETS_EVENTS_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'EMAIL-TEMPLATES',
    dataLibraries: [
      {
        libraryName: 'EMAIL TEMPLATES',
        columns: DEFAULT_EMAIL_TEMPLATES_COLUMNS_FOR_EMAIL_TEMPLATES_VIEW
      }
    ]
  },
  {
    viewType: 'CONNECTORS',
    dataLibraries: [
      {
        libraryName: 'CONNECTORS',
        columns: DEFAULT_CONNECTORS_COLUMNS_FOR_CONNECTORS_VIEW
      }
    ]
  },
  {
    viewType: 'ACTIVE-ASSET-EVENTS-POPUP',
    dataLibraries: [
      {
        libraryName: 'SITES',
        columns: DEFAULT_SITES_COLUMNS_FOR_ASSET_EVENTS_VIEW
      },
      {
        libraryName: 'ASSETS',
        columns: DEFAULT_ASSETS_COLUMNS_FOR_ASSET_EVENTS_VIEW
      },
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_ASSETS_EVENTS_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_ASSET_EVENTS_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'PO-EVENT-GENERATED-ASSET-EVENTS',
    dataLibraries: [
      {
        libraryName: 'ASSETS',
        columns: DEFAULT_ASSETS_COLUMNS_FOR_PO_EVENT_GENERATED_ASSET_EVENTS_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_PO_EVENT_GENERATED_ASSET_EVENTS_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  },
  {
    viewType: 'PO-EVENT-GENERATED-DEVICE-EVENTS',
    dataLibraries: [
      {
        libraryName: 'DEVICES',
        columns: DEFAULT_DEVICES_COLUMNS_FOR_PO_EVENT_GENERATED_DEVICE_EVENTS_VIEW
      },
      {
        libraryName: 'EVENTS',
        columns: DEFAULT_EVENTS_COLUMNS_FOR_PO_EVENT_GENERATED_DEVICE_EVENTS_VIEW
      },
      {
        libraryName: 'TAGS',
        columns: []
      }
    ]
  }
];

export const getDefaultColumnsByConcept = (concept: I4bCellConcept | string): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] => {
  return (
    defaultGridsDefinitions
      .filter((defaultGrid) => defaultGrid.viewType.toLowerCase() === concept.toLowerCase())[0]
      ?.dataLibraries.reduce((acc: any, value) => {
        acc = [...acc, ...value.columns.filter((col) => col.configuration.isDefault)];
        return acc;
      }, [])
      .sort((colA, colB) => (colA.options.order > colB.options.order ? 1 : -1)) ?? []
  );
};

export const getAllColumnsByConcept = (concept: I4bCellConcept | string): I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] => {
  return (
    defaultGridsDefinitions
      .filter((defaultGrid) => defaultGrid.viewType.toLowerCase() === concept.toLowerCase())[0]
      ?.dataLibraries.reduce((acc: any, value) => {
        acc = [...acc, ...value.columns];
        return acc;
      }, []) ?? []
  );
};

export const defaultSitesGrid: DefaultSitesGrid = new DefaultSitesGrid();
export const defaultStockSiteDevicesGrid: DefaultStockSiteDevicesGrid = new DefaultStockSiteDevicesGrid();
export const defaultAssetsGrid: DefaultAssetsGrid = new DefaultAssetsGrid();
export const defaultDevicesGrid: DefaultDevicesGrid = new DefaultDevicesGrid();
export const defaultDeviceEventsGrid: DefaultDeviceEventsGrid = new DefaultDeviceEventsGrid();
export const defaultAssetEventsGrid: DefaultAssetEventsGrid = new DefaultAssetEventsGrid();
export const defaultEmailTemplatesGrid: DefaultEmailTemplatesGrid = new DefaultEmailTemplatesGrid();
export const defaultConnectorsGrid: DefaultConnectorsGrid = new DefaultConnectorsGrid();
export const defaultActiveAssetEventsPopupGrid: DefaultActiveAssetEventsPopupGrid = new DefaultActiveAssetEventsPopupGrid();
export const defaultPoEventGeneratedAssetEventsGrid: DefaultPoEventGeneratedAssetEventsGrid = new DefaultPoEventGeneratedAssetEventsGrid();
export const defaultPoEventGeneratedDeviceEventsGrid: DefaultPoEventGeneratedDeviceEventsGrid = new DefaultPoEventGeneratedDeviceEventsGrid();
