import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

import { CustomOptionsCellParams } from '../custom-options-cell.params';

@Component({
  selector: 'grid-engine-translated-cell',
  templateUrl: './translated-cell.component.html',
  styleUrls: ['./translated-cell.component.scss']
})
export class TranslatedCellComponent implements ICellRendererAngularComp {
  params: CustomOptionsCellParams;

  translateKey: string;
  translateStyle: any;

  agInit(params: CustomOptionsCellParams): void {
    this.params = params;

    this.translateKey = this.params.cellOptions?.translateKey ?? 'IOT_DICTIONARY.';
    this.translateStyle = this.params.cellOptions?.class ? this.params.cellOptions.class[this.processValue()] : '';
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  processValue(): string {
    if (!!this.params.value) {
      return this.params.value
        .toLowerCase()
        .split('_')
        .reduce((acc, value) => {
          if (acc && value) {
            return acc + value[0].toUpperCase() + value.slice(1);
          }
          return value;
        }, '');
    }
    return '';
  }
}
