import { NgModule } from '@angular/core';
import { SharedModule } from '@iot-platform/shared';
import { UiModule } from '@iot-platform/iot-platform-ui';
import { EventCommentComponent } from './event-comment/event-comment.component';
import { EventCommentsComponent } from './event-comments.component';
import { IotPlatformPipesModule } from '@iot-platform/pipes';

@NgModule({
  imports: [UiModule, SharedModule, IotPlatformPipesModule],
  declarations: [EventCommentComponent, EventCommentsComponent],
  exports: [EventCommentsComponent]
})
export class EventCommentsModule {}
