import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { TagCategory } from '@iot-platform/models/common';

import { select, Store } from '@ngrx/store';

import { BehaviorSubject, Observable } from 'rxjs';

import {
  ColorPickerService
} from '../../../../../iot-platform-ui/src/lib/ui/components/color-picker/color-picker.service';

import { AdminOrganizationsTagsPageActions } from '../../features/admin-organizations/state/actions';
import * as fromOrganizations from '../../features/admin-organizations/state/reducers';

@Component({
  selector: 'iot4bos-backoffice-ui-tag-editor',
  templateUrl: './tag-editor.component.html',
  styleUrls: ['./tag-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TagEditorComponent implements OnInit, AfterContentInit {
  @Input() organizationId: string;
  @Input() concept: string;

  @Output() changeValue: EventEmitter<boolean> = new EventEmitter();

  areCategoriesSaved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  newCategory: TagCategory;
  colors$: Observable<string[]> = this.colorPickerService.getColorList();

  categories$: Observable<TagCategory[]> = this.store.pipe(select(fromOrganizations.getInEditModeTags));
  initialCats: TagCategory[];

  constructor(private store: Store<fromOrganizations.State>, private colorPickerService: ColorPickerService) {}

  ngOnInit() {
    this.categories$.subscribe((cat) => {
      this.initialCats = cat;
      this.areCategoriesSaved$.next(true);
    });

    this.newCategory = { entityId: this.organizationId, concept: this.concept.toUpperCase(), labels: [], mandatory: false, color: '', name: '' };

    this.areCategoriesSaved$.subscribe((value) => this.changeValue.emit(value));
  }

  ngAfterContentInit(): void {
    if (this.organizationId && this.concept) {
      this.store.dispatch(
        AdminOrganizationsTagsPageActions.listTagsByOrganizationForEdition({ organizationId: this.organizationId, withParents: false, concept: this.concept })
      );
    }
  }

  onChangeValue(): void {
    this.areCategoriesSaved$.next(false);
  }

  onAddTagCategory(category: TagCategory) {
    if (category) {
      this.store.dispatch(AdminOrganizationsTagsPageActions.addTagToOrganizationForEdition({ tagToAdd: category }));
    }
  }

  onUpdateTagCategory(category: TagCategory) {
    this.store.dispatch(AdminOrganizationsTagsPageActions.updateTagInOrganizationForEdition({ tagToUpdate: category }));
  }

  onRemove(category) {
    this.store.dispatch(AdminOrganizationsTagsPageActions.removeTagFromOrganizationForEdition({ tagToRemove: category }));
  }
}
