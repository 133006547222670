import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@iot-platform/iot-platform-ui';
import { ExportType } from '@iot-platform/models/grid-engine';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { DateFormatPipe } from '../../../../../../../iot-platform-pipes/src/lib/date-format/date-format.pipe';
import { GridExportService } from '../grid-export.service';

@Component({
  selector: 'grid-engine-grid-export-dialog',
  templateUrl: './grid-export-dialog.component.html',
  styleUrls: ['./grid-export-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridExportDialogComponent extends BaseComponent implements OnInit {
  progressBarValue$: Observable<number> = this.gridExportService.progressBarValue$;
  loading$: Observable<boolean> = this.gridExportService.loading$;
  form: UntypedFormGroup;
  types: ExportType[] = [ExportType.CSV, ExportType.XLSX];
  formState = '';
  canDownload = true;

  constructor(
    private readonly gridExportService: GridExportService,
    private readonly dateFormatPipe: DateFormatPipe,
    private readonly translateService: TranslateService,
    private readonly dialogRef: MatDialogRef<GridExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { totalElements: number }
  ) {
    super();
  }

  get name(): AbstractControl {
    return this.form.get('name');
  }

  get type(): AbstractControl {
    return this.form.get('type');
  }

  get time(): string {
    return this.dateFormatPipe.transform(Date.now(), 'yyyyMMDD');
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required, Validators.pattern('\\S.*')]),
      type: new UntypedFormControl(this.gridExportService.currentType, [Validators.required])
    });
    this.subscriptions.push(
      this.form.valueChanges.subscribe(() => this.onFormChange()),
      this.gridExportService.onExportComplete$.subscribe((complete: boolean) => this.onComplete(complete))
    );
  }

  onTypeChange({ value }): void {
    this.gridExportService.currentType = value;
  }

  submit(): void {
    if (!this.canDownload) {
      this.close();
    } else if (this.form.valid) {
      this.name.disable();
      this.type.disable();
      const fileName = `${this.time}-${this.name.value}`;
      this.gridExportService.loadData(fileName);
    }
  }

  close(): void {
    this.gridExportService.destroy();
    this.dialogRef.close();
  }

  private onFormChange(): void {
    const newState: string = JSON.stringify({
      name: this.name.value,
      type: this.type.value
    });
    this.canDownload = this.formState !== newState;
    if (this.canDownload) {
      this.formState = newState;
    }
  }

  private onComplete(complete: boolean): void {
    this.canDownload = complete;
    this.name.enable();
    this.type.enable();
  }
}
