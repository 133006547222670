<div class="sticky" fxLayout="column">
  <iot-platform-ui-toolbar-v2
    [name]="'ADMIN.PRODUCT_CATALOGS.SHELL.TITLE' | translate"
    [total]="totalProducts$ | async"
    [isDataLoaded]="productsLoaded$ | async"
    [buttonList]="adminProductsCatalogsButtonList"
    [size]="toolbarSize"
    (dispatchToolbarEvent)="onToolbarEvent($event)"
  >
  </iot-platform-ui-toolbar-v2>

  <iot-platform-ui-filter-engine-container
    [masterView]="'product-catalogs'"
    [expanded]="filterEngineOpened"
    [displayActionButtons]="true"
    [withFavoriteFilters]="true"
    [currentFavoriteView$]="currentFavoriteView$"
    [currentFilters$]="currentFilters$"
    (applyFilters)="onApplyFilters($event)"
  >
  </iot-platform-ui-filter-engine-container>
</div>
<mat-sidenav-container class="layout-master-view-engine-container">
  <mat-sidenav-content>
    <grid-engine-grid-engine-component
      fxFlex
      class="layout-ag-grid-shell"
      [gridData]="products$ | async"
      [gridMeta]="mvSettings$ | async"
      [userPermissions]="userPermissions"
      (pageChange)="onPageChange($event)"
      (dispatchMasterViewEngineEvent)="onEventDispatched($event)"
    >
    </grid-engine-grid-engine-component>
  </mat-sidenav-content>
  <mat-sidenav class="sidenav" #sidenav position="end" disableClose>
    <iot4bos-backoffice-ui-admin-product-catalog-panel
      [catalogs]="catalogs$ | async"
      [canUpdate]="canUpdateProduct"
      [canDelete]="canDeleteProduct"
      (editCatalogs)="onEditCatalogs()"
    ></iot4bos-backoffice-ui-admin-product-catalog-panel>
  </mat-sidenav>
</mat-sidenav-container>
