<iot-platform-ui-async-autocomplete
  fxFlex
  displayKey="key"
  [placeholder]="placeholder | translate"
  [autocomplete]="true"
  [clearOnSelect]="clearOnSelect"
  [displaySearchIcon]="false"
  [required]="required"
  [errorMessage]="errorMessage"
  [debounceTime]="0"
  [initialItem]="selectedTimezone$ | async"
  [minLength]="0"
  [data]="filteredTimezones$ | async"
  (search)="onSearch($event)"
  (reset)="onReset()"
  (selectionChanged)="onSelect($event)"
>
</iot-platform-ui-async-autocomplete>
