<section fxFlex fxLayout="column" fxLayoutGap="10px">
  <iot-platform-ui-detail-popup-section-header
    headerTitle="{{ (totalAssets > 1 ? 'IOT_DICTIONARY.assets' : 'IOT_DICTIONARY.asset') | translate }} ({{ totalAssets }})"
  ></iot-platform-ui-detail-popup-section-header>
  <iot-platform-ui-card-loader *ngIf="!isAssetLoaded"
                               [backgroundClass]="'mv-detail-card h-60'"></iot-platform-ui-card-loader>
  <iot-platform-ui-detail-assets-card
    (selectItem)="selectAsset.emit($event)"
    *ngIf="isAssetLoaded"
    [assets]="assets"
    [ngClass]="{ invisible: !isAssetLoaded, visible: isAssetLoaded }"
    [total]="totalAssets"
  ></iot-platform-ui-detail-assets-card>
</section>

