export class OyanUiRoutingConstants {
  public static ADMIN = 'admin';
  public static USERS = 'users';
  public static PROFILE = 'my-profile';
  public static LOGIN = 'login';
  public static HOME = 'home';
  public static SITES = 'sites';
  public static GATEWAYS = 'gateways';
  public static RECEIVERS = 'forwarders';
  public static ASSETS = 'assets';
  public static COMMUNICATIONS = 'communications';
  public static TRANSLATIONS = 'i18n';
  public static PRODUCTS = 'products-catalog';
}
