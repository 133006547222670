import { Pagination, PlatformResponse, User } from '@iot-platform/models/common';
import { Topic } from '@iot-platform/models/ocm';
import { fromUserPreferences } from '@iot-platform/users';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
//
import * as fromAssetEventsByTopicDb from './asset-events-by-topic/asset-events-by-topic-db.reducer';
import * as fromAssetEventsByTopicLogsDb from './asset-events-by-topic/asset-events-by-topic-logs-db.reducer';
import * as fromAssetEventsByTopicLogsUi from './asset-events-by-topic/asset-events-by-topic-logs-ui.reducer';
import * as fromAssetEventsByTopicUi from './asset-events-by-topic/asset-events-by-topic-ui.reducer';
//
import * as fromDeviceEventsByTopicDb from './device-events-by-topic/device-events-by-topic-db.reducer';
import * as fromDeviceEventsByTopicLogsDb from './device-events-by-topic/device-events-by-topic-logs-db.reducer';
import * as fromDeviceEventsByTopicLogsUi from './device-events-by-topic/device-events-by-topic-logs-ui.reducer';
import * as fromDeviceEventsByTopicUi from './device-events-by-topic/device-events-by-topic-ui.reducer';
//
import * as fromTopicsDb from './topics-db.reducer';
import * as fromTopicsSubscribedProtocolsDb from './topics-subscribed-protocols-db.reducer';
import * as fromTopicsSubscribedProtocolsUi from './topics-subscribed-protocols-ui.reducer';
import * as fromTopicsSubscribedUsersDb from './topics-subscribed-users-db.reducer';
import * as fromTopicsSubscribedUsersUi from './topics-subscribed-users-ui.reducer';
import * as fromTopicsUi from './topics-ui.reducer';

export const topicsFeatureKey = 'topics';

export interface TopicsState {
  [fromTopicsDb.topicsDbFeatureKey]: fromTopicsDb.State;
  [fromTopicsUi.topicUiFeatureKey]: fromTopicsUi.State;
  [fromTopicsSubscribedProtocolsDb.topicsSubscribedProtocolsDbFeatureKey]: fromTopicsSubscribedProtocolsDb.State;
  [fromTopicsSubscribedProtocolsUi.topicsSubscribedProtocolsUiFeatureKey]: fromTopicsSubscribedProtocolsUi.State;
  [fromTopicsSubscribedUsersDb.topicsSubscribedUsersDbFeatureKey]: fromTopicsSubscribedUsersDb.State;
  [fromTopicsSubscribedUsersUi.topicsSubscribedUsersUiFeatureKey]: fromTopicsSubscribedUsersUi.State;

  [fromAssetEventsByTopicDb.assetEventsByTopicDbFeatureKey]: fromAssetEventsByTopicDb.State;
  [fromAssetEventsByTopicUi.assetEventsByTopicUiFeatureKey]: fromAssetEventsByTopicUi.State;
  [fromAssetEventsByTopicLogsDb.assetEventsByTopicLogsDbFeatureKey]: fromAssetEventsByTopicLogsDb.State;
  [fromAssetEventsByTopicLogsUi.assetEventsByTopicLogsUiFeatureKey]: fromAssetEventsByTopicLogsUi.State;
  [fromDeviceEventsByTopicDb.deviceEventsByTopicDbFeatureKey]: fromDeviceEventsByTopicDb.State;
  [fromDeviceEventsByTopicUi.deviceEventsByTopicUiFeatureKey]: fromDeviceEventsByTopicUi.State;
  [fromDeviceEventsByTopicLogsDb.deviceEventsByTopicLogsDbFeatureKey]: fromDeviceEventsByTopicLogsDb.State;
  [fromDeviceEventsByTopicLogsUi.deviceEventsByTopicLogsUiFeatureKey]: fromDeviceEventsByTopicLogsUi.State;
}

export interface State {
  [topicsFeatureKey]: TopicsState;
}

export function reducers(state: TopicsState | undefined, action: Action) {
  return combineReducers({
    [fromTopicsDb.topicsDbFeatureKey]: fromTopicsDb.reducer,
    [fromTopicsUi.topicUiFeatureKey]: fromTopicsUi.reducer,
    [fromTopicsSubscribedProtocolsDb.topicsSubscribedProtocolsDbFeatureKey]: fromTopicsSubscribedProtocolsDb.reducer,
    [fromTopicsSubscribedProtocolsUi.topicsSubscribedProtocolsUiFeatureKey]: fromTopicsSubscribedProtocolsUi.reducer,
    [fromTopicsSubscribedUsersDb.topicsSubscribedUsersDbFeatureKey]: fromTopicsSubscribedUsersDb.reducer,
    [fromTopicsSubscribedUsersUi.topicsSubscribedUsersUiFeatureKey]: fromTopicsSubscribedUsersUi.reducer,

    [fromAssetEventsByTopicDb.assetEventsByTopicDbFeatureKey]: fromAssetEventsByTopicDb.reducer,
    [fromAssetEventsByTopicUi.assetEventsByTopicUiFeatureKey]: fromAssetEventsByTopicUi.reducer,
    [fromAssetEventsByTopicLogsDb.assetEventsByTopicLogsDbFeatureKey]: fromAssetEventsByTopicLogsDb.reducer,
    [fromAssetEventsByTopicLogsUi.assetEventsByTopicLogsUiFeatureKey]: fromAssetEventsByTopicLogsUi.reducer,
    [fromDeviceEventsByTopicDb.deviceEventsByTopicDbFeatureKey]: fromDeviceEventsByTopicDb.reducer,
    [fromDeviceEventsByTopicUi.deviceEventsByTopicUiFeatureKey]: fromDeviceEventsByTopicUi.reducer,
    [fromDeviceEventsByTopicLogsDb.deviceEventsByTopicLogsDbFeatureKey]: fromDeviceEventsByTopicLogsDb.reducer,
    [fromDeviceEventsByTopicLogsUi.deviceEventsByTopicLogsUiFeatureKey]: fromDeviceEventsByTopicLogsUi.reducer
  })(state, action);
}

export const selectTopicsState = createFeatureSelector<TopicsState>(topicsFeatureKey);
const getUserPreferencesState = createFeatureSelector<fromUserPreferences.UserPreferencesState>(fromUserPreferences.userPreferencesFeatureKey);

// ****** //
// Topics //
// ****** //

export const selectTopicsDbState = createSelector(selectTopicsState, (state: TopicsState) => {
  return state[fromTopicsDb.topicsDbFeatureKey];
});

export const selectTopicsUiState = createSelector(selectTopicsState, (state: TopicsState) => {
  return state[fromTopicsUi.topicUiFeatureKey];
});

export const {
  selectIds: getTopicsIds,
  selectEntities: getTopicsEntities,
  selectAll: getAllTopics,
  selectTotal: getTotalTopics
} = fromTopicsDb.adapter.getSelectors(selectTopicsDbState);

export const getSelectedTopicId = createSelector(selectTopicsDbState, fromTopicsDb.getSelectedTopicId);
export const getCurrentFilters = createSelector(selectTopicsDbState, fromTopicsDb.getCurrentFilters);
export const getSelectedTopic = createSelector(getTopicsEntities, getSelectedTopicId, (entities, selectedTopicId) => {
  return selectedTopicId && entities[selectedTopicId];
});

export const getTopicsLoading = createSelector(selectTopicsUiState, fromTopicsUi.getTopicsLoading);
export const getTopicsLoaded = createSelector(selectTopicsUiState, fromTopicsUi.getTopicsLoaded);
export const getTopicsError = createSelector(selectTopicsUiState, fromTopicsUi.getTopicsError);

export const getPagination = createSelector(
  selectTopicsDbState,
  getUserPreferencesState,
  fromUserPreferences.getCurrentUser,
  (state: fromTopicsDb.State, userPreferencesState: fromUserPreferences.UserPreferencesState, currentUser: User) => {
    return { ...state.pagination, limit: 100 };
  }
);

export const getAddedTopicForConfig = createSelector(selectTopicsDbState, fromTopicsDb.getAddedTopicForConfig);
export const getConfigurePopupStatus = createSelector(selectTopicsUiState, fromTopicsUi.getOpenConfigurePopup);

export const getFormattedData = createSelector(getAllTopics, getPagination, (data: Topic[], pagination: Pagination) => {
  const response: PlatformResponse = {
    data: data,
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total
  };
  return response;
});

export const getTotal = createSelector(selectTopicsDbState, getPagination, (state, pagination) => {
  return pagination.total;
});

export const getConfigurePopupData = createSelector(getAddedTopicForConfig, getConfigurePopupStatus, (topic, status) => {
  return { canOpen: status, topic };
});

// ******************* //
// Subscribed Protocol //
// ******************* //

export const selectTopicsSubscribedProtocolsDbState = createSelector(selectTopicsState, (state: TopicsState) => {
  return state ? state[fromTopicsSubscribedProtocolsDb.topicsSubscribedProtocolsDbFeatureKey] : null;
});

export const selectTopicsSubscribedProtocolsUiState = createSelector(selectTopicsState, (state: TopicsState) => {
  return state ? state[fromTopicsSubscribedProtocolsUi.topicsSubscribedProtocolsUiFeatureKey] : null;
});

export const {
  selectIds: getTopicsSubscribedProtocolsIds,
  selectEntities: getTopicsSubscribedProtocolsEntities,
  selectAll: getAllTopicsSubscribedProtocols,
  selectTotal: getTotalTopicsSubscribedProtocols
} = fromTopicsSubscribedProtocolsDb.adapter.getSelectors(selectTopicsSubscribedProtocolsDbState);

export const getSubscribedProtocols = createSelector(getAllTopicsSubscribedProtocols, (subscribers) => {
  return subscribers;
});
export const getSubscribedProtocolsPending = createSelector(selectTopicsSubscribedProtocolsUiState, fromTopicsSubscribedProtocolsUi.getPending);

// ******************* //
//   Subscribed Users  //
// ******************* //

export const selectTopicSubscribedUsersDbState = createSelector(selectTopicsState, (state: TopicsState) => {
  return state ? state[fromTopicsSubscribedUsersDb.topicsSubscribedUsersDbFeatureKey] : null;
});

export const selectTopicsSubscribedUsersUiState = createSelector(selectTopicsState, (state: TopicsState) => {
  return state ? state[fromTopicsSubscribedUsersUi.topicsSubscribedUsersUiFeatureKey] : null;
});

export const {
  selectIds: getTopicsSubscribedUserIds,
  selectEntities: getTopicsSubscribedUserEntities,
  selectAll: getAllTopicsSubscribedUsers,
  selectTotal: getTotalTopicsSubscribedUsers
} = fromTopicsSubscribedUsersDb.adapter.getSelectors(selectTopicSubscribedUsersDbState);

export const getSubscribedUsers = createSelector(getAllTopicsSubscribedUsers, (subscribers) => {
  return subscribers;
});
export const getSubscribedUsersPending = createSelector(selectTopicsSubscribedUsersUiState, fromTopicsSubscribedUsersUi.getPending);

// ASSET EVENTS BY TOPIC //

export const selectAssetEventsByTopicLogsDbState = createSelector(
  selectTopicsState,
  (state: TopicsState) => state[fromAssetEventsByTopicLogsDb.assetEventsByTopicLogsDbFeatureKey]
);
export const selectAssetEventsByTopicLogsUiState = createSelector(
  selectTopicsState,
  (state: TopicsState) => state[fromAssetEventsByTopicLogsUi.assetEventsByTopicLogsUiFeatureKey]
);
export const {
  selectIds: getAssetEventsByTopicLogsIds,
  selectEntities: getAssetEventsByTopicLogsEntities,
  selectAll: getAllAssetEventsByTopicLogs,
  selectTotal: getAssetEventsByTopicTotalLogs
} = fromAssetEventsByTopicLogsDb.adapter.getSelectors(selectAssetEventsByTopicLogsDbState);

export const getAssetEventsByTopicLog = createSelector(selectAssetEventsByTopicLogsDbState, fromAssetEventsByTopicLogsDb.getLog);
export const getAssetEventsByTopicLogsLoaded = createSelector(selectAssetEventsByTopicLogsUiState, fromAssetEventsByTopicLogsUi.getLogsLoaded);

// DEVICE EVENTS BY TOPIC //

export const selectDeviceEventsByTopicLogsDbState = createSelector(
  selectTopicsState,
  (state: TopicsState) => state[fromDeviceEventsByTopicLogsDb.deviceEventsByTopicLogsDbFeatureKey]
);
export const selectDeviceEventsByTopicLogsUiState = createSelector(
  selectTopicsState,
  (state: TopicsState) => state[fromDeviceEventsByTopicLogsUi.deviceEventsByTopicLogsUiFeatureKey]
);
export const {
  selectIds: getDeviceEventsByTopicLogsIds,
  selectEntities: getDeviceEventsByTopicLogsEntities,
  selectAll: getAllDeviceEventsByTopicLogs,
  selectTotal: getDeviceEventsByTopicTotalLogs
} = fromDeviceEventsByTopicLogsDb.adapter.getSelectors(selectDeviceEventsByTopicLogsDbState);

export const getDeviceEventsByTopicLog = createSelector(selectDeviceEventsByTopicLogsDbState, fromDeviceEventsByTopicLogsDb.getLog);
export const getDeviceEventsByTopicLogsLoaded = createSelector(selectDeviceEventsByTopicLogsUiState, fromDeviceEventsByTopicLogsUi.getLogsLoaded);

/////////////////////////////////////////////////
//  **** MASTER VIEW ASSET EVENT BY TOPIC ****  //
/////////////////////////////////////////////////

export const selectAssetEventsByTopicDbState = createSelector(
  selectTopicsState,
  (state: TopicsState) => state[fromAssetEventsByTopicDb.assetEventsByTopicDbFeatureKey]
);
export const selectAssetEventsByTopicUiState = createSelector(
  selectTopicsState,
  (state: TopicsState) => state[fromAssetEventsByTopicUi.assetEventsByTopicUiFeatureKey]
);

export const {
  selectIds: getAssetEventsByTopicIds,
  selectEntities: getAssetEventsByTopicEntities,
  selectAll: getAllAssetEventsByTopic,
  selectTotal: getTotalAssetEventsByTopic
} = fromAssetEventsByTopicDb.adapter.getSelectors(selectAssetEventsByTopicDbState);

export const getSelectedAssetEventIdByTopic = createSelector(selectAssetEventsByTopicDbState, fromAssetEventsByTopicDb.getSelectedId);
export const getSelectedAssetEventByTopic = createSelector(
  getAssetEventsByTopicEntities,
  getSelectedAssetEventIdByTopic,
  (entities, selectedId) => selectedId && entities[selectedId]
);
export const getAssetEventsByTopicCheckedIds = createSelector(selectAssetEventsByTopicDbState, fromAssetEventsByTopicDb.getCheckedIds);

export const getAssetEventsByTopicPreviousTopicId = createSelector(selectAssetEventsByTopicDbState, fromAssetEventsByTopicDb.getPreviousTopicId);

export const getAssetEventsByTopicTableState = createSelector(
  selectAssetEventsByTopicDbState,
  getAllAssetEventsByTopic,
  getSelectedAssetEventByTopic,
  getAssetEventsByTopicCheckedIds,
  (state, allEvents, selected, checkedIds) => {
    return { selected, checked: allEvents.filter((event) => checkedIds.find((c) => c === event.id)) };
  }
);

export const getMvAssetEventsByTopicSettings = createSelector(selectAssetEventsByTopicDbState, fromAssetEventsByTopicDb.getMvSettings);

export const getAssetEventsByTopicPagination = createSelector(
  selectAssetEventsByTopicDbState,
  getMvAssetEventsByTopicSettings,
  (state: fromAssetEventsByTopicDb.State, settings) => {
    return settings && settings['masterViewTable']['bluePrint'].pageSize
      ? { ...state.pagination, limit: settings['masterViewTable']['bluePrint'].pageSize.toString() }
      : state.pagination;
  }
);

export const getAssetEventsByTopicAutoRefresh = createSelector(getMvAssetEventsByTopicSettings, (settings) => {
  return settings ? settings['masterViewTable']['bluePrint'].autoRefresh : false;
});

export const getAssetEventsByTopicRefreshDelay = createSelector(getMvAssetEventsByTopicSettings, (settings) => {
  return settings ? settings['masterViewTable']['bluePrint'].refreshDelay : 120;
});

export const getFormattedAssetEventsByTopic = createSelector(getAllAssetEventsByTopic, getAssetEventsByTopicPagination, (data: any, pagination: any) => {
  const response: PlatformResponse = {
    data: data,
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total
  };
  return response;
});

export const getAssetEventByTopicStatus = createSelector(selectAssetEventsByTopicDbState, fromAssetEventsByTopicDb.getStatus);

export const getAssetEventsByTopicLoaded = createSelector(selectAssetEventsByTopicUiState, fromAssetEventsByTopicUi.getAssetEventsLoaded);
export const getAssetEventsByTopicLoading = createSelector(selectAssetEventsByTopicUiState, fromAssetEventsByTopicUi.getAssetEventsLoading);

export const getTotalActiveAssetEventsByTopic = createSelector(selectAssetEventsByTopicDbState, fromAssetEventsByTopicDb.getTotalActiveEvents);
export const getTotalActiveAssetEventsByTopicLoaded = createSelector(selectAssetEventsByTopicUiState, fromAssetEventsByTopicUi.getTotalActiveEventsLoaded);

/////////////////////////////////////////////////
//  **** MASTER VIEW DEVICE EVENT BY TOPIC ****  //
/////////////////////////////////////////////////

export const selectDeviceEventsByTopicDbState = createSelector(
  selectTopicsState,
  (state: TopicsState) => state[fromDeviceEventsByTopicDb.deviceEventsByTopicDbFeatureKey]
);
export const selectDeviceEventsByTopicUiState = createSelector(
  selectTopicsState,
  (state: TopicsState) => state[fromDeviceEventsByTopicUi.deviceEventsByTopicUiFeatureKey]
);

export const {
  selectIds: getDeviceEventsByTopicIds,
  selectEntities: getDeviceEventsByTopicEntities,
  selectAll: getAllDeviceEventsByTopic,
  selectTotal: getTotalDeviceEventsByTopic
} = fromDeviceEventsByTopicDb.adapter.getSelectors(selectDeviceEventsByTopicDbState);

export const getSelectedDeviceEventIdByTopic = createSelector(selectDeviceEventsByTopicDbState, fromDeviceEventsByTopicDb.getSelectedId);
export const getSelectedDeviceEventByTopic = createSelector(
  getDeviceEventsByTopicEntities,
  getSelectedDeviceEventIdByTopic,
  (entities, selectedId) => selectedId && entities[selectedId]
);
export const getDeviceEventsByTopicCheckedIds = createSelector(selectDeviceEventsByTopicDbState, fromDeviceEventsByTopicDb.getCheckedIds);

export const getDeviceEventsByTopicPreviousTopicId = createSelector(selectDeviceEventsByTopicDbState, fromDeviceEventsByTopicDb.getPreviousTopicId);

export const getDeviceEventsByTopicTableState = createSelector(
  selectDeviceEventsByTopicDbState,
  getAllDeviceEventsByTopic,
  getSelectedDeviceEventByTopic,
  getDeviceEventsByTopicCheckedIds,
  (state, allEvents, selected, checkedIds) => {
    return { selected, checked: allEvents.filter((event) => checkedIds.find((c) => c === event.id)) };
  }
);

export const getMvDeviceEventsByTopicSettings = createSelector(selectDeviceEventsByTopicDbState, fromDeviceEventsByTopicDb.getMvSettings);

export const getDeviceEventsByTopicPagination = createSelector(
  selectDeviceEventsByTopicDbState,
  getMvDeviceEventsByTopicSettings,
  (state: fromDeviceEventsByTopicDb.State, settings) => {
    return settings && settings['masterViewTable']['bluePrint'].pageSize
      ? { ...state.pagination, limit: settings['masterViewTable']['bluePrint'].pageSize.toString() }
      : state.pagination;
  }
);

export const getDeviceEventsByTopicAutoRefresh = createSelector(getMvDeviceEventsByTopicSettings, (settings) => {
  return settings ? settings['masterViewTable']['bluePrint'].autoRefresh : false;
});

export const getDeviceEventsByTopicRefreshDelay = createSelector(getMvDeviceEventsByTopicSettings, (settings) => {
  return settings ? settings['masterViewTable']['bluePrint'].refreshDelay : 120;
});

export const getFormattedDeviceEventsByTopic = createSelector(getAllDeviceEventsByTopic, getDeviceEventsByTopicPagination, (data: any, pagination: any) => {
  const response: PlatformResponse = {
    data: data,
    currentPage: pagination.currentPage,
    hasMore: pagination.hasMore,
    limit: pagination.limit,
    maxPage: pagination.maxPage,
    total: pagination.total
  };
  return response;
});

export const getDeviceEventByTopicStatus = createSelector(selectDeviceEventsByTopicDbState, fromDeviceEventsByTopicDb.getStatus);

export const getDeviceEventsByTopicLoaded = createSelector(selectDeviceEventsByTopicUiState, fromDeviceEventsByTopicUi.getDeviceEventsLoaded);
export const getDeviceEventsByTopicLoading = createSelector(selectDeviceEventsByTopicUiState, fromDeviceEventsByTopicUi.getDeviceEventsLoading);

export const getTotalActiveDeviceEventsByTopic = createSelector(selectDeviceEventsByTopicDbState, fromDeviceEventsByTopicDb.getTotalActiveEvents);
export const getTotalActiveDeviceEventsByTopicLoaded = createSelector(selectDeviceEventsByTopicUiState, fromDeviceEventsByTopicUi.getTotalActiveEventsLoaded);
