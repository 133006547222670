<div class="concept-name" fxLayoutAlign="start center" fxLayoutGap="10px">
  <!--<iot-platform-ui-icon [name]="concept" [color]="'#394c5a'" [class]="'tag-category-list-' + concept"></iot-platform-ui-icon>-->
  <mat-icon [svgIcon]="concept" class="tag-category-list-concept-icon"></mat-icon>
  <span>{{ 'TAG_CATEGORY_LIST_COMPONENT.CONCEPTS.' + concept | translate | uppercase }}</span>
  <hr fxFlex class="regular-1px-line" />
  <button
    *ngIf="tagCategoriesByConcept.length && canManageTags"
    [matTooltip]="'TAG_CATEGORY_LIST_COMPONENT.TOOLTIP_EDIT' | translate"
    matTooltipClass="regular-tooltip"
    matTooltipPosition="below"
    mat-icon-button
    class="regular-round-button"
    (click)="onManageTags(concept)"
  >
    <mat-icon>edit</mat-icon>
  </button>
  <button
    *ngIf="!tagCategoriesByConcept.length && canManageTags"
    [matTooltip]="(canCreate ? 'TAG_CATEGORY_LIST_COMPONENT.TOOLTIP_CREATE' : 'TAG_CATEGORY_LIST_COMPONENT.TOOLTIP_ADD') | translate"
    matTooltipClass="regular-tooltip"
    matTooltipPosition="below"
    mat-icon-button
    class="regular-round-button"
    (click)="onManageTags(concept)"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>

<div *ngFor="let tagCategory of tagCategoriesByConcept" fxLayout="row" fxFlayoutAlign="start center" class="tag-category">
  <span fxFlex="15%" *ngIf="labelOnly" class="tag-category-name">{{ tagCategory.name }}</span>
  <iot-platform-ui-tag-label-list [tagCategory]="tagCategory" [labelOnly]="labelOnly"></iot-platform-ui-tag-label-list>
</div>
