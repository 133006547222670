import { User } from '@iot-platform/models/common';
import { createAction, props } from '@ngrx/store';

export const listAdministratorsByOrganizationSuccess = createAction(
  'Organizations/Administrators Api] List Administrators By Organization Success',
  props<{ administrators: User[] }>()
);

export const listAdministratorsByOrganizationFailure = createAction(
  '[Organizations API] List Administrators By Organization  Failure',
  props<{ error: any }>()
);

export const addAdministratorToOrganizationSuccess = createAction(
  '[Organizations/Administrators Api] Add Administrator to Organization Success',
  props<{ addedAdministrator: User }>()
);

export const addAdministratorToOrganizationFailure = createAction(
  '[Organizations/Administrators Api] Add Administrator to Organization Failure',
  props<{ error: any }>()
);
export const removeAdministratorFromOrganizationSuccess = createAction(
  '[Organizations/Administrators Api] Remove administrator From Organization Success',
  props<{ removedAdministratorId: string }>()
);

export const removeAdministratorFromOrganizationFailure = createAction(
  '[Organizations/Administrators Api] Remove administrator From Organization Failure',
  props<{ error: any }>()
);

export const getAdministratorsByOrganizationSuccess = createAction(
  '[Organizations API] Get Administrators By Organization Success',
  props<{ administrators: User[] }>()
);
