import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IotToolbarDefaultButton, IotToolbarDispatchActionType } from '@iot-platform/iot-platform-ui';
import { IotToolbarEvent, MasterViewEngineEvent } from '@iot-platform/models/common';
import { Site } from '@iot-platform/models/oyan';
import { BaseShellComponent } from '../../../../components/base-shell/base-shell.component';
import { AuthFacade } from '../../../auth/state/facades/auth.facade';
import { NavigationsFacade } from '../../../navigations/state/facades/navigations.facade';
import { SitesFacade } from '../../state/facades/sites.facade';

/**
 * Business rules
 * Gateway number will be displayed in red if at least one of the gateways is inactive
 * Receiver number will be displayed in red if at least one of the receivers is inactive
 * Cylinder number will be displayed in red if the cylinder is detected without an equivalent in Servitrax
 *
 * Navigations
 * The user could access the Site overview by clicking on the site name
 * The user could access the Gateways screen by clicking on the number of gateways,
 * the Gateways screen will open with the filter of “Shipto” and gateway
 * The user could access the Forwarders screen by clicking on the number of forwarders,
 * the Forwarders screen will open with the filter of “Shipto” and forwarder
 * The user could access the Cylinders screen by clicking on the number of cylinders,
 * the Cylinders screen will open with the filter of “Shipto”
 */
@Component({
  selector: 'oyan-ui-sites-shell',
  templateUrl: './sites-shell.component.html',
  styleUrls: ['./sites-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SitesShellComponent extends BaseShellComponent<Site> {
  constructor(protected authFacade: AuthFacade, protected sitesFacade: SitesFacade, private navigationsFacade: NavigationsFacade) {
    super(authFacade, sitesFacade);
    this.toolbarButtonList.find(
      (button: IotToolbarDefaultButton) => button.dispatchAction.type === IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE
    ).displayButton = false;
  }

  onToolbarEvent(event: IotToolbarEvent): void {
    super.onToolbarEvent(event);
    if (event.type === IotToolbarDispatchActionType.FILTER_TEXT_CHANGE) {
      this.onFilterTextChange(event.options);
    }
  }

  onMasterViewEngineEvent(event: MasterViewEngineEvent): void {
    switch (event.type) {
      case 'onSiteClickEvent':
        this.navigationsFacade.goToSiteOverViewFromSites(event.rawData);
        break;
      case 'onGatewayClickEvent':
        this.navigationsFacade.goToGatewaysPage(event.rawData as Site, this.businessProfile);
        break;
      case 'onReceiverClickEvent':
        this.navigationsFacade.goToReceiversPage(event.rawData as Site, this.businessProfile);
        break;
      case 'onAssetClickEvent':
        this.navigationsFacade.goToAssetsFromSites(event.rawData as Site);
        break;
      case 'onCustomerAppAccessClickEvent':
        this.navigationsFacade.openCustomerApp();
        break;
      default:
        break;
    }
  }

  private onFilterTextChange(searchString: string): void {
    this.subscriptions.push(
      this.sitesFacade.all$.subscribe((sites: Site[]) => {
        let filteredSites: Site[] = sites ? [...sites] : [];
        if (searchString !== null) {
          filteredSites = filteredSites.filter(
            (site: Site) =>
              `${site.name}`.toLowerCase().includes(searchString.toLowerCase()) ||
              (site.entity && `${site.entity.name}`.toLowerCase().includes(searchString.toLowerCase()))
          );
        }
        this.platformResponse$.next({
          ...this.platformResponse$.value,
          data: filteredSites
        });
        this.totalItems$.next(filteredSites.length);
      })
    );
  }
}
