<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'ADMIN.DIALOG.ADD_ROLE.TITLE_CREATE' | translate }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content fxLayout="column" class="dialog-card-content p-20">
    <form [formGroup]="bpForm">
      <mat-form-field fxFlex color="accent">
        <input matInput formControlName="nameControl" required maxlength="50" [placeholder]="'ADMIN.DIALOG.ADD_ROLE.PLACEHOLDER' | translate" />
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="close()">{{ 'ADMIN.DIALOG.ADD_ROLE.CANCEL' | translate }}</button>
    <button mat-raised-button color="accent" class="button-regular" [disabled]="!bpForm.valid" (click)="save()">
      {{ 'ADMIN.DIALOG.ADD_ROLE.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
