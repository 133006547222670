<div class="favorite-view-card" fxLayout="row" fxLayoutAlign="start stretch" matRipple (click)="onFavoriteViewClicked()">
  <div class="favorite-view-text-info" fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="150px">
    <div [innerHTML]="favoriteView.name" fxLayout="row" fxLayoutAlign="start center" class="favorite-view-name"></div>
    <div fxLayout="row">
      <mat-icon class='favorite-view-mv-icon' *ngIf="favoriteView.gridId">link</mat-icon>
      <div style="flex: 1" fxLayout="row" fxLayoutAlign="end center" class="favorite-view-mv">
        <span>{{ favoriteView.masterView | translate }}</span>
        <mat-icon [svgIcon]="favoriteView.masterView?.slice(0, -1)" class="favorite-view-mv-icon"></mat-icon>
      </div>
    </div>
  </div>
  <div class="favorite-view-count" fxLayout="row" fxLayoutAlign="center center" fxFlex="80px" [ngStyle]="{ 'background-color': favoriteView.color }">
    <mat-progress-spinner *ngIf="favoriteView.loading" [diameter]="26" mode="indeterminate"></mat-progress-spinner>
    <span *ngIf="!favoriteView.loading">{{ favoriteView.count | numberFormat }}</span>
  </div>
</div>
