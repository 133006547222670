<button [matMenuTriggerFor]="menu" mat-icon-button><mat-icon>{{ icon }}</mat-icon></button>

<mat-menu #menu="matMenu" class="height-300 mat-menu-list">
  <iot-platform-ui-async-autocomplete
    fxFlex
    [displayKey]='displayKey'
    [placeholder]="placeholder"
    [autocomplete]="false"
    [debounceTime]="150"
    [minLength]="0"
    [data]="data"
    (search)="search?.emit($event)"
    (reset)="clear?.emit()"
    (click)='$event.preventDefault(); $event.stopPropagation()'
  ></iot-platform-ui-async-autocomplete>

  <ng-container *ngFor="let item of data; index as i">
    <button mat-menu-item (click)="selectItem?.emit(item)"  [matTooltip]="item[displayKey]">{{ item[displayKey] | uppercase }}</button>
    <mat-divider *ngIf="i < item?.length - 1"></mat-divider>
  </ng-container>

  <div *ngIf="!data?.length" class="no-data-message" fxFlexLayout='row' fxFlexAlign='center center'>
    <p>{{ noDataMessage | translate }}</p>
  </div>
</mat-menu>
