<div fxFlex fxLayout="column">
  <div class="sticky">
    <iot-platform-ui-toolbar-v2
      [name]="'DEVICES.CONNECTORS.MASTER_VIEW.TITLE' | translate"
      [total]="totalConnectors$ | async"
      [buttonList]="masterViewConnectorsButtonList"
      [size]="toolbarSize"
      (dispatchToolbarEvent)="onToolbarEvent($event)"
    >
    </iot-platform-ui-toolbar-v2>
  </div>
  <div class="layout-master-view-engine-container">
    <grid-engine-grid-manager
      fxFlex
      class="layout-ag-grid-shell"
      [grid]="grid$ | async"
      [gridSort]="gridSort$ | async"
      [userPermissions]="userPermissions"
      (dispatchMasterViewEngineEvent)="onMasterViewEngineEvent($event)"
    ></grid-engine-grid-manager>
  </div>
</div>
