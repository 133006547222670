import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Pagination, PlatformResponse } from '@iot-platform/models/common';
import { MasterView } from '@iot-platform/models/i4b';
import { NotificationService } from '@iot-platform/notification';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { MasterViewEngineService } from './master-view-engine.service';

@Component({
  selector: 'i4b-table-engine-master-view-engine',
  templateUrl: './master-view-engine.component.html',
  styleUrls: ['./master-view-engine.component.scss']
})
export class MasterViewEngineComponent implements OnInit, OnChanges {
  @Input() masterViewType: {
    name: string;
    isPath: boolean;
    useExternalMetadata: boolean;
  };
  @Input() sticky = false;
  @Input() metadata: any;
  @Input() contextElement: any;
  @Input() userPermissions: Array<{ key: string; value: boolean }>;
  @Input() extraParam: any;

  @Input() data: PlatformResponse;
  @Input() useExternalData = true;
  @Input() selectedElement: any;
  @Input() checkedElements: any[];
  @Input() useFullyLoadedDataset = false;
  @Input() pageSizeOptions: number[] = [];
  @Input() withLoadMoreButton = false;
  @Input() loading = false;
  @Input() observerSelectors: string[] = [];

  @Output()
  dispatchMasterViewEngineEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  pageChange: EventEmitter<Pagination> = new EventEmitter<Pagination>();
  @Output() sortChange: EventEmitter<Sort> = new EventEmitter<Sort>();

  masterViewTable: any;
  masterViewMetadata: any;
  bluePrint$: BehaviorSubject<any> = new BehaviorSubject<any>(null); // masterViewTable?.bluePrint

  tableData$: BehaviorSubject<any> = new BehaviorSubject<any>({ data: [] });
  masterView$: Observable<MasterView>;
  pendingRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private masterViewEngineService: MasterViewEngineService, private notificationService: NotificationService) {}

  ngOnInit() {
    let pathToMasterView: { name; isPath: boolean };
    if (!this.masterViewType['name']) {
      pathToMasterView = { name: this.masterViewType, isPath: false };
    } else {
      pathToMasterView = {
        name: this.masterViewType.name,
        isPath: this.masterViewType.isPath
      };
    }

    if (!this.masterViewType.useExternalMetadata) {
      this.masterViewEngineService
        .getMasterView(pathToMasterView.name, pathToMasterView.isPath)
        .pipe(
          tap((masterView: MasterView) => {
            this.masterViewTable = masterView.masterViewTable;
            this.masterViewMetadata = masterView.metadata;
            this.bluePrint$.next(masterView.masterViewTable.bluePrint);
            this.masterView$ = of(masterView);
          }),
          switchMap((mv: MasterView) => {
            if (!this.useExternalData) {
              this.notificationService.showLoader();
              return this.masterViewEngineService.getData(
                this.masterViewType.name,
                this.contextElement.name,
                this.masterViewMetadata,
                this.contextElement,
                this.extraParam
              );
            }
            return of(null);
          })
        )
        .subscribe((data) => {
          if (data) {
            this.notificationService.hideLoader();
            this.tableData$.next(data);
            console.log(data);
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('data') && changes.data.currentValue) {
      this.tableData$.next(changes.data.currentValue);
    }

    if (changes.hasOwnProperty('metadata') && changes.metadata.currentValue) {
      this.bluePrint$.next(changes['metadata'].currentValue.masterViewTable.bluePrint);
    }
  }

  onDispatchCellEvent(event: any) {
    this.dispatchMasterViewEngineEvent.emit(event);
  }

  onPageChanged(pagination: Pagination) {
    if (!this.useExternalData) {
      this.notificationService.showLoader();
      this.pendingRequest$.next(true);
      const metadata = this.masterViewMetadata;
      metadata['params'].page = pagination.currentPage;
      this.masterViewEngineService
        .getData(
          this.masterViewType.name,
          this.contextElement && this.contextElement.name ? this.contextElement.name : this.contextElement,
          metadata,
          this.contextElement,
          this.extraParam
        )
        .subscribe((data) => {
          this.pendingRequest$.next(false);
          this.tableData$.next(data);
          this.notificationService.hideLoader();
        });
    } else {
      this.pageChange.emit(pagination);
    }
  }

  onSortChanged(sort: Sort) {
    this.sortChange.emit(sort);
  }
}
