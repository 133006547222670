import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Asset, Device, Site } from '@iot-platform/models/i4b';

@Component({
  selector: 'iot4bos-ui-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss', './breadcrumb-item.scss']
})
export class BreadcrumbComponent {
  @Input() site?: Site;
  @Input() selectedAsset?: Asset;
  @Input() selectedDevice?: Device;
  @Input() displayRefreshBtn?: boolean;
  @Input() activeItemId = '';
  @Input() mvorigin = '';

  @Output() load: EventEmitter<{ origin: string; activeId: string; requestedSiteId: string }> = new EventEmitter();
  @Output() returnToOrigin: EventEmitter<void> = new EventEmitter();
  @Output() refreshSite: EventEmitter<Site> = new EventEmitter();
  @Output() refreshAsset: EventEmitter<Asset> = new EventEmitter();
  @Output() refreshDevice: EventEmitter<Device> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Output() toggleAssociationsPanel: EventEmitter<boolean> = new EventEmitter();

  navigate(path: string, ids: { activeId: string; requestedSiteId: string }) {
    this.load.emit({ origin: path, activeId: ids.requestedSiteId, requestedSiteId: ids.requestedSiteId });
  }

  onReturnToOriginClick() {
    this.returnToOrigin.emit();
  }

  onRefreshAssetClick() {
    this.refreshAsset.emit(this.selectedAsset);
  }

  onRefreshDeviceClick() {
    this.refreshDevice.emit(this.selectedDevice);
  }

  onOpenAssociations() {
    this.toggleAssociationsPanel.emit(true);
  }

  onRefreshSiteClick() {
    this.refreshSite.emit(this.site);
  }
}
