<div
  class="container"
  [class.inSubMenu]="item?.level > 0"
  *ngIf="item.display"
  (click)="handleClick($event)"
  [style.border-left]="'6px solid ' + item.leftBorderColor"
>
  <a
    (mouseenter)="mouseEnter($event)"
    (mouseleave)="mouseLeave($event)"
    *ngIf="!item.hasChildren"
    mat-list-item
    (click)="navigate()"
    [ngClass]="{ 'active-item': (url$ | async) === item.path && item.disableActiveState !== true }"
    class="item-container"
  >
    <mat-icon *ngIf="item.icon" [svgIcon]="item.icon" class="item-icon"></mat-icon>
    <p class="item-content" title="{{ item.name | translate }}" [innerHTML]="item.name | translate"></p>
    <div class="right-icon" *ngIf="item.rightIcons">
      <mat-icon *ngFor="let icon of item.rightIcons" class="item-icon">{{ icon }}</mat-icon>
    </div>
  </a>

  <div class="submenu-container" *ngIf="item.hasChildren">
    <div [matMenuTriggerFor]="subMenu" #trigger="matMenuTrigger" (mouseenter)="mouseEnter($event, trigger)" (mouseleave)="mouseLeave($event)">
      <a
        *ngIf="item.path"
        mat-list-item
        [routerLink]="[item.path]"
        [ngClass]="{ 'active-item': (url$ | async) === item.path && item.disableActiveState !== true }"
        class="item-container"
      >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
      </a>
      <div *ngIf="!item.path" mat-list-item class="item-container">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
      </div>

      <ng-template #itemContent>
        <mat-icon *ngIf="item.icon" [svgIcon]="item.icon" class="item-icon"></mat-icon>
        <p class="item-content" [innerHTML]="item.name | translate"></p>
        <mat-icon class="chevron-right">chevron_right</mat-icon>
      </ng-template>
    </div>

    <mat-menu class="sidenav-menu-panel" backdropClass="sidenav-backdrop-class" xPosition="after" #subMenu="matMenu">
      <div *ngFor="let navBlock of item.submenu; trackBy: trackById">
        <iot4bos-ui-sidenav-block [navBlock]="navBlock"></iot4bos-ui-sidenav-block>
      </div>
    </mat-menu>
  </div>
</div>
