import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@iot-platform/auth';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { BusinessProfile, Environment } from '@iot-platform/models/common';
import { CachedUsersService } from '@iot-platform/shared';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NavigationTreeService } from 'libs/iot4bos-ui/src/lib/components/sidenav/services/navigation-tree.service';
import {
  AppShellComponent as I4bAppShellComponent
} from 'libs/iot4bos-ui/src/lib/containers/app-shell/app-shell.component';
import { UserPreferencesService } from 'libs/users/src/lib/features/preferences/services/user-preferences.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthorizationService } from '../../features/auth/services/authorization.service';
import { AuthFacade } from '../../features/auth/state/facades/auth.facade';
import { NavigationsFacade } from '../../features/navigations/state/facades/navigations.facade';
import { OyanUiRoutingConstants } from '../../oyan-ui.router.constants';
import { AppConfig } from '../../services/config/app.config';
import { APP_CONFIG } from '../../services/config/config.module';

@Component({
  selector: 'oyan-ui-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['../../../../../iot4bos-ui/src/lib/containers/app-shell/app-shell.component.scss', './app-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppShellComponent extends I4bAppShellComponent implements OnInit {
  eligibleBusinessProfiles$: Observable<BusinessProfile[]> = this.authFacade.eligibleBusinessProfiles$;
  sidenavState = {
    isOpen: this.storage.get(LocalStorageKeys.STORAGE_SIDEBAR_OPENED_KEY) ? this.storage.get(LocalStorageKeys.STORAGE_SIDEBAR_OPENED_KEY) === 'true' : false
  };
  appConfig: AppConfig;

  constructor(
    @Inject('environment') protected environment: Environment,
    @Inject(APP_CONFIG) private readonly config: AppConfig,
    protected authService: AuthService,
    protected translateService: TranslateService,
    protected router: Router,
    protected navigationTreeService: NavigationTreeService,
    protected store: Store,
    protected authorizationService: AuthorizationService,
    protected userPreferencesService: UserPreferencesService,
    protected dialog: MatDialog,
    protected storage: LocalStorageService,
    private readonly authFacade: AuthFacade,
    private readonly navigationsFacade: NavigationsFacade,
    protected cachedUsersService: CachedUsersService
  ) {
    super(
      environment,
      authService,
      translateService,
      router,
      navigationTreeService,
      store,
      authorizationService,
      userPreferencesService,
      dialog,
      storage,
      cachedUsersService
    );
    this.appConfig = {
      ...this.config,
      front: { ...this.config.front, version: `v-${this.config.front.version}` },
      back: { ...this.config.back, version: `v-${this.config.back.version}` }
    };
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscriptions.push(
      this.authFacade.isBusinessProfileChanged$.pipe(filter((changed: boolean) => changed)).subscribe((changed: boolean) => {
        this.navigationsFacade.onBusinessProfileChanged(changed);
      })
    );
  }

  returnHome(): void {
    this.router.navigate(['/']);
  }

  onChangeBusinessProfile(selected: BusinessProfile): void {
    this.authFacade.selectBusinessProfile(selected, false);
  }

  toggleSidenav(): void {
    super.toggleSidenav();
    this.dispatchResizeEvent();
  }

  openMyProfile(): void {
    this.router.navigate([`${OyanUiRoutingConstants.ADMIN}/${OyanUiRoutingConstants.PROFILE}`]);
  }

  private dispatchResizeEvent(): void {
    // Workaround for responsive bug on mat-tab-link open
    // Reference : https://github.com/angular/components/issues/12234
    // Clean solution : https://github.com/angular/components/issues/6130
    const _timeout = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      clearTimeout(_timeout);
    }, 50);
  }
}
