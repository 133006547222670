<div class="login-password-rules-container">
  <div class="login-password-rules-rule" [class.checked]="!errors?.format.size">
    <mat-icon class="login-password-rules-icon" [textContent]="errors?.format.size ? 'error' : 'check'"></mat-icon>
    <span>{{ 'LOGIN.HELP_MESSAGE.SIZE' | translate }}</span>
  </div>
  <div class="login-password-rules-rule" [class.checked]="!errors?.format.lowercase">
    <mat-icon class="login-password-rules-icon" [textContent]="errors?.format.lowercase ? 'error' : 'check'"></mat-icon>
    <span>{{ 'LOGIN.HELP_MESSAGE.LOWERCASE' | translate }}</span>
  </div>
  <div class="login-password-rules-rule" [class.checked]="!errors?.format.uppercase">
    <mat-icon class="login-password-rules-icon" [textContent]="errors?.format.uppercase ? 'error' : 'check'"></mat-icon>
    <span>{{ 'LOGIN.HELP_MESSAGE.UPPERCASE' | translate }}</span>
  </div>
  <div class="login-password-rules-rule" [class.checked]="!errors?.format.number">
    <mat-icon class="login-password-rules-icon" [textContent]="errors?.format.number ? 'error' : 'check'"></mat-icon>
    <span>{{ 'LOGIN.HELP_MESSAGE.NUMBER' | translate }}</span>
  </div>
  <div class="login-password-rules-rule" [class.checked]="!errors?.format.special">
    <mat-icon class="login-password-rules-icon" [textContent]="errors?.format.special ? 'error' : 'check'"></mat-icon>
    <span>{{ 'LOGIN.HELP_MESSAGE.SPECIAL' | translate }}</span>
  </div>
</div>
