import { Action, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../../auth/src/lib/state/actions';
import { DeviceEventsByTopicLogsDbActions, DeviceEventsByTopicLogsUiActions } from '../../actions';
//
export const deviceEventsByTopicLogsUiFeatureKey = 'deviceEventsByTopicLogsUi';

export interface State {
  error?: any;
  loaded?: boolean;
}

export const initialState: State = {
  error: null,
  loaded: false
};

const deviceEventsByDeviceLogsUiReducer = createReducer(
  initialState,
  on(DeviceEventsByTopicLogsUiActions.loadLogsByDeviceEventId, (state: State) => ({ ...state, error: null, loaded: false })),
  on(DeviceEventsByTopicLogsDbActions.loadLogsByDeviceEventIdSuccess, (state: State) => ({ ...state, loaded: true })),
  on(DeviceEventsByTopicLogsDbActions.loadLogsByDeviceEventIdFailure, (state: State, { error }) => ({ ...state, loaded: false, error })),
  // ****
  on(DeviceEventsByTopicLogsUiActions.createLogByDeviceEventId, (state: State) => ({ ...state, error: null })),
  on(DeviceEventsByTopicLogsDbActions.createLogByDeviceEventIdSuccess, (state: State) => ({ ...state, loaded: true })),
  on(DeviceEventsByTopicLogsDbActions.createLogByDeviceEventIdFailure, (state: State, { error }) => ({ ...state, loaded: false, error })),
  // ****
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return deviceEventsByDeviceLogsUiReducer(state, action);
}

export const getError = (state: State) => state.error;
export const getLogsLoaded = (state: State) => state.loaded;
