import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthInterceptor } from '@iot-platform/auth';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceConnectorDetailPopupComponent } from '../../components/device-connector-detail-popup/device-connector-detail-popup.component';
import { DeviceConnectorEditFormComponent } from '../../components/device-connector-edit-form/device-connector-edit-form.component';
import { DeviceConnectorsService } from '../../../../../../../shared/src/lib/services/device-connectors.service';
import { DeviceConnectorsShellComponent } from './device-connectors-shell.component';

@NgModule({
  declarations: [DeviceConnectorsShellComponent, DeviceConnectorDetailPopupComponent, DeviceConnectorEditFormComponent],
  imports: [CommonModule, SharedModule, TranslateModule, IotPlatformPipesModule, IotPlatformUiModule, GridEngineModule],
  providers: [
    DeviceConnectorsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  exports: [DeviceConnectorsShellComponent]
})
export class DeviceConnectorsShellModule {}
