<div class="mv-toolbar translation-toolbar translation-toolbar-height">
  <div class="mv-toolbar-row" fxLayout="row" fxLayoutAlign="start stretch">
    <div class="mv-toolbar-col" fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <span class="mv-toolbar-label"> {{ keyCount }} {{ 'ADMIN.TRANSLATION_MANAGEMENT.TOOLBAR.TITLE' | translate | uppercase }}</span>

      <span fxFlex></span>

      <button
        [ngClass]="{ 'translation-toolbar-readonly-button': !isEditMode, 'translation-toolbar-edit-button': isEditMode }"
        [matTooltip]="
          (isEditMode ? 'ADMIN.TRANSLATION_MANAGEMENT.TOOLBAR.BUTTON_READ_ONLY_MODE' : 'ADMIN.TRANSLATION_MANAGEMENT.TOOLBAR.BUTTON_EDIT_MODE') | translate
        "
        matTooltipClass="regular-tooltip"
        matTooltipPosition="below"
        mat-icon-button
        class="regular-round-button"
        (click)="toggleEditMode()"
      >
        <mat-icon color="accent">{{ isEditMode ? 'edit_off' : 'mode_edit' }}</mat-icon>
      </button>

      <button
        [matMenuTriggerFor]="menu"
        [matTooltip]="'ADMIN.TRANSLATION_MANAGEMENT.TOOLBAR.BUTTON_EXPORT' | translate"
        matTooltipClass="regular-tooltip"
        matTooltipPosition="below"
        mat-icon-button
        class="regular-round-button translation-toolbar-export-button"
      >
        <mat-icon color="accent">file_download</mat-icon>
      </button>
      <mat-menu #menu="matMenu" fxLayout="row">
        <ng-container *ngFor="let lang of languages">
          <button mat-menu-item (click)="onExport(lang)">{{ lang | uppercase }}</button>
          <mat-divider></mat-divider>
        </ng-container>
        <button mat-menu-item (click)="onExport('all')">{{ 'ALL' | translate }}</button>
      </mat-menu>

      <button
        *ngIf="isEditMode"
        [matTooltip]="'ADMIN.TRANSLATION_MANAGEMENT.TOOLBAR.BUTTON_ADD_LANGUAGE' | translate"
        matTooltipClass="regular-tooltip"
        matTooltipPosition="below"
        mat-icon-button
        class="regular-round-button translation-toolbar-add-language-button"
        (click)="onAddLanguage()"
      >
        <mat-icon color="accent">add</mat-icon>
      </button>

      <button
        *ngIf="isEditMode && showClearSelectedButton"
        [matTooltip]="'ADMIN.TRANSLATION_MANAGEMENT.TOOLBAR.BUTTON_CLEAR' | translate"
        matTooltipClass="regular-tooltip"
        matTooltipPosition="below"
        mat-icon-button
        class="regular-round-button translation-toolbar-clear-button"
        (click)="onClear()"
      >
        <mat-icon color="accent">clear</mat-icon>
      </button>

      <button
        *ngIf="isEditMode && !!languagesToRemove?.length"
        [matMenuTriggerFor]="menuDeleteLanguages"
        [matTooltip]="'ADMIN.TRANSLATION_MANAGEMENT.TOOLBAR.BUTTON_DELETE_LANGUAGE' | translate"
        matTooltipClass="regular-tooltip"
        matTooltipPosition="below"
        mat-icon-button
        class="regular-round-button translation-toolbar-delete-language-button"
      >
        <mat-icon color="accent">delete</mat-icon>
      </button>
      <mat-menu #menuDeleteLanguages="matMenu" fxLayout="row">
        <ng-container *ngFor="let lang of languagesToRemove; index as i">
          <button mat-menu-item (click)="onDeleteLanguage(lang)">{{ lang | uppercase }}</button>
          <mat-divider *ngIf="i < languagesToRemove?.length - 1"></mat-divider>
        </ng-container>
      </mat-menu>
    </div>
  </div>
</div>
