import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'grid-engine-linked-cell',
  templateUrl: './linked-cell.component.html',
  styleUrls: ['./linked-cell.component.scss']
})
export class LinkedCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  tooltip: string;

  constructor(private translateService: TranslateService) {}

  agInit(params: ICellRendererParams) {
    this.params = params;
    this.tooltip = this.translateService.instant(`ASSETS.VARIABLE.TOOLTIPS.${this.params?.value ? 'LINKED' : 'NOT_LINKED'}`);
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
