import { NgModule } from '@angular/core';
import { IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../../../../../iot4bos-ui/src/lib/directives/directives.module';
import { MasterViewEngineModule } from '../../../../../table-engine/src/lib/components/master-view-engine/master-view-engine.module';
import { ProductsCatalogComponent } from './containers/products-catalog/products-catalog.component';
import { ProductsRoutingModule } from './products-routing.module';
import { ProductsEffects } from './state/effects/products.effects';
import * as fromProducts from './state/reducers';

@NgModule({
  declarations: [ProductsCatalogComponent],
  imports: [
    SharedModule,
    TranslateModule,
    IotPlatformPipesModule,
    IotPlatformUiModule,
    DirectivesModule,
    MasterViewEngineModule,
    ProductsRoutingModule,
    StoreModule.forFeature(fromProducts.featureKey, fromProducts.reducers),
    EffectsModule.forFeature([ProductsEffects])
  ]
})
export class ProductsModule {
}
