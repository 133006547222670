import { AssetEvent, Log } from '@iot-platform/models/i4b';
import { createAction, props } from '@ngrx/store';

export const loadLogsByAssetEventIdSuccess = createAction('[AssetEventsBySite] Load Logs By Asset Event Id Success', props<{ logs: Log[] }>());
export const loadLogsByAssetEventIdFailure = createAction('[AssetEventsBySite] Load Logs By Asset Event Id Failure', props<{ error: any }>());
//
export const createLogByAssetEventIdSuccess = createAction(
  '[AssetEventsBySite] Create Log By Asset Event Id Success',
  props<{ log: Log; assetEvent: AssetEvent }>()
);
export const createLogByAssetEventIdFailure = createAction('[AssetEventsBySite] Create Log By Asset Event Id Failure', props<{ error: any }>());
