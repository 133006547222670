<div class="detail-device-card-row" fxLayout="row" fxLayoutGap="4px">
  <div class="detail-device-card-col">
    <span class="detail-device-card-label" [ngClass]="context" *ngIf="context === 'connector'">{{ 'DEVICES.DETAIL_DEVICE_CARD.INCOMING_CONNECTOR' | translate }}</span>
    <span class="detail-device-card-label" [ngClass]="context" *ngIf="context === 'connector'">{{ 'DEVICES.DETAIL_DEVICE_CARD.OUTGOING_CONNECTOR' | translate }}</span>
    <span class="detail-device-card-label" [ngClass]="context" *ngIf="context === 'communication'">{{ 'DEVICES.DETAIL_DEVICE_CARD.IDENTIFIER' | translate }}</span>
    <span class="detail-device-card-label" [ngClass]="context" *ngIf="context === 'communication'">{{ 'DEVICES.DETAIL_DEVICE_CARD.SUPPORT' | translate }}</span>
    <span class="detail-device-card-label" [ngClass]="context" *ngIf="context === 'communication'">{{ 'DEVICES.DETAIL_DEVICE_CARD.ENDPOINT' | translate }}</span>
  </div>
  <div class="detail-device-card-col">
    <span class="detail-device-card-value" *ngIf="context === 'connector'">{{ device?.incomingConnector?.name | infoDisplay }}</span>
    <span class="detail-device-card-value" *ngIf="context === 'connector'">{{ device?.outgoingConnector?.name | infoDisplay }}</span>
    <span class="detail-device-card-value" *ngIf="context === 'communication'">{{ device?.identifier | infoDisplay }}</span>
    <span class="detail-device-card-value" *ngIf="context === 'communication'">{{ device?.communication.support | infoDisplay }}</span>
    <span class="detail-device-card-value" *ngIf="context === 'communication'">{{ device?.communication.endpoint | infoDisplay }}</span>
  </div>
</div>
