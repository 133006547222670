import { BaseState, DynamicDataResponse, Filter, INITIAL_BASE_STATE } from '@iot-platform/models/common';
import { createEntityAdapter, Dictionary, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as Leaflet from 'leaflet';
import { get } from 'lodash';
import { IotGeoJsonFeature, IotGeoJsonRouteFeature } from '../../models/iot-geo-json-object.model';
import { MapActions } from '../actions';

export const featureKey = 'mapFeatureKey';

export interface State extends BaseState<IotGeoJsonFeature, unknown, Filter> {
  concept: string;
  featureCollection: { [concept: string]: IotGeoJsonFeature[] };
  currentRoutes: IotGeoJsonRouteFeature[];
  currentFeature: IotGeoJsonFeature | null;
  data: DynamicDataResponse;
  refresh: boolean;
  mapUiState: { center: Leaflet.LatLng; zoom: number; selectedMarker: Leaflet.Marker; hasLeavedMap: boolean; isReturningFromOutside: boolean } | null;
}

export const adapter: EntityAdapter<IotGeoJsonFeature> = createEntityAdapter<IotGeoJsonFeature>({
  selectId: (entity: IotGeoJsonFeature) => get(entity, 'properties.id', '')
});

export const initialState: State = adapter.getInitialState<State>({
  ...INITIAL_BASE_STATE,
  concept: '',
  refresh: false,
  ids: [],
  entities: [] as unknown as Dictionary<IotGeoJsonFeature>,
  featureCollection: {},
  currentRoutes: [],
  currentFeature: null,
  data: null,
  mapUiState: null
});

export const reducer = createReducer(
  initialState,

  on(
    MapActions.setFilters,
    (state: State, { filters }): State => ({
      ...state,
      filters: [...filters]
    })
  ),

  on(
    MapActions.setConcept,
    (state: State, { concept }): State => ({
      ...state,
      concept
    })
  ),

  on(
    MapActions.loadGeoLocations,
    (state: State, { concept, filters }): State => ({
      ...state,
      concept,
      filters: [...filters]
    })
  ),

  on(
    MapActions.loadMarkerDetails,
    (state: State, { feature }): State => ({
      ...state,
      currentFeature: feature
    })
  ),

  on(MapActions.clearData, (state: State): State => initialState),
  on(MapActions.refreshMap, (state: State): State => ({ ...state, refresh: true })),

  on(
    MapActions.loadGeoLocations,
    MapActions.loadMarkerDetails,
    (state: State): State => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),

  on(
    MapActions.loadGeoLocations,
    MapActions.loadMarkerDetails,
    (state: State): State => ({
      ...state,
      data: null
    })
  ),

  on(
    MapActions.loadMarkerDetailsSuccess,
    (state: State, { response }): State => ({
      ...state,
      data: response
    })
  ),

  on(MapActions.loadGeoLocationsSuccess, (state: State, { response }): State => {
    return adapter.setAll(response, {
      ...state,
      featureCollection: {
        ...state.featureCollection,
        [state.concept]: response
      }
    });
  }),

  on(MapActions.loadRoute, (state: State): State => {
    const newState = {
      ...state,
      loading: true,
      loaded: false,
      currentRoutes: []
    };
    return newState;
  }),

  on(MapActions.loadRouteSuccess, (state: State, { response }): State => {
    const newState = {
      ...state,
      loading: false,
      loaded: true,
      currentRoutes: response
    };
    return newState;
  }),

  on(
    MapActions.loadGeoLocationsSuccess,
    MapActions.loadMarkerDetailsSuccess,
    (state: State): State => ({
      ...state,
      loading: false,
      loaded: true,
      refresh: false
    })
  ),
  on(
    MapActions.loadGeoLocationsFailure,
    MapActions.loadMarkerDetailsFailure,
    (state: State, { error }): State => ({
      ...state,
      loading: false,
      loaded: false,
      error
    })
  ),
  on(MapActions.saveMapUiState, (state: State, { mapUiState }): State => ({ ...state, mapUiState }))
);

export const mapUiState = (state: State) => state.mapUiState;
