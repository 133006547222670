<div class="widget-item" fxLayout="column" (click)="onWidgetClick()">
  <div fxFlex iot4bosUiWidgetLoader [widget]="widget"></div>

  <div class="call-to-action" fxFlex *ngIf="menu?.enabled">
    <button
      class="menu"
      [class.active]="menuTrigger.menuOpen"
      (click)="$event?.stopPropagation()"
      #menuTrigger="matMenuTrigger"
      mat-icon-button
      [matMenuTriggerFor]="widgetMenu"
    >
      <mat-icon fxAlign="end">more_vert</mat-icon>
    </button>
    <mat-menu #widgetMenu>
      <button *ngFor="let item of menu?.actions" mat-menu-item (click)="onDispatchEvent(item?.name)" [disabled]="!item?.enabled">
        {{ item?.label | translate }}
      </button>
    </mat-menu>
  </div>
</div>
