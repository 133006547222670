import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { User } from '@iot-platform/models/common';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserCellService {
  users$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

  constructor(@Inject('environment') private readonly environment, private readonly http: HttpClient) {}

  public getUserById(userId): Observable<User> {
    return this.users$.pipe(
      switchMap((users: User[]) => {
        const found = users.find((u) => u.id === userId);
        if (found) {
          return of(found);
        } else {
          return this.http.get<User>(`${this.environment.api.url}/users/${userId}`).pipe(
            tap((u) => {
              users.push(u);
              this.users$.next(users);
            })
          );
        }
      })
    );
  }
}
