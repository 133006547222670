// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const commonEnvironment = {
  production: false,
  cognito: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_ohn2NJmLa',
    clientId: '4mve1fpj8nkdegjfsje58rj8rt'
  },
  api: {
    url: '/api/v1',
    url_v2: '/api/v2',
    key: '',
    endpoints: {
      sites: '/sites',
      gateways: '/gateways',
      synchronize: '/synchronize',
      receivers: '/forwarders',
      wards: '/wards',
      assets: '/assets',
      entities: '/entities',
      ack: '/ack',
      product: '/product',
      users: '/users',
      businessProfiles: '/business-profiles',
      favoriteViews: '/favorite-views',
      messages: '/messages',
      contacts: '/contacts',
      backendVersion: '/version',
      usersLightWeight: '/directory/users'
    },
    kerlinkUrl: 'https://wmc-poc.wanesy.com/#/login',
    customerAppAccessUrl: 'https://dev-oyansmarteservices.airliquide.com/login'
  },
  storage: {
    appPrefix: 'oyan'
  }
};
