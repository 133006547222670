import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SidenavMenuBlock } from '@iot-platform/models/i4b';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationTreeService {
  constructor(private httpClient: HttpClient) {}

  getNavigationTree(): Observable<SidenavMenuBlock[]> {
    return this.httpClient.get<SidenavMenuBlock[]>(`assets/data/navigation-tree.json`);
  }
}
