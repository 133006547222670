import { Injectable } from '@angular/core';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PreferencesActions } from '../../../preferences/state/actions';
import { UserProfileService } from '../../services/user-profile.service';
import { ProfileActions } from '../actions';

@Injectable()
export class ProfileEffects {
  currentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.loadUserProfile),
      switchMap(action =>
        this.userProfileService.loadProfile().pipe(
          map(user => ProfileActions.loadUserProfileSuccess({ user })),
          catchError(error => of(ProfileActions.loadUserProfileFailure({ error })))
        )
      )
    )
  );

  saveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.saveUserProfile),
      switchMap(action =>
        this.userProfileService.saveUserProfile(action.userToUpdate).pipe(
          map(user => ProfileActions.saveUserProfileSuccess({ updatedUser: user })),
          catchError(error => of(ProfileActions.saveUserProfileFailure({ error })))
        )
      )
    )
  );

  displayLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.saveUserProfile, ProfileActions.loadUserProfile),
        tap(() => this.notificationService.displayLoader(true))
      ),
    { dispatch: false }
  );

  hideLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProfileActions.saveUserProfileSuccess,
          ProfileActions.saveUserProfileFailure,
          ProfileActions.loadUserProfileSuccess,
          ProfileActions.loadUserProfileFailure
        ),
        tap(() => this.notificationService.hideLoader())
      ),
    { dispatch: false }
  );

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.saveUserProfileSuccess),
        tap(action => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.saveUserProfileFailure),
        tap(action => this.notificationService.displayError(action.type))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private userProfileService: UserProfileService, private notificationService: NotificationService) {}
}
