import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SortUtil } from 'libs/shared/src/lib/utils/sort.util';
import { BehaviorSubject } from 'rxjs';
import { TabNavBarItem } from './tab-nav-bar-item.model';

@Component({
  selector: 'oyan-ui-tab-nav-bar',
  templateUrl: './tab-nav-bar.component.html',
  styleUrls: ['./tab-nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabNavBarComponent implements OnChanges {
  @Input() centered: boolean;
  @Input() navLinks: TabNavBarItem[] = [];

  navLinks$: BehaviorSubject<TabNavBarItem[]> = new BehaviorSubject<TabNavBarItem[]>([]);

  @Output() selectedTab: EventEmitter<TabNavBarItem> = new EventEmitter<TabNavBarItem>();

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.prototype.hasOwnProperty.call(changes, 'navLinks') && changes.navLinks.currentValue) {
      this.navLinks$.next([...changes.navLinks.currentValue].sort(SortUtil.sortByProperty('index')));
    }
  }

  onTabLinkClick(tab: TabNavBarItem): void {
    this.selectedTab.emit(tab);
  }
}
