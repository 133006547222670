import { Action, createReducer, on } from '@ngrx/store';
import { AuthBusinessProfilesPageActions } from '../../../../../../../auth/src/lib/state/actions';
import { DeviceConnectorsDbActions, DeviceConnectorsUiActions } from '../actions';

export const deviceConnectorsUiFeatureKey = 'deviceConnectorsUi';

export interface State {
  connectorLoaded: boolean;
  connectorLoading: boolean;
  error?: any;
}

export const initialState: State = {
  connectorLoaded: false,
  connectorLoading: false,
  error: null
};

const deviceConnectorUiReducer = createReducer(
  initialState,
  on(DeviceConnectorsUiActions.loadConnectors, (state: State) => ({ ...state, connectorLoaded: false, connectorLoading: true })),
  on(DeviceConnectorsDbActions.loadConnectorsSuccess, (state: State) => ({ ...state, connectorLoaded: true, connectorLoading: false })),
  on(DeviceConnectorsDbActions.loadConnectorsFailure, (state: State, { error }) => ({ ...state, connectorLoaded: false, connectorLoading: false, error })),
  //
  on(DeviceConnectorsUiActions.addConnector, (state: State) => ({ ...state, connectorLoaded: false, connectorLoading: true })),
  on(DeviceConnectorsDbActions.addConnectorSuccess, (state: State) => ({ ...state, connectorLoaded: true, connectorLoading: false })),
  on(DeviceConnectorsDbActions.addConnectorFailure, (state: State, { error }) => ({ ...state, connectorLoaded: false, connectorLoading: false, error })),
  //
  on(DeviceConnectorsUiActions.updateConnector, (state: State) => ({ ...state, connectorLoaded: false, connectorLoading: true })),
  on(DeviceConnectorsDbActions.updateConnectorSuccess, (state: State) => ({ ...state, connectorLoaded: true, connectorLoading: false })),
  on(DeviceConnectorsDbActions.updateConnectorFailure, (state: State, { error }) => ({ ...state, connectorLoaded: false, connectorLoading: true, error })),
  //
  on(DeviceConnectorsUiActions.deleteConnector, (state: State) => ({ ...state, connectorLoaded: false, connectorLoading: true })),
  on(DeviceConnectorsDbActions.deleteConnectorSuccess, (state: State) => ({ ...state, connectorLoaded: true, connectorLoading: false })),
  on(DeviceConnectorsDbActions.deleteConnectorFailure, (state: State, { error }) => ({ ...state, connectorLoaded: false, connectorLoading: false, error })),
  //
  on(AuthBusinessProfilesPageActions.selectBusinessProfile, (state: State) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return deviceConnectorUiReducer(state, action);
}

export const getConnectorLoaded = (state: State) => state.connectorLoaded;
export const getConnectorLoading = (state: State) => state.connectorLoading;
