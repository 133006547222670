import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OyanAuthGuard } from './features/auth/guards/auth.guard';
import { OyanUiRoutingConstants } from './oyan-ui.router.constants';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./containers/app-shell/app-shell.module').then((m) => m.OyanUiAppShellModule),
    canActivate: [OyanAuthGuard]
  },
  {
    path: OyanUiRoutingConstants.LOGIN,
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.OyanUiAuthModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OyanUiRouterModule {}
