import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { IotPlatformPipesModule } from '@iot-platform/pipes';
import { CardLoaderModule, ChipModule, DetailPopupModule } from '@iot-platform/iot-platform-ui';
import { DetailPopupCardsModule } from '../detail-popup-cards/detail-popup-cards.module';
import { MatChipsModule } from '@angular/material/chips';
import { AssetDetailsBasicInfoComponent } from './asset-details-basic-info/asset-details-basic-info.component';
import { AssetDetailsFollowedVariablesComponent } from './asset-details-followed-variables/asset-details-followed-variables.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    IotPlatformPipesModule,
    DetailPopupModule,
    DetailPopupCardsModule,
    CardLoaderModule,
    ChipModule,
    MatChipsModule
  ],
  declarations: [AssetDetailsBasicInfoComponent, AssetDetailsFollowedVariablesComponent],
  exports: [AssetDetailsBasicInfoComponent, AssetDetailsFollowedVariablesComponent]
})
export class AssetDetailsModule {}
