import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HistoryLog, HistoryLogDetail } from '@iot-platform/models/audit-trail';
import { get } from 'lodash';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { SortUtil } from '../../../../../shared/src/lib/utils/sort.util';
import { AuditTrailService } from '../../services/audit-trail.service';

@Component({
  selector: 'iot-platform-audit-trail-history-log-popup',
  templateUrl: './history-logs-popup.component.html',
  styleUrls: ['./history-logs-popup.component.scss']
})
export class HistoryLogsPopupComponent implements OnInit, OnDestroy {
  historyLogs: HistoryLog[] = [];
  historyLogsLoading = true;

  historyLogsDetailById: { [id: string]: HistoryLogDetail } = {};
  selectedHistoryLog?: HistoryLogDetail;
  selectedLodLoading = false;

  destroy$ = new Subject<void>();

  constructor(
    private readonly auditTrailService: AuditTrailService,
    public dialogRef: MatDialogRef<HistoryLogsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { concept: string; elementId: string; elementName: string }
  ) {}

  ngOnInit(): void {
    this.auditTrailService
      .getHistoryLogByConceptAndConceptId(this.data.concept, this.data.elementId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.historyLogsLoading = false))
      )
      .subscribe((data) => {
        this.historyLogs = data.sort(SortUtil.sortBy('datetime', false));
      });
  }

  selectHistoryLog(log: HistoryLog): void {
    if (!!this.historyLogsDetailById && !!this.historyLogsDetailById[log.id]) {
      this.selectedHistoryLog = this.historyLogsDetailById[log.id];
      this.selectedLodLoading = false;
    } else {
      this.selectedLodLoading = true;
      this.auditTrailService
        .getHistoryLogById(log.id)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => (this.selectedLodLoading = false))
        )
        .subscribe((historyLogDetail: HistoryLogDetail) => {
          this.selectedHistoryLog = historyLogDetail;
          this.historyLogsDetailById[log.id] = historyLogDetail;
        });
    }
  }

  getParsedAttribute(object, attribute: string) {
    return get(object, attribute);
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
