import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterViewEngineEvent } from '@iot-platform/models/common';
import { ProcessMultipleStateVariablesUtil } from '../../../../../../../shared/src/lib/utils/process-multiple-state-variables.util';

@Component({
  selector: 'i4b-table-engine-variable-value-cell',
  templateUrl: './variable-value-cell.component.html',
  styleUrls: ['./variable-value-cell.component.scss']
})
export class VariableValueCellComponent implements OnInit {
  @Input() data: any;
  @Input() rawData: any;
  @Input() columnFormat: any;
  @Input() cellOptions: any;
  @Input() cellInfo: any;

  @Output() dispatchEvent: EventEmitter<MasterViewEngineEvent> = new EventEmitter<MasterViewEngineEvent>();

  processedUnit: string;

  constructor() {}

  ngOnInit() {
    if (
      this.data &&
      this.cellOptions &&
      this.cellOptions.value &&
      this.cellOptions.unit &&
      this.data[this.cellOptions.unit] &&
      this.data[this.cellOptions.unit][0] === '/'
    ) {
      const lastRecord = this.data[this.cellOptions.value] !== null ? [{ value: this.data[this.cellOptions.value], datetime: '' }] : [];

      this.processedUnit = ProcessMultipleStateVariablesUtil.getProcessedUnit({
        ...this.data,
        lastRecords: lastRecord
      });
    }
  }
}
