<div class="app-round-button-mv" fxLayoutAlign="center center" *ngIf="actions?.length">
  <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
    <mat-icon >more_vert</mat-icon></button>
  <mat-menu #menu="matMenu">
    <div *ngFor="let action of params?.actions">
      <grid-engine-call-to-action-menu-item
        [action]="action"
        [element]="params.data"
        [userPermissions]="userPermissions"
        (actionClicked)="onActionClicked($event)"
      >

      </grid-engine-call-to-action-menu-item>
    </div>
  </mat-menu>
</div>
