<mat-card fxLayout="column" class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="dialog-toolbar" color="accent">
      <h1 class="dialog-card-title">{{ 'FILTER_ENGINE.MANAGE_FAVORITE_FILTERS.TITLE' | translate }}</h1>
      <button mat-icon-button class="regular-round-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content fxLayout="column" class="dialog-card-content">
    <form [formGroup]="manageFavoriteFiltersForm" class="p-20" fxLayout="column" fxLayoutGap="20px">
      <div class="manage-favorite-filters--filter-count" fxLayoutGap="10px" fxLayoutAlign="start center">
        <mat-icon>star_outlined</mat-icon>
        <span>{{
          'FILTER_ENGINE.MANAGE_FAVORITE_FILTERS.FILTER_COUNT' | translate: { currentTotal: favoriteFiltersTotal, max: favoriteFiltersMaximumPossible }
        }}</span>
        <span fxFlex *ngIf="favoriteFiltersControl.hasError('favoriteFiltersEmpty')" class="manage-favorite-filters--no-filter-error">{{
          'FILTER_ENGINE.MANAGE_FAVORITE_FILTERS.ERROR_MESSAGES.NO_FILTER_SELECTED' | translate
        }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="40px">
        <section *ngFor="let filtersByConcept of data.filterCriteria" fxLayout="column" fxLayoutGap="10px">
          <div class="manage-favorite-filters--filter-concept-label">{{ filtersByConcept.label | translate }}</div>
          <div class="manage-favorite-filters--filter-checkbox-container">
            <mat-checkbox
              *ngFor="let filter of filtersByConcept.options"
              [value]="filter.key"
              [checked]="isFavoriteFilter(filter, filtersByConcept.key)"
              [disabled]="!isFavoriteFilter(filter, filtersByConcept.key) && favoriteFiltersTotal === favoriteFiltersMaximumPossible"
              (change)="onFilterSelectionChange($event, filtersByConcept.key)"
              >{{ filter.label | translate }}</mat-checkbox
            >
          </div>
        </section>
      </div>
      <div fxLayout="column" fxLayoutGap="6px" class="manage-favorite-filters--drag-and-drop-container">
        <span>{{ 'FILTER_ENGINE.MANAGE_FAVORITE_FILTERS.DRAG_AND_DROP' | translate }}</span>
      <mat-chip-list fxFlex class="chip-container" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
        <mat-chip fxLayoutAlign="start center" fxLayoutGap="4px" class="chip-filter" cdkDrag *ngFor="let favoriteFilter of favoriteFiltersForOrdering">
          <mat-icon>drag_indicator</mat-icon>
          <span>{{ favoriteFilter.name }} </span>
        </mat-chip>
      </mat-chip-list>
      </div>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayoutAlign="end center">
    <button mat-button class="button-regular" (click)="close()">{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button mat-button color="accent" class="button-regular" (click)="resetToAppDefault()">
      {{ 'FILTER_ENGINE.MANAGE_FAVORITE_FILTERS.RESET_TO_APP_DEFAULT' | translate }}
    </button>
    <button
      mat-raised-button
      color="accent"
      class="button-regular"
      [disabled]="!manageFavoriteFiltersForm.valid || !manageFavoriteFiltersForm.dirty"
      (click)="save()"
    >
      {{ 'IOT_DICTIONARY.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
