import { I4BCellType, I4BThresholdColumnConfiguration } from '../configurations/i4b-column-configuration';
import { I4BColumnHeader } from '../models/i4b-column-header.model';
import { I4BVariableColumnOptions } from '../options/i4b-variable-column-options';
import { I4BBasicColumn } from './i4b-basic.column';

export class I4BThresholdColumn extends I4BBasicColumn {
  columnId = 'f6538da4-7237-11ec-a844-acde48001122-threshold';
  className = I4BThresholdColumn;

  constructor(
    customHeader?: Partial<I4BColumnHeader>,
    customConfig?: Partial<I4BThresholdColumnConfiguration>,
    customOptions?: Partial<I4BVariableColumnOptions>
  ) {
    super(
      { displayName: 'THRESHOLD ' + customConfig.cell.options.position },
      {
        id: (customConfig.isFollowedVariable ? 'followedVariables.' : 'expandedVariables.') + customConfig.id + '.thresholds.values',
        isDefault: false,
        translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.FOLLOWED_VARIABLE_' + customConfig.id,
        cell: { ...customConfig.cell, type: I4BCellType.ASSET_VARIABLE_THRESHOLD_CELL },
        width: 130
      },
      {
        order: customOptions.order,
        customId:
          (customConfig.isFollowedVariable ? 'followedVariables.' : 'expandedVariables.') +
          customConfig.id +
          '.thresholds.values' +
          customConfig.cell.options.position,
        sortable: false,
        isDisplayed: customOptions.displayThresholds?.[customConfig.cell.options.position]
      }
    );
  }
}
