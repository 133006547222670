import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssetsShellComponent } from './containers/assets-shell/assets-shell.component';

const routes: Routes = [
  {
    path: '',
    component: AssetsShellComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AssetsRoutingModule {
}
