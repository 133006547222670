import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FavoriteView, Filter } from '@iot-platform/models/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'iot-platform-ui-filter-engine-container',
  templateUrl: './filter-engine-container.component.html',
  styleUrls: ['./filter-engine-container.component.scss']
})
export class FilterEngineContainerComponent {
  @Input() masterView: string;
  @Input() expanded: boolean;
  @Input() readonly = false;
  @Input() displayActionButtons = false;
  @Input() withFavoriteFilters = false;
  @Input() displayManageFavoriteFilters = true;
  @Input() clearAppliedFilters$: Observable<boolean>;
  @Input() currentFavoriteView$: Observable<FavoriteView>;
  @Input() currentFilters$: Observable<Filter[]>;

  @Output() applyFilters: EventEmitter<Filter[]> = new EventEmitter<Filter[]>();

  onApplyFilters(filters: Filter[]): void {
    this.applyFilters.emit(filters);
  }
}
